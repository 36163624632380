import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstructionSaveResponse } from 'investor/models';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';

import { InstructionConfirmationModalComponent } from '../confirmation-modal';

const MESSAGE_SUCCESS = 'Your instruction was successfully submitted.';
const MESSAGE_FAILURE = 'Your instruction could not be submitted.';

export abstract class BaseInstructionComponent extends BaseComponent {
    public errMessage: string;

    constructor(protected toastr: ToastrService, loggerService: LoggerService, protected modalService: NgbModal) {
        super(loggerService);
    }

    public abstract cancel();

    public abstract submit();

    protected handleResponse(result: InstructionSaveResponse, title: string) {
        if (result.success) {
            this.cancel();
            this.showModal(title, MESSAGE_SUCCESS, true);
        } else {
            this.showModal(title, MESSAGE_FAILURE, false);
        }
    }

    private showModal(title: string, message: string, success: boolean) {
        let modal = this.modalService.open(InstructionConfirmationModalComponent);
        modal.componentInstance.title = title;
        modal.componentInstance.message = message;
        modal.componentInstance.success = success;
        modal.componentInstance.retry = () => {
            modal.close();
            this.submit();
        };
    }

    protected handleSubmitError(title: string) {
        this.showModal(title, MESSAGE_FAILURE, false);
    }

    protected markAsTouched(controlGroup: FormGroup) {
        this.markControls(controlGroup, 'markAsTouched');
    }

    protected markAsPristine(controlGroup: FormGroup) {
        this.markControls(controlGroup, 'markAsPristine');
    }

    private markControls(controlGroup: FormGroup, markAsFunction: string) {
        if (controlGroup && controlGroup[markAsFunction]) {
            controlGroup[markAsFunction]();
            if (controlGroup.controls) {
                for (const controlName of Object.keys(controlGroup.controls)) {
                    const control = controlGroup.get(controlName);
                    this.markControls(control as FormGroup, markAsFunction);
                }
            }
        }
    }
}
