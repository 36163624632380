import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { Observable } from 'rxjs';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-footer',
    styleUrls: ['./footer.component.scss'],
    templateUrl: 'footer.component.pug',
})
export class FooterComponent {
    public fullFooter: Observable<boolean>;
    public fixedFooter: Observable<boolean>;

    public version: string;
    public machineId: string;

    constructor(store: Store<SharedState>) {
        this.fullFooter = store.select(fromShared.selectShowFullFooter);
        this.fixedFooter = store.select(fromShared.selectShowFixedFooter);

        this.version = appConfig.version;
        this.machineId = appConfig.serverInstance;
    }
}
