import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Injectable()
export class BankerAllowedGuard implements CanActivate {
    private isBankerAllowed: boolean = null;
    private observable;

    constructor(private store: Store<SharedState>, private router: Router) {
        this.observable = this.store.select(fromShared.selectBankerLoginEnabled);
        this.observable.subscribe(isEnabled => (this.isBankerAllowed = isEnabled));
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.isBankerAllowed === null) {
            return this.observable;
        }
        if (this.isBankerAllowed) {
            return true;
        }
        this.router.navigate(['invest']);
    }
}
