import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { Store } from '@ngrx/store';
import { Store as NgxsStore } from '@ngxs/store';
import appConfig from 'appConfig';
import { ApplicationValidity, Customer, ProductApplication, SourceOfFundsDeclaration } from 'application/models';
import { ApplicationDateService, ApplicationService } from 'application/services';
import {
    AppFormReferenceUpdated,
    AppFormSubmissionError,
    AppFormSubmitted,
    AppFormUpdatedCustomerAction,
    AppFormUpdatedDebitOrderDetailsAction,
    AppFormUpdatedPortfolioTypeAction,
    AppFormUpdatedRecordOfAdviceAction,
    ClearApplicationAction,
    CustomerSelectedAction,
    NewHorizonApplicationAction,
} from 'application/store/application.reducers';
import { ApplicationState } from 'application/store/application.store';
import * as fromApplication from 'application/store/application.store';
import { BankerState } from 'banker/store/banker.store';
import * as moment from 'moment';
import { PstResult } from 'pst/models';
import { ResetPstStateAction } from 'pst/store/pst.reducers';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { AdviceEngineService } from 'robo/services/advice-engine.service';
import { RoboStore } from 'robo/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { BaseComponent, SharedModalComponent } from 'shared/components';
import { Fund, PortfolioType } from 'shared/models';
import { SourceOfFundsType } from 'shared/models/source-of-funds-type';
import { DateFormatterService, LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

interface Step {
    visited: boolean;
    route: string;
    validityKey: string;
}

const steps: Map<number, Step> = new Map([
    [1, { visited: false, route: 'investmentdetails', validityKey: 'investmentDetailsValid' }],
    [2, { visited: false, route: 'termsandconditions', validityKey: 'tcsDetailsValid' }],
]);

const firstStep = 1;
const lastStep = 2;

@Component({
    selector: 'wim-horizon-onboarding-wizard',
    templateUrl: 'onboarding-wizard.component.pug',
    styleUrls: ['onboarding-wizard.component.scss'],
})
export class HorizonSeriesOnboardingWizardComponent extends BaseComponent implements OnDestroy {
    public applicationForm: ProductApplication;
    public pstResult: PstResult = null;

    public submissionError = false;
    public selectedFund: Observable<Fund>;
    public isCash = false;

    public termDisplay = '';
    public noticeDisplay = '';
    public accessDisplay = '';
    public recurringDisplayHeading = '';

    public pstAdvised = true;
    public isDirect = false;

    private savedAdviceId;

    public lumpsumsDisplayTotal: number;

    private step: number = firstStep;
    private selectedCustomer: Customer;
    private sourceOfFundsTypes: SourceOfFundsType[] = [];
    private modalRef: NgbModalRef;
    private OK_TO_CANCEL = 'Yes Cancel';
    private appFormValidity: ApplicationValidity;

    private products: Map<number, PortfolioType> = new Map();
    public product: PortfolioType;

    public applicationSubmittedSuccessfully = false;
    private completedFields = null;

    constructor(
        private applicationStore: Store<ApplicationState>,
        private pstStore: Store<PstState>,
        private sharedStore: Store<SharedState>,
        private bankerStore: Store<BankerState>,
        private dateFormatter: DateFormatterService,
        private router: Router,
        loggerService: LoggerService,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private lookupService: LookupDataService,
        private modalService: NgbModal,
        private numberFormatter: NumberFormatterService,
        private store: NgxsStore,
        private adviceService: AdviceEngineService,
        private applicationDateService: ApplicationDateService
    ) {
        super(loggerService);
        this.lookupService.getSourceOfFundsTypes().subscribe(types => (this.sourceOfFundsTypes = types));
        this.applicationStore.dispatch(new ClearApplicationAction());
        this.sharedStore.dispatch(new LoadingAction());

        const products$ = this.lookupService.getPortfolioTypes();
        this.selectedFund = this.pstStore.select(fromPst.selectSelectedFund);
        this.applicationStore.dispatch(new NewHorizonApplicationAction());
        this.store.select(RoboStore.saveAdviceId)
        .subscribe(adviceId => (this.savedAdviceId = adviceId));
        this.store
            .select(RoboStore.completedFields)
            .pipe(
                this.scavenger.collect(),
                take(1)
            )
            .subscribe(fields => (this.completedFields = fields));

        this.applicationStore
            .select(fromApplication.selectApplicationFormValid)
            .pipe(this.scavenger.collect())
            .subscribe(valid => (this.appFormValidity = valid));

        this.applicationStore
            .select(fromApplication.selectApplicationSubmitted)
            .pipe(this.scavenger.collect())
            .subscribe(submitted => (this.applicationSubmittedSuccessfully = submitted));

        this.applicationStore
            .select(fromApplication.selectAppSubmissionError)
            .pipe(this.scavenger.collect())
            .subscribe(err => (this.submissionError = !!err));

        this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .pipe(
                filter(customer => !!customer),
                this.scavenger.collect()
            )
            .subscribe(customer => {
                this.selectedCustomer = customer;
                this.applicationStore.dispatch(
                    new AppFormUpdatedCustomerAction({
                        firstname: customer.firstname,
                        lastname: customer.lastname,
                        idNumber: customer.idNumber,
                        age: customer.age,
                        ucn: customer.ucn,
                        title: customer.title,
                    })
                );
            });

        this.pstStore
            .select(fromPst.selectSelectedFund)
            .pipe(
                filter(fund => !!fund),
                this.scavenger.collect()
            )
            .subscribe(fund => {
                this.applicationStore.dispatch(
                    new AppFormUpdatedRecordOfAdviceAction({
                        fundId: fund.fundId,
                    })
                );
            });

        this.applicationStore
            .select(fromApplication.selectHorizonApplication)
            .pipe(this.scavenger.collect())
            .subscribe(appForm => {
                this.applicationForm = appForm;
                this.lumpsumsDisplayTotal = 0;
                let lumpsumsExist =
                    this.applicationForm &&
                    this.applicationForm.lumpSumDetails &&
                    this.applicationForm.lumpSumDetails.length > 0 &&
                    this.applicationForm.lumpSumDetails[0].amount;

                if (lumpsumsExist) {
                    appForm.lumpSumDetails.forEach(lumpSum => {
                        this.lumpsumsDisplayTotal = +this.lumpsumsDisplayTotal + +lumpSum.amount;
                    });
                }
            });

        let advisedPst$ = this.pstStore.select(fromPst.selectAdvisedPst);
        let completedPst$ = this.pstStore.select(fromPst.selectCompletedPst);

        this.pstStore
            .select(fromShared.selectIsDirect)
            .pipe(this.scavenger.collect())
            .subscribe(isDirect => (this.isDirect = isDirect));

        combineLatest(advisedPst$, completedPst$, products$, (advisedPst, completedPst, products) => {
            return { advisedPst, completedPst, products };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ advisedPst, completedPst, products }) => {
                this.pstResult = completedPst;

                this.sharedStore.dispatch(new StopLoadingAction());
                if (this.pstResult === null) {
                    this.logger.warn('Cannot onboard before completing a PST, navigating to PST');
                    this.router.navigateByUrl('/secure/banker/pst');
                    return;
                }

                products.forEach(product => {
                    this.products.set(product.id, product);
                });

                this.product = this.products.get(completedPst.portfolioTypeId);

                this.transformNoticeAndAccessPeriod(this.pstResult.accessType);

                this.termDisplay = this.transformTerm(this.pstResult.term);

                this.applicationService.createCase(this.pstResult.reference, this.selectedCustomer, this.pstResult.pstAdvised);

                const payload: any = {};
                let debitOrder = 0;

                debitOrder = completedPst.clientInstallment;

                this.pstAdvised = completedPst.pstAdvised;

                if (debitOrder > 0) {
                    payload.debitOrderDetails = {
                        amount: debitOrder,
                        escalation: completedPst.contributionIncrease,
                        debitDate: completedPst.debitOrderDetails.debitDate,
                        firstDebitDate: completedPst.debitOrderDetails.firstDebitDate,
                        firstEscalationDate: completedPst.debitOrderDetails.firstEscalationDate,
                        paymentFrequencyId: completedPst.debitOrderDetails.paymentFrequencyId,
                    };
                }

                if (!completedPst.pstAdvised) {
                    let debitDay = this.applicationDateService.firstPossibleDebitDayOfMonth();
                    let firstDebitDate = this.applicationDateService.calculateFirstDebitDate(debitDay);
                    payload.debitOrderDetails = {
                        amount: 0,
                        escalation: 6,
                        debitDate: debitDay,
                        firstDebitDate,
                        firstEscalationDate: firstDebitDate.add(1, 'years').startOf('day'),
                    };
                }

                if (!completedPst.lumpSumDetails) {
                    let lumpsum = {
                        amount: 0,
                        debitDate: this.applicationDateService.firstPossibleDebitDate(),
                    };

                    let array = [];

                    array.push(lumpsum);
                    payload.lumpSumDetails = array;
                }

                if (completedPst.lumpSumDetails) {
                    if (completedPst.lumpSumDetails.length > 0 && completedPst.lumpSumDetails[0].amount > 0) {
                        payload.lumpSumDetails = {
                            amount: completedPst.lumpSum,
                            debitDate: this.applicationDateService.firstPossibleDebitDate(),
                        };
                    }
                }

                if (
                    completedPst.portfolioTypeId !== appConfig.portfolioTypes.INV &&
                    completedPst.portfolioTypeId !== appConfig.portfolioTypes.TFDS
                ) {
                    this.isCash = true;
                } else {
                    this.isCash = false;
                }

                this.setRecurringDisplayHeading(this.pstResult);

                this.applicationStore.dispatch(new AppFormUpdatedPortfolioTypeAction(completedPst.portfolioTypeId));
                this.applicationStore.dispatch(new AppFormUpdatedDebitOrderDetailsAction(payload));
                this.applicationStore.dispatch(
                    new AppFormUpdatedRecordOfAdviceAction({
                        goalName: completedPst.goalName,
                        term: completedPst.term,
                        goalAmount: completedPst.goalAmount,
                        estimatedMaturity: completedPst.futureValue,
                        pstEscalation: advisedPst.contributionIncrease ? advisedPst.contributionIncrease * 100 : 0,
                        pstLumpsum: advisedPst.lumpSum,
                        pstLumpsumDetails: advisedPst.lumpSumDetails,
                        pstRecurring: advisedPst.clientInstallment,
                        pstTarget: advisedPst.goalAmount,
                        advisedInstallment: completedPst.advisedInstallment,
                        noOtherTfsaConfirmed: completedPst.noOtherTfsaConfirmed,
                        productDisclaimerAccepted: completedPst.productDisclaimerAccepted,
                        projectedInterestRate: completedPst.projectedInterestRate,
                        pstAccessType: advisedPst.accessType,
                        pstNoticePeriod: advisedPst.noticePeriod,
                        pstAdvised: completedPst.pstAdvised,
                        advisedLumpSum: advisedPst.advisedLumpSum,
                        advisedPortfolioTypeId: completedPst.advisedPortfolioTypeId,
                        goalAmountAdjustedForInflation: completedPst.goalAmountAdjustedForInflation,
                        affordabilityConfirmed: completedPst.affordabilityConfirmed,
                    })
                );
            });
    }

    public ngOnDestroy() { }

    public canGoNext() {
        return this.applicationForm && this.isStepValid(this.step);
    }

    public canSubmit() {
        return this.isValid();
    }

    public goNext() {
        if (!this.canGoNext()) {
            return;
        }
        let step = this.step + 1;
        if (step >= lastStep) {
            step = lastStep;
        }
        this.navigateToStep(step);
    }

    public goBack() {
        let step = this.step - 1;
        if (step <= firstStep) {
            step = firstStep;
        }
        this.navigateToStep(step);
    }

    private transformTerm(value) {
        return this.numberFormatter.transformTerm(value);
    }

    private transformNoticeAndAccessPeriod(value) {
        if (value === 4) {
            this.accessDisplay = 'End of Term';
            this.noticeDisplay = 'N/A';
        } else if (value === 5) {
            this.accessDisplay = 'Immediate';
            this.noticeDisplay = 'N/A';
        } else if (value === 6) {
            this.accessDisplay = 'With Notice';
            this.noticeDisplay = '32 Days';
        } else if (value === 7) {
            this.accessDisplay = 'With Notice';
            this.noticeDisplay = '7 Days';
        }
    }

    private setRecurringDisplayHeading(res: PstResult) {          
        if(res.clientInstallment > 0) {
            if(this.isCash) {
                this.recurringDisplayHeading = 'Scheduled Transfer';
            } else {
                if (res.debitOrderDetails.paymentFrequencyId === 3) {
                    this.recurringDisplayHeading = 'Monthly contribution';
                } else if (res.debitOrderDetails.paymentFrequencyId === 7) {
                    this.recurringDisplayHeading = 'Annual contribution';
                }
            }
        } else {
            this.recurringDisplayHeading = 'Monthly contribution';
        }
    }    

    public cancel() {
        const warningRef = this.modalService.open(SharedModalComponent);
        warningRef.componentInstance.modalMessage = `Are you sure you want to cancel this Application?`;
        warningRef.componentInstance.noValue = 'No';
        warningRef.componentInstance.yesValue = 'Yes, Cancel Application';
        warningRef.componentInstance.modalTitle = 'Confirm Application Cancellation';
        warningRef.result
            .then(
                resultType => {
                    if (resultType === this.OK_TO_CANCEL) {
                        this.cancelConfirm();
                    } else {
                        this.logger.debug(`modal closed`);
                    }
                },
                () => {
                    this.logger.debug(`modal dismissed`);
                }
            )
            .catch(() => {
                this.logger.debug('Confirm modal dismissed');
            });
        this.modalRef = warningRef;
    }

    public cancelConfirm() {
        this.sharedStore.dispatch(new LoadingAction());
        this.applicationService
            .cancelApplication(this.applicationForm)
            .pipe(
                finalize(() => {
                    this.sharedStore.dispatch(new StopLoadingAction());
                    this.modalRef.dismiss();
                    if (this.isDirect) {
                        this.router.navigateByUrl('/invest');
                    } else {
                        this.router.navigateByUrl('/secure/banker/customer-search');
                        this.bankerStore.dispatch(new CustomerSelectedAction(null));
                    }
                    this.pstStore.dispatch(new ResetPstStateAction());
                })
            )
            .subscribe();
    }

    public submit() {
        this.applicationForm.fundId = this.applicationForm.recordOfAdvice.fundId;
        this.applicationForm.adviceFields = {
            fields: this.adviceService.convertObjectToArrayOfFields(this.completedFields),
            contextualData: this.adviceService.getContextualData(),
        };

        if (this.canSubmit()) {
            this.sharedStore.dispatch(new LoadingAction());
            if (this.applicationForm.lumpSumDetails) {
                this.applicationForm.lumpSumDetails = this.applicationForm.lumpSumDetails
                    .filter(lumpsum => !!lumpsum.debitDate)
                    .map(lumpSum => {
                        lumpSum.amount = +this.numberFormatter.parseBigNumber(`${lumpSum.amount}`);
                        return lumpSum;
                    });
            }
            if (this.applicationForm.debitOrderDetails) {
                this.applicationForm.debitOrderDetails.amount = +this.numberFormatter.parseBigNumber(
                    `${this.applicationForm.debitOrderDetails.amount}`
                );
            }

            this.applicationForm.savedAdviceReference = this.savedAdviceId;

            this.applicationService.createParty(this.applicationForm).subscribe(
                result => {
                    this.applicationStore.dispatch(new AppFormSubmitted(true));
                    this.applicationStore.dispatch(new AppFormSubmissionError(null));
                    this.applicationStore.dispatch(new AppFormReferenceUpdated(result.reference));
                    this.router.navigate(['complete'], { relativeTo: this.route });
                    this.sharedStore.dispatch(new StopLoadingAction());
                },
                err => {
                    this.applicationStore.dispatch(new AppFormSubmitted(false));
                    this.applicationStore.dispatch(new AppFormSubmissionError(err));
                    this.router.navigate(['complete'], { relativeTo: this.route });
                    this.sharedStore.dispatch(new StopLoadingAction());
                }
            );
        }
    }

    public isFirstPage() {
        return this.step === firstStep;
    }

    public isSecondPage() {
        return this.step === firstStep + 1;
    }

    public isLastPage() {
        return this.step === lastStep;
    }

    public navigateToStep(step) {
        steps.get(this.step).visited = true;
        if (step === this.step) {
            return;
        }
        this.step = step;
        steps.get(this.step).visited = true;
        this.router.navigate([steps.get(this.step).route], { relativeTo: this.route });
    }

    public isDone(step): boolean {
        return !this.isCurrent(step) && !!steps.get(step).visited;
    }

    public done() {
        this.router.navigateByUrl(this.isDirect ? '/secure/investor' : '/secure/banker/customer-search');
    }

    public isDisabled(step): boolean {
        if (this.isCurrent(step) || this.isDone(step)) {
            return false;
        }
        return this.step !== step;
    }

    public isCurrent(step): boolean {
        return step === this.step;
    }

    private isStepValid(step: number) {
        return this.appFormValidity[steps.get(step).validityKey];
    }

    private isValid(): boolean {
        return this.appFormValidity.investmentDetailsValid && this.appFormValidity.tcsDetailsValid;
    }
}
