import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagService } from 'shared/services/feature-flag.service';

import { FeatureFlags } from '../models';

@Injectable()
export class FeatureGuard implements CanActivate {
    private featureRoutes: { [feature: string]: FeatureFlags | FeatureFlags[] } = {};

    // If more than one feature is specified, the user only has to have 1 enabled (i.e. logical OR)
    constructor(private featureFlagService: FeatureFlagService, private router: Router) {
        this.featureRoutes['pending-agreements'] = FeatureFlags.AcceptTsAndCs;
        this.featureRoutes['investor-home'] = [FeatureFlags.BalanceDisplay, FeatureFlags.SharesBalanceDisplay];
        this.featureRoutes['documents'] = FeatureFlags.DownloadDocuments;
        this.featureRoutes['investor-instructions'] = FeatureFlags.MaintenanceInstructions;
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        let targetRoute = route.routeConfig.path;
        let featureConfig = this.featureRoutes[targetRoute];
        if (featureConfig) {
            let featuresRequired: FeatureFlags[];
            if (!Array.isArray(featureConfig)) {
                featuresRequired = [featureConfig];
            } else {
                featuresRequired = featureConfig;
            }

            let userHasAtLeastOneFeature = featuresRequired.some(feature => this.featureFlagService.isEnabled(feature));

            if (userHasAtLeastOneFeature) {
                return true;
            } else {
                this.router.navigateByUrl('/');
            }
        } else {
            return true;
        }
    }
}
