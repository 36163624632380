var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AccountSelectedAction } from 'investor/store/account.reducers';
import * as AccountActions from 'investor/store/account.reducers';
import * as fromInvestor from 'investor/store/investor.store';
import { ShowMenusAction } from 'shared/store/layout.reducers';
import { map, mergeMap } from 'rxjs/operators';
import { FeatureFlags } from 'shared/models';
import { FeatureFlagService } from 'shared/services';
var AccountEffects = /** @class */ (function () {
    function AccountEffects(actions$, investorStore, featueFlagService) {
        var _this = this;
        this.actions$ = actions$;
        this.investorStore = investorStore;
        this.featueFlagService = featueFlagService;
        this.accountSelected$ = this.actions$.ofType(AccountActions.ACCOUNT_NUMBER_SELECTED).pipe(map(function (action) { return action.payload; }), mergeMap(function (payload) { return _this.findAccount(payload); }));
        this.accountsLoaded$ = this.actions$.ofType(AccountActions.INVESTMENT_ACCOUNTS_LOADED).pipe(map(function (action) { return action.payload; }), mergeMap(function (payload) { return _this.showInvestorMenus(payload); }));
        this.investorStore.select(fromInvestor.selectInvestmentAccounts).subscribe(function (accounts) { return (_this.accounts = accounts); });
    }
    AccountEffects.prototype.findAccount = function (accountNumber) {
        var selectedAccount = this.accounts.filter(function (account) { return account.accountNumber === accountNumber; })[0];
        return of(new AccountSelectedAction(selectedAccount));
    };
    AccountEffects.prototype.showInvestorMenus = function (accounts) {
        if (accounts && accounts.length > 0) {
            var menusToShow = [];
            if (this.featueFlagService.isEnabled(FeatureFlags.MaintenanceInstructions)) {
                menusToShow.push('instructions');
            }
            if (this.featueFlagService.isEnabled(FeatureFlags.DownloadDocuments)) {
                menusToShow.push('documents');
            }
            return of(new ShowMenusAction(menusToShow));
        }
        return of();
    };
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AccountEffects.prototype, "accountSelected$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], AccountEffects.prototype, "accountsLoaded$", void 0);
    return AccountEffects;
}());
export { AccountEffects };
