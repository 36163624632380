import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatterService } from 'shared/services/number-formatter.service';

@Pipe({ name: 'bigNumber' })
export class BigNumberPipe implements PipeTransform {
    constructor(private formatterService: NumberFormatterService) {}

    public transform(value: number | string, fractionSize: number = 2, zeroIfEmpty = false, includeDecimals = false): string {
        return this.formatterService.transformBigNumber(value, fractionSize, zeroIfEmpty, includeDecimals);
    }

    public parse(value: string, fractionSize: number = 2): string {
        return this.formatterService.parseBigNumber(value, fractionSize, false);
    }
}
