import { AfterContentInit, Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionDispatched, Store as NgxsStore } from '@ngxs/store';
import { Store } from '@ngrx/store';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import appConfig from 'appConfig';
import { ApplicationDateService } from 'application/services';
import * as moment from 'moment';
import { PstContentModalComponent } from 'pst/components/content-modal/pst-content-modal.component';
import { FieldUpdate, RoboField, RoboInput } from 'robo/models';
import { FundingMethod } from 'robo/models/funding-method';
import { RoboFieldId } from 'robo/models/robo-field-id';
import { AdviceEngineService } from 'robo/services/advice-engine.service';

import {
    AdviceFormStatusChangedAction,
    ForceValidationMessagesAction,
    RoboAdditionalLumpsumsUpdated,
    RoboClearScreenAction,
    RoboRecommendationAcceptedAction,
    RoboStore,
    RoboUpdateFieldAction,
    TaxFreeOpenedAction,
    UpdateRoboValidationWarningsAction,
    RoboUpdateMultipleFieldsAction,
} from 'robo/store';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { FeatureFlags, MultipleChoiceOption, RoboGoal } from 'shared/models';
import { DateFormatterService, FeatureFlagService, LoggerService, LookupDataService } from 'shared/services';
import { bigNumberMaxValidator, bigNumberMinValidator, dateMaxValidator, dateMinValidator, dateSubsetValidator } from 'shared/validators';

import { Validator as ValidatorMetaData } from '.';

const DEFAULT_MIN_TERM = 1;
const DEFAULT_MAX_TERM = 120;

const DEFAULT_MAX = 10000000;
const DEFAULT_MIN = 1;
const DEBOUNCE_TIME = 500;

const OTHER_GOAL = 10;

@Component({
    selector: 'wim-robo-input-capture',
    templateUrl: 'robo-input-capture.component.pug',
    styleUrls: ['robo-input-capture.component.scss'],
})
export class RoboInputCaptureComponent extends BaseComponent implements AfterContentInit {
    public RoboFieldId = RoboFieldId;
    //public allowedTfsaDates = [1, 16, 21, 26];
    public allowedTfsaDates = Array.from({length: 26}, (_, i) => i + 1);
    public adviceForm: FormGroup;

    public goals: RoboGoal[];
    public goalTypeName: string;
    public goalName: string = null;
    public isEmergenciesGoalCategory: boolean;
    public isJustInvestTaxFreeGoalCategory: boolean;
    public isAnnually = false;
    public frequencyTypes = [];

    public isDirect: boolean;
    
    private preserveLumpSumFieldRange = false;
    private preserveRecurringFieldRange = false;

    private toolTips = [];
    private CPIRate: number;

    private OPTIONAL_FIELDS = [RoboFieldId.requestAdditionalLumpSums];
    private DEFAULT_NULL_BOOLEAN_FIELDS = [RoboFieldId.contributingToTaxFree];

    public showTarget = true;
    public showAdditionalLumpsums = false;
    public showAddAnotherButton = false;

    private completedFields: { [key: number]: RoboInput } = {};
    public visibleFields: { [key: number]: RoboField } = {};
    public fieldsRanges: { [key: number]: { min: number; max: number } } = {};

    public minDate: Date;
    public minDateTFUT: Date;
    public maxDate: Date;
    public minDateForPicker: NgbDateStruct;
    public minDateForPickerRecurring: NgbDateStruct;
    public maxDateForPicker: NgbDateStruct;

    public multipleLumpSumsEnabaled: boolean;

    private targetAmount = 0;
    public considerInflation = false;
    public goalAdjustedForInflation = 0;

    private isTfsa = false;
    private taxFreeOptOut = false;
    private hasExistingTfsa = false;
    private hasInFlightTfsa = false;    
    public tfsaDateError;

    public selectedFundingType = FundingMethod.both;

    public additionalLumpsumsEnabled: boolean = null;

    // No way to query existing validators, so need to track separatelty
    private currentValidators: { [key: number]: ValidatorMetaData[] } = {};

    public roboValidationWarnings$: Observable<string[]>;

    constructor(
        loggerService: LoggerService,
        private formBuilder: FormBuilder,
        private store: NgxsStore,
        private sharedStore: Store<SharedState>,
        private applicationStore: Store<ApplicationState>,
        private lookupDataService: LookupDataService,
        private adviceService: AdviceEngineService,
        private modalService: NgbModal,
        private dateFormatterService: DateFormatterService,
        private actions$: Actions,
        private applicationDateService: ApplicationDateService,
        private featureFlagService: FeatureFlagService
    ) {
        super(loggerService);
        let minDate = this.applicationDateService.firstPossibleDebitDate();
        let minDateTFUT = this.applicationDateService.firstPossibleTFUTDebitDate();
        let maxDate = this.applicationDateService.maxLumpsumDate();

        this.multipleLumpSumsEnabaled = this.featureFlagService.isEnabled(FeatureFlags.MultipleLumpSums);

        this.minDateForPicker = this.dateFormatterService.forDatePicker(minDate);
        this.minDateForPickerRecurring = this.dateFormatterService.forDatePicker(minDate);
        this.maxDateForPicker = this.dateFormatterService.forDatePicker(maxDate);

        this.minDate = minDate.startOf('day').toDate();
        this.minDateTFUT = minDateTFUT.startOf('day').toDate();
        this.maxDate = maxDate.endOf('day').toDate();

        store
            .select(RoboStore.taxFreeOpened)
            .pipe(this.scavenger.collect())
            .subscribe(taxFreeOpened => {
                this.isTfsa = taxFreeOpened;

                this.setMinDateRecurring();
                this.validateDateFields();
            });

        sharedStore
            .select(fromShared.selectIsDirect)
            .pipe(this.scavenger.collect())
            .subscribe(isDirect => {
                this.isDirect = isDirect;
            });             

        applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .subscribe(selected => {                
                this.hasExistingTfsa = selected ? selected.hasExistingTaxFreeAccount : null;
                this.hasInFlightTfsa = selected ? selected.hasInFlightTaxFreeApplication : null;

                let validationErrors: string[] = [];
                if(this.hasExistingTfsa) {
                    validationErrors.push('Please note that you already have an existing Tax-Free Unit Trust account, an alternative investment will be recommended.');
                }                
                if(this.hasInFlightTfsa) {
                    validationErrors.push('Please note that you already have an existing Tax-Free Unit Trust application in progress, an alternative investment will be recommended.');
                }

                this.store.dispatch(
                    new UpdateRoboValidationWarningsAction(validationErrors)
                )      
            });            

        /*this.tfsaDateError = `For TFUT, only ${this.allowedTfsaDates
            .slice(0, this.allowedTfsaDates.length - 1)
            .join(',')} or ${this.allowedTfsaDates.slice(this.allowedTfsaDates.length - 1)} is allowed`;*/

        this.tfsaDateError = `For TFUT, only ${this.allowedTfsaDates[0]}st to ${this.allowedTfsaDates[this.allowedTfsaDates.length - 1]}th is allowed`;            
    }

    public ngAfterContentInit() {
        this.clearLocalData();
        this.getGoals();
        this.getTooltips();
        this.getCPIRate();
        this.registerForRequiredFieldChanges();
        this.registerForFieldValueChanges();
        this.registerForActionsDispatched();
        this.registerForSpecificFieldChanges();
        this.registerForAdviceUpdates();
        this.setDefaultRecurringFrequency();
        this.setDefaultTaxFreeOptOut();
        this.registerForTaxFreeOptOutChanges();
        this.checkForExistingTfsa();
    }

    markDisabledRecurringStartDate = (date: NgbDateStruct) => {
        return ((date.day > 26) && (this.isTfsa) && (!this.isAnnually));
    }    

    private checkForExistingTfsa() {

        if(this.hasExistingTfsa) {
            this.adviceForm.get(RoboFieldId[RoboFieldId.contributingToTaxFree]).setValue(this.hasExistingTfsa);          
        }
        
        if(this.hasInFlightTfsa) {
            this.adviceForm.get(RoboFieldId[RoboFieldId.contributingToTaxFree]).setValue(this.hasInFlightTfsa);
        }    
        
        this.roboValidationWarnings$ = this.store.select(RoboStore.roboValidationWarnings);
    }    

    private setDefaultTaxFreeOptOut() {
        // Default to false
        this.store.dispatch(
            new RoboUpdateFieldAction({
                fieldId: RoboFieldId.taxFreeOptOut,
                index: 0,
                value: this.taxFreeOptOut.toString(),
            })
        )
    }

    private registerForTaxFreeOptOutChanges() {
        this.store
            .select(RoboStore.taxFreeOptOut)
            .pipe(this.scavenger.collect())
            .subscribe(taxFreeOptOut => {
                if(this.taxFreeOptOut === taxFreeOptOut) {
                    return;
                }
                this.taxFreeOptOut = taxFreeOptOut;
                this.store.dispatch(
                    new RoboUpdateFieldAction({
                        fieldId: RoboFieldId.taxFreeOptOut,
                        index: 0,
                        value: this.taxFreeOptOut.toString(),
                    })
                )
                .pipe()
                .subscribe(() => {this.adviceService.getAdvice();}); 
            });     
    }    

    private getTooltips() {
        this.lookupDataService.getPstTooltips().subscribe(toolTips => {
            this.toolTips = toolTips;
        });
    }

    private getCPIRate() {
        this.lookupDataService.getCPIRate().subscribe(rate => {
            this.CPIRate = rate * 100;
        });        
    }

    private getGoals() {
        this.lookupDataService
            .getRoboGoals()
            .pipe(this.scavenger.collect())
            .subscribe(goals => {
                this.goals = goals;
                if(this.hasExistingTfsa || this.hasInFlightTfsa || this.isDirect) {
                    this.goals = this.goals.filter(goal => goal.goalId !== 11)
                }
            });
    }

    private setDefaultRecurringFrequency() {
        // Default to Monthly for now, remove once LOBs can support others
        this.store.dispatch(
            new RoboUpdateFieldAction({
                fieldId: RoboFieldId.recurringContributionFrequency,
                index: 0,
                value: '3',
            })
        );
    }

    private getDefaultYealyIncreaseValue() {
        if(this.isJustInvestTaxFreeGoalCategory) {
            return '0';
        }        
        if(this.isEmergenciesGoalCategory) {
            return this.CPIRate.toString();
        }        
        
        let hasTaxFree = this.getBooleanFieldValue(RoboFieldId.contributingToTaxFree);

        let valueToSet: string;

        if(hasTaxFree == null) {
            valueToSet = this.CPIRate.toString();
        } else {
            if(hasTaxFree) {
                valueToSet = this.CPIRate.toString();
            } else {
                valueToSet = '0';
            }            
        }
        
        return valueToSet;
    }

    private setDefaultYealyIncrease() {

        if (this.visibleFields[RoboFieldId.annualEscalationPercentage] || this.isJustInvestTaxFreeGoalCategory) {

            let valueToSet = this.getDefaultYealyIncreaseValue();

            this.store.dispatch(
                new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.annualEscalationPercentage,
                    index: 0,
                    value: valueToSet,
                })
            );

            this.adviceForm.get(RoboFieldId[RoboFieldId.annualEscalationPercentage]).setValue(valueToSet);
        }      
    }

    private getMinDateRecurring() {

        let valueToSet = this.dateFormatterService.forDatePicker(moment(this.minDate));

        if((this.isTfsa) && (!this.isAnnually)) {
            valueToSet = this.dateFormatterService.forDatePicker(moment(this.minDateTFUT));
        }         

        return valueToSet;
    }
    
    private setMinDateRecurring() {

        let valueToSet = this.getMinDateRecurring();                   
        
        this.minDateForPickerRecurring = valueToSet;
        
        if (this.visibleFields[RoboFieldId.recurringContributionStartDate]) {

            let recurringDateValue = this.getDateFieldValue(RoboFieldId.recurringContributionStartDate);

            if(recurringDateValue && this.dateFormatterService.asMoment(valueToSet).isAfter(this.dateFormatterService.asMoment(recurringDateValue))) {
                // new min after current value
                this.adviceForm.get(RoboFieldId[RoboFieldId.recurringContributionStartDate]).setValue(valueToSet);
            }
        }
    }    

    private setJustInvestTaxFreeFields() {

        let value = this.adviceForm.value[RoboFieldId[RoboFieldId.fundingMethod]];

        if (value === 5 && this.isJustInvestTaxFreeGoalCategory) {
            
            this.adviceForm.get(RoboFieldId[RoboFieldId.lumpSumAmount]).setValue(null);
            this.adviceForm.get(RoboFieldId[RoboFieldId.recurringContributionAmount]).setValue(null);           
    
            let fields = [{
                fieldId: RoboFieldId.lumpSumAmount,
                index: 0,
                value: null,            
            },
            {
                fieldId: RoboFieldId.recurringContributionAmount,
                index: 0,
                value: null,            
            }];
            this.store.dispatch(new RoboUpdateMultipleFieldsAction(fields));
        }

        this.preserveLumpSumFieldRange = false;
        this.preserveRecurringFieldRange = false;

        if (value === 6 && this.isJustInvestTaxFreeGoalCategory) {
            this.frequencyTypes = [
                {value: '7', description: 'Annually'}
              ];
            this.isAnnually = true;
            this.store.dispatch(
                new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.recurringContributionFrequency,
                    index: 0,
                    value: '7',
                })
            );            
        } else {
            this.frequencyTypes = [                
                {value: '3', description: 'Monthly'}
              ];
            this.isAnnually = false;
            this.store.dispatch(
                new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.recurringContributionFrequency,
                    index: 0,
                    value: '3',
                })
            );                 
        }               
    }

    private fieldChangeGoalCategory() {
        if (this.visibleFields[RoboFieldId.goal]) {
            let value = this.adviceForm.value[RoboFieldId[RoboFieldId.goal]];
            this.isEmergenciesGoalCategory = (value === 4);
            this.isJustInvestTaxFreeGoalCategory = (value === 11);
            this.setDefaultYealyIncrease();
            this.fieldChange(RoboFieldId.goal);
        }
    }    

    private fieldChangeAnnualEscalationPercentage() {
        if (this.visibleFields[RoboFieldId.annualEscalationPercentage]) {
            let value = this.adviceForm.value[RoboFieldId[RoboFieldId.annualEscalationPercentage]];
            if((0 < +value) && (+value < 1)) {                
                this.store.dispatch(
                    new RoboUpdateFieldAction({
                        fieldId: RoboFieldId.annualEscalationPercentage,
                        index: 0,
                        value: '0',
                    })
                );
            } else {
                this.fieldChange(RoboFieldId.annualEscalationPercentage);
            }            
        }
    }

    private fieldChangeCheckTaxFreeLimit(fieldId: RoboFieldId) {

        // this check will only needs to be done for the Lump Sum & Recurring funding method type

        // here we will set a boolean values (preserveLumpSumFieldRange and preserveRecurringFieldRange) which will be used in the updateForm method 
        // this is becuase the fieldRange of the other field must only not change if the value entered does not exceed the current maximum

        if (this.adviceForm.value[RoboFieldId[RoboFieldId.fundingMethod]] === 5 && this.isJustInvestTaxFreeGoalCategory) {

            if(fieldId === RoboFieldId.lumpSumAmount) {
                this.preserveLumpSumFieldRange = true;

                let LSValue = +this.adviceForm.value[RoboFieldId[RoboFieldId.lumpSumAmount]];
                let LSValueMax = this.maxForField(RoboFieldId.lumpSumAmount);                
                if(LSValue > LSValueMax) {
                    this.preserveRecurringFieldRange = true;                    
                } else {
                    this.preserveRecurringFieldRange = false;
                }                 
            }

            if(fieldId === RoboFieldId.recurringContributionAmount) {
                this.preserveRecurringFieldRange = true;

                let RECValue = +this.adviceForm.value[RoboFieldId[RoboFieldId.recurringContributionAmount]];
                let RECValueMax = this.maxForField(RoboFieldId.recurringContributionAmount);                
                if(RECValue > RECValueMax) {
                    this.preserveLumpSumFieldRange = true;
                } else {
                    this.preserveLumpSumFieldRange = false;
                }   
            }                      
            
            // we must update the field so that the advice engine is called with the correct value
            this.fieldChange(fieldId); 

        } else {
            this.fieldChange(fieldId); 
        }   
    }    

    private registerForAdviceUpdates() {
        this.store
            .select(RoboStore.currentAdvice)
            .pipe(
                this.scavenger.collect(),
                filter(advice => advice && advice.recommendations.length > 0),
                map(advice => advice.recommendations[0])
            )
            .subscribe(recommendation => {
                this.goalAdjustedForInflation = recommendation.goalAmountAdjustedForInflation;

                if (recommendation.taxFreeOptionAvailable) {
                    if(this.taxFreeOptOut == false) {
                        this.store.dispatch(new TaxFreeOpenedAction(recommendation.taxFreeOptionAvailable));
                    }                    
                } else {
                    this.store.dispatch(new TaxFreeOpenedAction(false));
                }

                this.validateDateFields();
            });
    }

    private validateDateFields() {
        if (this.adviceForm) {
            this.validateDateControl(this.adviceForm.get('lumpSumDate'));
            this.validateDateControl(this.adviceForm.get('recurringContributionStartDate'));

            (this.adviceForm.get('additionalLumpsums') as FormArray).controls.forEach(control =>
                this.validateDateControl(control.get('date'))
            );
        }
    }

    private registerForSpecificFieldChanges() {
        this.selectFieldValue(RoboFieldId.goal).subscribe(goalId => {
            if (!this.goals) {
                return;
            }
            let selectedGoal = this.goals.find(goal => goal.goalId === +goalId);
            this.goalTypeName = selectedGoal ? selectedGoal.name : null;
            this.isEmergenciesGoalCategory = goalId ? (+goalId === 4) : false;
            this.isJustInvestTaxFreeGoalCategory = goalId ? (+goalId === 11) : false;
        });

        this.selectFieldValue(RoboFieldId.goalName)
            .pipe(this.scavenger.collect())
            .subscribe(goalName => {
                this.goalName = goalName;
            });

        this.selectFieldValue(RoboFieldId.considerInflation)
            .pipe(this.scavenger.collect())
            .subscribe(considerInflation => {
                if (considerInflation != null) {
                    this.considerInflation = `${considerInflation}`.toLowerCase() === 'true';
                }
            });

        this.selectFieldValue(RoboFieldId.targetValue)
            .pipe(this.scavenger.collect())
            .subscribe(target => {
                this.targetAmount = +target;
            });
    }

    private registerForActionsDispatched() {
        this.actions$
            .pipe(
                ofActionDispatched(ForceValidationMessagesAction),
                this.scavenger.collect()
            )
            .subscribe(() => this.markControlsTouched(this.adviceForm));
        this.actions$
            .pipe(
                ofActionDispatched(RoboClearScreenAction),
                this.scavenger.collect()
            )
            .subscribe(() => {
                this.clearLocalData();
            });
        this.actions$
            .pipe(
                ofActionDispatched(RoboRecommendationAcceptedAction),
                this.scavenger.collect()
            )
            .subscribe((action: RoboRecommendationAcceptedAction) => {
                switch (action.payload.field) {
                    case RoboFieldId.lumpSumAmount:
                        this.setRecommendedLumpSumAmount(action);
                        break;
                    case RoboFieldId.recurringContributionAmount:
                        this.setRecurringAmount(action);
                        break;
                    case RoboFieldId.targetValue:
                        this.adviceForm.get(RoboFieldId[RoboFieldId.targetValue]).setValue(action.payload.value);
                        break;
                }
                this.adviceForm.updateValueAndValidity();
            });
    }

    private setRecurringAmount(action: RoboRecommendationAcceptedAction) {
        this.setFundingType(FundingMethod.recurring);
        this.adviceForm.get(RoboFieldId[RoboFieldId.fundingMethod]).setValue(this.selectedFundingType);
        this.fieldChange(RoboFieldId.fundingMethod);
        this.adviceForm.get(RoboFieldId[RoboFieldId.recurringContributionAmount]).setValue(action.payload.value);
        this.fieldChange(RoboFieldId.recurringContributionAmount);
    }

    private setRecommendedLumpSumAmount(action: RoboRecommendationAcceptedAction) {
        this.setFundingType(FundingMethod.lumpsum);
        this.adviceForm.get(RoboFieldId[RoboFieldId.fundingMethod]).setValue(this.selectedFundingType);
        this.fieldChange(RoboFieldId.fundingMethod);
        this.adviceForm.get(RoboFieldId[RoboFieldId.lumpSumAmount]).setValue(action.payload.value);
        this.fieldChange(RoboFieldId.lumpSumAmount);
    }

    private registerForFieldValueChanges() {
        this.store
            .select(RoboStore.completedFields)
            .pipe(this.scavenger.collect())
            .subscribe(completedFields => {
                this.completedFields = completedFields;
                if (!this.adviceForm) {
                    this.createForm();
                }
            });
    }

    private registerForRequiredFieldChanges() {
        this.store
            .select(RoboStore.requiredFields)
            .pipe(
                this.scavenger.collect(),
                filter(fields => !!fields)
            )
            .subscribe(requiredFields => {
                this.updateForm(requiredFields);
            });
    }

    private clearLocalData() {
        this.additionalLumpsumsEnabled = null;
        this.considerInflation = false;
        this.goalName = null;
        this.goalTypeName = null;
        this.showTarget = true;
        this.showAdditionalLumpsums = false;
        this.showAddAnotherButton = false;
        this.targetAmount = 0;
        this.goalAdjustedForInflation = 0;
        this.selectedFundingType = null;
    }

    public ngOnDestroy() {}

    public setFundingType(action) {
        this.selectFieldValue(RoboFieldId.fundingMethod).subscribe(fundingStrategy => {
            if (parseInt(fundingStrategy, 10) === FundingMethod.lumpsum && action === FundingMethod.lumpsum) {
                this.selectedFundingType = parseInt(fundingStrategy, 10);
            } else if (parseInt(fundingStrategy, 10) === FundingMethod.recurring && action === FundingMethod.recurring) {
                this.selectedFundingType = parseInt(fundingStrategy, 10);
            } else {
                this.selectedFundingType = FundingMethod.both;
            }
        });
    }

    public maxForTermField() {
        const field = this.visibleFields[RoboFieldId.term];
        return field && field.fieldRange ? field.fieldRange.max : DEFAULT_MAX_TERM;
    }

    public showInflatedGoal() {
        return this.considerInflation && this.goalAdjustedForInflation > 0 && this.targetAmount !== this.goalAdjustedForInflation;
    }

    public minForTermField() {
        const field = this.visibleFields[RoboFieldId.term];
        return field && field.fieldRange ? field.fieldRange.min : DEFAULT_MIN_TERM;
    }

    public maxForField(fieldId: RoboFieldId): number {
        const field = this.visibleFields[fieldId];
        return field && field.fieldRange ? field.fieldRange.max : DEFAULT_MAX;
    }

    public minForField(fieldId: RoboFieldId): number {
        const field = this.visibleFields[fieldId];
        return field && field.fieldRange ? field.fieldRange.min : DEFAULT_MIN;
    }

    public fieldOptions(fieldId: RoboFieldId): MultipleChoiceOption[] {
        const field = this.visibleFields[fieldId];
        return field && field.options ? field.options : [];
    }

    public showField(fieldId: RoboFieldId): boolean {
        return !!this.visibleFields[fieldId];
    }

    public showTooltip() {
        let content = this.toolTips[1];
        content.tooltip = content.tooltip.replace('{{CPI_VALUE}}', this.CPIRate + '%');        
        const modalRef = this.modalService.open(PstContentModalComponent);        
        modalRef.componentInstance.content = content.tooltip;
    }

    public showTaxFreeDisclaimer() {
        let content = this.toolTips[3];      
        const modalRef = this.modalService.open(PstContentModalComponent);        
        modalRef.componentInstance.content = content.tooltip;
    }    

    public showInflationDescription() {
        let content = this.toolTips[6];
        this.logger.debug(content);
        const modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.tooltip;
    }

    public showRecurringStartDateDisclaimer() {
        var content;
        if(this.isAnnually) {
            content = this.toolTips[4];
        } else {
            content = this.toolTips[5];
        }
        const modalRef = this.modalService.open(PstContentModalComponent);        
        modalRef.componentInstance.content = content.tooltip;
    }    

    public fieldChange(fieldId: RoboFieldId) {
        const fieldUpdate: FieldUpdate = {
            fieldId,
            value: this.adviceForm.value[RoboFieldId[fieldId]],
        };

        this.store.dispatch(new RoboUpdateFieldAction(fieldUpdate));
    }

    public dateFieldChange(fieldId: RoboFieldId) {
        const date = this.adviceForm.value[RoboFieldId[fieldId]] as NgbDateStruct;
        const fieldUpdate: FieldUpdate = {
            fieldId,
            value: this.dateFormatterService.toString(date),
        };
        let control = this.adviceForm.get(RoboFieldId[fieldId]);
        this.validateDateControl(control);

        this.store.dispatch(new RoboUpdateFieldAction(fieldUpdate));
    }

    private validateDateControl(control: AbstractControl) {
        let minError = dateMinValidator(this.minDate, this.dateFormatterService)(control);
        let maxError = dateMaxValidator(this.maxDate, this.dateFormatterService)(control);
        let requiredError = Validators.required(control);
        let subsetError = null;
        if ((this.isTfsa) && (control === this.adviceForm.get('recurringContributionStartDate'))) {
            subsetError = dateSubsetValidator(this.allowedTfsaDates, this.dateFormatterService)(control);
        }

        let errors = Object.assign({}, minError, maxError, requiredError, subsetError);
        if (Object.keys(errors).length === 0) {
            errors = null;
        }
        control.setErrors(errors);
    }

    private selectFieldValue(id: RoboFieldId): Observable<string> {
        return this.store.select(RoboStore.completedField).pipe(
            map(fn => {
                let field = fn(id);
                return field ? field.value : null;
            })
        );
    }

    private updateForm(requiredFields: RoboField[]) {
        if (!this.adviceForm) {
            return;
        }
        let changesMade = false;
        for (let fieldId of Object.values(RoboFieldId).filter(k => !isNaN(k))) {
            const field = requiredFields.find(f => f.fieldId === fieldId);
            let fieldName = RoboFieldId[fieldId];
            let control: AbstractControl = this.adviceForm.get(fieldName);
            if (field) {
                if (!control) {
                    continue;
                }
                // preserve fieldRange if neccessary for Tax Free limit
                if((fieldId === RoboFieldId.lumpSumAmount) && (this.preserveLumpSumFieldRange)) {
                    field.fieldRange = this.visibleFields[fieldId].fieldRange;
                }
                // preserve fieldRange if neccessary for Tax Free limit
                if((fieldId === RoboFieldId.recurringContributionAmount) && (this.preserveRecurringFieldRange)) {
                    field.fieldRange = this.visibleFields[fieldId].fieldRange;
                }                                     
                this.visibleFields[fieldId] = field;
                let value = this.adviceForm.value[fieldName];
                if (value === null || value === undefined) {
                    let defaultValue = this.getValueOrDefault(fieldId, id => this.getStringFieldValue(id));

                    if (defaultValue) {
                        let controlValue = defaultValue;
                        if (fieldId === RoboFieldId.lumpSumDate) {
                            controlValue = this.dateFormatterService.forDatePickerFromString(defaultValue);
                        }
                        if (fieldId === RoboFieldId.recurringContributionStartDate) {
                            controlValue = this.getMinDateRecurring();
                            defaultValue = this.dateFormatterService.toString(controlValue);
                        }                        
                        if(fieldId === RoboFieldId.annualEscalationPercentage) {
                            controlValue = this.getDefaultYealyIncreaseValue();
                        }                        
                        changesMade = true;
                        control.setValue(controlValue, { emitEvent: false });
                        this.store.dispatch(
                            new RoboUpdateFieldAction({
                                fieldId: field.fieldId,
                                value: defaultValue,
                            })
                        );
                    }
                }
                // this is to cater for Save For Later - controls are not disabled initially
                if (!control.disabled  && ((fieldId === RoboFieldId.contributingToTaxFree) && ((this.hasExistingTfsa) || (this.hasInFlightTfsa)))) {
                    control.disable({ onlySelf: true, emitEvent: false });
                }                
                if (control.disabled && !((fieldId === RoboFieldId.contributingToTaxFree) && ((this.hasExistingTfsa) || (this.hasInFlightTfsa)))) {
                    control.enable({ onlySelf: true, emitEvent: false });
                }
                let oldValidators = Object.assign([], this.currentValidators[fieldId]);
                control.setValidators(this.getValidators(fieldId));
                if (this.haveValidatorsChanged(fieldId, oldValidators)) {
                    control.updateValueAndValidity();
                }
            } else {
                delete this.visibleFields[fieldId];

                if (control && control.enabled) {
                    control.disable({ onlySelf: true, emitEvent: false });
                }
            }
        }
        let mustAskForAdditionalLumpsums = !!requiredFields.find(field => field.fieldId === RoboFieldId.requestAdditionalLumpSums);
        let showingLumpsums = this.adviceForm.value.additionalLumpsumQuestion === 'true';
        if ((showingLumpsums && !mustAskForAdditionalLumpsums) || (!showingLumpsums && mustAskForAdditionalLumpsums)) {
            this.additionalLumpSumToggleChanged();
        }
        if (changesMade) {
            this.adviceForm.updateValueAndValidity();
        }
    }

    public getStringFieldValue(fieldId: RoboFieldId): string {
        const field = this.completedFields[fieldId];
        if (!field) {
            return null;
        }
        return field.value;
    }

    public getNumberFieldValue(fieldId: RoboFieldId): number {
        const value = this.getStringFieldValue(fieldId);
        if (value) {
            return +value;
        }
        return null;
    }

    public getBooleanFieldValue(fieldId: RoboFieldId): boolean {
        const value = this.getStringFieldValue(fieldId);
        if (value) {
            return value.toLowerCase() === 'true';
        }
        return null;
    }

    public getDateFieldValue(fieldId: RoboFieldId): NgbDateStruct {
        const value = this.getStringFieldValue(fieldId);
        if (value) {
            return this.dateFormatterService.forDatePickerFromString(value);
        }
        return null;
    }

    private getLumpsums(): FormArray {
        return this.adviceForm.get('additionalLumpsums') as FormArray;
    }

    public additionalLumpsumsChanged() {
        let lumpsumControls = this.getLumpsums();
        for (let control of lumpsumControls.controls) {
            this.validateDateControl(control.get('date'));
        }
        let lumpsums = (lumpsumControls.value as Array<{ amount; date }>).map(lumpsum => {
            return {
                ...lumpsum,
                date: this.dateFormatterService.toString(lumpsum.date),
            };
        });

        this.store.dispatch(new RoboAdditionalLumpsumsUpdated(lumpsums));

        if(lumpsumControls.length > 4) {
            this.showAddAnotherButton = false;
        } else {
            this.showAddAnotherButton = true;
        }        
    }

    public removeLumpSum(index) {
        let array = this.getLumpsums();
        array.removeAt(index);
        this.additionalLumpsumsChanged();
    }

    public addLumpSum() {
        let array = this.getLumpsums();
        array.push(
            this.formBuilder.group({
                amount: [
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.min(this.minForField(RoboFieldId.lumpSumAmount)),
                        Validators.max(this.maxForField(RoboFieldId.lumpSumAmount)),
                    ]),
                ],
                date: this.dateFormatterService.forDatePicker(moment().add(3, 'days')),
            })
        );
        this.additionalLumpsumsChanged();
    }

    public additionalLumpSumToggleChanged() {
        let value = this.adviceForm.value.additionalLumpsumQuestion;
        this.showAdditionalLumpsums = value === 'true';
        this.showAddAnotherButton = this.showAdditionalLumpsums;
        if (this.showAdditionalLumpsums && this.getLumpsums().length === 0) {
            this.addLumpSum();
        } else if (!this.showAdditionalLumpsums) {
            let lumpsumArray = this.adviceForm.get('additionalLumpsums') as FormArray;
            while (lumpsumArray.length !== 0) {
                lumpsumArray.removeAt(0);
            }
            this.store.dispatch(new RoboAdditionalLumpsumsUpdated([]));
        }
        if (!this.showField(RoboFieldId.requestAdditionalLumpSums)) {
            this.showAdditionalLumpsums = false;

            this.adviceForm.get('additionalLumpsumQuestion').setValue(false);

            this.store.dispatch(new RoboAdditionalLumpsumsUpdated([]));
        }
    }

    private createForm() {
        this.store
            .select(RoboStore.additionalLumpsums)
            .pipe(
                this.scavenger.collect(),
                take(1)
            )
            .subscribe(additionalLumpsums => {
                this.adviceForm = this.formBuilder.group({
                    goal: this.buildNumberControl(RoboFieldId.goal),
                    term: this.buildNumberControl(RoboFieldId.term),
                    goalName: this.buildStringControl(RoboFieldId.goalName),
                    targetValue: this.buildNumberControl(RoboFieldId.targetValue),
                    considerInflation: this.buildBooleanControl(RoboFieldId.considerInflation),
                    taxFreeContributionForCurrentTaxYear: this.buildNumberControl(RoboFieldId.taxFreeContributionForCurrentTaxYear),
                    contributingToTaxFree: this.buildBooleanControl(RoboFieldId.contributingToTaxFree),
                    fundingMethod: this.buildNumberControl(RoboFieldId.fundingMethod),

                    lumpSumAmount: this.buildNumberControl(RoboFieldId.lumpSumAmount),
                    lumpSumDate: this.buildDateControl(RoboFieldId.lumpSumDate),

                    withdrawalAccessType: this.buildNumberControl(RoboFieldId.withdrawalAccessType),

                    additionalLumpsumQuestion: false,
                    additionalLumpsums: this.formBuilder.array([]),

                    recurringContributionAmount: this.buildNumberControl(RoboFieldId.recurringContributionAmount),
                    recurringContributionStartDate: this.buildDateControl(RoboFieldId.recurringContributionStartDate),
                    recurringContributionFrequency: ['3'],
                    annualEscalationPercentage: this.buildNumberControl(RoboFieldId.annualEscalationPercentage),
                    requestAdditionalLumpSums: null,
                });

                this.adviceForm.valueChanges
                    .pipe(
                        debounceTime(DEBOUNCE_TIME),
                        this.scavenger.collect()
                    )
                    .subscribe(changes => this.checkForChanges(changes));

                this.adviceForm.statusChanges
                    .pipe(this.scavenger.collect())
                    .subscribe(status => this.store.dispatch(new AdviceFormStatusChangedAction(status)));

                if (additionalLumpsums.length > 0) {
                    let initialLumpsum = additionalLumpsums[0];
                    this.adviceForm.get('lumpSumAmount').setValue(initialLumpsum.amount, { emitEvent: false });
                    this.store.dispatch(
                        new RoboUpdateFieldAction({
                            fieldId: RoboFieldId.lumpSumAmount,
                            index: 0,
                            value: `${initialLumpsum.amount}`,
                        })
                    );

                    this.adviceForm
                        .get('lumpSumDate')
                        .setValue(this.dateFormatterService.forDatePicker(moment(initialLumpsum.date)), { emitEvent: false });
                    this.store.dispatch(
                        new RoboUpdateFieldAction({
                            fieldId: RoboFieldId.lumpSumDate,
                            index: 0,
                            value: `${initialLumpsum.date}`,
                        })
                    );
                    additionalLumpsums = additionalLumpsums.slice(1);

                    let array = this.getLumpsums();
                    additionalLumpsums.forEach(lumpsum =>
                        array.push(
                            this.formBuilder.group({
                                amount: lumpsum.amount,
                                date: this.dateFormatterService.forDatePicker(moment(lumpsum.date)),
                            })
                        )
                    );
                    this.adviceForm.get('additionalLumpsumQuestion').setValue(additionalLumpsums.length > 0 ? 'true' : 'false');

                    this.additionalLumpsumsChanged();
                    this.additionalLumpSumToggleChanged();

                    setTimeout(() => {
                        this.adviceForm.updateValueAndValidity();
                        this.store.dispatch(new AdviceFormStatusChangedAction(this.adviceForm.status));
                    }, 1000);
                }
            });
    }

    private buildNumberControl(fieldId: RoboFieldId) {
        return [this.getValueOrDefault(fieldId, id => this.getNumberFieldValue(id)), this.getValidators(fieldId)];
    }

    private buildBooleanControl(fieldId: RoboFieldId) {
        let valueOrDefault = this.getValueOrDefault(fieldId, id => this.getBooleanFieldValue(id));
        let valueShouldDefaultToNull = this.DEFAULT_NULL_BOOLEAN_FIELDS.find(id => id === fieldId);
        if (valueOrDefault === null && !valueShouldDefaultToNull) {
            valueOrDefault = false;
            this.store.dispatch(new RoboUpdateFieldAction({ fieldId, value: valueOrDefault }));
        }
        return [valueOrDefault, this.getValidators(fieldId)];
    }

    private buildStringControl(fieldId: RoboFieldId) {
        return [this.getValueOrDefault(fieldId, id => this.getStringFieldValue(id)), this.getValidators(fieldId)];
    }

    private buildDateControl(fieldId: RoboFieldId) {
        let valueOrDefault = this.getValueOrDefault(fieldId, id => this.getDateFieldValue(id));
        if (valueOrDefault === null) {
            valueOrDefault = this.dateFormatterService.forDatePicker(moment(this.minDate));
            this.store.dispatch(new RoboUpdateFieldAction({ fieldId, value: this.dateFormatterService.toString(valueOrDefault) }));
        }
        return [valueOrDefault, this.getDateValidators(fieldId)];
    }

    private getValueOrDefault(fieldId: RoboFieldId, getter: (fieldId: RoboFieldId) => any) {
        let existingValue = getter(fieldId);

        if (existingValue !== null && existingValue !== undefined) {
            return existingValue;
        }
        let fieldDetails = this.visibleFields[fieldId];
        if (fieldDetails && fieldDetails.defaultValue) {
            return fieldDetails.defaultValue;
        }
        return null;
    }

    private haveValidatorsChanged(fieldId: RoboFieldId, oldValidatorMetaData: ValidatorMetaData[]) {
        let currentValidators = this.currentValidators[fieldId];

        for (let validator of oldValidatorMetaData) {
            let matching = currentValidators.find(v => v.type === validator.type);
            if (!matching) {
                return true;
            }
        }

        for (let validator of currentValidators) {
            let matching = oldValidatorMetaData.find(v => v.type === validator.type);
            if (!matching) {
                return true;
            } else {
                if (!this.areEqual(matching.values, validator.values)) {
                    return true;
                }
            }
        }
        return false;
    }

    private areEqual(obj1, obj2): boolean {
        if (obj1 === obj2) {
            return true;
        }
        if (!obj1 || !obj2) {
            return false;
        }
        let aProps = Object.getOwnPropertyNames(obj1);
        let bProps = Object.getOwnPropertyNames(obj2);

        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let propName of aProps) {
            if (obj1[propName] !== obj2[propName]) {
                return false;
            }
        }
        return true;
    }

    private validateForWhiteSpace(control: AbstractControl): { whitespace: boolean } {
        if(control && control.value) {
            return control.value.trim() !== '' ? null : { whitespace: true };
        } else {
            return null;
        }
    }    

    private getValidators(fieldId: RoboFieldId) {
        let validators = [];
        let validatorsMetaData: ValidatorMetaData[] = [];
        if (this.OPTIONAL_FIELDS.indexOf(fieldId) < 0) {
            validators.push(Validators.required);
            validatorsMetaData.push({ type: 'required' });
        }
        let fieldDetails = this.visibleFields[fieldId];
        if (fieldDetails && fieldDetails.fieldRange) {
            if (fieldDetails.fieldRange.min) {
                validators.push(bigNumberMinValidator(fieldDetails.fieldRange.min));
                validatorsMetaData.push({ type: 'min', values: { min: fieldDetails.fieldRange.min } });
            }
            if (fieldDetails.fieldRange.max) {
                validators.push(bigNumberMaxValidator(fieldDetails.fieldRange.max));
                validatorsMetaData.push({ type: 'max', values: { min: fieldDetails.fieldRange.max } });
            }
        }
        if(fieldId === RoboFieldId.goalName) {
            validators.push(this.validateForWhiteSpace);
            validatorsMetaData.push({ type: 'whitespace'});
        }        
        this.currentValidators[fieldId] = validatorsMetaData;
        return Validators.compose(validators);
    }

    private getDateValidators(fieldId: RoboFieldId) {
        let validators = [];
        let validatorsMetaData: ValidatorMetaData[] = [];

        if (this.OPTIONAL_FIELDS.indexOf(fieldId) < 0) {
            validators.push(Validators.required);
        }

        validators.push(dateMinValidator(this.minDate, this.dateFormatterService));
        validatorsMetaData.push({ type: 'datemin', values: { min: this.minDate } });

        validators.push(dateMaxValidator(this.maxDate, this.dateFormatterService));
        validatorsMetaData.push({ type: 'datemax', values: { min: this.maxDate } });

        this.currentValidators[fieldId] = validatorsMetaData;
        return Validators.compose(validators);
    }

    private checkForChanges(changes) {
        this.adviceService.getAdvice();
    }

    private markControlsTouched(controlGroup: FormGroup) {
        if (controlGroup) {
            controlGroup.markAsTouched();
            if (controlGroup.controls) {
                for (const controlName of Object.keys(controlGroup.controls)) {
                    const control = controlGroup.get(controlName);
                    this.markControlsTouched(control as FormGroup);
                }
            }
        }
    }
}
