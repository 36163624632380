import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LocalTimeService } from 'shared/services/local-time.service';

const tPlusDaysMin = 2;
const maxLumpsumDaysInFuture = 60;

@Injectable()
export class ApplicationDateService {
    constructor(private timeService: LocalTimeService) {}

    public maxLumpsumDate() {
        //return this.timeService.now().add(maxLumpsumDaysInFuture, 'days');
        return this.timeService.now().add(tPlusDaysMin + maxLumpsumDaysInFuture, 'days'); // 60 days from T + 2
    }

    public maxAnnualContributionDate() {
        return this.timeService.now().add(365, 'days'); // T + one year
    }

    public cashDefaultLumpsumDate(): moment.Moment{
        return this.timeService
            .now()
            .startOf('day');        
    }

    public firstPossibleDebitDayOfMonth(): number {
        return this.firstPossibleDebitDate().get('date');
    }

    public firstPossibleDebitDate(): moment.Moment {
        return this.timeService
            .now()
            .startOf('day')
            .add(tPlusDaysMin, 'days');
    }

    public firstPossibleTFUTDebitDate(): moment.Moment {

        let t = this.timeService
                .now()
                .startOf('day')
                .add(tPlusDaysMin, 'days');

        if(t.get('date') > 26) {
            return this.timeService
            .now()
            .startOf('month')
            .add(1, 'month');            
        } else {
            return this.timeService
            .now()
            .startOf('day')
            .add(tPlusDaysMin, 'days');            
        }
    }       

    public calculateFirstDebitDate(userDebitDay: number = null): moment.Moment {
        if (!userDebitDay) {
            userDebitDay = this.timeService.now().get('date');
        }

        const earliestDebitDate = this.firstPossibleDebitDate();
        const userDebitDate = this.timeService.now().set('date', userDebitDay);

        if (this.timeService.now().get('date') > userDebitDay) {
            userDebitDate.add(1, 'month');
        }

        const firstDebitDate = this.timeService
            .now()
            .startOf('day')
            .set('date', userDebitDay);

        if (this.timeService.now().get('date') > userDebitDay) {
            firstDebitDate.add(1, 'month');
        }

        if (earliestDebitDate.isAfter(userDebitDate)) {
            firstDebitDate.add(1, 'month');
        }
        return firstDebitDate;
    }
}
