﻿import { AdviceRequest } from 'robo/models';

import { Customer } from './customer';
import { CustomerAgreement } from './customer-agreement';
import { CustomerConsent } from './customer-consent';
import { CustomerBankAccount } from './customer-bank-account';
import { DebitOrderDetails } from './debit-order-details';
import { LumpSumDetails } from './lump-sum-details';
import { RecordOfAdvice } from './record-of-advice';

export class ProductApplication {
    public applicationId: number;
    public portfolioTypeId: number;
    public fundId: number;
    public customerDetails: Customer = new Customer();
    public debitBankAccount: CustomerBankAccount = null;
    public debitOrderDetails: DebitOrderDetails = new DebitOrderDetails();
    public lumpSumDetails: LumpSumDetails[] = [new LumpSumDetails()];
    public recordOfAdvice: RecordOfAdvice = new RecordOfAdvice();
    public agreements: CustomerAgreement = new CustomerAgreement();
    public consents: CustomerConsent = new CustomerConsent();
    public adviceFields: AdviceRequest;
    public savedAdviceReference: string;
}
