import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { GeneralDocument, InvestorDocument } from 'investor/models';
import { DocumentRetrievalError, InvestorState, NewAccountDocumentsListAction, NewGeneralDocumentsListAction } from 'investor/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentContent } from 'shared/models';
import { Base64Service } from 'shared/services';

@Injectable()
export class DocumentsService {
    constructor(private http: HttpClient, private investorStore: Store<InvestorState>, private base64Service: Base64Service) {}

    public fetchGeneralDocuments() {
        this.http
            .get<GeneralDocument[]>(`${appConfig.apiUrl}/documents/general`)
            .subscribe(
                documents => this.investorStore.dispatch(new NewGeneralDocumentsListAction(documents)),
                error => this.investorStore.dispatch(new DocumentRetrievalError(error))
            );
    }

    public fetchAccountDocuments(accountNumber: string) {
        this.http
            .get<InvestorDocument[]>(`${appConfig.apiUrl}/documents/account/${accountNumber}`)
            .subscribe(
                documents => this.investorStore.dispatch(new NewAccountDocumentsListAction(documents)),
                error => this.investorStore.dispatch(new DocumentRetrievalError(error))
            );
    }

    public downloadDocument(documentId: string): Observable<DocumentContent> {
        return this.http
            .get<DocumentContent>(`${appConfig.apiUrl}/documents/download/${documentId}`)
            .pipe(map(document => Object.assign(document, { dataAsBytes: this.base64Service.decodeArrayBuffer(document.data) })));
    }
}
