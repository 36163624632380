/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./investor-documents.component";
var styles_InvestorDocumentsComponent = [];
var RenderType_InvestorDocumentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InvestorDocumentsComponent, data: {} });
export { RenderType_InvestorDocumentsComponent as RenderType_InvestorDocumentsComponent };
export function View_InvestorDocumentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 22, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "col-md-12 card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Documents"])), (_l()(), i0.ɵeld(3, 0, null, null, 16, "div", [["class", "col-12 fadeInUp animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 14, "ul", [["class", "nav nav-pills m-t-30 m-b-30 justify-content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "a", [["class", "nav-link"], ["routerLink", "account"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(8, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["Account documents"])), (_l()(), i0.ɵeld(12, 0, null, null, 6, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 5, "a", [["class", "nav-link"], ["routerLink", "general"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 671744, [[4, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(15, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["General Documents"])), (_l()(), i0.ɵeld(19, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [["class", "col-12 tab-content br-n pn"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(22, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_2 = "account"; _ck(_v, 7, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 8, 0, currVal_3); var currVal_6 = "general"; _ck(_v, 14, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 15, 0, currVal_7); _ck(_v, 22, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).target; var currVal_1 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵnov(_v, 14).target; var currVal_5 = i0.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_4, currVal_5); }); }
export function View_InvestorDocumentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-investor-documents", [], null, null, null, View_InvestorDocumentsComponent_0, RenderType_InvestorDocumentsComponent)), i0.ɵdid(1, 49152, null, 0, i3.InvestorDocumentsComponent, [], null, null)], null, null); }
var InvestorDocumentsComponentNgFactory = i0.ɵccf("wim-investor-documents", i3.InvestorDocumentsComponent, View_InvestorDocumentsComponent_Host_0, {}, {}, []);
export { InvestorDocumentsComponentNgFactory as InvestorDocumentsComponentNgFactory };
