import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

import { DateFormatterService } from '../services';

export function dateSubsetValidator(allowedDays: number[], dateFormatter: DateFormatterService): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        let date: number;
        let value = control.value;
        if (value && typeof value === 'object') {
            date = dateFormatter.asMoment(value).date();
        } else {
            date = moment(value).date();
        }

        if (allowedDays.find(allowedDay => allowedDay === date)) {
            return null;
        }

        return {
            subset: allowedDays,
        };
    };
}
