import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-loader',
    template: `
        <div class="loader-container" [hidden]="hidden">
            <div class="loader">
                <div class="loader__figure"></div>
                <p class="loader__label">Please wait</p>
            </div>
        </div>
    `,
    styles: [
        `
            .loader-container {
                background-color: white;
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 2000;
                opacity: 0.8;
            }
        `,
    ],
})
export class LoaderComponent {
    public hidden = true;
    constructor(store: Store<SharedState>) {
        store.select(fromShared.selectShowGlobalLoader).subscribe(showLoader => (this.hidden = !showLoader));
    }
}
