import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minIfValidator(min: number, ifCondition: () => boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const conditionTrue = ifCondition();
        let valid = true;
        if (conditionTrue) {
            valid = control.value !== null && control.value !== '' && +control.value >= min;
        }
        return valid ? null : { min: true };
    };
}
