import { DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { isNull } from 'util';

const PADDING = '000000';
const DECIMAL_SEPARATOR = '.';
const THOUSANDS_SEPARATOR = ' ';

@Injectable()
export class NumberFormatterService {
    constructor(private percentPipe: PercentPipe, private decimalPipe: DecimalPipe) {}
    // https://blog.ngconsultant.io/custom-input-formatting-with-simple-directives-for-angular-2-ec792082976
    // Splits thousands with a space
    public transformBigNumber(value: number | string, fractionSize: number = 2, zeroIfEmpty = false, includeDecimals = false): string {
        let [integer, fraction = ''] = (value || '').toString().split(DECIMAL_SEPARATOR);

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR);

        if (zeroIfEmpty) {
            integer = integer.length === 0 ? '0' : integer;
        }

        if (includeDecimals) {
            fraction = fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';
            integer = integer + fraction;
        }

        return integer;
    }

    public parseBigNumber(value: string, fractionSize: number = 2, includeDecimals = false): string {
        let [integer, fraction = ''] = (`${value}` || '').split(DECIMAL_SEPARATOR);

        integer = integer.replace(new RegExp(THOUSANDS_SEPARATOR, 'g'), '');

        if (includeDecimals) {
            fraction =
                parseInt(fraction, 10) > 0 && fractionSize > 0 ? DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize) : '';
            return integer + fraction;
        }
        return integer;
    }

    public transformDecimal(value: string, format: string) {
        return this.decimalPipe.transform(value, format);
    }

    public parseDecimal(value: string, format: string) {
        return this.decimalPipe.transform(value, format);
    }

    public parsePercent(value: string) {
        const percentStripped = value.replace(/%/g, '').trim();
        return `${+percentStripped}`;
    }

    public transformPercent(value: string, format: string, zeroIfEmpty = false) {
        return this.percentPipe.transform(`${+value / 100}`, format);
    }

    public parseNumberTextAppend(value: string, text: string, parser: (value: string) => string = null) {
        const regex = new RegExp(`${text}$`, 'g');
        const num = value.replace(regex, '').trim();
        if (parser) {
            return parser(num);
        }
        return num;
    }

    public transformNumberTextAppend(value: string, format = null, text = '', formatter: (value: string) => string = null) {
        if (formatter) {
            return `${formatter(value)}${text}`;
        }
        return `${this.decimalPipe.transform(value, format)}${text}`;
    }

    public parseNumberTextPrepend(value: string, text: string, parser: (value: string) => string = null) {
        const regex = new RegExp(`^${text}`, 'g');
        const num = value.replace(regex, '').trim();
        if (parser) {
            return parser(num);
        }
        return num;
    }

    public transformNumberTextPrepend(value: string, format = null, text = '', formatter: (value: string) => string = null) {
        if (formatter) {
            return `${text}${formatter(value)}`;
        }
        return `${text}${this.decimalPipe.transform(value, format)}}`;
    }

    public transformTerm(value) {
        let termYears = Math.floor(value / 12);
        let termMonths = value % 12;

        let months = 'Mnths';
        let years = 'Yrs';

        if (termYears < 2) {
            years = 'Yr';
        }
        if (termMonths === 1) {
            months = 'Mnth';
        }

        if (termMonths === 0) {
            return `${termYears} ${years}`;
        }
        return `${termYears} ${years}, ${termMonths} ${months}`;
    }

    public transformNoticePeriod(value) {
        if (value === 0 || isNull(value) || '' || !value) {
            return 'N/A';
        }
        return value;
    }
}
