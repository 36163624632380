var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { ApplicationValidity, Customer, ProductApplication } from 'application/models';
import { ApplicationDateService, ApplicationService } from 'application/services';
import { AppFormReferenceUpdated, AppFormSubmissionError, AppFormSubmitted, AppFormUpdatedCustomerAction, AppFormUpdatedDebitOrderDetailsAction, AppFormUpdatedPortfolioTypeAction, AppFormUpdatedRecordOfAdviceAction, ClearApplicationAction, CustomerSelectedAction, NewHorizonApplicationAction, } from 'application/store/application.reducers';
import * as fromApplication from 'application/store/application.store';
import { ResetPstStateAction } from 'pst/store/pst.reducers';
import * as fromPst from 'pst/store/pst.store';
import { AdviceEngineService } from 'robo/services/advice-engine.service';
import { RoboStore } from 'robo/store';
import { combineLatest } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { BaseComponent, SharedModalComponent } from 'shared/components';
import { DateFormatterService, LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var steps = new Map([
    [1, { visited: false, route: 'investmentdetails', validityKey: 'investmentDetailsValid' }],
    [2, { visited: false, route: 'termsandconditions', validityKey: 'tcsDetailsValid' }],
]);
var firstStep = 1;
var lastStep = 2;
var HorizonSeriesOnboardingWizardComponent = /** @class */ (function (_super) {
    __extends(HorizonSeriesOnboardingWizardComponent, _super);
    function HorizonSeriesOnboardingWizardComponent(applicationStore, pstStore, sharedStore, bankerStore, dateFormatter, router, loggerService, route, applicationService, lookupService, modalService, numberFormatter, store, adviceService, applicationDateService) {
        var _this = _super.call(this, loggerService) || this;
        _this.applicationStore = applicationStore;
        _this.pstStore = pstStore;
        _this.sharedStore = sharedStore;
        _this.bankerStore = bankerStore;
        _this.dateFormatter = dateFormatter;
        _this.router = router;
        _this.route = route;
        _this.applicationService = applicationService;
        _this.lookupService = lookupService;
        _this.modalService = modalService;
        _this.numberFormatter = numberFormatter;
        _this.store = store;
        _this.adviceService = adviceService;
        _this.applicationDateService = applicationDateService;
        _this.pstResult = null;
        _this.submissionError = false;
        _this.isCash = false;
        _this.termDisplay = '';
        _this.noticeDisplay = '';
        _this.accessDisplay = '';
        _this.recurringDisplayHeading = '';
        _this.pstAdvised = true;
        _this.isDirect = false;
        _this.step = firstStep;
        _this.sourceOfFundsTypes = [];
        _this.OK_TO_CANCEL = 'Yes Cancel';
        _this.products = new Map();
        _this.applicationSubmittedSuccessfully = false;
        _this.completedFields = null;
        _this.lookupService.getSourceOfFundsTypes().subscribe(function (types) { return (_this.sourceOfFundsTypes = types); });
        _this.applicationStore.dispatch(new ClearApplicationAction());
        _this.sharedStore.dispatch(new LoadingAction());
        var products$ = _this.lookupService.getPortfolioTypes();
        _this.selectedFund = _this.pstStore.select(fromPst.selectSelectedFund);
        _this.applicationStore.dispatch(new NewHorizonApplicationAction());
        _this.store.select(RoboStore.saveAdviceId)
            .subscribe(function (adviceId) { return (_this.savedAdviceId = adviceId); });
        _this.store
            .select(RoboStore.completedFields)
            .pipe(_this.scavenger.collect(), take(1))
            .subscribe(function (fields) { return (_this.completedFields = fields); });
        _this.applicationStore
            .select(fromApplication.selectApplicationFormValid)
            .pipe(_this.scavenger.collect())
            .subscribe(function (valid) { return (_this.appFormValidity = valid); });
        _this.applicationStore
            .select(fromApplication.selectApplicationSubmitted)
            .pipe(_this.scavenger.collect())
            .subscribe(function (submitted) { return (_this.applicationSubmittedSuccessfully = submitted); });
        _this.applicationStore
            .select(fromApplication.selectAppSubmissionError)
            .pipe(_this.scavenger.collect())
            .subscribe(function (err) { return (_this.submissionError = !!err); });
        _this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .pipe(filter(function (customer) { return !!customer; }), _this.scavenger.collect())
            .subscribe(function (customer) {
            _this.selectedCustomer = customer;
            _this.applicationStore.dispatch(new AppFormUpdatedCustomerAction({
                firstname: customer.firstname,
                lastname: customer.lastname,
                idNumber: customer.idNumber,
                age: customer.age,
                ucn: customer.ucn,
                title: customer.title,
            }));
        });
        _this.pstStore
            .select(fromPst.selectSelectedFund)
            .pipe(filter(function (fund) { return !!fund; }), _this.scavenger.collect())
            .subscribe(function (fund) {
            _this.applicationStore.dispatch(new AppFormUpdatedRecordOfAdviceAction({
                fundId: fund.fundId,
            }));
        });
        _this.applicationStore
            .select(fromApplication.selectHorizonApplication)
            .pipe(_this.scavenger.collect())
            .subscribe(function (appForm) {
            _this.applicationForm = appForm;
            _this.lumpsumsDisplayTotal = 0;
            var lumpsumsExist = _this.applicationForm &&
                _this.applicationForm.lumpSumDetails &&
                _this.applicationForm.lumpSumDetails.length > 0 &&
                _this.applicationForm.lumpSumDetails[0].amount;
            if (lumpsumsExist) {
                appForm.lumpSumDetails.forEach(function (lumpSum) {
                    _this.lumpsumsDisplayTotal = +_this.lumpsumsDisplayTotal + +lumpSum.amount;
                });
            }
        });
        var advisedPst$ = _this.pstStore.select(fromPst.selectAdvisedPst);
        var completedPst$ = _this.pstStore.select(fromPst.selectCompletedPst);
        _this.pstStore
            .select(fromShared.selectIsDirect)
            .pipe(_this.scavenger.collect())
            .subscribe(function (isDirect) { return (_this.isDirect = isDirect); });
        combineLatest(advisedPst$, completedPst$, products$, function (advisedPst, completedPst, products) {
            return { advisedPst: advisedPst, completedPst: completedPst, products: products };
        })
            .pipe(_this.scavenger.collect())
            .subscribe(function (_a) {
            var advisedPst = _a.advisedPst, completedPst = _a.completedPst, products = _a.products;
            _this.pstResult = completedPst;
            _this.sharedStore.dispatch(new StopLoadingAction());
            if (_this.pstResult === null) {
                _this.logger.warn('Cannot onboard before completing a PST, navigating to PST');
                _this.router.navigateByUrl('/secure/banker/pst');
                return;
            }
            products.forEach(function (product) {
                _this.products.set(product.id, product);
            });
            _this.product = _this.products.get(completedPst.portfolioTypeId);
            _this.transformNoticeAndAccessPeriod(_this.pstResult.accessType);
            _this.termDisplay = _this.transformTerm(_this.pstResult.term);
            _this.applicationService.createCase(_this.pstResult.reference, _this.selectedCustomer, _this.pstResult.pstAdvised);
            var payload = {};
            var debitOrder = 0;
            debitOrder = completedPst.clientInstallment;
            _this.pstAdvised = completedPst.pstAdvised;
            if (debitOrder > 0) {
                payload.debitOrderDetails = {
                    amount: debitOrder,
                    escalation: completedPst.contributionIncrease,
                    debitDate: completedPst.debitOrderDetails.debitDate,
                    firstDebitDate: completedPst.debitOrderDetails.firstDebitDate,
                    firstEscalationDate: completedPst.debitOrderDetails.firstEscalationDate,
                    paymentFrequencyId: completedPst.debitOrderDetails.paymentFrequencyId,
                };
            }
            if (!completedPst.pstAdvised) {
                var debitDay = _this.applicationDateService.firstPossibleDebitDayOfMonth();
                var firstDebitDate = _this.applicationDateService.calculateFirstDebitDate(debitDay);
                payload.debitOrderDetails = {
                    amount: 0,
                    escalation: 6,
                    debitDate: debitDay,
                    firstDebitDate: firstDebitDate,
                    firstEscalationDate: firstDebitDate.add(1, 'years').startOf('day'),
                };
            }
            if (!completedPst.lumpSumDetails) {
                var lumpsum = {
                    amount: 0,
                    debitDate: _this.applicationDateService.firstPossibleDebitDate(),
                };
                var array = [];
                array.push(lumpsum);
                payload.lumpSumDetails = array;
            }
            if (completedPst.lumpSumDetails) {
                if (completedPst.lumpSumDetails.length > 0 && completedPst.lumpSumDetails[0].amount > 0) {
                    payload.lumpSumDetails = {
                        amount: completedPst.lumpSum,
                        debitDate: _this.applicationDateService.firstPossibleDebitDate(),
                    };
                }
            }
            if (completedPst.portfolioTypeId !== appConfig.portfolioTypes.INV &&
                completedPst.portfolioTypeId !== appConfig.portfolioTypes.TFDS) {
                _this.isCash = true;
            }
            else {
                _this.isCash = false;
            }
            _this.setRecurringDisplayHeading(_this.pstResult);
            _this.applicationStore.dispatch(new AppFormUpdatedPortfolioTypeAction(completedPst.portfolioTypeId));
            _this.applicationStore.dispatch(new AppFormUpdatedDebitOrderDetailsAction(payload));
            _this.applicationStore.dispatch(new AppFormUpdatedRecordOfAdviceAction({
                goalName: completedPst.goalName,
                term: completedPst.term,
                goalAmount: completedPst.goalAmount,
                estimatedMaturity: completedPst.futureValue,
                pstEscalation: advisedPst.contributionIncrease ? advisedPst.contributionIncrease * 100 : 0,
                pstLumpsum: advisedPst.lumpSum,
                pstLumpsumDetails: advisedPst.lumpSumDetails,
                pstRecurring: advisedPst.clientInstallment,
                pstTarget: advisedPst.goalAmount,
                advisedInstallment: completedPst.advisedInstallment,
                noOtherTfsaConfirmed: completedPst.noOtherTfsaConfirmed,
                productDisclaimerAccepted: completedPst.productDisclaimerAccepted,
                projectedInterestRate: completedPst.projectedInterestRate,
                pstAccessType: advisedPst.accessType,
                pstNoticePeriod: advisedPst.noticePeriod,
                pstAdvised: completedPst.pstAdvised,
                advisedLumpSum: advisedPst.advisedLumpSum,
                advisedPortfolioTypeId: completedPst.advisedPortfolioTypeId,
                goalAmountAdjustedForInflation: completedPst.goalAmountAdjustedForInflation,
                affordabilityConfirmed: completedPst.affordabilityConfirmed,
            }));
        });
        return _this;
    }
    HorizonSeriesOnboardingWizardComponent.prototype.ngOnDestroy = function () { };
    HorizonSeriesOnboardingWizardComponent.prototype.canGoNext = function () {
        return this.applicationForm && this.isStepValid(this.step);
    };
    HorizonSeriesOnboardingWizardComponent.prototype.canSubmit = function () {
        return this.isValid();
    };
    HorizonSeriesOnboardingWizardComponent.prototype.goNext = function () {
        if (!this.canGoNext()) {
            return;
        }
        var step = this.step + 1;
        if (step >= lastStep) {
            step = lastStep;
        }
        this.navigateToStep(step);
    };
    HorizonSeriesOnboardingWizardComponent.prototype.goBack = function () {
        var step = this.step - 1;
        if (step <= firstStep) {
            step = firstStep;
        }
        this.navigateToStep(step);
    };
    HorizonSeriesOnboardingWizardComponent.prototype.transformTerm = function (value) {
        return this.numberFormatter.transformTerm(value);
    };
    HorizonSeriesOnboardingWizardComponent.prototype.transformNoticeAndAccessPeriod = function (value) {
        if (value === 4) {
            this.accessDisplay = 'End of Term';
            this.noticeDisplay = 'N/A';
        }
        else if (value === 5) {
            this.accessDisplay = 'Immediate';
            this.noticeDisplay = 'N/A';
        }
        else if (value === 6) {
            this.accessDisplay = 'With Notice';
            this.noticeDisplay = '32 Days';
        }
        else if (value === 7) {
            this.accessDisplay = 'With Notice';
            this.noticeDisplay = '7 Days';
        }
    };
    HorizonSeriesOnboardingWizardComponent.prototype.setRecurringDisplayHeading = function (res) {
        if (res.clientInstallment > 0) {
            if (this.isCash) {
                this.recurringDisplayHeading = 'Scheduled Transfer';
            }
            else {
                if (res.debitOrderDetails.paymentFrequencyId === 3) {
                    this.recurringDisplayHeading = 'Monthly contribution';
                }
                else if (res.debitOrderDetails.paymentFrequencyId === 7) {
                    this.recurringDisplayHeading = 'Annual contribution';
                }
            }
        }
        else {
            this.recurringDisplayHeading = 'Monthly contribution';
        }
    };
    HorizonSeriesOnboardingWizardComponent.prototype.cancel = function () {
        var _this = this;
        var warningRef = this.modalService.open(SharedModalComponent);
        warningRef.componentInstance.modalMessage = "Are you sure you want to cancel this Application?";
        warningRef.componentInstance.noValue = 'No';
        warningRef.componentInstance.yesValue = 'Yes, Cancel Application';
        warningRef.componentInstance.modalTitle = 'Confirm Application Cancellation';
        warningRef.result
            .then(function (resultType) {
            if (resultType === _this.OK_TO_CANCEL) {
                _this.cancelConfirm();
            }
            else {
                _this.logger.debug("modal closed");
            }
        }, function () {
            _this.logger.debug("modal dismissed");
        })
            .catch(function () {
            _this.logger.debug('Confirm modal dismissed');
        });
        this.modalRef = warningRef;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.cancelConfirm = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.applicationService
            .cancelApplication(this.applicationForm)
            .pipe(finalize(function () {
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.modalRef.dismiss();
            if (_this.isDirect) {
                _this.router.navigateByUrl('/invest');
            }
            else {
                _this.router.navigateByUrl('/secure/banker/customer-search');
                _this.bankerStore.dispatch(new CustomerSelectedAction(null));
            }
            _this.pstStore.dispatch(new ResetPstStateAction());
        }))
            .subscribe();
    };
    HorizonSeriesOnboardingWizardComponent.prototype.submit = function () {
        var _this = this;
        this.applicationForm.fundId = this.applicationForm.recordOfAdvice.fundId;
        this.applicationForm.adviceFields = {
            fields: this.adviceService.convertObjectToArrayOfFields(this.completedFields),
            contextualData: this.adviceService.getContextualData(),
        };
        if (this.canSubmit()) {
            this.sharedStore.dispatch(new LoadingAction());
            if (this.applicationForm.lumpSumDetails) {
                this.applicationForm.lumpSumDetails = this.applicationForm.lumpSumDetails
                    .filter(function (lumpsum) { return !!lumpsum.debitDate; })
                    .map(function (lumpSum) {
                    lumpSum.amount = +_this.numberFormatter.parseBigNumber("" + lumpSum.amount);
                    return lumpSum;
                });
            }
            if (this.applicationForm.debitOrderDetails) {
                this.applicationForm.debitOrderDetails.amount = +this.numberFormatter.parseBigNumber("" + this.applicationForm.debitOrderDetails.amount);
            }
            this.applicationForm.savedAdviceReference = this.savedAdviceId;
            this.applicationService.createParty(this.applicationForm).subscribe(function (result) {
                _this.applicationStore.dispatch(new AppFormSubmitted(true));
                _this.applicationStore.dispatch(new AppFormSubmissionError(null));
                _this.applicationStore.dispatch(new AppFormReferenceUpdated(result.reference));
                _this.router.navigate(['complete'], { relativeTo: _this.route });
                _this.sharedStore.dispatch(new StopLoadingAction());
            }, function (err) {
                _this.applicationStore.dispatch(new AppFormSubmitted(false));
                _this.applicationStore.dispatch(new AppFormSubmissionError(err));
                _this.router.navigate(['complete'], { relativeTo: _this.route });
                _this.sharedStore.dispatch(new StopLoadingAction());
            });
        }
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isFirstPage = function () {
        return this.step === firstStep;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isSecondPage = function () {
        return this.step === firstStep + 1;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isLastPage = function () {
        return this.step === lastStep;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.navigateToStep = function (step) {
        steps.get(this.step).visited = true;
        if (step === this.step) {
            return;
        }
        this.step = step;
        steps.get(this.step).visited = true;
        this.router.navigate([steps.get(this.step).route], { relativeTo: this.route });
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isDone = function (step) {
        return !this.isCurrent(step) && !!steps.get(step).visited;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.done = function () {
        this.router.navigateByUrl(this.isDirect ? '/secure/investor' : '/secure/banker/customer-search');
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isDisabled = function (step) {
        if (this.isCurrent(step) || this.isDone(step)) {
            return false;
        }
        return this.step !== step;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isCurrent = function (step) {
        return step === this.step;
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isStepValid = function (step) {
        return this.appFormValidity[steps.get(step).validityKey];
    };
    HorizonSeriesOnboardingWizardComponent.prototype.isValid = function () {
        return this.appFormValidity.investmentDetailsValid && this.appFormValidity.tcsDetailsValid;
    };
    return HorizonSeriesOnboardingWizardComponent;
}(BaseComponent));
export { HorizonSeriesOnboardingWizardComponent };
