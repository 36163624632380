import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { InvestmentAccount } from 'investor/models';
import { AccountsService, InstructionsService } from 'investor/services';
import { InstructionsAccountAction, InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-investor-instructions',
    templateUrl: 'investor-instructions.component.pug',
    styleUrls: ['investor-instructions.component.scss'],
})
export class InvestorInstructionsComponent extends BaseComponent implements OnInit, OnDestroy {
    public selectedAccount: InvestmentAccount = null;
    public investmentAccounts: InvestmentAccount[] = [];
    public hasHoldings = false;
    public isKyc = false;
    public form: FormGroup;

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private instructionService: InstructionsService,
        private accountService: AccountsService,
        loggerService: LoggerService
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());

        let investmentAccounts$ = this.investorStore.select(fromInvestor.selectInvestmentAccounts);
        let params$ = this.route.params;
        let user$ = this.sharedStore.select(fromShared.selectUser);

        combineLatest(investmentAccounts$, params$, user$, (accounts, routeParams, user) => {
            return { accounts, routeParams, user };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ accounts, routeParams, user }) => {
                this.createForm();
                this.isKyc = user.customerDetails.isKycVerified;
                if (!accounts) {
                    return;
                }
                this.investmentAccounts = accounts;

                if (routeParams && routeParams['accountNumber']) {
                    if (this.investmentAccounts && this.investmentAccounts.length > 0) {
                        this.form
                            .get('selectedAccount')
                            .setValue(this.investmentAccounts.filter(acct => acct.accountNumber === routeParams['accountNumber'])[0]);

                        this.investorStore.dispatch(new InstructionsAccountAction(this.selectedAccount.accountNumber));
                        this.checkForValidHoldings();
                    }
                }
                this.sharedStore.dispatch(new StopLoadingAction());
            });
        this.accountService.fetchAccounts();
    }

    public ngOnDestroy(): void {}

    public selectedAccountChanged(account: InvestmentAccount) {
        this.selectedAccount = account;
        const accountNumber = this.selectedAccount ? this.selectedAccount.accountNumber : null;
        this.investorStore.dispatch(new InstructionsAccountAction(accountNumber));
        this.checkForValidHoldings();
    }

    private createForm() {
        this.form = this.formBuilder.group({
            selectedAccount: [null, Validators.required],
        });
        this.form.valueChanges.subscribe(changes => {
            this.selectedAccountChanged(changes.selectedAccount);
        });
    }

    private checkForValidHoldings() {
        this.hasHoldings =
            this.selectedAccount.accountPortfolios.filter(portfolioItem => this.instructionService.canInstructOnAccountItem(portfolioItem))
                .length > 0;
    }
}
