import { JwtToken } from './jwt-token';
import { User } from './user';

export interface BankerLoginResult {
    authenticated: boolean;
    user: User;
    reason: AuthenticationFailedReason;
    token: JwtToken;
}

export enum AuthenticationFailedReason {
    Success = 0,
    NotOnNetwork = 1,
    InvalidADToken = 2,
    NotBanker = 3,
    InvalidUsernameOrPassword = 4
}

export function getHumanReadableFailureReason(reason: AuthenticationFailedReason): string {
    switch (reason) {
        case AuthenticationFailedReason.InvalidUsernameOrPassword:
            return 'Invalid username or password, please try again';
        case AuthenticationFailedReason.NotOnNetwork:
            return 'This application is only available from the Firstrand network';
        case AuthenticationFailedReason.NotBanker:
            return 'You do not have permission to access this application';
        default:
            return 'You could not be logged in, please try again';
    }
}
