import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { ChartModule } from 'angular-highcharts';
import { ApplicationModule } from 'application/application.module';
import { PstRoutingModule } from 'pst/pst-routing.module';
import { reducers } from 'pst/store/pst.reducers';

import { SharedModule } from '../shared/shared.module';

import {
    CashResultComponent,
    ContextComponent,
    CurrencyComponent,
    InvestmentAccountResultComponent,
    MessageComponent,
    MultipleChoiceComponent,
    NumberComponent,
    PstNavigationComponent,
    PstWizardComponent,
    TermComponent,
    TermYearsComponent,
    TextComponent,
    UserTypeContextService,
} from './components';
import { PstContentModalComponent } from './components/content-modal/pst-content-modal.component';
// import { DisclaimerModalComponent } from './components/disclaimer-modal/disclaimer-modal.component';
import { InsertStepDirective } from './directives/insert-step.directive';
import {
    CashCalculator,
    CashProjectionService,
    InvestmentAccountCalculator,
    PstParserService,
    PstService,
    PstTfsaValidator,
} from './services';

const MODULE_COMPONENTS = [
    ContextComponent,
    CurrencyComponent,
    MessageComponent,
    MultipleChoiceComponent,
    NumberComponent,
    PstNavigationComponent,
    PstWizardComponent,
    CashResultComponent,
    InvestmentAccountResultComponent,
    TextComponent,
    TermComponent,
    TermYearsComponent,
    PstContentModalComponent,
    // DisclaimerModalComponent,
];

const MODULE_SERVICES = [
    UserTypeContextService,
    CashCalculator,
    CashProjectionService,
    InvestmentAccountCalculator,
    PstParserService,
    PstService,
    PstTfsaValidator,
    UserTypeContextService,
];

const MODULE_DIRECTIVES = [InsertStepDirective];

@NgModule({
    entryComponents: [
        MultipleChoiceComponent,
        CurrencyComponent,
        NumberComponent,
        InvestmentAccountResultComponent,
        CashResultComponent,
        TextComponent,
        MessageComponent,
        TermComponent,
        ContextComponent,
        PstContentModalComponent,
        TermYearsComponent,
        // DisclaimerModalComponent,
    ],
    imports: [
        CommonModule,
        NgbModalModule,
        RouterModule,
        PstRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        ChartModule,
        NgxErrorsModule,
        SharedModule,
        ApplicationModule,
        StoreModule.forFeature('pst', reducers),
    ],
    declarations: [...MODULE_COMPONENTS, ...MODULE_DIRECTIVES],
    exports: [PstWizardComponent],
    providers: [...MODULE_SERVICES],
})
export class PstModule {}
