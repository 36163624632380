import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PstIcon, PstTooltip } from 'pst/models';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';

import { PstContentModalComponent } from './content-modal/pst-content-modal.component';

export abstract class BasePstComponent extends BaseComponent {
    public tooltip: PstTooltip = null;
    public icons: PstIcon[] = [];

    constructor(loggerService: LoggerService, protected modalService: NgbModal) {
        super(loggerService);
    }

    protected initContent(icons: PstIcon[], tooltips: PstTooltip[], nodeId: number) {
        this.icons = icons;
        this.tooltip = tooltips.find(tip => tip.nodeId === nodeId);
    }

    public isIcon(nodeId) {
        return !!this.icons.find(i => i.nodeId === nodeId);
    }

    public iconPath(nodeId: number): string {
        if (!this.isIcon(nodeId)) {
            return;
        }
        let icon = this.icons.find(i => i.nodeId === nodeId);
        return `assets/icon/pst/${icon.iconName}.svg`;
    }

    public isTooltip() {
        return !!this.tooltip;
    }

    public showTooltip() {
        if (!this.isTooltip()) {
            return;
        }
        let content = this.tooltip.tooltip;
        const modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content;
    }

    public measureTextWidth(text: string) {
        let font = '40px fnb_sanslight';
        let div = document.createElement('div');
        div.setAttribute('style', `display:inline-block; opacity:0; font:${font}; white-space:no-wrap;`);
        div.innerText = text;
        let body = document.querySelector('body');
        body.appendChild(div);
        let width = div.clientWidth;
        div.remove();
        return width;
    }
}
