import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components/base';
import { BankerLoginResult, Brand, getHumanReadableFailureReason } from 'shared/models';
import { AuthenticationService } from 'shared/services/authentication.service';
import { LoggerService } from 'shared/services/logger.service';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-banker-login',
    templateUrl: 'banker-login.component.pug',
    styleUrls: ['banker-login.component.scss'],
})
export class BankerLoginComponent extends BaseComponent implements OnDestroy {
    public username: string;
    public password: string;
    public errorMessage: string;
    public currentStep = 1;
    public currentInstance: string;
    public showInvestorLogin = appConfig.env !== 'prd' && appConfig.env !== 'qa';
    public loginAllowed$;
    public loginForm: FormGroup;

    constructor(
        private authService: AuthenticationService,
        private store: Store<SharedState>,
        private router: Router,
        private formBuilder: FormBuilder,
        route: ActivatedRoute,
        loggerService: LoggerService
    ) {
        super(loggerService);

        this.loginAllowed$ = this.store.select(fromShared.selectBankerLoginEnabled);

        const adLoginInProgress$ = this.store.select(fromShared.selectLoginInProgress);
        const apiLoginInProgress$ = this.store.select(fromShared.selectApiLoginInProgress);

        const showLoader$ = combineLatest(adLoginInProgress$, apiLoginInProgress$, (adLoginInProgress, apiLoginInProgress) => {
            return { adLoginInProgress, apiLoginInProgress };
        }).subscribe(({ adLoginInProgress, apiLoginInProgress }) => {
            const showLoader = adLoginInProgress || apiLoginInProgress;
            if (!showLoader) {
                this.store.dispatch(new StopLoadingAction());
            }
        });

        this.registerSubscriptions(
            showLoader$,
            this.store.select(fromShared.selectAuthenticated).subscribe(loggedIn => this.authenticatedChanged(loggedIn)),

            this.store.select(fromShared.selectBrand).subscribe(k => this.setBrand(k)),

            this.store.select(fromShared.selectBankerLoginError).subscribe(reason => {
                if (reason) {
                    const reasonEnum = (reason as BankerLoginResult).reason;
                    if (reasonEnum) {
                        this.errorMessage = getHumanReadableFailureReason(reasonEnum);
                    } else {
                        this.errorMessage = 'Something went wrong while logging in.';
                    }
                } else {
                    this.errorMessage = '';
                }
            })
        );

        this.createForm();
    }

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }

    public login() {
        this.store.dispatch(new LoadingAction());
        this.authService.bankerLogin(this.loginForm.value.username, this.loginForm.value.password);
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    private authenticatedChanged(loggedIn: boolean) {
        if (loggedIn) {
            this.router.navigateByUrl('/secure/banker');
        }
    }
    private setBrand(brand) {
        switch (brand) {
            case Brand.RMB: {
                this.currentInstance = 'RMB';
                break;
            }
            default: {
                this.currentInstance = 'FNB';
                break;
            }
        }
    }
}
