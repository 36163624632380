var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseComponent } from 'shared/components';
import { PstContentModalComponent } from './content-modal/pst-content-modal.component';
var BasePstComponent = /** @class */ (function (_super) {
    __extends(BasePstComponent, _super);
    function BasePstComponent(loggerService, modalService) {
        var _this = _super.call(this, loggerService) || this;
        _this.modalService = modalService;
        _this.tooltip = null;
        _this.icons = [];
        return _this;
    }
    BasePstComponent.prototype.initContent = function (icons, tooltips, nodeId) {
        this.icons = icons;
        this.tooltip = tooltips.find(function (tip) { return tip.nodeId === nodeId; });
    };
    BasePstComponent.prototype.isIcon = function (nodeId) {
        return !!this.icons.find(function (i) { return i.nodeId === nodeId; });
    };
    BasePstComponent.prototype.iconPath = function (nodeId) {
        if (!this.isIcon(nodeId)) {
            return;
        }
        var icon = this.icons.find(function (i) { return i.nodeId === nodeId; });
        return "assets/icon/pst/" + icon.iconName + ".svg";
    };
    BasePstComponent.prototype.isTooltip = function () {
        return !!this.tooltip;
    };
    BasePstComponent.prototype.showTooltip = function () {
        if (!this.isTooltip()) {
            return;
        }
        var content = this.tooltip.tooltip;
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content;
    };
    BasePstComponent.prototype.measureTextWidth = function (text) {
        var font = '40px fnb_sanslight';
        var div = document.createElement('div');
        div.setAttribute('style', "display:inline-block; opacity:0; font:" + font + "; white-space:no-wrap;");
        div.innerText = text;
        var body = document.querySelector('body');
        body.appendChild(div);
        var width = div.clientWidth;
        div.remove();
        return width;
    };
    return BasePstComponent;
}(BaseComponent));
export { BasePstComponent };
