import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';

@Component({
    selector: 'wim-about-us',
    templateUrl: 'about-us.component.pug',
})
export class AboutUsComponent extends BaseComponent {
    constructor(private loggerService: LoggerService, private modalService: NgbModal) {
        super(loggerService);
    }

    public contactUs() {
        const modalRef = this.modalService.open(ContactBankerModalComponent, { windowClass: 'large-modal' });
    }

    public ngOnDestroy() {}
}
