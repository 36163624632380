import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger, LoggerService, RequestInspectionService } from 'shared/services';
import { SharedState } from 'shared/store';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    private logger: Logger;

    constructor(loggerService: LoggerService) {
        this.logger = loggerService.getLogger('ServerErrorInterceptor');
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((httpError: HttpErrorResponse) => {
                // 401 and 403 are handled by the LoggedOutInterceptor
                if (httpError.status >= 300 && httpError.status !== 401 && httpError.status !== 403) {
                    this.logger.error(`${httpError.url} - ${httpError.status} - ${httpError.statusText}`);
                }
                return throwError(httpError);
            })
        );
    }
}
