import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, takeLast } from 'rxjs/operators';
import { LookupDataService } from 'shared/services';

@Pipe({ name: 'addVat' })
export class AddVatPipe implements PipeTransform {
    private vatRate: Observable<number>;
    constructor(private lookupService: LookupDataService) {
        this.vatRate = lookupService.getVATRate();
    }

    public transform(value: number): Observable<number> {
        return this.vatRate.pipe(
            takeLast(1),
            map(vat => value * (1 + vat))
        );
    }
}
