import { Injectable } from '@angular/core';

@Injectable()
export class ServerTimeService {
    private offset = 0;

    public setServerTime(dateHeader: string) {
        if (!dateHeader) {
            return;
        }
        let serverTimeMillisGMT = Date.parse(new Date(Date.parse(dateHeader)).toUTCString());
        let localMillisUTC = Date.parse(new Date().toUTCString());
        this.offset = serverTimeMillisGMT - localMillisUTC;
    }

    public getServerTime(): Date {
        let date = new Date();
        date.setTime(date.getTime() + this.offset);
        return date;
    }
}
