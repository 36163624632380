var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components/base';
import { Brand, getHumanReadableFailureReason } from 'shared/models';
import { AuthenticationService } from 'shared/services/authentication.service';
import { LoggerService } from 'shared/services/logger.service';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var BankerLoginComponent = /** @class */ (function (_super) {
    __extends(BankerLoginComponent, _super);
    function BankerLoginComponent(authService, store, router, formBuilder, route, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.authService = authService;
        _this.store = store;
        _this.router = router;
        _this.formBuilder = formBuilder;
        _this.currentStep = 1;
        _this.showInvestorLogin = appConfig.env !== 'prd' && appConfig.env !== 'qa';
        _this.loginAllowed$ = _this.store.select(fromShared.selectBankerLoginEnabled);
        var adLoginInProgress$ = _this.store.select(fromShared.selectLoginInProgress);
        var apiLoginInProgress$ = _this.store.select(fromShared.selectApiLoginInProgress);
        var showLoader$ = combineLatest(adLoginInProgress$, apiLoginInProgress$, function (adLoginInProgress, apiLoginInProgress) {
            return { adLoginInProgress: adLoginInProgress, apiLoginInProgress: apiLoginInProgress };
        }).subscribe(function (_a) {
            var adLoginInProgress = _a.adLoginInProgress, apiLoginInProgress = _a.apiLoginInProgress;
            var showLoader = adLoginInProgress || apiLoginInProgress;
            if (!showLoader) {
                _this.store.dispatch(new StopLoadingAction());
            }
        });
        _this.registerSubscriptions(showLoader$, _this.store.select(fromShared.selectAuthenticated).subscribe(function (loggedIn) { return _this.authenticatedChanged(loggedIn); }), _this.store.select(fromShared.selectBrand).subscribe(function (k) { return _this.setBrand(k); }), _this.store.select(fromShared.selectBankerLoginError).subscribe(function (reason) {
            if (reason) {
                var reasonEnum = reason.reason;
                if (reasonEnum) {
                    _this.errorMessage = getHumanReadableFailureReason(reasonEnum);
                }
                else {
                    _this.errorMessage = 'Something went wrong while logging in.';
                }
            }
            else {
                _this.errorMessage = '';
            }
        }));
        _this.createForm();
        return _this;
    }
    BankerLoginComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    BankerLoginComponent.prototype.login = function () {
        this.store.dispatch(new LoadingAction());
        this.authService.bankerLogin(this.loginForm.value.username, this.loginForm.value.password);
    };
    BankerLoginComponent.prototype.createForm = function () {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    };
    BankerLoginComponent.prototype.authenticatedChanged = function (loggedIn) {
        if (loggedIn) {
            this.router.navigateByUrl('/secure/banker');
        }
    };
    BankerLoginComponent.prototype.setBrand = function (brand) {
        switch (brand) {
            case Brand.RMB: {
                this.currentInstance = 'RMB';
                break;
            }
            default: {
                this.currentInstance = 'FNB';
                break;
            }
        }
    };
    return BankerLoginComponent;
}(BaseComponent));
export { BankerLoginComponent };
