import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { BigNumberPipe } from 'shared/pipes';
import { NumberFormatterService } from 'shared/services/number-formatter.service';

@Directive({ selector: '[wimBigNumberFormatter]' })
export class BigNumberFormatterDirective implements OnInit {
    @Input()
    public zeroIfEmpty = false;

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef, private formatterService: NumberFormatterService) {
        this.el = this.elementRef.nativeElement;
    }

    public ngOnInit() {
        this.el.value = this.formatterService.transformBigNumber(this.el.value);
    }

    @HostListener('focus', ['$event.target.value'])
    public onFocus(value) {
        this.el.value = this.formatterService.parseBigNumber(value);
        this.el.type = 'number';
    }

    @HostListener('blur', ['$event.target.value'])
    public onBlur(value) {
        this.el.type = 'text';
        this.el.value = this.formatterService.transformBigNumber(this.el.value, null, this.zeroIfEmpty);
    }
}
