import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvestorHelperHideAction } from 'shared/store/layout.reducers';

import { StopLoadingAction } from './global-loader.reducers';

@Injectable()
export class LayoutEffects {
    @Effect()
    public hideInvestorHelperOnRouteChange$: Observable<Action> = this.actions$.ofType(ROUTER_NAVIGATION).pipe(
        map(_ => new InvestorHelperHideAction()),
        //map(_ => new StopLoadingAction())
    );
    constructor(private actions$: Actions) {}
}
