var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Scavenger } from '@wishtack/rx-scavenger';
var BaseLoggingClass = /** @class */ (function () {
    function BaseLoggingClass(loggerService) {
        this.logger = loggerService.getLogger(this.constructor['name']);
    }
    return BaseLoggingClass;
}());
export { BaseLoggingClass };
var BaseSubscriptionClass = /** @class */ (function (_super) {
    __extends(BaseSubscriptionClass, _super);
    function BaseSubscriptionClass(loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.subscriptions = [];
        _this.scavenger = new Scavenger(_this);
        return _this;
    }
    BaseSubscriptionClass.prototype.registerSubscriptions = function () {
        var sub = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            sub[_i] = arguments[_i];
        }
        var _a;
        (_a = this.subscriptions).push.apply(_a, sub);
    };
    BaseSubscriptionClass.prototype.cleanUpSubscriptions = function () {
        this.logger.debug("Cleaning up " + this.subscriptions.length + " Observable subscriptions");
        if (this.subscriptions && this.subscriptions.length > 0) {
            // tslint:disable-next-line:prefer-const
            for (var _i = 0, _a = this.subscriptions; _i < _a.length; _i++) {
                var sub = _a[_i];
                if (sub) {
                    sub.unsubscribe();
                }
            }
        }
    };
    return BaseSubscriptionClass;
}(BaseLoggingClass));
export { BaseSubscriptionClass };
var BaseComponent = /** @class */ (function (_super) {
    __extends(BaseComponent, _super);
    function BaseComponent(loggerService) {
        return _super.call(this, loggerService) || this;
    }
    return BaseComponent;
}(BaseSubscriptionClass));
export { BaseComponent };
var BaseService = /** @class */ (function (_super) {
    __extends(BaseService, _super);
    function BaseService(loggerService) {
        return _super.call(this, loggerService) || this;
    }
    return BaseService;
}(BaseSubscriptionClass));
export { BaseService };
