import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import {
    AccountPortfolios,
    InstructionAllocation,
    InstrumentAllocationBasis,
    InvestmentAccount,
    LumpSumInstruction,
} from 'investor/models';
import { InstructionsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { combineLatest } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { CustomerBankAccount } from 'application/models';
import { finalize } from 'rxjs/operators';
import { EacModalComponent } from 'shared/components';
import { User } from 'shared/models';
import { SourceOfFundsType } from 'shared/models/source-of-funds-type';
import { DateFormatterService, LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { dateMaxValidator, dateMinValidator } from 'shared/validators';

@Component({
    selector: 'wim-instruction-lumpsum',
    templateUrl: 'lumpsum-instruction.component.pug',
    styleUrls: ['lumpsum-instruction.component.scss'],
})
export class LumpSumInstructionComponent extends BaseInstructionComponent implements OnInit, OnDestroy {
    private modalTitle = 'Lumpsum Investment';

    public allFunds: AccountPortfolios[];
    public debitAgreement: string;
    public instructionForm: FormGroup;
    public hasHoldings = false;
    public isKyc = false;
    public existingInstructions: LumpSumInstruction[] = [];
    public viewExisting = false;

    public errorMessage: string;

    public sourceOfFundsTypes: SourceOfFundsType[];
    public userBankAccounts: CustomerBankAccount[];
    public totalAllocation = 0;
    public totalValueAllocation = 0;
    public selectedAccountNumber: string;

    public minLumpSum = 2000;
    public maxLumpSum = 1000000;

    private user: User;
    private selectedAccount: InvestmentAccount;

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private instructionService: InstructionsService,
        private lookupService: LookupDataService,
        modalService: NgbModal,
        private formBuilder: FormBuilder,
        private decimalPipe: DecimalPipe,
        toastr: ToastrService,
        loggerService: LoggerService,
        private dateFormatter: DateFormatterService
    ) {
        super(toastr, loggerService, modalService);
    }

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());
        this.viewExisting = false;
        this.createForm(undefined);

        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(user => {
                this.user = user;
                this.isKyc = user.customerDetails.isKycVerified;
                this.userBankAccounts = user.bankAccounts;
                if (user.bankAccounts.length >= 1) {
                    this.instructionForm.get('bankAccount').setValue(user.bankAccounts[0]);
                }
            });

        this.lookupService
            .getSourceOfFundsTypes()
            .pipe(this.scavenger.collect())
            .subscribe(types => (this.sourceOfFundsTypes = types));

        this.lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(funds => {
                if (funds && funds.length > 0) {
                    this.allFunds = funds.map(fund => {
                        return {
                            fundName: fund.name,
                            fundCode: fund.pstCode.split(' ')[0],
                            percentageOfTotal: 0,
                            totalValue: 0,
                            lastPriceDate: null,
                            units: 0,
                            unitPrice: 0,
                            factSheetUrl: fund.factSheetUrl,
                            ter: fund.ter,
                            transactionCosts: fund.transactionCosts,
                            adviceFee: fund.adviceFee,
                            adminFee: fund.adminFee,
                            assetClass: fund.assetClass,
                        };
                    });
                } else {
                    this.allFunds = [];
                }
            });

        combineLatest(
            this.investorStore.select(fromInvestor.selectInvestmentAccounts),
            this.investorStore.select(fromInvestor.selectSelectedInstructionAccount),
            (accounts, selectedAccount) => {
                return { accounts, selectedAccount };
            }
        )
            .pipe(this.scavenger.collect())
            .subscribe(({ accounts, selectedAccount }) => {
                if (!accounts) {
                    return;
                }
                this.selectedAccountNumber = selectedAccount;
                this.sharedStore.dispatch(new StopLoadingAction());
                this.selectedAccount = accounts.find(acct => {
                    return acct.accountNumber === this.selectedAccountNumber;
                });

                this.lookupService.getAgreementContent(this.selectedAccount.portfolioTypeId).subscribe(content => {
                    this.debitAgreement = content.filter(agg => agg.isDebitOrder)[0].summary;
                });

                this.addFundHoldings();
            });
    }

    public ngOnDestroy(): void {}

    public adhocAmountUpdated() {
        this.computeAllocationTotal();
    }

    public computeAllocationTotal() {
        let allocations = this.instructionForm.get('allocations') as FormArray;
        let lumpsumAmount = +this.instructionForm.get('adHocAmount').value;
        if (lumpsumAmount === 0) {
            return;
        }
        for (let allocationControl of allocations.controls) {
            let percentValue = allocationControl.get('percentageOfTotal').value;
            percentValue = isNaN(percentValue) ? 0 : percentValue;
            let randValue = (lumpsumAmount * percentValue) / 100.0;
            allocationControl.get('valueAllocation').setValue(randValue, { emitEvent: false });
        }
        this.totalAllocation = this.instructionForm.get('allocations').value.reduce((a, b) => a + b.percentageOfTotal, 0);
        this.totalValueAllocation = this.instructionForm.get('allocations').value.reduce((a, b) => a + b.valueAllocation, 0);
    }

    public computeValueAllocationTotal() {
        let allocations = this.instructionForm.get('allocations') as FormArray;
        let lumpsumAmount = +this.instructionForm.get('adHocAmount').value;
        if (lumpsumAmount === 0) {
            return;
        }
        for (let allocationControl of allocations.controls) {
            let randValue = allocationControl.get('valueAllocation').value;
            randValue = isNaN(randValue) ? 0 : randValue;
            let percentAllocation = Math.round((randValue / lumpsumAmount) * 10000.0) / 100.0;
            allocationControl.get('percentageOfTotal').setValue(percentAllocation, { emitEvent: false });
        }
        this.totalAllocation = this.instructionForm.get('allocations').value.reduce((a, b) => a + b.percentageOfTotal, 0);
        this.totalValueAllocation = this.instructionForm.get('allocations').value.reduce((a, b) => a + b.valueAllocation, 0);
    }

    public canSubmit() {
        return this.totalValueAllocation === +this.instructionForm.get('adHocAmount').value && this.instructionForm.valid;
    }

    public mayInstruct() {
        return this.hasHoldings && this.isKyc;
    }

    public cancel() {
        this.instructionForm.reset();
        this.addFundHoldings();
        this.setDefaults();
    }

    public submit() {
        if (this.canSubmit()) {
            const formData = this.instructionForm.value;
            this.sharedStore.dispatch(new LoadingAction());
            const payLoad: LumpSumInstruction = {
                accountNumber: this.selectedAccountNumber,
                bankAccountNumber: formData.bankAccount.accountNumber,
                investmentAmount: formData.adHocAmount,
                isDebitAuthorised: formData.debitOrderAgreed,
                sourceOfFundName: formData.sourceOfFunds.description,
                sourceOfFundOther: formData.otherSourceOfFund,
                sourceOfFundsTypeId: formData.sourceOfFunds.id,
                ucn: this.user.customerDetails.ucn,
                caseTypeId: 0,
                investmentAllocations: this.getLumpSumAllocations(),
                debitDate: this.dateFormatter.forApi(formData.debitDate),
                existingInstructionId: formData.existingInstructionId,
                bankAccountHolder: this.user.displayName,
                bankAccountType: formData.bankAccount.accountType,
            };
            this.instructionService
                .submitLumpSumInstruction(payLoad)
                .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
                .subscribe(result => this.handleResponse(result, this.modalTitle), () => this.handleSubmitError(this.modalTitle));
        } else {
            this.markAsTouched(this.instructionForm);
        }
    }

    public calculateEac() {
        const eacRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        const account = {
            funds: this.instructionForm.get('allocations').value.map(accountFund => {
                return {
                    allocation: accountFund.percentageOfTotal / 100,
                    fundCode: accountFund.fundCode.replace('ZA', '').trim(),
                };
            }),
        };
        eacRef.componentInstance.account = account;
    }

    public getLumpSumAllocations() {
        const _allocations: InstructionAllocation[] = [];
        this.instructionForm.get('allocations').value.forEach(allocation => {
            _allocations.push({
                fundCode: allocation.fundCode,
                fundName: allocation.fundName,
                allocatedAmount: allocation.valueAllocation,
                allocatedPercent: allocation.percentageOfTotal,
                allocatedUnits: null,
                allocationBasis: InstrumentAllocationBasis.currencyValue,
            });
        });
        return _allocations;
    }

    public showTable() {
        return this.instructionForm.get('allocations').value.length > 0;
    }

    public isSourceOfFundsOther(id) {
        if (!this.sourceOfFundsTypes || !id) {
            return false;
        }
        const sourceType = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return sourceType && sourceType.isOther;
    }

    public loadInstruction(instruction: LumpSumInstruction) {
        this.createForm(instruction);
        this.addFundHoldings();
        this.setExistingValues(instruction.bankAccountNumber, instruction.sourceOfFundName, instruction.existingInstructionId);
        this.viewExisting = false;
    }

    public backToExisting() {
        this.viewExisting = true;
    }

    public createNew() {
        this.createForm(undefined);
        this.addFundHoldings();
        this.viewExisting = false;
    }

    private getMinDebitDate() {
        return moment()
            .add(3, 'days')
            .startOf('day');
    }

    private getMaxDebitDate() {
        return moment()
            .add(1, 'years')
            .startOf('day');
    }

    private setDefaults() {
        this.totalAllocation = 0;
        this.instructionForm.get('adHocAmount').setValue(0);
        this.instructionForm.get('debitDate').setValue(this.dateFormatter.forDatePicker(this.getMinDebitDate()));
        this.instructionForm.get('otherSourceOfFund').setValue('');
        this.instructionForm.get('debitOrderAgreed').setValue(false);
    }

    private createForm(instruction: LumpSumInstruction) {
        const ok = !!instruction;
        const minDebitDate = ok ? moment(instruction.debitDate) : this.getMinDebitDate();
        const maxDebitDate = ok
            ? moment(instruction.debitDate)
                  .add(1, 'years')
                  .startOf('day')
            : this.getMaxDebitDate();
        const investingAmount = ok ? instruction.investmentAmount : 0;
        const debitBankAccount = ok ? instruction.bankAccountNumber : null;
        const agreedDebit = ok ? instruction.isDebitAuthorised : false;
        this.instructionForm = this.formBuilder.group(
            {
                adHocAmount: [
                    investingAmount,
                    Validators.compose([Validators.required, Validators.min(this.minLumpSum), Validators.max(this.maxLumpSum)]),
                ],
                bankAccount: [debitBankAccount, Validators.required],
                debitDate: [
                    this.dateFormatter.forDatePicker(minDebitDate),
                    Validators.compose([
                        Validators.required,
                        dateMinValidator(minDebitDate.toDate(), this.dateFormatter),
                        dateMaxValidator(maxDebitDate.toDate(), this.dateFormatter),
                    ]),
                ],
                sourceOfFunds: [null, Validators.required],
                otherSourceOfFund: [''],
                allocations: this.formBuilder.array([]),
                debitOrderAgreed: [agreedDebit, Validators.requiredTrue],
                existingInstructionId: [null],
            },
            { validator: this.validateSourceOfFunds.bind(this) }
        );
    }

    private addFundHoldings() {
        if (this.selectedAccount) {
            const items = this.instructionForm.get('allocations') as FormArray;
            items.controls.length = 0;
            let totalFundHoldings = this.selectedAccount.accountPortfolios.reduce((total, holding) => {
                if (this.instructionService.canInstructOnAccountItem(holding)) {
                    return total + holding.totalValue;
                }
                return total;
            }, 0);
            for (let fund of this.allFunds) {
                let matchingHolding = this.selectedAccount.accountPortfolios.filter(
                    holding => holding.fundCode.replace(' ZA', '') === fund.fundCode.replace(' ZA', '')
                )[0];
                let fundOnlyPercentage = matchingHolding ? (matchingHolding.totalValue / totalFundHoldings) * 100 : 0;
                let percentageString = this.decimalPipe.transform(fundOnlyPercentage, '1.2-2');
                percentageString = percentageString.replace(/,/g, '').replace(/ /g, '');
                let percentageOfTotal = +percentageString;
                items.push(
                    this.formBuilder.group({
                        fundCode: fund.fundCode,
                        fundName: fund.fundName,
                        valueAllocation: [0, Validators.compose([Validators.required, Validators.min(0)])],
                        percentageOfTotal: [
                            percentageOfTotal,
                            Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
                        ],
                    })
                );
            }
            this.hasHoldings = items.length > 0;
            this.computeAllocationTotal();
        }
    }

    private otherDescriptionRequired(id: number) {
        const type = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return type && type.isOther;
    }

    private validateSourceOfFunds(abstractForm: FormGroup): { [key: string]: any } {
        if (!this.sourceOfFundsTypes) {
            return null;
        }
        const sourceIdControl = abstractForm.get('sourceOfFunds');
        const descriptionControl = abstractForm.get('otherSourceOfFund');

        const required = this.otherDescriptionRequired(+(sourceIdControl.value ? sourceIdControl.value.id : 0));

        let valid = true;
        if (required) {
            const value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    }

    private setExistingValues(bankAccountNumber, sourceOfFundName, existingInstructionId) {
        const bankAccount = !!bankAccountNumber
            ? this.userBankAccounts.filter(k => k.accountNumber === bankAccountNumber)[0]
            : this.userBankAccounts[0];

        const selectedSource = this.sourceOfFundsTypes.filter(k => k.description === sourceOfFundName)[0];
        if (existingInstructionId) {
            this.instructionForm.get('existingInstructionId').setValue(existingInstructionId);
        }
        if (selectedSource) {
            this.instructionForm.get('sourceOfFunds').setValue(selectedSource);
        }
        this.instructionForm.get('bankAccount').setValue(bankAccount);
    }
}
