import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { BigNumberPipe } from 'shared/pipes';
import { NumberFormatterService } from 'shared/services/number-formatter.service';

@Directive({ selector: '[wimBigNumberRandFormatter]' })
export class BigNumberRandFormatterDirective implements OnInit {
    @Input()
    public zeroIfEmpty = false;

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef, private formatterService: NumberFormatterService) {
        this.el = this.elementRef.nativeElement;
    }

    public ngOnInit() {
        this.el.value = this.formatterService.transformBigNumber(this.el.value);
    }

    @HostListener('focus', ['$event.target.value'])
    public onFocus(value) {
        this.el.value = this.formatterService.parseNumberTextPrepend(value, 'R ', this.formatterService.parseBigNumber);
        this.el.type = 'number';
    }

    @HostListener('blur', ['$event.target.value'])
    public onBlur(value) {
        this.el.type = 'text';
        this.el.value = this.formatterService.transformNumberTextPrepend(value, null, 'R ', v =>
            this.formatterService.transformBigNumber(v, null, this.zeroIfEmpty, null)
        );
    }
}
