/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@hackages/ngxerrors/src/ngxerrors.directive";
import * as i3 from "@hackages/ngxerrors/src/ngxerror.directive";
import * as i4 from "./other-name-modal.component";
import * as i5 from "../../../../shared/services/logger.service";
import * as i6 from "@ngxs/store";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_GoalSelectionOtherNameModalComponent = [];
var RenderType_GoalSelectionOtherNameModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoalSelectionOtherNameModalComponent, data: {} });
export { RenderType_GoalSelectionOtherNameModalComponent as RenderType_GoalSelectionOtherNameModalComponent };
export function View_GoalSelectionOtherNameModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "modal-title"], ["id", "exampleModalLabel1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please name your goal"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 21, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(9, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(11, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 15, "div", [["class", "col-md-12 float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 14, "div", [["class", "col-md-12 no-gutters float-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 13, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "label", [["class", "control-label"], ["for", "goal-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Goal Name:"])), (_l()(), i0.ɵeld(17, 0, null, null, 5, "input", [["class", "form-control"], ["formControlName", "goalName"], ["id", "recipient-name1"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(18, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(20, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(22, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(23, 0, null, null, 4, "div", [["ngxErrors", "goalName"]], null, null, null, null, null)), i0.ɵdid(24, 4931584, null, 0, i2.NgxErrorsDirective, [i1.FormGroupDirective], { controlName: [0, "controlName"] }, null), (_l()(), i0.ɵeld(25, 0, null, null, 2, "div", [["class", "validation-error"], ["ngxError", "required"], ["when", "touched"]], [[8, "hidden", 0]], null, null, null, null)), i0.ɵdid(26, 475136, null, 0, i3.NgxErrorDirective, [i2.NgxErrorsDirective], { ngxError: [0, "ngxError"], when: [1, "when"] }, null), (_l()(), i0.ɵted(-1, null, ["Goal name is required"])), (_l()(), i0.ɵeld(28, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 1, "button", [["class", "btn btn-themecolor-sec btn-rounded"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 9, 0, currVal_7); var currVal_15 = "goalName"; _ck(_v, 20, 0, currVal_15); var currVal_16 = "goalName"; _ck(_v, 24, 0, currVal_16); var currVal_18 = "required"; var currVal_19 = "touched"; _ck(_v, 26, 0, currVal_18, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 11).ngClassValid; var currVal_5 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i0.ɵnov(_v, 22).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 22).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 22).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 22).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 22).ngClassValid; var currVal_13 = i0.ɵnov(_v, 22).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 22).ngClassPending; _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_17 = i0.ɵnov(_v, 26).hidden; _ck(_v, 25, 0, currVal_17); var currVal_20 = !_co.form.valid; _ck(_v, 29, 0, currVal_20); }); }
export function View_GoalSelectionOtherNameModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-robo-goal-other-name", [], null, null, null, View_GoalSelectionOtherNameModalComponent_0, RenderType_GoalSelectionOtherNameModalComponent)), i0.ɵdid(1, 180224, null, 0, i4.GoalSelectionOtherNameModalComponent, [i5.LoggerService, i6.Store, i1.FormBuilder, i7.NgbActiveModal], null, null)], null, null); }
var GoalSelectionOtherNameModalComponentNgFactory = i0.ɵccf("wim-robo-goal-other-name", i4.GoalSelectionOtherNameModalComponent, View_GoalSelectionOtherNameModalComponent_Host_0, {}, {}, []);
export { GoalSelectionOtherNameModalComponentNgFactory as GoalSelectionOtherNameModalComponentNgFactory };
