/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tax-free-disclaimer-modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@ngrx/store";
var styles_TaxFreeDisclaimerModalComponent = [];
var RenderType_TaxFreeDisclaimerModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TaxFreeDisclaimerModalComponent, data: {} });
export { RenderType_TaxFreeDisclaimerModalComponent as RenderType_TaxFreeDisclaimerModalComponent };
export function View_TaxFreeDisclaimerModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Disclaimer"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please note that we have not taken into account contributions into any other tax free savings account held with FNB or any other Financial Services Provider. If you contribute in excess of the annual tax-free savings limit you will be responsible for the income tax payable."])), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["I agree"]))], null, null); }
export function View_TaxFreeDisclaimerModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-tax-free-disclaimer-modal", [], null, null, null, View_TaxFreeDisclaimerModalComponent_0, RenderType_TaxFreeDisclaimerModalComponent)), i0.ɵdid(1, 49152, null, 0, i1.TaxFreeDisclaimerModalComponent, [i2.NgbActiveModal, i3.Store], null, null)], null, null); }
var TaxFreeDisclaimerModalComponentNgFactory = i0.ɵccf("wim-tax-free-disclaimer-modal", i1.TaxFreeDisclaimerModalComponent, View_TaxFreeDisclaimerModalComponent_Host_0, {}, {}, []);
export { TaxFreeDisclaimerModalComponentNgFactory as TaxFreeDisclaimerModalComponentNgFactory };
