import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { Observable } from 'rxjs';
import { BankerContactForm, ContactForm, ContactResponse } from 'shared/models';

@Injectable()
export class ContactService {
    constructor(private http: HttpClient) {}

    public sendFeedbackForm(feedbackObject: ContactForm): Observable<ContactResponse> {
        return this.http.post<ContactResponse>(`${appConfig.apiUrl}/general/send-feedBack`, feedbackObject);
    }

    public sendBankerContactForm(feedbackObject: BankerContactForm): Observable<ContactResponse> {
        return this.http.post<ContactResponse>(`${appConfig.apiUrl}/general/send-banker-contact`, feedbackObject);
    }
}
