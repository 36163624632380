/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./advice-download-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./advice-download-modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@ngrx/store";
import * as i7 from "../../services/advice-engine.service";
var styles_AdviceDownloadModalComponent = [i0.styles];
var RenderType_AdviceDownloadModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdviceDownloadModalComponent, data: {} });
export { RenderType_AdviceDownloadModalComponent as RenderType_AdviceDownloadModalComponent };
function View_AdviceDownloadModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "email-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Investment Proposal PDF sent to ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailAddress; _ck(_v, 2, 0, currVal_0); }); }
function View_AdviceDownloadModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "email-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["There was a problems sending the Investment Proposal PDF to ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailAddress; _ck(_v, 2, 0, currVal_0); }); }
function View_AdviceDownloadModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "email-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please enter a valid email"]))], null, null); }
export function View_AdviceDownloadModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Investment Proposal Download"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 34, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Investment Proposal is ready to download"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "btn btn-sm btn-themecolor btn-rounded waves-effect waves-light"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download Now"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 25, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 10, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 9, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email Investment Proposal to: "])), (_l()(), i1.ɵeld(19, 0, null, null, 7, "input", [["class", "form-control"], ["name", "emailAddress"], ["required", ""], ["type", "text"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.emailAddress = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(21, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(24, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(26, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 7, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 6, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 30).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(30, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(32, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "btn btn-sm btn-themecolor btn-rounded waves-effect waves-light"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.email() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send Now"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdviceDownloadModalComponent_1)), i1.ɵdid(36, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdviceDownloadModalComponent_2)), i1.ɵdid(38, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdviceDownloadModalComponent_3)), i1.ɵdid(40, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(41, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = ""; _ck(_v, 21, 0, currVal_9); var currVal_10 = "emailAddress"; var currVal_11 = _co.emailAddress; _ck(_v, 24, 0, currVal_10, currVal_11); var currVal_19 = _co.emailSent; _ck(_v, 36, 0, currVal_19); var currVal_20 = _co.emailError; _ck(_v, 38, 0, currVal_20); var currVal_21 = _co.emailFormatError; _ck(_v, 40, 0, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.uniqueUrl, ""); _ck(_v, 12, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 21).required ? "" : null); var currVal_2 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 26).ngClassValid; var currVal_7 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 19, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = i1.ɵnov(_v, 32).ngClassUntouched; var currVal_13 = i1.ɵnov(_v, 32).ngClassTouched; var currVal_14 = i1.ɵnov(_v, 32).ngClassPristine; var currVal_15 = i1.ɵnov(_v, 32).ngClassDirty; var currVal_16 = i1.ɵnov(_v, 32).ngClassValid; var currVal_17 = i1.ɵnov(_v, 32).ngClassInvalid; var currVal_18 = i1.ɵnov(_v, 32).ngClassPending; _ck(_v, 28, 0, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18); }); }
export function View_AdviceDownloadModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wim-advice-download-modal", [], null, null, null, View_AdviceDownloadModalComponent_0, RenderType_AdviceDownloadModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdviceDownloadModalComponent, [i5.NgbActiveModal, i6.Store, i7.AdviceEngineService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdviceDownloadModalComponentNgFactory = i1.ɵccf("wim-advice-download-modal", i4.AdviceDownloadModalComponent, View_AdviceDownloadModalComponent_Host_0, {}, {}, []);
export { AdviceDownloadModalComponentNgFactory as AdviceDownloadModalComponentNgFactory };
