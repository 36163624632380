var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { InstrumentAllocationBasis } from 'investor/models';
import { InstructionsService } from 'investor/services';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var SwitchInstructionComponent = /** @class */ (function (_super) {
    __extends(SwitchInstructionComponent, _super);
    function SwitchInstructionComponent(investorStore, sharedStore, instructionService, lookupService, formBuilder, loggerService, modalService, toastr) {
        var _this = _super.call(this, toastr, loggerService, modalService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.instructionService = instructionService;
        _this.lookupService = lookupService;
        _this.formBuilder = formBuilder;
        _this.hasHoldings = false;
        _this.switchOutValue = 0;
        _this.totalSwitchInValue = 0;
        _this.totalSwitchInPercentage = 0;
        _this.modalTitle = 'Switch';
        return _this;
    }
    SwitchInstructionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.createForm();
        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(function (user) {
            _this.user = user;
        });
        combineLatest(this.investorStore.select(fromInvestor.selectInvestmentAccounts), this.investorStore.select(fromInvestor.selectSelectedInstructionAccount), function (accounts, selectedAccount) {
            return { accounts: accounts, selectedAccount: selectedAccount };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var accounts = _a.accounts, selectedAccount = _a.selectedAccount;
            if (!accounts) {
                return;
            }
            _this.selectedAccountNumber = selectedAccount;
            _this.selectedInvestmentAccount = accounts.find(function (acct) {
                return acct.accountNumber === _this.selectedAccountNumber;
            });
            _this.addFundHoldings();
        });
        this.lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(function (funds) {
            if (funds && funds.length > 0) {
                _this.switchInFunds = [];
                funds.forEach(function (val) {
                    var _accountCode = val.pstCode.split(' ')[0];
                    var newFund = {
                        fundName: val.name,
                        fundCode: _accountCode,
                        allocatedPercent: 0,
                        allocatedAmount: 0,
                        allocatedUnits: 0,
                        allocationBasis: InstrumentAllocationBasis.unitsQuantity,
                    };
                    _this.switchInFunds.push(newFund);
                });
            }
            else {
                _this.switchInFunds = [];
            }
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    SwitchInstructionComponent.prototype.ngOnDestroy = function () { };
    SwitchInstructionComponent.prototype.basis = function () {
        return this.instructionForm.get('switchOutBasis').value;
    };
    SwitchInstructionComponent.prototype.byUnits = function () {
        return this.basis() === InstrumentAllocationBasis.unitsQuantity;
    };
    SwitchInstructionComponent.prototype.byValue = function () {
        return this.basis() === InstrumentAllocationBasis.currencyValue;
    };
    SwitchInstructionComponent.prototype.switchOutPercentageChanged = function () {
        var percent = this.instructionForm.get('switchOutPercentage').value;
        if (percent) {
            var outFund = this.instructionForm.get('switchOutFund').value;
            outFund.switchUnits = (outFund.unitsHeld * percent) / 100;
            outFund.switchValue = (outFund.totalValue * percent) / 100;
            this.instructionForm.get('switchOutFund').setValue(outFund);
            this.instructionForm.get('switchOutAmount').setValue(outFund.switchValue, { emitEvent: false });
            this.switchOutValue = outFund.switchValue;
            this.clearSwitchIn();
        }
    };
    SwitchInstructionComponent.prototype.selectedSwitchOutAccountChanged = function () {
        var selected = this.instructionForm.get('switchOutFund').value;
        if (selected) {
            this.createSwitchInFundsObject(selected.fundCode);
            this.instructionForm.get('switchOutAmount').enable();
            this.instructionForm.get('switchOutPercentage').enable();
        }
        else {
            this.instructionForm.get('switchOutAmount').disable();
            this.instructionForm.get('switchOutPercentage').disable();
        }
    };
    SwitchInstructionComponent.prototype.switchOutValueChanged = function () {
        var amount = this.instructionForm.get('switchOutAmount').value;
        if (amount) {
            var outFund = this.instructionForm.get('switchOutFund').value;
            var percent = (amount / outFund.totalValue) * 100;
            outFund.switchUnits = (outFund.unitsHeld * percent) / 100;
            this.instructionForm.get('switchOutFund').setValue(outFund);
            this.switchOutValue = (outFund.totalValue * percent) / 100;
            this.instructionForm.get('switchOutPercentage').setValue(percent, { emitEvent: false });
            this.clearSwitchIn();
        }
    };
    SwitchInstructionComponent.prototype.round = function (value) {
        return Math.round(value * 100) / 100;
    };
    SwitchInstructionComponent.prototype.switchInPercentageChange = function (_, fund) {
        var percent = fund.value.switchPercentage;
        var value = this.round((this.switchOutValue * percent) / 100);
        fund.get('switchValue').setValue(value, { emitEvent: false });
        this.calculateTotalSwitchInValues();
    };
    SwitchInstructionComponent.prototype.switchInValueChanged = function (_, fund) {
        var value = fund.value.switchValue;
        var percent = this.round((value / this.switchOutValue) * 100 || 0);
        fund.get('switchPercentage').setValue(percent, { emitEvent: false });
        this.calculateTotalSwitchInValues();
    };
    SwitchInstructionComponent.prototype.removeCurrentFund = function (_, fund) {
        var fundControlArray = this.instructionForm.get('switchInFunds');
        var newControls = fundControlArray.controls.filter(function (fundControl) { return fundControl.value.fundCode !== fund.value.fundCode; });
        fundControlArray.controls = newControls;
        this.calculateTotalSwitchInValues();
    };
    SwitchInstructionComponent.prototype.calculateTotalSwitchInValues = function () {
        this.totalSwitchInValue = this.instructionForm.get('switchInFunds').controls.reduce(function (total, control) { return total + control.get('switchValue').value; }, 0);
        this.totalSwitchInPercentage = this.instructionForm.get('switchInFunds').controls.reduce(function (total, control) { return total + control.get('switchPercentage').value; }, 0);
    };
    SwitchInstructionComponent.prototype.clearSwitchIn = function () {
        for (var _i = 0, _a = this.instructionForm.get('switchInFunds').controls; _i < _a.length; _i++) {
            var control = _a[_i];
            control.get('switchPercentage').setValue(0);
            control.get('switchValue').setValue(0);
        }
        this.calculateTotalSwitchInValues();
    };
    SwitchInstructionComponent.prototype.canSubmit = function () {
        return this.instructionForm.valid && this.totalSwitchInPercentage === 100;
    };
    SwitchInstructionComponent.prototype.mayInstruct = function () {
        return this.hasHoldings;
    };
    SwitchInstructionComponent.prototype.submit = function () {
        var _this = this;
        if (this.canSubmit()) {
            var switchInArray = this.instructionForm.get('switchInFunds').value.map(function (fund) {
                return {
                    fundCode: fund.fundCode,
                    fundName: fund.fundName,
                    allocationBasis: InstrumentAllocationBasis.percentageOfFunding,
                    allocatedPercent: fund.switchPercentage,
                    allocatedAmount: fund.switchValue,
                };
            });
            var switchOutFund = this.instructionForm.value.switchOutFund;
            this.sharedStore.dispatch(new LoadingAction());
            var switchOutFundObject = {
                fundCode: switchOutFund.fundCode,
                fundName: switchOutFund.fundName,
                allocatedPercent: this.instructionForm.get('switchOutPercentage').value,
                allocatedUnits: switchOutFund.switchUnits,
                allocatedAmount: this.instructionForm.get('switchOutAmount').value,
                allocationBasis: this.instructionForm.get('switchOutBasis').value,
            };
            var payLoad = {
                accountNumber: this.selectedAccountNumber,
                caseTypeId: 0,
                ucn: this.user.customerDetails.ucn,
                switchInFunds: switchInArray,
                switchOutFund: switchOutFundObject,
            };
            this.instructionService
                .submitSwitchInstruction(payLoad)
                .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
                .subscribe(function (result) { return _this.handleResponse(result, _this.modalTitle); }, function () { return _this.handleSubmitError(_this.modalTitle); });
        }
        else {
            this.markAsTouched(this.instructionForm);
        }
    };
    SwitchInstructionComponent.prototype.cancel = function () {
        this.instructionForm.reset();
        this.addFundHoldings();
        this.setDefaults();
    };
    SwitchInstructionComponent.prototype.addFundHoldings = function () {
        var _this = this;
        this.switchOutFunds = [];
        this.selectedInvestmentAccount.accountPortfolios
            .filter(function (acc) { return _this.instructionService.canInstructOnAccountItem(acc); })
            .forEach(function (holding) {
            var fundCode = holding.fundCode.replace(' ZA', '');
            var newFund = {
                fundName: holding.fundName,
                fundCode: fundCode,
                percentageOfTotal: holding.percentageOfTotal,
                unitsHeld: holding.units,
                switchPercentage: 0,
                switchValue: 0,
                switchUnits: 0,
                totalValue: holding.totalValue,
            };
            _this.switchOutFunds.push(newFund);
        });
        this.hasHoldings = this.switchOutFunds.length > 0;
    };
    SwitchInstructionComponent.prototype.setDefaults = function () {
        this.instructionForm.get('switchOutBasis').setValue(InstrumentAllocationBasis.unitsQuantity);
        this.switchOutValue = 0;
        this.instructionForm.get('switchOutPercentage').setValue(0);
        this.instructionForm.get('switchOutAmount').setValue(0);
        this.instructionForm.get('switchOutAmount').disable();
        this.instructionForm.get('switchOutPercentage').disable();
        this.calculateTotalSwitchInValues();
    };
    SwitchInstructionComponent.prototype.createForm = function () {
        this.instructionForm = this.formBuilder.group({
            switchOutFund: [null, Validators.required],
            switchOutPercentage: [0, Validators.compose([Validators.required, Validators.min(1), Validators.max(100)])],
            switchOutAmount: [0, Validators.compose([Validators.required, Validators.min(0)])],
            switchInFunds: this.formBuilder.array([]),
            switchOutBasis: [InstrumentAllocationBasis.unitsQuantity, Validators.required],
        });
        this.instructionForm.get('switchOutAmount').disable();
        this.instructionForm.get('switchOutPercentage').disable();
    };
    SwitchInstructionComponent.prototype.createSwitchInFundsObject = function (fundCode) {
        var _this = this;
        var items = this.instructionForm.get('switchInFunds');
        items.controls.length = 0;
        this.switchInFunds.forEach(function (fund) {
            if (fundCode !== fund.fundCode) {
                items.push(_this.formBuilder.group({
                    fundCode: fund.fundCode,
                    fundName: fund.fundName,
                    percentageOfTotal: [0, Validators.required],
                    totalValue: [0, Validators.required],
                    switchPercentage: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
                    switchValue: [0, Validators.compose([Validators.required, Validators.min(0)])],
                }));
            }
        });
    };
    return SwitchInstructionComponent;
}(BaseInstructionComponent));
export { SwitchInstructionComponent };
