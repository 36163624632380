// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { InvestmentAccount, TradingAccount } from 'investor/models';
import { LOGIN_SUCCESS, LoginSuccessAction } from 'shared/store/user.reducers';

export const ACCOUNT_NUMBER_SELECTED = '[Accounts] Account Number Selected';
export class AccountNumberSelectedAction implements Action {
    public readonly type = ACCOUNT_NUMBER_SELECTED;
    constructor(public payload: string) {}
}

export const ACCOUNT_SELECTED = '[Accounts] Account Selected';
export class AccountSelectedAction implements Action {
    public readonly type = ACCOUNT_SELECTED;
    constructor(public payload: InvestmentAccount) {}
}

export const TRADINGS_ACCOUNTS_LOADED = '[Accounts] Tradings Accounts Loaded';
export class TradingAccountsLoadedAction implements Action {
    public readonly type = TRADINGS_ACCOUNTS_LOADED;
    constructor(public payload: TradingAccount[]) {}
}

export const INVESTMENT_ACCOUNTS_LOADED = '[Accounts] Investment Accounts Loaded';
export class InvestmentAccountsLoadedAction implements Action {
    public readonly type = INVESTMENT_ACCOUNTS_LOADED;
    constructor(public payload: InvestmentAccount[]) {}
}

export const INVESTMENT_ACCOUNTS_LOADING_ERROR = '[Accounts] Investments Accounts Loading Error';
export class InvestmentAccountsLoadingErrorAction implements Action {
    public readonly type = INVESTMENT_ACCOUNTS_LOADING_ERROR;
    constructor(public payload: Error) {}
}

export const INVESTMENT_ACCOUNTS_PENDING = '[Accounts] Investment Accounts Pending';
export class InvestmentAccountsPendingAction implements Action {
    public readonly type = INVESTMENT_ACCOUNTS_PENDING;
    constructor(public payload: boolean) {}
}

export const HAS_PROCESSING_APPLICATIONS = '[Accounts] Has Processing Applications';
export class HasProcessingApplicationsAction implements Action {
    public readonly type = HAS_PROCESSING_APPLICATIONS;
    constructor(public payload: boolean) {}
}

export const SET_INSTRUCTION_ACCOUNT = '[Accounts] setInstructionAccount';
export class InstructionsAccountAction implements Action {
    public readonly type = SET_INSTRUCTION_ACCOUNT;
    constructor(public payload: string) {}
}

export type AccountAction =
    | AccountNumberSelectedAction
    | AccountSelectedAction
    | TradingAccountsLoadedAction
    | InvestmentAccountsLoadedAction
    | InvestmentAccountsLoadingErrorAction
    | InvestmentAccountsPendingAction
    | HasProcessingApplicationsAction
    | LoginSuccessAction
    | InstructionsAccountAction;

export function accountNumberSelectedReducer(state: string = null, action: AccountAction) {
    switch (action.type) {
        case ACCOUNT_NUMBER_SELECTED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function accountSelectedReducer(state: InvestmentAccount = null, action: AccountAction) {
    switch (action.type) {
        case ACCOUNT_SELECTED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function tradingAccountsReducer(state: TradingAccount[] = null, action: AccountAction) {
    switch (action.type) {
        case TRADINGS_ACCOUNTS_LOADED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function investmentAccountsReducer(state: InvestmentAccount[] = null, action: AccountAction) {
    switch (action.type) {
        case INVESTMENT_ACCOUNTS_LOADED: {
            return action.payload;
        }
        case LOGIN_SUCCESS:
        case INVESTMENT_ACCOUNTS_LOADING_ERROR: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function investmentAccountsLoadingErrorReducer(state: Error = null, action: AccountAction) {
    switch (action.type) {
        case INVESTMENT_ACCOUNTS_LOADING_ERROR: {
            return action.payload;
        }
        case LOGIN_SUCCESS:
        case INVESTMENT_ACCOUNTS_LOADING_ERROR: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function investmentAccountsPendingReducer(state: boolean = null, action: AccountAction) {
    switch (action.type) {
        case INVESTMENT_ACCOUNTS_PENDING: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function hasProcessingApplicationsReducer(state: boolean = null, action: AccountAction) {
    switch (action.type) {
        case HAS_PROCESSING_APPLICATIONS: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function setInstructionAccountReducer(state: string = '', action: AccountAction) {
    switch (action.type) {
        case SET_INSTRUCTION_ACCOUNT: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
