import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import appConfig from 'appConfig';
import { CustomerBankAccount, CustomerSearchResult } from 'application/models';
import { Observable } from 'rxjs';

@Injectable()
export class CisSearchService {
    constructor(private http: HttpClient) {}

    public search(ucn: string, idNumber: string): Observable<CustomerSearchResult[]> {
        return this.http.post<CustomerSearchResult[]>(`${appConfig.apiUrl}/onboarding/customer-search`, {
            ucn,
            idNumber,
        });
    }

    public lookup(): Observable<CustomerSearchResult> {
        return this.http.get<CustomerSearchResult>(`${appConfig.apiUrl}/onboarding/customer-lookup`);
    }

    public getBankAccounts(ucn: string): Observable<CustomerBankAccount[]> {
        return this.http.get<CustomerBankAccount[]>(`${appConfig.apiUrl}/onboarding/${ucn}/bank-accounts`);
    }

    public getLoggedInUserBankAccounts(): Observable<CustomerBankAccount[]> {
        return this.http.get<CustomerBankAccount[]>(`${appConfig.apiUrl}/onboarding/investor-bank-accounts`);
    }
}
