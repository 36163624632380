﻿import { LumpSumDetails } from 'application/models';

export class RecordOfAdvice {
    public recordOfAdviceId: number;
    public applicationId: number;
    public goalName: string;
    public term: number;
    public fundId: number;
    public estimatedMaturity: number;
    public goalAmount: number;
    public pstTarget: number;
    public pstLumpsum: number;
    public pstRecurring: number;
    public pstEscalation: number;
    public pstAccessType: string;
    public pstNoticePeriod: string;
    public advisedInstallment: number;
    public noOtherTfsaConfirmed: boolean;
    public productDisclaimerAccepted: boolean;
    public pstAdvised: boolean;
    public pstLumpsumDetails: LumpSumDetails[];
    public advisedLumpSum: number;
    public advisedPortfolioTypeId: number;
    public projectedInterestRate: number;
    public goalAmountAdjustedForInflation: number;
    public affordabilityConfirmed: boolean;
}
