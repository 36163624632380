import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstReference } from 'pst/models';
import { PstParserService } from 'pst/services';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { LoggerService, LookupDataService } from 'shared/services';

import { BasePstComponent } from '../base-pst.component';

@Component({
    selector: 'wim-pst-message',
    templateUrl: 'message.component.pug',
})
export class MessageComponent extends BasePstComponent implements OnInit {
    public tree: PstReference;

    public heading: string;
    public description: string;

    constructor(
        loggerService: LoggerService,
        modalService: NgbModal,
        private pstStore: Store<PstState>,
        private pstParser: PstParserService,
        private lookupService: LookupDataService
    ) {
        super(loggerService, modalService);
    }

    public ngOnDestroy() {}

    public ngOnInit() {
        const result$ = this.pstStore.select(fromPst.selectInProgressPst);
        const tree$ = this.pstStore.select(fromPst.selectPstTree);

        const tooltips$ = this.lookupService.getPstTooltips();

        combineLatest(result$, tree$, tooltips$, (result, tree, tooltips) => {
            return { result, tree, tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ result, tree, tooltips }) => {
                this.tree = tree;
                if (!this.tree) {
                    return;
                }
                super.initContent(null, tooltips, tree.node.nodeId);
                this.heading = this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
                this.description = tree.node.nodeDescription;
            });
    }
}
