import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CustomerBankAccount } from 'application/models';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { DisInvestInstruction, InstructionAllocation, InstrumentAllocationBasis, InvestmentAccount } from 'investor/models';
import { InstructionsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { maxIfValidator, minIfValidator, requiredIfValidator } from 'shared/validators';

@Component({
    selector: 'wim-instruction-disinvest',
    templateUrl: 'disinvest-instruction.component.pug',
    styleUrls: ['disinvest-instruction.component.scss'],
})
export class DisinvestInstructionComponent extends BaseInstructionComponent implements OnInit, OnDestroy {
    public errMessage: string;
    public isSummationValid: boolean;
    private modalTitle = 'Disinvest';
    public isKyc = false;
    public hasHoldings = false;

    public userBankAccounts: CustomerBankAccount[];
    public selectedAccountNumber: string;
    public totalAllocation: number;
    public instructionForm: FormGroup;
    public atLeastOneFundAllocated = false;

    private user: User;
    private selectedAccount: InvestmentAccount;

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private instructionService: InstructionsService,
        private formBuilder: FormBuilder,
        modalService: NgbModal,
        toastr: ToastrService,
        loggerService: LoggerService
    ) {
        super(toastr, loggerService, modalService);
    }

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());
        this.createForm();

        let user$ = this.sharedStore.select(fromShared.selectUser);
        let investmentAccounts$ = this.investorStore.select(fromInvestor.selectInvestmentAccounts);
        let selectedAccount$ = this.investorStore.select(fromInvestor.selectSelectedInstructionAccount);

        combineLatest(user$, investmentAccounts$, selectedAccount$, (user, investmentAccounts, selectedAccount) => {
            return { user, investmentAccounts, selectedAccount };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ user, investmentAccounts, selectedAccount }) => {
                if (!investmentAccounts) {
                    return;
                }
                this.user = user;
                this.isKyc = user.customerDetails.isKycVerified;
                this.userBankAccounts = user.bankAccounts;
                if (user.bankAccounts.length >= 1) {
                    this.instructionForm.get('bankAccount').setValue(user.bankAccounts[0]);
                }
                if (this.userBankAccounts) {
                    this.instructionForm.get('bankAccount').setValue(this.userBankAccounts[0]);
                }
                this.sharedStore.dispatch(new StopLoadingAction());
                this.selectedAccountNumber = selectedAccount;
                this.selectedAccount = investmentAccounts.find(acct => {
                    return acct.accountNumber === this.selectedAccountNumber;
                });
                this.addFundHoldings();
            });
    }

    public ngOnDestroy(): void {}

    public canSubmit() {
        if (this.instructionForm.get('sellAll').value) {
            return this.instructionForm.get('bankAccount').valid;
        }
        return this.instructionForm.valid && this.atLeastOneFundAllocated;
    }

    public mayInstruct() {
        return this.hasHoldings && this.isKyc;
    }

    public submit() {
        if (this.canSubmit()) {
            const formData = this.instructionForm.value;
            this.sharedStore.dispatch(new LoadingAction());
            const payLoad: DisInvestInstruction = {
                accountNumber: this.selectedAccountNumber,
                bankAccountNumber: formData.bankAccount.accountNumber,
                bankAccountHolder: this.user.displayName,
                bankAccountType: formData.bankAccount.accountType,
                stopDebit: false,
                sellAll: formData.sellAll,
                disInvestBreakdown: this.getDisInvestAllocations(),
                ucn: this.user.customerDetails.ucn,
                caseTypeId: 0,
            };
            this.instructionService
                .submitDisInvestInstruction(payLoad)
                .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
                .subscribe(result => this.handleResponse(result, this.modalTitle), () => this.handleSubmitError(this.modalTitle));
        } else {
            this.markAsTouched(this.instructionForm);
        }
    }

    public cancel() {
        this.instructionForm.reset();
        this.instructionForm.get('sellAll').setValue(false);
        this.instructionForm.get('disinvestBasis').setValue(InstrumentAllocationBasis.unitsQuantity);
        this.addFundHoldings();
    }

    public getDisInvestAllocations() {
        const _allocations: InstructionAllocation[] = [];
        this.instructionForm.get('allocations').value.forEach(fund => {
            let allocation = {
                fundName: fund.fundName,
                fundCode: fund.fundCode.replace(' ZA', ''),
                allocatedUnits: null,
                allocatedPercent: null,
                allocatedAmount: null,
                allocationBasis: this.instructionForm.get('disinvestBasis').value,
            };
            if (this.byUnits()) {
                allocation.allocatedUnits = fund.units;
                allocation.allocatedPercent = fund.percentageOfUnits;
            } else {
                allocation.allocatedAmount = fund.value;
            }
            _allocations.push(allocation);
        });
        return _allocations;
    }

    public basis() {
        return this.instructionForm.get('disinvestBasis').value;
    }

    public byUnits() {
        return this.basis() === InstrumentAllocationBasis.unitsQuantity;
    }

    public byValue() {
        return this.basis() === InstrumentAllocationBasis.currencyValue;
    }

    public showTable() {
        return this.instructionForm.get('allocations').value.length > 0 && !this.instructionForm.get('sellAll').value;
    }

    public basisChange(basis) {
        let allocations = this.instructionForm.get('allocations') as FormArray;
        for (let control of allocations.controls) {
            if (basis === InstrumentAllocationBasis.unitsQuantity) {
                control.get('value').setValue(0);
                control.get('value').markAsPristine();
                control.get('percOfValue').setValue(0);
                control.get('percOfValue').markAsPristine();
            } else {
                control.get('percentageOfUnits').setValue(0);
                control.get('percentageOfUnits').markAsPristine();
                control.get('units').setValue(0);
                control.get('units').markAsPristine();
            }
        }
    }

    public unitsUpdated(fundControl) {
        let unitPercentage = this.round((fundControl.value.units / fundControl.value.unitsHeld) * 100);
        fundControl.get('percentageOfUnits').setValue(unitPercentage, { emitEvent: false });
    }

    public unitPercentageUpdated(fundControl, value) {
        let units = this.round((fundControl.value.percentageOfUnits / 100) * fundControl.value.unitsHeld);
        fundControl.get('units').setValue(units, { emitEvent: false });
    }

    public valueUpdated(fundControl) {
        let valuePercentage = this.round((fundControl.value.value / fundControl.value.valueHeld) * 100);
        fundControl.get('percOfValue').setValue(valuePercentage, { emitEvent: false });
    }

    private round(value: number, places: number = 2) {
        return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
    }

    private createForm() {
        this.instructionForm = this.formBuilder.group({
            bankAccount: [null, Validators.required],
            disinvestBasis: [InstrumentAllocationBasis.unitsQuantity],
            allocations: this.formBuilder.array([]),
            sellAll: [false],
        });
        this.instructionForm.valueChanges.subscribe(() => {
            const allocations = this.instructionForm.get('allocations').value;

            if (this.byUnits()) {
                this.atLeastOneFundAllocated = allocations.filter(alloc => alloc.units > 0 || alloc.percentageOfUnits > 0).length > 0;
            } else {
                this.atLeastOneFundAllocated = allocations.filter(alloc => alloc.value > 0).length > 0;
            }
        });
    }

    private addFundHoldings() {
        if (this.selectedAccount) {
            const formArray = this.instructionForm.get('allocations') as FormArray;
            formArray.controls.length = 0;

            for (let accountPorfolio of this.selectedAccount.accountPortfolios.filter(acc =>
                this.instructionService.canInstructOnAccountItem(acc)
            )) {
                formArray.push(
                    this.formBuilder.group({
                        units: [
                            0,
                            Validators.compose([
                                requiredIfValidator(() => this.byUnits()),
                                minIfValidator(0, () => this.byUnits()),
                                maxIfValidator(accountPorfolio.units, () => this.byUnits()),
                            ]),
                        ],
                        value: [
                            0,
                            Validators.compose([
                                requiredIfValidator(() => this.byValue()),
                                minIfValidator(0, () => this.byValue()),
                                maxIfValidator(accountPorfolio.totalValue, () => this.byValue()),
                            ]),
                        ],

                        percentageOfUnits: [
                            0,
                            Validators.compose([
                                requiredIfValidator(() => this.byUnits()),
                                minIfValidator(0, () => this.byUnits()),
                                maxIfValidator(100, () => this.byUnits()),
                            ]),
                        ],

                        percOfValue: [
                            0,
                            Validators.compose([minIfValidator(0, () => this.byValue()), maxIfValidator(98, () => this.byValue())]),
                        ],

                        fundName: [accountPorfolio.fundName],
                        fundCode: [accountPorfolio.fundCode],
                        unitsHeld: [accountPorfolio.units],
                        unitPrice: [accountPorfolio.unitPrice],
                        lastPriceDate: [accountPorfolio.lastPriceDate],
                        valueHeld: [accountPorfolio.totalValue],
                    })
                );
            }
            this.hasHoldings = formArray.length > 0;
        }
    }
}
