import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export function bigNumberMaxValidator(maxValue: number, text = ''): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const textRegex = new RegExp(text, 'g');
        const value = +`${control.value}`.replace(/\s/g, '').replace(textRegex, '');
        const belowMax = value === null || value <= maxValue;

        return belowMax ? null : { max: maxValue };
    };
}
