var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as fromShared from 'shared/store/shared.store';
import { Chart } from 'angular-highcharts';
import appConfig from 'appConfig';
import { PstContentModalComponent } from 'pst/components/content-modal/pst-content-modal.component';
import { FundSelectedAction } from 'pst/store';
import { RoboFieldId } from 'robo/models';
import { RoboRecommendationAcceptedAction, RoboStore } from 'robo/store';
import { TaxFreeOpenedAction, TaxFreeOptOutAction } from 'robo/store/robo.actions';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { EacModalComponent } from 'shared/components';
import { LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { chartOptions } from './chart-options';
var PER_MONTH = ' <span class="per-mont">p/m</span>';
var RoboProjectedPerformanceComponent = /** @class */ (function (_super) {
    __extends(RoboProjectedPerformanceComponent, _super);
    function RoboProjectedPerformanceComponent(loggerService, sharedStore, store, lookupDataService, ngxsStore, numberFormatter, modalService, pstStore) {
        var _this = _super.call(this, loggerService) || this;
        _this.sharedStore = sharedStore;
        _this.store = store;
        _this.ngxsStore = ngxsStore;
        _this.numberFormatter = numberFormatter;
        _this.modalService = modalService;
        _this.pstStore = pstStore;
        _this.funds = [];
        _this.fees = [];
        _this.goals = [];
        _this.portfolioTypes = [];
        _this.cashProducts = [];
        _this.enteredTargetAmount = 0;
        _this.enteredRecurringAmount = 0;
        _this.enteredTerm = 120;
        _this.content = [];
        _this.toolTips = [];
        _this.openTaxFree = true;
        _this.goalReached = false;
        _this.canIncreaseGoal = true;
        _this.chart = new BehaviorSubject(_this.getEmptyChart()).pipe(debounceTime(100));
        lookupDataService
            .getHorizonFunds()
            .pipe(_this.scavenger.collect())
            .subscribe(function (funds) {
            _this.funds = funds;
        });
        lookupDataService
            .getPortfolioInstrumentFees()
            .pipe(_this.scavenger.collect())
            .subscribe(function (fees) {
            _this.fees = fees;
        });
        lookupDataService
            .getRoboGoals()
            .pipe(_this.scavenger.collect())
            .subscribe(function (goals) {
            _this.goals = goals;
        });
        lookupDataService
            .getCashProductDetails()
            .pipe(_this.scavenger.collect())
            .subscribe(function (cashProducts) {
            _this.cashProducts = cashProducts;
        });
        sharedStore
            .select(fromShared.selectIsDirect)
            .pipe(_this.scavenger.collect())
            .subscribe(function (isDirect) {
            _this.isDirect = isDirect;
        });
        store
            .select(RoboStore.currentAdvice)
            .pipe(_this.scavenger.collect())
            .subscribe(function (advice) {
            _this.renderRecommendation(advice);
        });
        lookupDataService
            .getPortfolioTypes()
            .pipe(_this.scavenger.collect())
            .subscribe(function (portfolioTypes) {
            _this.portfolioTypes = portfolioTypes;
        });
        _this.errors$ = store.select(RoboStore.validationErrors);
        lookupDataService
            .getPstResultContent()
            .pipe(_this.scavenger.collect())
            .subscribe(function (content) {
            _this.content = content;
        });
        store
            .select(RoboStore.currentAdvice)
            .pipe(_this.scavenger.collect())
            .subscribe(function (advice) {
            _this.renderRecommendation(advice);
        });
        store
            .select(RoboStore.completedField)
            .pipe(_this.scavenger.collect(), map(function (fn) {
            var goalId = _this.getValueIfFieldType(fn, RoboFieldId.goal);
            _this.goal = goalId ? _this.goals.find(function (goal) { return +goal.goalId === +goalId; }) : null;
            _this.isEmergenciesGoalCategory = goalId ? (+goalId === 4) : false;
            _this.isJustInvestTaxFreeGoalCategory = goalId ? (+goalId === 11) : false;
            return fn;
        }), map(function (fn) {
            _this.goalName = _this.getValueIfFieldType(fn, RoboFieldId.goalName);
            return fn;
        }), map(function (fn) {
            _this.enteredTargetAmount = +_this.getValueIfFieldType(fn, RoboFieldId.targetValue);
            if (!_this.recommendation) {
                _this.renderRecommendation(null);
            }
            return fn;
        }), map(function (fn) {
            _this.enteredRecurringAmount = +_this.getValueIfFieldType(fn, RoboFieldId.recurringContributionAmount);
            if (!_this.recommendation) {
                _this.renderRecommendation(null);
            }
            return fn;
        }), map(function (fn) {
            _this.enteredTerm = +_this.getValueIfFieldType(fn, RoboFieldId.term);
            if (!_this.recommendation) {
                _this.renderRecommendation(null);
            }
            return fn;
        }))
            .subscribe();
        return _this;
    }
    RoboProjectedPerformanceComponent.prototype.renderChart = function (chart) {
        this.chart.next(chart);
    };
    RoboProjectedPerformanceComponent.prototype.renderRecommendation = function (advice) {
        var _this = this;
        if (advice && advice.recommendations) {
            this.recommendation = advice.recommendations[0];
        }
        else {
            this.recommendation = null;
        }
        if (this.recommendation) {
            if (this.recommendation.graphData) {
                this.renderChart(this.createChart(this.recommendation.graphData));
            }
            else {
                this.renderChart(this.getEmptyChart());
            }
            this.recommendedPortfolioType = this.portfolioTypes.find(function (pType) { return pType.id === _this.recommendation.portfolioTypeId; });
            if (this.recommendation.taxFreeOptionAvailable) {
                this.recommendedPortfolioType = this.portfolioTypes.find(function (pType) { return pType.id === appConfig.portfolioTypes.TFDS; });
            }
            if (this.recommendation.taxFreeOptionAvailable) {
                if (this.openTaxFree) {
                    this.recommendedPortfolioType = this.portfolioTypes.find(function (pType) { return pType.id === appConfig.portfolioTypes.TFDS; });
                }
                else {
                    this.recommendedPortfolioType = this.portfolioTypes.find(function (pType) { return pType.id === appConfig.portfolioTypes.INV; });
                }
            }
            this.setPortfolioDetails();
            if (this.recommendation.investmentAccountReferenceData) {
                var fund = this.funds.find(function (f) { return f.fundId === _this.recommendation.fundId; });
                this.selectedFund = fund;
                this.setAdminFee();
                this.recommendedCashProduct = null;
                this.pstStore.dispatch(new FundSelectedAction(fund));
            }
            if (this.recommendation.cashAccountReferenceData) {
                this.selectedFund = null;
                this.recommendedCashProduct = this.cashProducts.find(function (product) { return product.portfolioTypeId === _this.recommendation.portfolioTypeId; });
                this.pstStore.dispatch(new FundSelectedAction(null));
            }
        }
        else {
            this.recommendedPortfolioType = null;
            this.renderChart(this.getEmptyChart());
        }
        this.checkRecommendations();
    };
    RoboProjectedPerformanceComponent.prototype.setPortfolioDetails = function () {
        if (this.recommendedPortfolioType) {
            if (this.recommendedPortfolioType.id === 3) {
                this.portfolioDetails = 'An Investment Account provides an opportunity to access a wide variety of unit trust funds through a single platform, allowing you to diversify across various asset classes. The investment account is easy to transact on; you can add and/ or withdraw funds. Income tax and/or capital gains tax may be applicable.';
            }
            if (this.recommendedPortfolioType.id === 10) {
                this.portfolioDetails = 'A Tax-Free Unit Trust provides you with a tax efficient way to invest. You can contribute up to R36 000 per year, and up to R500 000 in your lifetime, and the proceeds are tax free. You are allowed to withdraw from the investment, BUT please note that the withdrawn amount is deducted from your annual and lifetime contribution limit and cannot be replaced.';
            }
            if (this.recommendedPortfolioType.id === 25) { // Savings Account
                this.portfolioDetails = 'We can help you separate your savings from your day-to-day spending to ensure you don’t spend money which is meant to be saved. A Savings Account allows you to put money away safely and securely for unforeseen expenses where you can earn interest from as little as R1 and have instant access to your savings all at no fee.';
            }
            if (this.recommendedPortfolioType.id === 26) { // Money Maximiser
                this.portfolioDetails = 'We can help you earn a money market fund related rate. A Money Maximiser provides an opportunity to put away R100 000 or more and grow it safely and securely, where you can earn a money market fund related rate.  You still enjoy capital and quoted returns guaranteed. Enjoy instant access to your investment.';
            }
            if (this.recommendedPortfolioType.id === 27) { // 32 Day Flexi Notice
                this.portfolioDetails = 'A 32 Day Flexi Notice Account lets you save R5 000 or more, securely. Add any amount at any time and the money is available after 32 days’ notice for free, or earlier at a fee. No monthly fees apply.';
            }
            if (this.recommendedPortfolioType.id === 28) { // 7 Day Notice
                this.portfolioDetails = 'A 7 Day Notice Account, save from R20 000 or more and grow your money. Add any amount at any time (electronically) and the money is available after 7 days’ notice for free. No monthly fees apply and capital and quoted returns are guaranteed.';
            }
            if (this.recommendedPortfolioType.id === 29) { // Cash Intelligence
                this.portfolioDetails = 'A Cash Intelligence Investment provides an opportunity to earn an enhanced variable rate and enjoy flexible access to your investment. Minimum opening deposit of R 1 000 000. The Cash Index Investment is designed to offer guaranteed capital and quoted returns.';
            }
            if (this.recommendedPortfolioType.id === 30) { // Cash Intelligence
                this.portfolioDetails = 'A Cash Intelligence Investment provides an opportunity to earn an enhanced variable rate and enjoy flexible access to your investment. Minimum opening deposit of R 1 000 000. The Cash Index Investment is designed to offer guaranteed capital and quoted returns.';
            }
            if (this.recommendedPortfolioType.id === 31) { // Cash Intelligence
                this.portfolioDetails = 'A Cash Intelligence Investment provides an opportunity to earn an enhanced variable rate and enjoy flexible access to your investment. Minimum opening deposit of R 1 000 000. The Cash Index Investment is designed to offer guaranteed capital and quoted returns.';
            }
            if (this.recommendedPortfolioType.id === 32) { // Fixed Deposit
                this.portfolioDetails = 'We can help  you keep your money growing and safe.  Know in advance how much interest you are going to earn when you invest in an FNB Fixed Deposit.   Your deposits are safe and guaranteed.  A Fixed Deposit allows you to put away R10 000 or more and grow it securely for a fixed period between 7 days to 60 months. Additional money can be added at maturity and interest can be reinvested or transferred to another account. No monthly fees apply.';
            }
        }
    };
    RoboProjectedPerformanceComponent.prototype.setAdminFee = function () {
        var _this = this;
        var fee = this.fees.find(function (f) { return f.portfolioTypeId === _this.recommendedPortfolioType.id && f.instrumentId === _this.selectedFund.instrumentId && f.instrumentFeeTypeId === 6; });
        this.selectedFund.adminFee = fee.feeValue;
    };
    RoboProjectedPerformanceComponent.prototype.getValueIfFieldType = function (selectorFunction, fieldId) {
        var field = selectorFunction(fieldId);
        if (field) {
            return field.value;
        }
        return null;
    };
    RoboProjectedPerformanceComponent.prototype.getChartOptions = function () {
        return chartOptions;
    };
    RoboProjectedPerformanceComponent.prototype.transformBigNumberRand = function (value) {
        var _this = this;
        return this.numberFormatter.transformNumberTextPrepend(value, null, 'R ', function (val) {
            return _this.numberFormatter.transformBigNumber(val, null, true);
        });
    };
    RoboProjectedPerformanceComponent.prototype.toggleTaxFree = function () {
        this.openTaxFree = !this.openTaxFree;
        this.ngxsStore.dispatch(new TaxFreeOpenedAction(this.openTaxFree));
        this.ngxsStore.dispatch(new TaxFreeOptOutAction(!this.openTaxFree));
        if (this.openTaxFree) {
            this.recommendedPortfolioType = this.portfolioTypes.find(function (pType) { return pType.id === appConfig.portfolioTypes.TFDS; });
        }
        else {
            this.recommendedPortfolioType = this.portfolioTypes.find(function (pType) { return pType.id === appConfig.portfolioTypes.INV; });
        }
        this.setAdminFee();
    };
    RoboProjectedPerformanceComponent.prototype.checkRecommendations = function () {
        var recommendationThreshold = 100;
        if (this.recommendation) {
            if (this.recommendation.goalAmountAdjustedForInflation <= 10000) {
                recommendationThreshold = 100;
            }
            else if (this.recommendation.goalAmountAdjustedForInflation > 10000 &&
                this.recommendation.goalAmountAdjustedForInflation <= 1000000) {
                recommendationThreshold = this.recommendation.goalAmountAdjustedForInflation / 100;
            }
            else if (this.recommendation.goalAmountAdjustedForInflation > 1000000) {
                recommendationThreshold = 10000;
            }
            if (this.recommendation.surplusOrShortfall >= 0) {
                this.goalReached = true;
            }
            else {
                var shortfall = Math.abs(this.recommendation.surplusOrShortfall);
                if (shortfall < recommendationThreshold) {
                    this.goalReached = true;
                }
                if (shortfall > recommendationThreshold) {
                    this.goalReached = false;
                }
            }
            if (this.recommendation.surplusOrShortfall > recommendationThreshold && this.recommendation.surplusOrShortfall > 0) {
                this.canIncreaseGoal = true;
            }
            else {
                this.canIncreaseGoal = false;
            }
        }
        return this.goalReached;
    };
    RoboProjectedPerformanceComponent.prototype.getEmptyChart = function () {
        var chartConfig = this.getChartOptions();
        chartConfig.series = [
            {
                name: 'Projected Performance',
                data: new Array(this.enteredTerm).fill(0),
                color: 'black',
            },
            {
                name: 'Target Amount',
                data: new Array(this.enteredTerm).fill(this.enteredTargetAmount),
                color: '#007072',
            },
            {
                name: 'Target Amount',
                data: new Array(this.enteredTerm).fill(this.enteredTargetAmount),
                color: '#007072',
            },
        ];
        return new Chart(chartConfig);
    };
    RoboProjectedPerformanceComponent.prototype.openEacModal = function () {
        var modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = { funds: [{ fundCode: this.selectedFund.pstCode, allocation: 1 }], portfolioTypeId: this.recommendedPortfolioType.id };
    };
    RoboProjectedPerformanceComponent.prototype.isContent = function (id) {
        return !!this.content.find(function (c) { return c.id === id; });
    };
    RoboProjectedPerformanceComponent.prototype.displayFundContentPopup = function () {
        var id = 0;
        switch (this.selectedFund.instrumentId) {
            case 7:
                id = 4;
                break;
            case 6:
                id = 5;
                break;
            case 3:
                id = 6;
                break;
            case 5:
                id = 7;
                break;
        }
        this.displayContentPopup(id);
    };
    RoboProjectedPerformanceComponent.prototype.displayContentPopup = function (id) {
        if (!this.isContent(id)) {
            return;
        }
        var content = this.content.find(function (c) { return c.id === id; });
        var modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    };
    RoboProjectedPerformanceComponent.prototype.displayProjectionContentPopup = function (id) {
        if (!this.isContent(id)) {
            return;
        }
        var content = Object.assign('', this.content.find(function (c) { return c.id === id; }));
        var bestCaseGraphPoint = this.recommendation.graphData.up[this.recommendation.graphData.up.length - 1];
        var bestCase = this.transformBigNumberRand(bestCaseGraphPoint);
        var worstCaseGraphPoint = this.recommendation.graphData.down[this.recommendation.graphData.up.length - 1];
        var worstCase = this.transformBigNumberRand(worstCaseGraphPoint);
        var recommended = this.transformBigNumberRand(this.recommendation.requiredMonthlyContribution).trim();
        var original = this.transformBigNumberRand(this.enteredRecurringAmount).trim();
        content.content = content.content.replace('{{UP_SCENARIO}}', this.formatAsRandValue(bestCase));
        content.content = content.content.replace('{{DOWN_SCENARIO}}', this.formatAsRandValue(worstCase));
        content.content = content.content.replace('{{ORIGINAL}}', this.formatMonthlyPayment(original));
        if (this.recommendation.goalAmountAdjustedForInflation > 0) {
            if (this.goalReached && this.canIncreaseGoal) {
                content.content = content.content.replace('{{RECOMMENDED}}', 'Ahead of your goal');
            }
            else if (this.goalReached && !this.canIncreaseGoal) {
                content.content = content.content.replace('{{RECOMMENDED}}', 'Goal on track');
            }
            else {
                content.content = content.content.replace('{{RECOMMENDED}}', this.formatMonthlyPayment(recommended));
            }
        }
        else {
            content.content = content.content.replace('{{RECOMMENDED}}', 'No recommendation as no goal amount specified');
        }
        var modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    };
    RoboProjectedPerformanceComponent.prototype.formatMonthlyPayment = function (value) {
        if (value === 'R') {
            return "R 0" + PER_MONTH;
        }
        return "" + value + PER_MONTH;
    };
    RoboProjectedPerformanceComponent.prototype.formatAsRandValue = function (value) {
        if (value === 'R') {
            return "R 0";
        }
        return "" + value;
    };
    RoboProjectedPerformanceComponent.prototype.createChart = function (graphData) {
        var series;
        var charts;
        if (this.recommendation.goalAmountAdjustedForInflation > 0) {
            graphData.goal = new Array(graphData.clientScenario.length);
            graphData.goal.fill(this.recommendation.goalAmountAdjustedForInflation);
        }
        else {
            graphData.goal = null;
        }
        var downScenario = {
            name: 'Low Growth Scenario',
            data: graphData.down,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        var upScenario = {
            name: 'High Growth Scenario',
            data: graphData.up,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        var clientScenario = {
            name: 'Your Contribution',
            data: graphData.clientScenario,
            color: '#01aaad',
        };
        var goalScenario = {
            name: 'Goal Amount',
            data: graphData.goal,
            color: '#007072',
            dashStyle: 'longdash',
        };
        var recommendedScenario = {
            name: 'Recommended Contribution',
            data: graphData.expected,
            color: '#ff9900',
        };
        series = [];
        charts = [goalScenario, downScenario, clientScenario, upScenario];
        charts.forEach(function (chart) {
            if (chart.data) {
                series.push(chart);
            }
        });
        if (!this.goalReached) {
            series.push(recommendedScenario);
        }
        var chartConfig = this.getChartOptions();
        chartConfig.series = series;
        return new Chart(chartConfig);
    };
    RoboProjectedPerformanceComponent.prototype.adjustRecurring = function () {
        this.store.dispatch(new RoboRecommendationAcceptedAction({
            field: RoboFieldId.recurringContributionAmount,
            value: Math.round(this.recommendation.requiredMonthlyContribution),
        }));
    };
    RoboProjectedPerformanceComponent.prototype.adjustLumpSum = function () {
        this.store.dispatch(new RoboRecommendationAcceptedAction({
            field: RoboFieldId.lumpSumAmount,
            value: Math.round(this.recommendation.requiredInitialLump),
        }));
    };
    RoboProjectedPerformanceComponent.prototype.adjustGoal = function () {
        this.store.dispatch(new RoboRecommendationAcceptedAction({
            field: RoboFieldId.targetValue,
            value: Math.round(this.recommendation.recommendedGoalAmount),
        }));
    };
    RoboProjectedPerformanceComponent.prototype.ngOnDestroy = function () { };
    return RoboProjectedPerformanceComponent;
}(BaseComponent));
export { RoboProjectedPerformanceComponent };
