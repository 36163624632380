import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccessTokenRequest, AccessTokenResponse, SsoTokenRequest, SsoTokenResponse, TradingAccount } from 'investor/models';
import { TradingAccountsLoadedAction } from 'investor/store/account.reducers';
import { InvestorState } from 'investor/store/investor.store';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { FeatureFlags } from 'shared/models';
import { FeatureFlagService } from 'shared/services';

@Injectable()
export class FnbSecuritiesService {
    constructor(private http: HttpClient, private investorStore: Store<InvestorState>, private featureFlagService: FeatureFlagService) {}

    public fetchAccounts() {
        let accessTokenRequest: AccessTokenRequest = {
            clientId: 'fnbsweb',
            scopes: ['fnbswebapi'],
        };
        if (this.featureFlagService.isEnabled(FeatureFlags.SharesBalanceDisplay)) {
            this.http
                .post<AccessTokenResponse>(`${appConfig.apiUrl}/users/access-token`, accessTokenRequest)
                .pipe(
                    mergeMap(token =>
                        this.http.get(`${appConfig.fnbsApiUrl}/session`, { headers: { Authorization: `Bearer ${token.token}` } })
                    ),
                    mergeMap(() => this.http.get<TradingAccount[]>(`${appConfig.fnbsApiUrl}/account/all`)),
                    catchError(error => {
                        this.investorStore.dispatch(new TradingAccountsLoadedAction([]));
                        return of([]);
                    })
                )
                .subscribe(accounts => this.investorStore.dispatch(new TradingAccountsLoadedAction(accounts)));
        } else {
            this.investorStore.dispatch(new TradingAccountsLoadedAction([]));
        }
    }

    public getSsoToken(request: SsoTokenRequest): Observable<SsoTokenResponse> {
        return this.http.post<SsoTokenResponse>(`${appConfig.apiUrl}/users/sso-fnbs`, request);
    }
}
