var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import * as fromApplication from 'application/store/application.store';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { FundSelectedAction, PstAdviceShownAction, PstCompletedAction } from 'pst/store';
import { combineLatest } from 'rxjs';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { BaseComponent } from 'shared/components/base';
import { FeatureFlags } from 'shared/models';
import { FeatureFlagService, LoggerService, LookupDataService } from 'shared/services';
import { StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var DEFAULT_ESCALATION = '6';
var SalesComponent = /** @class */ (function (_super) {
    __extends(SalesComponent, _super);
    function SalesComponent(lookupService, scrollService, route, document, modalService, loggerService, router, sharedStore, pstStore, applicationStore, featureFlagService) {
        var _this = _super.call(this, loggerService) || this;
        _this.document = document;
        _this.modalService = modalService;
        _this.router = router;
        _this.sharedStore = sharedStore;
        _this.pstStore = pstStore;
        _this.applicationStore = applicationStore;
        _this.featureFlagService = featureFlagService;
        _this.isEligible = false;
        _this.iKnowWhatIWantEnabled = false;
        _this.showFundsButton = 'I want to Invest';
        _this.roboButton = 'Help me Invest';
        _this.investNowButton = 'Apply Now';
        _this.termsOfUseUrl = '';
        _this.showGetStartedButton = false;
        _this.carouselOptions = {
            loop: true,
            center: true,
            margin: 50,
            stagePadding: 153,
            mouseDrag: true,
            dots: true,
            dotsSpeed: 600,
            responsive: {
                0: {
                    items: 1,
                    margin: 15,
                    center: false,
                    stagePadding: 15,
                    dots: false,
                },
                575: {
                    items: 3,
                    center: true,
                    stagePadding: 350,
                },
                1440: {
                    items: 4,
                    margin: 67,
                    stagePadding: 153,
                    dots: true,
                },
            },
        };
        lookupService
            .getHorizonFunds()
            .pipe(_this.scavenger.collect())
            .subscribe(function (funds) {
            _this.funds = funds;
            _this.selectedFund = funds[0];
        });
        lookupService
            .getGeneralDocuments()
            .pipe(_this.scavenger.collect())
            .subscribe(function (docs) {
            _this.termsOfUseUrl = docs.find(function (d) { return d.name === 'Terms of Use'; }).url;
        });
        var authenticated$ = _this.sharedStore.select(fromShared.selectAuthenticated);
        var isDirect$ = _this.sharedStore.select(fromShared.selectIsDirect);
        var application$ = _this.applicationStore.select(fromApplication.selectHorizonApplication);
        var isEligible$ = _this.applicationStore.select(fromShared.selectInvestorEligible);
        var customer$ = _this.applicationStore.select(fromApplication.selectSelectedCustomer);
        combineLatest(authenticated$, isDirect$, application$, isEligible$, customer$, function (authenticated, isDirect, appform, isEligible, customer) {
            return { authenticated: authenticated, isDirect: isDirect, appform: appform, isEligible: isEligible, customer: customer };
        })
            .pipe(_this.scavenger.collect())
            .subscribe(function (_a) {
            var authenticated = _a.authenticated, isDirect = _a.isDirect, isEligible = _a.isEligible, customer = _a.customer;
            _this.authenticated = authenticated;
            _this.isDirect = isDirect;
            _this.isEligible = isEligible;
            _this.selectedCustomer = customer;
            if (authenticated) {
                _this.iKnowWhatIWantEnabled = isDirect && _this.featureFlagService.isEnabled(FeatureFlags.IKnowWhatIWant);
            }
            else {
                _this.iKnowWhatIWantEnabled = true;
            }
            if (authenticated) {
                if (isDirect) {
                    _this.showFundsButton = 'I want to Invest';
                    _this.roboButton = 'Help me Invest';
                    _this.investNowButton = _this.iKnowWhatIWantEnabled ? 'Apply Now' : 'Help me Invest';
                }
                else {
                    // Banker
                    _this.showFundsButton = 'Our Funds';
                    _this.roboButton = 'Robo Advisor';
                    _this.investNowButton = 'Robo Advisor';
                }
            }
            if (authenticated) {
                if (isDirect) {
                    if (isEligible) {
                        _this.showGetStartedButton = true;
                    }
                    else {
                        _this.showGetStartedButton = false;
                    }
                }
                else {
                    // Banker
                    _this.showGetStartedButton = true;
                }
            }
            else {
                _this.showGetStartedButton = true;
            }
        });
        route.queryParams.pipe(_this.scavenger.collect()).subscribe(function (params) {
            if (params['showFunds'] === 'true') {
                var pageScrollInstance = PageScrollInstance.simpleInstance(_this.document, '#tabs');
                scrollService.start(pageScrollInstance);
            }
            if (params['openLeadForm'] === 'true') {
                _this.contactUs();
            }
        });
        return _this;
    }
    SalesComponent.prototype.ngOnInit = function () {
        this.sharedStore.dispatch(new StopLoadingAction());
    };
    SalesComponent.prototype.contactUs = function () {
        this.modalService.open(ContactBankerModalComponent, { windowClass: 'large-modal' });
    };
    SalesComponent.prototype.customerSelected = function () {
        return this.selectedCustomer;
    };
    SalesComponent.prototype.helpMe = function () {
        if (this.authenticated) {
            if (this.isDirect) {
                if (this.isEligible) {
                    this.router.navigateByUrl('/secure/investor/robo');
                }
                else {
                    this.contactUs();
                }
            }
            else {
                if (this.customerSelected()) {
                    this.router.navigateByUrl('/secure/banker/robo');
                }
                else {
                    this.router.navigateByUrl('/secure/banker');
                }
            }
        }
        else {
            this.contactUs();
        }
    };
    SalesComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    SalesComponent.prototype.getIcon = function (fundIcon) {
        return "assets/icon/funds/" + fundIcon + ".svg";
    };
    SalesComponent.prototype.select = function (fundId) {
        this.selectedFund = this.funds.find(function (fund) { return fund.fundId === fundId; });
    };
    SalesComponent.prototype.investInFund = function (fundId) {
        if (this.authenticated && this.isEligible) {
            var fund = this.funds.filter(function (f) { return f.fundId === fundId; })[0];
            this.pstStore.dispatch(new FundSelectedAction(fund));
            var pstResult = {
                fundCode: fund.pstCode,
                pstAdvised: false,
                portfolioTypeId: appConfig.portfolioTypes.INV,
                reference: 'NOT_ADVISED',
                contributionIncrease: DEFAULT_ESCALATION,
            };
            this.pstStore.dispatch(new PstAdviceShownAction(pstResult));
            this.pstStore.dispatch(new PstCompletedAction(pstResult));
            this.router.navigateByUrl('/secure/investor/application');
        }
        else {
            this.contactUs();
        }
    };
    return SalesComponent;
}(BaseComponent));
export { SalesComponent };
