export enum CustomerFilterReason {
    kyc = 3,
    email = 4,
    privateIndividual = 5,
    southAfricanId = 6,
    ucnCountry = 7,
    minor = 8,
    deceased = 9,
    accounts = 10,
    taxResidency = 11,
    fnbAccount = 12,
    undesirable = 13,
    crsCompliant = 14,
    foreignTaxIndicatorValue = 15,
    foreignTaxResidency = 16
}
