import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PstContentModalComponent } from 'pst/components/content-modal/pst-content-modal.component';
import { PstResultContent } from 'pst/models';
import { TaxFreeDisclaimerModalComponent } from 'robo/components/goal-selection/tax-free-disclaimer-modal';
import { Store } from '@ngrx/store';
import { Store as NgxsStore } from '@ngxs/store';
import { ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { RoboGoal, User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { RoboFieldId, RoboFieldType } from '../../models';
import { RoboClearScreenAction, RoboUpdateFieldAction, TaxFreeOptOutAction } from '../../store';

import { GoalSelectionOtherNameModalComponent } from './other-name-modal';

@Component({
    selector: 'wim-robo-goal-selection',
    templateUrl: 'goal-selection.component.pug',
    styleUrls: ['goal-selection.component.scss'],
})
export class RoboGoalSelectionComponent extends BaseComponent implements OnInit {
    public goals$: Observable<RoboGoal[]>;
    public user: Observable<User>;
    public goalForm: FormGroup;
    public customerName: string;
    public error: string = null;
    public isDirect = false;
    private hasExistingTfsa = false;
    private hasInFlightTfsa = false;
    private content: PstResultContent[] = [];  

    constructor(
        loggerService: LoggerService,
        private lookupDataService: LookupDataService,
        private modalService: NgbModal,
        private sharedStore: Store<SharedState>,
        private applicationStore: Store<ApplicationState>,
        private formBuilder: FormBuilder,
        private router: Router,
        private store: NgxsStore,
        private ngbModal: NgbModal
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.goals$ = this.lookupDataService.getRoboGoals();

        this.lookupDataService
            .getPstResultContent()
            .pipe(this.scavenger.collect())
            .subscribe(content => {
                this.content = content;
            });        

        let customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        let isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        this.store.dispatch(new RoboClearScreenAction());
        this.store.dispatch(new TaxFreeOptOutAction(false));

        combineLatest(customer$, isDirect$, (customer, isDirect) => {
            return { customer, isDirect };
        })
            .pipe(
                this.scavenger.collect(),
                catchError(error => {
                    this.error = error;
                    return of(error);
                })
            )
            .subscribe(({ customer, isDirect }) => {
                this.isDirect = isDirect;
                if(isDirect) {
                    this.goals$ = this.goals$.pipe(map(goals => goals.filter(goal => goal.goalId !== 11)));
                }                
                if (customer === null) {
                    if (isDirect === false) {
                        this.logger.warn('Cannot give advice before selecting a customer, navigating to customer search');
                        this.router.navigateByUrl('/secure/banker/customer-search');
                        this.sharedStore.dispatch(new StopLoadingAction());
                        return;
                    } else if (isDirect === true) {
                        this.logger.warn('Cannot give advice before selecting a customer, navigating to SSO screen to lookup customer');
                        this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
                        return;
                    }
                }
                this.customerName = `${customer.title} ${customer.firstname} ${customer.lastname}`;
                this.hasExistingTfsa = customer.hasExistingTaxFreeAccount;
                this.hasInFlightTfsa = customer.hasInFlightTaxFreeApplication;                
            });

        this.goals$
            .pipe(
                this.scavenger.collect(),
                catchError(error => {
                    this.error = error;
                    return of(error);
                })
            )
            .subscribe(() => {
                this.createForm();
                this.sharedStore.dispatch(new StopLoadingAction());
            });

        // // TODO: When we actually know if this is a minor then set it here
        // this.store.dispatch(
        //     new RoboUpdateFieldAction({
        //         fieldId: RoboFieldId.isMinor,
        //         value: 'false',
        //     })
        // );
    }

    public ngOnDestroy() {}

    public retry() {
        this.ngOnInit();
    }

    private routeTo(route) {
        let userType = this.isDirect ? 'investor' : 'banker';
        let routeUrl = route.replace('{user}', userType);
        this.router.navigateByUrl(routeUrl);
    }

    public cancel() {
        this.routeTo('secure/{user}');
    }

    public start() {
        this.routeTo('secure/{user}/robo/advice');
    }

    public radioClicked(node) {
        this.goalForm.get('selectedGoal').setValue(node);
    }

    public isIcon(goal: RoboGoal) {
        return goal.iconName != null && goal.iconName !== '';
    }

    public iconPath(goal: RoboGoal): string {
        return `assets/icon/pst/${goal.iconName}.svg`;
    }

    public isContent(id: number) {
        return !!this.content.find(c => c.id === id);
    }

    public displayContentPopup(id: number) {
        if (!this.isContent(id)) {
            return;
        }
        let content = this.content.find(c => c.id === id);

        const modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.result
                .then(() => {
                    this.goalForm.get('selectedGoal').setValue(null);
                })
                .catch(() => {
                    this.goalForm.get('selectedGoal').setValue(null);
                });           
        modalRef.componentInstance.content = content.content;
    }
    
    public showTaxFreeDisclaimer() {
        let modal = this.modalService.open(TaxFreeDisclaimerModalComponent, { windowClass: 'large-modal' });
        modal.result
            .then(() => {
                this.start();
            })
            .catch(() => {
                this.goalForm.get('selectedGoal').setValue(null);
            });        
    }


    private createForm() {
        this.goalForm = this.formBuilder.group({
            selectedGoal: [null, Validators.required],
        });
        this.goalForm.valueChanges.subscribe(changes => {
            if (changes.selectedGoal) {
                let selectedGoal = changes.selectedGoal as RoboGoal;
                this.store.dispatch(
                    new RoboUpdateFieldAction({
                        fieldId: RoboFieldId.goal,
                        value: `${selectedGoal.goalId}`,
                    })
                );
            }
        });
        this.goalForm.valueChanges.pipe(this.scavenger.collect()).subscribe(values => {
            if (values.selectedGoal) {
                if(values.selectedGoal.goalId === 11) {
                    if(this.hasExistingTfsa) {
                        this.displayContentPopup(11);
                    }
                    else if (this.hasInFlightTfsa) {
                        this.displayContentPopup(12);
                    } 
                    else {
                        this.showTaxFreeDisclaimer();
                    }
                }
                else {
                    this.start();
                }
            }
        });
    }
}
