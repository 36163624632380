import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { AboutUsComponent } from 'sales/components/about';
import { ContactUsComponent } from 'sales/components/contact';
import { SalesComponent } from 'sales/components/sales';

export const salesRoutes: Routes = [
    { path: '', component: SalesComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'about-us', component: AboutUsComponent },
];

@NgModule({
    imports: [RouterModule.forChild(salesRoutes), ReactiveFormsModule, NgxErrorsModule],
    exports: [RouterModule],
})
export class SalesRoutingModule {}
