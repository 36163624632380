import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';
import { InvestorHelperToggleAction, LogoutRequestAction, SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-investor-helper',
    templateUrl: 'investor-helper.component.pug',
    styleUrls: ['investor-helper.component.scss'],
})
export class InvestorHelperComponent extends BaseComponent implements OnInit, OnDestroy {
    public helperVisible: boolean;
    public authenticated: Observable<boolean>;
    public notAuthenticated: boolean;
    public sideBarLogo = require('assets/images/logo/fnb-wim-logo.svg');

    public links = [
        {
            text: 'I want to Invest',
            route: ['/invest'],
            params: { showFunds: true },
        },
        {
            text: 'About Us',
            route: ['/invest/about-us'],
            params: {},
        },
        {
            text: 'Get in touch',
            route: ['/invest/contact-us'],
            params: {},
        },
    ];

    public investorLinks = [
        {
            route: '/secure/investor/investor-home',
            text: 'Accounts',
            params: {},
        },
        {
            route: '/secure/investor/investor-instructions',
            text: 'Instructions',
            params: {},
        },
        {
            route: '/secure/investor/documents',
            text: 'Documents',
            params: {},
        },
        {
            route: '/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW',
            text: 'Apply Now',
            params: {},
        },
    ];

    private storeSubscription: Subscription;
    constructor(private store: Store<SharedState>, loggerService: LoggerService) {
        super(loggerService);
    }

    public ngOnInit() {
        this.authenticated = this.store.select(fromShared.selectAuthenticated);

        this.store
            .select(fromShared.selectInvestorHelper)
            .pipe(this.scavenger.collect())
            .subscribe(canShow => (this.helperVisible = canShow ? canShow.isVisible : false));
    }
    public ngOnDestroy() {}

    public toggleClose() {
        this.store.dispatch(new InvestorHelperToggleAction());
    }

    public logout() {
        this.store.dispatch(new LogoutRequestAction());
    }
}
