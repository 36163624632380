import { enableProdMode } from '@angular/core';
import appConfig from 'appConfig';
import 'hammerjs';
import 'reflect-metadata';
import { decorateModuleRef } from './app/environment';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (appConfig.env !== 'Default') {
    enableProdMode();
}
var bootstrap = function () {
    return __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .then(decorateModuleRef);
};
var ɵ0 = bootstrap;
if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else {
    bootstrap().catch(function (err) { return console.log(err); });
}
export { ɵ0 };
