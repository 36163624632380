import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest ,  Observable } from 'rxjs';
import { BaseComponent } from 'shared/components';
import { MenuToggle } from 'shared/models';
import { LoggerService } from 'shared/services';
import { InvestorHelperToggleAction, LogoutRequestAction, SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-navigation',
    styleUrls: ['./navigation.component.scss'],
    templateUrl: 'navigation.component.pug',
})
export class NavigationComponent extends BaseComponent {
    public isBanker: boolean;
    public authenticated: Observable<boolean>;
    private menus: MenuToggle[];

    public showSmallHeader = false;
    public showTransparentHeader = false;
    public investorHelperToggled = false;

    constructor(private store: Store<SharedState>, private router: Router, loggerService: LoggerService) {
        super(loggerService);

        this.authenticated = store.select(fromShared.selectAuthenticated);

        this.store
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(usr => (this.isBanker = usr ? usr.isBanker : false));

        this.store
            .select(fromShared.selectMenus)
            .pipe(this.scavenger.collect())
            .subscribe(menus => (this.menus = menus));

        this.store
            .select(fromShared.selectInvestorHelper)
            .pipe(this.scavenger.collect())
            .subscribe(investorHelperState => (this.investorHelperToggled = investorHelperState && investorHelperState.isVisible));

        const showSmallHeader$ = store.select(fromShared.selectShowSmallHeader);
        const showTransparentHeader$ = store.select(fromShared.selectShowTransparentHeader);

        combineLatest(showSmallHeader$, showTransparentHeader$, (showSmallHeader, showTransparentHeader) => {
            return { showSmallHeader, showTransparentHeader };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ showSmallHeader, showTransparentHeader }) => {
                this.showSmallHeader = showSmallHeader;
                this.showTransparentHeader = !showSmallHeader && showTransparentHeader;
            });
    }

    public ngOnDestroy() {}

    public isOnboardingActive() {
        return (
            this.router.isActive('/secure/banker/pst', false) ||
            this.router.isActive('/secure/banker/robo', false) ||
            this.router.isActive('/secure/banker/customer-search', false) ||
            this.router.isActive('/secure/banker/application', false) ||
            this.router.isActive('/invest', false)
        );
    }

    public isDirectPstActive() {
        return (
            this.router.isActive('/secure/investor/pst', false) ||
            this.router.isActive('/secure/investor/robo', false) ||
            this.router.isActive('/secure/investor/application', false) ||
            this.router.isActive('/invest', false)
        );
    }

    public logout() {
        this.store.dispatch(new LogoutRequestAction());
    }

    public investDirect() {
        this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
        return;
    }

    public isMenuVisible(menuName: string) {
        const toggle = this.menus.filter(menu => menu.name === menuName)[0];
        return toggle && toggle.visible;
    }

    public toggleCustomerHelp() {
        this.store.dispatch(new InvestorHelperToggleAction());
    }
}
