var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CustomerSelectedAction } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { ToastrService } from 'ngx-toastr';
import { PstService } from 'pst/services';
import { ResetPstStateAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BaseComponent, SharedModalComponent } from 'shared/components';
import { LoggerService } from 'shared/services';
import { LayoutEffects, LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
// import { DisclaimerModalComponent } from '../disclaimer-modal/disclaimer-modal.component';
var ROUTE_INVEST_NOW = '/secure/{user}/application';
var ROUTE_PST = '/secure/{user}/pst';
var ROUTE_SEARCH = '/secure/{user}/customer-search';
var ROUTE_HOME = '/secure/{user}';
var PstNavigationComponent = /** @class */ (function (_super) {
    __extends(PstNavigationComponent, _super);
    function PstNavigationComponent(pstService, pstStore, bankerStore, sharedStore, applicationStore, router, modalService, loggerService, toastr, layoutEffects) {
        var _this = _super.call(this, loggerService) || this;
        _this.pstService = pstService;
        _this.pstStore = pstStore;
        _this.bankerStore = bankerStore;
        _this.sharedStore = sharedStore;
        _this.applicationStore = applicationStore;
        _this.router = router;
        _this.modalService = modalService;
        _this.toastr = toastr;
        _this.layoutEffects = layoutEffects;
        _this.isDirect = false;
        _this.OK_TO_CANCEL = 'Yes Cancel';
        return _this;
    }
    PstNavigationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pstStore
            .select(fromPst.selectPstTree)
            .pipe(this.scavenger.collect())
            .subscribe(function (tree) { return (_this.tree = tree); });
        this.pstStore
            .select(fromPst.selectPstError)
            .pipe(this.scavenger.collect())
            .subscribe(function (error) { return (_this.error = error); });
        this.pstStore
            .select(fromPst.selectUserPstSelection)
            .pipe(this.scavenger.collect())
            .subscribe(function (selection) { return (_this.userSelection = selection); });
        this.applicationStore
            .select(fromApplication.selectHorizonApplication)
            .pipe(this.scavenger.collect())
            .subscribe(function (app) { return (_this.currentApplication = app); });
        this.pstStore
            .select(fromPst.selectCompletedPst)
            .pipe(this.scavenger.collect())
            .subscribe(function (pstResult) { return (_this.pstResult = pstResult); });
        this.sharedStore
            .select(fromShared.selectIsDirect)
            .pipe(this.scavenger.collect())
            .subscribe(function (isDirect) { return (_this.isDirect = isDirect); });
        this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .pipe(this.scavenger.collect())
            .subscribe(function (customer) { return (_this.customer = customer); });
        // Disabled for now
        // this.pstStore
        //     .select(fromPst.selectEnterPressed)
        //     .pipe(this.scavenger.collect())
        //     .subscribe(pressed => {
        //         if (pressed) {
        //             this.next();
        //         }
        //     });
        this.onResult$ = this.pstStore.select(fromPst.selectCompletedPst).pipe(map(function (result) { return !!result; }));
    };
    PstNavigationComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    PstNavigationComponent.prototype.next = function () {
        if (this.tree) {
            this.pstService.traverseTreeForwards(this.tree.transactionref, this.tree.node, this.userSelection.selectedNode, this.userSelection.value);
        }
        else {
            this.restart();
        }
    };
    PstNavigationComponent.prototype.startInvesting = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    PstNavigationComponent.prototype.restart = function () {
        this.pstService.startNewTree();
        this.routeToUserSpecificRoute(ROUTE_PST);
    };
    PstNavigationComponent.prototype.cancel = function () {
        var _this = this;
        this.warningRef = this.modalService.open(SharedModalComponent);
        this.warningRef.componentInstance.modalMessage = "Please note product selection results will not be saved ,\n        and should you wish to revisit this application you will be required to restart the advisory process";
        this.warningRef.componentInstance.noValue = 'No';
        this.warningRef.componentInstance.yesValue = 'Yes, Cancel Process';
        this.warningRef.componentInstance.modalTitle = 'Confirm  Cancellation';
        this.warningRef.result.then(function (resultType) {
            if (resultType === _this.OK_TO_CANCEL) {
                _this.cancelConfirmed();
            }
        });
    };
    PstNavigationComponent.prototype.save = function () {
        var _this = this;
        var savedPstResult = {
            customerUcn: this.customer.ucn,
            pstReference: this.pstResult.reference,
            goalName: this.pstResult.goalName,
            goalAmount: this.pstResult.goalAmount,
            debitOrder: this.pstResult.clientInstallment,
            lumpSum: this.pstResult.lumpSum,
            annualEscalation: this.pstResult.contributionIncrease,
            termMonths: this.pstResult.term,
            fundCode: this.pstResult.fundCode,
            portfolioTypeId: this.pstResult.portfolioTypeId,
        };
        this.sharedStore.dispatch(new LoadingAction());
        this.pstService
            .saveResult(savedPstResult)
            .pipe(finalize(function () {
            _this.sharedStore.dispatch(new StopLoadingAction());
        }), catchError(function (error) {
            _this.logger.error(error);
            _this.toastr.error('The robo-advisor result could not be saved, please try again later.');
            return of();
        }))
            .subscribe(function () {
            _this.toastr.success('The robo-advisor result was successfully saved. It can be retrieved for the next 5 days when searching for a customer');
            _this.clearPst();
            _this.routeToUserSpecificRoute(ROUTE_SEARCH);
        });
    };
    PstNavigationComponent.prototype.email = function () { };
    PstNavigationComponent.prototype.cancelConfirmed = function () {
        this.warningRef.dismiss();
        this.clearPst();
        if (this.isDirect) {
            this.routeToUserSpecificRoute(ROUTE_HOME);
        }
        else {
            this.routeToUserSpecificRoute(ROUTE_SEARCH);
        }
    };
    PstNavigationComponent.prototype.clearPst = function () {
        this.bankerStore.dispatch(new CustomerSelectedAction(null));
        this.pstStore.dispatch(new ResetPstStateAction());
    };
    PstNavigationComponent.prototype.routeToUserSpecificRoute = function (route) {
        var userType = this.isDirect ? 'investor' : 'banker';
        var routeUrl = route.replace('{user}', userType);
        this.router.navigateByUrl(routeUrl);
    };
    return PstNavigationComponent;
}(BaseComponent));
export { PstNavigationComponent };
