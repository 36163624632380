import { PstResult, SavedPstResult } from 'pst/models';

import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { LoggerService } from 'shared/services/logger.service';

import { BaseService } from 'shared/components';

import {
    GraphLines,
    GraphOptions,
    InstallmentCalculatorOptions,
    InvestmentAccountCalculationResult,
    JustInvestOptions,
    Prediction,
    PstFund,
    PstResultType,
    ScenarioMonth,
} from 'pst/services/ia-calculator/models';
import { funds } from 'pst/services/ia-calculator/scenarios';

const minDebitOrder = 500;

@Injectable()
export class InvestmentAccountCalculator extends BaseService {
    public static extractCapturedValues(pstResult: PstResult) {
        pstResult.goalName = pstResult.capturedValues['GOAL_NAME'];
        pstResult.term = +pstResult.capturedValues['GOAL_MONTHS'] || 0;
        pstResult.goalAmount = +pstResult.capturedValues['GOAL_AMOUNT'] || 0;
        pstResult.clientInstallment = +pstResult.capturedValues['DEBIT_AMOUNT'] || 0;
        pstResult.contributionIncrease = (+pstResult.capturedValues['YEARLY_ESCALATION'] || 0) / 100.0;
        pstResult.lumpSum = +pstResult.capturedValues['LUMP_SUM'] || 0;
        // PST reducer needs these to use the mock properly
        pstResult.accessType = pstResult.capturedValues['ACCESS_TYPE'];
        pstResult.noticePeriod = pstResult.capturedValues['NOTICE_PERIOD'];
        // -<
    }

    public static injectCapturedValues(pstResult: PstResult, save: SavedPstResult) {
        pstResult.capturedValues['GOAL_NAME'] = save.goalName;
        pstResult.capturedValues['GOAL_MONTHS'] = save.termMonths;
        pstResult.capturedValues['GOAL_AMOUNT'] = save.goalAmount;
        pstResult.capturedValues['DEBIT_AMOUNT'] = save.debitOrder;
        pstResult.capturedValues['YEARLY_ESCALATION'] = save.annualEscalation * 100;
        pstResult.capturedValues['LUMP_SUM'] = save.lumpSum;
        pstResult.portfolioTypeId = save.portfolioTypeId;
        // PST reducer needs these to use the mock properly
        pstResult.capturedValues['ACCESS_TYPE'] = save.accessType;
        pstResult.capturedValues['NOTICE_PERIOD'] = save.noticePeriod;
        // -<
    }

    constructor(loggerService: LoggerService = null) {
        super(loggerService);
    }

    public ngOnDestroy() {}

    public calculate(pstResult: PstResult, annualCosts: number, resultType: PstResultType): InvestmentAccountCalculationResult {
        this.logger.debug('PST Result', pstResult);

        const result = new InvestmentAccountCalculationResult();
        let graphData = null;
        let term = pstResult.term / 12;
        result.originalMonthlyContribution = pstResult.clientInstallment;

        result.calculatedMonthlyContribution = this.calculateInstallment({
            annualIncrease: pstResult.contributionIncrease,
            fundCode: pstResult.fundCode,
            goalAmount: pstResult.goalAmount,
            lumpsum: pstResult.lumpSum || 0,
            selectedTerm: term,
            annualCosts,
        });
        result.calculatedMonthlyContribution = Math.ceil(result.calculatedMonthlyContribution);
        pstResult.advisedInstallment = result.calculatedMonthlyContribution;

        const graphOptions: GraphOptions = {
            annualIncrease: pstResult.contributionIncrease,
            fundCode: pstResult.fundCode,
            goalAmount: pstResult.goalAmount,
            lumpsum: pstResult.lumpSum,
            term,
            annualCosts,
            clientInstallment: pstResult.clientInstallment,
            advisedInstallment:
                resultType === PstResultType.Installment ? result.calculatedMonthlyContribution : pstResult.clientInstallment,
        };
        graphData = this.calculateGraphData(graphOptions);
        result.bestCase = graphData.up[graphData.up.length - 1];
        result.worstCase = graphData.down[graphData.down.length - 1];
        result.estimatedFutureValue = this.calculateJustInvestFromGraph(graphData);
        pstResult.futureValue = result.estimatedFutureValue;

        result.graph = this.createChart(graphData, resultType, result.originalMonthlyContribution, result.calculatedMonthlyContribution);

        result.pstResult = pstResult;

        return result;
    }

    public createChart(graphData: GraphLines, pstResultType: PstResultType, clientDebitOrder: number, calculatedDebitOrder: number): Chart {
        const chartConfig: Highcharts.Options = {
            credits: {
                enabled: false,
            },
            chart: {
                type: 'spline',
            },
            title: {
                text: '',
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                title: {
                    text: 'Years',
                },
                labels: {
                    overflow: 'justify',
                },
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: 'Value (R)',
                },
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                alternateGridColor: null,
            },

            plotOptions: {
                spline: {
                    lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 4,
                        },
                    },
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    tooltip: {
                        headerFormat: '',
                        pointFormat: `<span style="font-weight:bold">{series.name}</span><br/>Year {point.x}<br/>R {point.y}`,
                    },
                },
            },

            navigation: {
                menuItemStyle: {
                    fontSize: '10px',
                },
            },
            legend: {
                enabled: false,
            },
        };

        let series: any[];
        const downScenario = {
            name: 'Worst Case Scenario',
            data: graphData.down,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        const upScenario = {
            name: 'Best Case Scenario',
            data: graphData.up,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        const clientScenario = {
            name: 'Your Contribution',
            data: graphData.clientScenario,
            color: '#01aaad',
        };

        if (pstResultType === PstResultType.Installment) {
            series = [
                {
                    name: 'Goal Amount',
                    data: graphData.goal,
                    color: '#007072',
                    dashStyle: 'longdash',
                },
                downScenario,
                {
                    name: 'Recommended Contribution',
                    data: graphData.expected,
                    color: '#ff9900',
                },
                clientScenario,
                upScenario,
            ];
        } else {
            series = [downScenario, clientScenario, upScenario];
        }
        chartConfig.series = series;
        return new Chart(chartConfig);
    }

    public calculateInstallment(options: InstallmentCalculatorOptions): number {
        const fund = this.lookupFund(options.fundCode);
        this.logger.debug(`Using fund with code ${options.fundCode}`);
        const scenario = fund.scenarios[options.selectedTerm];
        const realisedReturn = this.calculateRealisedAnnualReturn(scenario, options.annualCosts);
        const newGoal = this.calculateGoalLessLumpsum(options.goalAmount, options.lumpsum, realisedReturn, options.selectedTerm);
        this.logger.debug(`New goal after taking lumpsum future value into account is R ${newGoal}`);
        const yearlyRecurring = this.calculateFirstYearRecurring(
            newGoal,
            options.selectedTerm,
            scenario,
            options.annualIncrease,
            options.annualCosts
        );
        const installment = this.round(
            this.calculateMonthlyRecurring(yearlyRecurring, this.calculateInterestRate(scenario, options.annualCosts))
        );
        // Allow for some leeway to prevent a R500 installment when the rounding errors create a needed installment of a few cents
        if (installment > 5) {
            return installment < minDebitOrder ? minDebitOrder : installment;
        } else {
            return 0;
        }
    }

    public calculateJustInvest(options: JustInvestOptions) {
        const graphData = this.calculateGraphData({
            fundCode: options.fundCode,
            advisedInstallment: options.installment,
            clientInstallment: options.installment,
            lumpsum: options.lumpsum,
            term: options.term,
            goalAmount: 0,
            annualIncrease: options.annualIncrease,
            annualCosts: options.annualCosts,
        });
        const value = graphData.expected[graphData.expected.length - 1];
        return this.round(value / 100, 0) * 100;
    }

    public calculateJustInvestFromGraph(graphData: GraphLines) {
        this.logger.debug('Taking last point in exected scenario graph line for Future Value');
        return graphData.clientScenario[graphData.expected.length - 1];
    }

    public calculateGraphData(options: GraphOptions): GraphLines {
        this.logger.debug('Calculating graph');
        const result = new GraphLines();
        const fund = this.lookupFund(options.fundCode);
        let previousMonth: Prediction;
        let calculatedDebitOrder = options.advisedInstallment;
        let originalDebitOrder = options.clientInstallment;

        for (let month = 0; month < options.term * 12; month++) {
            if (month === 0) {
                result.down.push(options.lumpsum + options.advisedInstallment);
                result.up.push(options.lumpsum + options.advisedInstallment);
                result.expected.push(options.lumpsum + options.advisedInstallment);
                result.clientScenario.push(options.lumpsum + options.clientInstallment);
                result.goal.push(options.goalAmount);
            }
            const prediction: Prediction = this.calcMonth(
                month,
                options.lumpsum,
                calculatedDebitOrder,
                originalDebitOrder,
                fund.scenarios[options.term],
                previousMonth,
                options.annualCosts
            );
            previousMonth = prediction;

            if ((month + 1) % 12 === 0) {
                calculatedDebitOrder = this.increaseByPercent(calculatedDebitOrder, options.annualIncrease);
                originalDebitOrder = this.increaseByPercent(originalDebitOrder, options.annualIncrease);

                result.down.push(prediction.down);
                result.expected.push(prediction.expected);
                result.clientScenario.push(prediction.client);
                result.up.push(prediction.up);
                result.goal.push(options.goalAmount);
            }
        }
        return result;
    }

    private increaseByPercent(amount: number, percent: number) {
        return amount + amount * percent;
    }

    public calcMonth(
        month: number,
        lumpsum: number,
        calculatedDebitOrder: number,
        originalDebitOrder: number,
        scenario: ScenarioMonth,
        previousMonth: Prediction,
        annualCosts: number
    ): Prediction {
        let beginning: Prediction;

        if (month === 0) {
            beginning = {
                down: lumpsum + calculatedDebitOrder,
                expected: lumpsum + calculatedDebitOrder,
                client: lumpsum + originalDebitOrder,
                up: lumpsum + calculatedDebitOrder,
            };
        } else {
            beginning = {
                down: previousMonth.down + calculatedDebitOrder,
                expected: previousMonth.expected + calculatedDebitOrder,
                client: previousMonth.client + originalDebitOrder,
                up: previousMonth.up + calculatedDebitOrder,
            };
        }

        const monthlyCosts = {
            down: (annualCosts * beginning.down) / 12,
            expected: (annualCosts * beginning.expected) / 12,
            client: (annualCosts * beginning.client) / 12,
            up: (annualCosts * beginning.up) / 12,
        };

        const investmentReturn = {
            down: this.round(beginning.down * scenario.down),
            expected: this.round(beginning.expected * scenario.expected),
            client: this.round(beginning.client * scenario.expected),
            up: this.round(beginning.up * scenario.up),
        };
        const result = {
            down: this.round(beginning.down - monthlyCosts.down + investmentReturn.down, 0),
            expected: this.round(beginning.expected - monthlyCosts.expected + investmentReturn.expected, 0),
            client: this.round(beginning.client - monthlyCosts.client + investmentReturn.client, 0),
            up: this.round(beginning.up - monthlyCosts.up + investmentReturn.up, 0),
        };
        return result;
    }

    public calculateGoalLessLumpsum(goal: number, lumpsum: number, realisedAnnualReturn: number, term: number) {
        if (lumpsum === 0) {
            return goal;
        }
        return goal - lumpsum * Math.pow(1 + realisedAnnualReturn, term);
    }

    public calculatePresentValue(amount: number, termYears: number, rate: number) {
        return this.round(amount / Math.pow(1 + rate / 12, termYears * 12));
    }

    public calculateRealisedAnnualReturn(scenario: ScenarioMonth, annualCosts: number) {
        return Math.pow(1 + (scenario.expected - annualCosts / 12), 12) - 1;
    }

    public calculateInterestRate(scenario: ScenarioMonth, annualCosts: number) {
        return (Math.pow(1 + this.calculateRealisedAnnualReturn(scenario, annualCosts), 1 / 12) - 1) * 12;
    }

    public calculateEffectiveRate(interestRate: number): number {
        return Math.pow(1 + interestRate / 12, 12) - 1;
    }

    public calculateRecurringRate(effectiveRate: number, annualIncrease: number): number {
        return ((effectiveRate - annualIncrease) * 100) / (1 + annualIncrease) / 100;
    }

    public calculateFirstYearRecurring(goal: number, term: number, scenario: ScenarioMonth, annualIncrease: number, annualCosts: number) {
        const interest = this.calculateInterestRate(scenario, annualCosts);
        this.logger.debug(`Interest Rate`, interest);
        const presentValue = this.calculatePresentValue(goal, term, interest);
        this.logger.debug(`PV`, presentValue);
        const effectiveRate = this.calculateEffectiveRate(interest);
        this.logger.debug(`Effective Rate`, effectiveRate);
        const recurringRate = this.calculateRecurringRate(effectiveRate, annualIncrease);
        this.logger.debug(`Recurring Rate`, recurringRate);
        const value = presentValue * ((recurringRate / (1 - Math.pow(1 + recurringRate, -term))) * (1 / (1 + recurringRate)));
        const firstYearRecurring = this.round(value);
        this.logger.debug('First Year Recurring', firstYearRecurring);
        return firstYearRecurring;
    }

    public calculateMonthlyRecurring(yearlyRecurring: number, interestRate: number) {
        const monthlyInterest = interestRate / 12;
        const value = yearlyRecurring * ((monthlyInterest / (1 - Math.pow(1 + monthlyInterest, -12))) * (1 / (1 + monthlyInterest)));
        const monthlyRecurring = this.round(value);
        this.logger.debug(`Monthly recurring: ${monthlyRecurring}`);
        return monthlyRecurring;
    }

    private round(value: number, decimals = 2): number {
        const roundTo = Math.pow(10, decimals);
        return Math.round(value * roundTo) / roundTo;
    }

    private lookupFund(code: string): PstFund {
        return funds[code];
    }
}
