/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../investor-helper/investor-helper.component.ngfactory";
import * as i5 from "../investor-helper/investor-helper.component";
import * as i6 from "@ngrx/store";
import * as i7 from "../../shared/services/logger.service";
import * as i8 from "./navigation.component";
var styles_NavigationComponent = [i0.styles];
var RenderType_NavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationComponent, data: {} });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
function View_NavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"], ["routerLink", "/secure/investor/investor-home"], ["routerLinkActive", "active"], ["style", "width:100px;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Accounts"]))], function (_ck, _v) { var currVal_2 = "/secure/investor/investor-home"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"], ["routerLink", "/secure/investor/pending-agreements"], ["routerLinkActive", "active"], ["style", "width:100px;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Ts&Cs"]))], function (_ck, _v) { var currVal_2 = "/secure/investor/pending-agreements"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"], ["routerLink", "/secure/investor/investor-instructions"], ["routerLinkActive", "active"], ["style", "width:100px;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Instructions"]))], function (_ck, _v) { var currVal_2 = "/secure/investor/investor-instructions"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NavigationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"], ["routerLink", "/secure/investor/documents"], ["routerLinkActive", "active"], ["style", "width:100px;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[8, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Documents"]))], function (_ck, _v) { var currVal_2 = "/secure/investor/documents"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NavigationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"], ["routerLink", "/secure/banker/customer-search"], ["style", "width:100px;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Onboarding"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"; var currVal_3 = _ck(_v, 3, 0, _co.isOnboardingActive()); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = "/secure/banker/customer-search"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "menu-box hidden-sm-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_4)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_5)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_6)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuVisible("accounts"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isMenuVisible("termsconditions"); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isMenuVisible("instructions"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isMenuVisible("documents"); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.isMenuVisible("onboarding"); _ck(_v, 10, 0, currVal_4); }, null); }
function View_NavigationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"], ["href", "#"], ["style", "width:100px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.investDirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵted(-1, null, ["Invest Now"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mx-1 btn btn-rounded btn-sm btn-themecolor-nav waves-effect waves-orange font-bold"; var currVal_1 = _ck(_v, 3, 0, _co.isDirectPstActive()); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NavigationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invest-now hidden-sm-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_8)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuVisible("apply"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavigationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "mx-1 btn btn-rounded btn-sm btn-themecolor-sec waves-effect waves-orange font-bold logout-button"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], null, null); }
export function View_NavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "header", [["class", "header trans-200"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "scrolled": 0, "see-through": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 25, "div", [["class", "header-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "logo-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "logo-icon"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "assets/images/logo/FNB-S-Logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "spacer hidden-lg-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavigationComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavigationComponent_7)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "logout hidden-sm-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavigationComponent_9)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 9, "div", [["class", "hamburger py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "mm-menu-toggle"], ["id", "mm-menu-toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCustomerHelp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { "active open": 0 }), (_l()(), i1.ɵeld(23, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "wim-investor-helper", [], null, null, null, i4.View_InvestorHelperComponent_0, i4.RenderType_InvestorHelperComponent)), i1.ɵdid(28, 245760, null, 0, i5.InvestorHelperComponent, [i6.Store, i7.LoggerService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "header trans-200"; var currVal_1 = _ck(_v, 2, 0, _co.showSmallHeader, _co.showTransparentHeader); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = "/"; _ck(_v, 6, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.authenticated)); _ck(_v, 10, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.authenticated)); _ck(_v, 13, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.authenticated)); _ck(_v, 17, 0, currVal_7); var currVal_8 = "mm-menu-toggle"; var currVal_9 = _ck(_v, 22, 0, _co.investorHelperToggled); _ck(_v, 21, 0, currVal_8, currVal_9); _ck(_v, 28, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 6).target; var currVal_3 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_2, currVal_3); }); }
export function View_NavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wim-navigation", [], null, null, null, View_NavigationComponent_0, RenderType_NavigationComponent)), i1.ɵdid(1, 180224, null, 0, i8.NavigationComponent, [i6.Store, i2.Router, i7.LoggerService], null, null)], null, null); }
var NavigationComponentNgFactory = i1.ɵccf("wim-navigation", i8.NavigationComponent, View_NavigationComponent_Host_0, {}, {}, []);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
