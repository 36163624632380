import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ServerTimeService } from 'shared/services/server-time.service';

@Injectable()
export class ServerTimeInterceptor implements HttpInterceptor {
    constructor(private serverTimeService: ServerTimeService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(response => {
                if (response instanceof HttpResponse) {
                    this.serverTimeService.setServerTime(response.headers.get('date'));
                }
            })
        );
    }
}
