var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { AccountsService } from 'investor/services/accounts.service';
import { FnbSecuritiesService } from 'investor/services/fnbsecurities.service';
import { LOGIN_SUCCESS } from 'shared/store';
import { map, mergeMap } from 'rxjs/operators';
var InvestorEffects = /** @class */ (function () {
    function InvestorEffects(actions$, accountService, fnbsService) {
        var _this = this;
        this.actions$ = actions$;
        this.accountService = accountService;
        this.fnbsService = fnbsService;
        this.apiLoginSuccess$ = this.actions$.ofType(LOGIN_SUCCESS).pipe(map(function (action) { return action.payload; }), mergeMap(function (payload) { return _this.fetchAccountDetails(payload); }));
    }
    InvestorEffects.prototype.fetchAccountDetails = function (user) {
        if (!user.isBanker) {
            this.accountService.fetchAccounts();
            this.accountService.fetchPendingAccounts(user);
            this.fnbsService.fetchAccounts();
        }
        return of();
    };
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], InvestorEffects.prototype, "apiLoginSuccess$", void 0);
    return InvestorEffects;
}());
export { InvestorEffects };
