export class Customer {
    public title: string;
    public firstname: string;
    public middlename: string;
    public lastname: string;
    public ucn: string;
    public idNumber: string;
    public age: number;
    public emailAddress: string;
    public contactNumber: string;
}
