import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EacAccount } from 'shared/components/eac-modal/effective-annual-cost/models';

@Component({
    selector: 'wim-eac-modal',
    templateUrl: 'eac-modal.component.pug',
})
export class EacModalComponent {
    public account: EacAccount;

    constructor(public activeModal: NgbActiveModal) {}
}
