import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import appConfig from 'appConfig';
import { Observable } from 'rxjs';
import { EACRequest } from 'shared/models/eac/eac-request';
import { EACResponse } from 'shared/models/eac/eac-response';
import { Logger, LoggerService } from 'shared/services/logger.service';

@Injectable()
export class EffectiveAnnualCostService {
    private logger: Logger;
    constructor(private http: HttpClient, loggerService: LoggerService) {
        this.logger = loggerService.getLogger('EffectiveAnnualCostService');
    }

    public getEAC(request: EACRequest): Observable<EACResponse> {
        return this.http.post<EACResponse>(`${appConfig.apiUrl}/get-effective-annual-cost`, request);
    }
}
