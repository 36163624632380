var tokens = new Map([
    [/{{CURRENCY_SYMBOL_ZAR}}/, { type: 'currency', value: 'R' }],
    [/{{INPUT:(.*)}}/, { type: 'input', value: 'value' }],
    [/{{INPUT_(.*)}}/, { type: 'input', value: 'value' }],
]);
var PstParserService = /** @class */ (function () {
    function PstParserService() {
    }
    PstParserService.prototype.parseParameterizedString = function (value, pstResult) {
        // e.g. How much do you want to invest for {{GOAL_NAME}}
        var newValue = value;
        var params = /{{(.*)}}/.exec(value);
        if (!params) {
            return newValue;
        }
        for (var i = 1; i < params.length; i++) {
            var paramName = params[i];
            newValue = newValue.replace("{{" + paramName + "}}", pstResult.capturedValues[paramName]);
        }
        return newValue;
    };
    PstParserService.prototype.extractContextType = function (value) {
        // e.g. {{INPUT:USER_TYPE}}
        var newValue = value;
        var params = /{{INPUT:(.*)}}/.exec(value);
        if (!params) {
            return newValue;
        }
        return params[1];
    };
    PstParserService.prototype.extractChildNodeValue = function (longDescription) {
        // e.g. [[VALUE:100]]
        var valueRegex = /\[\[VALUE:(.*)\]\]/;
        var matchingToken = this.testAllTokens(valueRegex, longDescription, '|')[0];
        if (matchingToken) {
            return matchingToken[1];
        }
        return '';
    };
    PstParserService.prototype.extractRange = function (longDescription) {
        // e.g. [[RANGE:min,max]]
        var rangeRegex = /\[\[RANGE:(.*)\]\]/;
        var matchingToken = this.testAllTokens(rangeRegex, longDescription, '|')[0];
        if (matchingToken) {
            var range = matchingToken[1].split(',');
            return [+range[0], +range[1]];
        }
        return null;
    };
    PstParserService.prototype.extractTermSwitch = function (longDescription) {
        // e.g. [[SWITCH_POINT:months]]
        var switchRegex = /\[\[SWITCH_POINT:(.*)\]\]/;
        var matchingToken = this.testAllTokens(switchRegex, longDescription, '|')[0];
        if (matchingToken) {
            return +matchingToken[1];
        }
        return 0;
    };
    PstParserService.prototype.extractMaxOverride = function (longDescription) {
        // e.g. [[SLIDER_OVERRIDE]]
        var overrideRegex = /\[\[SLIDER_OVERRIDE:(.*)\]\]/;
        var matchingToken = this.testAllTokens(overrideRegex, longDescription, '|')[0];
        if (matchingToken) {
            return +matchingToken[1];
        }
        return null;
    };
    PstParserService.prototype.extractDefaultValue = function (longDescription) {
        // e.g. [[DEFAULT:min]]
        var defaultRegex = /\[\[DEFAULT:(.*)\]\]/;
        var matchingToken = this.testAllTokens(defaultRegex, longDescription, '|')[0];
        if (matchingToken) {
            return matchingToken[1];
        }
        return null;
    };
    PstParserService.prototype.buildInputDescription = function (description) {
        // e.g. I have a Lump Sum of |{{CURRENCY_SYMBOL_ZAR}}|{{ROUND_NUMBER_INPUT}}
        var parts = description
            .split('|')
            .map(function (part) { return part.trim(); })
            .filter(function (part) { return part.length > 0; });
        var parsedParts = [];
        var _loop_1 = function (descriptionPart) {
            var part = null;
            tokens.forEach(function (value, key) {
                if (key.test(descriptionPart)) {
                    part = Object.assign({}, value);
                    part.property = key.exec(descriptionPart)[1];
                }
            });
            if (part === null) {
                part = {
                    type: 'text',
                    value: descriptionPart,
                };
            }
            parsedParts.push(part);
        };
        for (var _i = 0, parts_1 = parts; _i < parts_1.length; _i++) {
            var descriptionPart = parts_1[_i];
            _loop_1(descriptionPart);
        }
        return parsedParts;
    };
    PstParserService.prototype.testAllTokens = function (regex, extractFrom, delimiter) {
        var tokensInString = extractFrom.split(delimiter);
        return tokensInString.map(function (token) { return regex.exec(token); }).filter(function (result) { return !!result && !!result[1]; });
    };
    return PstParserService;
}());
export { PstParserService };
