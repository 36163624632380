import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneralDocument, InvestmentAccount, InvestorDocument, TradingAccount } from 'investor/models';

export interface InvestorState {
    generalDocuments: GeneralDocument[];
    investorDocuments: InvestorDocument[];
    documentRetrievalError: Error;

    selectedAccountNumber: string;
    selectedAccount: InvestmentAccount;

    tradingAccounts: TradingAccount[];
    investmentAccounts: InvestmentAccount[];
    investmentAccountLoadingError: Error;
    investmentAccountsPending: boolean;
    hasProcessingApplications: boolean;
    selectedInstructionAccount: string;
}

const selectInvestorState = createFeatureSelector<InvestorState>('investor');
export const selectGeneralDocuments = createSelector(selectInvestorState, (state: InvestorState) => state.generalDocuments);
export const selectInvestorDocuments = createSelector(selectInvestorState, (state: InvestorState) => state.investorDocuments);
export const selectDocumentRetrievalError = createSelector(selectInvestorState, (state: InvestorState) => state.documentRetrievalError);
export const selectSelectedAccountNumber = createSelector(selectInvestorState, (state: InvestorState) => state.selectedAccountNumber);
export const selectSelectedAccount = createSelector(selectInvestorState, (state: InvestorState) => state.selectedAccount);
export const selectTradingAccounts = createSelector(selectInvestorState, (state: InvestorState) => state.tradingAccounts);
export const selectInvestmentAccounts = createSelector(selectInvestorState, (state: InvestorState) => state.investmentAccounts);
export const selectInvestmentAccountLoadingError = createSelector(
    selectInvestorState,
    (state: InvestorState) => state.investmentAccountLoadingError
);
export const selectInvestmentAccountsPending = createSelector(
    selectInvestorState,
    (state: InvestorState) => state.investmentAccountsPending
);
export const selectHasProcessingApplications = createSelector(
    selectInvestorState,
    (state: InvestorState) => state.hasProcessingApplications
);
export const selectSelectedInstructionAccount = createSelector(
    selectInvestorState,
    (state: InvestorState) => state.selectedInstructionAccount
);
