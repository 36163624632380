import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomerSearchResult } from 'application/models';
import { customerSearchReducer } from 'banker/store/banker.reducers';

export interface BankerState {
    customerSearchResults: CustomerSearchResult[];
}

const selectBankerState = createFeatureSelector<BankerState>('banker');
export const selectCustomerSearchResults = createSelector(selectBankerState, (state: BankerState) => state.customerSearchResults);
