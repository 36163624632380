import { Injectable } from '@angular/core';

// https://github.com/danguer/blog-examples/blob/master/js/base64-binary.js
const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

@Injectable()
export class Base64Service {
    public decodeArrayBuffer(input: string) {
        const bytes = (input.length / 4) * 3;
        const arrayBuffer = new ArrayBuffer(bytes);
        this.decode(input, arrayBuffer);
        return arrayBuffer;
    }

    private removePaddingChars(input: string) {
        const lkey = _keyStr.indexOf(input.charAt(input.length - 1));
        if (lkey === 64) {
            return input.substring(0, input.length - 1);
        }
        return input;
    }

    // tslint:disable:no-bitwise
    private decode(input: string, arrayBuffer: ArrayBuffer): Uint16Array {
        input = this.removePaddingChars(input);

        const bytes = parseInt(`${(input.length / 4) * 3}`, 10);

        let uarray;
        let i = 0;
        let j = 0;

        if (arrayBuffer) {
            uarray = new Uint8Array(arrayBuffer);
        } else {
            uarray = new Uint8Array(bytes);
        }

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        for (i = 0; i < bytes; i += 3) {
            const enc1 = _keyStr.indexOf(input.charAt(j++));
            const enc2 = _keyStr.indexOf(input.charAt(j++));
            const enc3 = _keyStr.indexOf(input.charAt(j++));
            const enc4 = _keyStr.indexOf(input.charAt(j++));

            const chr1 = (enc1 << 2) | (enc2 >> 4);
            const chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            const chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i] = chr1;

            if (enc3 !== 64) {
                uarray[i + 1] = chr2;
            }
            if (enc4 !== 64) {
                uarray[i + 2] = chr3;
            }
        }

        return uarray;
    }
}
