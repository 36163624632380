var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fromApplication from 'application/store/application.store';
import { CashCalculator, PstResultType, PstService } from 'pst/services';
import { CashCalculationResult } from 'pst/services/cash-calculator/models';
import { FundSelectedAction, PstAdviceShownAction, PstCompletedAction, PstScreenValidAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { EnableFixedFooterAction, LoadingAction, StopLoadingAction } from 'shared/store';
import { bigNumberMaxValidator, bigNumberMinIfNotZeroValidator, bigNumberMinValidator } from 'shared/validators';
import { PstContentModalComponent } from '../content-modal/pst-content-modal.component';
var CashResultComponent = /** @class */ (function (_super) {
    __extends(CashResultComponent, _super);
    function CashResultComponent(loggerService, pstStore, applicationStore, sharedStore, pstService, cashCalculator, router, lookupService, formBuilder, numberFormatter, modalService) {
        var _this = _super.call(this, loggerService) || this;
        _this.pstStore = pstStore;
        _this.applicationStore = applicationStore;
        _this.sharedStore = sharedStore;
        _this.pstService = pstService;
        _this.cashCalculator = cashCalculator;
        _this.router = router;
        _this.lookupService = lookupService;
        _this.formBuilder = formBuilder;
        _this.numberFormatter = numberFormatter;
        _this.modalService = modalService;
        _this.calculationError = false;
        _this.minDebitOrder = 1;
        _this.maxDebitOrder = 20000000;
        _this.estimatedFutureValue = 0;
        _this.minLumpSum = 1;
        _this.maxLumpSum = 50000000;
        _this.products = new Map();
        _this.originalPstResult = null;
        _this.recalculateTimer = null;
        _this.formReady = false;
        _this.content = [];
        return _this;
    }
    CashResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.sharedStore.dispatch(new EnableFixedFooterAction(true));
        var pstResult$ = this.pstStore.select(fromPst.selectInProgressPst);
        var pstTree$ = this.pstStore.select(fromPst.selectPstTree);
        var products$ = this.lookupService.getPortfolioTypes();
        var content$ = this.lookupService.getPstResultContent();
        var allCashProducts$ = this.lookupService.getCashProductDetails();
        var selectedCustomer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        var subscription = combineLatest(pstResult$, pstTree$, products$, allCashProducts$, selectedCustomer$, content$, function (pstResult, pstTree, products, allCashProducts, selectedCustomer, content) {
            return { pstResult: pstResult, pstTree: pstTree, products: products, allCashProducts: allCashProducts, selectedCustomer: selectedCustomer, content: content };
        }).subscribe(function (_a) {
            var pstResult = _a.pstResult, pstTree = _a.pstTree, products = _a.products, allCashProducts = _a.allCashProducts, selectedCustomer = _a.selectedCustomer, content = _a.content;
            if (!pstResult) {
                _this.sharedStore.dispatch(new StopLoadingAction());
                _this.router.navigateByUrl('/secure/banker/customer-search');
                return;
            }
            // Empty pstResult, pst is not yet completed. Happens when restarting PST
            if (Object.keys(pstResult.capturedValues).length === 0) {
                return;
            }
            if (!_this.originalPstResult) {
                CashCalculator.extractCapturedValues(pstResult);
                _this.originalPstResult = Object.assign({}, pstResult);
                _this.pstStore.dispatch(new PstAdviceShownAction(_this.originalPstResult));
            }
            if (!_this.resultType) {
                if (pstResult.goalAmount === null || pstResult.goalAmount === 0) {
                    _this.logger.debug('User selected Just Invest during PST');
                    _this.resultType = PstResultType.FutureValue;
                }
                else {
                    _this.logger.debug('User selected a goal during PST');
                    _this.resultType = PstResultType.Installment;
                }
            }
            _this.content = content;
            pstResult.portfolioTypeId = pstResult.portfolioTypeId || parseInt(pstTree.node.nodeDescription, 10);
            pstResult.reference = pstResult.reference || pstTree.transactionref;
            products.forEach(function (product) {
                _this.products.set(product.id, product);
            });
            _this.pstStore.dispatch(new FundSelectedAction(_this.fund));
            _this.cashProduct = allCashProducts.find(function (cashProduct) { return cashProduct.portfolioTypeId === pstResult.portfolioTypeId; });
            _this.selectedCustomer = selectedCustomer;
            _this.recalculate(pstResult);
            _this.createForm();
            _this.pstStore.dispatch(new PstScreenValidAction(_this.calculationForm.valid));
        });
        this.registerSubscriptions(subscription);
    };
    CashResultComponent.prototype.isJustInvest = function () {
        return this.calculation.pstResult && this.calculation.pstResult.goalAmount === 0;
    };
    CashResultComponent.prototype.recalculate = function (pstResult) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.selectedCustomer) {
                            return [2 /*return*/];
                        }
                        pstResult = pstResult || this.calculation.pstResult;
                        this.sharedStore.dispatch(new LoadingAction());
                        this.calculation = new CashCalculationResult();
                        this.calculation.pstResult = pstResult;
                        return [4 /*yield*/, this.cashCalculator
                                .calculate(pstResult, this.cashProduct.adviceFee, this.selectedCustomer.age, this.selectedCustomer.ucn)
                                .pipe(finalize(function () {
                                _this.sharedStore.dispatch(new StopLoadingAction());
                                _this.formReady = true;
                            }))
                                .subscribe(function (data) {
                                _this.calculation = data;
                                _this.calculation.pstResult = data.pstResult;
                                _this.chart = data.graph;
                                _this.cashProduct.interestRate = data.nominalRate;
                                _this.calculationError = false;
                                _this.pstStore.dispatch(new PstCompletedAction(pstResult));
                            }, function (error) { return (_this.calculationError = true); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CashResultComponent.prototype.recalculateWithDebounce = function (pstResult) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.recalculateTimer) {
                clearTimeout(_this.recalculateTimer || _this.recalculateTimer._id);
            }
            _this.recalculateTimer = setTimeout(function () {
                _this.recalculate(pstResult);
                resolve();
            }, 1000);
        });
    };
    CashResultComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
        this.sharedStore.dispatch(new EnableFixedFooterAction(false));
    };
    CashResultComponent.prototype.restart = function () {
        this.cleanUpSubscriptions();
        this.pstService.startNewTree();
    };
    CashResultComponent.prototype.isContent = function (id) {
        return !!this.content.find(function (c) { return c.id === id; });
    };
    CashResultComponent.prototype.displayContentPopup = function (id) {
        if (!this.isContent(id)) {
            return;
        }
        var content = this.content.find(function (c) { return c.id === id; });
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.content;
    };
    CashResultComponent.prototype.transformBigNumberRand = function (value, zeroIfEmpty) {
        var _this = this;
        if (zeroIfEmpty === void 0) { zeroIfEmpty = false; }
        return this.numberFormatter.transformNumberTextPrepend(value, null, 'R ', function (v) {
            return _this.numberFormatter.transformBigNumber(v, null, zeroIfEmpty, null);
        });
    };
    CashResultComponent.prototype.transformTerm = function (value) {
        return this.numberFormatter.transformTerm(value);
    };
    CashResultComponent.prototype.transformNoticePeriod = function (value) {
        return this.numberFormatter.transformNoticePeriod(value);
    };
    CashResultComponent.prototype.parseBigNumber = function (bigNumber) {
        return this.numberFormatter.parseNumberTextPrepend(bigNumber, 'R ', this.numberFormatter.parseBigNumber);
    };
    CashResultComponent.prototype.hasRandValueChanged = function (originalValue, newValue) {
        var rawNewValue = newValue;
        if (rawNewValue.indexOf('R') >= 0) {
            rawNewValue = this.parseBigNumber(rawNewValue);
        }
        return +rawNewValue !== +originalValue;
    };
    CashResultComponent.prototype.createForm = function () {
        var _this = this;
        this.calculationForm = this.formBuilder.group({
            goalAmount: [
                this.transformBigNumberRand(this.calculation.pstResult.goalAmount),
                Validators.compose([Validators.required, bigNumberMinValidator(0, 'R')]),
            ],
            lumpSum: [
                this.transformBigNumberRand(this.calculation.pstResult.lumpSum, true),
                Validators.compose([bigNumberMinIfNotZeroValidator(this.minLumpSum, 'R'), bigNumberMaxValidator(this.maxLumpSum, 'R')]),
            ],
            clientInstallment: [
                this.transformBigNumberRand(this.calculation.pstResult.clientInstallment, true),
                Validators.compose([
                    bigNumberMinIfNotZeroValidator(this.minDebitOrder, 'R'),
                    bigNumberMaxValidator(this.maxDebitOrder, 'R'),
                ]),
            ],
            lumpSumDisabled: [this.transformBigNumberRand(this.calculation.pstResult.lumpSum)],
            futureValue: [this.transformBigNumberRand(this.calculation.estimatedFutureValue)],
            term: [this.transformTerm(this.calculation.pstResult.term)],
            goalName: [this.calculation.pstResult.goalName],
            // calculatedMonthlyContribution: [this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution)],
            accessType: this.calculation.pstResult.accessType,
            noticePeriod: [this.transformNoticePeriod(this.calculation.pstResult.noticePeriod)],
        });
        var portfolioTypeCode = +this.calculation.pstResult.portfolioTypeId;
        this.product = this.products.get(portfolioTypeCode);
        this.calculationForm.valueChanges.subscribe(function (changes) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.checkForChanges(changes)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); });
    };
    CashResultComponent.prototype.checkForChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.hasRandValueChanged(this.calculation.pstResult.goalAmount, changes.goalAmount)) return [3 /*break*/, 2];
                        this.calculation.pstResult.goalAmount = +this.parseBigNumber(changes.goalAmount);
                        return [4 /*yield*/, this.recalculateInstallment()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.calculation.pstResult.productDisclaimerAccepted = changes.productDisclaimerAccepted;
                        this.pstStore.dispatch(new PstScreenValidAction(this.calculationForm.valid));
                        return [2 /*return*/];
                }
            });
        });
    };
    CashResultComponent.prototype.recalculateInstallment = function (usePreviousCalculation) {
        if (usePreviousCalculation === void 0) { usePreviousCalculation = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!usePreviousCalculation) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.recalculateWithDebounce(this.calculation.pstResult)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return CashResultComponent;
}(BaseComponent));
export { CashResultComponent };
