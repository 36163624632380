import { Injectable } from '@angular/core';
import { BaseService } from 'shared/components';
import { LoggerService } from 'shared/services/logger.service';

@Injectable()
export class PstTfsaValidator extends BaseService {
    constructor(loggerService: LoggerService = null) {
        super(loggerService);
    }

    private targetLimit = 500000;
    private annualContributionLimit = 33000;
    private monthlyContributionLimit = 2750; // (33000 / 12 = 2750);
    private termLimit = 3 * 12;
    private taxYearContributionAmount;
    private remainingTaxYearContributionAmount;
    private nextTaxYearContributionAmount;
    private targetValid = false;
    private lumpSumValid = false;
    private contributionValid = false;
    private taxYearLimitValid = false;
    private productAvailable = false;
    private date = new Date();

    private financialMonthsLeft = [0, 2, 1, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3];

    public tfsaTermValidate(term) {
        return term > this.termLimit;
    }

    public ngOnDestroy() {}

    public validatePstForTfsa(targetAmount, lumpSumAmount, monthlyContributionAmount, term, isDirect) {
        let currentMonth = this.date.getMonth() + 1;
        this.targetValid = this.tfsaTargetValidate(targetAmount);
        this.lumpSumValid = this.tfsaLumpSumCheck(lumpSumAmount);
        this.contributionValid = this.tfsaContributionCheck(monthlyContributionAmount);
        this.taxYearLimitValid = this.tfsaTaxYearLimitCheck(lumpSumAmount, monthlyContributionAmount, currentMonth);
        this.productAvailable = this.productAvailabilityCheck(isDirect);
        if (
            this.targetValid &&
            this.lumpSumValid &&
            this.contributionValid &&
            this.taxYearLimitValid &&
            this.productAvailable &&
            this.tfsaTermValidate(term)
        ) {
            return true;
        } else {
            return false;
        }
    }

    public tfsaTargetValidate(targetAmount) {
        if (targetAmount > this.targetLimit) {
            return false;
        }
        return true;
    }

    public tfsaLumpSumCheck(lumpSumAmount) {
        if (lumpSumAmount > this.annualContributionLimit) {
            return false;
        }
        return true;
    }

    public tfsaContributionCheck(monthlyContributionAmount) {
        if (monthlyContributionAmount > this.monthlyContributionLimit) {
            return false;
        }
        return true;
    }

    public productAvailabilityCheck(isDirect) {
        if (isDirect === true) {
            return false;
        }
        return true;
    }

    public tfsaTaxYearLimitCheck(lumpSumAmount, monthlyContributionAmount, currentMonth) {
        this.taxYearContributionAmount = monthlyContributionAmount * this.financialMonthsLeft[currentMonth];
        this.nextTaxYearContributionAmount = monthlyContributionAmount * 12;
        this.remainingTaxYearContributionAmount = lumpSumAmount + this.taxYearContributionAmount;

        if (this.taxYearContributionAmount > this.annualContributionLimit) {
            return false;
        }
        if (this.nextTaxYearContributionAmount > this.annualContributionLimit) {
            return false;
        }
        if (this.remainingTaxYearContributionAmount > this.annualContributionLimit) {
            return false;
        }
        return true;
    }
}
