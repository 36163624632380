import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'wim-yes-no',
    templateUrl: 'yes-no.component.pug',
    styleUrls: ['yes-no.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => YesNoComponent),
            multi: true,
        },
    ],
})
export class YesNoComponent implements ControlValueAccessor, OnInit {
    public isDisabled: boolean;
    public yesNoForm: FormGroup;
    private onChangeFunction;
    private onTouchFunction;

    @Input()
    public initialValue: string;

    @Output()
    public nonFormChangeEvent = new EventEmitter();

    constructor(formBuilder: FormBuilder) {
        this.yesNoForm = formBuilder.group({
            value: false,
        });
        this.yesNoForm.valueChanges.subscribe(changes => {
            if (this.onChangeFunction) {
                this.onChangeFunction(changes.value);
            }
            if (this.onTouchFunction) {
                this.onTouchFunction(true);
            }
            this.nonFormChangeEvent.emit(changes.value);
        });
    }

    public ngOnInit() {
        this.writeValue(this.initialValue);
    }

    public writeValue(value: string): void {
        if (typeof value === 'boolean') {
            value = `${value}`;
        }
        this.yesNoForm.patchValue({ value });
    }

    public registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchFunction = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (isDisabled) {
            this.yesNoForm.get('value').disable();
        } else {
            this.yesNoForm.get('value').enable();
        }
    }
}
