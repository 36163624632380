import appConfig from 'appConfig';
import bugsnag from 'bugsnag-js';

function getReleaseStage() {
    switch (appConfig.env) {
        case 'Default':
        case 'DEV':
            return 'development';
        case 'INT':
        case 'QA':
            return 'testing';
        case 'PRD':
            return 'production';
        default:
            return 'development';
    }
}

const bugsnagClient = bugsnag({
    apiKey: 'NOPE',
    appVersion: appConfig.version,
    endpoints: {
        notify: '/coreapi/diagnostics/notify',
        sessions: null,
    },
    releaseStage: getReleaseStage(),
    notifyReleaseStages: ['development', 'testing', 'production'],
    autoCaptureSessions: false,
    metaData: {
        company: {
            name: 'FNB Wealth and Investments',
        },
    },
});

export default bugsnagClient;
