var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Actions, ofActionDispatched } from '@ngxs/store';
import appConfig from 'appConfig';
import { SourceOfFundsDeclaration } from 'application/models/source-of-funds-declaration';
import { ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PstAdviceShownAction, PstCompletedAction } from 'pst/store';
import { DisclaimerModalComponent } from 'robo/components/disclaimer-modal';
import { AdviceEngineService } from 'robo/services/advice-engine.service';
import { of } from 'rxjs';
import { combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { LoggerService, LookupDataService } from 'shared/services';
import { EnableFixedFooterAction, LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { RoboFieldId } from '../../models';
import { ForceValidationMessagesAction, RoboSavedAction, RoboStore } from '../../store';
import { AdviceDownloadModalComponent } from '../advice-download-modal/advice-download-modal.component';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';
var RoboAdviceComponent = /** @class */ (function (_super) {
    __extends(RoboAdviceComponent, _super);
    function RoboAdviceComponent(loggerService, sharedStore, applicationStore, pstStore, store, router, adviceService, lookupDataService, toastr, modalService, actions$) {
        var _this = _super.call(this, loggerService) || this;
        _this.sharedStore = sharedStore;
        _this.applicationStore = applicationStore;
        _this.pstStore = pstStore;
        _this.store = store;
        _this.router = router;
        _this.adviceService = adviceService;
        _this.lookupDataService = lookupDataService;
        _this.toastr = toastr;
        _this.modalService = modalService;
        _this.actions$ = actions$;
        _this.formValid = false;
        _this.adviceServerError = false;
        _this.adviceValidationErrors = false;
        _this.roboFields = {};
        _this.allLumpsums = [];
        _this.taxFreeOpened = true;
        _this.error = null;
        _this.downloadingError = null;
        return _this;
    }
    RoboAdviceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.sharedStore.dispatch(new EnableFixedFooterAction(true));
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        var isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        combineLatest(customer$, isDirect$, function (customer, isDirect) {
            return { customer: customer, isDirect: isDirect };
        })
            .pipe(this.scavenger.collect(), catchError(function (error) {
            _this.error = error;
            return of(error);
        }))
            .subscribe(function (_a) {
            var customer = _a.customer, isDirect = _a.isDirect;
            _this.isDirect = isDirect;
            if (customer === null) {
                if (isDirect === false) {
                    _this.logger.warn('Cannot give advice before selecting a customer, navigating to customer search');
                    _this.router.navigateByUrl('/secure/banker/customer-search');
                    _this.sharedStore.dispatch(new StopLoadingAction());
                    return;
                }
                else if (isDirect === true) {
                    _this.logger.warn('Cannot give advice before selecting a customer, navigating to SSO screen to lookup customer');
                    _this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
                    return;
                }
            }
            _this.customerName = customer.title + " " + customer.firstname + " " + customer.lastname;
        });
        this.adviceService.getAdvice(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); });
        this.error$ = this.store.select(RoboStore.adviceError);
        this.error$.pipe(this.scavenger.collect()).subscribe(function (error) { return (_this.adviceServerError = !!error); });
        this.validationErrors$ = this.store.select(RoboStore.validationErrors);
        this.adviceEngineValidationWarnings$ = this.store.select(RoboStore.adviceEngineValidationWarnings);
        this.downstreamError$ = this.store.select(RoboStore.downstreamAdviceError);
        this.store
            .select(RoboStore.validationErrors)
            .pipe(this.scavenger.collect())
            .subscribe(function (errors) { return (_this.adviceValidationErrors = errors && errors.length > 0); });
        this.lookupDataService
            .getRoboGoals()
            .pipe(this.scavenger.collect())
            .subscribe(function (goals) { return (_this.goals = goals); });
        this.store
            .select(RoboStore.completedFields)
            .pipe(this.scavenger.collect())
            .subscribe(function (fields) { return (_this.roboFields = fields); });
        this.store
            .select(RoboStore.additionalLumpsums)
            .pipe(this.scavenger.collect())
            .subscribe(function (additionalLumpsums) { return (_this.lumpsums = additionalLumpsums); });
        this.store
            .select(RoboStore.taxFreeOpened)
            .pipe(this.scavenger.collect())
            .subscribe(function (taxFreeOpened) { return (_this.taxFreeOpened = taxFreeOpened); });
        this.store
            .select(RoboStore.saveError)
            .pipe(this.scavenger.collect())
            .subscribe(function (error) {
            if (error) {
                _this.logger.error(error);
                _this.toastr.error('The robo-advisor result could not be saved, please try again later.');
            }
        });
        this.actions$
            .pipe(ofActionDispatched(RoboSavedAction), this.scavenger.collect())
            .subscribe(function () {
            _this.toastr.success("The robo-advisor result was successfully saved. It can be\n                             retrieved for the next 5 days when searching for a customer");
        });
        this.store
            .select(RoboStore.currentAdvice)
            .pipe(this.scavenger.collect())
            .subscribe(function (advice) {
            _this.recommendation = advice ? advice.recommendations[0] : null;
        });
        this.store
            .select(RoboStore.adviceFormValid)
            .pipe(this.scavenger.collect())
            .subscribe(function (valid) { return (_this.formValid = valid); });
    };
    RoboAdviceComponent.prototype.ngAfterViewInit = function () { };
    RoboAdviceComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    RoboAdviceComponent.prototype.cancel = function () {
        if (this.isDirect) {
            this.router.navigateByUrl('/secure/investor');
        }
        else {
            this.router.navigateByUrl('/secure/banker');
        }
    };
    RoboAdviceComponent.prototype.restart = function () {
        if (this.isDirect) {
            this.router.navigateByUrl('/secure/investor/robo');
        }
        else {
            this.router.navigateByUrl('/secure/banker/robo');
        }
    };
    RoboAdviceComponent.prototype.saveForLater = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.adviceService.saveForLater(function () {
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    RoboAdviceComponent.prototype.canDownload = function () {
        return this.recommendation && this.recommendation.totalContributions > 0;
    };
    RoboAdviceComponent.prototype.download = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.downloadingError = false;
        this.adviceService.prepareDownload(!this.taxFreeOpened).subscribe(function (uniqueUrlModel) {
            _this.sharedStore.dispatch(new StopLoadingAction());
            var downloadModal = _this.modalService.open(AdviceDownloadModalComponent);
            downloadModal.componentInstance.uniqueId = uniqueUrlModel.uniqueId;
            downloadModal.componentInstance.uniqueUrl = appConfig.apiUrl + "/" + uniqueUrlModel.uniqueUrl;
        }, function (err) {
            console.error(err);
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.downloadingError = true;
        });
    };
    RoboAdviceComponent.prototype.getValue = function (fieldId) {
        var field = this.roboFields[fieldId];
        if (field) {
            return field.value;
        }
        return null;
    };
    RoboAdviceComponent.prototype.populateLumpSums = function () {
        var _this = this;
        if (this.getValue(RoboFieldId.lumpSumAmount) === null || this.getValue(RoboFieldId.lumpSumAmount) === undefined) {
            return [];
        }
        var portfolioType = this.getSelectedPortfolioType();
        var date = moment();
        if (portfolioType === 3 || portfolioType === 10) {
            date = moment(this.getValue(RoboFieldId.lumpSumDate));
        }
        else {
            date = moment().startOf('day');
        }
        var initialLumpSum = {
            debitDate: date.toDate(),
            amount: Number(+this.getValue(RoboFieldId.lumpSumAmount)),
            fundingTypeId: this.populatePortfolioType(),
            sourceOfFunds: new SourceOfFundsDeclaration(),
        };
        this.allLumpsums.push(initialLumpSum);
        if (this.lumpsums) {
            this.lumpsums.forEach(function (lumpsum) {
                var additionalLumpSum = {
                    debitDate: new Date(lumpsum.date),
                    amount: Number(lumpsum.amount),
                    fundingTypeId: _this.populatePortfolioType(),
                    sourceOfFunds: new SourceOfFundsDeclaration(),
                };
                _this.allLumpsums.push(additionalLumpSum);
            });
        }
        return this.allLumpsums;
    };
    RoboAdviceComponent.prototype.calculateLumpSums = function () {
        var totalContribution = 0;
        var initialLumpSumAmount = Number(+this.getValue(RoboFieldId.lumpSumAmount));
        if (Number.isNaN(initialLumpSumAmount)) {
            initialLumpSumAmount = 0;
        }
        totalContribution = initialLumpSumAmount;
        if (this.lumpsums) {
            this.lumpsums.forEach(function (lumpsum) {
                totalContribution = totalContribution + Number(lumpsum.amount);
            });
        }
        return totalContribution;
    };
    RoboAdviceComponent.prototype.getAdvisedPortfolioType = function () {
        if (this.recommendation.taxFreeOptionAvailable && this.recommendation.portfolioTypeId === appConfig.portfolioTypes.INV) {
            return appConfig.portfolioTypes.TFDS;
        }
        return this.recommendation.portfolioTypeId;
    };
    RoboAdviceComponent.prototype.getSelectedPortfolioType = function () {
        if (this.recommendation.taxFreeOptionAvailable && this.taxFreeOpened) {
            return appConfig.portfolioTypes.TFDS;
        }
        return this.recommendation.portfolioTypeId;
    };
    RoboAdviceComponent.prototype.populateDebitOrders = function () {
        var debitOrder = {
            sourceOfFunds: new SourceOfFundsDeclaration(),
            debitDate: this.populateDebitDate(),
            firstDebitDate: this.calculateDebitDate(),
            amount: Number(+this.getValue(RoboFieldId.recurringContributionAmount)),
            escalation: Number(+this.getValue(RoboFieldId.annualEscalationPercentage)),
            firstEscalationDate: this.calculateEscalationDate(),
            fundingTypeId: this.populatePortfolioType(),
            paymentFrequencyId: Number(+this.getValue(RoboFieldId.recurringContributionFrequency)),
        };
        return debitOrder;
    };
    RoboAdviceComponent.prototype.calculateDebitDate = function () {
        var date = moment(this.getValue(RoboFieldId.recurringContributionStartDate));
        return date.toDate();
    };
    RoboAdviceComponent.prototype.populateDebitDate = function () {
        var portfolioType = this.getSelectedPortfolioType();
        var transferDay = 0;
        if (portfolioType === 3 || portfolioType === 10) {
            if (this.getValue(RoboFieldId.recurringContributionStartDate)) {
                transferDay = moment(this.getValue(RoboFieldId.recurringContributionStartDate)).date();
            }
            else if (this.getValue(RoboFieldId.lumpSumDate)) {
                transferDay = moment(this.getValue(RoboFieldId.lumpSumDate)).date();
            }
        }
        else {
            transferDay = moment().startOf('day').add(1, 'month').date();
        }
        return transferDay;
    };
    RoboAdviceComponent.prototype.calculateEscalationDate = function () {
        var firstDebitDate = moment(this.getValue(RoboFieldId.recurringContributionStartDate));
        return firstDebitDate
            .add(1, 'years')
            .toDate();
    };
    RoboAdviceComponent.prototype.populatePortfolioType = function () {
        if (this.recommendation.portfolioTypeId === 3) {
            this.isCash = false;
            this.isUnitTrust = true;
            this.isTaxFree = false;
            return appConfig.fundingTypes.standard;
        }
        else if (this.recommendation.portfolioTypeId === 10) {
            this.isCash = true;
            this.isUnitTrust = false;
            this.isTaxFree = false;
            return appConfig.fundingTypes.cash;
        }
        else if (this.recommendation.portfolioTypeId !== 3 && this.recommendation.portfolioTypeId !== 10) {
            this.isCash = false;
            this.isUnitTrust = false;
            this.isTaxFree = true;
            return appConfig.fundingTypes.standard;
        }
    };
    RoboAdviceComponent.prototype.startInvesting = function () {
        var _this = this;
        if (this.formInvalid()) {
            this.store.dispatch(new ForceValidationMessagesAction());
            var validationModal = this.modalService.open(ValidationModalComponent);
            validationModal.componentInstance.formInvalid = this.isAdviceFormInvalid();
            validationModal.componentInstance.recommendationInvalid = this.isRecommendationInvalid();
            validationModal.componentInstance.validationErrors = this.adviceValidationErrors;
            return;
        }
        var modal = this.modalService.open(DisclaimerModalComponent, { windowClass: 'large-modal' });
        modal.result
            .then(function () {
            var result = {
                advisedInstallment: _this.recommendation.requiredMonthlyContribution,
                contributionIncrease: +_this.getValue(RoboFieldId.annualEscalationPercentage) / 100,
                clientInstallment: +_this.getValue(RoboFieldId.recurringContributionAmount),
                fundCode: '',
                futureValue: +_this.recommendation.projectedValue,
                goalAmount: +_this.getValue(RoboFieldId.targetValue),
                goalName: _this.getValue(RoboFieldId.goalName),
                lumpSum: _this.calculateLumpSums(),
                portfolioTypeId: _this.getSelectedPortfolioType(),
                term: +_this.getValue(RoboFieldId.term),
                reference: 'ROBO',
                lumpSumDetails: _this.populateLumpSums(),
                debitOrderDetails: _this.populateDebitOrders(),
                fundId: _this.recommendation.fundId,
                affordabilityConfirmed: true,
                taxFreeOffered: _this.recommendation.taxFreeOptionAvailable,
                taxFreeOpened: _this.taxFreeOpened,
                advisedLumpSum: _this.recommendation.requiredInitialLump,
                advisedPortfolioTypeId: _this.getAdvisedPortfolioType(),
                pstAdvised: true,
                accessType: _this.getValue(RoboFieldId.withdrawalAccessType),
                projectedInterestRate: _this.recommendation.cashAccountReferenceData && _this.recommendation.cashAccountReferenceData.nominalRate,
                goalAmountAdjustedForInflation: _this.recommendation.goalAmountAdjustedForInflation,
            };
            var goal = _this.goals.find(function (g) { return g.goalId === +_this.roboFields[RoboFieldId.goal].value; });
            if (goal) {
                if (result.goalName && result.goalName.trim().length > 0) {
                    result.goalName = goal.name + '(' + result.goalName + ')';
                }
                else {
                    result.goalName = goal.name;
                }
            }
            _this.pstStore.dispatch(new PstCompletedAction(result));
            _this.pstStore.dispatch(new PstAdviceShownAction(result));
            if (_this.isDirect) {
                _this.router.navigateByUrl('/secure/investor/application');
            }
            else {
                _this.router.navigateByUrl('/secure/banker/application');
            }
        })
            .catch(function () { });
        modal.componentInstance.portfolioTypeId = +this.recommendation.portfolioTypeId;
    };
    RoboAdviceComponent.prototype.retry = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.adviceService.getAdvice(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); });
    };
    RoboAdviceComponent.prototype.formInvalid = function () {
        return this.isAdviceFormInvalid() || this.isAnyErrors() || this.isRecommendationInvalid();
    };
    RoboAdviceComponent.prototype.isAdviceFormInvalid = function () {
        return !this.formValid;
    };
    RoboAdviceComponent.prototype.isAnyErrors = function () {
        return this.adviceServerError || this.adviceValidationErrors;
    };
    RoboAdviceComponent.prototype.isRecommendationInvalid = function () {
        return (this.recommendation === null || this.recommendation.totalContributions == null || this.recommendation.totalContributions === 0);
    };
    return RoboAdviceComponent;
}(BaseComponent));
export { RoboAdviceComponent };
