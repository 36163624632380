import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PstWizardComponent } from 'pst/components';
import { LoggedInAuthGuard } from 'shared/services';

export const pstRoutes: Routes = [{ path: '', component: PstWizardComponent, canActivate: [LoggedInAuthGuard] }];

@NgModule({
    imports: [RouterModule.forChild(pstRoutes)],
    exports: [RouterModule],
})
export class PstRoutingModule {}
