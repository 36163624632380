var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { InstrumentAllocationBasis, } from 'investor/models';
import { InstructionsService } from 'investor/services';
import * as fromInvestor from 'investor/store/investor.store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EacModalComponent, SharedModalComponent } from 'shared/components';
import { DateFormatterService, LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { dateMinValidator } from 'shared/validators';
var OK_TO_CANCEL = 'Yes Cancel';
var RecurringInstructionComponent = /** @class */ (function (_super) {
    __extends(RecurringInstructionComponent, _super);
    function RecurringInstructionComponent(investorStore, sharedStore, instructionService, lookupService, modalService, formBuilder, dateFormatter, loggerService, toastr) {
        var _this = _super.call(this, toastr, loggerService, modalService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.instructionService = instructionService;
        _this.lookupService = lookupService;
        _this.formBuilder = formBuilder;
        _this.dateFormatter = dateFormatter;
        _this.modalTitle = 'Recurring Investment';
        _this.minDebitOrder = 500;
        _this.maxDebitOrder = 100000;
        _this.maxEscalation = 15;
        _this.hasHoldings = false;
        _this.isKyc = false;
        _this.existingInstructions = [];
        _this.viewExisting = false;
        _this.debitDay = 0;
        _this.totalAllocation = 0;
        return _this;
    }
    RecurringInstructionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.viewExisting = false;
        this.createForm(undefined);
        this.lookupService
            .getSourceOfFundsTypes()
            .pipe(this.scavenger.collect())
            .subscribe(function (types) {
            _this.sourceOfFundsTypes = types ? types : [];
        });
        this.lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(function (funds) {
            if (funds && funds.length > 0) {
                _this.allFunds = funds.map(function (fund) {
                    return {
                        fundName: fund.name,
                        fundCode: fund.pstCode.split(' ')[0],
                        percentageOfTotal: 0,
                        totalValue: 0,
                        lastPriceDate: null,
                        units: 0,
                        unitPrice: 0,
                        factSheetUrl: fund.factSheetUrl,
                        ter: fund.ter,
                        transactionCosts: fund.transactionCosts,
                        adviceFee: fund.adviceFee,
                        adminFee: fund.adminFee,
                        assetClass: fund.assetClass,
                    };
                });
            }
            else {
                _this.allFunds = [];
            }
        });
        combineLatest(this.investorStore.select(fromInvestor.selectInvestmentAccounts), this.investorStore.select(fromInvestor.selectSelectedInstructionAccount), this.sharedStore.select(fromShared.selectUser), function (accounts, selectedAccount, selectedUser) {
            return { accounts: accounts, selectedAccount: selectedAccount, selectedUser: selectedUser };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var accounts = _a.accounts, selectedAccount = _a.selectedAccount, selectedUser = _a.selectedUser;
            if (!accounts) {
                return;
            }
            if (!selectedUser) {
                return;
            }
            _this.user = selectedUser;
            _this.isKyc = selectedUser.customerDetails.isKycVerified;
            _this.userBankAccounts = selectedUser.bankAccounts;
            if (selectedUser.bankAccounts.length >= 1) {
                _this.instructionForm.get('bankAccount').setValue(selectedUser.bankAccounts[0]);
            }
            _this.selectedAccountNumber = selectedAccount;
            _this.selectedAccountNumber = selectedAccount;
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.selectedAccount = accounts.find(function (acct) {
                return acct.accountNumber === _this.selectedAccountNumber;
            });
            _this.lookupService.getAgreementContent(_this.selectedAccount.portfolioTypeId).subscribe(function (content) {
                _this.debitAgreement = content.filter(function (agg) { return agg.isDebitOrder; })[0].summary;
            });
            _this.addFundHoldings();
        });
    };
    RecurringInstructionComponent.prototype.ngOnDestroy = function () { };
    RecurringInstructionComponent.prototype.computeAllocationTotal = function () {
        this.totalAllocation = this.instructionForm.get('allocations').value.reduce(function (a, b) { return a + b.percentageOfTotal; }, 0);
    };
    RecurringInstructionComponent.prototype.loadInstruction = function (instruction) {
        this.createForm(instruction);
        this.addFundHoldings();
        this.setExistingValues(instruction.bankAccountNumber, instruction.sourceOfFundName, instruction.existingInstructionId);
        this.viewExisting = false;
    };
    RecurringInstructionComponent.prototype.mayInstruct = function () {
        return this.hasHoldings && this.isKyc;
    };
    RecurringInstructionComponent.prototype.backToExisting = function () {
        this.viewExisting = true;
    };
    RecurringInstructionComponent.prototype.createNew = function () {
        this.createForm(undefined);
        this.addFundHoldings();
        this.viewExisting = false;
    };
    RecurringInstructionComponent.prototype.canSubmit = function () {
        if (this.instructionForm.get('stopDebit').value) {
            return true;
        }
        return this.totalAllocation === 100 && this.instructionForm.valid;
    };
    RecurringInstructionComponent.prototype.submit = function () {
        var _this = this;
        if (this.canSubmit()) {
            var warningRef = this.modalService.open(SharedModalComponent);
            if (this.instructionForm.value.stopDebit) {
                warningRef.componentInstance.modalMessage = "Are you sure you want to stop the debit order for this account?";
                warningRef.componentInstance.noValue = 'No';
                warningRef.componentInstance.yesValue = 'Yes, stop debit order';
                warningRef.componentInstance.modalTitle = 'Confirm Stop Debit Order';
            }
            else {
                warningRef.componentInstance.modalMessage = "Are you sure you want to create/modify your debit order for this account?";
                warningRef.componentInstance.noValue = 'No';
                warningRef.componentInstance.yesValue = 'Yes, change it';
                warningRef.componentInstance.modalTitle = 'Confirm Debit Order Change';
            }
            warningRef.result
                .then(function (resultType) {
                if (resultType === OK_TO_CANCEL) {
                    _this.submitComfirmed();
                }
            })
                .catch(function () { });
        }
        else {
            this.markAsTouched(this.instructionForm);
        }
    };
    RecurringInstructionComponent.prototype.submitComfirmed = function () {
        var _this = this;
        var formData = this.instructionForm.value;
        this.sharedStore.dispatch(new LoadingAction());
        var payLoad = formData.stopDebit
            ? {
                accountNumber: this.selectedAccountNumber,
                annualEscalationPercentage: null,
                bankAccountNumber: null,
                caseTypeId: formData.caseTypeId,
                debitAmount: null,
                debitDate: null,
                debitDay: null,
                isTermAccepted: formData.debitAuthorised,
                newEscalationDate: null,
                sourceOfFundName: null,
                sourceOfFundOther: null,
                stopDebit: formData.stopDebit,
                ucn: null,
                recurringAllocations: null,
                existingInstructionId: formData.existingInstructionId,
            }
            : {
                accountNumber: this.selectedAccountNumber,
                annualEscalationPercentage: formData.annualEscalationPercentage,
                bankAccountNumber: formData.bankAccount.accountNumber,
                caseTypeId: formData.caseTypeId,
                debitAmount: formData.debitOrderAmount,
                debitDate: this.dateFormatter.forApi(formData.debitDate),
                debitDay: this.debitDay,
                isTermAccepted: formData.debitAuthorised,
                newEscalationDate: this.dateFormatter.forApi(formData.escalationDate),
                sourceOfFundName: formData.sourceOfFunds.description,
                sourceOfFundOther: formData.otherSourceOfFund,
                stopDebit: formData.stopDebit,
                ucn: this.user.customerDetails.ucn,
                recurringAllocations: this.getAllocations(),
                existingInstructionId: formData.existingInstructionId,
            };
        this.instructionService
            .submitRecurringInstruction(payLoad)
            .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
            .subscribe(function (result) { return _this.handleResponse(result, _this.modalTitle); }, function () { return _this.handleSubmitError(_this.modalTitle); });
    };
    RecurringInstructionComponent.prototype.calculateEac = function () {
        var eacRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        var account = {
            funds: this.instructionForm.get('allocations').value.map(function (accountFund) {
                return {
                    allocation: accountFund.percentageOfTotal / 100,
                    fundCode: accountFund.fundCode.replace('ZA', '').trim(),
                };
            }),
        };
        eacRef.componentInstance.account = account;
    };
    RecurringInstructionComponent.prototype.cancel = function () {
        this.instructionForm.get('stopDebit').setValue(false);
        this.instructionForm.reset();
        this.setDefaults();
        this.addFundHoldings();
    };
    RecurringInstructionComponent.prototype.showTable = function () {
        return this.instructionForm.get('allocations').value.length > 0;
    };
    RecurringInstructionComponent.prototype.isSourceOfFundsOther = function (id) {
        if (!this.sourceOfFundsTypes || !id) {
            return false;
        }
        var sourceType = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return sourceType && sourceType.isOther;
    };
    RecurringInstructionComponent.prototype.updateDebitDay = function () {
        this.debitDay = this.dateFormatter.asMoment(this.instructionForm.get('debitDate').value).date();
        if (Number.isNaN(this.debitDay)) {
            this.debitDay = null;
        }
    };
    RecurringInstructionComponent.prototype.getMinDebitDate = function () {
        return moment()
            .add(3, 'days')
            .startOf('day');
    };
    RecurringInstructionComponent.prototype.getDefaultDebitEscalationDate = function () {
        return moment()
            .add(3, 'days')
            .add(1, 'year')
            .startOf('day');
    };
    RecurringInstructionComponent.prototype.setDefaults = function () {
        this.instructionForm.get('debitDate').setValue(this.dateFormatter.forDatePicker(this.getMinDebitDate()));
        this.instructionForm.get('escalationDate').setValue(this.dateFormatter.forDatePicker(this.getDefaultDebitEscalationDate()));
        this.instructionForm.get('annualEscalationPercentage').setValue(6);
        this.instructionForm.get('stopDebit').setValue(false);
        this.instructionForm.get('debitAuthorised').setValue(false);
    };
    RecurringInstructionComponent.prototype.createForm = function (instruction) {
        var ok = !!instruction;
        var minDebitDate = ok ? moment(instruction.debitDate) : this.getMinDebitDate();
        var defaultDebitEscalationDate = ok ? moment(instruction.newEscalationDate) : this.getDefaultDebitEscalationDate();
        var debitAmount = ok ? instruction.debitAmount : null;
        var escalationPercent = ok ? instruction.annualEscalationPercentage : 6;
        var otherSource = ok ? instruction.sourceOfFundOther : null;
        var debitStop = ok ? instruction.stopDebit : false;
        var authorisedDebit = ok ? instruction.isTermAccepted : false;
        var defaultCaseTypeId = ok ? instruction.instructionId : null;
        this.instructionForm = this.formBuilder.group({
            caseTypeId: [defaultCaseTypeId],
            bankAccount: [null, Validators.required],
            debitOrderAmount: [
                debitAmount,
                Validators.compose([Validators.required, Validators.min(this.minDebitOrder), Validators.max(this.maxDebitOrder)]),
            ],
            debitDate: [
                this.dateFormatter.forDatePicker(minDebitDate),
                Validators.compose([Validators.required, dateMinValidator(minDebitDate.toDate(), this.dateFormatter)]),
            ],
            annualEscalationPercentage: [
                escalationPercent,
                Validators.compose([Validators.required, Validators.min(0), Validators.max(this.maxEscalation)]),
            ],
            escalationDate: [this.dateFormatter.forDatePicker(defaultDebitEscalationDate), Validators.required],
            sourceOfFunds: [null, Validators.required],
            otherSourceOfFund: [otherSource],
            stopDebit: [debitStop, Validators.required],
            debitAuthorised: [authorisedDebit, Validators.requiredTrue],
            allocations: this.formBuilder.array([]),
            existingInstructionId: [null],
        }, {
            validator: this.validateOtherFields.bind(this),
        });
        this.updateDebitDay();
    };
    RecurringInstructionComponent.prototype.addFundHoldings = function () {
        if (this.selectedAccount) {
            var items = this.instructionForm.get('allocations');
            items.controls.length = 0;
            for (var _i = 0, _a = this.allFunds; _i < _a.length; _i++) {
                var portfolioItem = _a[_i];
                items.push(this.formBuilder.group({
                    fundCode: portfolioItem.fundCode,
                    fundName: portfolioItem.fundName,
                    percentageOfTotal: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
                }));
            }
            this.hasHoldings = items.length > 0;
        }
    };
    RecurringInstructionComponent.prototype.getAllocations = function () {
        var _this = this;
        var _allocations = [];
        this.instructionForm.get('allocations').value.forEach(function (fund) {
            _allocations.push({
                fundCode: fund.fundCode,
                fundName: fund.fundName,
                allocatedAmount: (fund.percentageOfTotal / 100) * _this.instructionForm.value.debitOrderAmount,
                allocatedPercent: parseFloat(fund.percentageOfTotal),
                allocatedUnits: null,
                allocationBasis: InstrumentAllocationBasis.currencyValue,
            });
        });
        return _allocations;
    };
    RecurringInstructionComponent.prototype.otherDescriptionRequired = function (id) {
        var type = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return type && type.isOther;
    };
    RecurringInstructionComponent.prototype.validateSourceOfFundsOther = function (abstractForm) {
        if (!this.sourceOfFundsTypes) {
            return null;
        }
        var sourceIdControl = abstractForm.get('sourceOfFunds');
        var descriptionControl = abstractForm.get('otherSourceOfFund');
        var required = this.otherDescriptionRequired(+(sourceIdControl.value ? sourceIdControl.value.id : 0));
        var valid = true;
        if (required) {
            var value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    };
    RecurringInstructionComponent.prototype.validateEscalationDate = function (abstractForm) {
        var firstDebitDateControl = abstractForm.get('debitDate');
        var escalationDateControl = abstractForm.get('escalationDate');
        var escalationDate = this.dateFormatter.asMoment(escalationDateControl.value);
        var firstDebitDate = this.dateFormatter.asMoment(firstDebitDateControl.value);
        var valid = true;
        if (escalationDateControl.value === '' || escalationDate === null) {
            escalationDateControl.setErrors({ required: true });
            valid = false;
        }
        else if (escalationDate.isBefore(firstDebitDate)) {
            escalationDateControl.setErrors({ beforeFirst: true });
            valid = false;
        }
        else {
            var oneYearFromNow = firstDebitDate.add(1, 'year').startOf('day');
            if (escalationDate.isAfter(oneYearFromNow)) {
                escalationDateControl.setErrors({ oneYear: oneYearFromNow.format('YYYY-MM-DD') });
                valid = false;
            }
        }
        if (valid) {
            escalationDateControl.setErrors(null);
            return null;
        }
        return { required: true };
    };
    RecurringInstructionComponent.prototype.validateOtherFields = function (abstractForm) {
        var sourceOfFundsErrors = this.validateSourceOfFundsOther(abstractForm);
        var escalationDateErrors = this.validateEscalationDate(abstractForm);
        if (sourceOfFundsErrors || escalationDateErrors) {
            return { required: true };
        }
        return null;
    };
    RecurringInstructionComponent.prototype.setExistingValues = function (bankAccountNumber, sourceOfFundName, existingInstructionId) {
        var bankAccount = !!bankAccountNumber
            ? this.userBankAccounts.filter(function (k) { return k.accountNumber === bankAccountNumber; })[0]
            : this.userBankAccounts[0];
        var selectedSource = this.sourceOfFundsTypes.filter(function (k) { return k.description === sourceOfFundName; })[0];
        if (existingInstructionId) {
            this.instructionForm.get('existingInstructionId').setValue(existingInstructionId);
        }
        if (selectedSource) {
            this.instructionForm.get('sourceOfFunds').setValue(selectedSource);
        }
        this.instructionForm.get('bankAccount').setValue(bankAccount);
    };
    return RecurringInstructionComponent;
}(BaseInstructionComponent));
export { RecurringInstructionComponent };
