import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BankerLoginResult, Brand, InvestorHelper, JwtToken, MenuToggle, SessionExtension, User } from 'shared/models';

export interface SharedState {
    showGlobalLoader: boolean;
    brand: Brand;
    authenticated: boolean;
    user: User;
    sessionRenewal: SessionExtension;

    menus: MenuToggle[];
    loginInProgress: boolean;
    apiLoginInProgress: boolean;
    accessToken: JwtToken;
    initialToken: JwtToken;
    logoutRequested: boolean;

    bankerLoginEnabled: boolean;
    investorHelper: InvestorHelper;

    showFullFooter: boolean;
    showFixedFooter: boolean;
    showFullWidth: boolean;
    bankerLoginError: BankerLoginResult | Error;

    logoutUrl: string;
    sessionLifeSupport: boolean;
    sessionExpiring: boolean;

    redirectRoute: string;

    showSmallHeader: boolean;
    showTransparentHeader: boolean;
}

const selectSharedState = createFeatureSelector<SharedState>('shared');
export const selectBrand = createSelector(
    selectSharedState,
    (state: SharedState) => state.brand
);
export const selectAuthenticated = createSelector(
    selectSharedState,
    (state: SharedState) => state.authenticated
);

export const selectInvestorEligible = createSelector(
    selectSharedState,
    (state: SharedState) => state.user && state.user.customerDetails && state.user.customerDetails.validCustomer
);

export const selectUser = createSelector(
    selectSharedState,
    (state: SharedState) => state.user
);
export const selectIsDirect = createSelector(
    selectSharedState,
    (state: SharedState) => state.user && !state.user.isBanker
);
export const selectUserBankAccounts = createSelector(
    selectSharedState,
    (state: SharedState) => state.user.bankAccounts
);
export const selectSessionRenewal = createSelector(
    selectSharedState,
    (state: SharedState) => state.sessionRenewal
);
export const selectLogoutRequest = createSelector(
    selectSharedState,
    (state: SharedState) => state.logoutRequested
);
export const selectApiLoginInProgress = createSelector(
    selectSharedState,
    (state: SharedState) => state.apiLoginInProgress
);
export const selectLoginInProgress = createSelector(
    selectSharedState,
    (state: SharedState) => state.loginInProgress
);
export const selectAccessToken = createSelector(
    selectSharedState,
    (state: SharedState) => state.accessToken
);
export const selectInitialToken = createSelector(
    selectSharedState,
    (state: SharedState) => state.initialToken
);
export const selectMenus = createSelector(
    selectSharedState,
    (state: SharedState) => state.menus
);
export const selectBankerLoginEnabled = createSelector(
    selectSharedState,
    (state: SharedState) => state.bankerLoginEnabled
);
export const selectInvestorHelper = createSelector(
    selectSharedState,
    (state: SharedState) => state.investorHelper
);
export const selectShowFullFooter = createSelector(
    selectSharedState,
    (state: SharedState) => state.showFullFooter
);

export const selectShowFixedFooter = createSelector(
    selectSharedState,
    (state: SharedState) => state.showFixedFooter
);

export const selectShowFullWidth = createSelector(
    selectSharedState,
    (state: SharedState) => state.showFullWidth
);
export const selectBankerLoginError = createSelector(
    selectSharedState,
    (state: SharedState) => state.bankerLoginError
);
export const selectShowGlobalLoader = createSelector(
    selectSharedState,
    (state: SharedState) => state.showGlobalLoader
);
export const selectLogoutUrl = createSelector(
    selectSharedState,
    (state: SharedState) => state.logoutUrl
);
export const selectSessionLifeSupport = createSelector(
    selectSharedState,
    (state: SharedState) => state.sessionLifeSupport
);
export const selectSessionExpiring = createSelector(
    selectSharedState,
    (state: SharedState) => state.sessionExpiring
);
export const selectRedirectRoute = createSelector(
    selectSharedState,
    (state: SharedState) => state.redirectRoute
);
export const selectShowSmallHeader = createSelector(
    selectSharedState,
    (state: SharedState) => state.showSmallHeader
);
export const selectShowTransparentHeader = createSelector(
    selectSharedState,
    (state: SharedState) => state.showTransparentHeader
);
