/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./onboarding-investment-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./advised-onboarding-details/advised-onboarding-details.component.ngfactory";
import * as i3 from "./advised-onboarding-details/advised-onboarding-details.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../shared/services/lookup-data.service";
import * as i7 from "../../../../shared/services/number-formatter.service";
import * as i8 from "../../../../banker/services/cis-search.service";
import * as i9 from "../../../services/application-date.service";
import * as i10 from "../../../../shared/services/date-formatter.service";
import * as i11 from "./direct-onboarding-details/direct-onboarding-details.component.ngfactory";
import * as i12 from "./direct-onboarding-details/direct-onboarding-details.component";
import * as i13 from "@angular/common";
import * as i14 from "./onboarding-investment-details.component";
import * as i15 from "../../../../shared/services/logger.service";
var styles_OnboardingInvestmentDetailsComponent = [i0.styles];
var RenderType_OnboardingInvestmentDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OnboardingInvestmentDetailsComponent, data: {} });
export { RenderType_OnboardingInvestmentDetailsComponent as RenderType_OnboardingInvestmentDetailsComponent };
function View_OnboardingInvestmentDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "wim-advised-onboarding-details", [], null, null, null, i2.View_AdvisedOnboardingDetailsComponent_0, i2.RenderType_AdvisedOnboardingDetailsComponent)), i1.ɵdid(2, 180224, null, 0, i3.AdvisedOnboardingDetailsComponent, [i4.Store, i4.Store, i4.Store, i5.FormBuilder, i6.LookupDataService, i7.NumberFormatterService, i8.CisSearchService, i9.ApplicationDateService, i10.DateFormatterService], null, null)], null, null); }
function View_OnboardingInvestmentDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "wim-direct-onboarding-details", [], null, null, null, i11.View_DirectOnboardingDetailsComponent_0, i11.RenderType_DirectOnboardingDetailsComponent)), i1.ɵdid(2, 180224, null, 0, i12.DirectOnboardingDetailsComponent, [i4.Store, i4.Store, i4.Store, i5.FormBuilder, i6.LookupDataService, i7.NumberFormatterService, i8.CisSearchService, i9.ApplicationDateService, i10.DateFormatterService], null, null)], null, null); }
export function View_OnboardingInvestmentDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnboardingInvestmentDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OnboardingInvestmentDetailsComponent_2)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAdvised; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isAdvised; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_OnboardingInvestmentDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wim-onboarding-investment-details", [], null, null, null, View_OnboardingInvestmentDetailsComponent_0, RenderType_OnboardingInvestmentDetailsComponent)), i1.ɵdid(1, 180224, null, 0, i14.OnboardingInvestmentDetailsComponent, [i4.Store, i15.LoggerService], null, null)], null, null); }
var OnboardingInvestmentDetailsComponentNgFactory = i1.ɵccf("wim-onboarding-investment-details", i14.OnboardingInvestmentDetailsComponent, View_OnboardingInvestmentDetailsComponent_Host_0, {}, {}, []);
export { OnboardingInvestmentDetailsComponentNgFactory as OnboardingInvestmentDetailsComponentNgFactory };
