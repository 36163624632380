import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { select, Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccountType, AgreementContent, ConsentContent, CustomerBankAccount, CustomerConsent } from 'application/models';
import { DebitOrderAgreement } from 'application/models/debit-order-agreement';
import { ApplicationService } from 'application/services';
import { CisSearchService } from 'banker/services';
import { InvestmentAccountsPendingAction, InvestorState } from 'investor/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, throwError } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { BaseComponent, EacModalComponent, SharedModalComponent } from 'shared/components';
import { CheckboxQuestion, Fund, PendingAgreement, PortfolioType, User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';
import { AgreementCompletedAction, AgreementSubmitFailed, LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import { ShowMenuAction } from 'shared/store/layout.reducers';

import * as fromShared from 'shared/store/shared.store';

import { InvestorAcceptanceModalComponent } from '../investor-acceptance-modal/investor-acceptance-modal.component';

@Component({
    selector: 'wim-investor-agreements',
    templateUrl: 'investor-agreements.component.pug',
    styleUrls: ['investor-agreements.component.scss'],
})
export class InvestorAgreementsComponent extends BaseComponent implements OnInit, OnDestroy {
    public termsToAccept = false;
    public hasProcessingApplications = false;
    public informationMessageTitle: string;
    public informationMessage: string;
    public hasError: boolean;
    public goalName: string;
    public change: any;
    public agreement: any = {};
    public consent: CustomerConsent;
    public agreementQuestions: any;
    public consentQuestions: any;
    public termsForm: FormGroup;
    public debitAgreementContent: string;
    public tcContent: string;
    public taxAgreementContent: string;
    public productName: string;
    public fundName: string;
    public accountNumber: string;
    public loadingError: boolean;
    private funds: Fund[];
    private portfolioTypes: PortfolioType[];

    public activeAgreement: PendingAgreement;
    private user: User;
    public pendingAgreements;
    public processingApplications;
    public totalAgreements;
    public currentAgreementIndex;
    public caseReference: string;
    private isInitialLoad = true;
    private agreements: AgreementContent[];
    private consents: ConsentContent[];
    private OK_TO_CANCEL = 'Yes Cancel';
    public adviceFee: number;
    public debitOrderDetails: DebitOrderAgreement;
    public insufficientFunds = false;
    public bankAccountNumber;
    public initialLumpSum: number;
    public refreshError: boolean;

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private appService: ApplicationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private lookupService: LookupDataService,
        private toastr: ToastrService,
        private customerService: CisSearchService,
        loggerService: LoggerService
    ) {
        super(loggerService);
    }

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());
        const userSub = this.sharedStore
            .pipe(
                select(fromShared.selectUser),
                filter(user => !!user)
            )
            .subscribe(usr => {
                this.user = usr;
            });
        this.agreementQuestions = [];
        this.consentQuestions = [];
        this.termsForm = new FormGroup({});

        const funds$ = this.lookupService.getHorizonFunds();
        const portfolioTypes$ = this.lookupService.getPortfolioTypes();
        const pendingAgreements$ = this.appService.getClientPendingAgreements();

        const combinedSub = combineLatest(funds$, portfolioTypes$, pendingAgreements$)
            .pipe(map(results => ({ funds: results[0], portfolioTypes: results[1], pendingAgreements: results[2] })))
            .subscribe(
                ({ funds, portfolioTypes, pendingAgreements }) => {
                    this.funds = funds;
                    this.portfolioTypes = portfolioTypes;
                    this.pendingAgreements = pendingAgreements.agreements;
                    this.processingApplications = pendingAgreements.processingApplications;

                    if (this.processingApplications.length > 0) {
                        this.hasProcessingApplications = true;
                        this.informationMessageTitle = 'Pending Terms and Conditions';
                        if(this.processingApplications.length > 1) {
                            this.informationMessage = 'We are busy preparing the documents for your new accounts. Kindly try again in a few minutes.';
                        } else {
                            this.informationMessage = 'We are busy preparing the documents for your new account. Kindly try again in a few minutes.';
                        }                        
                        this.sharedStore.dispatch(new StopLoadingAction());
                    } else {
                        if (this.pendingAgreements.length > 0) {
                            this.termsToAccept = true;
                            this.activeAgreement = this.pendingAgreements[0];
                            this.updateProductDetails();

                            this.totalAgreements = this.pendingAgreements.length;
                            if (this.isInitialLoad) {
                                this.isInitialLoad = false;
                                this.currentAgreementIndex = 1;
                            }
                        } else {
                            this.informationMessageTitle = 'Pending Terms and Conditions';
                            this.informationMessage = 'You don\'t currently have any outstanding Terms and Conditions to accept.';
                            this.sharedStore.dispatch(new StopLoadingAction());
                        }
                    }                    
                },
                () => (this.hasError = true)
            );

        this.registerSubscriptions(combinedSub, userSub);
    }

    public displayContentPopup(cancelled: boolean, acceptedAgreement: PendingAgreement, skipped: boolean) {
        const modalRef = this.modalService.open(InvestorAcceptanceModalComponent);
        modalRef.componentInstance.agreement = acceptedAgreement;
        modalRef.componentInstance.product = this.portfolioTypes.filter(p => p.id === acceptedAgreement.portfolioTypeId)[0].name;
        modalRef.componentInstance.agreementsLeft = this.pendingAgreements.length - 1;
        modalRef.componentInstance.cancelled = cancelled;
        modalRef.componentInstance.skipped = skipped;
    }

    public showAccountNumber() {
        return this.activeAgreement && this.activeAgreement.portfolioTypeId === appConfig.portfolioTypes.INV;
    }

    private setAdviceFee(agreement) {
        if (agreement.isAdvised && agreement.isCash === false) {
            agreement.adviceFee = 0.29;
        } else {
            agreement.adviceFee = 0;
        }
        this.adviceFee = agreement.adviceFee;
    }

    private updateProductDetails() {
        this.termsForm = new FormGroup({});
        this.agreementQuestions = [];
        this.consentQuestions = [];
        this.agreement = {};
        this.consent = new CustomerConsent();
        this.activeAgreement.debitOrderDetails.accountName = AccountType[this.pendingAgreements[0].debitOrderDetails.accountName];
        if (this.activeAgreement.fundId) {
            this.fundName = this.funds.filter(f => f.fundId === this.activeAgreement.fundId)[0].name;
        } else {
            this.fundName = null;
        }
        this.setAdviceFee(this.activeAgreement);
        this.productName = this.portfolioTypes.filter(p => p.id === this.activeAgreement.portfolioTypeId)[0].name;
        this.accountNumber = this.activeAgreement.accountNumber;
        this.bankAccountNumber = this.activeAgreement.debitOrderDetails.accountNumber;
        let lumpSumDetails = this.activeAgreement.debitOrderDetails.lumpSumDetails;
        this.initialLumpSum =
            lumpSumDetails &&
            lumpSumDetails.length > 0 &&
            lumpSumDetails.sort((a, b) => new Date(b.debitDate).getTime() - new Date(a.debitDate).getTime())[0].amount;
        this.caseReference = this.activeAgreement.caseReference;
        this.goalName = this.activeAgreement.goalName;
        this.insufficientFunds = this.activeAgreement.insufficientFunds;
        this.loadAgreementContent(this.activeAgreement.portfolioTypeId);
    }

    public ngOnDestroy(): void {
        this.cleanUpSubscriptions();
    }

    public canSubmit() {
        const canSub =
            this.agreements &&
            this.termsForm &&
            this.termsForm.value &&
            this.agreementQuestions &&
            this.agreements.filter((agreement, index) => {
                return this.agreementQuestions.length > 0 && !this.agreementQuestions[index].value;
            }).length === 0
            && this.consents
            && this.consentQuestions
            && this.consents.filter((consent, index) => {
                return this.consentQuestions.length > 0 && this.consentQuestions[index].required && !this.consentQuestions[index].value;
            }).length === 0;
        return canSub;
    }

    public refreshAccounts() {
        this.refreshError = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.customerService
            .getLoggedInUserBankAccounts()
            .pipe(
                finalize(() => this.sharedStore.dispatch(new StopLoadingAction())),
                catchError(error => {
                    this.refreshError = true;
                    return throwError(error);
                })
            )
            .subscribe(accounts => {
                let matchingAccount = accounts.find(
                    account => `${+account.accountNumber}` === this.bankAccountNumber
                )[0] as CustomerBankAccount;
                if (matchingAccount) {
                    if (matchingAccount.availableBalance >= this.initialLumpSum) {
                        this.insufficientFunds = false;
                    }
                } else {
                    this.refreshError = true;
                }
            });
    }

    public skip() {
        if (this.pendingAgreements.length > 1) {
            this.displayContentPopup(false, this.activeAgreement, true);
            this.loadNextAgreement();
        } else {
            this.sharedStore.dispatch(new StopLoadingAction());
            this.user.hasAgreedTerms = true;
            this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
            this.investorStore.dispatch(new AgreementCompletedAction(this.user));
            this.sharedStore.dispatch(new ShowMenuAction('apply'));
            this.conclude(true);
        }
    }

    public cancelAgreement() {
        const warningRef = this.modalService.open(SharedModalComponent);
        warningRef.componentInstance.modalMessage = `Are you sure you want to cancel this Application?`;
        warningRef.componentInstance.noValue = 'No';
        warningRef.componentInstance.yesValue = 'Yes, Cancel Application';
        warningRef.componentInstance.modalTitle = 'Confirm Application Cancellation';
        warningRef.result
            .then(
                resultType => {
                    if (resultType === this.OK_TO_CANCEL) {
                        this.cancelConfirmed();
                    } else {
                        this.logger.debug(`modal closed`);
                    }
                },
                () => {
                    this.logger.debug(`modal dismissed`);
                }
            )
            .catch(() => {
                this.logger.debug('Confirm modal dismissed');
            });
    }

    public cancelConfirmed() {
        this.sharedStore.dispatch(new LoadingAction());
        this.appService.cancelCustomerApplication(this.activeAgreement.applicationId).subscribe(
            () => {
                if (this.pendingAgreements.length > 1) {
                    this.displayContentPopup(true, this.activeAgreement, false);
                    this.loadNextAgreement();
                } else {
                    this.displayContentPopup(true, this.activeAgreement, false);
                    this.user.hasAgreedTerms = false;
                    this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
                    this.investorStore.dispatch(new AgreementCompletedAction(this.user));
                    this.sharedStore.dispatch(new ShowMenuAction('apply'));
                    this.router.navigate(['/secure/investor']);
                }
                this.sharedStore.dispatch(new StopLoadingAction());
            },
            error => {
                this.hasError = true;
                this.toastr.error('An error occurred while cancelling your application');
                this.investorStore.dispatch(new AgreementSubmitFailed(error));
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        );
    }

    public submitAgreement() {
        this.sharedStore.dispatch(new LoadingAction());
        this.consent.acceptances = this.consentQuestions.filter(q => q.showTickBox).map(question => ({            
            consentType: question.key,
            isAccepted: question.value
        }));
        this.appService.submitPendingAgreements(this.activeAgreement.applicationId, this.consent).subscribe(
            resp => {
                if (resp.isSuccessful) {
                    if (this.pendingAgreements.length > 1) {
                        this.displayContentPopup(false, this.activeAgreement, false);
                        this.loadNextAgreement();
                    } else {
                        this.sharedStore.dispatch(new StopLoadingAction());
                        this.user.hasAgreedTerms = true;
                        this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
                        this.investorStore.dispatch(new AgreementCompletedAction(this.user));
                        this.sharedStore.dispatch(new ShowMenuAction('apply'));
                        this.conclude();
                    }
                }
            },
            err => {
                this.hasError = true;
                this.toastr.error('An error occurred while submitting the form');
                this.investorStore.dispatch(new AgreementSubmitFailed(err));
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        );
    }

    public createForm() {
        const self = this;

        this.agreements.forEach(agreement => {
            const checkBoxQuestion = new CheckboxQuestion({
                key: agreement.agreementId.toString(),
                type: 'checkbox',
                label: agreement.name,
                content: agreement.assistedSummary ? agreement.assistedSummary : agreement.summary,
                order: agreement.agreementId,
                id: `cntrlAgreement${agreement.agreementId}`,
                value: false,
                controlFormName: `isCheckedAgreement${agreement.agreementId}`,
                fileUrl: agreement.moreDetailLink,
                showDebitOrderDetails: agreement.isDebitOrder,
            });
            self.agreementQuestions.push(checkBoxQuestion);
            self.agreement[`isCheckedAgreement${agreement.agreementId}`] = false;
        });

        this.consents.forEach(consent => {
            const checkBoxQuestion = new CheckboxQuestion({
                key: consent.consentId.toString(),
                type: 'checkbox',
                label: consent.name,
                content: consent.summary,
                order: consent.consentId,
                id: `cntrlConsent${consent.consentId}`,
                value: false,
                controlFormName: `isCheckedConsent${consent.consentId}`,
                showTickBox: consent.showTickBox,
                required: consent.isMandatory,
                tickBoxContent: consent.tickBoxSummary
            });
            this.consentQuestions.push(checkBoxQuestion);
            self.agreement[`isCheckedConsent${consent.consentId}`] = false;
        });          

        this.termsForm = this.formBuilder.group(this.agreement);
    }

    public openEacModal(agreement) {
        const modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = {
            funds: [{ fundCode: this.funds.filter(fund => fund.fundId === agreement.fundId)[0].pstCode, allocation: 1 }],
            notAdvised: !agreement.isAdvised,
            portfolioTypeId: this.activeAgreement.portfolioTypeId
        };
    }

    private loadNextAgreement() {
        this.pendingAgreements.splice(0, 1);
        this.activeAgreement = this.pendingAgreements[0];
        this.currentAgreementIndex = this.totalAgreements - this.pendingAgreements.length + 1;

        this.updateProductDetails();
    }

    private loadAgreementContent(portfolioTypeId: number) {
        this.sharedStore.dispatch(new LoadingAction());

        const agreements$ = this.lookupService.getAgreementContent(portfolioTypeId);
        const consents$ = this.lookupService.getConsentContent();

        combineLatest(agreements$, consents$, (agreements, contents) => {
            return { agreements, contents };
        })
            .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
            .subscribe(
                ({ agreements, contents }) => {
                    this.agreements = agreements;
                    this.consents = contents;
                    this.createForm();
                },
                () => (this.loadingError = true)
            );          
    }

    private conclude(skipped = false) {
        const modalRef = this.modalService.open(SharedModalComponent);
        if (skipped) {
            modalRef.componentInstance.modalMessage = `Please don't forget to transfer money and then return here`;
            modalRef.componentInstance.yesValue = 'Ok';
            modalRef.componentInstance.modalTitle = 'Terms And Conditions Skipped';
            modalRef.result.then(resultType => {
                if (resultType === this.OK_TO_CANCEL) {
                    this.router.navigate(['/secure/investor']);
                }
            });            
        } else {
            modalRef.componentInstance.modalMessage = `Thank you for activating your ${this.productName}.`;
            modalRef.componentInstance.yesValue = 'Ok';
            modalRef.componentInstance.modalTitle = 'Successful';
            this.termsToAccept = false;
            this.hasProcessingApplications = false;
            this.informationMessageTitle = 'Accepted Terms and Conditions';
            this.informationMessage = `Your new ${this.productName} has successfully been opened. To open an additional investment solution click on Invest Now`;
        }

    }

    private refresh() {
        this.hasProcessingApplications = false;
        this.ngOnInit();
    }
}
