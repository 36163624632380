var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DecimalPipe } from '@angular/common';
import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components/base';
import { EacAccount, EacItem, EacPerFund, EacSvcItem } from 'shared/components/eac-modal/effective-annual-cost/models';
import { FeatureFlags } from 'shared/models';
import { EACRequest } from 'shared/models/eac/eac-request';
import { EffectiveAnnualCostService } from 'shared/services/eac.service';
import { FeatureFlagService } from 'shared/services/feature-flag.service';
import { LoggerService } from 'shared/services/logger.service';
import { LookupDataService } from 'shared/services/lookup-data.service';
var EffectiveAnnualCostCalucationComponent = /** @class */ (function (_super) {
    __extends(EffectiveAnnualCostCalucationComponent, _super);
    function EffectiveAnnualCostCalucationComponent(lookupService, loggerService, decimalPipe, eacService, featureFlagService) {
        var _this = _super.call(this, loggerService) || this;
        _this.lookupService = lookupService;
        _this.decimalPipe = decimalPipe;
        _this.eacService = eacService;
        _this.featureFlagService = featureFlagService;
        _this.items = [];
        _this.funds = null;
        _this.fees = [];
        _this.vatRate = 1;
        _this.investFundCodes = [];
        _this.appianInstrumentIds = [];
        _this.eacDisplayedYears = [1, 3, 5, 10];
        return _this;
    }
    EffectiveAnnualCostCalucationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var subscription = combineLatest(this.lookupService.getHorizonFunds(), this.lookupService.getPortfolioInstrumentFees(), this.lookupService.getVATRate(), function (funds, fees, vat) {
            return { funds: funds, fees: fees, vat: vat };
        }).subscribe(function (_a) {
            var funds = _a.funds, fees = _a.fees, vat = _a.vat;
            _this.funds = funds;
            _this.fees = fees;
            _this.vatRate = vat;
            if (_this.featureFlagService.isEnabled(FeatureFlags.EacService)) {
                _this.getEAC(_this.account);
            }
            else {
                _this.recalculate();
            }
        });
        this.registerSubscriptions(subscription);
    };
    EffectiveAnnualCostCalucationComponent.prototype.ngOnChanges = function (changes) {
        this.recalculate();
    };
    EffectiveAnnualCostCalucationComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    // EAC service integration if (this.eacServiceEnabaled)
    EffectiveAnnualCostCalucationComponent.prototype.getEAC = function (account) {
        var _this = this;
        this.funds.forEach(function (fund) {
            _this.investFundCodes.push(fund.pstCode);
            _this.appianInstrumentIds.push(fund.instrumentId);
        });
        var request = new EACRequest();
        request.funds = this.mapFundType(account);
        request.transactionId = '/invest';
        request.portfolioTypeId = account.portfolioTypeId;
        this.eacService
            .getEAC(request)
            .pipe(this.scavenger.collect())
            .subscribe(function (data) { return _this.mapEacResponse(data); });
    };
    EffectiveAnnualCostCalucationComponent.prototype.mapFundType = function (eacAccount) {
        var _this = this;
        var fundsWithAllocation = new EACRequest();
        fundsWithAllocation.funds = [];
        eacAccount.funds.forEach(function (fund) {
            var mapId = _this.investFundCodes.indexOf(fund.fundCode);
            fund.fundCode = _this.appianInstrumentIds[mapId].toString();
            var mappedFund = { FundId: +fund.fundCode, FundAllocation: fund.allocation };
            fundsWithAllocation.funds.push(mappedFund);
        });
        return fundsWithAllocation.funds;
    };
    EffectiveAnnualCostCalucationComponent.prototype.mapEacResponse = function (response) {
        var _this = this;
        var investmentManagementArray = [];
        var adviceArray = [];
        var adminArray = [];
        var otherArray = [];
        var eacArray = [];
        response.effectiveAnnualCost.forEach(function (yearSet) {
            if (_this.eacDisplayedYears.includes(yearSet.year)) {
                yearSet.fees.forEach(function (fee) {
                    if (fee.type === 'Investment Management') {
                        investmentManagementArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Advice') {
                        adviceArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Administration') {
                        adminArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Other') {
                        otherArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Effective Annual Cost') {
                        eacArray.push(fee.value / 100);
                    }
                });
            }
        });
        this.items = [
            new EacSvcItem('Investment management', investmentManagementArray),
            new EacSvcItem('Advice', adviceArray),
            new EacSvcItem('Administration', adminArray),
            new EacSvcItem('Other', otherArray),
            new EacSvcItem('*Effective Annual Cost', eacArray),
        ];
        if (this.account.notAdvised) {
            this.items.forEach(function (item) {
                if (item.name === 'Advice') {
                    item.years = [0.0, 0.0, 0.0, 0.0];
                }
            });
        }
    };
    // EAC component calcualtion if (!this.eacServiceEnabaled)
    EffectiveAnnualCostCalucationComponent.prototype.recalculate = function () {
        if (!this.funds) {
            return;
        }
        var fees = new Map();
        var _loop_1 = function (accountFund) {
            var fund = this_1.funds.find(function (f) { return f.pstCode === accountFund.fundCode; });
            fees.set(accountFund, this_1.calculateForFund(fund));
        };
        var this_1 = this;
        for (var _i = 0, _a = this.account.funds; _i < _a.length; _i++) {
            var accountFund = _a[_i];
            _loop_1(accountFund);
        }
        var weightedEac = new EacPerFund();
        for (var _b = 0, _c = Array.from(fees.keys()); _b < _c.length; _b++) {
            var item = _c[_b];
            var eac = fees.get(item);
            if (this.account.notAdvised) {
                weightedEac.adviceFee = 0;
            }
            else {
                weightedEac.adviceFee += eac.adviceFee * item.allocation;
            }
            weightedEac.adminFee += eac.adminFee * item.allocation;
            weightedEac.investmentManagementFee += eac.investmentManagementFee * item.allocation;
            weightedEac.otherFee += eac.otherFee * item.allocation;
        }
        this.items = [
            new EacItem('Investment management', weightedEac.investmentManagementFee),
            new EacItem('Advice', weightedEac.adviceFee),
            new EacItem('Administration', weightedEac.adminFee),
            new EacItem('Other', weightedEac.otherFee),
            new EacItem('*Effective Annual Cost', weightedEac.total(this.decimalPipe)),
        ];
    };
    EffectiveAnnualCostCalucationComponent.prototype.calculateForFund = function (fund) {
        if (!fund) {
            return new EacPerFund(0, 0, 0, 0);
        }
        return new EacPerFund(this.addVAT(fund.ter + fund.transactionCosts), this.addVAT(this.getFundAdviceFee(fund)), this.addVAT(this.getFundAdminFee(fund)), this.addVAT(0.0));
    };
    EffectiveAnnualCostCalucationComponent.prototype.getFundAdminFee = function (fund) {
        var _this = this;
        var fee = this.fees.find(function (f) { return f.portfolioTypeId === _this.account.portfolioTypeId && f.instrumentId === fund.instrumentId && f.instrumentFeeTypeId === 6; });
        return fee.feeValue;
    };
    EffectiveAnnualCostCalucationComponent.prototype.getFundAdviceFee = function (fund) {
        var _this = this;
        var fee = this.fees.find(function (f) { return f.portfolioTypeId === _this.account.portfolioTypeId && f.instrumentId === fund.instrumentId && f.instrumentFeeTypeId === 7; });
        return fee.feeValue;
    };
    EffectiveAnnualCostCalucationComponent.prototype.addVAT = function (fee) {
        return fee * (1 + this.vatRate);
    };
    return EffectiveAnnualCostCalucationComponent;
}(BaseComponent));
export { EffectiveAnnualCostCalucationComponent };
