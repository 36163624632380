import { Injectable } from '@angular/core';

@Injectable()
export class SaIdNumberValidationService {
    public getCheckDigit(idNumber: string): number {
        idNumber = idNumber.substring(0, idNumber.length - 1);
        const oddDigitsSum = idNumber
            .split('')
            .filter((element, index) => (index + 1) % 2 !== 0)
            .reduce((total, char) => total + parseInt(char, 10), 0);

        const evenDigitsConcatenated = idNumber
            .split('')
            .filter((element, index) => (index + 1) % 2 === 0)
            .join('');

        const evenDigitsConcatednatedAndDoubled = `${parseInt(evenDigitsConcatenated, 10) * 2}`;
        const sumOfEvenDigits = evenDigitsConcatednatedAndDoubled.split('').reduce((total, char) => total + parseInt(char, 10), 0);
        const total = sumOfEvenDigits + oddDigitsSum;

        const overallSumString = `${total}`;
        const tallyDigit = parseInt(overallSumString[overallSumString.length - 1], 10);

        if (tallyDigit === 0) {
            return 0;
        }

        return 10 - tallyDigit;
    }

    public validate(idNumber: string): boolean {
        return (
            idNumber !== null &&
            !isNaN(+idNumber) &&
            idNumber.length === 13 &&
            this.controlDigitValidation(idNumber) &&
            this.datePartValidation(idNumber)
        );
    }

    public getDateOfBirth(idNumber: string): string {
        const fullDate = new Date(+idNumber.substring(0, 2), +idNumber.substring(2, 4) - 1, +idNumber.substring(4, 6));
        return fullDate.getFullYear().toString() + (fullDate.getMonth() + 1).toString() + fullDate.getDate().toString();
    }

    public getGender(idNumber: string): string {
        if (!idNumber || idNumber.length < 7) {
            return undefined;
        }

        return parseInt(idNumber[6], 10) < 5 ? 'F' : 'M';
    }

    private controlDigitValidation(idNumber: string) {
        const checkDigit = parseInt(idNumber[idNumber.length - 1], 10);
        return this.getCheckDigit(idNumber) === checkDigit;
    }

    private datePartValidation(idNumber: string) {
        let month = parseInt(idNumber.substring(2, 4), 10);
        if (month <= 12 && month >= 1) {
            const day = parseInt(idNumber.substring(4, 6), 10);
            if (day <= 31 && day >= 1) {
                return true;
            }
        }
        return false;
    }
}
