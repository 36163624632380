import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { JwtToken } from 'shared/models';
import { TokenStorageService } from 'shared/services/auth-tokens/token-storage.service';
import { Logger, LoggerService } from 'shared/services/logger.service';
import { InitialTokenAction, NoInitialTokenAction } from 'shared/store/auth-token.reducers';
import { SharedState } from 'shared/store/shared.store';

@Injectable()
export class AuthTokenService {
    private logger: Logger;

    constructor(private tokenStorage: TokenStorageService, private store: Store<SharedState>, loggerService: LoggerService) {
        this.logger = loggerService.getLogger('AuthTokenService');
    }

    public init() {
        this.logger.debug('Checking for existing access token');
        const existingToken = this.getToken();
        if (existingToken) {
            this.logger.debug('Token exists and expires at ' + moment(existingToken.expiresAt).format('YYYY-MM-DD HH:mm:ss'));
            this.store.dispatch(new InitialTokenAction(existingToken));
        } else {
            this.store.dispatch(new NoInitialTokenAction());
        }
    }

    public getToken(): JwtToken {
        return this.tokenStorage.getAccessToken();
    }

    public clearToken() {
        this.tokenStorage.deleteToken();
    }
}
