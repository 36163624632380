import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { Observable, ObservableInput, of } from 'rxjs';
import { filter ,  catchError, finalize, map, tap } from 'rxjs/operators';
import { BankerLoginResult, FeatureFlags, User } from 'shared/models';
import { AuthTokenService } from 'shared/services/auth-tokens/auth-token.service';
import { Logger, LoggerService } from 'shared/services/logger.service';

import { LoadingAction, StopLoadingAction } from 'shared/store/global-loader.reducers';
import { HideMenuAction, ShowMenusAction } from 'shared/store/layout.reducers';
import { SharedState } from 'shared/store/shared.store';
import * as fromShared from 'shared/store/shared.store';

import bugsnagClient from 'bugsnag';
import { FeatureFlagService } from 'shared/services/feature-flag.service';
import { SessionService } from 'shared/services/session.service';

import {
    ApiAuthenticationStarted,
    BankerLoginEnabledAction,
    BankerLoginFailedAction,
    LoginFailedAction,
    LoginStartedAction,
    LoginSuccessAction,
    LogoutAction,
} from 'shared/store/user.reducers';

@Injectable()
export class AuthenticationService {
    public pendingLogin: Observable<any>;

    private logger: Logger;
    private userApiEndpoint = appConfig.apiUrl + '/users';
    private sessionApiEndpoint = appConfig.apiUrl + '/session';
    private bankerApiEndpoint = appConfig.apiUrl + '/banker-login';

    private wasLoggedIn = false;

    constructor(
        private store: Store<SharedState>,
        private http: HttpClient,
        private logService: LoggerService,
        private authTokenService: AuthTokenService,
        private router: Router,
        private sessionService: SessionService,
        private featureFlagService: FeatureFlagService
    ) {
        this.logger = this.logService.getLogger('AuthenticationService');
        this.store
            .select(fromShared.selectLogoutRequest)
            .pipe(filter(requested => requested))
            .subscribe(() => {
                this.logout();
            });

        this.store.select(fromShared.selectAuthenticated).subscribe(loggedIn => {
            if (loggedIn) {
                this.wasLoggedIn = true;
            }
        });

        this.store
            .select(fromShared.selectRedirectRoute)
            .pipe(filter(route => !!route))
            .subscribe(route => {
                setTimeout(() => this.router.navigateByUrl(route), 500);
            });
    }

    public checkIfBankerLoginAllowed() {
        this.http
            .get<boolean>(`${this.bankerApiEndpoint}/check`)
            .subscribe(allowed => this.store.dispatch(new BankerLoginEnabledAction(allowed)), error => new BankerLoginEnabledAction(false));
    }

    public bankerLogin(username: string, password: string) {
        this.store.dispatch(new LoginStartedAction());

        this.http.post<BankerLoginResult>(`${this.bankerApiEndpoint}/login`, { username, password }).subscribe(
            result => {
                if (!result.authenticated) {
                    this.logger.error(`Could not log in ${result.reason}`);
                    this.store.dispatch(new BankerLoginFailedAction(result));
                } else {
                    this.setUser(result.user);
                    this.store.dispatch(new LoginSuccessAction(result.user));
                }
            },
            error => {
                this.store.dispatch(new BankerLoginFailedAction(error));
            }
        );
    }

    public extendSession() {
        return this.http.get<User>(`${this.userApiEndpoint}/login`).subscribe();
    }

    public startApiSession() {
        this.store.dispatch(new LoadingAction());
        let token = this.authTokenService.getToken();
        this.store.dispatch(new ApiAuthenticationStarted());
        return this.http
            .get(this.sessionApiEndpoint, { headers: { Authorization: `Bearer ${token.access_token}` } })
            .pipe(finalize(() => this.authTokenService.clearToken()));
    }

    public apiLogin() {
        this.store.dispatch(new LoadingAction());
        this.store.dispatch(new ApiAuthenticationStarted());
        this.pendingLogin = this.http.get<User>(`${this.userApiEndpoint}/login`);
        return this.pendingLogin.pipe(
            tap(user => this.setUser(user)),
            map(user => new LoginSuccessAction(user)),
            catchError(() => {
                this.store.dispatch(new StopLoadingAction());
                return of(new LogoutAction());
            })
        );
    }

    public loginFailed(error: Error): ObservableInput<any> {
        this.store.dispatch(new LoginFailedAction());
        return Observable;
    }

    private logout(dispatchEvent = true) {
        this.logger.info('Logging user out');
        this.sessionService.endLifeSupport();
        if (dispatchEvent) {
            this.store.dispatch(new LogoutAction());
        }

        this.http.get(`${this.userApiEndpoint}/logout`).subscribe();

        this.store.select(fromShared.selectLogoutUrl).subscribe(url => {
            if (url) {
                this.router.navigateByUrl(url);
            } else {
                if (appConfig.env === 'Default') {
                    this.router.navigateByUrl('/invest');
                    alert('User logged out, in prod you would have been directed to fnb.co.za');
                } else {
                    window.location.assign('https://www.fnb.co.za');
                }
            }
        });
    }

    private setUser(user: User): User {
        this.featureFlagService.setFlags(user.featureFlags);
        this.sessionService.startSession();
        this.store.dispatch(new HideMenuAction('*'));
        if (user.isBanker) {
            this.store.dispatch(new ShowMenusAction(['onboarding']));
        } else {
            let menus = ['apply'];
            if (
                this.featureFlagService.isEnabled(FeatureFlags.BalanceDisplay) ||
                this.featureFlagService.isEnabled(FeatureFlags.SharesBalanceDisplay)
            ) {
                menus.push('accounts');
            }
            if (this.featureFlagService.isEnabled(FeatureFlags.AcceptTsAndCs)) {
                menus.push('termsconditions');
            }
            this.store.dispatch(new ShowMenusAction(menus));
            if(user.customerDetails.pendingAgreements && user.customerDetails.pendingAgreements.length > 0) {
                this.store.dispatch(new HideMenuAction('apply'));
            }
            if(user.customerDetails.processingApplications && user.customerDetails.processingApplications.length > 0) {
                this.store.dispatch(new HideMenuAction('apply'));
            }            
        }
        this.store.dispatch(new StopLoadingAction());
        bugsnagClient.user = user;
        return user;
    }
}
