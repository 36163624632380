import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import appConfig from 'appConfig';
import { CashProjectionRequest, CashProjectionResponse } from 'pst/services/cash-calculator/models';
import { Observable } from 'rxjs';
import { Logger, LoggerService } from 'shared/services/logger.service';

@Injectable()
export class CashProjectionService {
    private logger: Logger;
    constructor(private http: HttpClient, loggerService: LoggerService) {
        this.logger = loggerService.getLogger('CashProjectionService');
    }

    public getProjection(request: CashProjectionRequest): Observable<CashProjectionResponse> {
        return this.http.post<CashProjectionResponse>(`${appConfig.apiUrl}/cash-projections`, request);
    }
}
