import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import appConfig from 'appConfig';
import { PstReference, PstResult } from 'pst/models';
import { PstResultContent } from 'pst/models';
import { InvestmentAccountCalculationResult, InvestmentAccountCalculator, PstResultType, PstService, PstTfsaValidator } from 'pst/services';
import { FundSelectedAction, PstAdviceShownAction, PstCompletedAction, PstScreenValidAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { PstState } from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent, EacModalComponent } from 'shared/components';
import { Fund, PortfolioType } from 'shared/models';
import { LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { EnableFixedFooterAction, LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { bigNumberMaxValidator, bigNumberMinIfNotZeroValidator, bigNumberMinValidator } from 'shared/validators';

import { PstContentModalComponent } from '../content-modal/pst-content-modal.component';

const OVER_UNDER_LEEWAY = 100;

@Component({
    selector: 'wim-pst-result',
    templateUrl: 'investment-account-result.component.pug',
    styleUrls: ['investment-account-result.component.scss'],
})
export class InvestmentAccountResultComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    public customerName: string;
    public calculation: InvestmentAccountCalculationResult;
    public fund: Fund;
    public chart: Chart;

    public calculationForm: FormGroup;

    public minDebitOrder = 500;
    public maxDebitOrder = 100000;

    public minLumpSum = 5000;
    public maxLumpSum = 1000000;

    public minIncrease = 0;
    public maxIncrease = 15;

    private products: Map<number, PortfolioType> = new Map();
    public product: PortfolioType;

    public overUnder = 1102846;

    public overGoal = true;
    public underGoal = false;
    public hitGoal = false;
    public tfsaEligible = false;
    public tfsaSelected = false;
    public tfsaApplicable = true;
    public tfsaDeclined = false;
    public showEscalation = true;
    public isJustInvest = false;
    public isDirect = false;

    private costs = 0;
    private originalPstResult: PstResult = null;
    private recalculateTimer = null;
    private content: PstResultContent[] = [];

    private resultType: PstResultType;

    constructor(
        loggerService: LoggerService,
        private pstStore: Store<PstState>,
        private sharedStore: Store<SharedState>,
        private pstService: PstService,
        private router: Router,
        private lookupService: LookupDataService,
        private pstCalculator: InvestmentAccountCalculator,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private numberFormatter: NumberFormatterService,
        private tfsaValidation: PstTfsaValidator
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.sharedStore.dispatch(new EnableFixedFooterAction(true));
        const funds$ = this.lookupService.getHorizonFunds();
        const pstResult$ = this.pstStore.select(fromPst.selectInProgressPst);
        const pstTree$ = this.pstStore.select(fromPst.selectPstTree);
        const vatRate$ = this.lookupService.getVATRate();
        const products$ = this.lookupService.getPortfolioTypes();
        const isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        const content$ = this.lookupService.getPstResultContent();

        // rxJs and TypeScript have some limitations, so we need to map the results in a different way
        // https://github.com/ReactiveX/rxjs/issues/3601
        const subscription = combineLatest(funds$, pstResult$, pstTree$, vatRate$, products$, isDirect$, content$)
            .pipe(
                this.scavenger.collect(),
                map(
                    ([funds, pstResult, pstTree, vatRate, products, isDirect, content]: [
                        Fund[],
                        PstResult,
                        PstReference,
                        number,
                        PortfolioType[],
                        boolean,
                        PstResultContent[]
                    ]) => ({
                        funds,
                        pstResult,
                        pstTree,
                        vatRate,
                        products,
                        isDirect,
                        content,
                    })
                )
            )
            .subscribe(({ funds, pstResult, pstTree, vatRate, products, isDirect, content }) => {
                if (!pstResult) {
                    this.sharedStore.dispatch(new StopLoadingAction());
                    this.router.navigateByUrl('/secure/banker/customer-search');
                    return;
                }
                // Empty pstResult, pst is not yet completed. Happens when restarting PST
                if (Object.keys(pstResult.capturedValues).length === 0) {
                    return;
                }

                if (!this.originalPstResult) {
                    InvestmentAccountCalculator.extractCapturedValues(pstResult);
                    this.originalPstResult = Object.assign({}, pstResult);
                    this.pstStore.dispatch(new PstAdviceShownAction(this.originalPstResult));
                }
                if (!this.resultType) {
                    if (pstResult.goalAmount === null || pstResult.goalAmount === 0) {
                        this.logger.debug('User selected Just Invest during PST');
                        this.resultType = PstResultType.FutureValue;
                    } else {
                        this.logger.debug('User selected a goal during PST');
                        this.resultType = PstResultType.Installment;
                    }
                }
                pstResult.fundCode = pstResult.fundCode || pstTree.node.nodeDescription;
                pstResult.reference = pstResult.reference || pstTree.transactionref;

                products.forEach(product => {
                    this.products.set(product.id, product);
                });

                this.isDirect = isDirect;
                this.content = content;
                this.product = this.products.get(appConfig.portfolioTypes.INV);

                this.fund = funds.filter(fund => fund.pstCode === pstResult.fundCode)[0];
                this.pstStore.dispatch(new FundSelectedAction(this.fund));
                this.costs = this.fund.adviceFee * (1 + vatRate);

                this.sharedStore.dispatch(new StopLoadingAction());

                this.recalculate(pstResult);
                this.createForm();

                this.pstStore.dispatch(new PstScreenValidAction(this.calculationForm.valid));
            });
    }

    public isContent(id: number) {
        return !!this.content.find(c => c.id === id);
    }

    public displayContentPopup(id: number) {
        if (!this.isContent(id)) {
            return;
        }
        let content = this.content.find(c => c.id === id);

        const modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    }

    public displayProjectionContentPopup(id: number) {
        if (!this.isContent(id)) {
            return;
        }
        let content = Object.assign('', this.content.find(c => c.id === id));
        let bestCase = this.transformBigNumberRand(this.calculation.bestCase).trim();
        let worstCase = this.transformBigNumberRand(this.calculation.worstCase).trim();
        let recommended = this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution).trim();
        let original = this.transformBigNumberRand(this.calculation.originalMonthlyContribution).trim();

        content.content = content.content.replace('{{UP_SCENARIO}}', bestCase === 'R' ? 'R 0' : bestCase);
        content.content = content.content.replace('{{DOWN_SCENARIO}}', worstCase === 'R' ? 'R 0' : worstCase);
        content.content = content.content.replace('{{RECOMMENDED}}', recommended === 'R' ? 'R 0' : recommended);
        content.content = content.content.replace('{{ORIGINAL}}', original === 'R' ? 'R 0' : original);

        const modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    }

    public displayFundContentPopup() {
        let id = 0;
        switch (this.fund.instrumentId) {
            case 7:
                id = 4;
                break;
            case 6:
                id = 5;
                break;
            case 3:
                id = 6;
                break;
            case 5:
                id = 7;
                break;
        }
        this.displayContentPopup(id);
    }

    public fixGoal() {
        this.calculationForm.get('goalAmount').setValue(this.transformBigNumberRand(this.calculation.estimatedFutureValue));
    }

    public fixInstallment() {
        this.calculationForm.get('debitOrder').setValue(this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution));
    }

    private recalculate(pstResult: PstResult) {
        if (this.tfsaSelected) {
            pstResult.contributionIncrease = 0;
        }
        const calculation = this.pstCalculator.calculate(pstResult, this.costs, this.resultType);

        this.isJustInvest = this.resultType === PstResultType.FutureValue;
        this.calculation = calculation;
        this.chart = this.calculation.graph;
        pstResult.noOtherTfsaConfirmed = this.tfsaEligible ? true : null;
        this.pstStore.dispatch(new PstCompletedAction(pstResult));

        this.overUnder = Math.abs(pstResult.goalAmount - calculation.estimatedFutureValue);

        this.hitGoal = this.overUnder <= OVER_UNDER_LEEWAY;

        if (this.hitGoal) {
            this.underGoal = false;
            this.overGoal = false;
        } else {
            this.underGoal = calculation.estimatedFutureValue < pstResult.goalAmount;
            this.overGoal = !this.underGoal;
        }
    }

    private recalculateWithDebounce(pstResult: PstResult): Promise<any> {
        return new Promise<any>(resolve => {
            if (this.recalculateTimer) {
                clearTimeout(this.recalculateTimer || this.recalculateTimer._id);
            }
            this.recalculateTimer = setTimeout(() => {
                this.recalculate(pstResult);
                this.calculateTfsaViability();
                resolve();
            }, 500);
        });
    }

    public openEacModal() {
        const modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = { funds: [{ fundCode: this.fund.pstCode, allocation: 1 }] };
    }

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
        this.sharedStore.dispatch(new EnableFixedFooterAction(false));
    }

    public restart() {
        this.cleanUpSubscriptions();
        this.pstService.startNewTree();
    }

    private transformBigNumberRand(value) {
        return this.numberFormatter.transformNumberTextPrepend(value, null, 'R ', val => {
            return this.numberFormatter.transformBigNumber(val, null, true);
        });
    }

    private transformTerm(value) {
        return this.numberFormatter.transformTerm(value);
    }
    private transformPercent(value) {
        return this.numberFormatter.transformPercent(`${+value * 100}`, '1.0-2');
    }

    private parseBigNumber(bigNumber) {
        return this.numberFormatter.parseNumberTextPrepend(bigNumber, 'R ', this.numberFormatter.parseBigNumber);
    }

    private parsePercent(bigNumber) {
        return this.numberFormatter.parseNumberTextAppend(bigNumber, '%', this.numberFormatter.parsePercent);
    }

    private hasRandValueChanged(originalValue, newValue) {
        let rawNewValue = newValue;
        if (rawNewValue.indexOf('R') >= 0) {
            rawNewValue = this.parseBigNumber(rawNewValue);
        }
        return +rawNewValue !== +originalValue;
    }

    private hasPercentValueChanged(originalValue, newValue) {
        let rawNewValue = newValue;
        if (rawNewValue.indexOf('%') >= 0) {
            rawNewValue = +this.numberFormatter.parsePercent(rawNewValue) / 100;
        } else {
            rawNewValue /= 100;
        }
        return +rawNewValue !== +originalValue;
    }

    private validateLumpsumAndDebitOrder(lumpsum, debitOrder) {
        const lumpsumControl = this.calculationForm.controls.lumpSum;
        const debitOrderControl = this.calculationForm.controls.debitOrder;

        if (+this.parseBigNumber(lumpsum) === 0 && +this.parseBigNumber(debitOrder) === 0) {
            let errors = {
                bothZero: true,
            };
            lumpsumControl.setErrors(errors);
            debitOrderControl.setErrors(errors);
        } else {
            if (lumpsumControl.errors && Object.keys(lumpsumControl.errors).length === 1 && lumpsumControl.hasError('bothZero')) {
                lumpsumControl.setErrors(null);
            }
            if (debitOrderControl.errors && Object.keys(debitOrderControl.errors).length === 1 && debitOrderControl.hasError('bothZero')) {
                debitOrderControl.setErrors(null);
            }
        }
    }

    private createForm() {
        this.calculationForm = this.formBuilder.group({
            goalAmount: [
                this.transformBigNumberRand(this.calculation.pstResult.goalAmount),
                Validators.compose([Validators.required, bigNumberMinValidator(0, 'R')]),
            ],
            lumpSum: [
                this.transformBigNumberRand(this.calculation.pstResult.lumpSum),
                Validators.compose([bigNumberMinIfNotZeroValidator(this.minLumpSum, 'R'), bigNumberMaxValidator(this.maxLumpSum, 'R')]),
            ],
            debitOrder: [
                this.transformBigNumberRand(this.calculation.originalMonthlyContribution),
                Validators.compose([
                    bigNumberMinIfNotZeroValidator(this.minDebitOrder, 'R'),
                    bigNumberMaxValidator(this.maxDebitOrder, 'R'),
                ]),
            ],
            annualIncrease: [
                this.transformPercent(this.calculation.pstResult.contributionIncrease),
                Validators.compose([
                    bigNumberMinValidator(this.minIncrease, '%'),
                    bigNumberMaxValidator(this.maxIncrease, '%'),
                    Validators.min(this.minIncrease),
                    Validators.pattern('^[0-9]+[%]*$'),
                ]),
            ],
            // These are disabled as they cannot be edit directly by the user
            lumpSumDisabled: [this.transformBigNumberRand(this.calculation.pstResult.lumpSum)],

            futureValue: [this.transformBigNumberRand(this.calculation.estimatedFutureValue)],
            term: [this.transformTerm(this.calculation.pstResult.term)],
            goalName: [this.calculation.pstResult.goalName],
            calculatedMonthlyContribution: [this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution)],
            investTaxFree: [false],
        });

        this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
        this.tfsaApplicable = this.tfsaValidation.tfsaTermValidate(this.calculation.pstResult.term);
        this.calculationForm.valueChanges.subscribe(async changes => await this.checkForChanges(changes));
        this.calculateTfsaViability();
    }

    private async checkForChanges(changes) {
        if (this.hasRandValueChanged(this.calculation.pstResult.goalAmount, changes.goalAmount)) {
            this.calculation.pstResult.goalAmount = +this.parseBigNumber(changes.goalAmount);
            await this.recalculateInstallment();
        }
        if (this.hasRandValueChanged(this.calculation.pstResult.lumpSum, changes.lumpSum)) {
            this.calculation.pstResult.lumpSum = +this.parseBigNumber(changes.lumpSum);
            this.calculationForm.get('lumpSumDisabled').setValue(this.transformBigNumberRand(changes.lumpSum), { emitEvent: false });
            await this.recalculateInstallment();
            await this.recalculateFutureValue(true);
        }
        if (this.hasRandValueChanged(this.calculation.pstResult.clientInstallment, changes.debitOrder)) {
            this.calculation.pstResult.clientInstallment = +this.parseBigNumber(changes.debitOrder);
            await this.recalculateFutureValue();
        }
        if (this.hasPercentValueChanged(this.calculation.pstResult.contributionIncrease, changes.annualIncrease)) {
            this.calculation.pstResult.contributionIncrease = +this.parsePercent(changes.annualIncrease) / 100;

            await this.recalculateInstallment();
            await this.recalculateFutureValue(true);
        }

        if (changes.investTaxFree !== this.tfsaSelected) {
            this.tfsaSelected = changes.investTaxFree;
            if (changes.investTaxFree) {
                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.TFDS;
                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
            } else {
                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                this.calculation.pstResult.contributionIncrease =
                    +this.parsePercent(this.calculationForm.get('annualIncrease').value) / 100;
            }
            this.recalculate(this.calculation.pstResult);
        }

        this.validateLumpsumAndDebitOrder(changes.lumpSum, changes.debitOrder);
        this.pstStore.dispatch(new PstScreenValidAction(this.calculationForm.valid));
    }

    private async recalculateInstallment(usePreviousCalculation = false) {
        if (!usePreviousCalculation) {
            await this.recalculateWithDebounce(this.calculation.pstResult);
        }
        this.calculationForm
            .get('calculatedMonthlyContribution')
            .setValue(this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution), { emitEvent: false });
    }

    private async recalculateFutureValue(usePreviousCalculation = false) {
        if (!usePreviousCalculation) {
            await this.recalculateWithDebounce(this.calculation.pstResult);
        }
        this.calculationForm
            .get('futureValue')
            .setValue(this.transformBigNumberRand(this.calculation.estimatedFutureValue), { emitEvent: false });
    }

    public confrimTFSAinterest() {
        if (this.tfsaEligible) {
            this.tfsaEligible = false;
            if (this.tfsaSelected) {
                this.tfsaSelected = false;
                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                this.calculation.pstResult.contributionIncrease =
                    +this.parsePercent(this.calculationForm.get('annualIncrease').value) / 100;
                this.recalculate(this.calculation.pstResult);
            }
        } else {
            this.tfsaEligible = true;
        }
    }

    public denyTFSAinterest() {
        this.tfsaDeclined = true;
    }

    public changeTFSAinterest() {
        this.tfsaDeclined = false;
        if (this.tfsaEligible) {
            this.tfsaEligible = false;
            if (this.tfsaSelected) {
                this.tfsaSelected = false;
                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                this.calculation.pstResult.contributionIncrease =
                    +this.parsePercent(this.calculationForm.get('annualIncrease').value) / 100;
                this.recalculate(this.calculation.pstResult);
            }
        } else {
            this.tfsaEligible = false;
        }
    }

    private calculateTfsaViability() {
        this.tfsaApplicable = this.tfsaValidation.validatePstForTfsa(
            this.calculation.pstResult.goalAmount,
            this.calculation.pstResult.lumpSum,
            this.calculation.originalMonthlyContribution,
            this.calculation.pstResult.term,
            this.isDirect
        );
        if (!this.tfsaApplicable && this.tfsaSelected) {
            this.tfsaSelected = false;
            this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
            this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
        }
    }
}
