var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from 'shared/components';
import { LoggerService } from 'shared/services/logger.service';
var PstTfsaValidator = /** @class */ (function (_super) {
    __extends(PstTfsaValidator, _super);
    function PstTfsaValidator(loggerService) {
        if (loggerService === void 0) { loggerService = null; }
        var _this = _super.call(this, loggerService) || this;
        _this.targetLimit = 500000;
        _this.annualContributionLimit = 33000;
        _this.monthlyContributionLimit = 2750; // (33000 / 12 = 2750);
        _this.termLimit = 3 * 12;
        _this.targetValid = false;
        _this.lumpSumValid = false;
        _this.contributionValid = false;
        _this.taxYearLimitValid = false;
        _this.productAvailable = false;
        _this.date = new Date();
        _this.financialMonthsLeft = [0, 2, 1, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3];
        return _this;
    }
    PstTfsaValidator.prototype.tfsaTermValidate = function (term) {
        return term > this.termLimit;
    };
    PstTfsaValidator.prototype.ngOnDestroy = function () { };
    PstTfsaValidator.prototype.validatePstForTfsa = function (targetAmount, lumpSumAmount, monthlyContributionAmount, term, isDirect) {
        var currentMonth = this.date.getMonth() + 1;
        this.targetValid = this.tfsaTargetValidate(targetAmount);
        this.lumpSumValid = this.tfsaLumpSumCheck(lumpSumAmount);
        this.contributionValid = this.tfsaContributionCheck(monthlyContributionAmount);
        this.taxYearLimitValid = this.tfsaTaxYearLimitCheck(lumpSumAmount, monthlyContributionAmount, currentMonth);
        this.productAvailable = this.productAvailabilityCheck(isDirect);
        if (this.targetValid &&
            this.lumpSumValid &&
            this.contributionValid &&
            this.taxYearLimitValid &&
            this.productAvailable &&
            this.tfsaTermValidate(term)) {
            return true;
        }
        else {
            return false;
        }
    };
    PstTfsaValidator.prototype.tfsaTargetValidate = function (targetAmount) {
        if (targetAmount > this.targetLimit) {
            return false;
        }
        return true;
    };
    PstTfsaValidator.prototype.tfsaLumpSumCheck = function (lumpSumAmount) {
        if (lumpSumAmount > this.annualContributionLimit) {
            return false;
        }
        return true;
    };
    PstTfsaValidator.prototype.tfsaContributionCheck = function (monthlyContributionAmount) {
        if (monthlyContributionAmount > this.monthlyContributionLimit) {
            return false;
        }
        return true;
    };
    PstTfsaValidator.prototype.productAvailabilityCheck = function (isDirect) {
        if (isDirect === true) {
            return false;
        }
        return true;
    };
    PstTfsaValidator.prototype.tfsaTaxYearLimitCheck = function (lumpSumAmount, monthlyContributionAmount, currentMonth) {
        this.taxYearContributionAmount = monthlyContributionAmount * this.financialMonthsLeft[currentMonth];
        this.nextTaxYearContributionAmount = monthlyContributionAmount * 12;
        this.remainingTaxYearContributionAmount = lumpSumAmount + this.taxYearContributionAmount;
        if (this.taxYearContributionAmount > this.annualContributionLimit) {
            return false;
        }
        if (this.nextTaxYearContributionAmount > this.annualContributionLimit) {
            return false;
        }
        if (this.remainingTaxYearContributionAmount > this.annualContributionLimit) {
            return false;
        }
        return true;
    };
    return PstTfsaValidator;
}(BaseService));
export { PstTfsaValidator };
