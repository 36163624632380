import { Chart } from 'angular-highcharts';
import { PstResult } from 'pst/models';

export class InvestmentAccountCalculationResult {
    public calculatedMonthlyContribution: number;
    public originalMonthlyContribution: number;
    public estimatedFutureValue: number;

    public graph: Chart;

    public pstResult: PstResult;

    public bestCase: number;
    public worstCase: number;
}
