import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { PstState } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';

@Component({
    selector: 'wim-onboarding-investment-details',
    templateUrl: 'onboarding-investment-details.component.pug',
    styleUrls: ['onboarding-investment-details.component.scss'],
})
export class OnboardingInvestmentDetailsComponent extends BaseComponent {
    public isAdvised: boolean;
    public insufficientFunds = false;

    constructor(private pstStore: Store<PstState>, loggerService: LoggerService) {
        super(loggerService);

        this.pstStore
            .select(fromPst.selectCompletedPst)
            .pipe(this.scavenger.collect())
            .subscribe(flag => (this.isAdvised = flag.pstAdvised));
    }
    public ngOnDestroy() {}
}
