import { CommonModule } from '@angular/common';
import { ApplicationModule, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { StoreModule } from '@ngrx/store';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { reducers as applicationReducers } from 'application/store/application.reducers';
import { BankerRoutingModule } from 'banker/banker-routing.module';
import { CustomerSearchComponent } from 'banker/components';
import { reducers } from 'banker/store/banker.reducers';
import { PstModule } from 'pst/pst.module';

import { SharedModule } from '../shared/shared.module';

import { CisSearchService } from './services';

const MODULE_COMPONENTS = [CustomerSearchComponent];
const MODULE_SERVICES = [CisSearchService];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        BankerRoutingModule,
        ReactiveFormsModule,
        NgxErrorsModule,
        PrettyJsonModule,
        SharedModule,
        PstModule,
        ApplicationModule,
        StoreModule.forFeature('banker', reducers),
        StoreModule.forFeature('application', applicationReducers),
    ],
    declarations: [...MODULE_COMPONENTS],
    exports: [],
    providers: [...MODULE_SERVICES],
})
export class BankerModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: BankerModule,
            providers: [...MODULE_SERVICES],
        };
    }
}
