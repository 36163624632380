import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { JwtToken } from 'shared/models';
import { Logger, LoggerService } from 'shared/services/logger.service';
import { SharedState } from 'shared/store/shared.store';
import * as fromShared from 'shared/store/shared.store';

const KEY_ACCESS_TOKEN = 'ls.access_token';

@Injectable()
export class TokenStorageService {
    private logger: Logger;

    constructor(loggerService: LoggerService, store: Store<SharedState>) {
        this.logger = loggerService.getLogger('TokenStorageService');
    }

    public getAccessToken() {
        return JSON.parse(localStorage.getItem(KEY_ACCESS_TOKEN));
    }

    public deleteToken() {
        localStorage.removeItem(KEY_ACCESS_TOKEN);
    }
}
