import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

const FORMAT = 'YYYY-MM-DD';

@Injectable()
export class DateFormatterService {
    public forDatePicker(date: moment.Moment): NgbDateStruct {
        if (!date) {
            return null;
        }
        return { year: date.year(), month: date.month() + 1, day: date.date() };
    }

    public forDatePickerFromString(date: string): NgbDateStruct {
        if (!date) {
            return null;
        }
        return this.forDatePicker(moment(date, FORMAT));
    }

    private stringToMoment(dateString: string) {
        let momentDate = moment(dateString, FORMAT);
        if (momentDate.isValid()) {
            return momentDate.startOf('day');
        }
        return null;
    }

    public asMoment(date: NgbDateStruct): moment.Moment {
        if (!date) {
            return null;
        }
        if (typeof date === 'string') {
            return this.stringToMoment(date);
        }
        return moment()
            .utc()
            .year(date.year)
            .month(date.month - 1)
            .date(date.day)
            .startOf('day');
    }

    public toString(date: NgbDateStruct): string {
        if (!date) {
            return null;
        }
        let momentDate = this.asMoment(date);
        return momentDate ? momentDate.format(FORMAT) : null;
    }

    public forApi(date: NgbDateStruct): Date {
        if (!date) {
            return null;
        }
        let asMoment = this.asMoment(date);
        if (asMoment) {
            return asMoment.toDate();
        }
        return null;
    }
}
