import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { bankerRoutes } from 'banker/banker-routing.module';
import { investorRoutes } from 'investor/investor-routing.module';
import { salesRoutes } from 'sales/sales-routing.module';
import { BankerLoginComponent } from 'shared/components';
import { BankerGuard, InvestorGuard, LoggedInAuthGuard } from 'shared/services';

const ROUTES: Routes = [
    { path: '', redirectTo: 'invest', pathMatch: 'full' },
    { path: 'invest', children: salesRoutes },
    { path: 'banker-login', component: BankerLoginComponent },
    {
        path: 'secure',
        canActivate: [LoggedInAuthGuard],
        children: [
            { path: '', redirectTo: 'banker', pathMatch: 'full' },
            { path: 'banker', canActivate: [BankerGuard], children: bankerRoutes },
            { path: 'investor', canActivate: [InvestorGuard], children: investorRoutes },
        ],
    },
    { path: '**', redirectTo: 'invest' },
];

export { ROUTES };

@NgModule({
    imports: [RouterModule.forRoot(ROUTES, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
