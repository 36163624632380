/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./message.component";
import * as i3 from "../../../shared/services/logger.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/pst-parser.service";
import * as i7 from "../../../shared/services/lookup-data.service";
var styles_MessageComponent = [];
var RenderType_MessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageComponent, data: {} });
export { RenderType_MessageComponent as RenderType_MessageComponent };
function View_MessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "button", [["class", "no-border info-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showTooltip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_MessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "col-md-12 no-gutters p-0 fadeInDown animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [["class", "col-md-12 card-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please note:"])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "card rad fadeInDown animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "login-box card px-5 py-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessageComponent_1)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isTooltip(); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 7, 0, currVal_0); }); }
export function View_MessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-pst-message", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i0.ɵdid(1, 245760, null, 0, i2.MessageComponent, [i3.LoggerService, i4.NgbModal, i5.Store, i6.PstParserService, i7.LookupDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageComponentNgFactory = i0.ɵccf("wim-pst-message", i2.MessageComponent, View_MessageComponent_Host_0, {}, {}, []);
export { MessageComponentNgFactory as MessageComponentNgFactory };
