export enum RoboFieldId {
    unknown = 1,
    notApplicable = 2,
    goal = 3,
    goalName = 4,
    targetValue = 5,
    term = 6,
    fundingMethod = 7,
    lumpSumAmount = 8,
    lumpSumDate = 9,
    recurringContributionAmount = 10,
    recurringContributionFrequency = 11,
    recurringContributionStartDate = 12,
    annualEscalationPercentage = 13,
    considerInflation = 14,
    contributingToTaxFree = 15,
    taxFreeContributionForCurrentTaxYear = 16,
    noticePeriod = 17,
    withdrawalAccessType = 18,
    requestAdditionalLumpSums = 19,
    taxFreeOptOut = 39
}
