import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PageScrollService } from 'ngx-page-scroll';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'sales/services/contact.service';
import { BaseComponent } from 'shared/components/base';
import { BankerContactForm, Brand } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { CustomerSearchResult } from '../../../application/models/customer-search-result';
import { ApplicationState } from '../../../application/store';

@Component({
    selector: 'wim-contact-us',
    templateUrl: 'contact-banker-modal.component.pug',
})
export class ContactBankerModalComponent extends BaseComponent {
    public showContactForm = true;
    public contactForm: FormGroup;
    public feedbackTypeList: any[];
    public titleList: any[];
    public noTitleSelected = false;
    public noFeedbackTypeSelected = false;

    private customer: CustomerSearchResult;

    public products = [
        'I am not sure yet',
        'Investment Account',
        'Tax Free Savings Account',
        'Retirement Annuity',
        'Endowment Account',
        'Preservation Account',
        'Living Annuity',
    ];

    public funds = [
        'I am not sure yet',
        'A combination',
        'FNB Multi Manager Income Fund (CPI+1%)',
        'FNB Stable Fund (CPI+2%)',
        'FNB Moderate Fund (CPI+3.5%)',
        'FNB Growth Fund (CPI+5%)',
    ];

    public regions = [
        'Gauteng',
        'Western Cape',
        'Eastern Cape',
        'KwaZulu Natal',
        'Northern Cape',
        'Free State',
        'Limpopo',
        'Mpumalanga',
        'North West',
    ];

    private currentInstance: string;

    constructor(
        loggerService: LoggerService,
        private sharedStore: Store<SharedState>,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private contactService: ContactService,
        private activeModal: NgbActiveModal
    ) {
        super(loggerService);
        this.sharedStore
            .select(fromShared.selectBrand)
            .pipe(this.scavenger.collect())
            .subscribe(brand => this.setBrand(brand));

        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(user => (this.customer = user ? user.customerDetails : null));

        if (!this.contactForm) {
            if (this.customer) {
                this.createCustomerForm(this.customer);
            } else {
                this.createContactForm();
            }
        }
    }

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }

    public submit() {
        this.sharedStore.dispatch(new LoadingAction());
        const contactModel: BankerContactForm = this.contactForm.value;

        contactModel.eligibilityReasons = this.customer.notValidReasons;
        //console.log('TEST' + this.customer.notValidReasons);

        this.contactService.sendBankerContactForm(contactModel).subscribe(
            result => {
                this.sharedStore.dispatch(new StopLoadingAction());
                if (result.success) {
                    this.resetForm();
                    this.toastr.success(
                        // tslint:disable-next-line:max-line-length
                        'Thank you for showing interest. An Investment Consultant will contact you within 48 hours (Monday to Friday).'
                    );
                    this.close();
                } else {
                    this.toastr.error(result.message);
                }
            },
            () => this.toastr.error('Unable to submit form, please try again')
        );
    }

    public close() {
        this.activeModal.close();
    }

    private createContactForm(): any {
        this.contactForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            idNumber: [''],
            region: [null, Validators.required],
            product: [null, Validators.required],
            phoneNumber: ['', Validators.required],
            fund: [null, Validators.required],
            brand: [this.currentInstance],
        });
    }

    private createCustomerForm(customer: CustomerSearchResult) {
        customer.contactNumber = customer.contactNumber || '';
        this.contactForm = this.formBuilder.group({
            fullName: [customer.firstname + ' ' + customer.lastname, Validators.required],
            email: [customer.emailAddress, [Validators.required, Validators.email]],
            idNumber: [customer.idNumber],
            region: [null, Validators.required],
            product: ['Investment Account', Validators.required],
            phoneNumber: [customer.contactNumber.replace(/ /g, ''), Validators.required],
            fund: ['I am not sure yet', Validators.required],
            brand: [this.currentInstance],
        });
    }

    private resetForm() {
        this.contactForm.reset();
    }

    private setBrand(activeBrand) {
        switch (activeBrand) {
            case Brand.RMB: {
                this.currentInstance = 'RMB';
                break;
            }
            default: {
                this.currentInstance = 'FNB';
                break;
            }
        }
    }
}
