var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';
import { InvestorHelperToggleAction, LogoutRequestAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var InvestorHelperComponent = /** @class */ (function (_super) {
    __extends(InvestorHelperComponent, _super);
    function InvestorHelperComponent(store, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.store = store;
        _this.sideBarLogo = require('assets/images/logo/fnb-wim-logo.svg');
        _this.links = [
            {
                text: 'I want to Invest',
                route: ['/invest'],
                params: { showFunds: true },
            },
            {
                text: 'About Us',
                route: ['/invest/about-us'],
                params: {},
            },
            {
                text: 'Get in touch',
                route: ['/invest/contact-us'],
                params: {},
            },
        ];
        _this.investorLinks = [
            {
                route: '/secure/investor/investor-home',
                text: 'Accounts',
                params: {},
            },
            {
                route: '/secure/investor/investor-instructions',
                text: 'Instructions',
                params: {},
            },
            {
                route: '/secure/investor/documents',
                text: 'Documents',
                params: {},
            },
            {
                route: '/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW',
                text: 'Apply Now',
                params: {},
            },
        ];
        return _this;
    }
    InvestorHelperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticated = this.store.select(fromShared.selectAuthenticated);
        this.store
            .select(fromShared.selectInvestorHelper)
            .pipe(this.scavenger.collect())
            .subscribe(function (canShow) { return (_this.helperVisible = canShow ? canShow.isVisible : false); });
    };
    InvestorHelperComponent.prototype.ngOnDestroy = function () { };
    InvestorHelperComponent.prototype.toggleClose = function () {
        this.store.dispatch(new InvestorHelperToggleAction());
    };
    InvestorHelperComponent.prototype.logout = function () {
        this.store.dispatch(new LogoutRequestAction());
    };
    return InvestorHelperComponent;
}(BaseComponent));
export { InvestorHelperComponent };
