import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CustomerSearchResult, ProductApplication } from 'application/models';
import { ApplicationState, CustomerSelectedAction } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { BankerState } from 'banker/store/banker.store';
import { ToastrService } from 'ngx-toastr';
import { PstReference, PstResult, SavedPstResult, UserSelection } from 'pst/models';
import { PstService } from 'pst/services';
import { PstCompletedAction, ResetPstStateAction } from 'pst/store';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BaseComponent, SharedModalComponent } from 'shared/components';
import { User } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LayoutEffects, LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

// import { DisclaimerModalComponent } from '../disclaimer-modal/disclaimer-modal.component';

const ROUTE_INVEST_NOW = '/secure/{user}/application';
const ROUTE_PST = '/secure/{user}/pst';
const ROUTE_SEARCH = '/secure/{user}/customer-search';
const ROUTE_HOME = '/secure/{user}';

@Component({
    selector: 'wim-pst-navigation',
    templateUrl: 'pst-navigation.component.pug',
})
export class PstNavigationComponent extends BaseComponent implements OnInit, OnDestroy {
    public error: Error;
    public onResult$: Observable<boolean>;
    public pstResult: PstResult;
    public userSelection: UserSelection;
    public isDirect = false;
    private tree: PstReference;
    private user: User;
    private customer: CustomerSearchResult;

    private currentApplication: ProductApplication;
    private OK_TO_CANCEL = 'Yes Cancel';
    private warningRef;

    constructor(
        private pstService: PstService,
        private pstStore: Store<PstState>,
        private bankerStore: Store<BankerState>,
        private sharedStore: Store<SharedState>,
        private applicationStore: Store<ApplicationState>,
        private router: Router,
        private modalService: NgbModal,
        loggerService: LoggerService,
        protected toastr: ToastrService,
        private layoutEffects: LayoutEffects
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.pstStore
            .select(fromPst.selectPstTree)
            .pipe(this.scavenger.collect())
            .subscribe(tree => (this.tree = tree));

        this.pstStore
            .select(fromPst.selectPstError)
            .pipe(this.scavenger.collect())
            .subscribe(error => (this.error = error));

        this.pstStore
            .select(fromPst.selectUserPstSelection)
            .pipe(this.scavenger.collect())
            .subscribe(selection => (this.userSelection = selection));

        this.applicationStore
            .select(fromApplication.selectHorizonApplication)
            .pipe(this.scavenger.collect())
            .subscribe(app => (this.currentApplication = app));

        this.pstStore
            .select(fromPst.selectCompletedPst)
            .pipe(this.scavenger.collect())
            .subscribe(pstResult => (this.pstResult = pstResult));

        this.sharedStore
            .select(fromShared.selectIsDirect)
            .pipe(this.scavenger.collect())
            .subscribe(isDirect => (this.isDirect = isDirect));

        this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .pipe(this.scavenger.collect())
            .subscribe(customer => (this.customer = customer));

        // Disabled for now
        // this.pstStore
        //     .select(fromPst.selectEnterPressed)
        //     .pipe(this.scavenger.collect())
        //     .subscribe(pressed => {
        //         if (pressed) {
        //             this.next();
        //         }
        //     });

        this.onResult$ = this.pstStore.select(fromPst.selectCompletedPst).pipe(map(result => !!result));
    }

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }

    public next() {
        if (this.tree) {
            this.pstService.traverseTreeForwards(
                this.tree.transactionref,
                this.tree.node,
                this.userSelection.selectedNode,
                this.userSelection.value
            );
        } else {
            this.restart();
        }
    }

    public async startInvesting() {
        // this.pstResult.pstAdvised = true;
        // modal.result
        //     .then(() => {
        //         this.pstStore.dispatch(new PstCompletedAction(this.pstResult));
        //         this.routeToUserSpecificRoute(ROUTE_INVEST_NOW);
        //     })
        //     .catch(() => {});
        // modal.componentInstance.portfolioTypeId = this.pstResult.portfolioTypeId;
    }

    public restart() {
        this.pstService.startNewTree();
        this.routeToUserSpecificRoute(ROUTE_PST);
    }

    public cancel() {
        this.warningRef = this.modalService.open(SharedModalComponent);
        this.warningRef.componentInstance.modalMessage = `Please note product selection results will not be saved ,
        and should you wish to revisit this application you will be required to restart the advisory process`;

        this.warningRef.componentInstance.noValue = 'No';
        this.warningRef.componentInstance.yesValue = 'Yes, Cancel Process';
        this.warningRef.componentInstance.modalTitle = 'Confirm  Cancellation';
        this.warningRef.result.then(resultType => {
            if (resultType === this.OK_TO_CANCEL) {
                this.cancelConfirmed();
            }
        });
    }

    public save() {
        const savedPstResult = {
            customerUcn: this.customer.ucn,
            pstReference: this.pstResult.reference,
            goalName: this.pstResult.goalName,
            goalAmount: this.pstResult.goalAmount,
            debitOrder: this.pstResult.clientInstallment,
            lumpSum: this.pstResult.lumpSum,
            annualEscalation: this.pstResult.contributionIncrease,
            termMonths: this.pstResult.term,
            fundCode: this.pstResult.fundCode,
            portfolioTypeId: this.pstResult.portfolioTypeId,
        } as SavedPstResult;
        this.sharedStore.dispatch(new LoadingAction());
        this.pstService
            .saveResult(savedPstResult)
            .pipe(
                finalize(() => {
                    this.sharedStore.dispatch(new StopLoadingAction());
                }),
                catchError(error => {
                    this.logger.error(error);
                    this.toastr.error('The robo-advisor result could not be saved, please try again later.');
                    return of();
                })
            )
            .subscribe(() => {
                this.toastr.success(
                    'The robo-advisor result was successfully saved. It can be retrieved for the next 5 days when searching for a customer'
                );
                this.clearPst();
                this.routeToUserSpecificRoute(ROUTE_SEARCH);
            });
    }

    public email() {}

    private cancelConfirmed() {
        this.warningRef.dismiss();
        this.clearPst();
        if (this.isDirect) {
            this.routeToUserSpecificRoute(ROUTE_HOME);
        } else {
            this.routeToUserSpecificRoute(ROUTE_SEARCH);
        }
    }

    private clearPst() {
        this.bankerStore.dispatch(new CustomerSelectedAction(null));
        this.pstStore.dispatch(new ResetPstStateAction());
    }

    private routeToUserSpecificRoute(route: string) {
        let userType = this.isDirect ? 'investor' : 'banker';
        let routeUrl = route.replace('{user}', userType);
        this.router.navigateByUrl(routeUrl);
    }
}
