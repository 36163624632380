class DebitDate {
    constructor(public value: number, public label: string, public limitedToProducts: number[] = []) {}

    public forProduct(portfolioTypeId) {
        return this.limitedToProducts.length === 0 || this.limitedToProducts.filter(p => p === portfolioTypeId).length > 0;
    }
}

export const PossibleDebitDates: DebitDate[] = [
    new DebitDate(1, '1st'),
    new DebitDate(2, '2nd'),
    new DebitDate(3, '3rd'),
    new DebitDate(4, '4th'),
    new DebitDate(5, '5th'),
    new DebitDate(6, '6th'),
    new DebitDate(7, '7th'),
    new DebitDate(8, '8th'),
    new DebitDate(9, '9th'),
    new DebitDate(10, '10th'),
    new DebitDate(11, '11th'),
    new DebitDate(12, '12th'),
    new DebitDate(13, '13th'),
    new DebitDate(14, '14th'),
    new DebitDate(15, '15th'),
    new DebitDate(16, '16th'),
    new DebitDate(17, '17th'),
    new DebitDate(18, '18th'),
    new DebitDate(19, '19th'),
    new DebitDate(20, '20th'),
    new DebitDate(21, '21st'),
    new DebitDate(22, '22nd'),
    new DebitDate(23, '23rd'),
    new DebitDate(24, '24th'),
    new DebitDate(25, '25th'),
    new DebitDate(26, '26th'),
    new DebitDate(27, '27th', [3, 25, 26, 27, 28, 29, 30, 31, 32]),
    new DebitDate(28, '28th', [3, 25, 26, 27, 28, 29, 30, 31, 32]),
    new DebitDate(29, '29th', [3, 25, 26, 27, 28, 29, 30, 31, 32]),
    new DebitDate(30, '30th', [3, 25, 26, 27, 28, 29, 30, 31, 32]),
    new DebitDate(31, '31st', [3, 25, 26, 27, 28, 29, 30, 31, 32]),
];
