export class QuestionBase<T> {
    public controlFormName: string;
    public value: T;
    public key: string;
    public label: string;
    public required: boolean;
    public order: number;
    public controlType: string;
    public content: string;
    public id: string;
    public type: boolean;
    public fileUrl: string;
    public showDebitOrderDetails: boolean;
    public tickBoxContent: string;
    public showTickBox: boolean;
    constructor(
        options: {
            value?: T;
            key?: string;
            label?: string;
            required?: boolean;
            order?: number;
            controlType?: string;
            content?: string;
            id?: string;
            controlFormName?: string;
            type?: boolean;
            fileUrl?: string;
            showDebitOrderDetails?: boolean;
            tickBoxContent?: string;
            showTickBox?: boolean;
        } = {}
    ) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.content = options.content || '';
        this.controlFormName = options.controlFormName || '';
        this.id = options.id || '';
        this.type = options.type;
        this.fileUrl = options.fileUrl;
        this.showDebitOrderDetails = !!options.showDebitOrderDetails;
        this.tickBoxContent = options.tickBoxContent || '';
        this.showTickBox = !!options.showTickBox;
    }
}
