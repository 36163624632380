import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Injectable()
export class BankerGuard implements CanActivate {
    private isBanker: boolean;

    constructor(private store: Store<SharedState>, private router: Router) {
        this.store.select(fromShared.selectUser).subscribe(user => (this.isBanker = user && user.isBanker));
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.isBanker) {
            return true;
        } else {
            // Send to default investor page
            this.router.navigate(['secure', 'investor']);
        }
    }
}
