export const chartOptions = {
    credits: {
        enabled: false,
    },
    chart: {
        type: 'spline',
    },
    title: {
        text: '',
    },
    subtitle: {
        text: '',
    },
    xAxis: {
        title: {
            text: 'Months',
        },
        labels: {
            overflow: 'justify',
        },
        gridLineWidth: 1,
    },
    yAxis: {
        title: {
            text: 'Value (R)',
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
    },

    plotOptions: {
        spline: {
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 4,
                },
            },
            marker: {
                enabled: false,
            },
        },
        series: {
            tooltip: {
                headerFormat: '',
                pointFormat: `<span style="font-weight:bold">{series.name}</span><br/>Month {point.x}<br/>R {point.y}`,
            },
            animation: false,
        },
    },

    navigation: {
        menuItemStyle: {
            fontSize: '10px',
        },
    },
    legend: {
        enabled: false,
    },
};
