var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Chart } from 'angular-highcharts';
import { LoggerService } from 'shared/services/logger.service';
import { BaseService } from 'shared/components';
import { GraphLines, InvestmentAccountCalculationResult, PstResultType, } from 'pst/services/ia-calculator/models';
import { funds } from 'pst/services/ia-calculator/scenarios';
var minDebitOrder = 500;
var InvestmentAccountCalculator = /** @class */ (function (_super) {
    __extends(InvestmentAccountCalculator, _super);
    function InvestmentAccountCalculator(loggerService) {
        if (loggerService === void 0) { loggerService = null; }
        return _super.call(this, loggerService) || this;
    }
    InvestmentAccountCalculator.extractCapturedValues = function (pstResult) {
        pstResult.goalName = pstResult.capturedValues['GOAL_NAME'];
        pstResult.term = +pstResult.capturedValues['GOAL_MONTHS'] || 0;
        pstResult.goalAmount = +pstResult.capturedValues['GOAL_AMOUNT'] || 0;
        pstResult.clientInstallment = +pstResult.capturedValues['DEBIT_AMOUNT'] || 0;
        pstResult.contributionIncrease = (+pstResult.capturedValues['YEARLY_ESCALATION'] || 0) / 100.0;
        pstResult.lumpSum = +pstResult.capturedValues['LUMP_SUM'] || 0;
        // PST reducer needs these to use the mock properly
        pstResult.accessType = pstResult.capturedValues['ACCESS_TYPE'];
        pstResult.noticePeriod = pstResult.capturedValues['NOTICE_PERIOD'];
        // -<
    };
    InvestmentAccountCalculator.injectCapturedValues = function (pstResult, save) {
        pstResult.capturedValues['GOAL_NAME'] = save.goalName;
        pstResult.capturedValues['GOAL_MONTHS'] = save.termMonths;
        pstResult.capturedValues['GOAL_AMOUNT'] = save.goalAmount;
        pstResult.capturedValues['DEBIT_AMOUNT'] = save.debitOrder;
        pstResult.capturedValues['YEARLY_ESCALATION'] = save.annualEscalation * 100;
        pstResult.capturedValues['LUMP_SUM'] = save.lumpSum;
        pstResult.portfolioTypeId = save.portfolioTypeId;
        // PST reducer needs these to use the mock properly
        pstResult.capturedValues['ACCESS_TYPE'] = save.accessType;
        pstResult.capturedValues['NOTICE_PERIOD'] = save.noticePeriod;
        // -<
    };
    InvestmentAccountCalculator.prototype.ngOnDestroy = function () { };
    InvestmentAccountCalculator.prototype.calculate = function (pstResult, annualCosts, resultType) {
        this.logger.debug('PST Result', pstResult);
        var result = new InvestmentAccountCalculationResult();
        var graphData = null;
        var term = pstResult.term / 12;
        result.originalMonthlyContribution = pstResult.clientInstallment;
        result.calculatedMonthlyContribution = this.calculateInstallment({
            annualIncrease: pstResult.contributionIncrease,
            fundCode: pstResult.fundCode,
            goalAmount: pstResult.goalAmount,
            lumpsum: pstResult.lumpSum || 0,
            selectedTerm: term,
            annualCosts: annualCosts,
        });
        result.calculatedMonthlyContribution = Math.ceil(result.calculatedMonthlyContribution);
        pstResult.advisedInstallment = result.calculatedMonthlyContribution;
        var graphOptions = {
            annualIncrease: pstResult.contributionIncrease,
            fundCode: pstResult.fundCode,
            goalAmount: pstResult.goalAmount,
            lumpsum: pstResult.lumpSum,
            term: term,
            annualCosts: annualCosts,
            clientInstallment: pstResult.clientInstallment,
            advisedInstallment: resultType === PstResultType.Installment ? result.calculatedMonthlyContribution : pstResult.clientInstallment,
        };
        graphData = this.calculateGraphData(graphOptions);
        result.bestCase = graphData.up[graphData.up.length - 1];
        result.worstCase = graphData.down[graphData.down.length - 1];
        result.estimatedFutureValue = this.calculateJustInvestFromGraph(graphData);
        pstResult.futureValue = result.estimatedFutureValue;
        result.graph = this.createChart(graphData, resultType, result.originalMonthlyContribution, result.calculatedMonthlyContribution);
        result.pstResult = pstResult;
        return result;
    };
    InvestmentAccountCalculator.prototype.createChart = function (graphData, pstResultType, clientDebitOrder, calculatedDebitOrder) {
        var chartConfig = {
            credits: {
                enabled: false,
            },
            chart: {
                type: 'spline',
            },
            title: {
                text: '',
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                title: {
                    text: 'Years',
                },
                labels: {
                    overflow: 'justify',
                },
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: 'Value (R)',
                },
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                alternateGridColor: null,
            },
            plotOptions: {
                spline: {
                    lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 4,
                        },
                    },
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    tooltip: {
                        headerFormat: '',
                        pointFormat: "<span style=\"font-weight:bold\">{series.name}</span><br/>Year {point.x}<br/>R {point.y}",
                    },
                },
            },
            navigation: {
                menuItemStyle: {
                    fontSize: '10px',
                },
            },
            legend: {
                enabled: false,
            },
        };
        var series;
        var downScenario = {
            name: 'Worst Case Scenario',
            data: graphData.down,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        var upScenario = {
            name: 'Best Case Scenario',
            data: graphData.up,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        var clientScenario = {
            name: 'Your Contribution',
            data: graphData.clientScenario,
            color: '#01aaad',
        };
        if (pstResultType === PstResultType.Installment) {
            series = [
                {
                    name: 'Goal Amount',
                    data: graphData.goal,
                    color: '#007072',
                    dashStyle: 'longdash',
                },
                downScenario,
                {
                    name: 'Recommended Contribution',
                    data: graphData.expected,
                    color: '#ff9900',
                },
                clientScenario,
                upScenario,
            ];
        }
        else {
            series = [downScenario, clientScenario, upScenario];
        }
        chartConfig.series = series;
        return new Chart(chartConfig);
    };
    InvestmentAccountCalculator.prototype.calculateInstallment = function (options) {
        var fund = this.lookupFund(options.fundCode);
        this.logger.debug("Using fund with code " + options.fundCode);
        var scenario = fund.scenarios[options.selectedTerm];
        var realisedReturn = this.calculateRealisedAnnualReturn(scenario, options.annualCosts);
        var newGoal = this.calculateGoalLessLumpsum(options.goalAmount, options.lumpsum, realisedReturn, options.selectedTerm);
        this.logger.debug("New goal after taking lumpsum future value into account is R " + newGoal);
        var yearlyRecurring = this.calculateFirstYearRecurring(newGoal, options.selectedTerm, scenario, options.annualIncrease, options.annualCosts);
        var installment = this.round(this.calculateMonthlyRecurring(yearlyRecurring, this.calculateInterestRate(scenario, options.annualCosts)));
        // Allow for some leeway to prevent a R500 installment when the rounding errors create a needed installment of a few cents
        if (installment > 5) {
            return installment < minDebitOrder ? minDebitOrder : installment;
        }
        else {
            return 0;
        }
    };
    InvestmentAccountCalculator.prototype.calculateJustInvest = function (options) {
        var graphData = this.calculateGraphData({
            fundCode: options.fundCode,
            advisedInstallment: options.installment,
            clientInstallment: options.installment,
            lumpsum: options.lumpsum,
            term: options.term,
            goalAmount: 0,
            annualIncrease: options.annualIncrease,
            annualCosts: options.annualCosts,
        });
        var value = graphData.expected[graphData.expected.length - 1];
        return this.round(value / 100, 0) * 100;
    };
    InvestmentAccountCalculator.prototype.calculateJustInvestFromGraph = function (graphData) {
        this.logger.debug('Taking last point in exected scenario graph line for Future Value');
        return graphData.clientScenario[graphData.expected.length - 1];
    };
    InvestmentAccountCalculator.prototype.calculateGraphData = function (options) {
        this.logger.debug('Calculating graph');
        var result = new GraphLines();
        var fund = this.lookupFund(options.fundCode);
        var previousMonth;
        var calculatedDebitOrder = options.advisedInstallment;
        var originalDebitOrder = options.clientInstallment;
        for (var month = 0; month < options.term * 12; month++) {
            if (month === 0) {
                result.down.push(options.lumpsum + options.advisedInstallment);
                result.up.push(options.lumpsum + options.advisedInstallment);
                result.expected.push(options.lumpsum + options.advisedInstallment);
                result.clientScenario.push(options.lumpsum + options.clientInstallment);
                result.goal.push(options.goalAmount);
            }
            var prediction = this.calcMonth(month, options.lumpsum, calculatedDebitOrder, originalDebitOrder, fund.scenarios[options.term], previousMonth, options.annualCosts);
            previousMonth = prediction;
            if ((month + 1) % 12 === 0) {
                calculatedDebitOrder = this.increaseByPercent(calculatedDebitOrder, options.annualIncrease);
                originalDebitOrder = this.increaseByPercent(originalDebitOrder, options.annualIncrease);
                result.down.push(prediction.down);
                result.expected.push(prediction.expected);
                result.clientScenario.push(prediction.client);
                result.up.push(prediction.up);
                result.goal.push(options.goalAmount);
            }
        }
        return result;
    };
    InvestmentAccountCalculator.prototype.increaseByPercent = function (amount, percent) {
        return amount + amount * percent;
    };
    InvestmentAccountCalculator.prototype.calcMonth = function (month, lumpsum, calculatedDebitOrder, originalDebitOrder, scenario, previousMonth, annualCosts) {
        var beginning;
        if (month === 0) {
            beginning = {
                down: lumpsum + calculatedDebitOrder,
                expected: lumpsum + calculatedDebitOrder,
                client: lumpsum + originalDebitOrder,
                up: lumpsum + calculatedDebitOrder,
            };
        }
        else {
            beginning = {
                down: previousMonth.down + calculatedDebitOrder,
                expected: previousMonth.expected + calculatedDebitOrder,
                client: previousMonth.client + originalDebitOrder,
                up: previousMonth.up + calculatedDebitOrder,
            };
        }
        var monthlyCosts = {
            down: (annualCosts * beginning.down) / 12,
            expected: (annualCosts * beginning.expected) / 12,
            client: (annualCosts * beginning.client) / 12,
            up: (annualCosts * beginning.up) / 12,
        };
        var investmentReturn = {
            down: this.round(beginning.down * scenario.down),
            expected: this.round(beginning.expected * scenario.expected),
            client: this.round(beginning.client * scenario.expected),
            up: this.round(beginning.up * scenario.up),
        };
        var result = {
            down: this.round(beginning.down - monthlyCosts.down + investmentReturn.down, 0),
            expected: this.round(beginning.expected - monthlyCosts.expected + investmentReturn.expected, 0),
            client: this.round(beginning.client - monthlyCosts.client + investmentReturn.client, 0),
            up: this.round(beginning.up - monthlyCosts.up + investmentReturn.up, 0),
        };
        return result;
    };
    InvestmentAccountCalculator.prototype.calculateGoalLessLumpsum = function (goal, lumpsum, realisedAnnualReturn, term) {
        if (lumpsum === 0) {
            return goal;
        }
        return goal - lumpsum * Math.pow(1 + realisedAnnualReturn, term);
    };
    InvestmentAccountCalculator.prototype.calculatePresentValue = function (amount, termYears, rate) {
        return this.round(amount / Math.pow(1 + rate / 12, termYears * 12));
    };
    InvestmentAccountCalculator.prototype.calculateRealisedAnnualReturn = function (scenario, annualCosts) {
        return Math.pow(1 + (scenario.expected - annualCosts / 12), 12) - 1;
    };
    InvestmentAccountCalculator.prototype.calculateInterestRate = function (scenario, annualCosts) {
        return (Math.pow(1 + this.calculateRealisedAnnualReturn(scenario, annualCosts), 1 / 12) - 1) * 12;
    };
    InvestmentAccountCalculator.prototype.calculateEffectiveRate = function (interestRate) {
        return Math.pow(1 + interestRate / 12, 12) - 1;
    };
    InvestmentAccountCalculator.prototype.calculateRecurringRate = function (effectiveRate, annualIncrease) {
        return ((effectiveRate - annualIncrease) * 100) / (1 + annualIncrease) / 100;
    };
    InvestmentAccountCalculator.prototype.calculateFirstYearRecurring = function (goal, term, scenario, annualIncrease, annualCosts) {
        var interest = this.calculateInterestRate(scenario, annualCosts);
        this.logger.debug("Interest Rate", interest);
        var presentValue = this.calculatePresentValue(goal, term, interest);
        this.logger.debug("PV", presentValue);
        var effectiveRate = this.calculateEffectiveRate(interest);
        this.logger.debug("Effective Rate", effectiveRate);
        var recurringRate = this.calculateRecurringRate(effectiveRate, annualIncrease);
        this.logger.debug("Recurring Rate", recurringRate);
        var value = presentValue * ((recurringRate / (1 - Math.pow(1 + recurringRate, -term))) * (1 / (1 + recurringRate)));
        var firstYearRecurring = this.round(value);
        this.logger.debug('First Year Recurring', firstYearRecurring);
        return firstYearRecurring;
    };
    InvestmentAccountCalculator.prototype.calculateMonthlyRecurring = function (yearlyRecurring, interestRate) {
        var monthlyInterest = interestRate / 12;
        var value = yearlyRecurring * ((monthlyInterest / (1 - Math.pow(1 + monthlyInterest, -12))) * (1 / (1 + monthlyInterest)));
        var monthlyRecurring = this.round(value);
        this.logger.debug("Monthly recurring: " + monthlyRecurring);
        return monthlyRecurring;
    };
    InvestmentAccountCalculator.prototype.round = function (value, decimals) {
        if (decimals === void 0) { decimals = 2; }
        var roundTo = Math.pow(10, decimals);
        return Math.round(value * roundTo) / roundTo;
    };
    InvestmentAccountCalculator.prototype.lookupFund = function (code) {
        return funds[code];
    };
    return InvestmentAccountCalculator;
}(BaseService));
export { InvestmentAccountCalculator };
