/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../directives/insert-step.directive";
import * as i3 from "../navigation/pst-navigation.component.ngfactory";
import * as i4 from "../navigation/pst-navigation.component";
import * as i5 from "../../services/pst.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/router";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../shared/services/logger.service";
import * as i10 from "ngx-toastr";
import * as i11 from "../../../shared/store/layout.effects";
import * as i12 from "./pst-wizard.component";
import * as i13 from "../../../shared/services/lookup-data.service";
var styles_PstWizardComponent = [];
var RenderType_PstWizardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstWizardComponent, data: {} });
export { RenderType_PstWizardComponent as RenderType_PstWizardComponent };
function View_PstWizardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sorry, the Robo-Advisor is not currently available"]))], null, null); }
function View_PstWizardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_PstWizardComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { host: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "v-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PstWizardComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstWizardComponent_2)), i0.ɵdid(6, 16384, [[1, 4]], 0, i2.InsertStepDirective, [i0.ViewContainerRef], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "wim-pst-navigation", [], null, null, null, i3.View_PstNavigationComponent_0, i3.RenderType_PstNavigationComponent)), i0.ɵdid(8, 245760, null, 0, i4.PstNavigationComponent, [i5.PstService, i6.Store, i6.Store, i6.Store, i6.Store, i7.Router, i8.NgbModal, i9.LoggerService, i10.ToastrService, i11.LayoutEffects], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.error$)); _ck(_v, 3, 0, currVal_0); _ck(_v, 8, 0); }, null); }
export function View_PstWizardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-pst-wizard", [], null, null, null, View_PstWizardComponent_0, RenderType_PstWizardComponent)), i0.ɵdid(1, 1294336, null, 0, i12.PstWizardComponent, [i0.ComponentFactoryResolver, i5.PstService, i6.Store, i6.Store, i6.Store, i7.Router, i7.ActivatedRoute, i13.LookupDataService, i9.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PstWizardComponentNgFactory = i0.ɵccf("wim-pst-wizard", i12.PstWizardComponent, View_PstWizardComponent_Host_0, {}, {}, []);
export { PstWizardComponentNgFactory as PstWizardComponentNgFactory };
