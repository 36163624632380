import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PstResult, SavedPstResult } from 'pst/models';

import * as moment from 'moment';
import { CashProjectionRequest } from 'pst/services/cash-calculator/models';
import { CashCalculationResult } from 'pst/services/cash-calculator/models/cash-calculation-result';
import { CashGraphLines } from 'pst/services/cash-calculator/models/cash-graph-lines';
import { CashGraphOptions } from 'pst/services/cash-calculator/models/cash-graph-options';
import { CashProjectionService } from 'pst/services/cash-projection.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'shared/components';
import { LoggerService } from 'shared/services/logger.service';

@Injectable()
export class CashCalculator extends BaseService {
    public estimatedFutureValue: number;

    public static extractCapturedValues(pstResult: PstResult) {
        pstResult.goalName = pstResult.capturedValues['GOAL_NAME'];
        pstResult.term = +pstResult.capturedValues['GOAL_MONTHS'] || 0;
        pstResult.goalAmount = +pstResult.capturedValues['GOAL_AMOUNT'] || 0;
        pstResult.clientInstallment = +pstResult.capturedValues['DEBIT_AMOUNT'] || 0;
        pstResult.contributionIncrease = (+pstResult.capturedValues['YEARLY_ESCALATION'] || 0) / 100.0;
        pstResult.lumpSum = +pstResult.capturedValues['LUMP_SUM'] || 0;
        pstResult.accessType = pstResult.capturedValues['ACCESS_TYPE'];
        pstResult.noticePeriod = pstResult.capturedValues['NOTICE_PERIOD'];
    }

    public static injectCapturedValues(pstResult: PstResult, save: SavedPstResult) {
        pstResult.capturedValues['GOAL_NAME'] = save.goalName;
        pstResult.capturedValues['GOAL_MONTHS'] = save.termMonths;
        pstResult.capturedValues['GOAL_AMOUNT'] = save.goalAmount;
        pstResult.capturedValues['DEBIT_AMOUNT'] = save.debitOrder;
        pstResult.capturedValues['YEARLY_ESCALATION'] = save.annualEscalation * 100;
        pstResult.capturedValues['LUMP_SUM'] = save.lumpSum;
        pstResult.capturedValues['ACCESS_TYPE'] = save.accessType;
        pstResult.capturedValues['NOTICE_PERIOD'] = save.noticePeriod;
    }

    constructor(loggerService: LoggerService, private cashProjectionService: CashProjectionService) {
        super(loggerService);
    }

    public ngOnDestroy() {}

    public calculate(
        pstResult: PstResult,
        adviceFee: number,
        investorAge: number,
        investorUcn: string,
        startDate: Date = null
    ): Observable<CashCalculationResult> {
        this.logger.debug('Cash PST Result', pstResult || '');

        if (!startDate) {
            startDate = moment()
                .startOf('day')
                .toDate();
        }

        let lumpsumLessFee = pstResult.lumpSum - pstResult.lumpSum * adviceFee;

        let endDate = moment(startDate)
            .add(pstResult.term, 'months')
            .toDate();

        let request: CashProjectionRequest = {
            ucn: investorUcn,
            startDate,
            endDate,
            lumpSum: lumpsumLessFee,
            scheduledPayment: pstResult.clientInstallment,
            requiredAmount: pstResult.goalAmount,
            age: investorAge,
            portfolioTypeId: pstResult.portfolioTypeId,
        };

        return this.cashProjectionService.getProjection(request).pipe(
            map(projection => {
                const result = new CashCalculationResult();
                result.effectiveRate = projection.effectiveRate;
                result.nominalRate = projection.nominalRate;
                result.estimatedFutureValue = projection.projectedTotal;
                pstResult.futureValue = result.estimatedFutureValue;

                let graphData = this.calculateGraphData({
                    startDate,
                    effectiveRate: projection.effectiveRate,
                    futureValue: projection.projectedTotal,
                    goalAmount: pstResult.goalAmount,
                    lumpsum: pstResult.lumpSum,
                    nominalRate: projection.nominalRate,
                    recurring: pstResult.clientInstallment,
                    termMonths: pstResult.term,
                });

                // To account for any drift in our calculations, we always end up with the value from the business function
                graphData.clientScenario[graphData.clientScenario.length - 1] = projection.projectedTotal;

                result.graph = this.createChart(graphData);
                pstResult.projectedInterestRate = projection.nominalRate;
                result.pstResult = pstResult;
                return result;
            })
        );
    }

    public createChart(graphData: CashGraphLines): Chart {
        const chartConfig: Highcharts.Options = {
            credits: {
                enabled: false,
            },
            chart: {
                type: 'spline',
            },
            title: {
                text: 'Projected Performance',
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                title: {
                    text: 'Months',
                },
                labels: {
                    overflow: 'justify',
                },
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: 'Value (R)',
                },
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                alternateGridColor: null,
            },

            plotOptions: {
                spline: {
                    lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 4,
                        },
                    },
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    tooltip: {
                        headerFormat: '',
                        pointFormat: `<span style="font-weight:bold">{series.name}</span><br/>Month {point.x}<br/>R {point.y}`,
                    },
                },
            },

            navigation: {
                menuItemStyle: {
                    fontSize: '10px',
                },
            },
            legend: {
                enabled: false,
            },
        };

        let series = [
            {
                name: 'Goal Amount',
                data: graphData.goal,
                color: '#007072',
                dashStyle: 'longdash',
            },
            {
                name: 'Investment Balance',
                data: graphData.clientScenario,
                color: '#01aaad',
            },
        ];

        chartConfig.series = series;
        return new Chart(chartConfig);
    }

    public getEndDate(startDate: Date, termMonths: number): Date {
        return moment(startDate)
            .add(termMonths, 'months')
            .toDate();
    }

    public getDaysToPayInterestFor(start: Date, termIndex: number): any {
        let startOfPeriod = moment(start).add(termIndex - 1, 'months');
        let endOfPeriod = moment(start).add(termIndex, 'months');

        return moment(endOfPeriod).diff(moment(startOfPeriod), 'days');
    }

    public calculateGraphData(options: CashGraphOptions): CashGraphLines {
        this.logger.debug('Calculating graph');
        const result = new CashGraphLines();

        let previousMonth: number;

        for (let month = 0; month <= options.termMonths; month++) {
            if (month === 0) {
                result.clientScenario.push(options.lumpsum);
                if (options.goalAmount > 0) {
                    result.goal.push(options.goalAmount);
                }
                previousMonth = options.lumpsum;
                continue;
            }
            const interest = this.calculateInterest(options.startDate, month, previousMonth, options.nominalRate);
            const newBalance = previousMonth + interest + (month < options.termMonths ? options.recurring : 0);
            previousMonth = newBalance;
            result.clientScenario.push(this.round(newBalance));
            if (options.goalAmount > 0) {
                result.goal.push(options.goalAmount);
            }
        }
        return result;
    }

    public calculateInterest(startDate: Date, month: number, balanceAtBeginningOfMonth: number, interestRate: number): number {
        const daysOfInterest = this.getDaysToPayInterestFor(startDate, month);
        return (daysOfInterest / 365) * interestRate * balanceAtBeginningOfMonth;
    }

    private round(value: number, decimals = 2): number {
        const roundTo = Math.pow(10, decimals);
        return Math.round(value * roundTo) / roundTo;
    }
}
