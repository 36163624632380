import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components/base';
import { EacAccount, EacAccountFund, EacItem, EacPerFund, EacSvcItem } from 'shared/components/eac-modal/effective-annual-cost/models';
import { FeatureFlags, Fund, PortfolioInstrumentFee } from 'shared/models';
import { EACRequest } from 'shared/models/eac/eac-request';
import { EACResponse } from 'shared/models/eac/eac-response';
import { EffectiveAnnualCostService } from 'shared/services/eac.service';
import { FeatureFlagService } from 'shared/services/feature-flag.service';
import { LoggerService } from 'shared/services/logger.service';
import { LookupDataService } from 'shared/services/lookup-data.service';

@Component({
    selector: 'wim-eac-calculation',
    templateUrl: 'eac-calculation.component.pug',
    styleUrls: ['eac-calculation.component.scss'],
})
export class EffectiveAnnualCostCalucationComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {
    public items: EacItem[] = [];

    @Input() public account: EacAccount;

    private funds: Fund[] = null;
    private fees: PortfolioInstrumentFee[] = [];
    private vatRate = 1;

    private investFundCodes = [];
    private appianInstrumentIds = [];

    private eacDisplayedYears = [1, 3, 5, 10];

    constructor(
        private lookupService: LookupDataService,
        loggerService: LoggerService,
        private decimalPipe: DecimalPipe,
        private eacService: EffectiveAnnualCostService,
        private featureFlagService: FeatureFlagService
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        const subscription = combineLatest(this.lookupService.getHorizonFunds(), this.lookupService.getPortfolioInstrumentFees(), this.lookupService.getVATRate(), (funds, fees, vat) => {
            return { funds, fees, vat };
        }).subscribe(({ funds, fees, vat }) => {
            this.funds = funds;
            this.fees = fees;
            this.vatRate = vat;
            if (this.featureFlagService.isEnabled(FeatureFlags.EacService)) {
                this.getEAC(this.account);
            } else {
                this.recalculate();
            }
        });

        this.registerSubscriptions(subscription);
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.recalculate();
    }

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }

    // EAC service integration if (this.eacServiceEnabaled)
    private getEAC(account: EacAccount) {
        this.funds.forEach(fund => {
            this.investFundCodes.push(fund.pstCode);
            this.appianInstrumentIds.push(fund.instrumentId);
        });

        const request = new EACRequest();
        request.funds = this.mapFundType(account);
        request.transactionId = '/invest';
        request.portfolioTypeId = account.portfolioTypeId;

        this.eacService
            .getEAC(request)
            .pipe(this.scavenger.collect())
            .subscribe(data => this.mapEacResponse(data));
    }

    private mapFundType(eacAccount: EacAccount) {
        const fundsWithAllocation = new EACRequest();
        fundsWithAllocation.funds = [];

        eacAccount.funds.forEach(fund => {
            const mapId = this.investFundCodes.indexOf(fund.fundCode);
            fund.fundCode = this.appianInstrumentIds[mapId].toString();

            const mappedFund = { FundId: +fund.fundCode, FundAllocation: fund.allocation };

            fundsWithAllocation.funds.push(mappedFund);
        });

        return fundsWithAllocation.funds;
    }

    private mapEacResponse(response: EACResponse) {
        const investmentManagementArray = [];
        const adviceArray = [];
        const adminArray = [];
        const otherArray = [];
        const eacArray = [];

        response.effectiveAnnualCost.forEach(yearSet => {
            if (this.eacDisplayedYears.includes(yearSet.year)) {
                yearSet.fees.forEach(fee => {
                    if (fee.type === 'Investment Management') {
                        investmentManagementArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Advice') {
                        adviceArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Administration') {
                        adminArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Other') {
                        otherArray.push(fee.value / 100);
                    }
                    if (fee.type === 'Effective Annual Cost') {
                        eacArray.push(fee.value / 100);
                    }
                });
            }
        });

        this.items = [
            new EacSvcItem('Investment management', investmentManagementArray),
            new EacSvcItem('Advice', adviceArray),
            new EacSvcItem('Administration', adminArray),
            new EacSvcItem('Other', otherArray),
            new EacSvcItem('*Effective Annual Cost', eacArray),
        ];

        if (this.account.notAdvised) {
            this.items.forEach(item => {
                if (item.name === 'Advice') {
                    item.years = [0.0, 0.0, 0.0, 0.0];
                }
            });
        }
    }

    // EAC component calcualtion if (!this.eacServiceEnabaled)
    private recalculate() {
        if (!this.funds) {
            return;
        }
        const fees: Map<EacAccountFund, EacPerFund> = new Map();
        for (const accountFund of this.account.funds) {
            const fund = this.funds.find(f => f.pstCode === accountFund.fundCode);
            fees.set(accountFund, this.calculateForFund(fund));
        }
        const weightedEac: EacPerFund = new EacPerFund();

        for (let item of Array.from(fees.keys())) {
            const eac = fees.get(item);

            if (this.account.notAdvised) {
                weightedEac.adviceFee = 0;
            } else {
                weightedEac.adviceFee += eac.adviceFee * item.allocation;
            }
            weightedEac.adminFee += eac.adminFee * item.allocation;
            weightedEac.investmentManagementFee += eac.investmentManagementFee * item.allocation;
            weightedEac.otherFee += eac.otherFee * item.allocation;
        }

        this.items = [
            new EacItem('Investment management', weightedEac.investmentManagementFee),
            new EacItem('Advice', weightedEac.adviceFee),
            new EacItem('Administration', weightedEac.adminFee),
            new EacItem('Other', weightedEac.otherFee),
            new EacItem('*Effective Annual Cost', weightedEac.total(this.decimalPipe)),
        ];
    }

    private calculateForFund(fund: Fund): EacPerFund {
        if (!fund) {
            return new EacPerFund(0, 0, 0, 0);
        }

        return new EacPerFund(
            this.addVAT(fund.ter + fund.transactionCosts),
            this.addVAT(this.getFundAdviceFee(fund)),
            this.addVAT(this.getFundAdminFee(fund)),
            this.addVAT(0.0)
        );
    }

    private getFundAdminFee(fund: Fund): number {
        let fee = this.fees.find(f => f.portfolioTypeId === this.account.portfolioTypeId && f.instrumentId === fund.instrumentId && f.instrumentFeeTypeId === 6);
        return fee.feeValue;
    }

    private getFundAdviceFee(fund: Fund): number {
        let fee = this.fees.find(f => f.portfolioTypeId === this.account.portfolioTypeId && f.instrumentId === fund.instrumentId && f.instrumentFeeTypeId === 7);
        return fee.feeValue;        
    }    

    private addVAT(fee: number): number {
        return fee * (1 + this.vatRate);
    }
}