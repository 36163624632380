import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstReference } from 'pst/models';
import { PstParserService, QuestionPart } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable } from 'rxjs';
import { User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';

import { BasePstComponent } from '../base-pst.component';

@Component({
    selector: 'wim-pst-number',
    templateUrl: 'number.component.pug',
    styleUrls: ['number.component.scss'],
})
export class NumberComponent extends BasePstComponent implements OnInit, OnDestroy {
    @Input()
    public customerName: string;
    public tree: PstReference;
    public user: Observable<User>;

    public sliderText: QuestionPart[];
    public heading: string;
    public description: string;

    public max = 10000;
    public noMaxValue = false;
    public overrideMax = null;
    public min = 1;
    public step = 1;
    public sliderValue = 0;
    public inputForm: FormGroup;
    public inputWidth: string;
    private defaultValue: string;

    constructor(
        loggerService: LoggerService,
        modalService: NgbModal,
        private pstStore: Store<PstState>,
        private pstParser: PstParserService,
        private formBuilder: FormBuilder,
        private lookupService: LookupDataService
    ) {
        super(loggerService, modalService);
    }

    public sliderMove(event) {
        this.inputForm.controls.value.setValue(event.value);
    }

    public ngOnDestroy() {}

    public ngOnInit() {
        const result$ = this.pstStore.select(fromPst.selectInProgressPst);
        const tree$ = this.pstStore.select(fromPst.selectPstTree);
        const tooltips$ = this.lookupService.getPstTooltips();

        combineLatest(result$, tree$, tooltips$, (result, tree, tooltips) => {
            return { result, tree, tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ result, tree, tooltips }) => {
                this.tree = tree;
                if (!this.tree) {
                    return;
                }
                super.initContent(null, tooltips, tree.node.nodeId);

                const childNode = tree.childNodes[0];
                this.pstStore.dispatch(new UserSelectedChildNodeAction(childNode));

                this.heading = this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
                this.description = tree.node.nodeDescription;
                this.sliderText = this.pstParser.buildInputDescription(childNode.nodeDescription);

                this.overrideMax = this.pstParser.extractMaxOverride(childNode.nodeLongDescription);

                const range = this.pstParser.extractRange(childNode.nodeLongDescription);

                if (range) {
                    if (isNaN(range[1])) {
                        if (this.overrideMax) {
                            this.noMaxValue = true;
                            this.min = range[0] === null ? 1 : range[0];
                        } else {
                            this.noMaxValue = false;
                        }
                    } else {
                        [this.min, this.max] = range;
                    }
                }
                this.inputWidth = this.calculatInputWidth();
                this.defaultValue = this.pstParser.extractDefaultValue(tree.node.nodeLongDescription) || '';
                this.createForm();
                this.pstStore.dispatch(new PstScreenValidAction(this.inputForm.valid));
            });
    }

    private calculatInputWidth() {
        return `${super.measureTextWidth(this.noMaxValue ? '1 000 000' : `${this.max}`)}px`;
    }

    private createForm() {
        if (!this.inputForm) {
            const validators = [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(this.min)];
            if (!this.noMaxValue) {
                validators.push(Validators.max(this.max));
            }

            this.inputForm = this.formBuilder.group({
                value: ['', Validators.compose(validators)],
            });

            this.inputForm.valueChanges.subscribe(changes => {
                if (changes.value === null || changes.value === '') {
                    this.sliderValue = 0;
                } else {
                    this.sliderValue = +changes.value;
                }
                this.pstStore.dispatch(new UserEnteredValueAction(changes.value));
                this.pstStore.dispatch(new PstScreenValidAction(this.inputForm.valid));

                let newWidth = super.measureTextWidth(`${changes.value}`);
                if (newWidth > +this.inputWidth.replace('px', '')) {
                    this.inputWidth = `${newWidth}px`;
                }
            });
        }
        if (this.defaultValue) {
            this.inputForm.get('value').setValue(+this.defaultValue);
        }
    }
}
