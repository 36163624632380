import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { AppComponent } from 'app.component';
import appConfig from 'appConfig';
import { ApplicationModule } from 'application/application.module';
import { BankerModule } from 'banker/banker.module';
import BugsnagErrorHandler from 'bugsnag-angular';
import { InvestorModule } from 'investor/investor.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ToastrModule } from 'ngx-toastr';
import { PstModule } from 'pst/pst.module';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { SalesModule } from 'sales/sales.module';
import { reducers } from 'store/app.store';

import { FooterComponent, InvestorHelperComponent, NavigationComponent } from './app-layout';
import { AppRoutingModule } from './app.routes';
import bugsnagClient from './bugsnag';
import { ENV_PROVIDERS } from './environment';

import { RoboModule } from './robo/robo.module';
import {
    CacheBusterInterceptor,
    LoggedOutInterceptor,
    ServerErrorInterceptor,
    ServerTimeInterceptor,
    SessionExtenderInterceptor,
} from './shared/interceptors';
import { SharedModule } from './shared/shared.module';
// tslint:disable:no-var-requires
require('style-loader!../assets/css/main.css');
require('style-loader!../assets/css/purple-green.css');
require('style-loader!../assets/plugins/icheck/skins/all.css');
require('style-loader!ngx-toastr/toastr.css');
require('style-loader!../assets/css/style.css');
require('style-loader!../assets/css/style-ext.css');

export function errorHandlerFactory() {
    return new BugsnagErrorHandler(bugsnagClient);
}
const bugsnagProvider = {
    provide: ErrorHandler,
    useFactory: errorHandlerFactory,
};

const MODULE_COMPONENTS = [AppComponent, FooterComponent, NavigationComponent, InvestorHelperComponent];
const APP_HTTP_INTERCEPTORS = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheBusterInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ServerErrorInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: SessionExtenderInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ServerTimeInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggedOutInterceptor,
        multi: true,
    },
];

const OPTIONAL_IMPORTS = [];

if (appConfig.env === 'Default') {
    OPTIONAL_IMPORTS.push(
        StoreDevtoolsModule.instrument({
            maxAge: 100,
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            maxAge: 100,
        })
    );
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [...MODULE_COMPONENTS],
    imports: [
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgbModule.forRoot(),
        NgxsModule.forRoot([], {
            developmentMode: appConfig.env === 'Default',
            compatibility: {
                strictContentSecurityPolicy: true,
            },
        }),
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        SharedModule.forRoot(),
        InvestorModule,
        BankerModule.forRoot(),
        PstModule,
        RoboModule,
        ApplicationModule,
        SalesModule,
        NgxErrorsModule,
        PrettyJsonModule,
        NgxPageScrollModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            progressAnimation: 'decreasing',
            enableHtml: true,
        }),
        BrowserAnimationsModule,
        MatSliderModule,
        ...OPTIONAL_IMPORTS,
    ],

    providers: [ENV_PROVIDERS, ...APP_HTTP_INTERCEPTORS, bugsnagProvider],
    entryComponents: [ContactBankerModalComponent],
})
export class AppModule {
    constructor() {
        // bugsnagClient.startSession();
    }
}
