import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccountType, SourceOfFundsDeclaration } from 'application/models';
import { ApplicationDateService } from 'application/services';
import { AppFormUpdatedBankAccountAction, AppFormUpdatedDebitOrderDetailsAction, BankAccountListLoadedAction, InvestmentDetailsValidAction, } from 'application/store/application.reducers';
import * as fromApplication from 'application/store/application.store';
import { CisSearchService } from 'banker/services';
import * as moment from 'moment';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DateFormatterService, LookupDataService, NumberFormatterService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { bigNumberMaxValidator, bigNumberMinValidator, dateMaxValidator, dateMinValidator } from 'shared/validators';
import { PossibleDebitDates } from '../debit-dates';
var minUnitTrustDebitOrder = 500;
var maxUnitTrustDebitOrder = 100000;
var minUnitTrustLumpSum = 5000;
var maxUnitTrustLumpSum = 1000000;
var minCashDebitOrder = 1;
var maxCashDebitOrder = 50000000;
var minCashLumpSum = 1;
var maxCashLumpSum = 20000000;
var DirectOnboardingDetailsComponent = /** @class */ (function () {
    function DirectOnboardingDetailsComponent(applicationStore, sharedStore, pstStore, formBuilder, lookupService, numberFormatter, customerService, applicationDateService, dateFormatter) {
        var _this = this;
        this.applicationStore = applicationStore;
        this.sharedStore = sharedStore;
        this.pstStore = pstStore;
        this.formBuilder = formBuilder;
        this.lookupService = lookupService;
        this.numberFormatter = numberFormatter;
        this.customerService = customerService;
        this.applicationDateService = applicationDateService;
        this.dateFormatter = dateFormatter;
        this.lumpSumSelected = false;
        this.debitOrderSelected = false;
        this.isCash = false;
        this.pstAdvised = false;
        this.isDirect = false;
        this.bankAccounts = [];
        this.debitDates = [];
        this.minIncrease = 0;
        this.maxIncrease = 15;
        this.totalLumpsum = 0;
        this.sourceOfFundsTypes = [];
        this.bankAccountsLoadedOnInit = false;
        this.products = new Map();
        this.sharedStore.dispatch(new LoadingAction());
        this.selectedFund = this.pstStore.select(fromPst.selectSelectedFund);
        this.lookupService.getSourceOfFundsTypes().subscribe(function (types) { return (_this.sourceOfFundsTypes = types); });
        var application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer).pipe(filter(function (customer) { return !!customer; }));
        var products$ = this.lookupService.getPortfolioTypes();
        var isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        this.minDebitDate = this.applicationDateService.firstPossibleDebitDate();
        this.maxDebitDate = this.applicationDateService.maxLumpsumDate();
        this.minDateForPicker = this.dateFormatter.forDatePicker(this.minDebitDate);
        this.maxDateForPicker = this.dateFormatter.forDatePicker(this.maxDebitDate);
        this.subscriptions$ = combineLatest(customer$, application$, products$, isDirect$, function (selectedCustomer, appForm, products, isDirect) {
            return { selectedCustomer: selectedCustomer, appForm: appForm, products: products, isDirect: isDirect };
        }).subscribe(function (_a) {
            var selectedCustomer = _a.selectedCustomer, appForm = _a.appForm, products = _a.products, isDirect = _a.isDirect;
            _this.isDirect = isDirect;
            _this.pstAdvised = appForm.recordOfAdvice.pstAdvised;
            _this.appForm = appForm;
            _this.debitDates = PossibleDebitDates.filter(function (date) { return date.forProduct(appForm.portfolioTypeId); });
            _this.customerName = selectedCustomer.title + " " + selectedCustomer.firstname + " " + selectedCustomer.lastname;
            _this.selectedCustomer = selectedCustomer;
            if (!_this.bankAccountsLoadedOnInit) {
                _this.bankAccountsLoadedOnInit = true;
                _this.reloadBankAccounts();
            }
            _this.minDebitOrder = minUnitTrustDebitOrder;
            _this.maxDebitOrder = maxUnitTrustDebitOrder;
            _this.minLumpSum = minUnitTrustLumpSum;
            _this.maxLumpSum = maxUnitTrustLumpSum;
            var unitTrustPortfolioType = _this.appForm.portfolioTypeId === appConfig.portfolioTypes.INV ||
                _this.appForm.portfolioTypeId === appConfig.portfolioTypes.TFDS;
            if (!unitTrustPortfolioType) {
                products.forEach(function (product) {
                    _this.products.set(product.id, product);
                });
                _this.product = _this.products.get(_this.appForm.portfolioTypeId);
                _this.minDebitOrder = minCashDebitOrder;
                _this.maxDebitOrder = maxCashDebitOrder;
                _this.minLumpSum = minCashLumpSum;
                _this.maxLumpSum = maxCashLumpSum;
            }
            if (!_this.investmentForm) {
                _this.lumpSumSelected = !!appForm.lumpSumDetails;
                _this.debitOrderSelected = !!appForm.debitOrderDetails;
                _this.createForm();
            }
        });
    }
    DirectOnboardingDetailsComponent.prototype.isSourceOfFundsOther = function (id) {
        var sourceType = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return sourceType && sourceType.isOther;
    };
    DirectOnboardingDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions$.unsubscribe();
    };
    DirectOnboardingDetailsComponent.prototype.debitDayChange = function () {
        this.investmentForm.get('debitOrder.firstDebitDate').setValue(this.toFormDate(this.calculateFirstDebitDate()));
        this.investmentForm.get('debitOrder.firstEscalationDate').setValue(this.toFormDate(moment(this.calculateFirstDebitDate())
            .add(1, 'years')
            .toDate()));
    };
    DirectOnboardingDetailsComponent.prototype.lumpsumDateChanged = function () {
        if (this.isCash && this.appForm.debitOrderDetails) {
            var lumpSumDebitDate = this.dateFormatter.asMoment(this.investmentForm.get('lumpSum.debitDate').value);
            var firstRecurring = lumpSumDebitDate.clone().add(1, 'month');
            this.investmentForm.get('debitOrder.debitDate').setValue(lumpSumDebitDate.get('date'));
            this.investmentForm.get('debitOrder.firstDebitDate').setValue(this.toFormDate(firstRecurring));
        }
    };
    DirectOnboardingDetailsComponent.prototype.readableAccountType = function (type) {
        return AccountType[type];
    };
    DirectOnboardingDetailsComponent.prototype.accountName = function (account) {
        return account.accountName || AccountType[account.accountType];
    };
    DirectOnboardingDetailsComponent.prototype.reloadBankAccounts = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.bankAccountLoadingError = false;
        var bankAccounts$ = this.isDirect
            ? this.sharedStore.select(fromShared.selectUserBankAccounts)
            : this.customerService.getBankAccounts(this.selectedCustomer.ucn);
        bankAccounts$.subscribe(function (accounts) {
            _this.bankAccounts = accounts.map(function (acc) {
                acc.accountNumber = "" + +acc.accountNumber;
                return acc;
            });
            _this.applicationStore.dispatch(new BankAccountListLoadedAction(accounts));
            _this.sharedStore.dispatch(new StopLoadingAction());
        }, function () {
            _this.bankAccountLoadingError = true;
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    DirectOnboardingDetailsComponent.prototype.calculateFirstDebitDate = function (defaultValue) {
        if (defaultValue === void 0) { defaultValue = 1; }
        var userDebitDay = this.investmentForm ? +this.investmentForm.get('debitOrder.debitDate').value : defaultValue;
        return this.applicationDateService.calculateFirstDebitDate(userDebitDay);
    };
    DirectOnboardingDetailsComponent.prototype.toFormDate = function (date) {
        return this.dateFormatter.forDatePicker(moment(date));
    };
    DirectOnboardingDetailsComponent.prototype.otherDescriptionRequired = function (id) {
        var type = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return type && type.isOther;
    };
    DirectOnboardingDetailsComponent.prototype.validateSourceOfFunds = function (abstractForm) {
        var sourceIdControl = abstractForm.get('sourceOfFundsId');
        var descriptionControl = abstractForm.get('otherDescription');
        var required = this.otherDescriptionRequired(+sourceIdControl.value);
        var valid = true;
        if (required) {
            var value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    };
    DirectOnboardingDetailsComponent.prototype.validateMaxEscalationDate = function (first, second) {
        var errorControl = this.investmentForm.controls.debitOrder.get('firstEscalationDate');
        var firstDate = first ? moment(first) : null;
        var secondDate = second ? moment(second) : null;
        var valid = firstDate === null || secondDate === null || (!!firstDate && !!secondDate && secondDate.diff(firstDate, 'years', true) <= 1);
        var errorValue = valid ? null : { datemaxfrommin: valid ? null : firstDate.format('YYYY-MM-DD') };
        var afterMin = firstDate === null || secondDate === null || secondDate.isSameOrAfter(firstDate);
        var minErrorValue = afterMin ? null : { datemin: afterMin ? null : firstDate.format('YYYY-MM-DD') };
        if (errorControl) {
            if (errorValue) {
                errorControl.setErrors(errorValue);
            }
            else if (minErrorValue) {
                errorControl.setErrors(minErrorValue);
            }
            else {
                errorControl.setErrors(null);
            }
        }
    };
    DirectOnboardingDetailsComponent.prototype.createForm = function () {
        var _this = this;
        var form = {};
        this.appForm.lumpSumDetails.forEach(function (lumpSumDetail) {
            _this.totalLumpsum = _this.totalLumpsum + lumpSumDetail.amount;
        });
        form.bankAccount = this.formBuilder.group({
            selectedAccount: [this.appForm.debitBankAccount, Validators.required],
        });
        form.enableLumpSum = this.formBuilder.control(true);
        form.enableDebitOrder = this.formBuilder.control(true);
        if (!!this.appForm.lumpSumDetails) {
            form.lumpSum = this.formBuilder.group({
                amount: [
                    this.numberFormatter.transformBigNumber(this.appForm.lumpSumDetails[0].amount),
                    Validators.compose([
                        Validators.required,
                        bigNumberMinValidator(this.minLumpSum),
                        bigNumberMaxValidator(this.maxLumpSum),
                    ]),
                ],
                debitDate: [
                    this.toFormDate(this.appForm.lumpSumDetails[0].debitDate),
                    Validators.compose([
                        Validators.required,
                        dateMinValidator(this.minDebitDate.toDate(), this.dateFormatter),
                        dateMaxValidator(this.maxDebitDate.toDate(), this.dateFormatter),
                    ]),
                ],
                sourceOfFundsId: [this.appForm.lumpSumDetails[0].sourceOfFunds.sourceOfFundsId, Validators.required],
                otherDescription: [this.appForm.lumpSumDetails[0].sourceOfFunds.otherDescription],
                fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
            }, { validator: this.validateSourceOfFunds.bind(this) });
        }
        if (!!this.appForm.debitOrderDetails) {
            form.debitOrder = this.formBuilder.group({
                amount: [
                    this.numberFormatter.transformBigNumber(this.appForm.debitOrderDetails.amount),
                    Validators.compose([
                        Validators.required,
                        bigNumberMinValidator(this.minDebitOrder),
                        bigNumberMaxValidator(this.maxDebitOrder),
                    ]),
                ],
                debitDate: [this.appForm.debitOrderDetails.debitDate, Validators.required],
                firstDebitDate: [this.toFormDate(this.calculateFirstDebitDate(this.appForm.debitOrderDetails.debitDate))],
                firstEscalationDate: [
                    this.toFormDate(this.appForm.debitOrderDetails.firstEscalationDate),
                    Validators.compose([Validators.required]),
                ],
                escalation: [
                    this.appForm.debitOrderDetails.escalation ? +this.appForm.debitOrderDetails.escalation : 6,
                    Validators.compose([
                        Validators.required,
                        bigNumberMinValidator(this.minIncrease),
                        bigNumberMaxValidator(this.maxIncrease),
                    ]),
                ],
                sourceOfFundsId: [this.appForm.debitOrderDetails.sourceOfFunds.sourceOfFundsId, Validators.required],
                otherDescription: [this.appForm.debitOrderDetails.sourceOfFunds.otherDescription],
                fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
            }, { validator: this.validateSourceOfFunds.bind(this) });
        }
        this.investmentForm = this.formBuilder.group(form);
        this.investmentForm.valueChanges.subscribe(function (changes) { return _this.handleFormChanges(changes); });
        this.investmentForm.statusChanges.subscribe(function (val) {
            _this.applicationStore.dispatch(new InvestmentDetailsValidAction(val === 'VALID'));
        });
        this.lumpsumDateChanged();
    };
    DirectOnboardingDetailsComponent.prototype.handleFormChanges = function (changes) {
        var debitOrderGroup = this.investmentForm.get('debitOrder');
        var lumpSumGroup = this.investmentForm.get('lumpSum');
        var debitOrderEnabled = this.investmentForm.value.enableDebitOrder;
        var lumpSumEnabled = this.investmentForm.value.enableLumpSum;
        if (debitOrderGroup) {
            if (debitOrderEnabled && debitOrderGroup.disabled) {
                debitOrderGroup.enable();
                return;
            }
            if (!debitOrderEnabled && debitOrderGroup.enabled) {
                debitOrderGroup.disable();
                return;
            }
        }
        if (lumpSumGroup) {
            if (lumpSumEnabled && lumpSumGroup.disabled) {
                lumpSumGroup.enable();
                return;
            }
            if (!lumpSumEnabled && lumpSumGroup.enabled) {
                lumpSumGroup.disable();
                return;
            }
        }
        var changesClone = {
            debitOrder: changes.debitOrder ? Object.assign({}, changes.debitOrder) : undefined,
            lumpSum: changes.lumpSum ? Object.assign({}, changes.lumpSum) : {},
            bankAccount: {
                selectedAccount: changes.bankAccount && changes.bankAccount.selectedAccount && Object.assign(changes.bankAccount.selectedAccount),
            },
        };
        if (changesClone.debitOrder) {
            changesClone.debitOrder.firstDebitDate = this.dateFormatter.forApi(changes.debitOrder.firstDebitDate);
            changesClone.debitOrder.firstEscalationDate = this.dateFormatter.forApi(changes.debitOrder.firstEscalationDate);
        }
        if (changesClone.lumpSum) {
            var sourceOfFunds = new SourceOfFundsDeclaration();
            this.appForm.lumpSumDetails[0] = changesClone.lumpSum;
            changesClone.lumpSum.debitDate = this.dateFormatter.forApi(changesClone.lumpSum.debitDate);
            sourceOfFunds = {
                sourceOfFundsId: changesClone.lumpSum.sourceOfFundsId,
                otherDescription: changesClone.lumpSum.otherDescription,
            };
            this.appForm.lumpSumDetails[0].sourceOfFunds = sourceOfFunds;
        }
        this.applicationStore.dispatch(new AppFormUpdatedDebitOrderDetailsAction({
            lumpSumDetails: lumpSumEnabled ? changesClone.lumpSum : [],
            debitOrderDetails: debitOrderEnabled ? changesClone.debitOrder : undefined,
        }));
        this.applicationStore.dispatch(new AppFormUpdatedBankAccountAction(changes.bankAccount && changes.bankAccount.selectedAccount));
        if (changesClone.debitOrder) {
            this.validateMaxEscalationDate(changesClone.debitOrder.firstDebitDate, changesClone.debitOrder.firstEscalationDate);
        }
    };
    return DirectOnboardingDetailsComponent;
}());
export { DirectOnboardingDetailsComponent };
