import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store as NgrxStore } from '@ngrx/store';
import { Store } from '@ngxs/store';
import { Store as NgxsStore } from '@ngxs/store';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { Chart } from 'angular-highcharts';
import appConfig from 'appConfig';
import { PstContentModalComponent } from 'pst/components/content-modal/pst-content-modal.component';
import { PstResultContent } from 'pst/models';
import { FundSelectedAction, PstState } from 'pst/store';
import { FinancialAdvice, GraphData, Recommendation, RoboFieldId } from 'robo/models';
import { RoboRecommendationAcceptedAction, RoboStore } from 'robo/store';
import { TaxFreeOpenedAction, TaxFreeOptOutAction } from 'robo/store/robo.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { EacModalComponent } from 'shared/components';
import { CashProduct, Fund, PortfolioType, RoboGoal, PortfolioInstrumentFee } from 'shared/models';
import { LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';

import { chartOptions } from './chart-options';

const PER_MONTH = ' <span class="per-mont">p/m</span>';

@Component({
    selector: 'wim-robo-projected-performance',
    templateUrl: 'robo-projected-performance.component.pug',
    styleUrls: ['robo-projected-performance.component.scss'],
})
export class RoboProjectedPerformanceComponent extends BaseComponent {
    public chart: Observable<Chart>;
    private funds: Fund[] = [];
    private fees: PortfolioInstrumentFee[] = [];
    private goals: RoboGoal[] = [];
    private portfolioTypes: PortfolioType[] = [];
    private cashProducts: CashProduct[] = [];
    private enteredTargetAmount = 0;
    private enteredRecurringAmount = 0;
    private enteredTerm = 120;
    private content: PstResultContent[] = [];
    private toolTips = [];
    public recommendation: Recommendation;

    public selectedFund: Fund;
    public goalName: string;
    public goal: RoboGoal;
    public recommendedPortfolioType: PortfolioType;
    public recommendedCashProduct: CashProduct;
    public errors$: Observable<string[]>;
    public openTaxFree = true;
    public goalReached = false;
    public canIncreaseGoal = true;
    public portfolioDetails: string;
    public isDirect: boolean;
    public isEmergenciesGoalCategory: boolean;
    public isJustInvestTaxFreeGoalCategory: boolean;

    constructor(
        loggerService: LoggerService,
        private sharedStore: NgrxStore<SharedState>,
        private store: Store,
        lookupDataService: LookupDataService,
        private ngxsStore: NgxsStore,
        private numberFormatter: NumberFormatterService,
        private modalService: NgbModal,
        private pstStore: NgrxStore<PstState>
    ) {
        super(loggerService);

        this.chart = new BehaviorSubject(this.getEmptyChart()).pipe(debounceTime(100));

        lookupDataService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(funds => {
                this.funds = funds;
            });

        lookupDataService
            .getPortfolioInstrumentFees()
            .pipe(this.scavenger.collect())
            .subscribe(fees => {
                this.fees = fees;
            });            

        lookupDataService
            .getRoboGoals()
            .pipe(this.scavenger.collect())
            .subscribe(goals => {
                this.goals = goals;
            });

        lookupDataService
            .getCashProductDetails()
            .pipe(this.scavenger.collect())
            .subscribe(cashProducts => {
                this.cashProducts = cashProducts;
            });

        sharedStore
            .select(fromShared.selectIsDirect)
            .pipe(this.scavenger.collect())
            .subscribe(isDirect => {
                this.isDirect = isDirect;
            });            

        store
            .select(RoboStore.currentAdvice)
            .pipe(this.scavenger.collect())
            .subscribe(advice => {
                this.renderRecommendation(advice);
            });

        lookupDataService
            .getPortfolioTypes()
            .pipe(this.scavenger.collect())
            .subscribe(portfolioTypes => {
                this.portfolioTypes = portfolioTypes;
            });

        this.errors$ = store.select(RoboStore.validationErrors);

        lookupDataService
            .getPstResultContent()
            .pipe(this.scavenger.collect())
            .subscribe(content => {
                this.content = content;
            });

        store
            .select(RoboStore.currentAdvice)
            .pipe(this.scavenger.collect())
            .subscribe(advice => {
                this.renderRecommendation(advice);
            });

        store
            .select(RoboStore.completedField)
            .pipe(
                this.scavenger.collect(),
                map(fn => {
                    let goalId = this.getValueIfFieldType(fn, RoboFieldId.goal);
                    this.goal = goalId ? this.goals.find(goal => +goal.goalId === +goalId) : null;
                    this.isEmergenciesGoalCategory = goalId ? (+goalId === 4) : false;
                    this.isJustInvestTaxFreeGoalCategory = goalId ? (+goalId === 11) : false;
                    return fn;
                }),
                map(fn => {
                    this.goalName = this.getValueIfFieldType(fn, RoboFieldId.goalName);
                    return fn;
                }),
                map(fn => {
                    this.enteredTargetAmount = +this.getValueIfFieldType(fn, RoboFieldId.targetValue);
                    if (!this.recommendation) {
                        this.renderRecommendation(null);
                    }
                    return fn;
                }),
                map(fn => {
                    this.enteredRecurringAmount = +this.getValueIfFieldType(fn, RoboFieldId.recurringContributionAmount);
                    if (!this.recommendation) {
                        this.renderRecommendation(null);
                    }
                    return fn;
                }),
                map(fn => {
                    this.enteredTerm = +this.getValueIfFieldType(fn, RoboFieldId.term);
                    if (!this.recommendation) {
                        this.renderRecommendation(null);
                    }
                    return fn;
                })
            )
            .subscribe();
    }

    private renderChart(chart: Chart) {
        (this.chart as BehaviorSubject<Chart>).next(chart);
    }

    private renderRecommendation(advice: FinancialAdvice) {
        if (advice && advice.recommendations) {
            this.recommendation = advice.recommendations[0];
        } else {
            this.recommendation = null;
        }

        if (this.recommendation) {
            if (this.recommendation.graphData) {
                this.renderChart(this.createChart(this.recommendation.graphData));
            } else {
                this.renderChart(this.getEmptyChart());
            }
            this.recommendedPortfolioType = this.portfolioTypes.find(pType => pType.id === this.recommendation.portfolioTypeId);
            if (this.recommendation.taxFreeOptionAvailable) {
                this.recommendedPortfolioType = this.portfolioTypes.find(pType => pType.id === appConfig.portfolioTypes.TFDS);
            }
            if (this.recommendation.taxFreeOptionAvailable) {
                if (this.openTaxFree) {
                    this.recommendedPortfolioType = this.portfolioTypes.find(pType => pType.id === appConfig.portfolioTypes.TFDS);
                } else {
                    this.recommendedPortfolioType = this.portfolioTypes.find(pType => pType.id === appConfig.portfolioTypes.INV);
                }
            }
            this.setPortfolioDetails();            
            if (this.recommendation.investmentAccountReferenceData) {
                let fund = this.funds.find(f => f.fundId === this.recommendation.fundId);
                this.selectedFund = fund;
                this.setAdminFee();
                this.recommendedCashProduct = null;
                this.pstStore.dispatch(new FundSelectedAction(fund));
            }
            if (this.recommendation.cashAccountReferenceData) {
                this.selectedFund = null;
                this.recommendedCashProduct = this.cashProducts.find(
                    product => product.portfolioTypeId === this.recommendation.portfolioTypeId
                );
                this.pstStore.dispatch(new FundSelectedAction(null));
            }
        } else {
            this.recommendedPortfolioType = null;
            this.renderChart(this.getEmptyChart());
        }

        this.checkRecommendations();
    }

    private setPortfolioDetails() {
        if(this.recommendedPortfolioType) {
            if(this.recommendedPortfolioType.id === 3) {
                this.portfolioDetails = 'An Investment Account provides an opportunity to access a wide variety of unit trust funds through a single platform, allowing you to diversify across various asset classes. The investment account is easy to transact on; you can add and/ or withdraw funds. Income tax and/or capital gains tax may be applicable.';
            }
            if(this.recommendedPortfolioType.id === 10) {
                this.portfolioDetails = 'A Tax-Free Unit Trust provides you with a tax efficient way to invest. You can contribute up to R36 000 per year, and up to R500 000 in your lifetime, and the proceeds are tax free. You are allowed to withdraw from the investment, BUT please note that the withdrawn amount is deducted from your annual and lifetime contribution limit and cannot be replaced.';
            }
            if(this.recommendedPortfolioType.id === 25) { // Savings Account
                this.portfolioDetails = 'We can help you separate your savings from your day-to-day spending to ensure you don’t spend money which is meant to be saved. A Savings Account allows you to put money away safely and securely for unforeseen expenses where you can earn interest from as little as R1 and have instant access to your savings all at no fee.';
            }
            if(this.recommendedPortfolioType.id === 26) { // Money Maximiser
                this.portfolioDetails = 'We can help you earn a money market fund related rate. A Money Maximiser provides an opportunity to put away R100 000 or more and grow it safely and securely, where you can earn a money market fund related rate.  You still enjoy capital and quoted returns guaranteed. Enjoy instant access to your investment.';
            }                        
            if(this.recommendedPortfolioType.id === 27) { // 32 Day Flexi Notice
                this.portfolioDetails = 'A 32 Day Flexi Notice Account lets you save R5 000 or more, securely. Add any amount at any time and the money is available after 32 days’ notice for free, or earlier at a fee. No monthly fees apply.';
            }
            if(this.recommendedPortfolioType.id === 28) { // 7 Day Notice
                this.portfolioDetails = 'A 7 Day Notice Account, save from R20 000 or more and grow your money. Add any amount at any time (electronically) and the money is available after 7 days’ notice for free. No monthly fees apply and capital and quoted returns are guaranteed.';
            }
            if(this.recommendedPortfolioType.id === 29) { // Cash Intelligence
                this.portfolioDetails = 'A Cash Intelligence Investment provides an opportunity to earn an enhanced variable rate and enjoy flexible access to your investment. Minimum opening deposit of R 1 000 000. The Cash Index Investment is designed to offer guaranteed capital and quoted returns.';
            }
            if(this.recommendedPortfolioType.id === 30) { // Cash Intelligence
                this.portfolioDetails = 'A Cash Intelligence Investment provides an opportunity to earn an enhanced variable rate and enjoy flexible access to your investment. Minimum opening deposit of R 1 000 000. The Cash Index Investment is designed to offer guaranteed capital and quoted returns.';
            }
            if(this.recommendedPortfolioType.id === 31) {// Cash Intelligence
                this.portfolioDetails = 'A Cash Intelligence Investment provides an opportunity to earn an enhanced variable rate and enjoy flexible access to your investment. Minimum opening deposit of R 1 000 000. The Cash Index Investment is designed to offer guaranteed capital and quoted returns.';
            }
            if(this.recommendedPortfolioType.id === 32) { // Fixed Deposit
                this.portfolioDetails = 'We can help  you keep your money growing and safe.  Know in advance how much interest you are going to earn when you invest in an FNB Fixed Deposit.   Your deposits are safe and guaranteed.  A Fixed Deposit allows you to put away R10 000 or more and grow it securely for a fixed period between 7 days to 60 months. Additional money can be added at maturity and interest can be reinvested or transferred to another account. No monthly fees apply.';
            }
        }
    }    

    private setAdminFee() {
        let fee = this.fees.find(f => f.portfolioTypeId === this.recommendedPortfolioType.id && f.instrumentId === this.selectedFund.instrumentId && f.instrumentFeeTypeId === 6);
        this.selectedFund.adminFee = fee.feeValue;
    }

    private getValueIfFieldType(selectorFunction, fieldId: RoboFieldId) {
        let field = selectorFunction(fieldId);
        if (field) {
            return field.value;
        }
        return null;
    }

    private getChartOptions(): Highcharts.Options {
        return chartOptions;
    }

    private transformBigNumberRand(value) {
        return this.numberFormatter.transformNumberTextPrepend(value, null, 'R ', val => {
            return this.numberFormatter.transformBigNumber(val, null, true);
        });
    }

    public toggleTaxFree() {
        this.openTaxFree = !this.openTaxFree;

        this.ngxsStore.dispatch(new TaxFreeOpenedAction(this.openTaxFree));
        this.ngxsStore.dispatch(new TaxFreeOptOutAction(!this.openTaxFree));

        if (this.openTaxFree) {
            this.recommendedPortfolioType = this.portfolioTypes.find(pType => pType.id === appConfig.portfolioTypes.TFDS);
        } else {
            this.recommendedPortfolioType = this.portfolioTypes.find(pType => pType.id === appConfig.portfolioTypes.INV);
        }

        this.setAdminFee(); 
    }

    public checkRecommendations() {
        let recommendationThreshold = 100;

        if (this.recommendation) {
            if (this.recommendation.goalAmountAdjustedForInflation <= 10000) {
                recommendationThreshold = 100;
            } else if (
                this.recommendation.goalAmountAdjustedForInflation > 10000 &&
                this.recommendation.goalAmountAdjustedForInflation <= 1000000
            ) {
                recommendationThreshold = this.recommendation.goalAmountAdjustedForInflation / 100;
            } else if (this.recommendation.goalAmountAdjustedForInflation > 1000000) {
                recommendationThreshold = 10000;
            }

            if (this.recommendation.surplusOrShortfall >= 0) {
                this.goalReached = true;
            } else {
                let shortfall = Math.abs(this.recommendation.surplusOrShortfall);
                if (shortfall < recommendationThreshold) {
                    this.goalReached = true;
                }
                if (shortfall > recommendationThreshold) {
                    this.goalReached = false;
                }
            }

            if (this.recommendation.surplusOrShortfall > recommendationThreshold && this.recommendation.surplusOrShortfall > 0) {
                this.canIncreaseGoal = true;
            } else {
                this.canIncreaseGoal = false;
            }
        }
        return this.goalReached;
    }

    private getEmptyChart() {
        const chartConfig = this.getChartOptions();
        chartConfig.series = [
            {
                name: 'Projected Performance',
                data: new Array(this.enteredTerm).fill(0),
                color: 'black',
            },
            {
                name: 'Target Amount',
                data: new Array(this.enteredTerm).fill(this.enteredTargetAmount),
                color: '#007072',
            },
            {
                name: 'Target Amount',
                data: new Array(this.enteredTerm).fill(this.enteredTargetAmount),
                color: '#007072',
            },
        ];
        return new Chart(chartConfig);
    }

    public openEacModal() {
        const modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = { funds: [{ fundCode: this.selectedFund.pstCode, allocation: 1 }], portfolioTypeId: this.recommendedPortfolioType.id };
    }

    public isContent(id: number) {
        return !!this.content.find(c => c.id === id);
    }

    public displayFundContentPopup() {
        let id = 0;
        switch (this.selectedFund.instrumentId) {
            case 7:
                id = 4;
                break;
            case 6:
                id = 5;
                break;
            case 3:
                id = 6;
                break;
            case 5:
                id = 7;
                break;
        }
        this.displayContentPopup(id);
    }

    public displayContentPopup(id: number) {
        if (!this.isContent(id)) {
            return;
        }
        let content = this.content.find(c => c.id === id);

        const modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    }

    public displayProjectionContentPopup(id: number) {
        if (!this.isContent(id)) {
            return;
        }
        let content = Object.assign('', this.content.find(c => c.id === id));

        let bestCaseGraphPoint = this.recommendation.graphData.up[this.recommendation.graphData.up.length - 1];
        let bestCase = this.transformBigNumberRand(bestCaseGraphPoint);

        let worstCaseGraphPoint = this.recommendation.graphData.down[this.recommendation.graphData.up.length - 1];
        let worstCase = this.transformBigNumberRand(worstCaseGraphPoint);

        let recommended = this.transformBigNumberRand(this.recommendation.requiredMonthlyContribution).trim();
        let original = this.transformBigNumberRand(this.enteredRecurringAmount).trim();

        content.content = content.content.replace('{{UP_SCENARIO}}', this.formatAsRandValue(bestCase));
        content.content = content.content.replace('{{DOWN_SCENARIO}}', this.formatAsRandValue(worstCase));
        content.content = content.content.replace('{{ORIGINAL}}', this.formatMonthlyPayment(original));

        if (this.recommendation.goalAmountAdjustedForInflation > 0) {
            if (this.goalReached && this.canIncreaseGoal) {
                content.content = content.content.replace('{{RECOMMENDED}}', 'Ahead of your goal');
            } else if (this.goalReached && !this.canIncreaseGoal) {
                content.content = content.content.replace('{{RECOMMENDED}}', 'Goal on track');
            } else {
                content.content = content.content.replace('{{RECOMMENDED}}', this.formatMonthlyPayment(recommended));
            }
        } else {
            content.content = content.content.replace('{{RECOMMENDED}}', 'No recommendation as no goal amount specified');
        }

        const modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    }

    private formatMonthlyPayment(value: string) {
        if (value === 'R') {
            return `R 0${PER_MONTH}`;
        }
        return `${value}${PER_MONTH}`;
    }

    private formatAsRandValue(value: string) {
        if (value === 'R') {
            return `R 0`;
        }
        return `${value}`;
    }


    private createChart(graphData: GraphData) {
        let series: any[];
        let charts: any[];

        if (this.recommendation.goalAmountAdjustedForInflation > 0) {
            graphData.goal = new Array(graphData.clientScenario.length);
            graphData.goal.fill(this.recommendation.goalAmountAdjustedForInflation);
        } else {
            graphData.goal = null;
        }

        const downScenario = {
            name: 'Low Growth Scenario',
            data: graphData.down,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        const upScenario = {
            name: 'High Growth Scenario',
            data: graphData.up,
            color: '#aaaaaa',
            dashStyle: 'shortdot',
        };
        const clientScenario = {
            name: 'Your Contribution',
            data: graphData.clientScenario,
            color: '#01aaad',
        };
        const goalScenario = {
            name: 'Goal Amount',
            data: graphData.goal,
            color: '#007072',
            dashStyle: 'longdash',
        };
        const recommendedScenario = {
            name: 'Recommended Contribution',
            data: graphData.expected,
            color: '#ff9900',
        };

        series = [];
        charts = [goalScenario, downScenario, clientScenario, upScenario];

        charts.forEach(chart => {
            if (chart.data) {
                series.push(chart);
            }
        });

        if (!this.goalReached) {
            series.push(recommendedScenario);
        }

        const chartConfig = this.getChartOptions();
        chartConfig.series = series;

        return new Chart(chartConfig);
    }

    public adjustRecurring() {
        this.store.dispatch(
            new RoboRecommendationAcceptedAction({
                field: RoboFieldId.recurringContributionAmount,
                value: Math.round(this.recommendation.requiredMonthlyContribution),
            })
        );
    }

    public adjustLumpSum() {
        this.store.dispatch(
            new RoboRecommendationAcceptedAction({
                field: RoboFieldId.lumpSumAmount,
                value: Math.round(this.recommendation.requiredInitialLump),
            })
        );
    }

    public adjustGoal() {
        this.store.dispatch(
            new RoboRecommendationAcceptedAction({
                field: RoboFieldId.targetValue,
                value: Math.round(this.recommendation.recommendedGoalAmount),
            })
        );
    }

    public ngOnDestroy() {}
}
