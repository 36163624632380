import { Chart } from 'angular-highcharts';
import { PstResult } from 'pst/models';

export class CashCalculationResult {
    public estimatedFutureValue: number;

    public nominalRate: number;
    public effectiveRate: number;

    public graph: Chart;

    public pstResult: PstResult;
}
