﻿import { SourceOfFundsDeclaration } from './source-of-funds-declaration';

export class LumpSumDetails {
    public sourceOfFunds: SourceOfFundsDeclaration = new SourceOfFundsDeclaration();
    public debitDate: Date;
    public amount: number;
    public fundingTypeId: number;
    public sourceOfFundsId: number;
    public otherDescription: string;
}
