import { Injectable } from '@angular/core';
import appConfig from 'appConfig';
import bugsnagClient from 'bugsnag';
import * as moment from 'moment';
import { Brand } from 'shared/models';

// tslint:disable:max-line-length
const welcomeFNB =
    '%c[FNB WI]\n' +
    ' ________  __    __  _______         __       __         ___            ______ 		\n' +
    '/        |/  \\  /  |/       \\       /  |  _  /  |       /   \\          /      |	\n' +
    '$$$$$$$$/ $$  \\ $$ |$$$$$$$  |      $$ | / \\ $$ |      /$$$  |         $$$$$$/ 		\n' +
    '$$ |__    $$$  \\$$ |$$ |__$$ |      $$ |/$  \\$$ |      $$ $$ \\__         $$ |  	\n' +
    '$$    |   $$$$  $$ |$$    $$<       $$ /$$$  $$ |      /$$$     |        $$ |  		\n' +
    '$$$$$/    $$ $$ $$ |$$$$$$$  |      $$ $$/$$ $$ |      $$ $$ $$/         $$ |  		\n' +
    '$$ |      $$ |$$$$ |$$ |__$$ |      $$$$/  $$$$ |      $$ \\$$  \\        _$$ |_ 		\n' +
    '$$ |      $$ | $$$ |$$    $$/       $$$/    $$$ |      $$   $$  |      / $$   |		\n' +
    '$$/       $$/   $$/ $$$$$$$/        $$/      $$/        $$$$/$$/       $$$$$$/ 		\n';

const welcomeRMB =
    '%c[FNB WI]\n' +
    ' _______   __       __  _______         __       __         ___            ______ 		\n' +
    '/       \\ /  \\     /  |/       \\       /  |  _  /  |       /   \\          /      |	\n' +
    '$$$$$$$  |$$  \\   /$$ |$$$$$$$  |      $$ | / \\ $$ |      /$$$  |         $$$$$$/ 	\n' +
    '$$ |__$$ |$$$  \\ /$$$ |$$ |__$$ |      $$ |/$  \\$$ |      $$ $$ \\__         $$ |  	\n' +
    '$$    $$< $$$$  /$$$$ |$$    $$<       $$ /$$$  $$ |      /$$$     |        $$ |  		\n' +
    '$$$$$$$  |$$ $$ $$/$$ |$$$$$$$  |      $$ $$/$$ $$ |      $$ $$ $$/         $$ |  		\n' +
    '$$ |  $$ |$$ |$$$/ $$ |$$ |__$$ |      $$$$/  $$$$ |      $$ \\$$  \\        _$$ |_ 	\n' +
    '$$ |  $$ |$$ | $/  $$ |$$    $$/       $$$/    $$$ |      $$   $$  |      / $$   |		\n' +
    '$$/   $$/ $$/      $$/ $$$$$$$/        $$/      $$/        $$$$/$$/       $$$$$$/ 		\n';

// tslint:enable:max-line-length

export interface Logger {
    debug(...args: any[]);
    info(...args: any[]);
    warn(...args: any[]);
    error(...args: any[]);
    http(...args: any[]);
}

export enum LogLevel {
    http = 1,
    debug = 1,
    info = 2,
    warn = 3,
    error = 4,
}

const levelLabel: Map<LogLevel, string> = new Map([
    [LogLevel.debug, 'DEBUG'],
    [LogLevel.info, 'INFO '],
    [LogLevel.warn, 'WARN '],
    [LogLevel.error, 'ERROR'],
]);

const levelFormats: Map<LogLevel, string> = new Map([
    [LogLevel.debug, 'color: black'],
    [LogLevel.error, 'color: red; font-weight:bold'],
    [LogLevel.warn, 'color: orange'],
    [LogLevel.info, 'color: blue'],
]);

@Injectable()
export class LoggerService {
    private logLevel;

    constructor() {
        this.getConsoleLogger(LogLevel.info)(`Log level=${appConfig.logLevel}`);
        this.logLevel = LogLevel[appConfig.logLevel];
    }

    public getLogger(tag): Logger {
        let formattedTag = '[' + tag + ']';
        while (formattedTag.length < 25) {
            formattedTag += ' ';
        }
        return {
            debug: this.wrapFunction(LogLevel.debug, formattedTag),
            http: this.wrapFunction(LogLevel.debug, formattedTag),
            info: this.wrapFunction(LogLevel.info, formattedTag),
            warn: this.wrapFunction(LogLevel.warn, formattedTag),
            error: this.wrapFunction(LogLevel.error, formattedTag, true),
        };
    }

    public welcome(brand: Brand) {
        const message = brand === Brand.RMB ? welcomeRMB : welcomeFNB;
        this.getConsoleLogger(LogLevel.debug)(message);
    }

    private wrapFunction(level: LogLevel, tag: string, reportToServer: boolean = false) {
        if (level >= this.logLevel) {
            const fn = this.getConsoleLogger(level);
            // tslint:disable-next-line:only-arrow-functions
            return function() {
                let output = [].slice
                    .call(arguments)
                    .map(arg => {
                        try {
                            if (typeof arg === 'object') {
                                return `\n${JSON.stringify(arg, null, 2)}`;
                            }
                        } catch (err) {}
                        if (!arg) {
                            return '';
                        }
                        return arg.toString();
                    })
                    .join(' ');
                output = '%c[FNB WI]%c[' + levelLabel.get(level) + '][' + moment().format('HH:mm:ss.SSS') + '] ' + tag + output;
                const args = [output, 'color:#00796b', levelFormats.get(level)];
                try {
                    fn.apply(console, args);
                    if (reportToServer) {
                        bugsnagClient.notify(new Error(output));
                    }
                } catch (err) {
                    // Do nothing here, this is to handle ie being silly
                }
            };
        }
        // tslint:disable-next-line:no-empty
        return msg => {};
    }

    private isInternetExplorer() {
        return (
            navigator.appName === 'Microsoft Internet Explorer' ||
            !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))
        );
    }

    private getConsoleLogger(logLevel: LogLevel) {
        if (this.isInternetExplorer()) {
            // tslint:disable-next-line:no-empty
            return msg => {};
        }
        const windowConsole: Console = window.console || ({} as Console);
        // tslint:disable-next-line:no-empty
        return windowConsole[LogLevel[logLevel]] || windowConsole.log || (msg => {});
    }
}

interface Console {
    log();
}
