import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { applicationRoutes } from 'application/application-routing.module';
import { CustomerSearchComponent } from 'banker/components';
import { roboRoutes } from 'robo/robo-routing.module';
import { BankerGuard } from 'shared/services';

export const bankerRoutes: Routes = [
    { path: '', redirectTo: 'customer-search', pathMatch: 'full' },
    { path: 'customer-search', component: CustomerSearchComponent, canActivate: [BankerGuard] },
    { path: 'pst', redirectTo: 'robo', pathMatch: 'full' },
    { path: 'robo', children: roboRoutes },
    { path: 'application', children: applicationRoutes },
];

@NgModule({
    imports: [RouterModule.forChild(bankerRoutes)],
    exports: [RouterModule],
})
export class BankerRoutingModule {}
