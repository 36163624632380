import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { PstReference, PstResult, UserSelection } from 'pst/models';
import { Fund } from 'shared/models';

export interface PstState {
    pstTree: PstReference;
    inProgressPst: PstResult;
    completedPst: PstResult;
    advisedPst: PstResult;
    selectedFund: Fund;
    pstError: Error;
    userPstSelection: UserSelection;
    enterPressed: boolean;
}

const selectPstState = createFeatureSelector<PstState>('pst');
export const selectPstTree = createSelector(selectPstState, (state: PstState) => state.pstTree);
export const selectInProgressPst = createSelector(selectPstState, (state: PstState) => state.inProgressPst);
export const selectCompletedPst = createSelector(selectPstState, (state: PstState) => state.completedPst);
export const selectAdvisedPst = createSelector(selectPstState, (state: PstState) => state.advisedPst);
export const selectSelectedFund = createSelector(selectPstState, (state: PstState) => state.selectedFund);
export const selectPstError = createSelector(selectPstState, (state: PstState) => state.pstError);
export const selectUserPstSelection = createSelector(selectPstState, (state: PstState) => state.userPstSelection);
export const selectEnterPressed = createSelector(selectPstState, (state: PstState) => state.enterPressed);
