// tslint:disable:max-classes-per-file
import { ROUTER_CANCEL, ROUTER_NAVIGATION, RouterCancelAction, RouterNavigationAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { InvestorHelper, MenuToggle } from 'shared/models';

export const HIDE_MENU = '[Layout] Hide Menu';
export class HideMenuAction implements Action {
    public readonly type = HIDE_MENU;

    constructor(public payload: string) {}
}

export const HIDE_MENUS = '[Layout] Hide Menus';
export class HideMenusAction implements Action {
    public readonly type = HIDE_MENUS;

    constructor(public payload: string[]) {}
}

export const SHOW_MENU = '[Layout] Show Menu';
export class ShowMenuAction implements Action {
    public readonly type = SHOW_MENU;

    constructor(public payload: string) {}
}

export const SHOW_MENUS = '[Layout] Show Menus';
export class ShowMenusAction implements Action {
    public readonly type = SHOW_MENUS;

    constructor(public payload: string[]) {}
}

export const TOGGLE_VISIBLE = '[Investor Helper] Toggle Panel';
export class InvestorHelperToggleAction implements Action {
    public readonly type = TOGGLE_VISIBLE;
}

export const TOGGLE_HIDE = '[Investor Helper] Hide Panel';
export class InvestorHelperHideAction implements Action {
    public readonly type = TOGGLE_HIDE;
}

export const TOGGLE_HELPER_BUTTON = '[Investor Helper] Toggle Button';
export class InvestorHelperToggleButtonAction implements Action {
    public readonly type = TOGGLE_HELPER_BUTTON;
    constructor(public payload: boolean) {}
}

export const NAVIGATION = '[Navigation] Started';
export class NavigationAction implements Action {
    public readonly type = NAVIGATION;
    constructor(public payload: string) {}
}

export const SHOW_SMALL_HEADER = '[Layout] Show Small Header';
export class ShowSmallHeaderAction implements Action {
    public readonly type = SHOW_SMALL_HEADER;

    constructor() {}
}

export const HIDE_SMALL_HEADER = '[Layout] Hide Small Header';
export class HideSmallHeaderAction implements Action {
    public readonly type = HIDE_SMALL_HEADER;

    constructor() {}
}

export const SHOW_TRANSDPARENT_HEADER = '[Layout] Show Transparent Header';
export class ShowTransparentHeaderAction implements Action {
    public readonly type = SHOW_TRANSDPARENT_HEADER;

    constructor() {}
}

export const HIDE_TRANSDPARENT_HEADER = '[Layout] Hide Transparent Header';
export class HideTransparentHeaderAction implements Action {
    public readonly type = HIDE_TRANSDPARENT_HEADER;

    constructor() {}
}

export const ENABLE_FIXED_FOOTER = '[Layout] Enable Fixed Footer';
export class EnableFixedFooterAction implements Action {
    public readonly type = ENABLE_FIXED_FOOTER;
    constructor(public payload: boolean) {}
}

export type LayoutAction =
    | HideMenuAction
    | HideMenusAction
    | ShowMenuAction
    | ShowMenusAction
    | InvestorHelperHideAction
    | InvestorHelperToggleAction
    | InvestorHelperToggleButtonAction
    | NavigationAction
    | ShowSmallHeaderAction
    | HideSmallHeaderAction
    | ShowTransparentHeaderAction
    | HideTransparentHeaderAction
    | EnableFixedFooterAction;

const FULL_WIDTH_ROUTES = ['/invest', '/invest?showFunds=true', '/invest/contact-us', '/invest/about-us', '/invest?openLeadForm=true'];

export function showExpandedFooterReducer(
    state: boolean = false,
    action: RouterNavigationAction | RouterCancelAction<any> | NavigationAction
) {
    switch (action.type) {
        case ROUTER_CANCEL: {
            if (!action.payload || !action.payload.routerState) {
                return state;
            }
            return action.payload.routerState.url.startsWith('/banker-login');
        }
        case ROUTER_NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(route => route.startsWith(action.payload.routerState.url)).length > 0;
        }
        case NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(route => route.startsWith(action.payload)).length > 0;
        }
        default: {
            return state;
        }
    }
}

export function showFixedFooterReducer(state: boolean = false, action: EnableFixedFooterAction) {
    switch (action.type) {
        case ENABLE_FIXED_FOOTER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function showFullWidthReducer(state: boolean = false, action: RouterNavigationAction | RouterCancelAction<any> | NavigationAction) {
    switch (action.type) {
        case ROUTER_CANCEL: {
            if (!action.payload || !action.payload.routerState) {
                return state;
            }
            return action.payload.routerState.url.startsWith('/banker-login');
        }
        case ROUTER_NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(route => route.startsWith(action.payload.routerState.url)).length > 0;
        }
        case NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(route => route.startsWith(action.payload)).length > 0;
        }
        default: {
            return state;
        }
    }
}

const MENU_HIDDEN = false;
const MENU_VISIBLE = true;

export function menusReducer(state: MenuToggle[] = [], action: LayoutAction) {
    switch (action.type) {
        case HIDE_MENU: {
            if (action.payload === '*') {
                return toggleAllMenus(state, MENU_HIDDEN);
            }
            return toggleMenu(state, action.payload, MENU_HIDDEN);
        }
        case HIDE_MENUS: {
            let newState = [...state];
            for (const menuToToggle of action.payload) {
                newState = toggleMenu(newState, menuToToggle, MENU_HIDDEN);
            }
            return newState;
        }
        case SHOW_MENU: {
            if (action.payload === '*') {
                return toggleAllMenus(state, MENU_VISIBLE);
            }
            return toggleMenu(state, action.payload, MENU_VISIBLE);
        }
        case SHOW_MENUS: {
            let newState = [...state];
            for (const menuToToggle of action.payload) {
                newState = toggleMenu(newState, menuToToggle, MENU_VISIBLE);
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}

function toggleAllMenus(menus: MenuToggle[], visible: boolean) {
    return menus.map(m => {
        return {
            ...m,
            visible: false,
        };
    });
}

function toggleMenu(existingState: MenuToggle[], menuToToggle: string, visible: boolean) {
    const menuNotYetInState = existingState.filter(m => m.name === menuToToggle).length === 0;

    if (menuNotYetInState) {
        return [...existingState, { name: menuToToggle, visible }];
    }

    return existingState.map((menu, index) => {
        if (menu.name !== menuToToggle) {
            return menu;
        }
        return {
            ...menu,
            visible,
        };
    });
}

export function toggleInvestorHelperReducer(
    state: InvestorHelper = { isVisible: false, isActionButtonVisible: true },
    action: LayoutAction
) {
    switch (action.type) {
        case TOGGLE_HIDE: {
            return Object.assign({}, state, { isVisible: false });
        }
        case TOGGLE_VISIBLE: {
            const isHelperVisible = state ? state.isVisible : false;
            return Object.assign({}, state, { isVisible: !isHelperVisible });
        }
        case TOGGLE_HELPER_BUTTON: {
            return Object.assign({}, state, { isActionButtonVisible: action.payload });
        }
        default: {
            return state;
        }
    }
}

type SmallHeaderAction = ShowSmallHeaderAction | HideSmallHeaderAction;

export function showSmallHeaderReducer(state: boolean = false, action: SmallHeaderAction) {
    switch (action.type) {
        case SHOW_SMALL_HEADER: {
            return true;
        }
        case HIDE_SMALL_HEADER: {
            return false;
        }
        default: {
            return state;
        }
    }
}

type TransparentHeaderAction = ShowTransparentHeaderAction | HideTransparentHeaderAction;

export function showTransparentHeaderReducer(state: boolean = false, action: TransparentHeaderAction) {
    switch (action.type) {
        case SHOW_TRANSDPARENT_HEADER: {
            return true;
        }
        case HIDE_TRANSDPARENT_HEADER: {
            return false;
        }
        default: {
            return state;
        }
    }
}
