import { Component } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'shared/services/authentication.service';
import { SharedState } from 'shared/store/shared.store';
import { LogoutRequestAction, SessionLifeSupportAction } from 'shared/store/user.reducers';

@Component({
    selector: 'wim-session-expiring-modal',
    templateUrl: 'session-expiring-modal.component.pug',
})
export class SessionExpiringModalComponent implements OnDestroy {
    public timeLeft = 60;
    private interval;

    constructor(
        public activeModal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private store: Store<SharedState>
    ) {
        this.store.dispatch(new SessionLifeSupportAction(false));
        this.interval = setInterval(() => {
            this.timeLeft--;
            if (this.timeLeft <= 0) {
                this.closeAfterTimeout();
            }
        }, 1000);
    }

    public renewSession() {
        this.authenticationService.extendSession();
        this.activeModal.close();
    }

    private closeAfterTimeout() {
        this.store.dispatch(new SessionLifeSupportAction(true));
        this.cancelInterval();
        this.activeModal.close();
    }

    public logout() {
        this.store.dispatch(new LogoutRequestAction());
        this.cancelInterval();
        this.activeModal.close();
    }

    public ngOnDestroy() {
        this.cancelInterval();
    }

    private cancelInterval() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
