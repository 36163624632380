import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

import { DateFormatterService } from '../services';

export function dateMinValidator(minimumDate: Date, dateFormatter: DateFormatterService): ValidatorFn {
    const min = minimumDate ? moment(minimumDate) : null;

    return (control: AbstractControl): { [key: string]: any } => {
        let date: moment.Moment;
        let value = control.value;
        if (value && typeof value === 'object') {
            date = dateFormatter.asMoment(value);
        } else {
            date = moment(value);
        }
        if (!date.isValid()) {
            return { required: true };
        }

        const afterMin = min === null || date.isSameOrAfter(min);

        return afterMin ? null : { datemin: afterMin ? null : min.format('YYYY-MM-DD') };
    };
}
