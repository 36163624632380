var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { InvestmentAccountCalculator, PstResultType, PstService, PstTfsaValidator } from 'pst/services';
import { FundSelectedAction, PstAdviceShownAction, PstCompletedAction, PstScreenValidAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent, EacModalComponent } from 'shared/components';
import { LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { EnableFixedFooterAction, LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { bigNumberMaxValidator, bigNumberMinIfNotZeroValidator, bigNumberMinValidator } from 'shared/validators';
import { PstContentModalComponent } from '../content-modal/pst-content-modal.component';
var OVER_UNDER_LEEWAY = 100;
var InvestmentAccountResultComponent = /** @class */ (function (_super) {
    __extends(InvestmentAccountResultComponent, _super);
    function InvestmentAccountResultComponent(loggerService, pstStore, sharedStore, pstService, router, lookupService, pstCalculator, modalService, formBuilder, numberFormatter, tfsaValidation) {
        var _this = _super.call(this, loggerService) || this;
        _this.pstStore = pstStore;
        _this.sharedStore = sharedStore;
        _this.pstService = pstService;
        _this.router = router;
        _this.lookupService = lookupService;
        _this.pstCalculator = pstCalculator;
        _this.modalService = modalService;
        _this.formBuilder = formBuilder;
        _this.numberFormatter = numberFormatter;
        _this.tfsaValidation = tfsaValidation;
        _this.minDebitOrder = 500;
        _this.maxDebitOrder = 100000;
        _this.minLumpSum = 5000;
        _this.maxLumpSum = 1000000;
        _this.minIncrease = 0;
        _this.maxIncrease = 15;
        _this.products = new Map();
        _this.overUnder = 1102846;
        _this.overGoal = true;
        _this.underGoal = false;
        _this.hitGoal = false;
        _this.tfsaEligible = false;
        _this.tfsaSelected = false;
        _this.tfsaApplicable = true;
        _this.tfsaDeclined = false;
        _this.showEscalation = true;
        _this.isJustInvest = false;
        _this.isDirect = false;
        _this.costs = 0;
        _this.originalPstResult = null;
        _this.recalculateTimer = null;
        _this.content = [];
        return _this;
    }
    InvestmentAccountResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.sharedStore.dispatch(new EnableFixedFooterAction(true));
        var funds$ = this.lookupService.getHorizonFunds();
        var pstResult$ = this.pstStore.select(fromPst.selectInProgressPst);
        var pstTree$ = this.pstStore.select(fromPst.selectPstTree);
        var vatRate$ = this.lookupService.getVATRate();
        var products$ = this.lookupService.getPortfolioTypes();
        var isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        var content$ = this.lookupService.getPstResultContent();
        // rxJs and TypeScript have some limitations, so we need to map the results in a different way
        // https://github.com/ReactiveX/rxjs/issues/3601
        var subscription = combineLatest(funds$, pstResult$, pstTree$, vatRate$, products$, isDirect$, content$)
            .pipe(this.scavenger.collect(), map(function (_a) {
            var funds = _a[0], pstResult = _a[1], pstTree = _a[2], vatRate = _a[3], products = _a[4], isDirect = _a[5], content = _a[6];
            return ({
                funds: funds,
                pstResult: pstResult,
                pstTree: pstTree,
                vatRate: vatRate,
                products: products,
                isDirect: isDirect,
                content: content,
            });
        }))
            .subscribe(function (_a) {
            var funds = _a.funds, pstResult = _a.pstResult, pstTree = _a.pstTree, vatRate = _a.vatRate, products = _a.products, isDirect = _a.isDirect, content = _a.content;
            if (!pstResult) {
                _this.sharedStore.dispatch(new StopLoadingAction());
                _this.router.navigateByUrl('/secure/banker/customer-search');
                return;
            }
            // Empty pstResult, pst is not yet completed. Happens when restarting PST
            if (Object.keys(pstResult.capturedValues).length === 0) {
                return;
            }
            if (!_this.originalPstResult) {
                InvestmentAccountCalculator.extractCapturedValues(pstResult);
                _this.originalPstResult = Object.assign({}, pstResult);
                _this.pstStore.dispatch(new PstAdviceShownAction(_this.originalPstResult));
            }
            if (!_this.resultType) {
                if (pstResult.goalAmount === null || pstResult.goalAmount === 0) {
                    _this.logger.debug('User selected Just Invest during PST');
                    _this.resultType = PstResultType.FutureValue;
                }
                else {
                    _this.logger.debug('User selected a goal during PST');
                    _this.resultType = PstResultType.Installment;
                }
            }
            pstResult.fundCode = pstResult.fundCode || pstTree.node.nodeDescription;
            pstResult.reference = pstResult.reference || pstTree.transactionref;
            products.forEach(function (product) {
                _this.products.set(product.id, product);
            });
            _this.isDirect = isDirect;
            _this.content = content;
            _this.product = _this.products.get(appConfig.portfolioTypes.INV);
            _this.fund = funds.filter(function (fund) { return fund.pstCode === pstResult.fundCode; })[0];
            _this.pstStore.dispatch(new FundSelectedAction(_this.fund));
            _this.costs = _this.fund.adviceFee * (1 + vatRate);
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.recalculate(pstResult);
            _this.createForm();
            _this.pstStore.dispatch(new PstScreenValidAction(_this.calculationForm.valid));
        });
    };
    InvestmentAccountResultComponent.prototype.isContent = function (id) {
        return !!this.content.find(function (c) { return c.id === id; });
    };
    InvestmentAccountResultComponent.prototype.displayContentPopup = function (id) {
        if (!this.isContent(id)) {
            return;
        }
        var content = this.content.find(function (c) { return c.id === id; });
        var modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    };
    InvestmentAccountResultComponent.prototype.displayProjectionContentPopup = function (id) {
        if (!this.isContent(id)) {
            return;
        }
        var content = Object.assign('', this.content.find(function (c) { return c.id === id; }));
        var bestCase = this.transformBigNumberRand(this.calculation.bestCase).trim();
        var worstCase = this.transformBigNumberRand(this.calculation.worstCase).trim();
        var recommended = this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution).trim();
        var original = this.transformBigNumberRand(this.calculation.originalMonthlyContribution).trim();
        content.content = content.content.replace('{{UP_SCENARIO}}', bestCase === 'R' ? 'R 0' : bestCase);
        content.content = content.content.replace('{{DOWN_SCENARIO}}', worstCase === 'R' ? 'R 0' : worstCase);
        content.content = content.content.replace('{{RECOMMENDED}}', recommended === 'R' ? 'R 0' : recommended);
        content.content = content.content.replace('{{ORIGINAL}}', original === 'R' ? 'R 0' : original);
        var modalRef = this.modalService.open(PstContentModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.content = content.content;
    };
    InvestmentAccountResultComponent.prototype.displayFundContentPopup = function () {
        var id = 0;
        switch (this.fund.instrumentId) {
            case 7:
                id = 4;
                break;
            case 6:
                id = 5;
                break;
            case 3:
                id = 6;
                break;
            case 5:
                id = 7;
                break;
        }
        this.displayContentPopup(id);
    };
    InvestmentAccountResultComponent.prototype.fixGoal = function () {
        this.calculationForm.get('goalAmount').setValue(this.transformBigNumberRand(this.calculation.estimatedFutureValue));
    };
    InvestmentAccountResultComponent.prototype.fixInstallment = function () {
        this.calculationForm.get('debitOrder').setValue(this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution));
    };
    InvestmentAccountResultComponent.prototype.recalculate = function (pstResult) {
        if (this.tfsaSelected) {
            pstResult.contributionIncrease = 0;
        }
        var calculation = this.pstCalculator.calculate(pstResult, this.costs, this.resultType);
        this.isJustInvest = this.resultType === PstResultType.FutureValue;
        this.calculation = calculation;
        this.chart = this.calculation.graph;
        pstResult.noOtherTfsaConfirmed = this.tfsaEligible ? true : null;
        this.pstStore.dispatch(new PstCompletedAction(pstResult));
        this.overUnder = Math.abs(pstResult.goalAmount - calculation.estimatedFutureValue);
        this.hitGoal = this.overUnder <= OVER_UNDER_LEEWAY;
        if (this.hitGoal) {
            this.underGoal = false;
            this.overGoal = false;
        }
        else {
            this.underGoal = calculation.estimatedFutureValue < pstResult.goalAmount;
            this.overGoal = !this.underGoal;
        }
    };
    InvestmentAccountResultComponent.prototype.recalculateWithDebounce = function (pstResult) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.recalculateTimer) {
                clearTimeout(_this.recalculateTimer || _this.recalculateTimer._id);
            }
            _this.recalculateTimer = setTimeout(function () {
                _this.recalculate(pstResult);
                _this.calculateTfsaViability();
                resolve();
            }, 500);
        });
    };
    InvestmentAccountResultComponent.prototype.openEacModal = function () {
        var modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = { funds: [{ fundCode: this.fund.pstCode, allocation: 1 }] };
    };
    InvestmentAccountResultComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
        this.sharedStore.dispatch(new EnableFixedFooterAction(false));
    };
    InvestmentAccountResultComponent.prototype.restart = function () {
        this.cleanUpSubscriptions();
        this.pstService.startNewTree();
    };
    InvestmentAccountResultComponent.prototype.transformBigNumberRand = function (value) {
        var _this = this;
        return this.numberFormatter.transformNumberTextPrepend(value, null, 'R ', function (val) {
            return _this.numberFormatter.transformBigNumber(val, null, true);
        });
    };
    InvestmentAccountResultComponent.prototype.transformTerm = function (value) {
        return this.numberFormatter.transformTerm(value);
    };
    InvestmentAccountResultComponent.prototype.transformPercent = function (value) {
        return this.numberFormatter.transformPercent("" + +value * 100, '1.0-2');
    };
    InvestmentAccountResultComponent.prototype.parseBigNumber = function (bigNumber) {
        return this.numberFormatter.parseNumberTextPrepend(bigNumber, 'R ', this.numberFormatter.parseBigNumber);
    };
    InvestmentAccountResultComponent.prototype.parsePercent = function (bigNumber) {
        return this.numberFormatter.parseNumberTextAppend(bigNumber, '%', this.numberFormatter.parsePercent);
    };
    InvestmentAccountResultComponent.prototype.hasRandValueChanged = function (originalValue, newValue) {
        var rawNewValue = newValue;
        if (rawNewValue.indexOf('R') >= 0) {
            rawNewValue = this.parseBigNumber(rawNewValue);
        }
        return +rawNewValue !== +originalValue;
    };
    InvestmentAccountResultComponent.prototype.hasPercentValueChanged = function (originalValue, newValue) {
        var rawNewValue = newValue;
        if (rawNewValue.indexOf('%') >= 0) {
            rawNewValue = +this.numberFormatter.parsePercent(rawNewValue) / 100;
        }
        else {
            rawNewValue /= 100;
        }
        return +rawNewValue !== +originalValue;
    };
    InvestmentAccountResultComponent.prototype.validateLumpsumAndDebitOrder = function (lumpsum, debitOrder) {
        var lumpsumControl = this.calculationForm.controls.lumpSum;
        var debitOrderControl = this.calculationForm.controls.debitOrder;
        if (+this.parseBigNumber(lumpsum) === 0 && +this.parseBigNumber(debitOrder) === 0) {
            var errors = {
                bothZero: true,
            };
            lumpsumControl.setErrors(errors);
            debitOrderControl.setErrors(errors);
        }
        else {
            if (lumpsumControl.errors && Object.keys(lumpsumControl.errors).length === 1 && lumpsumControl.hasError('bothZero')) {
                lumpsumControl.setErrors(null);
            }
            if (debitOrderControl.errors && Object.keys(debitOrderControl.errors).length === 1 && debitOrderControl.hasError('bothZero')) {
                debitOrderControl.setErrors(null);
            }
        }
    };
    InvestmentAccountResultComponent.prototype.createForm = function () {
        var _this = this;
        this.calculationForm = this.formBuilder.group({
            goalAmount: [
                this.transformBigNumberRand(this.calculation.pstResult.goalAmount),
                Validators.compose([Validators.required, bigNumberMinValidator(0, 'R')]),
            ],
            lumpSum: [
                this.transformBigNumberRand(this.calculation.pstResult.lumpSum),
                Validators.compose([bigNumberMinIfNotZeroValidator(this.minLumpSum, 'R'), bigNumberMaxValidator(this.maxLumpSum, 'R')]),
            ],
            debitOrder: [
                this.transformBigNumberRand(this.calculation.originalMonthlyContribution),
                Validators.compose([
                    bigNumberMinIfNotZeroValidator(this.minDebitOrder, 'R'),
                    bigNumberMaxValidator(this.maxDebitOrder, 'R'),
                ]),
            ],
            annualIncrease: [
                this.transformPercent(this.calculation.pstResult.contributionIncrease),
                Validators.compose([
                    bigNumberMinValidator(this.minIncrease, '%'),
                    bigNumberMaxValidator(this.maxIncrease, '%'),
                    Validators.min(this.minIncrease),
                    Validators.pattern('^[0-9]+[%]*$'),
                ]),
            ],
            // These are disabled as they cannot be edit directly by the user
            lumpSumDisabled: [this.transformBigNumberRand(this.calculation.pstResult.lumpSum)],
            futureValue: [this.transformBigNumberRand(this.calculation.estimatedFutureValue)],
            term: [this.transformTerm(this.calculation.pstResult.term)],
            goalName: [this.calculation.pstResult.goalName],
            calculatedMonthlyContribution: [this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution)],
            investTaxFree: [false],
        });
        this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
        this.tfsaApplicable = this.tfsaValidation.tfsaTermValidate(this.calculation.pstResult.term);
        this.calculationForm.valueChanges.subscribe(function (changes) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.checkForChanges(changes)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); });
        this.calculateTfsaViability();
    };
    InvestmentAccountResultComponent.prototype.checkForChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.hasRandValueChanged(this.calculation.pstResult.goalAmount, changes.goalAmount)) return [3 /*break*/, 2];
                        this.calculation.pstResult.goalAmount = +this.parseBigNumber(changes.goalAmount);
                        return [4 /*yield*/, this.recalculateInstallment()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.hasRandValueChanged(this.calculation.pstResult.lumpSum, changes.lumpSum)) return [3 /*break*/, 5];
                        this.calculation.pstResult.lumpSum = +this.parseBigNumber(changes.lumpSum);
                        this.calculationForm.get('lumpSumDisabled').setValue(this.transformBigNumberRand(changes.lumpSum), { emitEvent: false });
                        return [4 /*yield*/, this.recalculateInstallment()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.recalculateFutureValue(true)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        if (!this.hasRandValueChanged(this.calculation.pstResult.clientInstallment, changes.debitOrder)) return [3 /*break*/, 7];
                        this.calculation.pstResult.clientInstallment = +this.parseBigNumber(changes.debitOrder);
                        return [4 /*yield*/, this.recalculateFutureValue()];
                    case 6:
                        _a.sent();
                        _a.label = 7;
                    case 7:
                        if (!this.hasPercentValueChanged(this.calculation.pstResult.contributionIncrease, changes.annualIncrease)) return [3 /*break*/, 10];
                        this.calculation.pstResult.contributionIncrease = +this.parsePercent(changes.annualIncrease) / 100;
                        return [4 /*yield*/, this.recalculateInstallment()];
                    case 8:
                        _a.sent();
                        return [4 /*yield*/, this.recalculateFutureValue(true)];
                    case 9:
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        if (changes.investTaxFree !== this.tfsaSelected) {
                            this.tfsaSelected = changes.investTaxFree;
                            if (changes.investTaxFree) {
                                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.TFDS;
                                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                            }
                            else {
                                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
                                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                                this.calculation.pstResult.contributionIncrease =
                                    +this.parsePercent(this.calculationForm.get('annualIncrease').value) / 100;
                            }
                            this.recalculate(this.calculation.pstResult);
                        }
                        this.validateLumpsumAndDebitOrder(changes.lumpSum, changes.debitOrder);
                        this.pstStore.dispatch(new PstScreenValidAction(this.calculationForm.valid));
                        return [2 /*return*/];
                }
            });
        });
    };
    InvestmentAccountResultComponent.prototype.recalculateInstallment = function (usePreviousCalculation) {
        if (usePreviousCalculation === void 0) { usePreviousCalculation = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!usePreviousCalculation) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.recalculateWithDebounce(this.calculation.pstResult)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.calculationForm
                            .get('calculatedMonthlyContribution')
                            .setValue(this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution), { emitEvent: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    InvestmentAccountResultComponent.prototype.recalculateFutureValue = function (usePreviousCalculation) {
        if (usePreviousCalculation === void 0) { usePreviousCalculation = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!usePreviousCalculation) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.recalculateWithDebounce(this.calculation.pstResult)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.calculationForm
                            .get('futureValue')
                            .setValue(this.transformBigNumberRand(this.calculation.estimatedFutureValue), { emitEvent: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    InvestmentAccountResultComponent.prototype.confrimTFSAinterest = function () {
        if (this.tfsaEligible) {
            this.tfsaEligible = false;
            if (this.tfsaSelected) {
                this.tfsaSelected = false;
                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                this.calculation.pstResult.contributionIncrease =
                    +this.parsePercent(this.calculationForm.get('annualIncrease').value) / 100;
                this.recalculate(this.calculation.pstResult);
            }
        }
        else {
            this.tfsaEligible = true;
        }
    };
    InvestmentAccountResultComponent.prototype.denyTFSAinterest = function () {
        this.tfsaDeclined = true;
    };
    InvestmentAccountResultComponent.prototype.changeTFSAinterest = function () {
        this.tfsaDeclined = false;
        if (this.tfsaEligible) {
            this.tfsaEligible = false;
            if (this.tfsaSelected) {
                this.tfsaSelected = false;
                this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
                this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
                this.calculation.pstResult.contributionIncrease =
                    +this.parsePercent(this.calculationForm.get('annualIncrease').value) / 100;
                this.recalculate(this.calculation.pstResult);
            }
        }
        else {
            this.tfsaEligible = false;
        }
    };
    InvestmentAccountResultComponent.prototype.calculateTfsaViability = function () {
        this.tfsaApplicable = this.tfsaValidation.validatePstForTfsa(this.calculation.pstResult.goalAmount, this.calculation.pstResult.lumpSum, this.calculation.originalMonthlyContribution, this.calculation.pstResult.term, this.isDirect);
        if (!this.tfsaApplicable && this.tfsaSelected) {
            this.tfsaSelected = false;
            this.calculation.pstResult.portfolioTypeId = appConfig.portfolioTypes.INV;
            this.product = this.products.get(this.calculation.pstResult.portfolioTypeId);
        }
    };
    return InvestmentAccountResultComponent;
}(BaseComponent));
export { InvestmentAccountResultComponent };
