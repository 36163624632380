/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pst-navigation.component";
import * as i3 from "../../services/pst.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../shared/services/logger.service";
import * as i8 from "ngx-toastr";
import * as i9 from "../../../shared/store/layout.effects";
var styles_PstNavigationComponent = [];
var RenderType_PstNavigationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PstNavigationComponent, data: {} });
export { RenderType_PstNavigationComponent as RenderType_PstNavigationComponent };
function View_PstNavigationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "row show-grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "col-md-2 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-4 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-4 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Try Again"])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "col-md-2 mb-3"]], null, null, null, null, null))], null, null); }
function View_PstNavigationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "row show-grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-4 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-4 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.restart() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Restart"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-4 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor-sec btn-rounded waves-effect waves-light"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userSelection.formInvalid; _ck(_v, 8, 0, currVal_0); }); }
function View_PstNavigationComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-3 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Save for later"]))], null, null); }
function View_PstNavigationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "row show-grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "mb-3"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor btn-rounded waves-effect waves-light float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.restart() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Restart"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstNavigationComponent_4)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "col-md-12 btn btn-sm btn-themecolor-sec btn-rounded waves-effect waves-light"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startInvesting() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Start Investing"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isDirect; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isDirect ? "col-md-4" : "col-md-3"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isDirect ? "col-md-4" : "col-md-3"); _ck(_v, 4, 0, currVal_1); var currVal_3 = (_co.isDirect ? "col-md-4" : "col-md-3"); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.userSelection.formInvalid; _ck(_v, 10, 0, currVal_4); }); }
export function View_PstNavigationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "col-md-8 no-gutters mx-auto"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "col-lg-12 m-auto button-group fadeInUp animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "card bg-transparent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PstNavigationComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PstNavigationComponent_2)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_PstNavigationComponent_3)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 6, 0, currVal_0); var currVal_1 = (!i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.onResult$)) && !_co.error); _ck(_v, 8, 0, currVal_1); var currVal_2 = (i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform(_co.onResult$)) && !_co.error); _ck(_v, 11, 0, currVal_2); }, null); }
export function View_PstNavigationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-pst-navigation", [], null, null, null, View_PstNavigationComponent_0, RenderType_PstNavigationComponent)), i0.ɵdid(1, 245760, null, 0, i2.PstNavigationComponent, [i3.PstService, i4.Store, i4.Store, i4.Store, i4.Store, i5.Router, i6.NgbModal, i7.LoggerService, i8.ToastrService, i9.LayoutEffects], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PstNavigationComponentNgFactory = i0.ɵccf("wim-pst-navigation", i2.PstNavigationComponent, View_PstNavigationComponent_Host_0, {}, {}, []);
export { PstNavigationComponentNgFactory as PstNavigationComponentNgFactory };
