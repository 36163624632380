var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstParserService } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { LoggerService, LookupDataService } from 'shared/services';
import { BasePstComponent } from '../base-pst.component';
var TextComponent = /** @class */ (function (_super) {
    __extends(TextComponent, _super);
    function TextComponent(loggerService, modalService, pstStore, pstParser, formBuilder, lookupService) {
        var _this = _super.call(this, loggerService, modalService) || this;
        _this.pstStore = pstStore;
        _this.pstParser = pstParser;
        _this.formBuilder = formBuilder;
        _this.lookupService = lookupService;
        return _this;
    }
    TextComponent.prototype.ngOnInit = function () {
        var _this = this;
        var result$ = this.pstStore.select(fromPst.selectInProgressPst);
        var tree$ = this.pstStore.select(fromPst.selectPstTree);
        var tooltips$ = this.lookupService.getPstTooltips();
        combineLatest(result$, tree$, tooltips$, function (result, tree, tooltips) {
            return { result: result, tree: tree, tooltips: tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var result = _a.result, tree = _a.tree, tooltips = _a.tooltips;
            _this.tree = tree;
            if (!_this.tree) {
                return;
            }
            _super.prototype.initContent.call(_this, null, tooltips, tree.node.nodeId);
            var childNode = tree.childNodes[0];
            _this.pstStore.dispatch(new UserSelectedChildNodeAction(childNode));
            _this.pstStore.dispatch(new PstScreenValidAction(false));
            _this.heading = _this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
            _this.description = tree.node.nodeDescription;
            _this.sliderText = _this.pstParser.buildInputDescription(childNode.nodeDescription);
            _this.createForm();
        });
    };
    TextComponent.prototype.ngOnDestroy = function () { };
    TextComponent.prototype.createForm = function () {
        var _this = this;
        if (!this.inputForm) {
            this.inputForm = this.formBuilder.group({
                value: ['', Validators.required],
            });
            this.inputForm.valueChanges.subscribe(function (changes) {
                _this.pstStore.dispatch(new UserEnteredValueAction(changes.value));
                _this.pstStore.dispatch(new PstScreenValidAction(_this.inputForm.valid));
            });
        }
    };
    return TextComponent;
}(BasePstComponent));
export { TextComponent };
