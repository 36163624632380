import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { InvestmentAccount, InvestorDocument } from 'investor/models';
import { DocumentsService } from 'investor/services';
import { AccountSelectedAction, InvestorState, NewAccountDocumentsListAction } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';

@Component({
    selector: 'wim-account-documents',
    templateUrl: 'account-documents.component.pug',
})
export class AccountDocumentsComponent extends BaseComponent implements OnInit, OnDestroy {
    public documents$: Observable<InvestorDocument[]>;
    public investmentAccounts: InvestmentAccount[] = [];

    public selectedAccount: InvestmentAccount;
    public loadingError = false;
    public downloadingError = false;
    public form: FormGroup;

    constructor(
        private documentService: DocumentsService,
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        loggerService: LoggerService
    ) {
        super(loggerService);
        this.buildForm();
    }

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());
        this.documents$ = this.investorStore.select(fromInvestor.selectInvestorDocuments).pipe(filter(docs => !!docs));
        const documentSubscription = this.documents$.pipe(filter(docs => !!docs)).subscribe(documents => {
            if (documents) {
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        });

        const retrievalErrorSubscription = this.investorStore
            .select(fromInvestor.selectDocumentRetrievalError)
            .pipe(filter(e => !!e))
            .subscribe(() => {
                this.loadingError = true;
                this.sharedStore.dispatch(new StopLoadingAction());
            });

        const combinedSubscription = combineLatest(
            this.investorStore.select(fromInvestor.selectInvestmentAccounts),
            this.route.params,
            (investmentAccounts, routeParams) => {
                return { investmentAccounts, routeParams };
            }
        ).subscribe(({ investmentAccounts, routeParams }) => {
            if (investmentAccounts == null) {
                return;
            }
            this.investmentAccounts = investmentAccounts;
            let selectedAccount = null;
            const accountNumber = routeParams['accountNumber'];
            if (accountNumber) {
                selectedAccount = this.investmentAccounts.filter(acc => acc.accountNumber === accountNumber)[0];
            } else {
                selectedAccount = this.investmentAccounts && this.investmentAccounts[0];
            }
            this.form.setValue({ account: selectedAccount });
        });

        this.registerSubscriptions(documentSubscription, retrievalErrorSubscription, combinedSubscription);
    }

    public ngOnDestroy(): void {
        this.cleanUpSubscriptions();
    }

    public changeAccount(account: InvestmentAccount) {
        this.selectedAccount = account;
        if (account) {
            this.investorStore.dispatch(new AccountSelectedAction(account));
            this.loadForAccount(account.accountNumber);
        } else {
            this.investorStore.dispatch(new NewAccountDocumentsListAction([]));
            this.loadingError = false;
        }
    }

    public download(document: InvestorDocument) {
        this.sharedStore.dispatch(new LoadingAction());
        this.documentService.downloadDocument(document.objectId).subscribe(
            docResponse => {
                const blob = new Blob([docResponse.dataAsBytes], { type: docResponse.contentType });
                const fileNameParts = docResponse.name.split('.');
                const extension = fileNameParts[fileNameParts.length - 1];
                const downloadName = `${this.selectedAccount.accountNumber}_${document.mappedName}.${extension}`;
                FileSaver.saveAs(blob, downloadName);
                this.sharedStore.dispatch(new StopLoadingAction());
            },
            () => {
                this.sharedStore.dispatch(new StopLoadingAction());
                this.downloadingError = true;
            }
        );
    }

    private loadForAccount(accountNumber: string) {
        this.loadingError = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.documentService.fetchAccountDocuments(accountNumber);
    }

    private buildForm() {
        this.form = this.formBuilder.group({
            account: [null],
        });

        this.form.valueChanges.subscribe(changes => {
            this.changeAccount(changes.account);
        });
    }
}
