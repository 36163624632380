import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
    selector: 'wim-warning-modal',
    templateUrl: './shared-modal.component.pug',
})
export class SharedModalComponent {
    @Input() public modalMessage;
    @Input() public noValue;
    @Input() public yesValue;
    @Input() public eventName;
    @Input() public modalTitle;
    constructor(public activeModal: NgbActiveModal) {}
}
