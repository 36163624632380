/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirmation-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_InstructionConfirmationModalComponent = [i0.styles];
var RenderType_InstructionConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstructionConfirmationModalComponent, data: {} });
export { RenderType_InstructionConfirmationModalComponent as RenderType_InstructionConfirmationModalComponent };
function View_InstructionConfirmationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "fa fa-check success"]], null, null, null, null, null))], null, null); }
function View_InstructionConfirmationModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "fa fa-times failure"]], null, null, null, null, null))], null, null); }
function View_InstructionConfirmationModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor-sec waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Try Again"]))], null, null); }
export function View_InstructionConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionConfirmationModalComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionConfirmationModalComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstructionConfirmationModalComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.success; _ck(_v, 9, 0, currVal_1); var currVal_2 = !_co.success; _ck(_v, 11, 0, currVal_2); var currVal_4 = !_co.success; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.message; _ck(_v, 13, 0, currVal_3); }); }
export function View_InstructionConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wim-instruction-confirm-modal", [], null, null, null, View_InstructionConfirmationModalComponent_0, RenderType_InstructionConfirmationModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.InstructionConfirmationModalComponent, [i4.NgbActiveModal], null, null)], null, null); }
var InstructionConfirmationModalComponentNgFactory = i1.ɵccf("wim-instruction-confirm-modal", i3.InstructionConfirmationModalComponent, View_InstructionConfirmationModalComponent_Host_0, {}, {}, []);
export { InstructionConfirmationModalComponentNgFactory as InstructionConfirmationModalComponentNgFactory };
