var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoboFieldId } from 'robo/models';
import { RoboUpdateFieldAction } from 'robo/store';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
var GoalSelectionOtherNameModalComponent = /** @class */ (function (_super) {
    __extends(GoalSelectionOtherNameModalComponent, _super);
    function GoalSelectionOtherNameModalComponent(loggerService, store, formBuilder, activeModal) {
        var _this = _super.call(this, loggerService) || this;
        _this.store = store;
        _this.formBuilder = formBuilder;
        _this.activeModal = activeModal;
        _this.createForm();
        return _this;
    }
    GoalSelectionOtherNameModalComponent.prototype.ngOnDestroy = function () { };
    GoalSelectionOtherNameModalComponent.prototype.submit = function () {
        this.store.dispatch(new RoboUpdateFieldAction({
            fieldId: RoboFieldId.goalName,
            value: this.form.value.goalName,
        }));
        this.activeModal.close();
    };
    GoalSelectionOtherNameModalComponent.prototype.close = function () {
        this.activeModal.dismiss();
    };
    GoalSelectionOtherNameModalComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            goalName: ['', Validators.required],
        });
    };
    return GoalSelectionOtherNameModalComponent;
}(BaseComponent));
export { GoalSelectionOtherNameModalComponent };
