import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccountType } from 'application/models';
import { ApplicationDateService } from 'application/services';
import { AppFormUpdatedBankAccountAction, AppFormUpdatedDebitOrderDetailsAction, BankAccountListLoadedAction, InsufficientFundsAction, InvestmentDetailsValidAction, } from 'application/store/application.reducers';
import * as fromApplication from 'application/store/application.store';
import { CisSearchService } from 'banker/services';
import * as moment from 'moment';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DateFormatterService, LookupDataService, NumberFormatterService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { dateMinValidator } from 'shared/validators';
import { PossibleDebitDates } from '../debit-dates';
var AdvisedOnboardingDetailsComponent = /** @class */ (function () {
    function AdvisedOnboardingDetailsComponent(applicationStore, sharedStore, pstStore, formBuilder, lookupService, numberFormatter, customerService, applicationDateService, dateFormatter) {
        var _this = this;
        this.applicationStore = applicationStore;
        this.sharedStore = sharedStore;
        this.pstStore = pstStore;
        this.formBuilder = formBuilder;
        this.lookupService = lookupService;
        this.numberFormatter = numberFormatter;
        this.customerService = customerService;
        this.applicationDateService = applicationDateService;
        this.dateFormatter = dateFormatter;
        this.lumpSumSelected = false;
        this.debitOrderSelected = false;
        this.annualContributionSelected = false;
        this.isCash = false;
        this.bankAccounts = [];
        this.debitDates = [];
        this.sourceOfFundsTypes = [];
        this.bankAccountsLoadedOnInit = false;
        this.products = new Map();
        this.minLumpSum = 5000;
        this.maxLumpSum = 1000000;
        this.isDirect = false;
        this.insufficientFunds = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.selectedFund = this.pstStore.select(fromPst.selectSelectedFund);
        this.lookupService.getSourceOfFundsTypes().subscribe(function (types) { return (_this.sourceOfFundsTypes = types); });
        var application$ = this.applicationStore.select(fromApplication.selectHorizonApplication).pipe(take(1));
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer).pipe(filter(function (customer) { return !!customer; }));
        var products$ = this.lookupService.getPortfolioTypes();
        var isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        this.subscriptions$ = combineLatest(customer$, application$, products$, isDirect$, function (selectedCustomer, appForm, products, isDirect) {
            return { selectedCustomer: selectedCustomer, appForm: appForm, products: products, isDirect: isDirect };
        }).subscribe(function (_a) {
            var selectedCustomer = _a.selectedCustomer, appForm = _a.appForm, products = _a.products, isDirect = _a.isDirect;
            _this.isDirect = isDirect;
            _this.lumpSumSelected = !!appForm.lumpSumDetails;
            if (!!appForm.debitOrderDetails) {
                if (appForm.debitOrderDetails.paymentFrequencyId === 7) {
                    _this.annualContributionSelected = true;
                }
                else {
                    _this.debitOrderSelected = true;
                }
            }
            _this.appForm = appForm;
            _this.debitDates = PossibleDebitDates.filter(function (date) { return date.forProduct(appForm.portfolioTypeId); });
            _this.customerName = selectedCustomer.title + " " + selectedCustomer.firstname + " " + selectedCustomer.lastname;
            _this.selectedCustomer = selectedCustomer;
            if (!_this.bankAccountsLoadedOnInit) {
                _this.bankAccountsLoadedOnInit = true;
                _this.reloadBankAccounts();
            }
            if (_this.appForm.portfolioTypeId !== appConfig.portfolioTypes.INV &&
                _this.appForm.portfolioTypeId !== appConfig.portfolioTypes.TFDS) {
                products.forEach(function (product) {
                    _this.products.set(product.id, product);
                });
                _this.product = _this.products.get(_this.appForm.portfolioTypeId);
                _this.isCash = true;
            }
            if (!_this.investmentForm) {
                _this.createForm();
            }
        });
    }
    AdvisedOnboardingDetailsComponent.prototype.isSourceOfFundsOther = function (id) {
        var sourceType = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return sourceType && sourceType.isOther;
    };
    AdvisedOnboardingDetailsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions$.unsubscribe();
    };
    AdvisedOnboardingDetailsComponent.prototype.debitDayChange = function () {
        this.investmentForm.get('debitOrder.firstDebitDate').setValue(this.toFormDate(this.calculateFirstDebitDate()));
        this.investmentForm.get('debitOrder.firstEscalationDate').setValue(this.toFormDate(moment(this.calculateFirstDebitDate())
            .add(1, 'years')
            .toDate()));
    };
    AdvisedOnboardingDetailsComponent.prototype.readableAccountType = function (type) {
        return AccountType[type];
    };
    AdvisedOnboardingDetailsComponent.prototype.accountName = function (account) {
        return account.accountName || AccountType[account.accountType];
    };
    AdvisedOnboardingDetailsComponent.prototype.reloadBankAccounts = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.bankAccountLoadingError = false;
        var bankAccounts$ = this.isDirect
            ? this.sharedStore.select(fromShared.selectUserBankAccounts)
            : this.customerService.getBankAccounts(this.selectedCustomer.ucn);
        bankAccounts$.subscribe(function (accounts) {
            _this.bankAccounts = accounts.map(function (acc) {
                acc.accountNumber = "" + +acc.accountNumber;
                return acc;
            });
            _this.applicationStore.dispatch(new BankAccountListLoadedAction(accounts));
            _this.sharedStore.dispatch(new StopLoadingAction());
        }, function () {
            _this.bankAccountLoadingError = true;
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    AdvisedOnboardingDetailsComponent.prototype.calculateFirstDebitDate = function () {
        var userDebitDay = this.investmentForm ? +this.investmentForm.get('debitOrder.debitDate').value : 1;
        return this.applicationDateService.calculateFirstDebitDate(userDebitDay);
    };
    AdvisedOnboardingDetailsComponent.prototype.toFormDate = function (date) {
        return this.dateFormatter.forDatePicker(moment(date));
    };
    AdvisedOnboardingDetailsComponent.prototype.otherDescriptionRequired = function (id) {
        var type = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return type && type.isOther;
    };
    AdvisedOnboardingDetailsComponent.prototype.validateSourceOfFunds = function (abstractForm) {
        var sourceIdControl = abstractForm.get('sourceOfFundsId');
        var descriptionControl = abstractForm.get('otherDescription');
        var required = this.otherDescriptionRequired(+sourceIdControl.value);
        var valid = true;
        if (required) {
            var value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    };
    AdvisedOnboardingDetailsComponent.prototype.validateMaxEscalationDate = function (first, second) {
        var errorControl = this.investmentForm.controls.debitOrder.get('firstEscalationDate');
        var firstDate = first ? moment(first) : null;
        var secondDate = second ? moment(second) : null;
        var valid = firstDate === null || secondDate === null || (!!firstDate && !!secondDate && secondDate.diff(firstDate, 'years', true) <= 1);
        var errorValue = valid ? null : { datemaxfrommin: valid ? null : firstDate.format('YYYY-MM-DD') };
        var afterMin = firstDate === null || secondDate === null || secondDate.isSameOrAfter(firstDate);
        var minErrorValue = afterMin ? null : { datemin: afterMin ? null : firstDate.format('YYYY-MM-DD') };
        if (errorControl) {
            if (errorValue) {
                errorControl.setErrors(errorValue);
            }
            else if (minErrorValue) {
                errorControl.setErrors(minErrorValue);
            }
            else {
                errorControl.setErrors(null);
            }
        }
    };
    AdvisedOnboardingDetailsComponent.prototype.createForm = function () {
        var _this = this;
        var form = {};
        form.bankAccount = this.formBuilder.group({
            selectedAccount: [this.appForm.debitBankAccount, Validators.required],
        });
        var lumpsumArray = this.formBuilder.array([]);
        form.lumpSum = lumpsumArray;
        if (!!this.appForm.recordOfAdvice.pstLumpsumDetails) {
            this.appForm.lumpSumDetails = this.appForm.recordOfAdvice.pstLumpsumDetails;
            for (var _i = 0, _a = this.appForm.lumpSumDetails; _i < _a.length; _i++) {
                var payment = _a[_i];
                lumpsumArray.push(this.formBuilder.group({
                    amount: [this.numberFormatter.transformBigNumber(payment.amount)],
                    debitDate: [
                        this.toFormDate(payment.debitDate),
                        Validators.compose([Validators.required, dateMinValidator(payment.debitDate, this.dateFormatter)]),
                    ],
                    sourceOfFundsId: [payment.sourceOfFundsId, Validators.required],
                    otherDescription: [payment.otherDescription],
                    fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
                }, { validator: this.validateSourceOfFunds.bind(this) }));
            }
        }
        if (!!this.appForm.debitOrderDetails) {
            form.debitOrder = this.formBuilder.group({
                amount: [this.numberFormatter.transformBigNumber(this.appForm.debitOrderDetails.amount)],
                debitDate: [this.appForm.debitOrderDetails.debitDate],
                firstDebitDate: [this.toFormDate(this.appForm.debitOrderDetails.firstDebitDate)],
                firstEscalationDate: [this.toFormDate(this.appForm.debitOrderDetails.firstEscalationDate)],
                escalation: [
                    this.appForm.debitOrderDetails.escalation < 1
                        ? (+this.appForm.debitOrderDetails.escalation.toFixed(3) * 100)
                        : +this.appForm.debitOrderDetails.escalation,
                ],
                sourceOfFundsId: [this.appForm.debitOrderDetails.sourceOfFunds.sourceOfFundsId, Validators.required],
                otherDescription: [this.appForm.debitOrderDetails.sourceOfFunds.otherDescription],
                fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
            }, { validator: this.validateSourceOfFunds.bind(this) });
            form.debitOrder.get('debitDate').disable();
        }
        this.investmentForm = this.formBuilder.group(form);
        this.investmentForm.valueChanges.subscribe(function (changes) {
            var changesClone = {
                debitOrder: changes.debitOrder ? Object.assign({}, changes.debitOrder) : undefined,
                lumpSum: changes.lumpSum ? Object.assign([], changes.lumpSum) : undefined,
                bankAccount: {
                    selectedAccount: changes.bankAccount && changes.bankAccount.selectedAccount && Object.assign(changes.bankAccount.selectedAccount),
                },
            };
            if (changesClone.debitOrder) {
                changesClone.debitOrder.firstDebitDate = _this.dateFormatter.forApi(changes.debitOrder.firstDebitDate);
                changesClone.debitOrder.firstEscalationDate = _this.dateFormatter.forApi(changes.debitOrder.firstEscalationDate);
            }
            if (changesClone.lumpSum) {
                changesClone.lumpSum = changesClone.lumpSum.map(function (lumpsum) {
                    return Object.assign({}, lumpsum, { debitDate: _this.dateFormatter.forApi(lumpsum.debitDate) });
                });
            }
            _this.applicationStore.dispatch(new AppFormUpdatedDebitOrderDetailsAction({
                lumpSumDetails: changesClone.lumpSum,
                debitOrderDetails: changesClone.debitOrder,
            }));
            _this.applicationStore.dispatch(new AppFormUpdatedBankAccountAction(changes.bankAccount && changes.bankAccount.selectedAccount));
            if (changesClone.debitOrder) {
                _this.validateMaxEscalationDate(changesClone.debitOrder.firstDebitDate, changesClone.debitOrder.firstEscalationDate);
            }
            if (_this.isCash) {
                var selectedAccount = _this.investmentForm.get('bankAccount').get('selectedAccount').value;
                if (!selectedAccount) {
                    _this.insufficientFunds = false;
                }
                else {
                    var lumpsumAmount = _this.appForm.lumpSumDetails.length > 0
                        ? +_this.numberFormatter.parseBigNumber("" + _this.appForm.lumpSumDetails[0].amount)
                        : 0;
                    _this.insufficientFunds = lumpsumAmount > selectedAccount.availableBalance;
                }
            }
            else {
                _this.insufficientFunds = false;
            }
            _this.applicationStore.dispatch(new InsufficientFundsAction(_this.insufficientFunds));
            _this.applicationStore.dispatch(new InsufficientFundsAction(_this.insufficientFunds));
        });
        this.investmentForm.statusChanges.subscribe(function (val) {
            _this.applicationStore.dispatch(new InvestmentDetailsValidAction(val === 'VALID'));
        });
    };
    return AdvisedOnboardingDetailsComponent;
}());
export { AdvisedOnboardingDetailsComponent };
