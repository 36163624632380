var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { CustomerSelectedAction } from 'application/store';
import { map, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from 'shared/services/authentication.service';
import { LookupDataService } from 'shared/services/lookup-data.service';
import { NEW_TOKEN, NO_TOKEN } from 'shared/store/auth-token.reducers';
import { LOGIN_SUCCESS } from 'shared/store/user.reducers';
var UserEffects = /** @class */ (function () {
    function UserEffects(authService, actions$, lookupService, applicationStore) {
        var _this = this;
        this.authService = authService;
        this.actions$ = actions$;
        this.lookupService = lookupService;
        this.applicationStore = applicationStore;
        this.apiLoginAfterTokenIssue$ = this.actions$.ofType(NEW_TOKEN).pipe(mergeMap(function () { return _this.authService.startApiSession(); }), mergeMap(function () { return _this.authService.apiLogin(); }));
        this.apiLoginOnStartup$ = this.actions$.ofType(NO_TOKEN).pipe(mergeMap(function () {
            return _this.authService.apiLogin();
        }));
        this.apiLoginSuccess$ = this.actions$.ofType(LOGIN_SUCCESS).pipe(map(function (action) { return action.payload; }), mergeMap(function (payload) {
            _this.applicationStore.dispatch(new CustomerSelectedAction(payload.customerDetails));
            return of(payload);
        }), mergeMap(function (payload) { return _this.fetchAccountDetails(payload); }));
    }
    UserEffects.prototype.fetchAccountDetails = function (user) {
        if (!user.isBanker) {
            this.lookupService.getSourceOfFundsTypes();
            this.lookupService.getVATRate();
            this.lookupService.getPortfolioTypes();
        }
        return of();
    };
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "apiLoginAfterTokenIssue$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "apiLoginOnStartup$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], UserEffects.prototype, "apiLoginSuccess$", void 0);
    return UserEffects;
}());
export { UserEffects };
