import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { StatementInstruction } from 'investor/models';
import { InstructionsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'shared/models';
import { DateFormatterService, LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-instruction-statement',
    templateUrl: 'statement-instruction.component.pug',
})
export class StatementInstructionComponent extends BaseInstructionComponent implements OnInit, OnDestroy {
    public selectedAccountNumber: string;
    public instructionForm: FormGroup;
    public hasHoldings = false;
    private modalTitle = 'Statement Request';
    private user: User;

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private instructionService: InstructionsService,
        private formBuilder: FormBuilder,
        private dateFormatter: DateFormatterService,
        modalService: NgbModal,
        loggerService: LoggerService,
        toastr: ToastrService
    ) {
        super(toastr, loggerService, modalService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());

        combineLatest(
            this.sharedStore.select(fromShared.selectUser),
            this.investorStore.select(fromInvestor.selectInvestmentAccounts),
            this.investorStore.select(fromInvestor.selectSelectedInstructionAccount),
            (user, accounts, selectedAccountNumber) => {
                return { user, accounts, selectedAccountNumber };
            }
        ).subscribe(({ user, accounts, selectedAccountNumber }) => {
            if (!accounts) {
                return;
            }
            this.user = user;
            this.sharedStore.dispatch(new StopLoadingAction());
            this.selectedAccountNumber = selectedAccountNumber;

            const selectedAccount = accounts.find(acct => {
                return acct.accountNumber === this.selectedAccountNumber;
            });
            this.hasHoldings =
                selectedAccount.accountPortfolios.filter(acc => this.instructionService.canInstructOnAccountItem(acc)).length > 0;
        });

        this.createForm();
    }

    public ngOnDestroy(): void {}

    public canSubmit() {
        return this.instructionForm.valid;
    }

    public submit() {
        if (this.canSubmit()) {
            this.sharedStore.dispatch(new LoadingAction());
            const payLoad: StatementInstruction = {
                fromDate: this.dateFormatter.forApi(this.instructionForm.value.fromDate),
                toDate: this.dateFormatter.forApi(this.instructionForm.value.toDate),
                accountNumber: this.selectedAccountNumber,
                caseTypeId: null,
                ucn: this.user.customerDetails.ucn,
            };
            this.instructionService
                .submitStatementInstruction(payLoad)
                .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
                .subscribe(result => this.handleResponse(result, this.modalTitle), () => this.handleSubmitError(this.modalTitle));
        } else {
            this.markAsTouched(this.instructionForm);
        }
    }

    public cancel() {
        this.instructionForm.reset();
    }

    private createForm() {
        this.instructionForm = this.formBuilder.group({
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
        });
    }
}
