import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AgreementContent, ConsentContent, LumpSumDetails, ProductApplication } from 'application/models';
import { DebitOrderAgreement } from 'application/models/debit-order-agreement';
import { AppFormUpdatedAgreementsAction, AppFormUpdatedConsentsAction, TermsAndConditionsValidAction } from 'application/store/application.reducers';
import { ApplicationState } from 'application/store/application.store';
import * as fromApplication from 'application/store/application.store';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EacModalComponent } from 'shared/components';
import { CheckboxQuestion, FeatureFlags, Fund } from 'shared/models';
import { FeatureFlagService, LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-onboarding-terms-and-conditions',
    templateUrl: 'onboarding-terms-and-conditions.component.pug',
    styleUrls: ['onboarding-terms-and-conditions.component.scss'],
})
export class OnboardingTermsAndConditionsComponent implements OnDestroy {
    public customerName: string;
    public termsForm: FormGroup;
    public willAgreeTerms = true;
    public isAllAccepted = false;
    public appForm: ProductApplication;
    public isError: boolean;
    public formValid: boolean;
    public tcContent: string;
    public debitAgreementContent: string;
    public taxAgreementContent: string;
    public agreement: any = {};
    public agreementQuestions: any = [];
    public consentQuestions: any = [];    
    public questions: any = [];
    public selectedFund: Fund;
    private subscription$: Subscription;
    private agreements: AgreementContent[];
    private consents: ConsentContent[];
    public debitOrderDetails: DebitOrderAgreement;
    public isDirect = false;
    public telephonicEnabled: boolean;
    public adviceFee: number;

    constructor(
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private pstStore: Store<PstState>,
        private formBuilder: FormBuilder,
        private lookupService: LookupDataService,
        private modalService: NgbModal,
        private featureFlagService: FeatureFlagService
    ) {
        this.isError = false;
        this.sharedStore.dispatch(new LoadingAction());

        const selectedFund$ = this.pstStore.select(fromPst.selectSelectedFund);

        const application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        const user$ = this.sharedStore.select(fromShared.selectUser);
        const customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer).pipe(filter(customer => !!customer));
        const isDirect$ = this.applicationStore.select(fromShared.selectIsDirect);

        const consentContent$ = this.lookupService.getConsentContent();

        this.subscription$ = combineLatest(
            application$,
            customer$,
            user$,
            isDirect$,
            selectedFund$,
            consentContent$,
            (application, customer, user, isDirect, selectedFund, consentContent) => {
                return { application, customer, user, isDirect, selectedFund, consentContent };
            }
        ).subscribe(
            ({ application, customer, user, isDirect, selectedFund, consentContent }) => {
                this.lookupService.getAgreementContent(application.portfolioTypeId).subscribe(agreementContent => {                    
                    this.agreements = agreementContent;
                    this.consents = consentContent;
                    this.telephonicEnabled = this.featureFlagService.isEnabled(FeatureFlags.BankerTelephonicAgreements);
                    this.sharedStore.dispatch(new StopLoadingAction());
                    this.customerName = `${customer.title} ${customer.firstname} ${customer.lastname}`;
                    this.appForm = application;
                    this.selectedFund = selectedFund;

                    this.isDirect = isDirect;
                    if (!this.termsForm) {
                        this.createForm(this.telephonicEnabled);
                        this.checkValidity();
                    }

                    this.setAdviceFee();
                });                 
            },
            () => {
                this.isError = true;
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        );
    }

    public ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    public channelChanged() {
        this.appForm.agreements.isAvailableToAccept = this.termsForm.get('isAvailableToAccept').value;
        this.checkValidity();
        this.updateApplicationForm();
    }

    public toggleTermsFormAgreementValue(id) {
        this.appForm.agreements[`isCheckedAgreement${id}`] = !this.appForm.agreements[`isCheckedAgreement${id}`];
        this.checkValidity();
        this.updateApplicationForm();
    }

    public toggleTermsFormConsentValue(id) {
        this.appForm.agreements[`isCheckedConsent${id}`] = !this.appForm.agreements[`isCheckedConsent${id}`];
        this.checkValidity();
        this.updateApplicationFormConsent();
    }       

    private updateApplicationForm() {
        this.applicationStore.dispatch(new AppFormUpdatedAgreementsAction(this.appForm.agreements));
    }

    private updateApplicationFormConsent() {
        this.applicationStore.dispatch(
            new AppFormUpdatedConsentsAction({
                acceptances: this.consentQuestions.filter(q => q.showTickBox).map(question => ({
                    consentType: question.key,
                    isAccepted: question.value
                }))
            }));           
    }    

    private setAdviceFee() {
        if (this.appForm.recordOfAdvice.pstAdvised) {
            this.adviceFee = 0.29;
        } else {
            this.adviceFee = 0;
        }
    }

    private checkValidity() {
        if (this.appForm.agreements.isAvailableToAccept === true) {
            this.formValid =
                (Object.getOwnPropertyNames(this.appForm.agreements).filter(item => {
                    return item.startsWith('isCheckedAgreement') && this.appForm.agreements[item] === false;
                }).length === 0) &&
                (this.consentQuestions.filter(q => q.required && q.value === false).length === 0);
        } else {
            this.formValid = this.appForm.agreements.isAvailableToAccept === false;
        }
        this.applicationStore.dispatch(new TermsAndConditionsValidAction(this.formValid));
    }

    public openEacModal() {
        const modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = {
            funds: [{ fundCode: this.selectedFund.pstCode, allocation: 1 }],
            notAdvised: !this.appForm.recordOfAdvice.pstAdvised,
            portfolioTypeId: this.appForm.portfolioTypeId
        };
    }

    private createForm(telephonicEnabled: boolean) {
        this.agreements.forEach(agreement => {
            const checkBoxQuestion = new CheckboxQuestion({
                key: agreement.agreementId.toString(),
                type: 'checkbox',
                label: agreement.name,
                content: agreement.summary,
                order: agreement.agreementId,
                id: `cntrlAgreement${agreement.agreementId}`,
                value: false,
                controlFormName: `isCheckedAgreement${agreement.agreementId}`,
                showDebitOrderDetails: agreement.isDebitOrder,
                fileUrl: agreement.moreDetailLink,
            });
            this.agreementQuestions.push(checkBoxQuestion);
            this.appForm.agreements[`isCheckedAgreement${agreement.agreementId}`] = false;
        });

        this.consents.forEach(consent => {
            const checkBoxQuestion = new CheckboxQuestion({
                key: consent.consentId.toString(),
                type: 'checkbox',
                label: consent.name,
                content: consent.summary,
                order: consent.consentId,
                id: `cntrlConsent${consent.consentId}`,
                value: false,
                controlFormName: `isCheckedConsent${consent.consentId}`,
                showTickBox: consent.showTickBox,
                required: consent.isMandatory,
                tickBoxContent: consent.tickBoxSummary
            });
            this.consentQuestions.push(checkBoxQuestion);
            this.appForm.agreements[`isCheckedConsent${consent.consentId}`] = false;
        });         

        this.appForm.agreements['isAvailableToAccept'] = this.isDirect ? true : telephonicEnabled ? null : false;

        this.debitOrderDetails = {
            accountNumber: this.appForm.debitBankAccount ? this.appForm.debitBankAccount.accountNumber : null,
            accountName: this.appForm.debitBankAccount ? this.appForm.debitBankAccount.accountName : null,
            lumpSumDetails: this.appForm.lumpSumDetails ? this.appForm.lumpSumDetails : null,
            debitAmount: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.amount : null,
            debitReDate: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.debitDate : null,
            debitStartDate: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.firstDebitDate : null,
            paymentFrequencyId: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.paymentFrequencyId : null,
        };
        this.termsForm = this.formBuilder.group(this.appForm.agreements);
    }
}
