import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { Node, PstReference, SavedPstResult } from 'pst/models';
import {
    NewTreeAction,
    PstAnswerCapturedAction,
    PstCompletedAction,
    PstErrorAction,
    PstLoadedAction,
    ResetPstStateAction,
} from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { PstState } from 'pst/store/pst.store';
import { Observable, ObservableInput, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger, LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

const endpointNewRef = `${appConfig.apiUrl}/pst`;
const endpointTraverse = `${appConfig.apiUrl}/pst/select`;
const endpointSave = `${appConfig.apiUrl}/pst/save`;

const selections: Map<number, string> = new Map();

@Injectable()
export class PstService {
    private logger: Logger;

    constructor(
        private sharedStore: Store<SharedState>,
        private pstStore: Store<PstState>,
        private http: HttpClient,
        loggerService: LoggerService
    ) {
        this.logger = loggerService.getLogger('PST Service');
    }

    public startNewTree() {
        this.logger.info('Fetching new PST tree');
        this.pstStore.dispatch(new ResetPstStateAction());
        this.sharedStore.dispatch(new LoadingAction());
        this.http
            .get<PstReference>(endpointNewRef)
            .pipe(catchError(err => this.pstActionFailed(err)))
            .subscribe(response => {
                if (!response.error) {
                    this.pstStore.dispatch(new NewTreeAction(response));
                }
            });
    }

    public traverseTreeForwards(reference: string, parentNode: Node, childNode: Node, value: string) {
        this.sharedStore.dispatch(new LoadingAction());
        this.pstStore.dispatch(
            new PstAnswerCapturedAction({
                parentNode,
                answerNode: childNode,
                answer: value,
            })
        );
        this.traverse(reference, parentNode.nodeId, childNode.nodeId, value, 'fwd');
    }

    public traverseTreeBackwards(reference: string, parentNodeId: number, childNodeId: number, value: string) {
        this.sharedStore.dispatch(new LoadingAction());
        this.traverse(reference, parentNodeId, childNodeId, value, 'bck');
    }

    public getSelections() {
        return selections;
    }

    public saveResult(pstResult: SavedPstResult) {
        return this.http.post<number>(endpointSave, pstResult);
    }

    private traverse(transactionRef: string, parentNodeId: number, childNodeId: number, value: string, action: string) {
        this.http
            .post<PstReference>(endpointTraverse, {
                action,
                transactionRef,
                childNodeId,
                parentNodeId,
                value,
            })
            .pipe(catchError(err => this.pstActionFailed(err)))
            .subscribe(response => {
                if (!response.error) {
                    this.pstStore.dispatch(new NewTreeAction(response));
                }
            });
    }

    private pstActionFailed(error: Error): ObservableInput<any> {
        this.pstStore.dispatch(new PstErrorAction(error));
        this.sharedStore.dispatch(new StopLoadingAction());
        return of(error);
    }
}
