import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

import { SaIdNumberValidationService } from '../services/sa-idnumber-validation.service';

export function saIdNumberValidator(validatorService: SaIdNumberValidationService, allowEmpty = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const valid =
            (allowEmpty && (control.value === null || control.value + '' === '')) || validatorService.validate(control.value + '');
        return valid ? null : { idnumber: !valid };
    };
}
