import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store as NgxsStore } from '@ngxs/store';
import { RoboFieldId, RoboFieldType } from 'robo/models';
import { RoboUpdateFieldAction } from 'robo/store';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';

@Component({
    selector: 'wim-robo-goal-other-name',
    templateUrl: 'other-name-modal.component.pug',
})
export class GoalSelectionOtherNameModalComponent extends BaseComponent {
    public form: FormGroup;

    constructor(
        loggerService: LoggerService,
        private store: NgxsStore,
        private formBuilder: FormBuilder,
        private activeModal: NgbActiveModal
    ) {
        super(loggerService);
        this.createForm();
    }

    public ngOnDestroy() {}

    public submit() {
        this.store.dispatch(
            new RoboUpdateFieldAction({
                fieldId: RoboFieldId.goalName,
                value: this.form.value.goalName,
            })
        );
        this.activeModal.close();
    }

    public close() {
        this.activeModal.dismiss();
    }

    private createForm(): any {
        this.form = this.formBuilder.group({
            goalName: ['', Validators.required],
        });
    }
}
