import { AfterContentInit, AfterViewInit, Component, ComponentFactoryResolver, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { CashResultComponent } from 'pst/components/cash-result/cash-result.component';
import { ContextComponent } from 'pst/components/context/context.component';
import { CurrencyComponent } from 'pst/components/currency/currency.component';
import { InvestmentAccountResultComponent } from 'pst/components/investment-account-result/investment-account-result.component';
import { MessageComponent } from 'pst/components/message/message.component';
import { MultipleChoiceComponent } from 'pst/components/multiple-choice/multiple-choice.component';
import { NumberComponent } from 'pst/components/number/number.component';
import { TermComponent } from 'pst/components/term/term.component';
import { TextComponent } from 'pst/components/text/text.component';
import { InsertStepDirective } from 'pst/directives/insert-step.directive';
import { PstNodeTypes, PstReference } from 'pst/models';
import { PstService } from 'pst/services';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { BaseComponent } from 'shared/components';
import { LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { ClearPstTreeNodeAction, EnterPressedAction, PstErrorAction } from '../../store';
import { TermYearsComponent } from '../term-years/term-years.component';

const nodeTypeMappings: Map<number, Type<any>> = new Map();
const portfolioTypeMappings: Map<number, Type<any>> = new Map();

@Component({
    selector: 'wim-pst-wizard',
    templateUrl: 'pst-wizard.component.pug',
})
export class PstWizardComponent extends BaseComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild(InsertStepDirective)
    public host: InsertStepDirective;
    public customerName: string;
    public tree: PstReference;

    public error$: Observable<Error>;

    public selectedNode: Node;
    public value: string;
    public formInvalid: boolean;

    private loadNewTree = true;
    private componentRef: any;

    private keyUpHandler;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private pstService: PstService,
        private pstStore: Store<PstState>,
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private lookupDataService: LookupDataService,
        loggerService: LoggerService
    ) {
        super(loggerService);

        nodeTypeMappings.set(PstNodeTypes.result, InvestmentAccountResultComponent);
        nodeTypeMappings.set(PstNodeTypes.cashResult, CashResultComponent);
        nodeTypeMappings.set(PstNodeTypes.text, TextComponent);
        nodeTypeMappings.set(PstNodeTypes.multipleChoice, MultipleChoiceComponent);
        nodeTypeMappings.set(PstNodeTypes.number, NumberComponent);
        nodeTypeMappings.set(PstNodeTypes.currency, CurrencyComponent);
        nodeTypeMappings.set(PstNodeTypes.term, TermComponent);
        nodeTypeMappings.set(PstNodeTypes.context, ContextComponent);
        nodeTypeMappings.set(PstNodeTypes.termYears, TermYearsComponent);

        portfolioTypeMappings.set(appConfig.portfolioTypes.INV, InvestmentAccountResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TFDS, InvestmentAccountResultComponent);

        portfolioTypeMappings.set(appConfig.portfolioTypes.DDA81, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.DDA77, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOA95, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOA97, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOAMA32, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOAMA45, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOAMA90, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOA98, CashResultComponent);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.pstStore.dispatch(new PstErrorAction(null));
        this.error$ = this.pstStore.select(fromPst.selectPstError);

        let customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        let pstTree$ = this.sharedStore.select(fromPst.selectPstTree);
        let queryParams$ = this.activatedRoute.queryParams;

        let originIsDirect$ = this.sharedStore.select(fromShared.selectIsDirect);

        combineLatest(customer$, pstTree$, queryParams$, originIsDirect$, (selectedCustomer, tree, params, isDirect) => {
            return { selectedCustomer, tree, params, isDirect };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ selectedCustomer, tree, params, isDirect }) => {
                if (selectedCustomer === null) {
                    if (isDirect === false) {
                        this.logger.warn('Cannot do PST before selecting a customer, navigating to customer search');
                        this.router.navigateByUrl('/secure/banker/customer-search');
                        this.sharedStore.dispatch(new StopLoadingAction());
                        this.loadNewTree = false;
                        return;
                    } else if (isDirect === true) {
                        this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
                        this.loadNewTree = false;
                        return;
                    }
                }
                this.customerName = `${selectedCustomer.title} ${selectedCustomer.firstname} ${selectedCustomer.lastname}`;

                let ctype: Type<any>;

                if (params['continue']) {
                    this.logger.info('Loading restored PST');
                    this.loadNewTree = false;
                    let portfolioTypeId = +(+params['portfolioTypeId'] || appConfig.portfolioTypes.INV);
                    ctype = portfolioTypeMappings.get(portfolioTypeId);
                } else if (!tree) {
                    this.loadNewTree = true;
                    return;
                } else {
                    this.loadNewTree = true;
                    this.tree = tree;
                    const parentNodeType = tree.node.nodeTypeCodeId;

                    if (parentNodeType === PstNodeTypes.question) {
                        if (this.tree.childNodes.length > 0) {
                            const childNodeType = this.tree.childNodes[0].nodeTypeCodeId;
                            ctype = nodeTypeMappings.get(childNodeType);
                        } else {
                            ctype = MessageComponent;
                        }
                    } else {
                        ctype = nodeTypeMappings.get(parentNodeType);
                    }
                    this.loadContent()
                        .pipe(this.scavenger.collect())
                        .subscribe();
                }
                this.sharedStore.dispatch(new StopLoadingAction());
                this.loadComponent(ctype);
            });
        // Disabled for now
        // this.keyUpHandler = this.enterPressed.bind(this);
        // document.addEventListener('keyup', this.keyUpHandler);
    }

    private enterPressed(event) {
        if (event && event.keyCode === 13) {
            this.pstStore.dispatch(new EnterPressedAction(true));
        }
    }

    public ngOnDestroy() {
        this.pstStore.dispatch(new ClearPstTreeNodeAction());
        // Disabled for now
        // document.removeEventListener('keyup', this.keyUpHandler);
    }

    public ngAfterContentInit() {
        if (this.loadNewTree) {
            this.pstService.startNewTree();
        }
    }

    public loadComponent(component: Type<any>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        if (this.host) {
            const viewContainerRef = this.host.viewContainerRef;
            if (this.componentRef && this.componentRef.ngOnDestroy) {
                (this.componentRef as OnDestroy).ngOnDestroy();
            }
            viewContainerRef.clear();
            // tslint:disable-next-line:no-unused-expression
            this.componentRef = viewContainerRef.createComponent(componentFactory).instance;
            this.componentRef.customerName = this.customerName;
        }
    }

    private loadContent(): Observable<any> {
        return forkJoin(
            this.lookupDataService.getPstIcons(),
            this.lookupDataService.getPstTooltips(),
            this.lookupDataService.getPstResultContent()
        );
    }
}
