import { CommonModule, DecimalPipe, PercentPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { LayoutEffects } from 'shared/store/layout.effects';
import { reducers } from 'shared/store/shared.reducers';
import { UserEffects } from 'shared/store/user.effects';

import {
    BankerLoginComponent,
    DecimalSliderInputComponent,
    EacModalComponent,
    EffectiveAnnualCostCalucationComponent,
    LoaderComponent,
    MultipleChoiceButtonsComponent,
    NumberSliderInputComponent,
    SessionExpiringModalComponent,
    SharedModalComponent,
    TermSliderInputComponent,
    YesNoComponent,
} from './components';
import { DebitOrderAgreementComponent } from './components/debit-order-agreement/debit-order-agreement.component';
import {
    AutoFocusDirective,
    AutoSubmitDirective,
    BigNumberFormatterDirective,
    BigNumberRandFormatterDirective,
    NumberTextFormatterDirective,
    PercentFormatterDirective,
} from './directives';
import { AbsoluteValuePipe, AddVatPipe, BigNumberPipe, TitleCasePipe } from './pipes';
import {
    AuthenticationService,
    AuthTokenService,
    BankerAllowedGuard,
    BankerGuard,
    Base64Service,
    DateFormatterService,
    EffectiveAnnualCostService,
    FeatureFlagService,
    FormControlService,
    InvestorGuard,
    LocalTimeService,
    LoggedInAuthGuard,
    LoggerService,
    LookupDataService,
    NumberFormatterService,
    RequestInspectionService,
    SaIdNumberValidationService,
    ServerTimeService,
    SessionService,
    TokenStorageService,
} from './services';
import { FeatureGuard } from './services/feature.guard';
import { DecimalFormatterDirective } from './directives/decimal-formatter.directive';

const MODULE_SERVICES = [
    AuthenticationService,
    AuthTokenService,
    BankerAllowedGuard,
    BankerGuard,
    Base64Service,
    DateFormatterService,
    EffectiveAnnualCostService,
    FeatureFlagService,
    FeatureGuard,
    FormControlService,
    InvestorGuard,
    LocalTimeService,
    LoggedInAuthGuard,
    LoggerService,
    LookupDataService,
    NumberFormatterService,
    RequestInspectionService,
    SaIdNumberValidationService,
    ServerTimeService,
    SessionService,
    TokenStorageService,
];

const MODULE_COMPONENTS = [
    BankerLoginComponent,
    DecimalSliderInputComponent,
    DebitOrderAgreementComponent,
    EacModalComponent,
    EffectiveAnnualCostCalucationComponent,
    LoaderComponent,
    SessionExpiringModalComponent,
    SharedModalComponent,
    YesNoComponent,
    NumberSliderInputComponent,
    TermSliderInputComponent,
    MultipleChoiceButtonsComponent,
];

const MODULE_DIRECTIVES = [
    AutoFocusDirective,
    AutoSubmitDirective,
    BigNumberFormatterDirective,
    BigNumberRandFormatterDirective,
    DecimalFormatterDirective,
    NumberTextFormatterDirective,
    PercentFormatterDirective,
];

const MODULE_PIPES = [AddVatPipe, BigNumberPipe, TitleCasePipe, AbsoluteValuePipe];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        NgxErrorsModule,
        ReactiveFormsModule,
        StoreModule.forFeature('shared', reducers),
        EffectsModule.forFeature([UserEffects, LayoutEffects]),
        StoreRouterConnectingModule,
        MatSliderModule,
    ],
    declarations: [...MODULE_COMPONENTS, ...MODULE_DIRECTIVES, ...MODULE_PIPES],
    exports: [...MODULE_COMPONENTS, ...MODULE_DIRECTIVES, ...MODULE_PIPES],
    entryComponents: [SharedModalComponent, EacModalComponent, SessionExpiringModalComponent],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [...MODULE_SERVICES, BigNumberPipe, AddVatPipe, DecimalPipe, PercentPipe],
        };
    }
}
