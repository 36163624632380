import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { FieldUpdate, RoboState } from '../models';
import { RoboFieldId } from '../models/robo-field-id';

import {
    AdviceFormStatusChangedAction,
    RoboActivateField as RoboActivateFieldAction,
    RoboAdditionalLumpsumsUpdated as RoboAdditionalLumpsumsUpdatedAction,
    RoboAdviceErrorAction,
    RoboAdviceUpdatedAction,
    RoboClearScreenAction,
    RoboContinuedAction,
    RoboDeactivateField as RoboDeactivateFieldAction,
    RoboRecommendationAcceptedAction,
    RoboSavedAction,
    RoboSaveErrorAction,
    RoboUpdateFieldAction,
    RoboUpdateMultipleFieldsAction,
    TaxFreeOpenedAction,
    TaxFreeOptOutAction,
    UpdateRoboValidationWarningsAction,
} from './robo.actions';

@State<RoboState>({
    defaults: new RoboState(),
    name: 'robo',
})
export class RoboStore {
    @Selector()
    public static completedField(state: RoboState) {
        return (type: RoboFieldId) => {
            return state.fields[type];
        };
    }

    @Selector()
    public static completedFields(state: RoboState) {
        return state.fields;
    }

    @Selector()
    public static additionalLumpsums(state: RoboState) {
        return state.additionalLumpsums;
    }

    @Selector()
    public static requiredFields(state: RoboState) {
        return state.requiredFields;
    }

    @Selector()
    public static currentAdvice(state: RoboState) {
        return state.advice;
    }

    @Selector()
    public static adviceError(state: RoboState) {
        return state.adviceError;
    }

    @Selector()
    public static saveError(state: RoboState) {
        return state.saveError;
    }

    @Selector()
    public static saveAdviceId(state: RoboState) {
        return state.savedAdviceId;
    }

    @Selector()
    public static validationErrors(state: RoboState) {
        return state.validationErrors;
    }

    @Selector()
    public static adviceEngineValidationWarnings(state: RoboState) {
        return state.adviceEngineValidationWarnings;
    }

    @Selector()
    public static roboValidationWarnings(state: RoboState) {
        return state.roboValidationWarnings;
    }    

    @Selector()
    public static taxFreeOpened(state: RoboState) {
        return state.taxFreeOpened;
    }

    @Selector()
    public static taxFreeOptOut(state: RoboState) {
        return state.taxFreeOptOut;
    }    

    @Selector()
    public static adviceFormValid(state: RoboState) {
        return state.adviceFormValid;
    }

    @Selector()
    public static downstreamAdviceError(state: RoboState) {
        return state.downstreamAdviceError;
    }

    @Action(RoboUpdateFieldAction)
    public updateField(ctx: StateContext<RoboState>, action: RoboUpdateFieldAction) {
        const newState = produce(ctx.getState(), draftState => {
            this.updateFieldInState(draftState, action.payload);
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboUpdateMultipleFieldsAction)
    public updateMultipleFields(ctx: StateContext<RoboState>, action: RoboUpdateMultipleFieldsAction) {
        const newState = produce(ctx.getState(), draftState => {
            action.payload.forEach(fieldUpdate => this.updateFieldInState(draftState, fieldUpdate));
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboAdviceUpdatedAction)
    public updateAdvice(ctx: StateContext<RoboState>, action: RoboAdviceUpdatedAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.requiredFields = action.payload.requiredFields;
            draftState.advice = action.payload.financialAdvice;
            draftState.validationErrors = action.payload.errors;
            draftState.adviceEngineValidationWarnings = action.payload.warnings;
            draftState.adviceError = null;
            draftState.downstreamAdviceError = action.payload.downstreamErrors;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboActivateFieldAction)
    public activateField(ctx: StateContext<RoboState>, action: RoboActivateFieldAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.fields[action.payload].active = true;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboDeactivateFieldAction)
    public deactivateField(ctx: StateContext<RoboState>, action: RoboActivateFieldAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.fields[action.payload].active = false;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboAdviceErrorAction)
    public errorWhenGettingAdvice(ctx: StateContext<RoboState>, action: RoboAdviceErrorAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.adviceError = action.payload;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboClearScreenAction)
    public clearInputs(ctx: StateContext<RoboState>) {
        const newState = produce(ctx.getState(), draftState => {
            RoboState.clear(draftState);
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboAdditionalLumpsumsUpdatedAction)
    public additionalLumpsumsUpdates(ctx: StateContext<RoboState>, action: RoboAdditionalLumpsumsUpdatedAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.additionalLumpsums = action.payload;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboSaveErrorAction)
    public errorWhenSaving(ctx: StateContext<RoboState>, action: RoboSaveErrorAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.saveError = action.payload;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboSavedAction)
    public successfulSave(ctx: StateContext<RoboState>, action: RoboSavedAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.savedAdviceId = action.payload;
            draftState.adviceError = null;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(RoboContinuedAction)
    public successfulResume(ctx: StateContext<RoboState>, action: RoboContinuedAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.savedAdviceId = action.payload;
            draftState.adviceError = null;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(TaxFreeOpenedAction)
    public openTaxFreeAccount(ctx: StateContext<RoboState>, action: TaxFreeOpenedAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.taxFreeOpened = action.payload;
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(TaxFreeOptOutAction)
    public declineTaxFreeAccount(ctx: StateContext<RoboState>, action: TaxFreeOptOutAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.taxFreeOptOut = action.payload;
        }) as RoboState;
        ctx.setState(newState);
    }    

    @Action(AdviceFormStatusChangedAction)
    public formValidStatusChanged(ctx: StateContext<RoboState>, action: AdviceFormStatusChangedAction) {
        const newState = produce(ctx.getState(), draftState => {
            draftState.adviceFormValid = action.payload === 'VALID';
        }) as RoboState;
        ctx.setState(newState);
    }

    @Action(UpdateRoboValidationWarningsAction)
    public updatedWarnings(ctx: StateContext<RoboState>, action: UpdateRoboValidationWarningsAction) {        
        const newState = produce(ctx.getState(), draftState => {
            draftState.roboValidationWarnings = action.payload;
        }) as RoboState;
        ctx.setState(newState);
    }      

    private updateFieldInState(draftState: RoboState, fieldChange: FieldUpdate) {
        if (!draftState.fields) {
            draftState.fields = {};
        }

        if (!draftState.fields[fieldChange.fieldId]) {
            draftState.fields[fieldChange.fieldId] = { value: fieldChange.value, active: true };
        } else {
            draftState.fields[fieldChange.fieldId].value = fieldChange.value;
        }
    }
}
