/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./validation-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_ValidationModalComponent = [];
var RenderType_ValidationModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValidationModalComponent, data: {} });
export { RenderType_ValidationModalComponent as RenderType_ValidationModalComponent };
function View_ValidationModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["We don't have enough information to give you advice yet, please continue completing the form."]))], null, null); }
function View_ValidationModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Some of the information you've provided is invalid, check for error messages."]))], null, null); }
export function View_ValidationModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Something is missing"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationModalComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationModalComponent_2)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recommendationInvalid; _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.formInvalid || _co.validationErrors); _ck(_v, 10, 0, currVal_1); }, null); }
export function View_ValidationModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-validation-modal", [], null, null, null, View_ValidationModalComponent_0, RenderType_ValidationModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.ValidationModalComponent, [i3.NgbActiveModal], null, null)], null, null); }
var ValidationModalComponentNgFactory = i0.ɵccf("wim-validation-modal", i2.ValidationModalComponent, View_ValidationModalComponent_Host_0, {}, {}, []);
export { ValidationModalComponentNgFactory as ValidationModalComponentNgFactory };
