import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestInspectionService } from 'shared/services';

@Injectable()
export class CacheBusterInterceptor implements HttpInterceptor {
    constructor(private requestInspectionService: RequestInspectionService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.requestInspectionService.isWebAPIRequest(request)) {
            const newRequest = request.clone({ setParams: { t: `${new Date().getTime()}` } });
            return next.handle(newRequest);
        }
        return next.handle(request);
    }
}
