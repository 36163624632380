import { Component } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PhoneReference, PhoneReferenceResponse } from 'application/models';
import { ApplicationService } from 'application/services';
import { ApplicationState } from 'application/store/application.store';
import * as fromApplication from 'application/store/application.store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-horizon-onboarding-complete',
    templateUrl: 'onboarding-complete.component.pug',
})
export class HorizonOnboardingCompleteComponent implements OnDestroy {
    public referenceNumber;
    public submissionError;
    public phoneReferenceForm: FormGroup;
    public referenceSubmitted = false;
    public productName = '';
    public isDirect = false;

    private subscriptions$: Subscription;

    constructor(
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        protected toastr: ToastrService,
        lookupService: LookupDataService
    ) {
        const portfolioTypes$ = lookupService.getPortfolioTypes();
        const application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        const referenceNumber$ = this.applicationStore.select(fromApplication.selectApplicationReference);
        const submissionError$ = this.applicationStore.select(fromApplication.selectAppSubmissionError);
        const isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);

        this.subscriptions$ = combineLatest(
            referenceNumber$,
            submissionError$,
            application$,
            portfolioTypes$,
            isDirect$,
            (referenceNum, submissionErr, application, portfolioTypes, isDirect) => {
                return { referenceNum, submissionErr, application, portfolioTypes, isDirect };
            }
        ).subscribe(({ referenceNum, submissionErr, application, portfolioTypes, isDirect }) => {
            this.referenceNumber = referenceNum;
            this.submissionError = submissionErr;

            this.productName = portfolioTypes.filter(portfolioType => portfolioType.id === application.portfolioTypeId)[0].name;
            this.isDirect = isDirect;
        });
        this.createForm();
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public createForm() {
        this.phoneReferenceForm = this.formBuilder.group({
            phoneReference: [''],
        });
    }

    public canSubmit() {
        return this.phoneReferenceForm.valid;
    }

    public submit() {
        this.sharedStore.dispatch(new LoadingAction());
        const formData = this.phoneReferenceForm.value;
        const payLoad: PhoneReference = {
            applicationReference: this.referenceNumber,
            phoneCallReference: formData.phoneReference,
        };
        this.applicationService
            .SubmitPhoneReference(payLoad)
            .pipe(
                finalize(() => {
                    this.referenceSubmitted = true;
                    this.sharedStore.dispatch(new StopLoadingAction());
                })
            )
            .subscribe(result => this.handleResponse(result), error => this.handleError());
    }

    private handleResponse(result: PhoneReferenceResponse) {
        if (result.success) {
            this.toastr.success(result.message);
            this.cancel();
        } else {
            this.toastr.error(result.message);
        }
    }

    private handleError() {
        this.toastr.error('An error occured while submitting the phone reference. Please try again later.');
    }

    private cancel() {
        this.phoneReferenceForm.reset();
    }
}
