import { Action } from '@ngrx/store';
import { JwtToken } from 'shared/models';

// tslint:disable:max-classes-per-file

export const NEW_TOKEN = '[AuthToken] Initial token';
export const NO_TOKEN = '[AuthToken] No token on init';
export const TOKEN_REFRESHED = '[AuthToken] Refreshed';
export const TOKEN_REVOKED = '[AuthToken] Revoked';

export class InitialTokenAction implements Action {
    public readonly type = NEW_TOKEN;
    public constructor(public payload: JwtToken) {}
}

export class NoInitialTokenAction implements Action {
    public readonly type = NO_TOKEN;
    public constructor() {}
}

export class TokenRevokedAction implements Action {
    public readonly type = TOKEN_REVOKED;
}

export class TokenRefreshedAction implements Action {
    public readonly type = TOKEN_REFRESHED;
    public constructor(public payload: JwtToken) {}
}

export type AuthTokenAction = InitialTokenAction | TokenRevokedAction | TokenRefreshedAction;

export function initialTokenReducer(state: JwtToken = null, action: AuthTokenAction) {
    switch (action.type) {
        case NEW_TOKEN: {
            return action.payload;
        }
        case TOKEN_REVOKED: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function accessTokenReducer(state: JwtToken = null, action: AuthTokenAction) {
    switch (action.type) {
        case NEW_TOKEN:
        case TOKEN_REFRESHED: {
            return action.payload;
        }
        case TOKEN_REVOKED: {
            return null;
        }
        default: {
            return state;
        }
    }
}
