import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstReference } from 'pst/models';
import { PstParserService, QuestionPart } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable } from 'rxjs';
import { User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';

import { BasePstComponent } from '../base-pst.component';

@Component({
    selector: 'wim-pst-text',
    templateUrl: 'text.component.pug',
    styleUrls: ['text.component.scss'],
})
export class TextComponent extends BasePstComponent implements OnInit {
    @Input()
    public customerName: string;
    public tree: PstReference;
    public user: Observable<User>;

    public sliderText: QuestionPart[];
    public heading: string;
    public description: string;
    public inputForm: FormGroup;

    constructor(
        loggerService: LoggerService,
        modalService: NgbModal,
        private pstStore: Store<PstState>,
        private pstParser: PstParserService,
        private formBuilder: FormBuilder,
        private lookupService: LookupDataService
    ) {
        super(loggerService, modalService);
    }

    public ngOnInit() {
        const result$ = this.pstStore.select(fromPst.selectInProgressPst);
        const tree$ = this.pstStore.select(fromPst.selectPstTree);

        const tooltips$ = this.lookupService.getPstTooltips();

        combineLatest(result$, tree$, tooltips$, (result, tree, tooltips) => {
            return { result, tree, tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ result, tree, tooltips }) => {
                this.tree = tree;
                if (!this.tree) {
                    return;
                }
                super.initContent(null, tooltips, tree.node.nodeId);
                const childNode = tree.childNodes[0];
                this.pstStore.dispatch(new UserSelectedChildNodeAction(childNode));
                this.pstStore.dispatch(new PstScreenValidAction(false));

                this.heading = this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
                this.description = tree.node.nodeDescription;
                this.sliderText = this.pstParser.buildInputDescription(childNode.nodeDescription);

                this.createForm();
            });
    }

    public ngOnDestroy() {}

    private createForm() {
        if (!this.inputForm) {
            this.inputForm = this.formBuilder.group({
                value: ['', Validators.required],
            });
            this.inputForm.valueChanges.subscribe(changes => {
                this.pstStore.dispatch(new UserEnteredValueAction(changes.value));
                this.pstStore.dispatch(new PstScreenValidAction(this.inputForm.valid));
            });
        }
    }
}
