import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';

export function bigNumberMinIfNotZeroValidator(minValue: number, text = ''): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const textRegex = new RegExp(text, 'g');
        const value = +`${control.value}`.replace(/\s/g, '').replace(textRegex, '');
        if (value === 0) {
            return null;
        }

        const aboveMin = value === null || value >= minValue;
        return aboveMin ? null : { min: minValue };
    };
}
