import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class LoggedInAuthGuard implements CanActivate {
    private loggedIn: boolean;

    constructor(private store: Store<SharedState>, private router: Router, private authenticationService: AuthenticationService) {
        this.store.select(fromShared.selectAuthenticated).subscribe(loggedIn => (this.loggedIn = loggedIn));
    }

    public canActivate(): boolean | Observable<boolean> | Promise<boolean> {
        if (this.loggedIn) {
            return true;
        }
        return Observable.create(observer => {
            this.store.select(fromShared.selectApiLoginInProgress).subscribe(loginPending => {
                if (loginPending) {
                    this.store.select(fromShared.selectAuthenticated).subscribe(authenticated => {
                        if (authenticated === null) {
                            return;
                        }
                        if (authenticated === false) {
                            observer.next(false);
                            setTimeout(() => this.router.navigateByUrl('/invest'));
                            return;
                        }
                        observer.next(authenticated);
                    });
                }
                if (!loginPending) {
                    if (this.loggedIn) {
                        observer.next(this.loggedIn);
                    } else {
                        setTimeout(() => this.router.navigateByUrl('/invest'));
                        observer.next(false);
                    }
                }
            });
        });
    }
}
