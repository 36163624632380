import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccountType, CustomerBankAccount, CustomerSearchResult, ProductApplication, SourceOfFundsDeclaration } from 'application/models';
import { ApplicationDateService } from 'application/services';
import {
    AppFormReferenceUpdated,
    AppFormUpdatedBankAccountAction,
    AppFormUpdatedDebitOrderDetailsAction,
    BankAccountListLoadedAction,
    InvestmentDetailsValidAction,
} from 'application/store/application.reducers';
import { ApplicationState } from 'application/store/application.store';
import * as fromApplication from 'application/store/application.store';
import { CisSearchService } from 'banker/services';
import * as moment from 'moment';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { Fund, PortfolioType } from 'shared/models';
import { SourceOfFundsType } from 'shared/models/source-of-funds-type';
import { DateFormatterService, LookupDataService, NumberFormatterService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { bigNumberMaxValidator, bigNumberMinValidator, dateMaxValidator, dateMinValidator } from 'shared/validators';

import { PossibleDebitDates } from '../debit-dates';

const minUnitTrustDebitOrder = 500;
const maxUnitTrustDebitOrder = 100000;
const minUnitTrustLumpSum = 5000;
const maxUnitTrustLumpSum = 1000000;

const minCashDebitOrder = 1;
const maxCashDebitOrder = 50000000;
const minCashLumpSum = 1;
const maxCashLumpSum = 20000000;

@Component({
    selector: 'wim-direct-onboarding-details',
    templateUrl: 'direct-onboarding-details.component.pug',
    styleUrls: ['direct-onboarding-details.component.scss'],
})
export class DirectOnboardingDetailsComponent implements OnDestroy {
    public customerName: string;
    public lumpSumSelected = false;
    public debitOrderSelected = false;
    public isCash = false;
    public bankAccountLoadingError;

    public pstAdvised = false;
    private isDirect = false;

    public investmentForm: FormGroup;
    public selectedFund: Observable<Fund>;
    public appForm: ProductApplication;
    public bankAccounts: CustomerBankAccount[] = [];
    public recurringEscalation: number;
    public debitDates: Array<{ value: number; label: string }> = [];

    public minDebitOrder: number;
    public maxDebitOrder: number;
    public minLumpSum: number;
    public maxLumpSum: number;

    public minIncrease = 0;
    public maxIncrease = 15;

    public totalLumpsum = 0;

    private subscriptions$: Subscription;
    private sourceOfFundsTypes: SourceOfFundsType[] = [];
    private selectedCustomer: CustomerSearchResult;
    private bankAccountsLoadedOnInit = false;
    private products: Map<number, PortfolioType> = new Map();
    public product: PortfolioType;
    public minDateForPicker: NgbDateStruct;
    public maxDateForPicker: NgbDateStruct;
    public minDebitDate: moment.Moment;
    public maxDebitDate: moment.Moment;

    constructor(
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private pstStore: Store<PstState>,
        private formBuilder: FormBuilder,
        private lookupService: LookupDataService,
        private numberFormatter: NumberFormatterService,
        private customerService: CisSearchService,
        private applicationDateService: ApplicationDateService,
        private dateFormatter: DateFormatterService
    ) {
        this.sharedStore.dispatch(new LoadingAction());
        this.selectedFund = this.pstStore.select(fromPst.selectSelectedFund);
        this.lookupService.getSourceOfFundsTypes().subscribe(types => (this.sourceOfFundsTypes = types));
        const application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        const customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer).pipe(filter(customer => !!customer));
        const products$ = this.lookupService.getPortfolioTypes();
        const isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);

        this.minDebitDate = this.applicationDateService.firstPossibleDebitDate();
        this.maxDebitDate = this.applicationDateService.maxLumpsumDate();

        this.minDateForPicker = this.dateFormatter.forDatePicker(this.minDebitDate);
        this.maxDateForPicker = this.dateFormatter.forDatePicker(this.maxDebitDate);

        this.subscriptions$ = combineLatest(
            customer$,
            application$,
            products$,
            isDirect$,
            (selectedCustomer, appForm, products, isDirect) => {
                return { selectedCustomer, appForm, products, isDirect };
            }
        ).subscribe(({ selectedCustomer, appForm, products, isDirect }) => {
            this.isDirect = isDirect;

            this.pstAdvised = appForm.recordOfAdvice.pstAdvised;

            this.appForm = appForm;

            this.debitDates = PossibleDebitDates.filter(date => date.forProduct(appForm.portfolioTypeId));

            this.customerName = `${selectedCustomer.title} ${selectedCustomer.firstname} ${selectedCustomer.lastname}`;
            this.selectedCustomer = selectedCustomer;

            if (!this.bankAccountsLoadedOnInit) {
                this.bankAccountsLoadedOnInit = true;
                this.reloadBankAccounts();
            }

            this.minDebitOrder = minUnitTrustDebitOrder;
            this.maxDebitOrder = maxUnitTrustDebitOrder;
            this.minLumpSum = minUnitTrustLumpSum;
            this.maxLumpSum = maxUnitTrustLumpSum;

            let unitTrustPortfolioType =
                this.appForm.portfolioTypeId === appConfig.portfolioTypes.INV ||
                this.appForm.portfolioTypeId === appConfig.portfolioTypes.TFDS;

            if (!unitTrustPortfolioType) {
                products.forEach(product => {
                    this.products.set(product.id, product);
                });

                this.product = this.products.get(this.appForm.portfolioTypeId);
                this.minDebitOrder = minCashDebitOrder;
                this.maxDebitOrder = maxCashDebitOrder;
                this.minLumpSum = minCashLumpSum;
                this.maxLumpSum = maxCashLumpSum;
            }

            if (!this.investmentForm) {
                this.lumpSumSelected = !!appForm.lumpSumDetails;

                this.debitOrderSelected = !!appForm.debitOrderDetails;
                this.createForm();
            }
        });
    }

    public isSourceOfFundsOther(id) {
        const sourceType = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return sourceType && sourceType.isOther;
    }

    public ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public debitDayChange() {
        this.investmentForm.get('debitOrder.firstDebitDate').setValue(this.toFormDate(this.calculateFirstDebitDate()));
        this.investmentForm.get('debitOrder.firstEscalationDate').setValue(
            this.toFormDate(
                moment(this.calculateFirstDebitDate())
                    .add(1, 'years')
                    .toDate()
            )
        );
    }

    public lumpsumDateChanged() {
        if (this.isCash && this.appForm.debitOrderDetails) {
            let lumpSumDebitDate = this.dateFormatter.asMoment(this.investmentForm.get('lumpSum.debitDate').value);
            let firstRecurring = lumpSumDebitDate.clone().add(1, 'month');

            this.investmentForm.get('debitOrder.debitDate').setValue(lumpSumDebitDate.get('date'));
            this.investmentForm.get('debitOrder.firstDebitDate').setValue(this.toFormDate(firstRecurring));
        }
    }

    public readableAccountType(type: AccountType) {
        return AccountType[type];
    }

    public accountName(account: CustomerBankAccount) {
        return account.accountName || AccountType[account.accountType];
    }

    public reloadBankAccounts() {
        this.sharedStore.dispatch(new LoadingAction());
        this.bankAccountLoadingError = false;

        let bankAccounts$ = this.isDirect
            ? this.sharedStore.select(fromShared.selectUserBankAccounts)
            : this.customerService.getBankAccounts(this.selectedCustomer.ucn);

        bankAccounts$.subscribe(
            accounts => {
                this.bankAccounts = accounts.map(acc => {
                    acc.accountNumber = `${+acc.accountNumber}`;
                    return acc;
                });
                this.applicationStore.dispatch(new BankAccountListLoadedAction(accounts));
                this.sharedStore.dispatch(new StopLoadingAction());
            },
            () => {
                this.bankAccountLoadingError = true;
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        );
    }

    private calculateFirstDebitDate(defaultValue = 1): moment.Moment {
        const userDebitDay = this.investmentForm ? +this.investmentForm.get('debitOrder.debitDate').value : defaultValue;
        return this.applicationDateService.calculateFirstDebitDate(userDebitDay);
    }

    private toFormDate(date: Date | moment.Moment) {
        return this.dateFormatter.forDatePicker(moment(date));
    }

    private otherDescriptionRequired(id: number) {
        const type = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return type && type.isOther;
    }

    private validateSourceOfFunds(abstractForm: FormGroup): { [key: string]: any } {
        const sourceIdControl = abstractForm.get('sourceOfFundsId');
        const descriptionControl = abstractForm.get('otherDescription');

        const required = this.otherDescriptionRequired(+sourceIdControl.value);

        let valid = true;
        if (required) {
            const value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    }

    private validateMaxEscalationDate(first, second) {
        const errorControl = this.investmentForm.controls.debitOrder.get('firstEscalationDate');
        const firstDate = first ? moment(first) : null;
        const secondDate = second ? moment(second) : null;

        const valid =
            firstDate === null || secondDate === null || (!!firstDate && !!secondDate && secondDate.diff(firstDate, 'years', true) <= 1);
        const errorValue = valid ? null : { datemaxfrommin: valid ? null : firstDate.format('YYYY-MM-DD') };
        const afterMin = firstDate === null || secondDate === null || secondDate.isSameOrAfter(firstDate);

        const minErrorValue = afterMin ? null : { datemin: afterMin ? null : firstDate.format('YYYY-MM-DD') };

        if (errorControl) {
            if (errorValue) {
                errorControl.setErrors(errorValue);
            } else if (minErrorValue) {
                errorControl.setErrors(minErrorValue);
            } else {
                errorControl.setErrors(null);
            }
        }
    }

    private createForm() {
        const form: any = {};

        this.appForm.lumpSumDetails.forEach(lumpSumDetail => {
            this.totalLumpsum = this.totalLumpsum + lumpSumDetail.amount;
        });
        form.bankAccount = this.formBuilder.group({
            selectedAccount: [this.appForm.debitBankAccount, Validators.required],
        });
        form.enableLumpSum = this.formBuilder.control(true);
        form.enableDebitOrder = this.formBuilder.control(true);

        if (!!this.appForm.lumpSumDetails) {
            form.lumpSum = this.formBuilder.group(
                {
                    amount: [
                        this.numberFormatter.transformBigNumber(this.appForm.lumpSumDetails[0].amount),
                        Validators.compose([
                            Validators.required,
                            bigNumberMinValidator(this.minLumpSum),
                            bigNumberMaxValidator(this.maxLumpSum),
                        ]),
                    ],
                    debitDate: [
                        this.toFormDate(this.appForm.lumpSumDetails[0].debitDate),
                        Validators.compose([
                            Validators.required,
                            dateMinValidator(this.minDebitDate.toDate(), this.dateFormatter),
                            dateMaxValidator(this.maxDebitDate.toDate(), this.dateFormatter),
                        ]),
                    ],
                    sourceOfFundsId: [this.appForm.lumpSumDetails[0].sourceOfFunds.sourceOfFundsId, Validators.required],
                    otherDescription: [this.appForm.lumpSumDetails[0].sourceOfFunds.otherDescription],
                    fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
                },
                { validator: this.validateSourceOfFunds.bind(this) }
            );
        }
        if (!!this.appForm.debitOrderDetails) {
            form.debitOrder = this.formBuilder.group(
                {
                    amount: [
                        this.numberFormatter.transformBigNumber(this.appForm.debitOrderDetails.amount),
                        Validators.compose([
                            Validators.required,
                            bigNumberMinValidator(this.minDebitOrder),
                            bigNumberMaxValidator(this.maxDebitOrder),
                        ]),
                    ],

                    debitDate: [this.appForm.debitOrderDetails.debitDate, Validators.required],
                    firstDebitDate: [this.toFormDate(this.calculateFirstDebitDate(this.appForm.debitOrderDetails.debitDate))],
                    firstEscalationDate: [
                        this.toFormDate(this.appForm.debitOrderDetails.firstEscalationDate),
                        Validators.compose([Validators.required]),
                    ],
                    escalation: [
                        this.appForm.debitOrderDetails.escalation ? +this.appForm.debitOrderDetails.escalation : 6,
                        Validators.compose([
                            Validators.required,
                            bigNumberMinValidator(this.minIncrease),
                            bigNumberMaxValidator(this.maxIncrease),
                        ]),
                    ],
                    sourceOfFundsId: [this.appForm.debitOrderDetails.sourceOfFunds.sourceOfFundsId, Validators.required],
                    otherDescription: [this.appForm.debitOrderDetails.sourceOfFunds.otherDescription],
                    fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
                },
                { validator: this.validateSourceOfFunds.bind(this) }
            );
        }

        this.investmentForm = this.formBuilder.group(form);
        this.investmentForm.valueChanges.subscribe(changes => this.handleFormChanges(changes));
        this.investmentForm.statusChanges.subscribe(val => {
            this.applicationStore.dispatch(new InvestmentDetailsValidAction(val === 'VALID'));
        });
        this.lumpsumDateChanged();
    }

    private handleFormChanges(changes) {
        let debitOrderGroup = this.investmentForm.get('debitOrder');
        let lumpSumGroup = this.investmentForm.get('lumpSum');
        let debitOrderEnabled = this.investmentForm.value.enableDebitOrder;
        let lumpSumEnabled = this.investmentForm.value.enableLumpSum;

        if (debitOrderGroup) {
            if (debitOrderEnabled && debitOrderGroup.disabled) {
                debitOrderGroup.enable();
                return;
            }

            if (!debitOrderEnabled && debitOrderGroup.enabled) {
                debitOrderGroup.disable();
                return;
            }
        }

        if (lumpSumGroup) {
            if (lumpSumEnabled && lumpSumGroup.disabled) {
                lumpSumGroup.enable();
                return;
            }

            if (!lumpSumEnabled && lumpSumGroup.enabled) {
                lumpSumGroup.disable();
                return;
            }
        }

        let changesClone = {
            debitOrder: changes.debitOrder ? Object.assign({}, changes.debitOrder) : undefined,
            lumpSum: changes.lumpSum ? Object.assign({}, changes.lumpSum) : {},
            bankAccount: {
                selectedAccount:
                    changes.bankAccount && changes.bankAccount.selectedAccount && Object.assign(changes.bankAccount.selectedAccount),
            },
        };

        if (changesClone.debitOrder) {
            changesClone.debitOrder.firstDebitDate = this.dateFormatter.forApi(changes.debitOrder.firstDebitDate);
            changesClone.debitOrder.firstEscalationDate = this.dateFormatter.forApi(changes.debitOrder.firstEscalationDate);
        }

        if (changesClone.lumpSum) {
            let sourceOfFunds = new SourceOfFundsDeclaration();

            this.appForm.lumpSumDetails[0] = changesClone.lumpSum;
            changesClone.lumpSum.debitDate = this.dateFormatter.forApi(changesClone.lumpSum.debitDate);

            sourceOfFunds = {
                sourceOfFundsId: changesClone.lumpSum.sourceOfFundsId,
                otherDescription: changesClone.lumpSum.otherDescription,
            };

            this.appForm.lumpSumDetails[0].sourceOfFunds = sourceOfFunds;
        }

        this.applicationStore.dispatch(
            new AppFormUpdatedDebitOrderDetailsAction({
                lumpSumDetails: lumpSumEnabled ? changesClone.lumpSum : [],
                debitOrderDetails: debitOrderEnabled ? changesClone.debitOrder : undefined,
            })
        );

        this.applicationStore.dispatch(new AppFormUpdatedBankAccountAction(changes.bankAccount && changes.bankAccount.selectedAccount));
        if (changesClone.debitOrder) {
            this.validateMaxEscalationDate(changesClone.debitOrder.firstDebitDate, changesClone.debitOrder.firstEscalationDate);
        }
    }
}
