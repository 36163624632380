import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultipleChoiceOption } from 'shared/models';

@Component({
    selector: 'wim-multiple-choice-buttons',
    templateUrl: 'multiple-choice-buttons.component.pug',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => MultipleChoiceButtonsComponent),
            multi: true,
        },
    ],
    styleUrls: ['multiple-choice-buttons.component.scss'],
})
export class MultipleChoiceButtonsComponent implements ControlValueAccessor {
    public isDisabled: boolean;
    public form: FormGroup;
    private onChangeFunction;
    private onTouchFunction;

    @Input()
    public options: MultipleChoiceOption[];

    constructor(formBuilder: FormBuilder) {
        this.form = formBuilder.group({
            value: null,
        });
        this.form.valueChanges.subscribe(changes => {
            if (this.onChangeFunction) {
                this.onChangeFunction(changes.value);
            }
            if (this.onTouchFunction) {
                this.onTouchFunction(true);
            }
        });
    }

    public writeValue(value): void {
        this.form.setValue({ value });
    }

    public registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchFunction = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (isDisabled) {
            this.form.get('value').disable();
        } else {
            this.form.get('value').enable();
        }
    }
}
