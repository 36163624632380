var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
var AboutUsComponent = /** @class */ (function (_super) {
    __extends(AboutUsComponent, _super);
    function AboutUsComponent(loggerService, modalService) {
        var _this = _super.call(this, loggerService) || this;
        _this.loggerService = loggerService;
        _this.modalService = modalService;
        return _this;
    }
    AboutUsComponent.prototype.contactUs = function () {
        var modalRef = this.modalService.open(ContactBankerModalComponent, { windowClass: 'large-modal' });
    };
    AboutUsComponent.prototype.ngOnDestroy = function () { };
    return AboutUsComponent;
}(BaseComponent));
export { AboutUsComponent };
