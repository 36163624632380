var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterContentInit, ComponentFactoryResolver, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import * as fromApplication from 'application/store/application.store';
import { CashResultComponent } from 'pst/components/cash-result/cash-result.component';
import { ContextComponent } from 'pst/components/context/context.component';
import { CurrencyComponent } from 'pst/components/currency/currency.component';
import { InvestmentAccountResultComponent } from 'pst/components/investment-account-result/investment-account-result.component';
import { MessageComponent } from 'pst/components/message/message.component';
import { MultipleChoiceComponent } from 'pst/components/multiple-choice/multiple-choice.component';
import { NumberComponent } from 'pst/components/number/number.component';
import { TermComponent } from 'pst/components/term/term.component';
import { TextComponent } from 'pst/components/text/text.component';
import { InsertStepDirective } from 'pst/directives/insert-step.directive';
import { PstNodeTypes } from 'pst/models';
import { PstService } from 'pst/services';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, forkJoin } from 'rxjs';
import { BaseComponent } from 'shared/components';
import { LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { ClearPstTreeNodeAction, EnterPressedAction, PstErrorAction } from '../../store';
import { TermYearsComponent } from '../term-years/term-years.component';
var nodeTypeMappings = new Map();
var portfolioTypeMappings = new Map();
var PstWizardComponent = /** @class */ (function (_super) {
    __extends(PstWizardComponent, _super);
    function PstWizardComponent(componentFactoryResolver, pstService, pstStore, applicationStore, sharedStore, router, activatedRoute, lookupDataService, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.componentFactoryResolver = componentFactoryResolver;
        _this.pstService = pstService;
        _this.pstStore = pstStore;
        _this.applicationStore = applicationStore;
        _this.sharedStore = sharedStore;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.lookupDataService = lookupDataService;
        _this.loadNewTree = true;
        nodeTypeMappings.set(PstNodeTypes.result, InvestmentAccountResultComponent);
        nodeTypeMappings.set(PstNodeTypes.cashResult, CashResultComponent);
        nodeTypeMappings.set(PstNodeTypes.text, TextComponent);
        nodeTypeMappings.set(PstNodeTypes.multipleChoice, MultipleChoiceComponent);
        nodeTypeMappings.set(PstNodeTypes.number, NumberComponent);
        nodeTypeMappings.set(PstNodeTypes.currency, CurrencyComponent);
        nodeTypeMappings.set(PstNodeTypes.term, TermComponent);
        nodeTypeMappings.set(PstNodeTypes.context, ContextComponent);
        nodeTypeMappings.set(PstNodeTypes.termYears, TermYearsComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.INV, InvestmentAccountResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TFDS, InvestmentAccountResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.DDA81, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.DDA77, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOA95, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOA97, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOAMA32, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOAMA45, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOAMA90, CashResultComponent);
        portfolioTypeMappings.set(appConfig.portfolioTypes.TOA98, CashResultComponent);
        return _this;
    }
    PstWizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.pstStore.dispatch(new PstErrorAction(null));
        this.error$ = this.pstStore.select(fromPst.selectPstError);
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        var pstTree$ = this.sharedStore.select(fromPst.selectPstTree);
        var queryParams$ = this.activatedRoute.queryParams;
        var originIsDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        combineLatest(customer$, pstTree$, queryParams$, originIsDirect$, function (selectedCustomer, tree, params, isDirect) {
            return { selectedCustomer: selectedCustomer, tree: tree, params: params, isDirect: isDirect };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var selectedCustomer = _a.selectedCustomer, tree = _a.tree, params = _a.params, isDirect = _a.isDirect;
            if (selectedCustomer === null) {
                if (isDirect === false) {
                    _this.logger.warn('Cannot do PST before selecting a customer, navigating to customer search');
                    _this.router.navigateByUrl('/secure/banker/customer-search');
                    _this.sharedStore.dispatch(new StopLoadingAction());
                    _this.loadNewTree = false;
                    return;
                }
                else if (isDirect === true) {
                    _this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
                    _this.loadNewTree = false;
                    return;
                }
            }
            _this.customerName = selectedCustomer.title + " " + selectedCustomer.firstname + " " + selectedCustomer.lastname;
            var ctype;
            if (params['continue']) {
                _this.logger.info('Loading restored PST');
                _this.loadNewTree = false;
                var portfolioTypeId = +(+params['portfolioTypeId'] || appConfig.portfolioTypes.INV);
                ctype = portfolioTypeMappings.get(portfolioTypeId);
            }
            else if (!tree) {
                _this.loadNewTree = true;
                return;
            }
            else {
                _this.loadNewTree = true;
                _this.tree = tree;
                var parentNodeType = tree.node.nodeTypeCodeId;
                if (parentNodeType === PstNodeTypes.question) {
                    if (_this.tree.childNodes.length > 0) {
                        var childNodeType = _this.tree.childNodes[0].nodeTypeCodeId;
                        ctype = nodeTypeMappings.get(childNodeType);
                    }
                    else {
                        ctype = MessageComponent;
                    }
                }
                else {
                    ctype = nodeTypeMappings.get(parentNodeType);
                }
                _this.loadContent()
                    .pipe(_this.scavenger.collect())
                    .subscribe();
            }
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.loadComponent(ctype);
        });
        // Disabled for now
        // this.keyUpHandler = this.enterPressed.bind(this);
        // document.addEventListener('keyup', this.keyUpHandler);
    };
    PstWizardComponent.prototype.enterPressed = function (event) {
        if (event && event.keyCode === 13) {
            this.pstStore.dispatch(new EnterPressedAction(true));
        }
    };
    PstWizardComponent.prototype.ngOnDestroy = function () {
        this.pstStore.dispatch(new ClearPstTreeNodeAction());
        // Disabled for now
        // document.removeEventListener('keyup', this.keyUpHandler);
    };
    PstWizardComponent.prototype.ngAfterContentInit = function () {
        if (this.loadNewTree) {
            this.pstService.startNewTree();
        }
    };
    PstWizardComponent.prototype.loadComponent = function (component) {
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        if (this.host) {
            var viewContainerRef = this.host.viewContainerRef;
            if (this.componentRef && this.componentRef.ngOnDestroy) {
                this.componentRef.ngOnDestroy();
            }
            viewContainerRef.clear();
            // tslint:disable-next-line:no-unused-expression
            this.componentRef = viewContainerRef.createComponent(componentFactory).instance;
            this.componentRef.customerName = this.customerName;
        }
    };
    PstWizardComponent.prototype.loadContent = function () {
        return forkJoin(this.lookupDataService.getPstIcons(), this.lookupDataService.getPstTooltips(), this.lookupDataService.getPstResultContent());
    };
    return PstWizardComponent;
}(BaseComponent));
export { PstWizardComponent };
