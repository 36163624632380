import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Store as NgxsStore } from '@ngxs/store';
import appConfig from 'appConfig';
import { CustomerSearchResult } from 'application/models';
import { ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { BaseService } from 'shared/components';
import { DocumentContent, User } from 'shared/models';
import { UniqueIdContent } from 'shared/models/unique-id-content';
import { Base64Service, LoggerService } from 'shared/services';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { AdditionalLumpsum, AdviceRequest, AdviceResponse, RoboFieldId, RoboInput } from '../models';
import { RoboAdviceErrorAction, RoboAdviceUpdatedAction, RoboSavedAction, RoboSaveErrorAction, RoboStore } from '../store';

const ON_HOST_ACCOUNTS = 8;

@Injectable()
export class AdviceEngineService extends BaseService {
    private roboFields: { [key: number]: RoboInput } = {};
    private lumpsums: AdditionalLumpsum[];
    private isDirect = false;
    private selectedCustomer: CustomerSearchResult;
    private savedId: string = null;
    private existingOnHostCodes: string;

    constructor(
        loggerService: LoggerService,
        private store: NgxsStore,
        private http: HttpClient,
        ngrxStore: Store<SharedState>,
        private applicationStore: Store<ApplicationState>,
        private base64Service: Base64Service
    ) {
        super(loggerService);
        store
            .select(RoboStore.completedFields)
            .pipe(this.scavenger.collect())
            .subscribe(fields => (this.roboFields = fields));

        store
            .select(RoboStore.additionalLumpsums)
            .pipe(this.scavenger.collect())
            .subscribe(additionalLumpsums => (this.lumpsums = additionalLumpsums));

        store
            .select(RoboStore.saveAdviceId)
            .pipe(this.scavenger.collect())
            .subscribe(id => (this.savedId = id));

        ngrxStore.select(fromApplication.selectCustomerHostAccountCodes).subscribe(codes => (this.existingOnHostCodes = codes));

        ngrxStore.select(fromShared.selectIsDirect).subscribe(isDirect => (this.isDirect = isDirect));

        const customer$ = this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .subscribe(selected => (this.selectedCustomer = selected));
        this.registerSubscriptions(customer$);
    }

    public getAdvice(whenComplete?: () => void) {
        const request = this.buildPayload();
        const url = this.buildUcnSpecificUrl('/robo/advice/investment');

        this.http
            .post<AdviceResponse>(url, request)
            .pipe(
                catchError(error => {
                    this.store.dispatch(new RoboAdviceErrorAction(error));
                    return throwError(error);
                }),
                finalize(() => {
                    if (whenComplete) {
                        whenComplete();
                    }
                })
            )
            .subscribe(advice => {
                this.store.dispatch(new RoboAdviceUpdatedAction(advice));
            });
    }

    public saveForLater(whenComplete?: () => void) {
        const request = this.buildPayload();
        const url = this.buildUcnSpecificSaveForlaterUrl('/robo/advice/save-for-later');

        let params = this.savedId
            ? {
                  previousSaveId: this.savedId,
              }
            : {};

        this.http
            .post<string>(url, request, { params, responseType: 'text' as 'json' })
            .pipe(
                catchError(error => {
                    this.store.dispatch(new RoboSaveErrorAction(error));
                    return throwError(error);
                }),
                finalize(() => {
                    if (whenComplete) {
                        whenComplete();
                    }
                })
            )
            .subscribe(id => {
                this.store.dispatch(new RoboSavedAction(id));
            });
    }

    public download(taxFreeOptedOut: boolean): Observable<DocumentContent> {
        const request = this.buildPayload();
        const url = this.buildUcnSpecificUrl('/robo/advice/download');

        return this.http
            .post<DocumentContent>(url, request, { params: { taxFreeOptedOut: `${taxFreeOptedOut}` } })
            .pipe(map(document => Object.assign(document, { dataAsBytes: this.base64Service.decodeArrayBuffer(document.data) })));
    }

    public email(uniqueId: string, emailAddress: string): Observable<any> {
        const request = {
            emailAddress,
            id: uniqueId,
        };
        const url = `${appConfig.apiUrl}/robo/advice/email-advice`;

        return this.http.post(url, request);
    }

    public prepareDownload(taxFreeOptedOut: boolean): Observable<UniqueIdContent> {
        const request = this.buildPayload();
        const url = this.buildUcnSpecificUrl('/robo/advice/prepare-download');

        return this.http.post<UniqueIdContent>(url, request, { params: { taxFreeOptedOut: `${taxFreeOptedOut}` } });
    }

    private buildUcnSpecificUrl(url) {
        let newUrl = `${appConfig.apiUrl}${url}`;
        if (!this.isDirect) {
            newUrl = `${newUrl}/${this.selectedCustomer && this.selectedCustomer.ucn}`;
        }
        newUrl = `${newUrl}/${this.selectedCustomer && this.selectedCustomer.age}`;
        return newUrl;
    }
    private buildUcnSpecificSaveForlaterUrl(url) {
        let newUrl = `${appConfig.apiUrl}${url}`;
        if (!this.isDirect) {
            newUrl = `${newUrl}/${this.selectedCustomer && this.selectedCustomer.ucn}`;
        }
        return newUrl;
    }

    public convertObjectToArrayOfFields(fields) {
        let activeFields = [];
        for (let field of Object.keys(fields)) {
            if (this.roboFields[field].active) {
                activeFields.push({
                    fieldId: field,
                    value: this.roboFields[field].value,
                    index: 0,
                });
            }
        }

        // The first lumpsum should be entered on the form (in activeFields) then addititonal start at index 1.
        // But if the user misses the first textboxes and inputs only additional, this will catch that
        let additionalLumpsumOffset = activeFields.find(field => +field.fieldId === RoboFieldId.lumpSumAmount) ? 1 : 0;
        for (let i = 0; i < this.lumpsums.length; i++) {
            activeFields.push(
                {
                    fieldId: RoboFieldId.lumpSumAmount,
                    value: this.lumpsums[i].amount,
                    index: i + additionalLumpsumOffset,
                },
                {
                    fieldId: RoboFieldId.lumpSumDate,
                    value: this.lumpsums[i].date,
                    index: i + additionalLumpsumOffset,
                }
            );
        }
        return activeFields;
    }

    public getContextualData() {
        let contextualData = {};
        contextualData[ON_HOST_ACCOUNTS] = this.existingOnHostCodes;
        return contextualData;
    }

    private buildPayload(): AdviceRequest {
        return {
            fields: this.convertObjectToArrayOfFields(this.roboFields),
            contextualData: this.getContextualData(),
        };
    }

    public ngOnDestroy() {}
}
