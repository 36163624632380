import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NumberFormatterService } from 'shared/services/number-formatter.service';

@Directive({ selector: '[wimPercentFormatter]' })
export class PercentFormatterDirective implements OnInit {
    @Input() public format = '1.0-0';

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef, private numberFormatter: NumberFormatterService) {
        this.el = this.elementRef.nativeElement;
    }

    public ngOnInit() {
        if (this.el.value.indexOf('%') >= 0) {
            return;
        }
        this.el.value = this.numberFormatter.transformPercent(this.el.value, this.format);
    }

    @HostListener('focus', ['$event.target.value'])
    public onFocus(value: string) {
        this.el.value = this.numberFormatter.parsePercent(value);
        this.el.type = 'number';
    }

    @HostListener('blur', ['$event.target.value'])
    public onBlur(value) {
        this.el.type = 'text';
        this.el.value = this.numberFormatter.transformPercent(value, this.format);
    }
}
