import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { InvestmentAccount } from 'investor/models';
import { AccountSelectedAction } from 'investor/store/account.reducers';
import { InvestorState } from 'investor/store/investor.store';

import * as AccountActions from 'investor/store/account.reducers';
import * as fromInvestor from 'investor/store/investor.store';
import { ShowMenusAction } from 'shared/store/layout.reducers';

import { map, mergeMap } from 'rxjs/operators';
import { FeatureFlags } from 'shared/models';
import { FeatureFlagService } from 'shared/services';

@Injectable()
export class AccountEffects {
    @Effect()
    public accountSelected$: Observable<Action> = this.actions$.ofType(AccountActions.ACCOUNT_NUMBER_SELECTED).pipe(
        map((action: AccountActions.AccountNumberSelectedAction) => action.payload),
        mergeMap(payload => this.findAccount(payload))
    );

    @Effect()
    public accountsLoaded$: Observable<Action> = this.actions$.ofType(AccountActions.INVESTMENT_ACCOUNTS_LOADED).pipe(
        map((action: AccountActions.InvestmentAccountsLoadedAction) => action.payload),
        mergeMap(payload => this.showInvestorMenus(payload))
    );

    private accounts: InvestmentAccount[];

    constructor(private actions$: Actions, private investorStore: Store<InvestorState>, private featueFlagService: FeatureFlagService) {
        this.investorStore.select(fromInvestor.selectInvestmentAccounts).subscribe(accounts => (this.accounts = accounts));
    }

    private findAccount(accountNumber: string): Observable<Action> {
        const selectedAccount = this.accounts.filter(account => account.accountNumber === accountNumber)[0];
        return of(new AccountSelectedAction(selectedAccount));
    }

    private showInvestorMenus(accounts: InvestmentAccount[]): Observable<Action> {
        if (accounts && accounts.length > 0) {
            let menusToShow = [];
            if (this.featueFlagService.isEnabled(FeatureFlags.MaintenanceInstructions)) {
                menusToShow.push('instructions');
            }
            if (this.featueFlagService.isEnabled(FeatureFlags.DownloadDocuments)) {
                menusToShow.push('documents');
            }
            return of(new ShowMenusAction(menusToShow));
        }
        return of();
    }
}
