import { ElementRef, Renderer } from '@angular/core';
var AutoSubmitDirective = /** @class */ (function () {
    function AutoSubmitDirective(element, renderer) {
        this.element = element;
        this.renderer = renderer;
    }
    Object.defineProperty(AutoSubmitDirective.prototype, "readyToSubmit", {
        set: function (submit) {
            var _this = this;
            if (submit) {
                setTimeout(function () { return _this.element.nativeElement.submit(); }, 0);
                submit = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AutoSubmitDirective;
}());
export { AutoSubmitDirective };
