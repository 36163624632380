var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { ContactService } from 'sales/services/contact.service';
import { BaseComponent } from 'shared/components/base';
import { Brand } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var ContactUsComponent = /** @class */ (function (_super) {
    __extends(ContactUsComponent, _super);
    function ContactUsComponent(loggerService, store, formBuilder, toastr, contactService, modalService) {
        var _this = _super.call(this, loggerService) || this;
        _this.loggerService = loggerService;
        _this.store = store;
        _this.formBuilder = formBuilder;
        _this.toastr = toastr;
        _this.contactService = contactService;
        _this.modalService = modalService;
        _this.showContactForm = true;
        _this.noTitleSelected = false;
        _this.noFeedbackTypeSelected = false;
        _this.titleList = [
            { value: 'Mr', description: 'Mr' },
            { value: 'Ms', description: 'Ms' },
            { value: 'Mrs', description: 'Mrs' },
            { value: 'Rev', description: 'Rev' },
            { value: 'Prof', description: 'Prof' },
            { value: 'Dr', description: 'Dr' },
            { value: 'Adv', description: 'Adv' },
            { value: 'Hon', description: 'Hon' },
        ];
        _this.feedbackTypeList = [
            { value: 'Compliments', description: 'Compliments' },
            { value: 'Complaints', description: 'Complaints' },
            { value: 'Queries', description: 'Queries' },
        ];
        return _this;
    }
    ContactUsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.brandSubscription = this.store.select(fromShared.selectBrand).subscribe(function (k) { return _this.setBrand(k); });
        if (!this.contactForm) {
            this.createContactForm();
        }
    };
    ContactUsComponent.prototype.ngOnDestroy = function () {
        if (this.brandSubscription) {
            this.brandSubscription.unsubscribe();
        }
    };
    ContactUsComponent.prototype.toggleContactForm = function () {
        return (this.showContactForm = !this.showContactForm);
    };
    ContactUsComponent.prototype.canSubmit = function () {
        if (!this.contactForm.value.feedbackType) {
            this.noFeedbackTypeSelected = true;
        }
        else {
            this.noFeedbackTypeSelected = false;
        }
        if (!this.contactForm.value.title) {
            this.noTitleSelected = true;
        }
        else {
            this.noTitleSelected = false;
        }
        return this.contactForm.valid;
    };
    ContactUsComponent.prototype.submit = function () {
        var _this = this;
        this.store.dispatch(new LoadingAction());
        var contactModel = this.contactForm.value;
        this.contactService.sendFeedbackForm(contactModel).subscribe(function (result) {
            _this.store.dispatch(new StopLoadingAction());
            if (result.success) {
                _this.resetForm();
                _this.toastr.success(result.message);
            }
            else {
                _this.toastr.error(result.message);
            }
        });
    };
    ContactUsComponent.prototype.contactUs = function () {
        var modalRef = this.modalService.open(ContactBankerModalComponent, { windowClass: 'large-modal' });
    };
    ContactUsComponent.prototype.createContactForm = function () {
        this.contactForm = this.formBuilder.group({
            title: ['', Validators.required],
            firstName: ['', Validators.required],
            surname: ['', Validators.required],
            idNumber: [''],
            email: ['', [Validators.required, Validators.email]],
            phoneNumber: ['', Validators.required],
            feedbackType: ['', Validators.required],
            message: ['', Validators.required],
            brand: [this.currentInstance],
        });
    };
    ContactUsComponent.prototype.resetForm = function () {
        this.contactForm.reset();
    };
    ContactUsComponent.prototype.setBrand = function (activeBrand) {
        switch (activeBrand) {
            case Brand.RMB: {
                this.currentInstance = 'RMB';
                break;
            }
            default: {
                this.currentInstance = 'FNB';
                break;
            }
        }
    };
    return ContactUsComponent;
}(BaseComponent));
export { ContactUsComponent };
