import { QuestionBase } from './question-base';

export class CheckboxQuestion extends QuestionBase<boolean> {
    public controlType = 'checkbox';

    constructor(options: {} = {}) {
        super(options);
        // this.type = options['type'] || 'checkbox';
    }
}
