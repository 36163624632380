import { Injectable } from '@angular/core';
import { PstResult } from 'pst/models';

import { QuestionPart } from '.';

const tokens: Map<RegExp, QuestionPart> = new Map([
    [/{{CURRENCY_SYMBOL_ZAR}}/, { type: 'currency', value: 'R' }],
    [/{{INPUT:(.*)}}/, { type: 'input', value: 'value' }],
    [/{{INPUT_(.*)}}/, { type: 'input', value: 'value' }],
]);

@Injectable()
export class PstParserService {
    public parseParameterizedString(value: string, pstResult: PstResult): string {
        // e.g. How much do you want to invest for {{GOAL_NAME}}
        let newValue = value;
        const params = /{{(.*)}}/.exec(value);
        if (!params) {
            return newValue;
        }
        for (let i = 1; i < params.length; i++) {
            const paramName = params[i];
            newValue = newValue.replace(`{{${paramName}}}`, pstResult.capturedValues[paramName]);
        }
        return newValue;
    }

    public extractContextType(value: string): string {
        // e.g. {{INPUT:USER_TYPE}}
        let newValue = value;
        const params = /{{INPUT:(.*)}}/.exec(value);
        if (!params) {
            return newValue;
        }
        return params[1];
    }

    public extractChildNodeValue(longDescription: string): string {
        // e.g. [[VALUE:100]]
        const valueRegex = /\[\[VALUE:(.*)\]\]/;
        const matchingToken = this.testAllTokens(valueRegex, longDescription, '|')[0];

        if (matchingToken) {
            return matchingToken[1];
        }
        return '';
    }

    public extractRange(longDescription: string): [number, number] {
        // e.g. [[RANGE:min,max]]
        const rangeRegex = /\[\[RANGE:(.*)\]\]/;

        const matchingToken = this.testAllTokens(rangeRegex, longDescription, '|')[0];

        if (matchingToken) {
            const range = matchingToken[1].split(',');
            return [+range[0], +range[1]];
        }
        return null;
    }

    public extractTermSwitch(longDescription: string): number {
        // e.g. [[SWITCH_POINT:months]]
        const switchRegex = /\[\[SWITCH_POINT:(.*)\]\]/;

        const matchingToken = this.testAllTokens(switchRegex, longDescription, '|')[0];

        if (matchingToken) {
            return +matchingToken[1];
        }
        return 0;
    }

    public extractMaxOverride(longDescription: string): number {
        // e.g. [[SLIDER_OVERRIDE]]
        const overrideRegex = /\[\[SLIDER_OVERRIDE:(.*)\]\]/;

        const matchingToken = this.testAllTokens(overrideRegex, longDescription, '|')[0];
        if (matchingToken) {
            return +matchingToken[1];
        }
        return null;
    }

    public extractDefaultValue(longDescription: string): string {
        // e.g. [[DEFAULT:min]]
        const defaultRegex = /\[\[DEFAULT:(.*)\]\]/;

        const matchingToken = this.testAllTokens(defaultRegex, longDescription, '|')[0];
        if (matchingToken) {
            return matchingToken[1];
        }
        return null;
    }

    public buildInputDescription(description: string): QuestionPart[] {
        // e.g. I have a Lump Sum of |{{CURRENCY_SYMBOL_ZAR}}|{{ROUND_NUMBER_INPUT}}
        const parts = description
            .split('|')
            .map(part => part.trim())
            .filter(part => part.length > 0);

        const parsedParts: QuestionPart[] = [];

        for (const descriptionPart of parts) {
            let part = null;
            tokens.forEach((value, key) => {
                if (key.test(descriptionPart)) {
                    part = Object.assign({}, value);
                    part.property = key.exec(descriptionPart)[1];
                }
            });
            if (part === null) {
                part = {
                    type: 'text',
                    value: descriptionPart,
                };
            }
            parsedParts.push(part);
        }
        return parsedParts;
    }

    private testAllTokens(regex: RegExp, extractFrom: string, delimiter: string): RegExpExecArray[] {
        const tokensInString = extractFrom.split(delimiter);
        return tokensInString.map(token => regex.exec(token)).filter(result => !!result && !!result[1]);
    }
}
