import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

import { DateFormatterService } from '../services';

export function dateMaxValidator(maximumDate: Date, dateFormatter: DateFormatterService): ValidatorFn {
    const max = maximumDate ? moment(maximumDate) : null;

    return (control: AbstractControl): { [key: string]: any } => {
        let date;
        let value = control.value;
        if (value && typeof value === 'object') {
            date = dateFormatter.asMoment(value);
        } else {
            date = moment(value);
        }
        if (!date.isValid()) {
            return { required: true };
        }
        const beforeMax = max === null || date.isSameOrBefore(max);

        return beforeMax ? null : { datemax: beforeMax ? null : max.format('YYYY-MM-DD') };
    };
}
