import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AboutUsComponent } from 'sales/components/about';
import { ContactUsComponent } from 'sales/components/contact';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { SalesComponent } from 'sales/components/sales';
import { SalesRoutingModule } from 'sales/sales-routing.module';
import { ContactService } from 'sales/services/contact.service';
import { SharedModule } from 'shared/shared.module';

const MODULE_COMPONENTS = [AboutUsComponent, ContactUsComponent, ContactBankerModalComponent, SalesComponent];

const MODULE_SERVICES = [ContactService];

@NgModule({
    imports: [CommonModule, SharedModule, NgxErrorsModule, SalesRoutingModule, ReactiveFormsModule, NgxPageScrollModule, NgbTabsetModule],
    declarations: [...MODULE_COMPONENTS],
    exports: [...MODULE_COMPONENTS],
    providers: [...MODULE_SERVICES],
})
export class SalesModule {}
