import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { LoggerService } from '../../services/logger.service';
import { BaseComponent } from '../base';

const MONTHS_IN_YEAR = 12;

@Component({
    selector: 'wim-term-slider-input',
    templateUrl: 'term-slider-input.component.pug',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => TermSliderInputComponent),
            multi: true,
        },
    ],
    styleUrls: ['term-slider-input.component.scss'],
})
export class TermSliderInputComponent extends BaseComponent implements ControlValueAccessor, OnChanges {
    public isDisabled: boolean;
    public form: FormGroup;

    public sliderValue = 0;

    @Input()
    public min = 1;

    @Input()
    public max = 12;

    @Input()
    public switchPoint = 24;

    @Input()
    public prefix: string = null;

    @Input()
    public suffix: string = null;

    @Output()
    public valueChange = new EventEmitter();

    public step = 1;

    public minYears: number;
    public maxYears: number;
    public minMonths = 0;
    public maxMonths = 11;

    private oldSliderValue = 0;

    private onChangeFunction;
    private onTouchFunction;

    constructor(formBuilder: FormBuilder, loggerService: LoggerService) {
        super(loggerService);
        this.maxYears = this.monthsToYears(this.max, MONTHS_IN_YEAR);
        this.minYears = this.monthsToYears(this.min, MONTHS_IN_YEAR);

        this.form = formBuilder.group({
            years: [0, this.getValidators(this.minYears, this.maxYears)],
            months: [0, this.getValidators(this.minMonths, this.maxMonths)],
        });

        this.form.valueChanges.subscribe(changes => this.handleFormChanges(changes));
    }

    public ngOnChanges(changes) {
        if (changes.min || changes.max) {
            this.maxYears = this.monthsToYears(this.max, MONTHS_IN_YEAR);
            this.minYears = this.monthsToYears(this.min, MONTHS_IN_YEAR);
            this.updateValidators(this.form.value.years, this.form.value.months);
        }
    }

    public ngOnDestroy() {}

    private handleFormChanges(changes) {
        let newMonths = +changes.months || 0;
        let newYears = +changes.years;

        let totalMonths = newYears * MONTHS_IN_YEAR + newMonths;
        this.sliderValue = totalMonths;
        if (totalMonths < this.oldSliderValue) {
            // Slider is moving left (i.e. down)
            if (totalMonths < this.switchPoint) {
                this.step = 1;
            } else {
                this.step = MONTHS_IN_YEAR;
                // Bump up to next full year
                totalMonths = newYears * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
        } else {
            // Slider is moving right (i.e. up)
            if (totalMonths <= this.switchPoint) {
                this.step = 1;
            } else {
                this.step = MONTHS_IN_YEAR;
            }
        }

        let currentMonths = +this.form.get('months').value;
        if ((totalMonths > this.switchPoint) || (this.min > this.switchPoint)) {
            if (currentMonths !== 0) {
                this.form.get('months').setValue(0, { emitEvent: false });
                let years = +this.form.get('years').value + 1;
                this.form.get('years').setValue(years, { emitEvent: false });
                totalMonths = years * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
            this.form.get('months').disable({ emitEvent: false });
        } else {
            this.form.get('months').enable({ emitEvent: false });
        }
        this.oldSliderValue = totalMonths;

        this.updateValidators(newYears, newMonths);

        if (this.onChangeFunction) {
            this.onChangeFunction(totalMonths);
        }
        if (this.onTouchFunction) {
            this.onTouchFunction();
        }

        this.valueChange.emit(totalMonths);
    }

    private monthsToYears(numerator: number, denominator: number): number {
        return Math.floor(numerator / denominator);
    }

    private updateValidators(years: number, months: number) {
        if(this.min === 1) {
            this.minMonths = years === 0 ? 1 : 0;
            this.minYears = months === 0 ? 1 : 0;
        }
        this.form.get('months').setValidators(this.getValidators(this.minMonths, this.maxMonths));
        this.form.get('months').updateValueAndValidity({ emitEvent: false });
        this.form.get('years').setValidators(this.getValidators(this.minYears, this.maxYears));
        this.form.get('years').updateValueAndValidity({ emitEvent: false });

        this.form.validator = this.validateTotalMonths.bind(this);
    }

    private validateTotalMonths(abstractForm: FormGroup) {
        const monthsControl = abstractForm.get('months');
        const yearsControl = abstractForm.get('years');

        const totalMonths = this.sliderValue;
        if (totalMonths < this.min) {
            monthsControl.setErrors({
                totalMin: true,
            });
            return { totalMin: true };
        } else if (totalMonths > this.max) {
            yearsControl.setErrors({
                max: true,
            });
            return { max: true };
        } else {
            monthsControl.setErrors(null);
            yearsControl.setErrors(null);
            return null;
        }
    }    

    private getValidators(min: number, max: number) {
        return Validators.compose([Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(min), Validators.max(max)]);
    }

    public writeValue(value: number): void {
        this.sliderValue = value;
        this.sliderMove({ value });
    }

    public registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchFunction = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.get('months').disable();
            this.form.get('years').disable();
        } else {
            this.form.get('months').enable();
            this.form.get('years').enable();
        }
    }

    public sliderMove(event) {
        let years = Math.floor(+event.value / MONTHS_IN_YEAR);
        let months = +event.value % MONTHS_IN_YEAR;

        this.form.controls.years.setValue(years, { emitEvent: false });
        this.form.controls.months.setValue(months);
    }
}
