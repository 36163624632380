var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { BaseComponent, SessionExpiringModalComponent } from 'shared/components';
import { Brand } from 'shared/models';
import { AuthenticationService, AuthTokenService, LoggerService, LookupDataService, SessionService } from 'shared/services';
import { BrandDetectedAction, HideSmallHeaderAction, HideTransparentHeaderAction, InvestorHelperToggleButtonAction, NavigationAction, SessionExpiringAction, SharedState, ShowSmallHeaderAction, } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(sharedStore, tokenService, router, loggerService, lookupService, authService, sessionService, modalService, pageScrollService, document) {
        var _this = _super.call(this, loggerService) || this;
        _this.sharedStore = sharedStore;
        _this.tokenService = tokenService;
        _this.router = router;
        _this.loggerService = loggerService;
        _this.lookupService = lookupService;
        _this.authService = authService;
        _this.sessionService = sessionService;
        _this.modalService = modalService;
        _this.pageScrollService = pageScrollService;
        _this.document = document;
        _this.investorHelperToggled = false;
        _this.isPstRoute = false;
        _this.isSalesPage = false;
        _this.brandLoaded = false;
        _this.shownWelcome = false;
        _this.lastScrollY = 0;
        _this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd && window.ga) {
                window.ga('set', 'page', event.urlAfterRedirects);
                window.ga('send', 'pageview');
            }
        });
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tokenService.init();
        this.lookupService.getHorizonFunds();
        this.authService.checkIfBankerLoginAllowed();
        this.sessionService.start();
        this.user$ = this.sharedStore.select(fromShared.selectUser);
        this.isLoggedIn$ = this.sharedStore.select(fromShared.selectAuthenticated);
        this.sharedStore
            .select(fromShared.selectBrand)
            .pipe(this.scavenger.collect())
            .subscribe(function (brand) { return _this.setBrand(brand); });
        this.user$.pipe(this.scavenger.collect()).subscribe(function (k) { return _this.setIsBanker(k); });
        this.sharedStore
            .select(fromShared.selectShowFullWidth)
            .pipe(this.scavenger.collect())
            .subscribe(function (fullWidth) { return (_this.isSalesPage = fullWidth); });
        this.router.events.pipe(this.scavenger.collect()).subscribe(function (event) {
            if (!_this.brandLoaded && event instanceof NavigationStart) {
                var brand = location.host === appConfig.brands.rmb
                    ? Brand.RMB
                    : location.host === appConfig.brands.fnb
                        ? Brand.FNB
                        : Brand.Undetected;
                _this.sharedStore.dispatch(new BrandDetectedAction(brand));
                _this.brandLoaded = true;
            }
            if (event instanceof NavigationCancel) {
                _this.sharedStore.dispatch(new NavigationAction(_this.router.url));
            }
            if (event instanceof NavigationEnd) {
                _this.sharedStore.dispatch(new HideSmallHeaderAction());
                if (event.url.includes('pst') || event.url.includes('customer-search')) {
                    _this.isPstRoute = true;
                }
                if (event.url.includes('banker-login') || _this.isBanker || event.url === '/') {
                    _this.sharedStore.dispatch(new InvestorHelperToggleButtonAction(false));
                }
                else {
                    _this.canShowBankerHelper = false;
                    _this.sharedStore.dispatch(new InvestorHelperToggleButtonAction(true));
                }
                if (/\/invest($|\?)/.test(event.url)) {
                    //this.sharedStore.dispatch(new ShowTransparentHeaderAction());
                    _this.sharedStore.dispatch(new HideTransparentHeaderAction());
                }
                else {
                    _this.sharedStore.dispatch(new HideTransparentHeaderAction());
                }
                _this.scrollToTop();
            }
        });
        this.sharedStore
            .select(fromShared.selectSessionExpiring)
            .pipe(this.scavenger.collect())
            .subscribe(function (sessionExpiring) {
            if (sessionExpiring) {
                _this.modalService.open(SessionExpiringModalComponent);
                _this.sharedStore.dispatch(new SessionExpiringAction(false));
            }
        });
        window.addEventListener('resize', function () { return _this.checkHeader(); });
        window.addEventListener('scroll', function () { return _this.checkHeader(); });
    };
    AppComponent.prototype.scrollToTop = function () {
        var scrollInstance = PageScrollInstance.newInstance({
            document: this.document,
            scrollTarget: '#top-marker',
            pageScrollDuration: 1,
            pageScrollOffset: 100,
        });
        this.pageScrollService.start(scrollInstance);
    };
    AppComponent.prototype.checkHeader = function () {
        if (window.scrollY > 100) {
            if (this.lastScrollY <= 100) {
                this.sharedStore.dispatch(new ShowSmallHeaderAction());
            }
        }
        else {
            if (this.lastScrollY > 100) {
                this.sharedStore.dispatch(new HideSmallHeaderAction());
            }
        }
        this.lastScrollY = window.scrollY;
    };
    AppComponent.prototype.ngOnDestroy = function () { };
    AppComponent.prototype.setBrand = function (brand) {
        this.initLog(brand);
        switch (brand) {
            case Brand.RMB: {
                require('style-loader!../assets/css/rmb.css');
                require('style-loader!../assets/css/rmb-ext.css');
                break;
            }
            default: {
                require('style-loader!../assets/css/fnb.css');
                require('style-loader!../assets/css/fnb-ext.css');
                break;
            }
        }
    };
    AppComponent.prototype.setIsBanker = function (user) {
        if (user) {
            this.isBanker = user.isBanker;
        }
        else {
            this.isBanker = false;
        }
    };
    AppComponent.prototype.initLog = function (brand) {
        if (this.shownWelcome) {
            this.logger.info("Brand: " + Brand[brand]);
        }
        else {
            this.loggerService.welcome(brand);
            if (brand !== Brand.Undetected) {
                this.logger.info("Brand: " + Brand[brand]);
            }
            this.logger.info("Environment: " + appConfig.env);
            this.logger.info("Version: " + appConfig.version);
            this.logger.info("Instance: " + appConfig.serverInstance);
        }
        this.shownWelcome = true;
    };
    return AppComponent;
}(BaseComponent));
export { AppComponent };
