/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loader.component";
import * as i2 from "@ngrx/store";
var styles_LoaderComponent = [".loader-container[_ngcontent-%COMP%] {\n                background-color: white;\n                width: 100%;\n                height: 100%;\n                position: fixed;\n                top: 0;\n                left: 0;\n                z-index: 2000;\n                opacity: 0.8;\n            }"];
var RenderType_LoaderComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "loader-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "loader__figure"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [["class", "loader__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please wait"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hidden; _ck(_v, 0, 0, currVal_0); }); }
export function View_LoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.LoaderComponent, [i2.Store], null, null)], null, null); }
var LoaderComponentNgFactory = i0.ɵccf("wim-loader", i1.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
