import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { NgxsModule } from '@ngxs/store';
import { ChartModule } from 'angular-highcharts';
import { ApplicationModule } from 'application/application.module';

import { SharedModule } from '../shared/shared.module';

import {
    DisclaimerModalComponent,
    TaxFreeDisclaimerModalComponent,
    GoalSelectionOtherNameModalComponent,
    RoboAdviceComponent,
    RoboInputCaptureComponent,
    RoboProjectedPerformanceComponent,
} from './components';
import { AdviceDownloadModalComponent } from './components/advice-download-modal';
import { RoboGoalSelectionComponent } from './components/goal-selection/goal-selection.component';
import { ValidationModalComponent } from './components/validation-modal';
import { RoboRoutingModule } from './robo-routing.module';
import { AdviceEngineService } from './services/advice-engine.service';
import { RoboStore } from './store';

const MODULE_COMPONENTS = [
    RoboGoalSelectionComponent,
    RoboAdviceComponent,
    GoalSelectionOtherNameModalComponent,
    GoalSelectionOtherNameModalComponent,
    RoboInputCaptureComponent,
    RoboProjectedPerformanceComponent,
    DisclaimerModalComponent,
    TaxFreeDisclaimerModalComponent,
    ValidationModalComponent,
    AdviceDownloadModalComponent,
];

const MODULE_SERVICES = [AdviceEngineService];

const MODULE_DIRECTIVES = [];

const STATE = [RoboStore];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        RoboRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        ChartModule,
        NgxErrorsModule,
        SharedModule,
        ApplicationModule,
        StoreModule,
        NgxsModule.forFeature(STATE),
    ],
    declarations: [...MODULE_COMPONENTS, ...MODULE_DIRECTIVES],
    exports: [],
    providers: [...MODULE_SERVICES],
    entryComponents: [
        GoalSelectionOtherNameModalComponent,
        DisclaimerModalComponent,
        TaxFreeDisclaimerModalComponent,
        ValidationModalComponent,
        AdviceDownloadModalComponent,
    ],
})
export class RoboModule {}
