var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseComponent } from 'shared/components/base';
import { InstructionConfirmationModalComponent } from '../confirmation-modal';
var MESSAGE_SUCCESS = 'Your instruction was successfully submitted.';
var MESSAGE_FAILURE = 'Your instruction could not be submitted.';
var BaseInstructionComponent = /** @class */ (function (_super) {
    __extends(BaseInstructionComponent, _super);
    function BaseInstructionComponent(toastr, loggerService, modalService) {
        var _this = _super.call(this, loggerService) || this;
        _this.toastr = toastr;
        _this.modalService = modalService;
        return _this;
    }
    BaseInstructionComponent.prototype.handleResponse = function (result, title) {
        if (result.success) {
            this.cancel();
            this.showModal(title, MESSAGE_SUCCESS, true);
        }
        else {
            this.showModal(title, MESSAGE_FAILURE, false);
        }
    };
    BaseInstructionComponent.prototype.showModal = function (title, message, success) {
        var _this = this;
        var modal = this.modalService.open(InstructionConfirmationModalComponent);
        modal.componentInstance.title = title;
        modal.componentInstance.message = message;
        modal.componentInstance.success = success;
        modal.componentInstance.retry = function () {
            modal.close();
            _this.submit();
        };
    };
    BaseInstructionComponent.prototype.handleSubmitError = function (title) {
        this.showModal(title, MESSAGE_FAILURE, false);
    };
    BaseInstructionComponent.prototype.markAsTouched = function (controlGroup) {
        this.markControls(controlGroup, 'markAsTouched');
    };
    BaseInstructionComponent.prototype.markAsPristine = function (controlGroup) {
        this.markControls(controlGroup, 'markAsPristine');
    };
    BaseInstructionComponent.prototype.markControls = function (controlGroup, markAsFunction) {
        if (controlGroup && controlGroup[markAsFunction]) {
            controlGroup[markAsFunction]();
            if (controlGroup.controls) {
                for (var _i = 0, _a = Object.keys(controlGroup.controls); _i < _a.length; _i++) {
                    var controlName = _a[_i];
                    var control = controlGroup.get(controlName);
                    this.markControls(control, markAsFunction);
                }
            }
        }
    };
    return BaseInstructionComponent;
}(BaseComponent));
export { BaseInstructionComponent };
