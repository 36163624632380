/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./onboarding-complete.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../services/application.service";
import * as i6 from "ngx-toastr";
import * as i7 from "../../../../shared/services/lookup-data.service";
var styles_HorizonOnboardingCompleteComponent = [];
var RenderType_HorizonOnboardingCompleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HorizonOnboardingCompleteComponent, data: {} });
export { RenderType_HorizonOnboardingCompleteComponent as RenderType_HorizonOnboardingCompleteComponent };
function View_HorizonOnboardingCompleteComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["Reference Number: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.referenceNumber; _ck(_v, 3, 0, currVal_0); }); }
function View_HorizonOnboardingCompleteComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please enter and submit the optional telephone call reference number where applicable"]))], null, null); }
function View_HorizonOnboardingCompleteComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 15, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 11, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 10, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "label", [["class", "form-control-label col-form-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Phone Reference:"])), (_l()(), i0.ɵeld(10, 0, null, null, 7, "input", [["class", "form-control form-control-sm"], ["formControlName", "phoneReference"], ["id", "phoneReference"], ["maxlength", "100"], ["type", "text"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(12, 540672, null, 0, i1.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i1.MaxLengthValidator]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(15, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(17, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(18, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 1, "button", [["class", "col-md-3 mb-2 btn btn-sm btn-themecolor-sec btn-rounded waves-effect waves-light"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit Reference"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.phoneReferenceForm; _ck(_v, 2, 0, currVal_7); var currVal_16 = "100"; _ck(_v, 12, 0, currVal_16); var currVal_17 = "phoneReference"; _ck(_v, 15, 0, currVal_17); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i0.ɵnov(_v, 12).maxlength ? i0.ɵnov(_v, 12).maxlength : null); var currVal_9 = i0.ɵnov(_v, 17).ngClassUntouched; var currVal_10 = i0.ɵnov(_v, 17).ngClassTouched; var currVal_11 = i0.ɵnov(_v, 17).ngClassPristine; var currVal_12 = i0.ɵnov(_v, 17).ngClassDirty; var currVal_13 = i0.ɵnov(_v, 17).ngClassValid; var currVal_14 = i0.ɵnov(_v, 17).ngClassInvalid; var currVal_15 = i0.ɵnov(_v, 17).ngClassPending; _ck(_v, 10, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
function View_HorizonOnboardingCompleteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "section", [["class", "bg-white rad py-4 mt-4 mb-4 fadeInUp animated body current"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["Thank you for applying for your ", "."])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HorizonOnboardingCompleteComponent_2)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HorizonOnboardingCompleteComponent_3)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HorizonOnboardingCompleteComponent_4)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.referenceNumber; _ck(_v, 6, 0, currVal_1); var currVal_2 = (!_co.isDirect && !_co.referenceSubmitted); _ck(_v, 8, 0, currVal_2); var currVal_3 = (!_co.isDirect && !_co.referenceSubmitted); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productName; _ck(_v, 4, 0, currVal_0); }); }
function View_HorizonOnboardingCompleteComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "bg-white rad py-4 mt-4 mb-4 fadeInUp animated body current"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There was a problem submitting the application"]))], null, null); }
export function View_HorizonOnboardingCompleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_HorizonOnboardingCompleteComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HorizonOnboardingCompleteComponent_5)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.submissionError; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.submissionError; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HorizonOnboardingCompleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-horizon-onboarding-complete", [], null, null, null, View_HorizonOnboardingCompleteComponent_0, RenderType_HorizonOnboardingCompleteComponent)), i0.ɵdid(1, 180224, null, 0, i3.HorizonOnboardingCompleteComponent, [i4.Store, i4.Store, i1.FormBuilder, i5.ApplicationService, i6.ToastrService, i7.LookupDataService], null, null)], null, null); }
var HorizonOnboardingCompleteComponentNgFactory = i0.ɵccf("wim-horizon-onboarding-complete", i3.HorizonOnboardingCompleteComponent, View_HorizonOnboardingCompleteComponent_Host_0, {}, {}, []);
export { HorizonOnboardingCompleteComponentNgFactory as HorizonOnboardingCompleteComponentNgFactory };
