import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AccountsService } from 'investor/services/accounts.service';
import { FnbSecuritiesService } from 'investor/services/fnbsecurities.service';
import { User } from 'shared/models';
import { LOGIN_SUCCESS, LoginSuccessAction } from 'shared/store';

import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class InvestorEffects {
    @Effect()
    public apiLoginSuccess$: Observable<Action> = this.actions$.ofType(LOGIN_SUCCESS).pipe(
        map((action: LoginSuccessAction) => action.payload),
        mergeMap(payload => this.fetchAccountDetails(payload))
    );

    constructor(private actions$: Actions, private accountService: AccountsService, private fnbsService: FnbSecuritiesService) {}

    private fetchAccountDetails(user: User): Observable<Action> {
        if (!user.isBanker) {
            this.accountService.fetchAccounts();
            this.accountService.fetchPendingAccounts(user);
            this.fnbsService.fetchAccounts();
        }
        return of();
    }
}
