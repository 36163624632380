import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstReference } from 'pst/models';
import { PstParserService, QuestionPart } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable } from 'rxjs';
import { User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';

import { BasePstComponent } from '../base-pst.component';

const MONTHS_IN_YEAR = 12;

@Component({
    selector: 'wim-pst-term',
    templateUrl: 'term.component.pug',
    styleUrls: ['term.component.scss'],
})
export class TermComponent extends BasePstComponent implements OnInit, OnDestroy {
    @Input()
    public customerName: string;
    public tree: PstReference;
    public user: Observable<User>;

    public sliderText: QuestionPart[];
    public heading: string;
    public description: string;

    public minYears: number;
    public maxYears: number;
    public minMonths = 0;
    public maxMonths = 11;

    public max = 10000;
    public min = 1;
    public step = 1;
    public sliderValue = 0;
    public inputForm: FormGroup;
    public switchPoint = 0;
    public overrideMax = true;
    private oldSliderValue = 0;

    constructor(
        loggerService: LoggerService,
        modalService: NgbModal,
        private pstStore: Store<PstState>,
        private pstParser: PstParserService,
        private formBuilder: FormBuilder,
        private lookupService: LookupDataService
    ) {
        super(loggerService, modalService);
    }

    public sliderMove(event) {
        let years = Math.floor(+event.value / MONTHS_IN_YEAR);
        let months = +event.value % MONTHS_IN_YEAR;

        this.inputForm.controls.GOAL_YEARS.setValue(years, { emitEvent: false });
        this.inputForm.controls.GOAL_MONTHS.setValue(months);
    }

    public ngOnDestroy() {}

    public ngOnInit() {
        const result$ = this.pstStore.select(fromPst.selectInProgressPst);
        const tree$ = this.pstStore.select(fromPst.selectPstTree);
        const tooltips$ = this.lookupService.getPstTooltips();

        combineLatest(result$, tree$, tooltips$, (result, tree, tooltips) => {
            return { result, tree, tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ result, tree, tooltips }) => {
                this.tree = tree;
                if (!this.tree) {
                    return;
                }
                super.initContent(null, tooltips, tree.node.nodeId);
                const childNode = tree.childNodes[0];
                this.pstStore.dispatch(new UserSelectedChildNodeAction(childNode));

                this.heading = this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
                this.description = tree.node.nodeDescription;
                this.sliderText = this.pstParser.buildInputDescription(childNode.nodeDescription);
                let lineBreakIndex = this.sliderText.findIndex(item => item.value === 'year(s) and');
                let line1 = this.sliderText.slice(0, lineBreakIndex);
                let line2 = this.sliderText.slice(lineBreakIndex + 1);

                this.sliderText = [
                    ...line1,
                    { type: 'text', value: 'year(s)' },
                    { type: 'break' },
                    { type: 'text', value: 'and' },
                    ...line2,
                ];

                const range = this.pstParser.extractRange(childNode.nodeLongDescription);
                if (range) {
                    [this.min, this.max] = range;
                }
                this.switchPoint = this.pstParser.extractTermSwitch(childNode.nodeLongDescription);
                this.createForm();
                this.pstStore.dispatch(new PstScreenValidAction(this.inputForm.valid));
            });
    }

    private monthsToYears(numerator: number, denominator: number): number {
        return Math.floor(numerator / denominator);
    }

    private createForm() {
        if (!this.inputForm) {
            this.maxYears = this.monthsToYears(this.max, MONTHS_IN_YEAR);
            this.minYears = this.monthsToYears(this.min, MONTHS_IN_YEAR);

            this.inputForm = this.formBuilder.group({
                GOAL_YEARS: [0, this.getValidators(this.minYears, this.maxYears)],
                GOAL_MONTHS: [0, this.getValidators(this.minMonths, this.maxMonths)],
            });

            this.inputForm.valueChanges.subscribe(changes => this.handleFormChanges(changes));
        }
    }

    private handleFormChanges(changes) {
        let newMonths = +changes.GOAL_MONTHS || 0;
        let newYears = +changes.GOAL_YEARS;

        let totalMonths = newYears * MONTHS_IN_YEAR + newMonths;
        this.sliderValue = totalMonths;
        if (totalMonths < this.oldSliderValue) {
            // Slider is moving left (i.e. down)
            if (totalMonths < this.switchPoint) {
                this.step = 1;
            } else {
                this.step = MONTHS_IN_YEAR;
                // Bump up to next full year
                totalMonths = (newYears + 1) * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
        } else {
            // Slider is moving right (i.e. up)
            if (totalMonths <= this.switchPoint) {
                this.step = 1;
            } else {
                this.step = MONTHS_IN_YEAR;
            }
        }

        let currentMonths = +this.inputForm.get('GOAL_MONTHS').value;
        if (totalMonths > this.switchPoint) {
            if (currentMonths !== 0) {
                this.inputForm.get('GOAL_MONTHS').setValue(0, { emitEvent: false });
                let years = +this.inputForm.get('GOAL_YEARS').value + 1;
                this.inputForm.get('GOAL_YEARS').setValue(years, { emitEvent: false });
                totalMonths = years * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
            this.inputForm.get('GOAL_MONTHS').disable({ emitEvent: false });
        } else {
            this.inputForm.get('GOAL_MONTHS').enable({ emitEvent: false });
        }
        this.oldSliderValue = totalMonths;

        this.updateValidators(newYears, newMonths);

        this.pstStore.dispatch(new UserEnteredValueAction(`${totalMonths}`));
        this.pstStore.dispatch(new PstScreenValidAction(this.inputForm.valid));
    }

    private updateValidators(years: number, months: number) {
        this.minMonths = years === 0 ? 1 : 0;
        this.minYears = months === 0 ? 1 : 0;
        this.inputForm.get('GOAL_MONTHS').setValidators(this.getValidators(this.minMonths, this.maxMonths));
        this.inputForm.get('GOAL_MONTHS').updateValueAndValidity({ emitEvent: false });
        this.inputForm.get('GOAL_YEARS').setValidators(this.getValidators(this.minYears, this.maxYears));
        this.inputForm.get('GOAL_YEARS').updateValueAndValidity({ emitEvent: false });
    }

    private getValidators(min: number, max: number) {
        return Validators.compose([Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(min), Validators.max(max)]);
    }
}
