import { AbstractControl, ValidatorFn } from '@angular/forms';

export function requiredIfValidator(ifCondition: () => boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const conditionTrue = ifCondition();
        let valid = true;
        if (conditionTrue) {
            valid = control.value != null && control.value !== '';
        }
        return valid ? null : { required: true };
    };
}
