import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wim-validation-modal',
    templateUrl: 'validation-modal.component.pug',
})
export class ValidationModalComponent {
    public formInvalid = false;
    public recommendationInvalid = false;
    public validationErrors = false;

    constructor(public activeModal: NgbActiveModal) {}
}
