import { DecimalPipe } from '@angular/common';

export class EacPerFund {
    constructor(
        public investmentManagementFee: number = 0,
        public adviceFee: number = 0,
        public adminFee: number = 0,
        public otherFee: number = 0
    ) {}
    public total(pipe: DecimalPipe): number {
        const formatter = '1.2-2';
        const total =
            +pipe.transform(this.adviceFee * 100, formatter) +
            +pipe.transform(this.adminFee * 100, formatter) +
            +pipe.transform(this.otherFee * 100, formatter) +
            +pipe.transform(this.investmentManagementFee * 100, formatter);
        return total / 100;
    }
}
