var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { CashCalculationResult } from 'pst/services/cash-calculator/models/cash-calculation-result';
import { CashGraphLines } from 'pst/services/cash-calculator/models/cash-graph-lines';
import { CashProjectionService } from 'pst/services/cash-projection.service';
import { map } from 'rxjs/operators';
import { BaseService } from 'shared/components';
import { LoggerService } from 'shared/services/logger.service';
var CashCalculator = /** @class */ (function (_super) {
    __extends(CashCalculator, _super);
    function CashCalculator(loggerService, cashProjectionService) {
        var _this = _super.call(this, loggerService) || this;
        _this.cashProjectionService = cashProjectionService;
        return _this;
    }
    CashCalculator.extractCapturedValues = function (pstResult) {
        pstResult.goalName = pstResult.capturedValues['GOAL_NAME'];
        pstResult.term = +pstResult.capturedValues['GOAL_MONTHS'] || 0;
        pstResult.goalAmount = +pstResult.capturedValues['GOAL_AMOUNT'] || 0;
        pstResult.clientInstallment = +pstResult.capturedValues['DEBIT_AMOUNT'] || 0;
        pstResult.contributionIncrease = (+pstResult.capturedValues['YEARLY_ESCALATION'] || 0) / 100.0;
        pstResult.lumpSum = +pstResult.capturedValues['LUMP_SUM'] || 0;
        pstResult.accessType = pstResult.capturedValues['ACCESS_TYPE'];
        pstResult.noticePeriod = pstResult.capturedValues['NOTICE_PERIOD'];
    };
    CashCalculator.injectCapturedValues = function (pstResult, save) {
        pstResult.capturedValues['GOAL_NAME'] = save.goalName;
        pstResult.capturedValues['GOAL_MONTHS'] = save.termMonths;
        pstResult.capturedValues['GOAL_AMOUNT'] = save.goalAmount;
        pstResult.capturedValues['DEBIT_AMOUNT'] = save.debitOrder;
        pstResult.capturedValues['YEARLY_ESCALATION'] = save.annualEscalation * 100;
        pstResult.capturedValues['LUMP_SUM'] = save.lumpSum;
        pstResult.capturedValues['ACCESS_TYPE'] = save.accessType;
        pstResult.capturedValues['NOTICE_PERIOD'] = save.noticePeriod;
    };
    CashCalculator.prototype.ngOnDestroy = function () { };
    CashCalculator.prototype.calculate = function (pstResult, adviceFee, investorAge, investorUcn, startDate) {
        var _this = this;
        if (startDate === void 0) { startDate = null; }
        this.logger.debug('Cash PST Result', pstResult || '');
        if (!startDate) {
            startDate = moment()
                .startOf('day')
                .toDate();
        }
        var lumpsumLessFee = pstResult.lumpSum - pstResult.lumpSum * adviceFee;
        var endDate = moment(startDate)
            .add(pstResult.term, 'months')
            .toDate();
        var request = {
            ucn: investorUcn,
            startDate: startDate,
            endDate: endDate,
            lumpSum: lumpsumLessFee,
            scheduledPayment: pstResult.clientInstallment,
            requiredAmount: pstResult.goalAmount,
            age: investorAge,
            portfolioTypeId: pstResult.portfolioTypeId,
        };
        return this.cashProjectionService.getProjection(request).pipe(map(function (projection) {
            var result = new CashCalculationResult();
            result.effectiveRate = projection.effectiveRate;
            result.nominalRate = projection.nominalRate;
            result.estimatedFutureValue = projection.projectedTotal;
            pstResult.futureValue = result.estimatedFutureValue;
            var graphData = _this.calculateGraphData({
                startDate: startDate,
                effectiveRate: projection.effectiveRate,
                futureValue: projection.projectedTotal,
                goalAmount: pstResult.goalAmount,
                lumpsum: pstResult.lumpSum,
                nominalRate: projection.nominalRate,
                recurring: pstResult.clientInstallment,
                termMonths: pstResult.term,
            });
            // To account for any drift in our calculations, we always end up with the value from the business function
            graphData.clientScenario[graphData.clientScenario.length - 1] = projection.projectedTotal;
            result.graph = _this.createChart(graphData);
            pstResult.projectedInterestRate = projection.nominalRate;
            result.pstResult = pstResult;
            return result;
        }));
    };
    CashCalculator.prototype.createChart = function (graphData) {
        var chartConfig = {
            credits: {
                enabled: false,
            },
            chart: {
                type: 'spline',
            },
            title: {
                text: 'Projected Performance',
            },
            subtitle: {
                text: '',
            },
            xAxis: {
                title: {
                    text: 'Months',
                },
                labels: {
                    overflow: 'justify',
                },
                gridLineWidth: 1,
            },
            yAxis: {
                title: {
                    text: 'Value (R)',
                },
                minorGridLineWidth: 0,
                gridLineWidth: 1,
                alternateGridColor: null,
            },
            plotOptions: {
                spline: {
                    lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 4,
                        },
                    },
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    tooltip: {
                        headerFormat: '',
                        pointFormat: "<span style=\"font-weight:bold\">{series.name}</span><br/>Month {point.x}<br/>R {point.y}",
                    },
                },
            },
            navigation: {
                menuItemStyle: {
                    fontSize: '10px',
                },
            },
            legend: {
                enabled: false,
            },
        };
        var series = [
            {
                name: 'Goal Amount',
                data: graphData.goal,
                color: '#007072',
                dashStyle: 'longdash',
            },
            {
                name: 'Investment Balance',
                data: graphData.clientScenario,
                color: '#01aaad',
            },
        ];
        chartConfig.series = series;
        return new Chart(chartConfig);
    };
    CashCalculator.prototype.getEndDate = function (startDate, termMonths) {
        return moment(startDate)
            .add(termMonths, 'months')
            .toDate();
    };
    CashCalculator.prototype.getDaysToPayInterestFor = function (start, termIndex) {
        var startOfPeriod = moment(start).add(termIndex - 1, 'months');
        var endOfPeriod = moment(start).add(termIndex, 'months');
        return moment(endOfPeriod).diff(moment(startOfPeriod), 'days');
    };
    CashCalculator.prototype.calculateGraphData = function (options) {
        this.logger.debug('Calculating graph');
        var result = new CashGraphLines();
        var previousMonth;
        for (var month = 0; month <= options.termMonths; month++) {
            if (month === 0) {
                result.clientScenario.push(options.lumpsum);
                if (options.goalAmount > 0) {
                    result.goal.push(options.goalAmount);
                }
                previousMonth = options.lumpsum;
                continue;
            }
            var interest = this.calculateInterest(options.startDate, month, previousMonth, options.nominalRate);
            var newBalance = previousMonth + interest + (month < options.termMonths ? options.recurring : 0);
            previousMonth = newBalance;
            result.clientScenario.push(this.round(newBalance));
            if (options.goalAmount > 0) {
                result.goal.push(options.goalAmount);
            }
        }
        return result;
    };
    CashCalculator.prototype.calculateInterest = function (startDate, month, balanceAtBeginningOfMonth, interestRate) {
        var daysOfInterest = this.getDaysToPayInterestFor(startDate, month);
        return (daysOfInterest / 365) * interestRate * balanceAtBeginningOfMonth;
    };
    CashCalculator.prototype.round = function (value, decimals) {
        if (decimals === void 0) { decimals = 2; }
        var roundTo = Math.pow(10, decimals);
        return Math.round(value * roundTo) / roundTo;
    };
    return CashCalculator;
}(BaseService));
export { CashCalculator };
