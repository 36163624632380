// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { Brand } from 'shared/models';

export const DETECTED = '[Brand] Detected';
export class BrandDetectedAction implements Action {
    public readonly type = DETECTED;
    constructor(public payload: Brand) {}
}

export type BrandAction = BrandDetectedAction;

export function brandReducer(state: Brand = Brand.Undetected, action: BrandAction) {
    switch (action.type) {
        case DETECTED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
