/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@hackages/ngxerrors/src/ngxerrors.directive";
import * as i4 from "@hackages/ngxerrors/src/ngxerror.directive";
import * as i5 from "@angular/common";
import * as i6 from "./statement-instruction.component";
import * as i7 from "@ngrx/store";
import * as i8 from "../../../services/instructions.service";
import * as i9 from "../../../../shared/services/date-formatter.service";
import * as i10 from "../../../../shared/services/logger.service";
import * as i11 from "ngx-toastr";
var styles_StatementInstructionComponent = [];
var RenderType_StatementInstructionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StatementInstructionComponent, data: {} });
export { RenderType_StatementInstructionComponent as RenderType_StatementInstructionComponent };
function View_StatementInstructionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "mb-3 ml-3 text-themecolor"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Interim Statements"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h6", [["class", "mb-3 ml-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You can only request a statement after your first debit order has been processed"]))], null, null); }
function View_StatementInstructionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "col-md-12 pt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-12 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "validation-error"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please complete all fields marked with a * before submitting the instruction"]))], null, null); }
function View_StatementInstructionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 67, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "mb-3 ml-3 text-themecolor"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Interim Statements"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 47, "div", [["class", "row form"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "col-lg-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 22, "div", [["class", "form-group col-lg-3 col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "label", [["class", "form-control-label col-form-label pr-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["From Date "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "sup", [["class", "required"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["*"])), (_l()(), i0.ɵted(-1, null, [":"])), (_l()(), i0.ɵeld(13, 0, null, null, 11, "div", [["class", "input-group input-group-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 16777216, null, null, 8, "input", [["class", "form-control form-control-sm"], ["formControlName", "fromDate"], ["id", "lsdate"], ["ngbDatepicker", ""], ["placeholder", "yyyy-mm-dd"]], [[8, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 17).manualDateChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (i0.ɵnov(_v, 17).manualDateChange($event.target.value, true) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 17).onBlur() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(512, null, i2.ɵx, i2.ɵx, [i2.NgbCalendar, i2.NgbDatepickerI18n]), i0.ɵdid(17, 671744, [["datePickerFrom", 4]], 0, i2.NgbInputDatepicker, [i2.NgbDateParserFormatter, i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i0.ComponentFactoryResolver, i0.NgZone, i2.ɵx, i2.NgbCalendar, i2.NgbDateAdapter, i0.ChangeDetectorRef, i2.ɵz], null, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.NgbInputDatepicker]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i2.NgbInputDatepicker]), i0.ɵdid(20, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(22, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(23, 0, null, null, 1, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 0, "button", [["class", "btn btn-outline-secondary fa fa-calendar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 17).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 4, "div", [["ngxErrors", "fromDate"]], null, null, null, null, null)), i0.ɵdid(26, 4931584, null, 0, i3.NgxErrorsDirective, [i1.FormGroupDirective], { controlName: [0, "controlName"] }, null), (_l()(), i0.ɵeld(27, 0, null, null, 2, "div", [["class", "validation-error"], ["ngxError", "required"], ["when", "touched"]], [[8, "hidden", 0]], null, null, null, null)), i0.ɵdid(28, 475136, null, 0, i4.NgxErrorDirective, [i3.NgxErrorsDirective], { ngxError: [0, "ngxError"], when: [1, "when"] }, null), (_l()(), i0.ɵted(-1, null, ["From date is required"])), (_l()(), i0.ɵeld(30, 0, null, null, 22, "div", [["class", "form-group col-lg-3 col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 4, "label", [["class", "form-control-label col-form-label pr-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["To Date "])), (_l()(), i0.ɵeld(33, 0, null, null, 1, "sup", [["class", "required"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["*"])), (_l()(), i0.ɵted(-1, null, [":"])), (_l()(), i0.ɵeld(36, 0, null, null, 11, "div", [["class", "input-group input-group-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 16777216, null, null, 8, "input", [["class", "form-control form-control-sm"], ["formControlName", "toDate"], ["id", "lsdate"], ["ngbDatepicker", ""], ["placeholder", "yyyy-mm-dd"]], [[8, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 38)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 38).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 38)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 38)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 40).manualDateChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (i0.ɵnov(_v, 40).manualDateChange($event.target.value, true) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 40).onBlur() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(38, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(512, null, i2.ɵx, i2.ɵx, [i2.NgbCalendar, i2.NgbDatepickerI18n]), i0.ɵdid(40, 671744, [["datePickerTo", 4]], 0, i2.NgbInputDatepicker, [i2.NgbDateParserFormatter, i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i0.ComponentFactoryResolver, i0.NgZone, i2.ɵx, i2.NgbCalendar, i2.NgbDateAdapter, i0.ChangeDetectorRef, i2.ɵz], null, null), i0.ɵprd(1024, null, i1.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.NgbInputDatepicker]), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i2.NgbInputDatepicker]), i0.ɵdid(43, 671744, null, 0, i1.FormControlName, [[3, i1.ControlContainer], [6, i1.NG_VALIDATORS], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlName]), i0.ɵdid(45, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(46, 0, null, null, 1, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i0.ɵeld(47, 0, null, null, 0, "button", [["class", "btn btn-outline-secondary fa fa-calendar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 40).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(48, 0, null, null, 4, "div", [["ngxErrors", "toDate"]], null, null, null, null, null)), i0.ɵdid(49, 4931584, null, 0, i3.NgxErrorsDirective, [i1.FormGroupDirective], { controlName: [0, "controlName"] }, null), (_l()(), i0.ɵeld(50, 0, null, null, 2, "div", [["class", "validation-error"], ["ngxError", "required"], ["when", "touched"]], [[8, "hidden", 0]], null, null, null, null)), i0.ɵdid(51, 475136, null, 0, i4.NgxErrorDirective, [i3.NgxErrorsDirective], { ngxError: [0, "ngxError"], when: [1, "when"] }, null), (_l()(), i0.ɵted(-1, null, ["To date is required"])), (_l()(), i0.ɵeld(53, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatementInstructionComponent_3)), i0.ɵdid(55, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(56, 0, null, null, 11, "div", [["class", "col-md-12 pt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(57, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(58, 0, null, null, 1, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(60, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(61, 0, null, null, 1, "button", [["class", "col-md-12 mb-2 btn btn-md btn-themecolor-sec btn-rounded waves-effect waves-light"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Submit"])), (_l()(), i0.ɵeld(63, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(64, 0, null, null, 1, "button", [["class", "col-md-12 mb-2 btn btn-md btn-themecolor btn-rounded waves-effect waves-light"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(66, 0, null, null, 1, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "fromDate"; _ck(_v, 20, 0, currVal_8); var currVal_9 = "fromDate"; _ck(_v, 26, 0, currVal_9); var currVal_11 = "required"; var currVal_12 = "touched"; _ck(_v, 28, 0, currVal_11, currVal_12); var currVal_21 = "toDate"; _ck(_v, 43, 0, currVal_21); var currVal_22 = "toDate"; _ck(_v, 49, 0, currVal_22); var currVal_24 = "required"; var currVal_25 = "touched"; _ck(_v, 51, 0, currVal_24, currVal_25); var currVal_26 = (!_co.canSubmit() && _co.instructionForm.touched); _ck(_v, 55, 0, currVal_26); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 17).disabled; var currVal_1 = i0.ɵnov(_v, 22).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 22).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 22).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 22).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 22).ngClassValid; var currVal_6 = i0.ɵnov(_v, 22).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 22).ngClassPending; _ck(_v, 14, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i0.ɵnov(_v, 28).hidden; _ck(_v, 27, 0, currVal_10); var currVal_13 = i0.ɵnov(_v, 40).disabled; var currVal_14 = i0.ɵnov(_v, 45).ngClassUntouched; var currVal_15 = i0.ɵnov(_v, 45).ngClassTouched; var currVal_16 = i0.ɵnov(_v, 45).ngClassPristine; var currVal_17 = i0.ɵnov(_v, 45).ngClassDirty; var currVal_18 = i0.ɵnov(_v, 45).ngClassValid; var currVal_19 = i0.ɵnov(_v, 45).ngClassInvalid; var currVal_20 = i0.ɵnov(_v, 45).ngClassPending; _ck(_v, 37, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_23 = i0.ɵnov(_v, 51).hidden; _ck(_v, 50, 0, currVal_23); }); }
export function View_StatementInstructionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "tab-pane"], ["id", "navpills-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(3, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "row bg-light pt-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatementInstructionComponent_1)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StatementInstructionComponent_2)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.instructionForm; _ck(_v, 3, 0, currVal_7); var currVal_8 = !_co.hasHoldings; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.hasHoldings; _ck(_v, 10, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_StatementInstructionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-instruction-statement", [], null, null, null, View_StatementInstructionComponent_0, RenderType_StatementInstructionComponent)), i0.ɵdid(1, 245760, null, 0, i6.StatementInstructionComponent, [i7.Store, i7.Store, i8.InstructionsService, i1.FormBuilder, i9.DateFormatterService, i2.NgbModal, i10.LoggerService, i11.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatementInstructionComponentNgFactory = i0.ɵccf("wim-instruction-statement", i6.StatementInstructionComponent, View_StatementInstructionComponent_Host_0, {}, {}, []);
export { StatementInstructionComponentNgFactory as StatementInstructionComponentNgFactory };
