var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstParserService } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { LoggerService, LookupDataService } from 'shared/services';
import { BasePstComponent } from '../base-pst.component';
var MultipleChoiceComponent = /** @class */ (function (_super) {
    __extends(MultipleChoiceComponent, _super);
    function MultipleChoiceComponent(loggerService, modalService, pstStore, formBuilder, pstParser, lookupService) {
        var _this = _super.call(this, loggerService, modalService) || this;
        _this.pstStore = pstStore;
        _this.formBuilder = formBuilder;
        _this.pstParser = pstParser;
        _this.lookupService = lookupService;
        return _this;
    }
    MultipleChoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var tooltips$ = this.lookupService.getPstTooltips();
        var icons$ = this.lookupService.getPstIcons();
        var tree$ = this.pstStore.select(fromPst.selectPstTree);
        combineLatest(tree$, tooltips$, icons$, function (tree, tooltips, icons) {
            return { tree: tree, tooltips: tooltips, icons: icons };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var tree = _a.tree, tooltips = _a.tooltips, icons = _a.icons;
            _this.tree = tree;
            if (!_this.tree) {
                return;
            }
            _super.prototype.initContent.call(_this, icons, tooltips, tree.node.nodeId);
            _this.createForm();
            if (_this.tree && _this.tree.childNodes.length > 0) {
                _this.form.reset();
                _this.pstStore.dispatch(new UserSelectedChildNodeAction(null));
            }
            _this.pstStore.dispatch(new UserEnteredValueAction(''));
            _this.pstStore.dispatch(new PstScreenValidAction(false));
        });
    };
    MultipleChoiceComponent.prototype.radioClicked = function (node) {
        this.form.get('selectedNode').setValue(node);
    };
    MultipleChoiceComponent.prototype.ngOnDestroy = function () { };
    MultipleChoiceComponent.prototype.createForm = function () {
        var _this = this;
        this.form = this.formBuilder.group({
            selectedNode: [null, Validators.required],
        });
        this.form.valueChanges.subscribe(function (changes) {
            var selectedNode = changes.selectedNode;
            _this.pstStore.dispatch(new UserSelectedChildNodeAction(selectedNode));
            _this.pstStore.dispatch(new UserEnteredValueAction(_this.pstParser.extractChildNodeValue(selectedNode ? selectedNode.nodeLongDescription : '')));
            _this.pstStore.dispatch(new PstScreenValidAction(_this.form.valid));
        });
    };
    return MultipleChoiceComponent;
}(BasePstComponent));
export { MultipleChoiceComponent };
