import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { ContactService } from 'sales/services/contact.service';
import { BaseComponent } from 'shared/components/base';
import { Brand, ContactForm } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-contact-us',
    templateUrl: 'contact.component.pug',
    styleUrls: ['contact.component.scss'],
})
export class ContactUsComponent extends BaseComponent implements OnInit, OnDestroy {
    public showContactForm = true;
    public contactForm: FormGroup;
    public feedbackTypeList: any[];
    public titleList: any[];
    public noTitleSelected = false;
    public noFeedbackTypeSelected = false;

    private currentInstance: string;
    private brandSubscription: Subscription;
    constructor(
        private loggerService: LoggerService,
        private store: Store<SharedState>,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private contactService: ContactService,
        private modalService: NgbModal
    ) {
        super(loggerService);

        this.titleList = [
            { value: 'Mr', description: 'Mr' },
            { value: 'Ms', description: 'Ms' },
            { value: 'Mrs', description: 'Mrs' },
            { value: 'Rev', description: 'Rev' },
            { value: 'Prof', description: 'Prof' },
            { value: 'Dr', description: 'Dr' },
            { value: 'Adv', description: 'Adv' },
            { value: 'Hon', description: 'Hon' },
        ];
        this.feedbackTypeList = [
            { value: 'Compliments', description: 'Compliments' },
            { value: 'Complaints', description: 'Complaints' },
            { value: 'Queries', description: 'Queries' },
        ];
    }
    public ngOnInit() {
        this.brandSubscription = this.store.select(fromShared.selectBrand).subscribe(k => this.setBrand(k));
        if (!this.contactForm) {
            this.createContactForm();
        }
    }
    public ngOnDestroy() {
        if (this.brandSubscription) {
            this.brandSubscription.unsubscribe();
        }
    }
    public toggleContactForm() {
        return (this.showContactForm = !this.showContactForm);
    }
    public canSubmit() {
        if (!this.contactForm.value.feedbackType) {
            this.noFeedbackTypeSelected = true;
        } else {
            this.noFeedbackTypeSelected = false;
        }
        if (!this.contactForm.value.title) {
            this.noTitleSelected = true;
        } else {
            this.noTitleSelected = false;
        }
        return this.contactForm.valid;
    }
    public submit() {
        this.store.dispatch(new LoadingAction());
        const contactModel: ContactForm = this.contactForm.value;
        this.contactService.sendFeedbackForm(contactModel).subscribe(result => {
            this.store.dispatch(new StopLoadingAction());
            if (result.success) {
                this.resetForm();
                this.toastr.success(result.message);
            } else {
                this.toastr.error(result.message);
            }
        });
    }

    public contactUs() {
        const modalRef = this.modalService.open(ContactBankerModalComponent, { windowClass: 'large-modal' });
    }

    private createContactForm(): any {
        this.contactForm = this.formBuilder.group({
            title: ['', Validators.required],
            firstName: ['', Validators.required],
            surname: ['', Validators.required],
            idNumber: [''],
            email: ['', [Validators.required, Validators.email]],
            phoneNumber: ['', Validators.required],
            feedbackType: ['', Validators.required],
            message: ['', Validators.required],
            brand: [this.currentInstance],
        });
    }
    private resetForm() {
        this.contactForm.reset();
    }
    private setBrand(activeBrand) {
        switch (activeBrand) {
            case Brand.RMB: {
                this.currentInstance = 'RMB';
                break;
            }
            default: {
                this.currentInstance = 'FNB';
                break;
            }
        }
    }
}
