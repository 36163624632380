import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wim-pst-content-modal',
    templateUrl: 'pst-content-modal.component.pug',
    styleUrls: ['pst-content-modal.component.scss'],
})
export class PstContentModalComponent {
    public content: string;

    constructor(public activeModal: NgbActiveModal) {}
}
