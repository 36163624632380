import { HttpRequest, HttpResponse } from '@angular/common/http';
import appConfig from 'appConfig';

export class RequestInspectionService {
    public isWebAPIRequest(request: HttpRequest<any>) {
        return this.isCoreApiRequest(request) || this.isMockRequest(request);
    }

    public isSSORequest(request: HttpRequest<any>) {
        return request.url.indexOf(appConfig.auth.url) >= 0;
    }

    public isCoreApiRequest(request: HttpRequest<any>) {
        return request.url.indexOf(appConfig.apiUrl) >= 0;
    }

    public isFnbsApiRequest(request: HttpRequest<any>) {
        return request.url.indexOf(appConfig.fnbsApiUrl) >= 0;
    }

    public isMockRequest(request: HttpRequest<any>) {
        return /\/mocks\//.test(request.url);
    }
}
