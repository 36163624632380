// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';

export const START_LOADING = '[Loader] Start';
export const STOP_LOADING = '[Loader] End';

export class LoadingAction implements Action {
    public readonly type = START_LOADING;
}

export class StopLoadingAction implements Action {
    public readonly type = STOP_LOADING;
}

type GlobalLoaderAction = LoadingAction | StopLoadingAction;

export function showLoaderReducer(state: boolean = false, action: GlobalLoaderAction) {
    switch (action.type) {
        case START_LOADING: {
            return true;
        }
        case STOP_LOADING: {
            return false;
        }
        default: {
            return state;
        }
    }
}
