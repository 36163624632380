import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { AdviceEngineService } from '../../services/advice-engine.service';

@Component({
    selector: 'wim-advice-download-modal',
    templateUrl: 'advice-download-modal.component.pug',
    styleUrls: ['advice-download-modal.component.scss'],
})
export class AdviceDownloadModalComponent implements OnInit {
    public uniqueId = '';
    public uniqueUrl = '';
    public emailAddress = '';
    public emailError = false;
    public emailSent = false;
    public emailFormatError = false;

    constructor(public activeModal: NgbActiveModal, private sharedStore: Store<SharedState>, private adviceService: AdviceEngineService) {}

    public ngOnInit() {
        this.sharedStore.select(fromShared.selectUser).subscribe(user => {
            this.emailAddress = user.emailAddress;
        });
    }

    public email() {
        this.emailSent = false;
        this.emailFormatError = false;
        this.emailError = false;

        if (this.emailAddress && this.emailAddress.trim().length > 0 && this.emailAddress.indexOf('@') > 0) {
            this.sharedStore.dispatch(new LoadingAction());
            this.adviceService.email(this.uniqueId, this.emailAddress).subscribe(
                () => {
                    this.emailSent = true;
                    this.sharedStore.dispatch(new StopLoadingAction());
                },
                err => {
                    console.error(err);
                    this.sharedStore.dispatch(new StopLoadingAction());
                    this.emailError = true;
                }
            );
        } else {
            this.emailFormatError = true;
        }
    }
}
