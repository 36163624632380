import { Component, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { Store } from '@ngrx/store';
import { GeneralDocument } from 'investor/models';
import { DocumentsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';

@Component({
    selector: 'wim-general-documents',
    templateUrl: 'general-documents.component.pug',
})
export class GeneralDocumentsComponent implements OnInit, OnDestroy {
    public documents: Observable<GeneralDocument[]>;
    private documentSubscription: Subscription;

    constructor(
        private documentService: DocumentsService,
        private sharedStore: Store<SharedState>,
        private investorStore: Store<InvestorState>
    ) {}

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());
        this.documents = this.investorStore.select(fromInvestor.selectGeneralDocuments);
        this.documentSubscription = this.documents
            .pipe(filter(docs => !!docs))
            .subscribe(() => this.sharedStore.dispatch(new StopLoadingAction()));
        this.documentService.fetchGeneralDocuments();
    }

    public ngOnDestroy(): void {
        if (this.documentSubscription) {
            this.documentSubscription.unsubscribe();
        }
    }
}
