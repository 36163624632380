import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { Observable } from 'rxjs';
import { BaseComponent, SessionExpiringModalComponent } from 'shared/components';
import { Brand, User } from 'shared/models';
import { AuthenticationService, AuthTokenService, LoggerService, LookupDataService, SessionService } from 'shared/services';
import {
    BrandDetectedAction,
    HideSmallHeaderAction,
    HideTransparentHeaderAction,
    InvestorHelperToggleButtonAction,
    NavigationAction,
    SessionExpiringAction,
    SharedState,
    ShowSmallHeaderAction,
    ShowTransparentHeaderAction,
} from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-app',
    styleUrls: ['./app.component.css'],
    templateUrl: 'app.component.pug',
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
    public user$: Observable<User>;
    public isBanker: boolean;
    public isLoggedIn$: Observable<boolean>;
    public canShowBankerHelper: boolean;
    public investorHelperToggled = false;
    public isPstRoute = false;
    public isSalesPage = false;

    private brandLoaded = false;
    private shownWelcome = false;
    private lastScrollY = 0;

    constructor(
        private sharedStore: Store<SharedState>,
        private tokenService: AuthTokenService,
        private router: Router,
        private loggerService: LoggerService,
        private lookupService: LookupDataService,
        private authService: AuthenticationService,
        private sessionService: SessionService,
        private modalService: NgbModal,
        private pageScrollService: PageScrollService,
        @Inject(DOCUMENT) private document: any
    ) {
        super(loggerService);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && (window as any).ga) {
                (window as any).ga('set', 'page', event.urlAfterRedirects);
                (window as any).ga('send', 'pageview');
            }
        });
    }

    public ngOnInit() {
        this.tokenService.init();
        this.lookupService.getHorizonFunds();
        this.authService.checkIfBankerLoginAllowed();
        this.sessionService.start();

        this.user$ = this.sharedStore.select(fromShared.selectUser);
        this.isLoggedIn$ = this.sharedStore.select(fromShared.selectAuthenticated);

        this.sharedStore
            .select(fromShared.selectBrand)
            .pipe(this.scavenger.collect())
            .subscribe(brand => this.setBrand(brand));

        this.user$.pipe(this.scavenger.collect()).subscribe(k => this.setIsBanker(k));

        this.sharedStore
            .select(fromShared.selectShowFullWidth)
            .pipe(this.scavenger.collect())
            .subscribe(fullWidth => (this.isSalesPage = fullWidth));

        this.router.events.pipe(this.scavenger.collect()).subscribe(event => {
            if (!this.brandLoaded && event instanceof NavigationStart) {
                const brand =
                    location.host === appConfig.brands.rmb
                        ? Brand.RMB
                        : location.host === appConfig.brands.fnb
                        ? Brand.FNB
                        : Brand.Undetected;
                this.sharedStore.dispatch(new BrandDetectedAction(brand));
                this.brandLoaded = true;
            }
            if (event instanceof NavigationCancel) {
                this.sharedStore.dispatch(new NavigationAction(this.router.url));
            }
            if (event instanceof NavigationEnd) {
                this.sharedStore.dispatch(new HideSmallHeaderAction());
                if (event.url.includes('pst') || event.url.includes('customer-search')) {
                    this.isPstRoute = true;
                }
                if (event.url.includes('banker-login') || this.isBanker || event.url === '/') {
                    this.sharedStore.dispatch(new InvestorHelperToggleButtonAction(false));
                } else {
                    this.canShowBankerHelper = false;
                    this.sharedStore.dispatch(new InvestorHelperToggleButtonAction(true));
                }
                if (/\/invest($|\?)/.test(event.url)) {
                    //this.sharedStore.dispatch(new ShowTransparentHeaderAction());
                    this.sharedStore.dispatch(new HideTransparentHeaderAction());
                } else {
                    this.sharedStore.dispatch(new HideTransparentHeaderAction());
                }

                this.scrollToTop();
            }
        });

        this.sharedStore
            .select(fromShared.selectSessionExpiring)
            .pipe(this.scavenger.collect())
            .subscribe(sessionExpiring => {
                if (sessionExpiring) {
                    this.modalService.open(SessionExpiringModalComponent);
                    this.sharedStore.dispatch(new SessionExpiringAction(false));
                }
            });

        window.addEventListener('resize', () => this.checkHeader());
        window.addEventListener('scroll', () => this.checkHeader());
    }

    private scrollToTop() {
        let scrollInstance = PageScrollInstance.newInstance({
            document: this.document,
            scrollTarget: '#top-marker',
            pageScrollDuration: 1,
            pageScrollOffset: 100,
        });
        this.pageScrollService.start(scrollInstance);
    }

    private checkHeader() {
        if (window.scrollY > 100) {
            if (this.lastScrollY <= 100) {
                this.sharedStore.dispatch(new ShowSmallHeaderAction());
            }
        } else {
            if (this.lastScrollY > 100) {
                this.sharedStore.dispatch(new HideSmallHeaderAction());
            }
        }
        this.lastScrollY = window.scrollY;
    }

    public ngOnDestroy() {}

    private setBrand(brand: Brand) {
        this.initLog(brand);
        switch (brand) {
            case Brand.RMB: {
                require('style-loader!../assets/css/rmb.css');
                require('style-loader!../assets/css/rmb-ext.css');
                break;
            }
            default: {
                require('style-loader!../assets/css/fnb.css');
                require('style-loader!../assets/css/fnb-ext.css');
                break;
            }
        }
    }

    private setIsBanker(user) {
        if (user) {
            this.isBanker = user.isBanker;
        } else {
            this.isBanker = false;
        }
    }

    private initLog(brand: Brand) {
        if (this.shownWelcome) {
            this.logger.info(`Brand: ${Brand[brand]}`);
        } else {
            this.loggerService.welcome(brand);
            if (brand !== Brand.Undetected) {
                this.logger.info(`Brand: ${Brand[brand]}`);
            }
            this.logger.info(`Environment: ${appConfig.env}`);
            this.logger.info(`Version: ${appConfig.version}`);
            this.logger.info(`Instance: ${appConfig.serverInstance}`);
        }
        this.shownWelcome = true;
    }
}
