var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { DocumentsService } from 'investor/services';
import { AccountSelectedAction, NewAccountDocumentsListAction } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
var AccountDocumentsComponent = /** @class */ (function (_super) {
    __extends(AccountDocumentsComponent, _super);
    function AccountDocumentsComponent(documentService, investorStore, sharedStore, route, formBuilder, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.documentService = documentService;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.route = route;
        _this.formBuilder = formBuilder;
        _this.investmentAccounts = [];
        _this.loadingError = false;
        _this.downloadingError = false;
        _this.buildForm();
        return _this;
    }
    AccountDocumentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.documents$ = this.investorStore.select(fromInvestor.selectInvestorDocuments).pipe(filter(function (docs) { return !!docs; }));
        var documentSubscription = this.documents$.pipe(filter(function (docs) { return !!docs; })).subscribe(function (documents) {
            if (documents) {
                _this.sharedStore.dispatch(new StopLoadingAction());
            }
        });
        var retrievalErrorSubscription = this.investorStore
            .select(fromInvestor.selectDocumentRetrievalError)
            .pipe(filter(function (e) { return !!e; }))
            .subscribe(function () {
            _this.loadingError = true;
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
        var combinedSubscription = combineLatest(this.investorStore.select(fromInvestor.selectInvestmentAccounts), this.route.params, function (investmentAccounts, routeParams) {
            return { investmentAccounts: investmentAccounts, routeParams: routeParams };
        }).subscribe(function (_a) {
            var investmentAccounts = _a.investmentAccounts, routeParams = _a.routeParams;
            if (investmentAccounts == null) {
                return;
            }
            _this.investmentAccounts = investmentAccounts;
            var selectedAccount = null;
            var accountNumber = routeParams['accountNumber'];
            if (accountNumber) {
                selectedAccount = _this.investmentAccounts.filter(function (acc) { return acc.accountNumber === accountNumber; })[0];
            }
            else {
                selectedAccount = _this.investmentAccounts && _this.investmentAccounts[0];
            }
            _this.form.setValue({ account: selectedAccount });
        });
        this.registerSubscriptions(documentSubscription, retrievalErrorSubscription, combinedSubscription);
    };
    AccountDocumentsComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    AccountDocumentsComponent.prototype.changeAccount = function (account) {
        this.selectedAccount = account;
        if (account) {
            this.investorStore.dispatch(new AccountSelectedAction(account));
            this.loadForAccount(account.accountNumber);
        }
        else {
            this.investorStore.dispatch(new NewAccountDocumentsListAction([]));
            this.loadingError = false;
        }
    };
    AccountDocumentsComponent.prototype.download = function (document) {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.documentService.downloadDocument(document.objectId).subscribe(function (docResponse) {
            var blob = new Blob([docResponse.dataAsBytes], { type: docResponse.contentType });
            var fileNameParts = docResponse.name.split('.');
            var extension = fileNameParts[fileNameParts.length - 1];
            var downloadName = _this.selectedAccount.accountNumber + "_" + document.mappedName + "." + extension;
            FileSaver.saveAs(blob, downloadName);
            _this.sharedStore.dispatch(new StopLoadingAction());
        }, function () {
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.downloadingError = true;
        });
    };
    AccountDocumentsComponent.prototype.loadForAccount = function (accountNumber) {
        this.loadingError = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.documentService.fetchAccountDocuments(accountNumber);
    };
    AccountDocumentsComponent.prototype.buildForm = function () {
        var _this = this;
        this.form = this.formBuilder.group({
            account: [null],
        });
        this.form.valueChanges.subscribe(function (changes) {
            _this.changeAccount(changes.account);
        });
    };
    return AccountDocumentsComponent;
}(BaseComponent));
export { AccountDocumentsComponent };
