var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '../../services/logger.service';
import { BaseComponent } from '../base';
var MONTHS_IN_YEAR = 12;
var TermSliderInputComponent = /** @class */ (function (_super) {
    __extends(TermSliderInputComponent, _super);
    function TermSliderInputComponent(formBuilder, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.sliderValue = 0;
        _this.min = 1;
        _this.max = 12;
        _this.switchPoint = 24;
        _this.prefix = null;
        _this.suffix = null;
        _this.valueChange = new EventEmitter();
        _this.step = 1;
        _this.minMonths = 0;
        _this.maxMonths = 11;
        _this.oldSliderValue = 0;
        _this.maxYears = _this.monthsToYears(_this.max, MONTHS_IN_YEAR);
        _this.minYears = _this.monthsToYears(_this.min, MONTHS_IN_YEAR);
        _this.form = formBuilder.group({
            years: [0, _this.getValidators(_this.minYears, _this.maxYears)],
            months: [0, _this.getValidators(_this.minMonths, _this.maxMonths)],
        });
        _this.form.valueChanges.subscribe(function (changes) { return _this.handleFormChanges(changes); });
        return _this;
    }
    TermSliderInputComponent.prototype.ngOnChanges = function (changes) {
        if (changes.min || changes.max) {
            this.maxYears = this.monthsToYears(this.max, MONTHS_IN_YEAR);
            this.minYears = this.monthsToYears(this.min, MONTHS_IN_YEAR);
            this.updateValidators(this.form.value.years, this.form.value.months);
        }
    };
    TermSliderInputComponent.prototype.ngOnDestroy = function () { };
    TermSliderInputComponent.prototype.handleFormChanges = function (changes) {
        var newMonths = +changes.months || 0;
        var newYears = +changes.years;
        var totalMonths = newYears * MONTHS_IN_YEAR + newMonths;
        this.sliderValue = totalMonths;
        if (totalMonths < this.oldSliderValue) {
            // Slider is moving left (i.e. down)
            if (totalMonths < this.switchPoint) {
                this.step = 1;
            }
            else {
                this.step = MONTHS_IN_YEAR;
                // Bump up to next full year
                totalMonths = newYears * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
        }
        else {
            // Slider is moving right (i.e. up)
            if (totalMonths <= this.switchPoint) {
                this.step = 1;
            }
            else {
                this.step = MONTHS_IN_YEAR;
            }
        }
        var currentMonths = +this.form.get('months').value;
        if ((totalMonths > this.switchPoint) || (this.min > this.switchPoint)) {
            if (currentMonths !== 0) {
                this.form.get('months').setValue(0, { emitEvent: false });
                var years = +this.form.get('years').value + 1;
                this.form.get('years').setValue(years, { emitEvent: false });
                totalMonths = years * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
            this.form.get('months').disable({ emitEvent: false });
        }
        else {
            this.form.get('months').enable({ emitEvent: false });
        }
        this.oldSliderValue = totalMonths;
        this.updateValidators(newYears, newMonths);
        if (this.onChangeFunction) {
            this.onChangeFunction(totalMonths);
        }
        if (this.onTouchFunction) {
            this.onTouchFunction();
        }
        this.valueChange.emit(totalMonths);
    };
    TermSliderInputComponent.prototype.monthsToYears = function (numerator, denominator) {
        return Math.floor(numerator / denominator);
    };
    TermSliderInputComponent.prototype.updateValidators = function (years, months) {
        if (this.min === 1) {
            this.minMonths = years === 0 ? 1 : 0;
            this.minYears = months === 0 ? 1 : 0;
        }
        this.form.get('months').setValidators(this.getValidators(this.minMonths, this.maxMonths));
        this.form.get('months').updateValueAndValidity({ emitEvent: false });
        this.form.get('years').setValidators(this.getValidators(this.minYears, this.maxYears));
        this.form.get('years').updateValueAndValidity({ emitEvent: false });
        this.form.validator = this.validateTotalMonths.bind(this);
    };
    TermSliderInputComponent.prototype.validateTotalMonths = function (abstractForm) {
        var monthsControl = abstractForm.get('months');
        var yearsControl = abstractForm.get('years');
        var totalMonths = this.sliderValue;
        if (totalMonths < this.min) {
            monthsControl.setErrors({
                totalMin: true,
            });
            return { totalMin: true };
        }
        else if (totalMonths > this.max) {
            yearsControl.setErrors({
                max: true,
            });
            return { max: true };
        }
        else {
            monthsControl.setErrors(null);
            yearsControl.setErrors(null);
            return null;
        }
    };
    TermSliderInputComponent.prototype.getValidators = function (min, max) {
        return Validators.compose([Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(min), Validators.max(max)]);
    };
    TermSliderInputComponent.prototype.writeValue = function (value) {
        this.sliderValue = value;
        this.sliderMove({ value: value });
    };
    TermSliderInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeFunction = fn;
    };
    TermSliderInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchFunction = fn;
    };
    TermSliderInputComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.get('months').disable();
            this.form.get('years').disable();
        }
        else {
            this.form.get('months').enable();
            this.form.get('years').enable();
        }
    };
    TermSliderInputComponent.prototype.sliderMove = function (event) {
        var years = Math.floor(+event.value / MONTHS_IN_YEAR);
        var months = +event.value % MONTHS_IN_YEAR;
        this.form.controls.years.setValue(years, { emitEvent: false });
        this.form.controls.months.setValue(months);
    };
    return TermSliderInputComponent;
}(BaseComponent));
export { TermSliderInputComponent };
