import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccountType, CustomerBankAccount, CustomerSearchResult, ProductApplication } from 'application/models';
import { ApplicationDateService } from 'application/services';
import {
    AppFormUpdatedBankAccountAction,
    AppFormUpdatedDebitOrderDetailsAction,
    BankAccountListLoadedAction,
    InsufficientFundsAction,
    InvestmentDetailsValidAction,
} from 'application/store/application.reducers';
import { ApplicationState } from 'application/store/application.store';
import * as fromApplication from 'application/store/application.store';
import { CisSearchService } from 'banker/services';
import * as moment from 'moment';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import { Fund, PortfolioType } from 'shared/models';
import { SourceOfFundsType } from 'shared/models/source-of-funds-type';
import { DateFormatterService, LookupDataService, NumberFormatterService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { bigNumberMaxValidator, bigNumberMinValidator, dateMinValidator } from 'shared/validators';

import { PossibleDebitDates } from '../debit-dates';

@Component({
    selector: 'wim-advised-onboarding-details',
    templateUrl: 'advised-onboarding-details.component.pug',
    styleUrls: ['advised-onboarding-details.component.scss'],
})
export class AdvisedOnboardingDetailsComponent implements OnDestroy {
    public customerName: string;
    public lumpSumSelected = false;
    public debitOrderSelected = false;
    public annualContributionSelected = false;
    public isCash = false;
    public bankAccountLoadingError;

    public investmentForm: FormGroup;
    public selectedFund: Observable<Fund>;
    public appForm: ProductApplication;
    public bankAccounts: CustomerBankAccount[] = [];
    public recurringEscalation: number;
    public debitDates: Array<{ value: number; label: string }> = [];

    private subscriptions$: Subscription;
    private sourceOfFundsTypes: SourceOfFundsType[] = [];
    private selectedCustomer: CustomerSearchResult;
    private bankAccountsLoadedOnInit = false;
    private products: Map<number, PortfolioType> = new Map();
    public product: PortfolioType;

    public minLumpSum = 5000;
    public maxLumpSum = 1000000;

    private isDirect = false;
    public insufficientFunds = false;

    constructor(
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private pstStore: Store<PstState>,
        private formBuilder: FormBuilder,
        private lookupService: LookupDataService,
        private numberFormatter: NumberFormatterService,
        private customerService: CisSearchService,
        private applicationDateService: ApplicationDateService,
        private dateFormatter: DateFormatterService
    ) {
        this.sharedStore.dispatch(new LoadingAction());
        this.selectedFund = this.pstStore.select(fromPst.selectSelectedFund);
        this.lookupService.getSourceOfFundsTypes().subscribe(types => (this.sourceOfFundsTypes = types));
        const application$ = this.applicationStore.select(fromApplication.selectHorizonApplication).pipe(take(1));
        const customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer).pipe(filter(customer => !!customer));
        const products$ = this.lookupService.getPortfolioTypes();
        const isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);

        this.subscriptions$ = combineLatest(
            customer$,
            application$,
            products$,
            isDirect$,
            (selectedCustomer, appForm, products, isDirect) => {
                return { selectedCustomer, appForm, products, isDirect };
            }
        ).subscribe(({ selectedCustomer, appForm, products, isDirect }) => {
            this.isDirect = isDirect;
            this.lumpSumSelected = !!appForm.lumpSumDetails;

            if(!!appForm.debitOrderDetails) {
                if(appForm.debitOrderDetails.paymentFrequencyId === 7) {
                    this.annualContributionSelected = true;
                } else {
                    this.debitOrderSelected = true;
                }
            }

            this.appForm = appForm;

            this.debitDates = PossibleDebitDates.filter(date => date.forProduct(appForm.portfolioTypeId));

            this.customerName = `${selectedCustomer.title} ${selectedCustomer.firstname} ${selectedCustomer.lastname}`;
            this.selectedCustomer = selectedCustomer;

            if (!this.bankAccountsLoadedOnInit) {
                this.bankAccountsLoadedOnInit = true;
                this.reloadBankAccounts();
            }

            if (
                this.appForm.portfolioTypeId !== appConfig.portfolioTypes.INV &&
                this.appForm.portfolioTypeId !== appConfig.portfolioTypes.TFDS
            ) {
                products.forEach(product => {
                    this.products.set(product.id, product);
                });

                this.product = this.products.get(this.appForm.portfolioTypeId);
                this.isCash = true;
            }

            if (!this.investmentForm) {
                this.createForm();
            }
        });
    }

    public isSourceOfFundsOther(id) {
        const sourceType = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return sourceType && sourceType.isOther;
    }

    public ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public debitDayChange() {
        this.investmentForm.get('debitOrder.firstDebitDate').setValue(this.toFormDate(this.calculateFirstDebitDate()));
        this.investmentForm.get('debitOrder.firstEscalationDate').setValue(
            this.toFormDate(
                moment(this.calculateFirstDebitDate())
                    .add(1, 'years')
                    .toDate()
            )
        );
    }

    public readableAccountType(type: AccountType) {
        return AccountType[type];
    }

    public accountName(account: CustomerBankAccount) {
        return account.accountName || AccountType[account.accountType];
    }

    public reloadBankAccounts() {
        this.sharedStore.dispatch(new LoadingAction());
        this.bankAccountLoadingError = false;

        let bankAccounts$ = this.isDirect
            ? this.sharedStore.select(fromShared.selectUserBankAccounts)
            : this.customerService.getBankAccounts(this.selectedCustomer.ucn);

        bankAccounts$.subscribe(
            accounts => {
                this.bankAccounts = accounts.map(acc => {
                    acc.accountNumber = `${+acc.accountNumber}`;
                    return acc;
                });
                this.applicationStore.dispatch(new BankAccountListLoadedAction(accounts));
                this.sharedStore.dispatch(new StopLoadingAction());
            },
            () => {
                this.bankAccountLoadingError = true;
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        );
    }

    private calculateFirstDebitDate(): moment.Moment {
        const userDebitDay = this.investmentForm ? +this.investmentForm.get('debitOrder.debitDate').value : 1;
        return this.applicationDateService.calculateFirstDebitDate(userDebitDay);
    }

    private toFormDate(date: Date | moment.Moment) {
        return this.dateFormatter.forDatePicker(moment(date));
    }

    private otherDescriptionRequired(id: number) {
        const type = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return type && type.isOther;
    }

    private validateSourceOfFunds(abstractForm: FormGroup): { [key: string]: any } {
        const sourceIdControl = abstractForm.get('sourceOfFundsId');
        const descriptionControl = abstractForm.get('otherDescription');

        const required = this.otherDescriptionRequired(+sourceIdControl.value);

        let valid = true;
        if (required) {
            const value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    }

    private validateMaxEscalationDate(first, second) {
        const errorControl = this.investmentForm.controls.debitOrder.get('firstEscalationDate');
        const firstDate = first ? moment(first) : null;
        const secondDate = second ? moment(second) : null;

        const valid =
            firstDate === null || secondDate === null || (!!firstDate && !!secondDate && secondDate.diff(firstDate, 'years', true) <= 1);
        const errorValue = valid ? null : { datemaxfrommin: valid ? null : firstDate.format('YYYY-MM-DD') };
        const afterMin = firstDate === null || secondDate === null || secondDate.isSameOrAfter(firstDate);

        const minErrorValue = afterMin ? null : { datemin: afterMin ? null : firstDate.format('YYYY-MM-DD') };

        if (errorControl) {
            if (errorValue) {
                errorControl.setErrors(errorValue);
            } else if (minErrorValue) {
                errorControl.setErrors(minErrorValue);
            } else {
                errorControl.setErrors(null);
            }
        }
    }

    private createForm() {
        const form: any = {};

        form.bankAccount = this.formBuilder.group({
            selectedAccount: [this.appForm.debitBankAccount, Validators.required],
        });

        let lumpsumArray = this.formBuilder.array([]);
        form.lumpSum = lumpsumArray;

        if (!!this.appForm.recordOfAdvice.pstLumpsumDetails) {
            this.appForm.lumpSumDetails = this.appForm.recordOfAdvice.pstLumpsumDetails;
            for (let payment of this.appForm.lumpSumDetails) {
                lumpsumArray.push(
                    this.formBuilder.group(
                        {
                            amount: [this.numberFormatter.transformBigNumber(payment.amount)],
                            debitDate: [
                                this.toFormDate(payment.debitDate),
                                Validators.compose([Validators.required, dateMinValidator(payment.debitDate, this.dateFormatter)]),
                            ],
                            sourceOfFundsId: [payment.sourceOfFundsId, Validators.required],
                            otherDescription: [payment.otherDescription],
                            fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
                        },
                        { validator: this.validateSourceOfFunds.bind(this) }
                    )
                );
            }
        }

        if (!!this.appForm.debitOrderDetails) {
            form.debitOrder = this.formBuilder.group(
                {
                    amount: [this.numberFormatter.transformBigNumber(this.appForm.debitOrderDetails.amount)],
                    debitDate: [this.appForm.debitOrderDetails.debitDate],
                    firstDebitDate: [this.toFormDate(this.appForm.debitOrderDetails.firstDebitDate)],
                    firstEscalationDate: [this.toFormDate(this.appForm.debitOrderDetails.firstEscalationDate)],
                    escalation: [
                        this.appForm.debitOrderDetails.escalation < 1
                            ? (+this.appForm.debitOrderDetails.escalation.toFixed(3) * 100)
                            : +this.appForm.debitOrderDetails.escalation,
                    ],
                    sourceOfFundsId: [this.appForm.debitOrderDetails.sourceOfFunds.sourceOfFundsId, Validators.required],
                    otherDescription: [this.appForm.debitOrderDetails.sourceOfFunds.otherDescription],
                    fundingTypeId: [this.isCash ? appConfig.fundingTypes.cash : appConfig.fundingTypes.standard],
                },
                { validator: this.validateSourceOfFunds.bind(this) }
            );
            form.debitOrder.get('debitDate').disable();
        }

        this.investmentForm = this.formBuilder.group(form);
        this.investmentForm.valueChanges.subscribe(changes => {
            let changesClone = {
                debitOrder: changes.debitOrder ? Object.assign({}, changes.debitOrder) : undefined,
                lumpSum: changes.lumpSum ? Object.assign([], changes.lumpSum) : undefined,
                bankAccount: {
                    selectedAccount:
                        changes.bankAccount && changes.bankAccount.selectedAccount && Object.assign(changes.bankAccount.selectedAccount),
                },
            };
            if (changesClone.debitOrder) {
                changesClone.debitOrder.firstDebitDate = this.dateFormatter.forApi(changes.debitOrder.firstDebitDate);
                changesClone.debitOrder.firstEscalationDate = this.dateFormatter.forApi(changes.debitOrder.firstEscalationDate);
            }
            if (changesClone.lumpSum) {
                changesClone.lumpSum = changesClone.lumpSum.map(lumpsum =>
                    Object.assign({}, lumpsum, { debitDate: this.dateFormatter.forApi(lumpsum.debitDate) })
                );
            }
            this.applicationStore.dispatch(
                new AppFormUpdatedDebitOrderDetailsAction({
                    lumpSumDetails: changesClone.lumpSum,
                    debitOrderDetails: changesClone.debitOrder,
                })
            );
            this.applicationStore.dispatch(new AppFormUpdatedBankAccountAction(changes.bankAccount && changes.bankAccount.selectedAccount));
            if (changesClone.debitOrder) {
                this.validateMaxEscalationDate(changesClone.debitOrder.firstDebitDate, changesClone.debitOrder.firstEscalationDate);
            }
            if (this.isCash) {
                let selectedAccount = this.investmentForm.get('bankAccount').get('selectedAccount').value as CustomerBankAccount;
                if (!selectedAccount) {
                    this.insufficientFunds = false;
                } else {
                    let lumpsumAmount =
                        this.appForm.lumpSumDetails.length > 0
                            ? +this.numberFormatter.parseBigNumber(`${this.appForm.lumpSumDetails[0].amount}`)
                            : 0;
                    this.insufficientFunds = lumpsumAmount > selectedAccount.availableBalance;
                }
            } else {
                this.insufficientFunds = false;
            }
            this.applicationStore.dispatch(new InsufficientFundsAction(this.insufficientFunds));
            this.applicationStore.dispatch(new InsufficientFundsAction(this.insufficientFunds));
        });
        this.investmentForm.statusChanges.subscribe(val => {
            this.applicationStore.dispatch(new InvestmentDetailsValidAction(val === 'VALID'));
        });
    }
}
