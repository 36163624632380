import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { CisSearchService } from 'banker/services';
import { NewCustomerSearchAction } from 'banker/store/banker.reducers';

import { Store as NgxsStore } from '@ngxs/store';
import { CustomerFilterReason, CustomerSearchResult } from 'application/models';
import { ApplicationState, CustomerSelectedAction } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { BankerState } from 'banker/store';
import * as fromBanker from 'banker/store/banker.store';
import { PstState } from 'pst/store';
import { AdditionalLumpsum, RoboFieldId, SavedAdvice } from 'robo/models';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from 'shared/components/base';
import { LoggerService, LookupDataService, SaIdNumberValidationService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';

import { RoboAdditionalLumpsumsUpdated, RoboClearScreenAction, RoboContinuedAction, RoboUpdateMultipleFieldsAction } from 'robo/store';
import { saIdNumberValidator } from 'shared/validators';
import { PortfolioType } from 'shared/models';

@Component({
    selector: 'wim-customer-search',
    templateUrl: 'customer-search.component.pug',
    styleUrls: ['customer-search.component.scss'],
})
export class CustomerSearchComponent extends BaseComponent implements OnInit, OnDestroy {
    public searchForm: FormGroup;
    public searchResult: Observable<CustomerSearchResult[]>;
    public selectedCustomer: CustomerSearchResult;
    public searchError = false;
    public searchPerformed = false;
    public emptyResultsMessage = '';
    private portfolioTypes: PortfolioType[];

    constructor(
        private formBuilder: FormBuilder,
        private cisService: CisSearchService,
        private bankerStore: Store<BankerState>,
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private pstStore: Store<PstState>,
        private router: Router,
        private lookupService: LookupDataService,
        private saIdNumberService: SaIdNumberValidationService,
        private ngxsStore: NgxsStore,
        loggerService: LoggerService
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.createForm();
        this.searchResult = this.bankerStore.select(fromBanker.selectCustomerSearchResults);
        const customer$ = this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .subscribe(selected => (this.selectedCustomer = selected));
        // Fetch now so we can be sure they'll be there when we need them
        const funds$ = this.lookupService.getSourceOfFundsTypes().subscribe();
        this.lookupService.getPortfolioTypes().subscribe(portfolios => {this.portfolioTypes = portfolios});
        this.registerSubscriptions(customer$, funds$);
        this.sharedStore.dispatch(new StopLoadingAction());
    }
    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }

    public search() {
        this.searchError = false;
        this.emptyResultsMessage = this.getEmptyMessage();

        this.sharedStore.dispatch(new LoadingAction());
        this.cisService
            .search(this.searchForm.get('customerNumber').value, this.searchForm.get('idNumber').value)
            .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
            .subscribe(
                results => {
                    this.bankerStore.dispatch(new NewCustomerSearchAction(results));
                    this.searchPerformed = true;

                    if (results.length === 1) {
                        this.select(results[0]);
                    }
                },
                error => (this.searchError = true)
            );
    }

    public canSearch() {
        if (!this.searchForm) {
            return false;
        }

        return (
            (this.searchForm.value.idNumber &&
                this.searchForm.value.idNumber.length > 0 &&
                !!this.searchForm.controls.idNumber &&
                this.searchForm.controls.idNumber.valid) ||
            (this.searchForm.controls.customerNumber &&
                !!this.searchForm.value.customerNumber &&
                this.searchForm.value.customerNumber.length > 0 &&
                this.searchForm.controls.customerNumber.valid)
        );
    }

    public reset() {
        this.searchForm.reset();
        this.searchPerformed = false;
        this.bankerStore.dispatch(new NewCustomerSearchAction([]));
        this.bankerStore.dispatch(new CustomerSelectedAction(null));
    }

    public select(customer: CustomerSearchResult) {
        if (customer.validCustomer) {
            this.bankerStore.dispatch(new CustomerSelectedAction(customer));
        } else {
            this.bankerStore.dispatch(new CustomerSelectedAction(null));
        }
    }

    public isSelected(customer: CustomerSearchResult) {
        return this.selectedCustomer && customer.ucn === this.selectedCustomer.ucn;
    }

    public canStart() {
        return this.selectedCustomer;
    }

    public start() {
        if (!this.canStart()) {
            return;
        }
        //this.router.navigateByUrl('/secure/banker/robo');
        this.router.navigateByUrl('/invest');
    }

    public getYears(months: number) {
        return Math.floor(months / 12);
    }

    public getMonths(months: number) {
        return months % 12;
    }

    public GetPortfolioType(portfolioTypeId: number) {
        return this.portfolioTypes.filter(p => p.id === portfolioTypeId)[0].name;
    }    

    public getInEligibilityReason(reason: CustomerFilterReason) {
        switch (reason) {
            case CustomerFilterReason.email:
                return `This client doesn't have an email address, please update their email and resubmit the investment application`;
            case CustomerFilterReason.kyc:
                // tslint:disable-next-line:max-line-length
                return 'This client is not KYC compliant please follow the normal KYC process and resubmit the investment application once KYC compliant';
            case CustomerFilterReason.minor:
                return 'This client is not at least 18 years old';
            case CustomerFilterReason.privateIndividual:
                return 'This client is not a private individual';
            case CustomerFilterReason.southAfricanId:
                return 'This client is not South African';
            case CustomerFilterReason.ucnCountry:
                return 'This client is not South African';
            case CustomerFilterReason.deceased:
                return 'This client is deceased';
            case CustomerFilterReason.accounts:
                return 'This client has no valid accounts that we can debit';
            case CustomerFilterReason.taxResidency:
                return 'This client is not a South African tax resident';
            case CustomerFilterReason.fnbAccount:
                return 'This client has no valid FNB accounts';
            case CustomerFilterReason.undesirable:
                return 'In accordance with domestic and international regulations, the Bank is required to take steps to prevent and mitigate its risk. Accordingly, the bank may not make any further products or services available to this client';
            case CustomerFilterReason.crsCompliant:
                return 'Client is not CRS Compliant. Please follow CRS process and provide tax residency updates to regulatory screens.';
            case CustomerFilterReason.foreignTaxIndicatorValue:
                return 'Client foreign tax residency is unknown. Please follow CRS process and provide tax residency updates to regulatory screens and confirm if the client has a foreign tax residency.';
            case CustomerFilterReason.foreignTaxResidency:             
                return 'Client foreign tax residency detected. Please verify if foreign tax residency status is correct and active and follow CRS process to provide tax residency updates to Regulatory screens.';
            default:
                return '';
        }
    }

    public continueAdvice(result: SavedAdvice) {
        this.ngxsStore.dispatch(new RoboClearScreenAction());
        let fields = result.fields
            .filter(field => field.fieldId !== RoboFieldId.lumpSumAmount && field.fieldId !== RoboFieldId.lumpSumDate)
            .filter(field => field.value && field.value.trim().length > 0);
        if(this.selectedCustomer.hasExistingTaxFreeAccount || this.selectedCustomer.hasInFlightTaxFreeApplication) {
            fields = fields.filter(field => field.fieldId !== RoboFieldId.contributingToTaxFree);
        }         
        this.ngxsStore.dispatch(new RoboUpdateMultipleFieldsAction(fields));

        let lumpSumFields = result.fields.filter(
            field => field.fieldId === RoboFieldId.lumpSumAmount || field.fieldId === RoboFieldId.lumpSumDate
        );
        let additionalLumpsums: AdditionalLumpsum[] = [];
        for (let field of lumpSumFields) {
            if (!additionalLumpsums[field.index]) {
                additionalLumpsums[field.index] = {
                    amount: 0,
                    date: null,
                };
            }
            if (field.fieldId === RoboFieldId.lumpSumAmount) {
                additionalLumpsums[field.index].amount = +field.value;
            } else if (field.fieldId === RoboFieldId.lumpSumDate) {
                additionalLumpsums[field.index].date = field.value;
            }
        }
        this.ngxsStore.dispatch(new RoboAdditionalLumpsumsUpdated(additionalLumpsums));
        this.ngxsStore.dispatch(new RoboContinuedAction(result.pstReference));
        this.router.navigateByUrl(`/secure/banker/robo/advice`);
    }

    private createForm() {
        this.searchForm = this.formBuilder.group({
            customerNumber: ['', [Validators.pattern('^[0-9]+$')]],
            idNumber: ['', Validators.compose([Validators.pattern('^[0-9]+$'), saIdNumberValidator(this.saIdNumberService, true)])],
        });
    }

    private getEmptyMessage() {
        const idNumber = this.searchForm.get('idNumber');
        const ucn = this.searchForm.get('customerNumber');

        return idNumber.value && ucn.value
            ? 'No client found linked to this UCN and ID Number'
            : ucn.value
            ? 'No client found linked to this UCN'
            : 'No client found linked to this ID Number';
    }
}
