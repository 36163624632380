﻿import { DebitOrderDate } from './debit-order-date';
import { SourceOfFundsDeclaration } from './source-of-funds-declaration';

export class DebitOrderDetails {
    public sourceOfFunds: SourceOfFundsDeclaration = new SourceOfFundsDeclaration();
    public debitDate: DebitOrderDate;
    public firstDebitDate: Date;
    public amount: number;
    public escalation: number;
    public firstEscalationDate: Date;
    public fundingTypeId: number;
    public paymentFrequencyId: number;
}
