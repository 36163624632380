import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { InvestmentAccount, PendingAccounts } from 'investor/models';
import {
    InvestmentAccountsLoadedAction,
    InvestmentAccountsLoadingErrorAction,
    InvestmentAccountsPendingAction,
    HasProcessingApplicationsAction,
} from 'investor/store/account.reducers';

import { InvestorState } from 'investor/store/investor.store';
import * as fromInvestor from 'investor/store/investor.store';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FeatureFlags, User } from 'shared/models';
import { FeatureFlagService } from 'shared/services';

@Injectable()
export class AccountsService {
    constructor(private http: HttpClient, private investorStore: Store<InvestorState>, private featureFlagService: FeatureFlagService) {}

    public fetchAccounts() {
        if (this.featureFlagService.isEnabled(FeatureFlags.BalanceDisplay)) {
            this.http
                .get<InvestmentAccount[]>(`${appConfig.apiUrl}/accounts`)
                .pipe(
                    catchError(error => {
                        this.investorStore.dispatch(new InvestmentAccountsLoadingErrorAction(error));
                        return of(error);
                    })
                )
                .subscribe(accounts => this.investorStore.dispatch(new InvestmentAccountsLoadedAction(accounts)));
        } else {
            this.investorStore.dispatch(new InvestmentAccountsLoadedAction([]));
        }
    }

    public fetchPendingAccounts(user: User) {
        if (this.featureFlagService.isEnabled(FeatureFlags.AcceptTsAndCs)) {
            /*this.http.get<PendingAccounts>(`${appConfig.apiUrl}/accounts/pending`).subscribe(pendingAccounts => {
                this.investorStore.dispatch(new InvestmentAccountsPendingAction(pendingAccounts.accountsPendingAgreements));
                this.investorStore.dispatch(new HasProcessingApplicationsAction(pendingAccounts.processingClientApplications));
            });*/
            this.investorStore.dispatch(new InvestmentAccountsPendingAction((user.customerDetails.pendingAgreements && user.customerDetails.pendingAgreements.length > 0)));
            this.investorStore.dispatch(new HasProcessingApplicationsAction((user.customerDetails.processingApplications && user.customerDetails.processingApplications.length > 0)));            

        } else {
            this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
            this.investorStore.dispatch(new HasProcessingApplicationsAction(false));
        }
    }
}
