import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PstReference } from 'pst/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { IContextLogic } from './icontext-logic';

const userTypes = {
    direct: 100,
    agent: 101,
};

@Injectable()
export class UserTypeContextService implements IContextLogic {
    constructor(private sharedStore: Store<SharedState>) {}

    public execute(tree: PstReference): Observable<number> {
        return this.sharedStore.select(fromShared.selectIsDirect).pipe(map(isDirect => (isDirect ? userTypes.direct : userTypes.agent)));
    }
}
