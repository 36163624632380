/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./general-documents.component";
import * as i3 from "../../../services/documents.service";
import * as i4 from "@ngrx/store";
var styles_GeneralDocumentsComponent = [];
var RenderType_GeneralDocumentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GeneralDocumentsComponent, data: {} });
export { RenderType_GeneralDocumentsComponent as RenderType_GeneralDocumentsComponent };
function View_GeneralDocumentsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "td", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-clock-o"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["\u00A0 ", ""])), i0.ɵppd(5, 2), (_l()(), i0.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["class", "btn btn-sm btn-themecolor-sec btn-rounded waves-effect waves-orange font-bold float-right"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.dateAdded, "yyyy/MM/dd")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.url, ""); _ck(_v, 9, 0, currVal_2); }); }
export function View_GeneralDocumentsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 16, "div", [["class", "col-12 fadeInUp animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 15, "div", [["class", "card rad"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 14, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 13, "div", [["class", "table-responsive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 12, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Date Added"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Document Name"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0"])), (_l()(), i0.ɵeld(15, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_GeneralDocumentsComponent_1)), i0.ɵdid(17, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform(_co.documents)); _ck(_v, 17, 0, currVal_0); }, null); }
export function View_GeneralDocumentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-general-documents", [], null, null, null, View_GeneralDocumentsComponent_0, RenderType_GeneralDocumentsComponent)), i0.ɵdid(1, 245760, null, 0, i2.GeneralDocumentsComponent, [i3.DocumentsService, i4.Store, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeneralDocumentsComponentNgFactory = i0.ɵccf("wim-general-documents", i2.GeneralDocumentsComponent, View_GeneralDocumentsComponent_Host_0, {}, {}, []);
export { GeneralDocumentsComponentNgFactory as GeneralDocumentsComponentNgFactory };
