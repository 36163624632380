var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AccountsService, InstructionsService } from 'investor/services';
import { FnbSecuritiesService } from 'investor/services';
import * as fromInvestor from 'investor/store/investor.store';
import { combineLatest } from 'rxjs';
import { EacModalComponent } from 'shared/components';
import { BaseComponent } from 'shared/components/base';
import { FeatureFlags } from 'shared/models';
import { FeatureFlagService, LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
var InvestorHomeComponent = /** @class */ (function (_super) {
    __extends(InvestorHomeComponent, _super);
    function InvestorHomeComponent(loggerService, investorStore, sharedStore, router, fnbsService, formBuilder, instructionService, modalService, accountService, featureFlagService) {
        var _this = _super.call(this, loggerService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.router = router;
        _this.fnbsService = fnbsService;
        _this.formBuilder = formBuilder;
        _this.instructionService = instructionService;
        _this.modalService = modalService;
        _this.accountService = accountService;
        _this.featureFlagService = featureFlagService;
        _this.factSheetImage = require('assets/images/icons/download-pdf.svg');
        _this.showInvestmentAccounts = false;
        _this.showTradingAccounts = false;
        _this.tradingAccountTotal = 0;
        _this.investmentAccountTotal = 0;
        _this.submitSsoForm = false;
        _this.ssoError = false;
        _this.loadingError = false;
        _this.documentsEnabled = false;
        _this.instructionsEnabled = false;
        _this.documentsEnabled = _this.featureFlagService.isEnabled(FeatureFlags.DownloadDocuments);
        _this.instructionsEnabled = _this.featureFlagService.isEnabled(FeatureFlags.MaintenanceInstructions);
        return _this;
    }
    InvestorHomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.sharedStore.dispatch(new LoadingAction());
        this.tradingAccounts$ = this.investorStore.select(fromInvestor.selectTradingAccounts);
        this.investmentAccounts$ = this.investorStore.select(fromInvestor.selectInvestmentAccounts);
        this.hasPendingAgreements$ = this.investorStore.select(fromInvestor.selectInvestmentAccountsPending);
        this.investorStore
            .select(fromInvestor.selectInvestmentAccountLoadingError)
            .pipe(this.scavenger.collect())
            .subscribe(function (error) {
            _this.loadingError = !!error;
        });
        this.hasPendingAgreements$
            .pipe(this.scavenger.collect())
            .subscribe(function (accountsPending) { return (_this.hasPendingAgreements = accountsPending); });
        this.investmentAccounts$.pipe(this.scavenger.collect()).subscribe(function (accounts) {
            if (accounts && Array.isArray(accounts)) {
                _this.investmentAccountTotal = accounts.reduce(function (total, account) { return total + account.accountTotalValue; }, 0);
            }
        });
        this.tradingAccounts$.pipe(this.scavenger.collect()).subscribe(function (fnbsAccounts) {
            if (fnbsAccounts && Array.isArray(fnbsAccounts)) {
                _this.tradingAccountTotal = fnbsAccounts.reduce(function (total, account) { return total + account.totalValue; }, 0);
            }
        });
        combineLatest(this.tradingAccounts$, this.investmentAccounts$, this.hasPendingAgreements$, function (tradingAccounts, investmentAccounts, pendingAgreements) {
            return { tradingAccounts: tradingAccounts, investmentAccounts: investmentAccounts, pendingAgreements: pendingAgreements };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var tradingAccounts = _a.tradingAccounts, investmentAccounts = _a.investmentAccounts, pendingAgreements = _a.pendingAgreements;
            if (!tradingAccounts || !investmentAccounts || pendingAgreements === null) {
                return;
            }
            _this.sharedStore.dispatch(new StopLoadingAction());
            var hasTradingAccounts = tradingAccounts && tradingAccounts.length > 0;
            var hasInvestmentAccounts = investmentAccounts && investmentAccounts.length > 0;
            var loadingError = investmentAccounts && !Array.isArray(investmentAccounts);
            if (!hasTradingAccounts && !hasInvestmentAccounts && !pendingAgreements && !loadingError) {
                _this.router.navigateByUrl('/');
                return;
            }
            if (pendingAgreements) {
                _this.showInvestmentAccounts = false;
                _this.showTradingAccounts = false;
            }
            else {
                _this.showTradingAccounts = hasTradingAccounts;
                _this.showInvestmentAccounts = hasInvestmentAccounts || loadingError;
            }
        });
        this.accountService.fetchAccounts();
    };
    InvestorHomeComponent.prototype.ngOnDestroy = function () { };
    InvestorHomeComponent.prototype.showFactSheet = function (fund) {
        return this.instructionService.canInstructOnAccountItem(fund);
    };
    InvestorHomeComponent.prototype.acceptTerms = function () {
        this.router.navigate(['/secure/investor/pending-agreements']);
    };
    InvestorHomeComponent.prototype.openEacModal = function (account) {
        var _this = this;
        // We cannot use the total allocation from the server as it takes the cash
        // element into account. This does not affect fees, so we need to caluclate allocation based only on holdings
        var totalHoldings = account.accountPortfolios
            .filter(function (accObj) { return _this.showFactSheet(accObj); })
            .reduce(function (total, fund) { return total + fund.totalValue; }, 0);
        var accountEAC = account.accountPortfolios
            .filter(function (accObj) { return _this.showFactSheet(accObj); })
            .map(function (accObj) {
            return {
                fundCode: accObj.fundCode.replace(/ ZA/g, ''),
                allocation: accObj.totalValue / totalHoldings,
            };
        });
        var modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = {
            funds: accountEAC,
        };
    };
    InvestorHomeComponent.prototype.filterOutZeros = function (accountItems) {
        return accountItems.filter(function (item) { return item.fundCode !== '-'; });
    };
    InvestorHomeComponent.prototype.sso = function (account, destination) {
        var _this = this;
        this.ssoError = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.fnbsService.getSsoToken({ accountNumber: account && account.bda, target: destination }).subscribe(function (response) {
            _this.ssoForm.setValue({ token: response.token });
            _this.ssoPostTarget = response.redirectUrl;
            _this.submitSsoForm = true;
        }, function () {
            _this.ssoError = true;
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    InvestorHomeComponent.prototype.createForm = function () {
        this.ssoForm = this.formBuilder.group({
            token: [''],
        });
    };
    return InvestorHomeComponent;
}(BaseComponent));
export { InvestorHomeComponent };
