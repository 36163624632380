import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Actions, ofActionDispatched, Store as NgxsStore } from '@ngxs/store';
import appConfig from 'appConfig';
import { DebitOrderDetails } from 'application/models';
import { LumpSumDetails } from 'application/models/lump-sum-details';
import { SourceOfFundsDeclaration } from 'application/models/source-of-funds-declaration';
import { AppFormUpdatedDebitOrderDetailsAction, ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PstResult } from 'pst/models';
import { PstAdviceShownAction, PstCompletedAction, PstState } from 'pst/store';
import { DisclaimerModalComponent } from 'robo/components/disclaimer-modal';
import { AdviceEngineService } from 'robo/services/advice-engine.service';
import { Observable, of } from 'rxjs';
import { combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { RoboGoal } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';
import { EnableFixedFooterAction, LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { AdditionalLumpsum, RoboInput } from '../..';
import { Recommendation, RoboFieldId } from '../../models';
import { ForceValidationMessagesAction, RoboSavedAction, RoboStore } from '../../store';
import { AdviceDownloadModalComponent } from '../advice-download-modal/advice-download-modal.component';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';

@Component({
    selector: 'wim-robo-advice',
    templateUrl: 'advice.component.pug',
    styleUrls: ['advice.component.scss'],
})
export class RoboAdviceComponent extends BaseComponent implements OnInit, AfterViewInit {
    public customerName: string;
    public error$: Observable<Error>;

    private formValid = false;
    public adviceServerError = false;
    private adviceValidationErrors = false;

    private isDirect: boolean;
    private goals: RoboGoal[];
    private roboFields: { [key: number]: RoboInput } = {};
    private allLumpsums: LumpSumDetails[] = [];
    private lumpsums: AdditionalLumpsum[];
    private taxFreeOpened = true;
    private recommendation: Recommendation;
    public error: string = null;
    private isCash: boolean;
    private isTaxFree: boolean;
    private isUnitTrust: boolean;
    public validationErrors$: Observable<string[]>;
    public adviceEngineValidationWarnings$: Observable<string[]>;

    public downstreamError$: Observable<boolean>;
    public downloadingError = null;

    constructor(
        loggerService: LoggerService,
        private sharedStore: Store<SharedState>,
        private applicationStore: Store<ApplicationState>,
        private pstStore: Store<PstState>,
        private store: NgxsStore,
        private router: Router,
        private adviceService: AdviceEngineService,
        private lookupDataService: LookupDataService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private actions$: Actions
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.sharedStore.dispatch(new EnableFixedFooterAction(true));

        let customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        let isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        combineLatest(customer$, isDirect$, (customer, isDirect) => {
            return { customer, isDirect };
        })
            .pipe(
                this.scavenger.collect(),
                catchError(error => {
                    this.error = error;
                    return of(error);
                })
            )
            .subscribe(({ customer, isDirect }) => {
                this.isDirect = isDirect;
                if (customer === null) {
                    if (isDirect === false) {
                        this.logger.warn('Cannot give advice before selecting a customer, navigating to customer search');
                        this.router.navigateByUrl('/secure/banker/customer-search');
                        this.sharedStore.dispatch(new StopLoadingAction());
                        return;
                    } else if (isDirect === true) {
                        this.logger.warn('Cannot give advice before selecting a customer, navigating to SSO screen to lookup customer');
                        this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
                        return;
                    }
                }
                this.customerName = `${customer.title} ${customer.firstname} ${customer.lastname}`;
            });

        this.adviceService.getAdvice(() => this.sharedStore.dispatch(new StopLoadingAction()));
        this.error$ = this.store.select(RoboStore.adviceError);
        this.error$.pipe(this.scavenger.collect()).subscribe(error => (this.adviceServerError = !!error));
        this.validationErrors$ = this.store.select(RoboStore.validationErrors);
        this.adviceEngineValidationWarnings$ = this.store.select(RoboStore.adviceEngineValidationWarnings);         
        this.downstreamError$ = this.store.select(RoboStore.downstreamAdviceError);

        this.store
            .select(RoboStore.validationErrors)
            .pipe(this.scavenger.collect())
            .subscribe(errors => (this.adviceValidationErrors = errors && errors.length > 0));

        this.lookupDataService
            .getRoboGoals()
            .pipe(this.scavenger.collect())
            .subscribe(goals => (this.goals = goals));

        this.store
            .select(RoboStore.completedFields)
            .pipe(this.scavenger.collect())
            .subscribe(fields => (this.roboFields = fields));

        this.store
            .select(RoboStore.additionalLumpsums)
            .pipe(this.scavenger.collect())
            .subscribe(additionalLumpsums => (this.lumpsums = additionalLumpsums));

        this.store
            .select(RoboStore.taxFreeOpened)
            .pipe(this.scavenger.collect())
            .subscribe(taxFreeOpened => (this.taxFreeOpened = taxFreeOpened));

        this.store
            .select(RoboStore.saveError)
            .pipe(this.scavenger.collect())
            .subscribe(error => {
                if (error) {
                    this.logger.error(error);
                    this.toastr.error('The robo-advisor result could not be saved, please try again later.');
                }
            });

        this.actions$
            .pipe(
                ofActionDispatched(RoboSavedAction),
                this.scavenger.collect()
            )
            .subscribe(() => {
                this.toastr.success(
                    `The robo-advisor result was successfully saved. It can be
                             retrieved for the next 5 days when searching for a customer`
                );
            });

        this.store
            .select(RoboStore.currentAdvice)
            .pipe(this.scavenger.collect())
            .subscribe(advice => {
                this.recommendation = advice ? advice.recommendations[0] : null;
            });

        this.store
            .select(RoboStore.adviceFormValid)
            .pipe(this.scavenger.collect())
            .subscribe(valid => (this.formValid = valid));
    }

    public ngAfterViewInit() {}

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }

    public cancel() {
        if (this.isDirect) {
            this.router.navigateByUrl('/secure/investor');
        } else {
            this.router.navigateByUrl('/secure/banker');
        }
    }

    public restart() {
        if (this.isDirect) {
            this.router.navigateByUrl('/secure/investor/robo');
        } else {
            this.router.navigateByUrl('/secure/banker/robo');
        }
    }

    public saveForLater() {
        this.sharedStore.dispatch(new LoadingAction());
        this.adviceService.saveForLater(() => {
            this.sharedStore.dispatch(new StopLoadingAction());
        });
    }

    public canDownload() {
        return this.recommendation && this.recommendation.totalContributions > 0;
    }

    public download() {
        this.sharedStore.dispatch(new LoadingAction());
        this.downloadingError = false;

        this.adviceService.prepareDownload(!this.taxFreeOpened).subscribe(
            uniqueUrlModel => {
                this.sharedStore.dispatch(new StopLoadingAction());
                let downloadModal = this.modalService.open(AdviceDownloadModalComponent);
                downloadModal.componentInstance.uniqueId = uniqueUrlModel.uniqueId;
                downloadModal.componentInstance.uniqueUrl = `${appConfig.apiUrl}/${uniqueUrlModel.uniqueUrl}`;
            },
            err => {
                console.error(err);
                this.sharedStore.dispatch(new StopLoadingAction());
                this.downloadingError = true;
            }
        );
    }

    private getValue(fieldId: RoboFieldId) {
        let field = this.roboFields[fieldId];
        if (field) {
            return field.value;
        }
        return null;
    }

    private populateLumpSums() {
        if (this.getValue(RoboFieldId.lumpSumAmount) === null || this.getValue(RoboFieldId.lumpSumAmount) === undefined) {
            return [];
        }

        let portfolioType = this.getSelectedPortfolioType();
        let date = moment();
        if (portfolioType === 3 || portfolioType === 10) {
            date = moment(this.getValue(RoboFieldId.lumpSumDate));
        } else {
            date = moment().startOf('day');
        }

        let initialLumpSum = {
            debitDate: date.toDate(),
            amount: Number(+this.getValue(RoboFieldId.lumpSumAmount)),
            fundingTypeId: this.populatePortfolioType(),
            sourceOfFunds: new SourceOfFundsDeclaration(),
        } as LumpSumDetails;

        this.allLumpsums.push(initialLumpSum);

        if (this.lumpsums) {
            this.lumpsums.forEach(lumpsum => {
                let additionalLumpSum = {
                    debitDate: new Date(lumpsum.date),
                    amount: Number(lumpsum.amount),
                    fundingTypeId: this.populatePortfolioType(),
                    sourceOfFunds: new SourceOfFundsDeclaration(),
                } as LumpSumDetails;

                this.allLumpsums.push(additionalLumpSum);
            });
        }
        return this.allLumpsums;
    }

    private calculateLumpSums() {
        let totalContribution = 0;
        let initialLumpSumAmount = Number(+this.getValue(RoboFieldId.lumpSumAmount));
        if (Number.isNaN(initialLumpSumAmount)) {
            initialLumpSumAmount = 0;
        }
        totalContribution = initialLumpSumAmount;

        if (this.lumpsums) {
            this.lumpsums.forEach(lumpsum => {
                totalContribution = totalContribution + Number(lumpsum.amount);
            });
        }
        return totalContribution;
    }

    private getAdvisedPortfolioType() {
        if (this.recommendation.taxFreeOptionAvailable && this.recommendation.portfolioTypeId === appConfig.portfolioTypes.INV) {
            return appConfig.portfolioTypes.TFDS;
        }
        return this.recommendation.portfolioTypeId;
    }

    private getSelectedPortfolioType() {
        if (this.recommendation.taxFreeOptionAvailable && this.taxFreeOpened) {
            return appConfig.portfolioTypes.TFDS;
        }
        return this.recommendation.portfolioTypeId;
    }

    private populateDebitOrders() {
        let debitOrder = {
            sourceOfFunds: new SourceOfFundsDeclaration(),
            debitDate: this.populateDebitDate(),
            firstDebitDate: this.calculateDebitDate(),
            amount: Number(+this.getValue(RoboFieldId.recurringContributionAmount)),
            escalation: Number(+this.getValue(RoboFieldId.annualEscalationPercentage)),
            firstEscalationDate: this.calculateEscalationDate(),
            fundingTypeId: this.populatePortfolioType(),
            paymentFrequencyId: Number(+this.getValue(RoboFieldId.recurringContributionFrequency)),
        } as DebitOrderDetails;
        return debitOrder;
    }

    private calculateDebitDate() {
        let date = moment(this.getValue(RoboFieldId.recurringContributionStartDate));
        return date.toDate();
    }

    private populateDebitDate() {
        let portfolioType = this.getSelectedPortfolioType();
        let transferDay = 0;
        if (portfolioType === 3 || portfolioType === 10) {
            if (this.getValue(RoboFieldId.recurringContributionStartDate)) {
                transferDay = moment(this.getValue(RoboFieldId.recurringContributionStartDate)).date();
            } else if (this.getValue(RoboFieldId.lumpSumDate)) {
                transferDay = moment(this.getValue(RoboFieldId.lumpSumDate)).date();
            }
        } else {
            transferDay = moment().startOf('day').add(1, 'month').date();
        }

        return transferDay;
    }

    private calculateEscalationDate() {
        let firstDebitDate = moment(this.getValue(RoboFieldId.recurringContributionStartDate));
        return firstDebitDate
            .add(1, 'years')
            .toDate();
    }

    private populatePortfolioType() {
        if (this.recommendation.portfolioTypeId === 3) {
            this.isCash = false;
            this.isUnitTrust = true;
            this.isTaxFree = false;
            return appConfig.fundingTypes.standard;
        } else if (this.recommendation.portfolioTypeId === 10) {
            this.isCash = true;
            this.isUnitTrust = false;
            this.isTaxFree = false;
            return appConfig.fundingTypes.cash;
        } else if (this.recommendation.portfolioTypeId !== 3 && this.recommendation.portfolioTypeId !== 10) {
            this.isCash = false;
            this.isUnitTrust = false;
            this.isTaxFree = true;
            return appConfig.fundingTypes.standard;
        }
    }

    public startInvesting() {
        if (this.formInvalid()) {
            this.store.dispatch(new ForceValidationMessagesAction());
            let validationModal = this.modalService.open(ValidationModalComponent);
            validationModal.componentInstance.formInvalid = this.isAdviceFormInvalid();
            validationModal.componentInstance.recommendationInvalid = this.isRecommendationInvalid();
            validationModal.componentInstance.validationErrors = this.adviceValidationErrors;
            return;
        }

        let modal = this.modalService.open(DisclaimerModalComponent, { windowClass: 'large-modal' });
        modal.result
            .then(() => {
                let result = {
                    advisedInstallment: this.recommendation.requiredMonthlyContribution,
                    contributionIncrease: +this.getValue(RoboFieldId.annualEscalationPercentage) / 100,
                    clientInstallment: +this.getValue(RoboFieldId.recurringContributionAmount),
                    fundCode: '',
                    futureValue: +this.recommendation.projectedValue,
                    goalAmount: +this.getValue(RoboFieldId.targetValue),
                    goalName: this.getValue(RoboFieldId.goalName),
                    lumpSum: this.calculateLumpSums(),
                    portfolioTypeId: this.getSelectedPortfolioType(),
                    term: +this.getValue(RoboFieldId.term),
                    reference: 'ROBO',
                    lumpSumDetails: this.populateLumpSums(),
                    debitOrderDetails: this.populateDebitOrders(),
                    fundId: this.recommendation.fundId,
                    affordabilityConfirmed: true,
                    taxFreeOffered: this.recommendation.taxFreeOptionAvailable,
                    taxFreeOpened: this.taxFreeOpened,
                    advisedLumpSum: this.recommendation.requiredInitialLump,
                    advisedPortfolioTypeId: this.getAdvisedPortfolioType(),
                    pstAdvised: true,
                    accessType: this.getValue(RoboFieldId.withdrawalAccessType),
                    projectedInterestRate:
                        this.recommendation.cashAccountReferenceData && this.recommendation.cashAccountReferenceData.nominalRate,
                    goalAmountAdjustedForInflation: this.recommendation.goalAmountAdjustedForInflation,
                } as PstResult;

                let goal = this.goals.find(g => g.goalId === +this.roboFields[RoboFieldId.goal].value);
                if (goal) {
                    if (result.goalName && result.goalName.trim().length > 0) {
                        result.goalName = goal.name + '(' + result.goalName + ')';
                    } else {
                        result.goalName = goal.name;
                    }
                }

                this.pstStore.dispatch(new PstCompletedAction(result));
                this.pstStore.dispatch(new PstAdviceShownAction(result));

                if (this.isDirect) {
                    this.router.navigateByUrl('/secure/investor/application');
                } else {
                    this.router.navigateByUrl('/secure/banker/application');
                }
            })
            .catch(() => {});
        modal.componentInstance.portfolioTypeId = +this.recommendation.portfolioTypeId;
    }    

    public retry() {
        this.sharedStore.dispatch(new LoadingAction());
        this.adviceService.getAdvice(() => this.sharedStore.dispatch(new StopLoadingAction()));
    }

    private formInvalid() {
        return this.isAdviceFormInvalid() || this.isAnyErrors() || this.isRecommendationInvalid();
    }

    private isAdviceFormInvalid() {
        return !this.formValid;
    }

    public isAnyErrors() {
        return this.adviceServerError || this.adviceValidationErrors;
    }

    private isRecommendationInvalid() {
        return (
            this.recommendation === null || this.recommendation.totalContributions == null || this.recommendation.totalContributions === 0
        );
    }
}
