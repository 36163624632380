import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationValidity, CustomerBankAccount, CustomerSearchResult, ProductApplication } from 'application/models';

export interface ApplicationState {
    horizonApplication: ProductApplication;

    bankAccounts: CustomerBankAccount[];
    selectedBankAccount: CustomerBankAccount;
    applicationFormValid: ApplicationValidity;

    applicationSubmitted: boolean;
    applicationReference: string;
    appSubmissionError: Error;
    selectedCustomer: CustomerSearchResult;
}

const selectApplicationState = createFeatureSelector<ApplicationState>('application');
export const selectHorizonApplication = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.horizonApplication
);
export const selectBankAccounts = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.bankAccounts
);
export const selectSelectedBankAccount = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.selectedBankAccount
);
export const selectApplicationFormValid = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.applicationFormValid
);
export const selectApplicationSubmitted = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.applicationSubmitted
);
export const selectApplicationReference = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.applicationReference
);
export const selectAppSubmissionError = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.appSubmissionError
);
export const selectSelectedCustomer = createSelector(
    selectApplicationState,
    (state: ApplicationState) => state.selectedCustomer
);
export const selectCustomerHostAccountCodes = createSelector(
    selectApplicationState,
    (state: ApplicationState) => (state.selectedCustomer ? state.selectedCustomer.onHostAccounts : '')
);
