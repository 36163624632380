import { Injectable } from '@angular/core';

import { FeatureFlags } from '../models';

import { Logger, LoggerService } from './logger.service';

@Injectable()
export class FeatureFlagService {
    private logger: Logger;
    constructor(loggerService: LoggerService) {
        this.logger = loggerService.getLogger('FeatureFlagService');
    }

    private flags: { [feature: number]: boolean } = {};

    public setFlags(flags: { [feature: string]: boolean }) {
        for (let feature of Object.keys(flags)) {
            let flagEnum = FeatureFlags[feature];
            if (flagEnum !== undefined) {
                this.logFeature(flagEnum, flags[feature]);
                this.flags[flagEnum] = flags[feature];
            }
        }
    }

    public isEnabled(flag: FeatureFlags) {
        return this.flags[flag] || false;
    }

    private logFeature(flagEnum: FeatureFlags, enabled: boolean) {
        let name = `${FeatureFlags[flagEnum]} (${flagEnum})`;
        while (name.length < 35) {
            name += ' ';
        }
        this.logger.info(`${name}:\t${enabled}`);
    }
}
