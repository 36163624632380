/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./effective-annual-cost/eac-calculation.component.ngfactory";
import * as i2 from "./effective-annual-cost/eac-calculation.component";
import * as i3 from "../../services/lookup-data.service";
import * as i4 from "../../services/logger.service";
import * as i5 from "@angular/common";
import * as i6 from "../../services/eac.service";
import * as i7 from "../../services/feature-flag.service";
import * as i8 from "./eac-modal.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
var styles_EacModalComponent = [];
var RenderType_EacModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EacModalComponent, data: {} });
export { RenderType_EacModalComponent as RenderType_EacModalComponent };
export function View_EacModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Effective Annual Cost (EAC)"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "wim-eac-calculation", [], null, null, null, i1.View_EffectiveAnnualCostCalucationComponent_0, i1.RenderType_EffectiveAnnualCostCalucationComponent)), i0.ɵdid(8, 770048, null, 0, i2.EffectiveAnnualCostCalucationComponent, [i3.LookupDataService, i4.LoggerService, i5.DecimalPipe, i6.EffectiveAnnualCostService, i7.FeatureFlagService], { account: [0, "account"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.account; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_EacModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-eac-modal", [], null, null, null, View_EacModalComponent_0, RenderType_EacModalComponent)), i0.ɵdid(1, 49152, null, 0, i8.EacModalComponent, [i9.NgbActiveModal], null, null)], null, null); }
var EacModalComponentNgFactory = i0.ɵccf("wim-eac-modal", i8.EacModalComponent, View_EacModalComponent_Host_0, {}, {}, []);
export { EacModalComponentNgFactory as EacModalComponentNgFactory };
