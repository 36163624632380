import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { applicationRoutes } from 'application/application-routing.module';
import {
    AccountDocumentsComponent,
    DisinvestInstructionComponent,
    GeneralDocumentsComponent,
    GeneralInstructionComponent,
    InvestorAgreementsComponent,
    InvestorDirectSalesComponent,
    InvestorDocumentsComponent,
    InvestorHomeComponent,
    InvestorInstructionsComponent,
    LumpSumInstructionComponent,
    RecurringInstructionComponent,
    StatementInstructionComponent,
    SwitchInstructionComponent,
} from 'investor/components';
import { roboRoutes } from 'robo/robo-routing.module';

import { InvestorGuard } from '../shared/services';
import { FeatureGuard } from '../shared/services/feature.guard';

export const investorRoutes: Routes = [
    {
        path: '',
        canActivate: [InvestorGuard],
        children: [
            { path: 'investor-home', component: InvestorHomeComponent, canActivate: [FeatureGuard] },
            { path: 'pending-agreements', component: InvestorAgreementsComponent, canActivate: [FeatureGuard] },
            { path: 'sso', component: InvestorDirectSalesComponent },
            {
                path: 'documents',
                component: InvestorDocumentsComponent,
                children: [
                    { path: '', redirectTo: 'general', pathMatch: 'full' },
                    { path: 'general', component: GeneralDocumentsComponent },
                    { path: 'account', component: AccountDocumentsComponent },
                ],
                canActivate: [FeatureGuard],
            },
            {
                path: 'investor-instructions',
                component: InvestorInstructionsComponent,
                children: [
                    { path: '', redirectTo: 'general', pathMatch: 'full' },
                    { path: 'general', component: GeneralInstructionComponent },
                    { path: 'lumpsum', component: LumpSumInstructionComponent },
                    { path: 'recurring', component: RecurringInstructionComponent },
                    { path: 'switch', component: SwitchInstructionComponent },
                    { path: 'disinvest', component: DisinvestInstructionComponent },
                    { path: 'statement', component: StatementInstructionComponent },
                ],
                canActivate: [FeatureGuard],
            },
            { path: 'pst', redirectTo: 'robo', pathMatch: 'full' },
            { path: 'robo', children: roboRoutes },
            { path: 'application', children: applicationRoutes },
            { path: '**', redirectTo: 'investor-home', pathMatch: 'full' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(investorRoutes)],
    exports: [RouterModule],
})
export class InvestorRoutingModule {}
