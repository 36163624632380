import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { InstructionAllocation, InstrumentAllocationBasis, InvestmentAccount, SwitchFund, SwitchInstruction } from 'investor/models';
import { InstructionsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-instruction-switch',
    templateUrl: 'switch-instruction.component.pug',
    styleUrls: ['switch-instruction.component.scss'],
})
export class SwitchInstructionComponent extends BaseInstructionComponent implements OnInit, OnDestroy {
    public instructionForm: FormGroup;

    public errMessage: string;
    public selectedAccountNumber: string;

    public switchOutFunds: SwitchFund[];
    public switchInFunds: InstructionAllocation[];

    public hasHoldings = false;

    public switchOutValue = 0;
    public totalSwitchInValue = 0;
    public totalSwitchInPercentage = 0;

    private user: User;
    private selectedInvestmentAccount: InvestmentAccount;
    private modalTitle = 'Switch';

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private instructionService: InstructionsService,
        private lookupService: LookupDataService,
        private formBuilder: FormBuilder,
        loggerService: LoggerService,
        modalService: NgbModal,
        toastr: ToastrService
    ) {
        super(toastr, loggerService, modalService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.createForm();

        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(user => {
                this.user = user;
            });

        combineLatest(
            this.investorStore.select(fromInvestor.selectInvestmentAccounts),
            this.investorStore.select(fromInvestor.selectSelectedInstructionAccount),
            (accounts, selectedAccount) => {
                return { accounts, selectedAccount };
            }
        )
            .pipe(this.scavenger.collect())
            .subscribe(({ accounts, selectedAccount }) => {
                if (!accounts) {
                    return;
                }

                this.selectedAccountNumber = selectedAccount;
                this.selectedInvestmentAccount = accounts.find(acct => {
                    return acct.accountNumber === this.selectedAccountNumber;
                });
                this.addFundHoldings();
            });

        this.lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(funds => {
                if (funds && funds.length > 0) {
                    this.switchInFunds = [];
                    funds.forEach(val => {
                        const _accountCode = val.pstCode.split(' ')[0];
                        const newFund: InstructionAllocation = {
                            fundName: val.name,
                            fundCode: _accountCode,
                            allocatedPercent: 0,
                            allocatedAmount: 0,
                            allocatedUnits: 0,
                            allocationBasis: InstrumentAllocationBasis.unitsQuantity,
                        };
                        this.switchInFunds.push(newFund);
                    });
                } else {
                    this.switchInFunds = [];
                }
                this.sharedStore.dispatch(new StopLoadingAction());
            });
    }

    public ngOnDestroy(): void {}

    public basis() {
        return this.instructionForm.get('switchOutBasis').value;
    }

    public byUnits() {
        return this.basis() === InstrumentAllocationBasis.unitsQuantity;
    }

    public byValue() {
        return this.basis() === InstrumentAllocationBasis.currencyValue;
    }

    public switchOutPercentageChanged() {
        const percent = this.instructionForm.get('switchOutPercentage').value;
        if (percent) {
            let outFund = this.instructionForm.get('switchOutFund').value as SwitchFund;
            outFund.switchUnits = (outFund.unitsHeld * percent) / 100;
            outFund.switchValue = (outFund.totalValue * percent) / 100;

            this.instructionForm.get('switchOutFund').setValue(outFund);
            this.instructionForm.get('switchOutAmount').setValue(outFund.switchValue, { emitEvent: false });
            this.switchOutValue = outFund.switchValue;
            this.clearSwitchIn();
        }
    }

    public selectedSwitchOutAccountChanged() {
        const selected = this.instructionForm.get('switchOutFund').value;
        if (selected) {
            this.createSwitchInFundsObject(selected.fundCode);
            this.instructionForm.get('switchOutAmount').enable();
            this.instructionForm.get('switchOutPercentage').enable();
        } else {
            this.instructionForm.get('switchOutAmount').disable();
            this.instructionForm.get('switchOutPercentage').disable();
        }
    }

    public switchOutValueChanged() {
        const amount = this.instructionForm.get('switchOutAmount').value;
        if (amount) {
            let outFund = this.instructionForm.get('switchOutFund').value as SwitchFund;
            let percent = (amount / outFund.totalValue) * 100;
            outFund.switchUnits = (outFund.unitsHeld * percent) / 100;

            this.instructionForm.get('switchOutFund').setValue(outFund);
            this.switchOutValue = (outFund.totalValue * percent) / 100;
            this.instructionForm.get('switchOutPercentage').setValue(percent, { emitEvent: false });
            this.clearSwitchIn();
        }
    }

    private round(value: number) {
        return Math.round(value * 100) / 100;
    }

    public switchInPercentageChange(_, fund) {
        let percent = fund.value.switchPercentage;
        let value = this.round((this.switchOutValue * percent) / 100);
        fund.get('switchValue').setValue(value, { emitEvent: false });
        this.calculateTotalSwitchInValues();
    }

    public switchInValueChanged(_, fund) {
        let value = fund.value.switchValue;
        let percent = this.round((value / this.switchOutValue) * 100 || 0);
        fund.get('switchPercentage').setValue(percent, { emitEvent: false });
        this.calculateTotalSwitchInValues();
    }

    public removeCurrentFund(_, fund) {
        const fundControlArray = this.instructionForm.get('switchInFunds') as FormArray;
        const newControls = fundControlArray.controls.filter(fundControl => fundControl.value.fundCode !== fund.value.fundCode);
        fundControlArray.controls = newControls;
        this.calculateTotalSwitchInValues();
    }

    private calculateTotalSwitchInValues() {
        this.totalSwitchInValue = (this.instructionForm.get('switchInFunds') as FormArray).controls.reduce(
            (total, control) => total + control.get('switchValue').value,
            0
        );

        this.totalSwitchInPercentage = (this.instructionForm.get('switchInFunds') as FormArray).controls.reduce(
            (total, control) => total + control.get('switchPercentage').value,
            0
        );
    }

    private clearSwitchIn() {
        for (let control of (this.instructionForm.get('switchInFunds') as FormArray).controls) {
            control.get('switchPercentage').setValue(0);
            control.get('switchValue').setValue(0);
        }
        this.calculateTotalSwitchInValues();
    }

    public canSubmit() {
        return this.instructionForm.valid && this.totalSwitchInPercentage === 100;
    }

    public mayInstruct() {
        return this.hasHoldings;
    }

    public submit() {
        if (this.canSubmit()) {
            const switchInArray = this.instructionForm.get('switchInFunds').value.map((fund: SwitchFund) => {
                return {
                    fundCode: fund.fundCode,
                    fundName: fund.fundName,
                    allocationBasis: InstrumentAllocationBasis.percentageOfFunding,
                    allocatedPercent: fund.switchPercentage,
                    allocatedAmount: fund.switchValue,
                };
            });

            let switchOutFund = this.instructionForm.value.switchOutFund as SwitchFund;
            this.sharedStore.dispatch(new LoadingAction());
            const switchOutFundObject: InstructionAllocation = {
                fundCode: switchOutFund.fundCode,
                fundName: switchOutFund.fundName,
                allocatedPercent: this.instructionForm.get('switchOutPercentage').value,
                allocatedUnits: switchOutFund.switchUnits,
                allocatedAmount: this.instructionForm.get('switchOutAmount').value,
                allocationBasis: this.instructionForm.get('switchOutBasis').value,
            };
            const payLoad: SwitchInstruction = {
                accountNumber: this.selectedAccountNumber,
                caseTypeId: 0,
                ucn: this.user.customerDetails.ucn,
                switchInFunds: switchInArray,
                switchOutFund: switchOutFundObject,
            };
            this.instructionService
                .submitSwitchInstruction(payLoad)
                .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
                .subscribe(result => this.handleResponse(result, this.modalTitle), () => this.handleSubmitError(this.modalTitle));
        } else {
            this.markAsTouched(this.instructionForm);
        }
    }

    public cancel() {
        this.instructionForm.reset();
        this.addFundHoldings();
        this.setDefaults();
    }

    private addFundHoldings() {
        this.switchOutFunds = [];
        this.selectedInvestmentAccount.accountPortfolios
            .filter(acc => this.instructionService.canInstructOnAccountItem(acc))
            .forEach(holding => {
                const fundCode = holding.fundCode.replace(' ZA', '');
                const newFund: SwitchFund = {
                    fundName: holding.fundName,
                    fundCode,
                    percentageOfTotal: holding.percentageOfTotal,
                    unitsHeld: holding.units,
                    switchPercentage: 0,
                    switchValue: 0,
                    switchUnits: 0,
                    totalValue: holding.totalValue,
                };
                this.switchOutFunds.push(newFund);
            });
        this.hasHoldings = this.switchOutFunds.length > 0;
    }

    private setDefaults() {
        this.instructionForm.get('switchOutBasis').setValue(InstrumentAllocationBasis.unitsQuantity);
        this.switchOutValue = 0;

        this.instructionForm.get('switchOutPercentage').setValue(0);
        this.instructionForm.get('switchOutAmount').setValue(0);
        this.instructionForm.get('switchOutAmount').disable();
        this.instructionForm.get('switchOutPercentage').disable();
        this.calculateTotalSwitchInValues();
    }

    private createForm() {
        this.instructionForm = this.formBuilder.group({
            switchOutFund: [null, Validators.required],
            switchOutPercentage: [0, Validators.compose([Validators.required, Validators.min(1), Validators.max(100)])],
            switchOutAmount: [0, Validators.compose([Validators.required, Validators.min(0)])],
            switchInFunds: this.formBuilder.array([]),
            switchOutBasis: [InstrumentAllocationBasis.unitsQuantity, Validators.required],
        });
        this.instructionForm.get('switchOutAmount').disable();
        this.instructionForm.get('switchOutPercentage').disable();
    }

    private createSwitchInFundsObject(fundCode) {
        const items = this.instructionForm.get('switchInFunds') as FormArray;
        items.controls.length = 0;
        this.switchInFunds.forEach(fund => {
            if (fundCode !== fund.fundCode) {
                items.push(
                    this.formBuilder.group({
                        fundCode: fund.fundCode,
                        fundName: fund.fundName,
                        percentageOfTotal: [0, Validators.required],
                        totalValue: [0, Validators.required],
                        switchPercentage: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
                        switchValue: [0, Validators.compose([Validators.required, Validators.min(0)])],
                    })
                );
            }
        });
    }
}
