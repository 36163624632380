import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'wim-number-slider-input',
    templateUrl: 'number-slider-input.component.pug',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => NumberSliderInputComponent),
            multi: true,
        },
    ],
    styleUrls: ['number-slider-input.component.scss'],
})
export class NumberSliderInputComponent implements ControlValueAccessor {
    public isDisabled: boolean;
    public form: FormGroup;

    public sliderValue = 0;

    @Input()
    public sliderMin = 1;

    @Input()
    public sliderMax: number = null;

    @Input()
    public step = 1;

    @Input()
    public prefix: string = null;

    @Input()
    public suffix: string = null;

    @Output()
    public valueChange = new EventEmitter();

    private onChangeFunction;
    private onTouchFunction;

    constructor(formBuilder: FormBuilder) {
        this.form = formBuilder.group({
            value: null,
        });
        this.form.valueChanges.subscribe(changes => {
            if (changes.value === null || changes.value === '') {
                this.sliderValue = 0;
            } else {
                this.sliderValue = +changes.value;
            }

            if (this.onChangeFunction) {
                this.onChangeFunction(changes.value);
            }
            if (this.onTouchFunction) {
                this.onTouchFunction();
            }
            this.valueChange.emit(changes.value);
        });
    }

    public writeValue(value: number): void {
        this.form.get('value').setValue(value);
    }

    public registerOnChange(fn: any): void {
        this.onChangeFunction = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchFunction = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        if (this.isDisabled) {
            this.form.get('value').disable();
        } else {
            this.form.get('value').enable();
        }
    }

    public sliderMove(event) {
        this.form.controls.value.setValue(event.value);
    }
}
