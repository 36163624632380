var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApplication from 'application/store/application.store';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { HideMenuAction } from 'shared/store/layout.reducers';
import * as fromInvestor from 'investor/store/investor.store';
var InvestorDirectSalesComponent = /** @class */ (function (_super) {
    __extends(InvestorDirectSalesComponent, _super);
    function InvestorDirectSalesComponent(investorStore, sharedStore, applicationStore, activatedRoute, router, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.applicationStore = applicationStore;
        _this.activatedRoute = activatedRoute;
        _this.router = router;
        _this.isEligible = false;
        return _this;
    }
    InvestorDirectSalesComponent.prototype.ngOnInit = function () {
        this.sharedStore.dispatch(new LoadingAction());
        this.loadCustomer();
    };
    InvestorDirectSalesComponent.prototype.loadCustomer = function () {
        var _this = this;
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        //const queryParams$ = this.activatedRoute.queryParams;
        // this is not make sure the code doesn't execute twice when the hasPendingAgreements value changes from null
        var hasPendingAgreements$ = this.investorStore
            .select(fromInvestor.selectInvestmentAccountsPending)
            .pipe(filter(function (hasPending) { return hasPending != null; }));
        var hasProcessingApplications$ = this.investorStore
            .select(fromInvestor.selectHasProcessingApplications)
            .pipe(filter(function (hasPending) { return hasPending != null; }));
        var userSub$ = this.sharedStore
            .select(fromShared.selectUser)
            .pipe(filter(function (user) { return !!user; }));
        combineLatest(customer$, hasPendingAgreements$, hasProcessingApplications$, userSub$, function (customer, pendingAgreements, processingApplications, user) {
            return { customer: customer, pendingAgreements: pendingAgreements, processingApplications: processingApplications, user: user };
        }).subscribe(function (_a) {
            var customer = _a.customer, pendingAgreements = _a.pendingAgreements, processingApplications = _a.processingApplications, user = _a.user;
            _this.checkEligibility(customer);
            if (_this.isEligible) {
                if (pendingAgreements || user.hasAgreedTerms || processingApplications) {
                    _this.startAgreements(customer);
                }
                else {
                    _this.startDirect();
                }
            }
            else {
                _this.startLead();
            }
            /*if (params['accountNumber'] === 'NEW') {
                this.checkEligibilityAndRouteCustomer(customer);
            } else {
                this.startMaintenance();
            }*/
        }, function () { return _this.startLead(); });
    };
    InvestorDirectSalesComponent.prototype.checkEligibility = function (customer) {
        if (customer && customer.validCustomer) {
            this.isEligible = true;
        }
        else {
            this.isEligible = false;
        }
    };
    InvestorDirectSalesComponent.prototype.startLead = function () {
        this.sharedStore.dispatch(new HideMenuAction('*'));
        this.router.navigateByUrl('/invest?openLeadForm=true');
        this.sharedStore.dispatch(new StopLoadingAction());
    };
    InvestorDirectSalesComponent.prototype.startMaintenance = function () {
        this.router.navigateByUrl('/secure/investor');
        this.sharedStore.dispatch(new StopLoadingAction());
    };
    InvestorDirectSalesComponent.prototype.startDirect = function () {
        this.router.navigateByUrl('/invest');
    };
    InvestorDirectSalesComponent.prototype.startAgreements = function (customer) {
        this.router.navigateByUrl('/secure/investor/pending-agreements');
    };
    InvestorDirectSalesComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    return InvestorDirectSalesComponent;
}(BaseComponent));
export { InvestorDirectSalesComponent };
