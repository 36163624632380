export enum FeatureFlags {
    BalanceDisplay,
    BankerTelephonicAgreements,
    IKnowWhatIWant,
    MaintenanceInstructions,
    AcceptTsAndCs,
    DownloadDocuments,
    SharesBalanceDisplay,
    EacService,
    MultipleLumpSums,
}
