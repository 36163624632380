import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import appConfig from 'appConfig';
import 'hammerjs';
import 'reflect-metadata';

import { AppModule } from './app/app.module';
import { decorateModuleRef } from './app/environment';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (appConfig.env !== 'Default') {
    enableProdMode();
}

const bootstrap = () =>
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then(decorateModuleRef);

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap().catch(err => console.log(err));
}
