var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import * as fromApplication from 'application/store/application.store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { BaseService } from 'shared/components';
import { DocumentContent } from 'shared/models';
import { Base64Service, LoggerService } from 'shared/services';
import * as fromShared from 'shared/store/shared.store';
import { RoboFieldId } from '../models';
import { RoboAdviceErrorAction, RoboAdviceUpdatedAction, RoboSavedAction, RoboSaveErrorAction, RoboStore } from '../store';
var ON_HOST_ACCOUNTS = 8;
var AdviceEngineService = /** @class */ (function (_super) {
    __extends(AdviceEngineService, _super);
    function AdviceEngineService(loggerService, store, http, ngrxStore, applicationStore, base64Service) {
        var _this = _super.call(this, loggerService) || this;
        _this.store = store;
        _this.http = http;
        _this.applicationStore = applicationStore;
        _this.base64Service = base64Service;
        _this.roboFields = {};
        _this.isDirect = false;
        _this.savedId = null;
        store
            .select(RoboStore.completedFields)
            .pipe(_this.scavenger.collect())
            .subscribe(function (fields) { return (_this.roboFields = fields); });
        store
            .select(RoboStore.additionalLumpsums)
            .pipe(_this.scavenger.collect())
            .subscribe(function (additionalLumpsums) { return (_this.lumpsums = additionalLumpsums); });
        store
            .select(RoboStore.saveAdviceId)
            .pipe(_this.scavenger.collect())
            .subscribe(function (id) { return (_this.savedId = id); });
        ngrxStore.select(fromApplication.selectCustomerHostAccountCodes).subscribe(function (codes) { return (_this.existingOnHostCodes = codes); });
        ngrxStore.select(fromShared.selectIsDirect).subscribe(function (isDirect) { return (_this.isDirect = isDirect); });
        var customer$ = _this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .subscribe(function (selected) { return (_this.selectedCustomer = selected); });
        _this.registerSubscriptions(customer$);
        return _this;
    }
    AdviceEngineService.prototype.getAdvice = function (whenComplete) {
        var _this = this;
        var request = this.buildPayload();
        var url = this.buildUcnSpecificUrl('/robo/advice/investment');
        this.http
            .post(url, request)
            .pipe(catchError(function (error) {
            _this.store.dispatch(new RoboAdviceErrorAction(error));
            return throwError(error);
        }), finalize(function () {
            if (whenComplete) {
                whenComplete();
            }
        }))
            .subscribe(function (advice) {
            _this.store.dispatch(new RoboAdviceUpdatedAction(advice));
        });
    };
    AdviceEngineService.prototype.saveForLater = function (whenComplete) {
        var _this = this;
        var request = this.buildPayload();
        var url = this.buildUcnSpecificSaveForlaterUrl('/robo/advice/save-for-later');
        var params = this.savedId
            ? {
                previousSaveId: this.savedId,
            }
            : {};
        this.http
            .post(url, request, { params: params, responseType: 'text' })
            .pipe(catchError(function (error) {
            _this.store.dispatch(new RoboSaveErrorAction(error));
            return throwError(error);
        }), finalize(function () {
            if (whenComplete) {
                whenComplete();
            }
        }))
            .subscribe(function (id) {
            _this.store.dispatch(new RoboSavedAction(id));
        });
    };
    AdviceEngineService.prototype.download = function (taxFreeOptedOut) {
        var _this = this;
        var request = this.buildPayload();
        var url = this.buildUcnSpecificUrl('/robo/advice/download');
        return this.http
            .post(url, request, { params: { taxFreeOptedOut: "" + taxFreeOptedOut } })
            .pipe(map(function (document) { return Object.assign(document, { dataAsBytes: _this.base64Service.decodeArrayBuffer(document.data) }); }));
    };
    AdviceEngineService.prototype.email = function (uniqueId, emailAddress) {
        var request = {
            emailAddress: emailAddress,
            id: uniqueId,
        };
        var url = appConfig.apiUrl + "/robo/advice/email-advice";
        return this.http.post(url, request);
    };
    AdviceEngineService.prototype.prepareDownload = function (taxFreeOptedOut) {
        var request = this.buildPayload();
        var url = this.buildUcnSpecificUrl('/robo/advice/prepare-download');
        return this.http.post(url, request, { params: { taxFreeOptedOut: "" + taxFreeOptedOut } });
    };
    AdviceEngineService.prototype.buildUcnSpecificUrl = function (url) {
        var newUrl = "" + appConfig.apiUrl + url;
        if (!this.isDirect) {
            newUrl = newUrl + "/" + (this.selectedCustomer && this.selectedCustomer.ucn);
        }
        newUrl = newUrl + "/" + (this.selectedCustomer && this.selectedCustomer.age);
        return newUrl;
    };
    AdviceEngineService.prototype.buildUcnSpecificSaveForlaterUrl = function (url) {
        var newUrl = "" + appConfig.apiUrl + url;
        if (!this.isDirect) {
            newUrl = newUrl + "/" + (this.selectedCustomer && this.selectedCustomer.ucn);
        }
        return newUrl;
    };
    AdviceEngineService.prototype.convertObjectToArrayOfFields = function (fields) {
        var activeFields = [];
        for (var _i = 0, _a = Object.keys(fields); _i < _a.length; _i++) {
            var field = _a[_i];
            if (this.roboFields[field].active) {
                activeFields.push({
                    fieldId: field,
                    value: this.roboFields[field].value,
                    index: 0,
                });
            }
        }
        // The first lumpsum should be entered on the form (in activeFields) then addititonal start at index 1.
        // But if the user misses the first textboxes and inputs only additional, this will catch that
        var additionalLumpsumOffset = activeFields.find(function (field) { return +field.fieldId === RoboFieldId.lumpSumAmount; }) ? 1 : 0;
        for (var i = 0; i < this.lumpsums.length; i++) {
            activeFields.push({
                fieldId: RoboFieldId.lumpSumAmount,
                value: this.lumpsums[i].amount,
                index: i + additionalLumpsumOffset,
            }, {
                fieldId: RoboFieldId.lumpSumDate,
                value: this.lumpsums[i].date,
                index: i + additionalLumpsumOffset,
            });
        }
        return activeFields;
    };
    AdviceEngineService.prototype.getContextualData = function () {
        var contextualData = {};
        contextualData[ON_HOST_ACCOUNTS] = this.existingOnHostCodes;
        return contextualData;
    };
    AdviceEngineService.prototype.buildPayload = function () {
        return {
            fields: this.convertObjectToArrayOfFields(this.roboFields),
            contextualData: this.getContextualData(),
        };
    };
    AdviceEngineService.prototype.ngOnDestroy = function () { };
    return AdviceEngineService;
}(BaseService));
export { AdviceEngineService };
