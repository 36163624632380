var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { InstructionsService } from 'investor/services';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var GeneralInstructionComponent = /** @class */ (function (_super) {
    __extends(GeneralInstructionComponent, _super);
    function GeneralInstructionComponent(investorStore, sharedStore, instructionService, formBuilder, loggerService, modalService, toastr) {
        var _this = _super.call(this, toastr, loggerService, modalService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.instructionService = instructionService;
        _this.formBuilder = formBuilder;
        _this.modalTitle = 'How can we help you?';
        return _this;
    }
    GeneralInstructionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.investorStore
            .select(fromInvestor.selectSelectedInstructionAccount)
            .pipe(this.scavenger.collect())
            .subscribe(function (account) { return (_this.selectedAccount = account); });
        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(function (usr) {
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.user = usr;
        });
        this.createForm();
    };
    GeneralInstructionComponent.prototype.ngOnDestroy = function () { };
    GeneralInstructionComponent.prototype.submit = function () {
        var _this = this;
        if (this.canSubmit()) {
            this.sharedStore.dispatch(new LoadingAction());
            var generalInstructionPayload = {
                accountNumber: this.selectedAccount,
                ucn: this.user.customerDetails.ucn,
                instructionContent: this.instructionForm.value.instructionDetail,
            };
            this.instructionService
                .submitGeneralInstruction(generalInstructionPayload)
                .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
                .subscribe(function (result) { return _this.handleResponse(result, _this.modalTitle); }, function (error) { return _this.handleSubmitError(_this.modalTitle); });
        }
        else {
            this.markAsTouched(this.instructionForm);
        }
    };
    GeneralInstructionComponent.prototype.cancel = function () {
        this.instructionForm.reset();
    };
    GeneralInstructionComponent.prototype.canSubmit = function () {
        return this.instructionForm.valid;
    };
    GeneralInstructionComponent.prototype.createForm = function () {
        this.instructionForm = this.formBuilder.group({
            instructionDetail: ['', Validators.required],
        });
    };
    return GeneralInstructionComponent;
}(BaseInstructionComponent));
export { GeneralInstructionComponent };
