// tslint:disable:max-classes-per-file
import { Action, ActionReducerMap } from '@ngrx/store';
import { ApplicationValidity, CreateCaseResult, CustomerBankAccount, CustomerSearchResult, ProductApplication } from 'application/models';
import { ApplicationState } from 'application/store';

export const CUSTOMER_SELECTED = '[Horizon Application] Customer Selected';
export class CustomerSelectedAction implements Action {
    public readonly type = CUSTOMER_SELECTED;
    constructor(public payload: CustomerSearchResult) {}
}

export const NEW = '[Horizon Application] New';
export class NewHorizonApplicationAction implements Action {
    public readonly type = NEW;
}

export const CLEAR = '[Horizon Application] Clear';
export class ClearApplicationAction implements Action {
    public readonly type = CLEAR;
}

export const NEW_ACCOUNT_LIST = '[Horizon Application] New Bank Account List';
export class BankAccountListLoadedAction implements Action {
    public readonly type = NEW_ACCOUNT_LIST;
    constructor(public payload: CustomerBankAccount[]) {}
}

export const ACCOUNT_SELECTED = '[Horizon Application] Bank account selected';
export class BankAccountSelectedAction implements Action {
    public readonly type = ACCOUNT_SELECTED;
    constructor(public payload: CustomerBankAccount) {}
}

export const CASE_CREATED = '[Horizon Application] Case Created';
export class CaseCreatedAction implements Action {
    public readonly type = CASE_CREATED;
    constructor(public payload: CreateCaseResult) {}
}

export const APP_UPDATE_DEBIT_ORDER = '[Horizon Application][Update] Debit Order Details';
export class AppFormUpdatedDebitOrderDetailsAction implements Action {
    public readonly type = APP_UPDATE_DEBIT_ORDER;
    constructor(public payload: any) {}
}

export const APP_UPDATE_CUSTOMER = '[Horizon Application][Update] Customer';
export class AppFormUpdatedCustomerAction implements Action {
    public readonly type = APP_UPDATE_CUSTOMER;
    constructor(public payload: any) {}
}

export const APP_UPDATE_BANK_ACCOUNT = '[Horizon Application][Update] Bank Account';
export class AppFormUpdatedBankAccountAction implements Action {
    public readonly type = APP_UPDATE_BANK_ACCOUNT;
    constructor(public payload: any) {}
}

export const APP_UPDATE_RECORD_OF_ADVICE = '[Horizon Application][Update] Record of Advice';
export class AppFormUpdatedRecordOfAdviceAction implements Action {
    public readonly type = APP_UPDATE_RECORD_OF_ADVICE;
    constructor(public payload: any) {}
}

export const APP_UPDATE_AGREEMENTS = '[Horizon Application][Update] Agreements';
export class AppFormUpdatedAgreementsAction implements Action {
    public readonly type = APP_UPDATE_AGREEMENTS;
    constructor(public payload: any) {}
}

export const APP_UPDATE_CONSENTS = '[Horizon Application][Update] Consents';
export class AppFormUpdatedConsentsAction implements Action {
    public readonly type = APP_UPDATE_CONSENTS;
    constructor(public payload: any) {}
}

export const APP_UPDATE_PORTFOLIO_TYPE = '[Horizon Application][Update] Portfolio Type';
export class AppFormUpdatedPortfolioTypeAction implements Action {
    public readonly type = APP_UPDATE_PORTFOLIO_TYPE;
    constructor(public payload: number) {}
}

export const APP_REFERENCE_UPDATED = '[Horizon Application] Reference Updated';
export class AppFormReferenceUpdated implements Action {
    public readonly type = APP_REFERENCE_UPDATED;
    constructor(public payload: string) {}
}

export const APP_SUBMITTED_SUCCESSFULLY = '[Horizon Application] Submitted';
export class AppFormSubmitted implements Action {
    public readonly type = APP_SUBMITTED_SUCCESSFULLY;
    constructor(public payload: boolean) {}
}

export const APP_SUBMITTED_ERROR = '[Horizon Application] Submission Error';
export class AppFormSubmissionError implements Action {
    public readonly type = APP_SUBMITTED_ERROR;
    constructor(public payload: Error) {}
}

export const INVESTMENT_DETAILS_VALID = '[Horizon Application] Investment details valid';
export class InvestmentDetailsValidAction implements Action {
    public readonly type = INVESTMENT_DETAILS_VALID;
    constructor(public payload: boolean) {}
}

export const TCS_DETAILS_VALID = '[Horizon Application] Ts&Cs valid';
export class TermsAndConditionsValidAction implements Action {
    public readonly type = TCS_DETAILS_VALID;
    constructor(public payload: boolean) {}
}

export const INSUFFICIENT_FUNDS = '[Horizon Application] Insufficient Funds';
export class InsufficientFundsAction implements Action {
    public readonly type = INSUFFICIENT_FUNDS;
    constructor(public payload: boolean) {}
}

export type ApplicationAction =
    | CustomerSelectedAction
    | NewHorizonApplicationAction
    | ClearApplicationAction
    | BankAccountListLoadedAction
    | BankAccountSelectedAction
    | CaseCreatedAction
    | AppFormUpdatedDebitOrderDetailsAction
    | AppFormUpdatedCustomerAction
    | AppFormUpdatedBankAccountAction
    | AppFormUpdatedRecordOfAdviceAction
    | AppFormUpdatedAgreementsAction
    | AppFormUpdatedConsentsAction
    | AppFormSubmitted
    | AppFormSubmissionError
    | InvestmentDetailsValidAction
    | TermsAndConditionsValidAction
    | AppFormUpdatedPortfolioTypeAction
    | AppFormReferenceUpdated
    | InsufficientFundsAction;

export function customerReducer(state: CustomerSearchResult = null, action: ApplicationAction) {
    switch (action.type) {
        case CUSTOMER_SELECTED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function customerSearchReducer(state: CustomerSearchResult[] = [], action: ApplicationAction) {
    switch (action.type) {
        case CLEAR: {
            return [];
        }
        default: {
            return state;
        }
    }
}

export function applicationReducer(state: ProductApplication = null, action: ApplicationAction) {
    switch (action.type) {
        case CLEAR: {
            return null;
        }
        case NEW: {
            return new ProductApplication();
        }
        case CASE_CREATED: {
            return Object.assign({}, state, { applicationId: action.payload.applicationId });
        }
        case APP_UPDATE_AGREEMENTS: {
            const newState = Object.assign({}, state);
            Object.assign(newState.agreements, action.payload);
            return newState;
        }
        case APP_UPDATE_CONSENTS: {
            const newState = Object.assign({}, state);
            Object.assign(newState.consents, action.payload);
            return newState;
        }        
        case APP_UPDATE_BANK_ACCOUNT: {
            const newState = Object.assign({}, state);
            if (action.payload === null || newState.debitBankAccount === null) {
                newState.debitBankAccount = Object.assign({}, action.payload);
            } else {
                Object.assign(newState.debitBankAccount, action.payload);
            }
            return newState;
        }
        case APP_UPDATE_CUSTOMER: {
            const newState = Object.assign({}, state);
            Object.assign(newState.customerDetails, action.payload);
            return newState;
        }

        case APP_UPDATE_DEBIT_ORDER: {
            const newState = Object.assign({}, state);
            if (action.payload.debitOrderDetails) {
                action.payload.debitOrderDetails.sourceOfFunds = {
                    sourceOfFundsId: action.payload.debitOrderDetails.sourceOfFundsId,
                    otherDescription: action.payload.debitOrderDetails.otherDescription,
                };
                newState.debitOrderDetails = Object.assign(newState.debitOrderDetails || {}, action.payload.debitOrderDetails);
            } else {
                newState.debitOrderDetails = null;
            }
            if (action.payload.lumpSumDetails) {
                for (let lumpsum of action.payload.lumpSumDetails) {
                    lumpsum.sourceOfFunds = {
                        sourceOfFundsId: lumpsum.sourceOfFundsId,
                        otherDescription: lumpsum.otherDescription,
                    };
                    newState.lumpSumDetails = Object.assign(newState.lumpSumDetails || {}, action.payload.lumpSumDetails);
                }
            } else {
                newState.lumpSumDetails = null;
            }

            return newState;
        }
        case APP_UPDATE_RECORD_OF_ADVICE: {
            const newState = Object.assign({}, state);
            Object.assign(state.recordOfAdvice, action.payload);
            return newState;
        }
        case APP_UPDATE_PORTFOLIO_TYPE: {
            const newState = Object.assign({}, state);
            newState.portfolioTypeId = action.payload;
            return newState;
        }
        case INSUFFICIENT_FUNDS: {
            const newBankAccountState = Object.assign({}, state.debitBankAccount);
            newBankAccountState.insufficientFunds = action.payload;
            const newState = Object.assign({}, state);
            newState.debitBankAccount = newBankAccountState;
            return newState;
        }
        default: {
            return state;
        }
    }
}

export function selectedBankAccountReducer(state: CustomerBankAccount, action: ApplicationAction) {
    switch (action.type) {
        case CLEAR: {
            return null;
        }
        case ACCOUNT_SELECTED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function bankAccountsReducer(state: CustomerBankAccount[], action: ApplicationAction) {
    switch (action.type) {
        case CLEAR: {
            return [];
        }
        case NEW_ACCOUNT_LIST: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function applicationSubmittedReducer(state: boolean = false, action: ApplicationAction) {
    switch (action.type) {
        case CLEAR: {
            return null;
        }
        case APP_SUBMITTED_SUCCESSFULLY: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function applicationReferenceReducer(state: string, action: ApplicationAction) {
    switch (action.type) {
        case CLEAR: {
            return null;
        }
        case APP_REFERENCE_UPDATED: {
            return action.payload;
        }
        case APP_SUBMITTED_ERROR: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function applicationSubmissionErrorReducer(state: Error, action: ApplicationAction) {
    switch (action.type) {
        case CLEAR:
        case APP_SUBMITTED_SUCCESSFULLY: {
            return null;
        }
        case APP_SUBMITTED_ERROR: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function applicationStateValidReducer(state: ApplicationValidity = new ApplicationValidity(), action: ApplicationAction) {
    switch (action.type) {
        case NEW: {
            return new ApplicationValidity();
        }
        case INVESTMENT_DETAILS_VALID: {
            return Object.assign({}, state, { investmentDetailsValid: action.payload });
        }
        case TCS_DETAILS_VALID: {
            return Object.assign({}, state, { tcsDetailsValid: action.payload });
        }
        default: {
            return state;
        }
    }
}

export function insufficientFundsReducer(state: ApplicationValidity = new ApplicationValidity(), action: ApplicationAction) {
    switch (action.type) {
        case NEW: {
            return new ApplicationValidity();
        }
        case INVESTMENT_DETAILS_VALID: {
            return Object.assign({}, state, { investmentDetailsValid: action.payload });
        }
        case TCS_DETAILS_VALID: {
            return Object.assign({}, state, { tcsDetailsValid: action.payload });
        }
        default: {
            return state;
        }
    }
}

export const reducers: ActionReducerMap<ApplicationState> = {
    horizonApplication: applicationReducer,

    bankAccounts: bankAccountsReducer,
    selectedBankAccount: selectedBankAccountReducer,
    applicationFormValid: applicationStateValidReducer,

    applicationReference: applicationReferenceReducer,
    applicationSubmitted: applicationSubmittedReducer,
    appSubmissionError: applicationSubmissionErrorReducer,
    selectedCustomer: customerReducer,
};
