var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AccountType, CustomerConsent } from 'application/models';
import { ApplicationService } from 'application/services';
import { CisSearchService } from 'banker/services';
import { InvestmentAccountsPendingAction } from 'investor/store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, throwError } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { BaseComponent, EacModalComponent, SharedModalComponent } from 'shared/components';
import { CheckboxQuestion } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';
import { AgreementCompletedAction, AgreementSubmitFailed, LoadingAction, StopLoadingAction } from 'shared/store';
import { ShowMenuAction } from 'shared/store/layout.reducers';
import * as fromShared from 'shared/store/shared.store';
import { InvestorAcceptanceModalComponent } from '../investor-acceptance-modal/investor-acceptance-modal.component';
var InvestorAgreementsComponent = /** @class */ (function (_super) {
    __extends(InvestorAgreementsComponent, _super);
    function InvestorAgreementsComponent(investorStore, sharedStore, appService, formBuilder, router, modalService, lookupService, toastr, customerService, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.appService = appService;
        _this.formBuilder = formBuilder;
        _this.router = router;
        _this.modalService = modalService;
        _this.lookupService = lookupService;
        _this.toastr = toastr;
        _this.customerService = customerService;
        _this.termsToAccept = false;
        _this.hasProcessingApplications = false;
        _this.agreement = {};
        _this.isInitialLoad = true;
        _this.OK_TO_CANCEL = 'Yes Cancel';
        _this.insufficientFunds = false;
        return _this;
    }
    InvestorAgreementsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        var userSub = this.sharedStore
            .pipe(select(fromShared.selectUser), filter(function (user) { return !!user; }))
            .subscribe(function (usr) {
            _this.user = usr;
        });
        this.agreementQuestions = [];
        this.consentQuestions = [];
        this.termsForm = new FormGroup({});
        var funds$ = this.lookupService.getHorizonFunds();
        var portfolioTypes$ = this.lookupService.getPortfolioTypes();
        var pendingAgreements$ = this.appService.getClientPendingAgreements();
        var combinedSub = combineLatest(funds$, portfolioTypes$, pendingAgreements$)
            .pipe(map(function (results) { return ({ funds: results[0], portfolioTypes: results[1], pendingAgreements: results[2] }); }))
            .subscribe(function (_a) {
            var funds = _a.funds, portfolioTypes = _a.portfolioTypes, pendingAgreements = _a.pendingAgreements;
            _this.funds = funds;
            _this.portfolioTypes = portfolioTypes;
            _this.pendingAgreements = pendingAgreements.agreements;
            _this.processingApplications = pendingAgreements.processingApplications;
            if (_this.processingApplications.length > 0) {
                _this.hasProcessingApplications = true;
                _this.informationMessageTitle = 'Pending Terms and Conditions';
                if (_this.processingApplications.length > 1) {
                    _this.informationMessage = 'We are busy preparing the documents for your new accounts. Kindly try again in a few minutes.';
                }
                else {
                    _this.informationMessage = 'We are busy preparing the documents for your new account. Kindly try again in a few minutes.';
                }
                _this.sharedStore.dispatch(new StopLoadingAction());
            }
            else {
                if (_this.pendingAgreements.length > 0) {
                    _this.termsToAccept = true;
                    _this.activeAgreement = _this.pendingAgreements[0];
                    _this.updateProductDetails();
                    _this.totalAgreements = _this.pendingAgreements.length;
                    if (_this.isInitialLoad) {
                        _this.isInitialLoad = false;
                        _this.currentAgreementIndex = 1;
                    }
                }
                else {
                    _this.informationMessageTitle = 'Pending Terms and Conditions';
                    _this.informationMessage = 'You don\'t currently have any outstanding Terms and Conditions to accept.';
                    _this.sharedStore.dispatch(new StopLoadingAction());
                }
            }
        }, function () { return (_this.hasError = true); });
        this.registerSubscriptions(combinedSub, userSub);
    };
    InvestorAgreementsComponent.prototype.displayContentPopup = function (cancelled, acceptedAgreement, skipped) {
        var modalRef = this.modalService.open(InvestorAcceptanceModalComponent);
        modalRef.componentInstance.agreement = acceptedAgreement;
        modalRef.componentInstance.product = this.portfolioTypes.filter(function (p) { return p.id === acceptedAgreement.portfolioTypeId; })[0].name;
        modalRef.componentInstance.agreementsLeft = this.pendingAgreements.length - 1;
        modalRef.componentInstance.cancelled = cancelled;
        modalRef.componentInstance.skipped = skipped;
    };
    InvestorAgreementsComponent.prototype.showAccountNumber = function () {
        return this.activeAgreement && this.activeAgreement.portfolioTypeId === appConfig.portfolioTypes.INV;
    };
    InvestorAgreementsComponent.prototype.setAdviceFee = function (agreement) {
        if (agreement.isAdvised && agreement.isCash === false) {
            agreement.adviceFee = 0.29;
        }
        else {
            agreement.adviceFee = 0;
        }
        this.adviceFee = agreement.adviceFee;
    };
    InvestorAgreementsComponent.prototype.updateProductDetails = function () {
        var _this = this;
        this.termsForm = new FormGroup({});
        this.agreementQuestions = [];
        this.consentQuestions = [];
        this.agreement = {};
        this.consent = new CustomerConsent();
        this.activeAgreement.debitOrderDetails.accountName = AccountType[this.pendingAgreements[0].debitOrderDetails.accountName];
        if (this.activeAgreement.fundId) {
            this.fundName = this.funds.filter(function (f) { return f.fundId === _this.activeAgreement.fundId; })[0].name;
        }
        else {
            this.fundName = null;
        }
        this.setAdviceFee(this.activeAgreement);
        this.productName = this.portfolioTypes.filter(function (p) { return p.id === _this.activeAgreement.portfolioTypeId; })[0].name;
        this.accountNumber = this.activeAgreement.accountNumber;
        this.bankAccountNumber = this.activeAgreement.debitOrderDetails.accountNumber;
        var lumpSumDetails = this.activeAgreement.debitOrderDetails.lumpSumDetails;
        this.initialLumpSum =
            lumpSumDetails &&
                lumpSumDetails.length > 0 &&
                lumpSumDetails.sort(function (a, b) { return new Date(b.debitDate).getTime() - new Date(a.debitDate).getTime(); })[0].amount;
        this.caseReference = this.activeAgreement.caseReference;
        this.goalName = this.activeAgreement.goalName;
        this.insufficientFunds = this.activeAgreement.insufficientFunds;
        this.loadAgreementContent(this.activeAgreement.portfolioTypeId);
    };
    InvestorAgreementsComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    InvestorAgreementsComponent.prototype.canSubmit = function () {
        var _this = this;
        var canSub = this.agreements &&
            this.termsForm &&
            this.termsForm.value &&
            this.agreementQuestions &&
            this.agreements.filter(function (agreement, index) {
                return _this.agreementQuestions.length > 0 && !_this.agreementQuestions[index].value;
            }).length === 0
            && this.consents
            && this.consentQuestions
            && this.consents.filter(function (consent, index) {
                return _this.consentQuestions.length > 0 && _this.consentQuestions[index].required && !_this.consentQuestions[index].value;
            }).length === 0;
        return canSub;
    };
    InvestorAgreementsComponent.prototype.refreshAccounts = function () {
        var _this = this;
        this.refreshError = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.customerService
            .getLoggedInUserBankAccounts()
            .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }), catchError(function (error) {
            _this.refreshError = true;
            return throwError(error);
        }))
            .subscribe(function (accounts) {
            var matchingAccount = accounts.find(function (account) { return "" + +account.accountNumber === _this.bankAccountNumber; })[0];
            if (matchingAccount) {
                if (matchingAccount.availableBalance >= _this.initialLumpSum) {
                    _this.insufficientFunds = false;
                }
            }
            else {
                _this.refreshError = true;
            }
        });
    };
    InvestorAgreementsComponent.prototype.skip = function () {
        if (this.pendingAgreements.length > 1) {
            this.displayContentPopup(false, this.activeAgreement, true);
            this.loadNextAgreement();
        }
        else {
            this.sharedStore.dispatch(new StopLoadingAction());
            this.user.hasAgreedTerms = true;
            this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
            this.investorStore.dispatch(new AgreementCompletedAction(this.user));
            this.sharedStore.dispatch(new ShowMenuAction('apply'));
            this.conclude(true);
        }
    };
    InvestorAgreementsComponent.prototype.cancelAgreement = function () {
        var _this = this;
        var warningRef = this.modalService.open(SharedModalComponent);
        warningRef.componentInstance.modalMessage = "Are you sure you want to cancel this Application?";
        warningRef.componentInstance.noValue = 'No';
        warningRef.componentInstance.yesValue = 'Yes, Cancel Application';
        warningRef.componentInstance.modalTitle = 'Confirm Application Cancellation';
        warningRef.result
            .then(function (resultType) {
            if (resultType === _this.OK_TO_CANCEL) {
                _this.cancelConfirmed();
            }
            else {
                _this.logger.debug("modal closed");
            }
        }, function () {
            _this.logger.debug("modal dismissed");
        })
            .catch(function () {
            _this.logger.debug('Confirm modal dismissed');
        });
    };
    InvestorAgreementsComponent.prototype.cancelConfirmed = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.appService.cancelCustomerApplication(this.activeAgreement.applicationId).subscribe(function () {
            if (_this.pendingAgreements.length > 1) {
                _this.displayContentPopup(true, _this.activeAgreement, false);
                _this.loadNextAgreement();
            }
            else {
                _this.displayContentPopup(true, _this.activeAgreement, false);
                _this.user.hasAgreedTerms = false;
                _this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
                _this.investorStore.dispatch(new AgreementCompletedAction(_this.user));
                _this.sharedStore.dispatch(new ShowMenuAction('apply'));
                _this.router.navigate(['/secure/investor']);
            }
            _this.sharedStore.dispatch(new StopLoadingAction());
        }, function (error) {
            _this.hasError = true;
            _this.toastr.error('An error occurred while cancelling your application');
            _this.investorStore.dispatch(new AgreementSubmitFailed(error));
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    InvestorAgreementsComponent.prototype.submitAgreement = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.consent.acceptances = this.consentQuestions.filter(function (q) { return q.showTickBox; }).map(function (question) { return ({
            consentType: question.key,
            isAccepted: question.value
        }); });
        this.appService.submitPendingAgreements(this.activeAgreement.applicationId, this.consent).subscribe(function (resp) {
            if (resp.isSuccessful) {
                if (_this.pendingAgreements.length > 1) {
                    _this.displayContentPopup(false, _this.activeAgreement, false);
                    _this.loadNextAgreement();
                }
                else {
                    _this.sharedStore.dispatch(new StopLoadingAction());
                    _this.user.hasAgreedTerms = true;
                    _this.investorStore.dispatch(new InvestmentAccountsPendingAction(false));
                    _this.investorStore.dispatch(new AgreementCompletedAction(_this.user));
                    _this.sharedStore.dispatch(new ShowMenuAction('apply'));
                    _this.conclude();
                }
            }
        }, function (err) {
            _this.hasError = true;
            _this.toastr.error('An error occurred while submitting the form');
            _this.investorStore.dispatch(new AgreementSubmitFailed(err));
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    };
    InvestorAgreementsComponent.prototype.createForm = function () {
        var _this = this;
        var self = this;
        this.agreements.forEach(function (agreement) {
            var checkBoxQuestion = new CheckboxQuestion({
                key: agreement.agreementId.toString(),
                type: 'checkbox',
                label: agreement.name,
                content: agreement.assistedSummary ? agreement.assistedSummary : agreement.summary,
                order: agreement.agreementId,
                id: "cntrlAgreement" + agreement.agreementId,
                value: false,
                controlFormName: "isCheckedAgreement" + agreement.agreementId,
                fileUrl: agreement.moreDetailLink,
                showDebitOrderDetails: agreement.isDebitOrder,
            });
            self.agreementQuestions.push(checkBoxQuestion);
            self.agreement["isCheckedAgreement" + agreement.agreementId] = false;
        });
        this.consents.forEach(function (consent) {
            var checkBoxQuestion = new CheckboxQuestion({
                key: consent.consentId.toString(),
                type: 'checkbox',
                label: consent.name,
                content: consent.summary,
                order: consent.consentId,
                id: "cntrlConsent" + consent.consentId,
                value: false,
                controlFormName: "isCheckedConsent" + consent.consentId,
                showTickBox: consent.showTickBox,
                required: consent.isMandatory,
                tickBoxContent: consent.tickBoxSummary
            });
            _this.consentQuestions.push(checkBoxQuestion);
            self.agreement["isCheckedConsent" + consent.consentId] = false;
        });
        this.termsForm = this.formBuilder.group(this.agreement);
    };
    InvestorAgreementsComponent.prototype.openEacModal = function (agreement) {
        var modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = {
            funds: [{ fundCode: this.funds.filter(function (fund) { return fund.fundId === agreement.fundId; })[0].pstCode, allocation: 1 }],
            notAdvised: !agreement.isAdvised,
            portfolioTypeId: this.activeAgreement.portfolioTypeId
        };
    };
    InvestorAgreementsComponent.prototype.loadNextAgreement = function () {
        this.pendingAgreements.splice(0, 1);
        this.activeAgreement = this.pendingAgreements[0];
        this.currentAgreementIndex = this.totalAgreements - this.pendingAgreements.length + 1;
        this.updateProductDetails();
    };
    InvestorAgreementsComponent.prototype.loadAgreementContent = function (portfolioTypeId) {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        var agreements$ = this.lookupService.getAgreementContent(portfolioTypeId);
        var consents$ = this.lookupService.getConsentContent();
        combineLatest(agreements$, consents$, function (agreements, contents) {
            return { agreements: agreements, contents: contents };
        })
            .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
            .subscribe(function (_a) {
            var agreements = _a.agreements, contents = _a.contents;
            _this.agreements = agreements;
            _this.consents = contents;
            _this.createForm();
        }, function () { return (_this.loadingError = true); });
    };
    InvestorAgreementsComponent.prototype.conclude = function (skipped) {
        var _this = this;
        if (skipped === void 0) { skipped = false; }
        var modalRef = this.modalService.open(SharedModalComponent);
        if (skipped) {
            modalRef.componentInstance.modalMessage = "Please don't forget to transfer money and then return here";
            modalRef.componentInstance.yesValue = 'Ok';
            modalRef.componentInstance.modalTitle = 'Terms And Conditions Skipped';
            modalRef.result.then(function (resultType) {
                if (resultType === _this.OK_TO_CANCEL) {
                    _this.router.navigate(['/secure/investor']);
                }
            });
        }
        else {
            modalRef.componentInstance.modalMessage = "Thank you for activating your " + this.productName + ".";
            modalRef.componentInstance.yesValue = 'Ok';
            modalRef.componentInstance.modalTitle = 'Successful';
            this.termsToAccept = false;
            this.hasProcessingApplications = false;
            this.informationMessageTitle = 'Accepted Terms and Conditions';
            this.informationMessage = "Your new " + this.productName + " has successfully been opened. To open an additional investment solution click on Invest Now";
        }
    };
    InvestorAgreementsComponent.prototype.refresh = function () {
        this.hasProcessingApplications = false;
        this.ngOnInit();
    };
    return InvestorAgreementsComponent;
}(BaseComponent));
export { InvestorAgreementsComponent };
