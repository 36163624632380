import { OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AgreementContent, ConsentContent, ProductApplication } from 'application/models';
import { AppFormUpdatedAgreementsAction, AppFormUpdatedConsentsAction, TermsAndConditionsValidAction } from 'application/store/application.reducers';
import * as fromApplication from 'application/store/application.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EacModalComponent } from 'shared/components';
import { CheckboxQuestion, FeatureFlags } from 'shared/models';
import { FeatureFlagService, LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var OnboardingTermsAndConditionsComponent = /** @class */ (function () {
    function OnboardingTermsAndConditionsComponent(applicationStore, sharedStore, pstStore, formBuilder, lookupService, modalService, featureFlagService) {
        var _this = this;
        this.applicationStore = applicationStore;
        this.sharedStore = sharedStore;
        this.pstStore = pstStore;
        this.formBuilder = formBuilder;
        this.lookupService = lookupService;
        this.modalService = modalService;
        this.featureFlagService = featureFlagService;
        this.willAgreeTerms = true;
        this.isAllAccepted = false;
        this.agreement = {};
        this.agreementQuestions = [];
        this.consentQuestions = [];
        this.questions = [];
        this.isDirect = false;
        this.isError = false;
        this.sharedStore.dispatch(new LoadingAction());
        var selectedFund$ = this.pstStore.select(fromPst.selectSelectedFund);
        var application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        var user$ = this.sharedStore.select(fromShared.selectUser);
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer).pipe(filter(function (customer) { return !!customer; }));
        var isDirect$ = this.applicationStore.select(fromShared.selectIsDirect);
        var consentContent$ = this.lookupService.getConsentContent();
        this.subscription$ = combineLatest(application$, customer$, user$, isDirect$, selectedFund$, consentContent$, function (application, customer, user, isDirect, selectedFund, consentContent) {
            return { application: application, customer: customer, user: user, isDirect: isDirect, selectedFund: selectedFund, consentContent: consentContent };
        }).subscribe(function (_a) {
            var application = _a.application, customer = _a.customer, user = _a.user, isDirect = _a.isDirect, selectedFund = _a.selectedFund, consentContent = _a.consentContent;
            _this.lookupService.getAgreementContent(application.portfolioTypeId).subscribe(function (agreementContent) {
                _this.agreements = agreementContent;
                _this.consents = consentContent;
                _this.telephonicEnabled = _this.featureFlagService.isEnabled(FeatureFlags.BankerTelephonicAgreements);
                _this.sharedStore.dispatch(new StopLoadingAction());
                _this.customerName = customer.title + " " + customer.firstname + " " + customer.lastname;
                _this.appForm = application;
                _this.selectedFund = selectedFund;
                _this.isDirect = isDirect;
                if (!_this.termsForm) {
                    _this.createForm(_this.telephonicEnabled);
                    _this.checkValidity();
                }
                _this.setAdviceFee();
            });
        }, function () {
            _this.isError = true;
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
    }
    OnboardingTermsAndConditionsComponent.prototype.ngOnDestroy = function () {
        this.subscription$.unsubscribe();
    };
    OnboardingTermsAndConditionsComponent.prototype.channelChanged = function () {
        this.appForm.agreements.isAvailableToAccept = this.termsForm.get('isAvailableToAccept').value;
        this.checkValidity();
        this.updateApplicationForm();
    };
    OnboardingTermsAndConditionsComponent.prototype.toggleTermsFormAgreementValue = function (id) {
        this.appForm.agreements["isCheckedAgreement" + id] = !this.appForm.agreements["isCheckedAgreement" + id];
        this.checkValidity();
        this.updateApplicationForm();
    };
    OnboardingTermsAndConditionsComponent.prototype.toggleTermsFormConsentValue = function (id) {
        this.appForm.agreements["isCheckedConsent" + id] = !this.appForm.agreements["isCheckedConsent" + id];
        this.checkValidity();
        this.updateApplicationFormConsent();
    };
    OnboardingTermsAndConditionsComponent.prototype.updateApplicationForm = function () {
        this.applicationStore.dispatch(new AppFormUpdatedAgreementsAction(this.appForm.agreements));
    };
    OnboardingTermsAndConditionsComponent.prototype.updateApplicationFormConsent = function () {
        this.applicationStore.dispatch(new AppFormUpdatedConsentsAction({
            acceptances: this.consentQuestions.filter(function (q) { return q.showTickBox; }).map(function (question) { return ({
                consentType: question.key,
                isAccepted: question.value
            }); })
        }));
    };
    OnboardingTermsAndConditionsComponent.prototype.setAdviceFee = function () {
        if (this.appForm.recordOfAdvice.pstAdvised) {
            this.adviceFee = 0.29;
        }
        else {
            this.adviceFee = 0;
        }
    };
    OnboardingTermsAndConditionsComponent.prototype.checkValidity = function () {
        var _this = this;
        if (this.appForm.agreements.isAvailableToAccept === true) {
            this.formValid =
                (Object.getOwnPropertyNames(this.appForm.agreements).filter(function (item) {
                    return item.startsWith('isCheckedAgreement') && _this.appForm.agreements[item] === false;
                }).length === 0) &&
                    (this.consentQuestions.filter(function (q) { return q.required && q.value === false; }).length === 0);
        }
        else {
            this.formValid = this.appForm.agreements.isAvailableToAccept === false;
        }
        this.applicationStore.dispatch(new TermsAndConditionsValidAction(this.formValid));
    };
    OnboardingTermsAndConditionsComponent.prototype.openEacModal = function () {
        var modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = {
            funds: [{ fundCode: this.selectedFund.pstCode, allocation: 1 }],
            notAdvised: !this.appForm.recordOfAdvice.pstAdvised,
            portfolioTypeId: this.appForm.portfolioTypeId
        };
    };
    OnboardingTermsAndConditionsComponent.prototype.createForm = function (telephonicEnabled) {
        var _this = this;
        this.agreements.forEach(function (agreement) {
            var checkBoxQuestion = new CheckboxQuestion({
                key: agreement.agreementId.toString(),
                type: 'checkbox',
                label: agreement.name,
                content: agreement.summary,
                order: agreement.agreementId,
                id: "cntrlAgreement" + agreement.agreementId,
                value: false,
                controlFormName: "isCheckedAgreement" + agreement.agreementId,
                showDebitOrderDetails: agreement.isDebitOrder,
                fileUrl: agreement.moreDetailLink,
            });
            _this.agreementQuestions.push(checkBoxQuestion);
            _this.appForm.agreements["isCheckedAgreement" + agreement.agreementId] = false;
        });
        this.consents.forEach(function (consent) {
            var checkBoxQuestion = new CheckboxQuestion({
                key: consent.consentId.toString(),
                type: 'checkbox',
                label: consent.name,
                content: consent.summary,
                order: consent.consentId,
                id: "cntrlConsent" + consent.consentId,
                value: false,
                controlFormName: "isCheckedConsent" + consent.consentId,
                showTickBox: consent.showTickBox,
                required: consent.isMandatory,
                tickBoxContent: consent.tickBoxSummary
            });
            _this.consentQuestions.push(checkBoxQuestion);
            _this.appForm.agreements["isCheckedConsent" + consent.consentId] = false;
        });
        this.appForm.agreements['isAvailableToAccept'] = this.isDirect ? true : telephonicEnabled ? null : false;
        this.debitOrderDetails = {
            accountNumber: this.appForm.debitBankAccount ? this.appForm.debitBankAccount.accountNumber : null,
            accountName: this.appForm.debitBankAccount ? this.appForm.debitBankAccount.accountName : null,
            lumpSumDetails: this.appForm.lumpSumDetails ? this.appForm.lumpSumDetails : null,
            debitAmount: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.amount : null,
            debitReDate: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.debitDate : null,
            debitStartDate: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.firstDebitDate : null,
            paymentFrequencyId: this.appForm.debitOrderDetails ? this.appForm.debitOrderDetails.paymentFrequencyId : null,
        };
        this.termsForm = this.formBuilder.group(this.appForm.agreements);
    };
    return OnboardingTermsAndConditionsComponent;
}());
export { OnboardingTermsAndConditionsComponent };
