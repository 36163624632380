import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInAuthGuard } from 'shared/services';

import { RoboAdviceComponent, RoboGoalSelectionComponent } from './components';

export const roboRoutes: Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'landing', component: RoboGoalSelectionComponent, canActivate: [LoggedInAuthGuard] },
    { path: 'advice', component: RoboAdviceComponent, canActivate: [LoggedInAuthGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(roboRoutes)],
    exports: [RouterModule],
})
export class RoboRoutingModule {}
