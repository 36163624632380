import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AccountPortfolios, InvestmentAccount, TradingAccount } from 'investor/models';
import { AccountsService, InstructionsService } from 'investor/services';
import { FnbSecuritiesService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { combineLatest, Observable } from 'rxjs';
import { EacModalComponent } from 'shared/components';
import { BaseComponent } from 'shared/components/base';
import { FeatureFlags } from 'shared/models';
import { FeatureFlagService, LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';

@Component({
    selector: 'wim-investor-home',
    templateUrl: 'investor-home.component.pug',
    styleUrls: ['investor-home.component.scss'],
})
export class InvestorHomeComponent extends BaseComponent implements OnInit, OnDestroy {
    public hasPendingAgreements: boolean;
    public factSheetImage = require('assets/images/icons/download-pdf.svg');

    public tradingAccounts$: Observable<TradingAccount[]>;
    public investmentAccounts$: Observable<InvestmentAccount[]>;
    public hasPendingAgreements$: Observable<boolean>;

    public showInvestmentAccounts = false;
    public showTradingAccounts = false;

    public tradingAccountTotal = 0;
    public investmentAccountTotal = 0;

    public ssoPostTarget: string;
    public submitSsoForm = false;
    public ssoToken: string;

    public ssoForm: FormGroup;
    public ssoError = false;

    public loadingError = false;

    public documentsEnabled = false;
    public instructionsEnabled = false;

    constructor(
        loggerService: LoggerService,
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private router: Router,
        private fnbsService: FnbSecuritiesService,
        private formBuilder: FormBuilder,
        private instructionService: InstructionsService,
        private modalService: NgbModal,
        private accountService: AccountsService,
        private featureFlagService: FeatureFlagService
    ) {
        super(loggerService);
        this.documentsEnabled = this.featureFlagService.isEnabled(FeatureFlags.DownloadDocuments);
        this.instructionsEnabled = this.featureFlagService.isEnabled(FeatureFlags.MaintenanceInstructions);
    }

    public ngOnInit() {
        this.createForm();
        this.sharedStore.dispatch(new LoadingAction());

        this.tradingAccounts$ = this.investorStore.select(fromInvestor.selectTradingAccounts);
        this.investmentAccounts$ = this.investorStore.select(fromInvestor.selectInvestmentAccounts);
        this.hasPendingAgreements$ = this.investorStore.select(fromInvestor.selectInvestmentAccountsPending);

        this.investorStore
            .select(fromInvestor.selectInvestmentAccountLoadingError)
            .pipe(this.scavenger.collect())
            .subscribe(error => {
                this.loadingError = !!error;
            });

        this.hasPendingAgreements$
            .pipe(this.scavenger.collect())
            .subscribe(accountsPending => (this.hasPendingAgreements = accountsPending));

        this.investmentAccounts$.pipe(this.scavenger.collect()).subscribe(accounts => {
            if (accounts && Array.isArray(accounts)) {
                this.investmentAccountTotal = accounts.reduce((total, account) => total + account.accountTotalValue, 0);
            }
        });

        this.tradingAccounts$.pipe(this.scavenger.collect()).subscribe(fnbsAccounts => {
            if (fnbsAccounts && Array.isArray(fnbsAccounts)) {
                this.tradingAccountTotal = fnbsAccounts.reduce((total, account) => total + account.totalValue, 0);
            }
        });

        combineLatest(
            this.tradingAccounts$,
            this.investmentAccounts$,
            this.hasPendingAgreements$,
            (tradingAccounts, investmentAccounts, pendingAgreements) => {
                return { tradingAccounts, investmentAccounts, pendingAgreements };
            }
        )
            .pipe(this.scavenger.collect())
            .subscribe(({ tradingAccounts, investmentAccounts, pendingAgreements }) => {
                if (!tradingAccounts || !investmentAccounts || pendingAgreements === null) {
                    return;
                }
                this.sharedStore.dispatch(new StopLoadingAction());
                const hasTradingAccounts = tradingAccounts && tradingAccounts.length > 0;
                const hasInvestmentAccounts = investmentAccounts && investmentAccounts.length > 0;
                const loadingError = investmentAccounts && !Array.isArray(investmentAccounts);

                if (!hasTradingAccounts && !hasInvestmentAccounts && !pendingAgreements && !loadingError) {
                    this.router.navigateByUrl('/');
                    return;
                }

                if (pendingAgreements) {
                    this.showInvestmentAccounts = false;
                    this.showTradingAccounts = false;
                } else {
                    this.showTradingAccounts = hasTradingAccounts;
                    this.showInvestmentAccounts = hasInvestmentAccounts || loadingError;
                }
            });
        this.accountService.fetchAccounts();
    }

    public ngOnDestroy() {}

    public showFactSheet(fund: AccountPortfolios) {
        return this.instructionService.canInstructOnAccountItem(fund);
    }

    public acceptTerms() {
        this.router.navigate(['/secure/investor/pending-agreements']);
    }

    public openEacModal(account: InvestmentAccount) {
        // We cannot use the total allocation from the server as it takes the cash
        // element into account. This does not affect fees, so we need to caluclate allocation based only on holdings
        let totalHoldings = account.accountPortfolios
            .filter(accObj => this.showFactSheet(accObj))
            .reduce((total, fund) => total + fund.totalValue, 0);

        let accountEAC = account.accountPortfolios
            .filter(accObj => this.showFactSheet(accObj))
            .map(accObj => {
                return {
                    fundCode: accObj.fundCode.replace(/ ZA/g, ''),
                    allocation: accObj.totalValue / totalHoldings,
                };
            });
        const modalRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        modalRef.componentInstance.account = {
            funds: accountEAC,
        };
    }

    public filterOutZeros(accountItems: AccountPortfolios[]) {
        return accountItems.filter(item => item.fundCode !== '-');
    }

    public sso(account: TradingAccount, destination: string) {
        this.ssoError = false;
        this.sharedStore.dispatch(new LoadingAction());
        this.fnbsService.getSsoToken({ accountNumber: account && account.bda, target: destination }).subscribe(
            response => {
                this.ssoForm.setValue({ token: response.token });
                this.ssoPostTarget = response.redirectUrl;
                this.submitSsoForm = true;
            },
            () => {
                this.ssoError = true;
                this.sharedStore.dispatch(new StopLoadingAction());
            }
        );
    }

    private createForm() {
        this.ssoForm = this.formBuilder.group({
            token: [''],
        });
    }
}
