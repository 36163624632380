// tslint:disable:max-classes-per-file

import { Action, ActionReducerMap } from '@ngrx/store';
import { Node, PstAnswer, PstReference, PstResult, SavedPstResult, UserSelection } from 'pst/models';
import { InvestmentAccountCalculator } from 'pst/services/ia-calculator/ia-calculator';
import { PstState } from 'pst/store';
import { Fund } from 'shared/models';

export const NEW_TREE = '[Pst] New';
export class NewTreeAction implements Action {
    public readonly type = NEW_TREE;
    constructor(public payload: PstReference) {}
}

export const TRAVERSE_FWD = '[Pst] Traverse Forward';
export class TraverseForwardAction implements Action {
    public readonly type = TRAVERSE_FWD;
    constructor(public payload: PstReference) {}
}

export const TRAVERSE_BCK = '[Pst] Traverse Backwards';
export class TraverseBackwardsAction implements Action {
    public readonly type = TRAVERSE_BCK;
    constructor(public payload: PstReference) {}
}

export const ERROR = '[Pst] Error';
export class PstErrorAction implements Action {
    public readonly type = ERROR;
    constructor(public payload: Error) {}
}

export const COMPLETE = '[Pst] Completed';
export class PstCompletedAction implements Action {
    public readonly type = COMPLETE;
    constructor(public payload: PstResult) {}
}

export const ADVISED = '[Pst] Advised Values Shown';
export class PstAdviceShownAction implements Action {
    public readonly type = ADVISED;
    constructor(public payload: PstResult) {}
}

export const LOADED = '[Pst] Loaded';
export class PstLoadedAction implements Action {
    public readonly type = LOADED;
    constructor(public payload: SavedPstResult) {}
}

export const ANSWER_CAPTURED = '[Pst] Answer Captured';
export class PstAnswerCapturedAction implements Action {
    public readonly type = ANSWER_CAPTURED;
    constructor(public payload: PstAnswer) {}
}

export const FUND_SELECTED = '[Pst] Fund Selected';
export class FundSelectedAction implements Action {
    public readonly type = FUND_SELECTED;
    constructor(public payload: Fund) {}
}

export const USER_SELECTION_CHANGED_NODE = '[Pst] User selected node';
export class UserSelectedChildNodeAction implements Action {
    public readonly type = USER_SELECTION_CHANGED_NODE;
    constructor(public payload: Node) {}
}

export const USER_SELECTION_CHANGED_VALUE = '[Pst] User changed value';
export class UserEnteredValueAction implements Action {
    public readonly type = USER_SELECTION_CHANGED_VALUE;
    constructor(public payload: string) {}
}

export const USER_SELECTION_CHANGED_VALID = '[Pst] Form Validity Changed';
export class PstScreenValidAction implements Action {
    public readonly type = USER_SELECTION_CHANGED_VALID;
    constructor(public payload: boolean) {}
}

export const RESET = '[Pst] Reset PST State';
export class ResetPstStateAction implements Action {
    public readonly type = RESET;
}

export const CLEAR_TREE_NODE = '[Pst] Clear PST Tree Node';
export class ClearPstTreeNodeAction implements Action {
    public readonly type = CLEAR_TREE_NODE;
}

export const ENTER_PRESSED = '[Pst] Enter Pressed';
export class EnterPressedAction implements Action {
    public readonly type = ENTER_PRESSED;
    constructor(public payload: boolean) {}
}

export type PstAction =
    | NewTreeAction
    | TraverseForwardAction
    | TraverseBackwardsAction
    | PstErrorAction
    | PstCompletedAction
    | PstLoadedAction
    | PstAnswerCapturedAction
    | FundSelectedAction
    | UserSelectedChildNodeAction
    | UserEnteredValueAction
    | PstScreenValidAction
    | ResetPstStateAction
    | PstAdviceShownAction
    | ClearPstTreeNodeAction
    | EnterPressedAction;

export function pstTreeReducer(state: PstReference = null, action: PstAction) {
    switch (action.type) {
        case CLEAR_TREE_NODE:
        case RESET: {
            return null;
        }
        case NEW_TREE: {
            return action.payload;
        }
        case TRAVERSE_FWD: {
            return action.payload;
        }
        case TRAVERSE_BCK: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function pstErrorReducer(state: Error = null, action: PstAction) {
    switch (action.type) {
        case NEW_TREE: {
            return null;
        }
        case ERROR: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function pstCompletedReducer(state: PstResult = null, action: PstAction) {
    switch (action.type) {
        case NEW_TREE: {
            return null;
        }
        case COMPLETE: {
            return action.payload;
        }

        default: {
            return state;
        }
    }
}

export function pstAdvisedReducer(state: PstResult = null, action: PstAction) {
    switch (action.type) {
        case NEW_TREE: {
            return null;
        }
        case ADVISED: {
            return action.payload;
        }

        default: {
            return state;
        }
    }
}

export function pstCalculationReducer(state: PstResult = null, action: PstAction): PstResult {
    switch (action.type) {
        case RESET: {
            return new PstResult();
        }
        case LOADED: {
            const result = new PstResult();
            const save = action.payload;

            result.reference = save.pstReference;
            result.fundCode = save.fundCode;

            InvestmentAccountCalculator.injectCapturedValues(result, save);
            return result;
        }
        case ANSWER_CAPTURED: {
            let result = state;
            if (result == null) {
                result = new PstResult();
            }

            const parentNode = action.payload.parentNode;
            const selectedChild = action.payload.answerNode;
            const value = action.payload.answer;

            const childInstructions = selectedChild.nodeLongDescription.split('|');
            // tslint:disable-next-line:prefer-const
            for (let instruction of childInstructions) {
                if (instruction.startsWith('[[NODE_DESCRIPTION:')) {
                    const variableName = instruction.split(':')[1].replace(']]', '');
                    result.capturedValues[variableName] = selectedChild.nodeDescription;
                } else if (instruction.startsWith('[[INPUT:')) {
                    const variableName = instruction.split(':')[1].replace(']]', '');
                    result.capturedValues[variableName] = value;
                }
            }

            return Object.assign({}, result);
        }
        default: {
            return state;
        }
    }
}

export function selectedFundReducer(state: Fund = null, action: PstAction) {
    switch (action.type) {
        case FUND_SELECTED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function pstNodeSelectionReducer(state: UserSelection = new UserSelection(), action: PstAction) {
    switch (action.type) {
        case NEW_TREE: {
            return new UserSelection();
        }
        case USER_SELECTION_CHANGED_NODE: {
            return Object.assign({}, state, { selectedNode: action.payload });
        }
        case USER_SELECTION_CHANGED_VALUE: {
            return Object.assign({}, state, { value: action.payload });
        }
        case USER_SELECTION_CHANGED_VALID: {
            return Object.assign({}, state, { formInvalid: !action.payload });
        }
        default: {
            return state;
        }
    }
}

export function pstEnterPressedReducer(state: boolean = false, action: PstAction) {
    switch (action.type) {
        case NEW_TREE: {
            return false;
        }
        case ENTER_PRESSED: {
            return true;
        }
        default: {
            return state;
        }
    }
}

export const reducers: ActionReducerMap<PstState> = {
    pstTree: pstTreeReducer,
    inProgressPst: pstCalculationReducer,
    completedPst: pstCompletedReducer,
    advisedPst: pstAdvisedReducer,
    selectedFund: selectedFundReducer,
    pstError: pstErrorReducer,
    userPstSelection: pstNodeSelectionReducer,
    enterPressed: pstEnterPressedReducer,
};
