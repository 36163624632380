import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import appConfig from 'appConfig';
import { AssetClass } from 'application/models';
import {
    AccountPortfolios,
    DisInvestInstruction,
    GeneralInstruction,
    InstructionSaveResponse,
    LumpSumInstruction,
    RecurringInstruction,
    StatementInstruction,
    SwitchInstruction,
} from 'investor/models';
import { Observable } from 'rxjs';

@Injectable()
export class InstructionsService {
    constructor(private http: HttpClient) {}

    public submitGeneralInstruction(instructionObject: GeneralInstruction): Observable<InstructionSaveResponse> {
        return this.http.post<InstructionSaveResponse>(`${appConfig.apiUrl}/instructions/general-instruction`, instructionObject);
    }
    public submitLumpSumInstruction(instructionObject: LumpSumInstruction): Observable<InstructionSaveResponse> {
        return this.http.post<InstructionSaveResponse>(`${appConfig.apiUrl}/instructions/lumpsum-instruction`, instructionObject);
    }
    public submitDisInvestInstruction(instructionObject: DisInvestInstruction): Observable<InstructionSaveResponse> {
        return this.http.post<InstructionSaveResponse>(`${appConfig.apiUrl}/instructions/disinvest-instruction`, instructionObject);
    }
    public submitSwitchInstruction(instructionObject: SwitchInstruction): Observable<InstructionSaveResponse> {
        return this.http.post<InstructionSaveResponse>(`${appConfig.apiUrl}/instructions/switch-instruction`, instructionObject);
    }
    public submitRecurringInstruction(instructionObject: RecurringInstruction): Observable<InstructionSaveResponse> {
        return this.http.post<InstructionSaveResponse>(`${appConfig.apiUrl}/instructions/recurring-instruction`, instructionObject);
    }
    public submitStatementInstruction(instructionObject: StatementInstruction): Observable<InstructionSaveResponse> {
        return this.http.post<InstructionSaveResponse>(`${appConfig.apiUrl}/instructions/statement-instruction`, instructionObject);
    }

    public canInstructOnAccountItem(lineEntry: AccountPortfolios) {
        return lineEntry.assetClass !== AssetClass.Cash;
    }
    public getExistingRecurringInstructions(accountNumber: string): Observable<RecurringInstruction[]> {
        return this.http.get<RecurringInstruction[]>(`${appConfig.apiUrl}/instructions/recurring-instruction`);
    }
    public getExistingLumpSumInstructions(accountNumber: string): Observable<LumpSumInstruction[]> {
        return this.http.get<LumpSumInstruction[]>(`${appConfig.apiUrl}/instructions/lumpsum-instruction`);
    }
}
