export enum PstNodeTypes {
    result = 3,
    cashResult = 100,
    question = 71,
    text = 72,
    multipleChoice = 74,
    number = 78,
    currency = 79,
    term = 101,
    context = 102,
    termYears = 103,
}
