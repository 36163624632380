import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { CustomerSelectedAction } from 'application/store';
import { ApplicationState } from 'application/store/application.store';
import { map, mergeMap } from 'rxjs/operators';
import { User } from 'shared/models';
import { AuthenticationService } from 'shared/services/authentication.service';
import { LookupDataService } from 'shared/services/lookup-data.service';
import { NEW_TOKEN, NO_TOKEN } from 'shared/store/auth-token.reducers';
import { LOGIN_SUCCESS, LoginSuccessAction } from 'shared/store/user.reducers';

@Injectable()
export class UserEffects {
    @Effect()
    public apiLoginAfterTokenIssue$: Observable<Action> = this.actions$.ofType(NEW_TOKEN).pipe(
        mergeMap(() => this.authService.startApiSession()),
        mergeMap(() => this.authService.apiLogin())
    );

    @Effect()
    public apiLoginOnStartup$: Observable<Action> = this.actions$.ofType(NO_TOKEN).pipe(
        mergeMap(() => {
            return this.authService.apiLogin();
        })
    );

    @Effect()
    public apiLoginSuccess$: Observable<Action> = this.actions$.ofType(LOGIN_SUCCESS).pipe(
        map((action: LoginSuccessAction) => action.payload),
        mergeMap(payload => {
            this.applicationStore.dispatch(new CustomerSelectedAction(payload.customerDetails));
            return of(payload);
        }),
        mergeMap(payload => this.fetchAccountDetails(payload))
    );

    constructor(
        private authService: AuthenticationService,
        private actions$: Actions,
        private lookupService: LookupDataService,
        private applicationStore: Store<ApplicationState>
    ) {}

    private fetchAccountDetails(user: User): Observable<Action> {
        if (!user.isBanker) {
            this.lookupService.getSourceOfFundsTypes();
            this.lookupService.getVATRate();
            this.lookupService.getPortfolioTypes();
        }
        return of();
    }
}
