/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pst-content-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pst-content-modal.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_PstContentModalComponent = [i0.styles];
var RenderType_PstContentModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PstContentModalComponent, data: {} });
export { RenderType_PstContentModalComponent as RenderType_PstContentModalComponent };
export function View_PstContentModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FNB Robo Advisor"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 7, 0, currVal_0); }); }
export function View_PstContentModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wim-pst-content-modal", [], null, null, null, View_PstContentModalComponent_0, RenderType_PstContentModalComponent)), i1.ɵdid(1, 49152, null, 0, i2.PstContentModalComponent, [i3.NgbActiveModal], null, null)], null, null); }
var PstContentModalComponentNgFactory = i1.ɵccf("wim-pst-content-modal", i2.PstContentModalComponent, View_PstContentModalComponent_Host_0, {}, {}, []);
export { PstContentModalComponentNgFactory as PstContentModalComponentNgFactory };
