var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'sales/services/contact.service';
import { BaseComponent } from 'shared/components/base';
import { Brand } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var ContactBankerModalComponent = /** @class */ (function (_super) {
    __extends(ContactBankerModalComponent, _super);
    function ContactBankerModalComponent(loggerService, sharedStore, formBuilder, toastr, contactService, activeModal) {
        var _this = _super.call(this, loggerService) || this;
        _this.sharedStore = sharedStore;
        _this.formBuilder = formBuilder;
        _this.toastr = toastr;
        _this.contactService = contactService;
        _this.activeModal = activeModal;
        _this.showContactForm = true;
        _this.noTitleSelected = false;
        _this.noFeedbackTypeSelected = false;
        _this.products = [
            'I am not sure yet',
            'Investment Account',
            'Tax Free Savings Account',
            'Retirement Annuity',
            'Endowment Account',
            'Preservation Account',
            'Living Annuity',
        ];
        _this.funds = [
            'I am not sure yet',
            'A combination',
            'FNB Multi Manager Income Fund (CPI+1%)',
            'FNB Stable Fund (CPI+2%)',
            'FNB Moderate Fund (CPI+3.5%)',
            'FNB Growth Fund (CPI+5%)',
        ];
        _this.regions = [
            'Gauteng',
            'Western Cape',
            'Eastern Cape',
            'KwaZulu Natal',
            'Northern Cape',
            'Free State',
            'Limpopo',
            'Mpumalanga',
            'North West',
        ];
        _this.sharedStore
            .select(fromShared.selectBrand)
            .pipe(_this.scavenger.collect())
            .subscribe(function (brand) { return _this.setBrand(brand); });
        _this.sharedStore
            .select(fromShared.selectUser)
            .pipe(_this.scavenger.collect())
            .subscribe(function (user) { return (_this.customer = user ? user.customerDetails : null); });
        if (!_this.contactForm) {
            if (_this.customer) {
                _this.createCustomerForm(_this.customer);
            }
            else {
                _this.createContactForm();
            }
        }
        return _this;
    }
    ContactBankerModalComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    ContactBankerModalComponent.prototype.submit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        var contactModel = this.contactForm.value;
        contactModel.eligibilityReasons = this.customer.notValidReasons;
        //console.log('TEST' + this.customer.notValidReasons);
        this.contactService.sendBankerContactForm(contactModel).subscribe(function (result) {
            _this.sharedStore.dispatch(new StopLoadingAction());
            if (result.success) {
                _this.resetForm();
                _this.toastr.success(
                // tslint:disable-next-line:max-line-length
                'Thank you for showing interest. An Investment Consultant will contact you within 48 hours (Monday to Friday).');
                _this.close();
            }
            else {
                _this.toastr.error(result.message);
            }
        }, function () { return _this.toastr.error('Unable to submit form, please try again'); });
    };
    ContactBankerModalComponent.prototype.close = function () {
        this.activeModal.close();
    };
    ContactBankerModalComponent.prototype.createContactForm = function () {
        this.contactForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            idNumber: [''],
            region: [null, Validators.required],
            product: [null, Validators.required],
            phoneNumber: ['', Validators.required],
            fund: [null, Validators.required],
            brand: [this.currentInstance],
        });
    };
    ContactBankerModalComponent.prototype.createCustomerForm = function (customer) {
        customer.contactNumber = customer.contactNumber || '';
        this.contactForm = this.formBuilder.group({
            fullName: [customer.firstname + ' ' + customer.lastname, Validators.required],
            email: [customer.emailAddress, [Validators.required, Validators.email]],
            idNumber: [customer.idNumber],
            region: [null, Validators.required],
            product: ['Investment Account', Validators.required],
            phoneNumber: [customer.contactNumber.replace(/ /g, ''), Validators.required],
            fund: ['I am not sure yet', Validators.required],
            brand: [this.currentInstance],
        });
    };
    ContactBankerModalComponent.prototype.resetForm = function () {
        this.contactForm.reset();
    };
    ContactBankerModalComponent.prototype.setBrand = function (activeBrand) {
        switch (activeBrand) {
            case Brand.RMB: {
                this.currentInstance = 'RMB';
                break;
            }
            default: {
                this.currentInstance = 'FNB';
                break;
            }
        }
    };
    return ContactBankerModalComponent;
}(BaseComponent));
export { ContactBankerModalComponent };
