import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { ApplicationRoutingModule } from 'application/application-routing.module';
import {
    HorizonOnboardingCompleteComponent,
    HorizonSeriesOnboardingWizardComponent,
    OnboardingInvestmentDetailsComponent,
    OnboardingTermsAndConditionsComponent,
} from 'application/components';
import { ApplicationDateService, ApplicationService } from 'application/services';
import { reducers } from 'application/store/application.reducers';

import { SharedModule } from '../shared/shared.module';

import { AdvisedOnboardingDetailsComponent, DirectOnboardingDetailsComponent } from './components/onboarding-wizard/investment-details';

const MODULE_COMPONENTS = [
    HorizonOnboardingCompleteComponent,
    OnboardingInvestmentDetailsComponent,
    OnboardingTermsAndConditionsComponent,
    HorizonSeriesOnboardingWizardComponent,
    AdvisedOnboardingDetailsComponent,
    DirectOnboardingDetailsComponent,
];

const MODULE_SERVICES = [ApplicationService, ApplicationDateService];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        ApplicationRoutingModule,
        ReactiveFormsModule,
        NgxErrorsModule,
        PrettyJsonModule,
        SharedModule,
        NgbDatepickerModule,
        StoreModule.forFeature('application', reducers),
    ],
    declarations: [...MODULE_COMPONENTS],
    exports: [],
    providers: [...MODULE_SERVICES],
})
export class ApplicationModule {}
