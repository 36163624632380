import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger, LoggerService, RequestInspectionService } from 'shared/services';
import { SharedState, UserSessionExtendedAction } from 'shared/store';

@Injectable()
export class SessionExtenderInterceptor implements HttpInterceptor {
    private logger: Logger;
    constructor(
        private requestInspectionService: RequestInspectionService,
        private store: Store<SharedState>,
        loggerService: LoggerService
    ) {
        this.logger = loggerService.getLogger('Session Extension Interceptor');
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map(event => {
                if (event instanceof HttpResponse && this.requestInspectionService.isWebAPIRequest(request)) {
                    const t = event as HttpResponse<any>;
                    if (t.status >= 200 && t.status < 300) {
                        if (t.headers.get('x-session-renewed')) {
                            this.store.dispatch(
                                new UserSessionExtendedAction({
                                    timeExtended: new Date(),
                                    url: t.url,
                                    lengthMinutes: +t.headers.get('x-session-renewed'),
                                })
                            );
                        }
                    }
                }
                return event;
            })
        );
    }
}
