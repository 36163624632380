var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstParserService } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { LoggerService, LookupDataService } from 'shared/services';
import { BasePstComponent } from '../base-pst.component';
var MONTHS_IN_YEAR = 12;
var TermYearsComponent = /** @class */ (function (_super) {
    __extends(TermYearsComponent, _super);
    function TermYearsComponent(loggerService, modalService, pstStore, pstParser, formBuilder, lookupService) {
        var _this = _super.call(this, loggerService, modalService) || this;
        _this.pstStore = pstStore;
        _this.pstParser = pstParser;
        _this.formBuilder = formBuilder;
        _this.lookupService = lookupService;
        _this.max = 10000;
        _this.noMaxValue = false;
        _this.overrideMax = null;
        _this.min = 1;
        _this.step = 1;
        _this.sliderValue = 0;
        return _this;
    }
    TermYearsComponent.prototype.sliderMove = function (event) {
        this.inputForm.controls.value.setValue(event.value);
    };
    TermYearsComponent.prototype.ngOnDestroy = function () { };
    TermYearsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var result$ = this.pstStore.select(fromPst.selectInProgressPst);
        var tree$ = this.pstStore.select(fromPst.selectPstTree);
        var tooltips$ = this.lookupService.getPstTooltips();
        combineLatest(result$, tree$, tooltips$, function (result, tree, tooltips) {
            return { result: result, tree: tree, tooltips: tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var result = _a.result, tree = _a.tree, tooltips = _a.tooltips;
            _this.tree = tree;
            if (!_this.tree) {
                return;
            }
            _super.prototype.initContent.call(_this, null, tooltips, tree.node.nodeId);
            var childNode = tree.childNodes[0];
            _this.pstStore.dispatch(new UserSelectedChildNodeAction(childNode));
            _this.heading = _this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
            _this.description = tree.node.nodeDescription;
            _this.sliderText = _this.pstParser.buildInputDescription(childNode.nodeDescription);
            _this.overrideMax = _this.pstParser.extractMaxOverride(childNode.nodeLongDescription);
            var range = _this.pstParser.extractRange(childNode.nodeLongDescription).map(function (r) { return r / MONTHS_IN_YEAR; });
            if (range) {
                if (isNaN(range[1])) {
                    if (_this.overrideMax) {
                        _this.noMaxValue = true;
                        _this.min = range[0] === null ? 1 : range[0];
                    }
                    else {
                        _this.noMaxValue = false;
                    }
                }
                else {
                    _this.min = range[0], _this.max = range[1];
                }
            }
            _this.inputWidth = _this.calculatInputWidth();
            _this.defaultValue = _this.pstParser.extractDefaultValue(tree.node.nodeLongDescription) || '';
            _this.createForm();
            _this.pstStore.dispatch(new PstScreenValidAction(_this.inputForm.valid));
        });
    };
    TermYearsComponent.prototype.calculatInputWidth = function () {
        return _super.prototype.measureTextWidth.call(this, this.noMaxValue ? '1 000 000' : "" + this.max) + "px";
    };
    TermYearsComponent.prototype.createForm = function () {
        var _this = this;
        if (!this.inputForm) {
            var validators = [Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(this.min)];
            if (!this.noMaxValue) {
                validators.push(Validators.max(this.max));
            }
            this.inputForm = this.formBuilder.group({
                value: ['', Validators.compose(validators)],
            });
            this.inputForm.valueChanges.subscribe(function (changes) {
                if (changes.value === null || changes.value === '') {
                    _this.sliderValue = 0;
                }
                else {
                    _this.sliderValue = +changes.value;
                }
                _this.pstStore.dispatch(new UserEnteredValueAction("" + _this.sliderValue * MONTHS_IN_YEAR));
                _this.pstStore.dispatch(new PstScreenValidAction(_this.inputForm.valid));
                var newWidth = _super.prototype.measureTextWidth.call(_this, "" + changes.value);
                if (newWidth > +_this.inputWidth.replace('px', '')) {
                    _this.inputWidth = newWidth + "px";
                }
            });
        }
        if (this.defaultValue) {
            this.inputForm.get('value').setValue(+this.defaultValue / MONTHS_IN_YEAR);
        }
    };
    return TermYearsComponent;
}(BasePstComponent));
export { TermYearsComponent };
