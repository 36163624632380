import { DebitOrderDetails } from 'application/models';
import { LumpSumDetails } from 'application/models/lump-sum-details';

export class PstResult {
    public goalName: string;
    public reference: string;
    public term: number;
    public fundCode: string;
    public capturedValues: { [key: string]: any } = {};

    public goalAmount: number;
    public goalAmountAdjustedForInflation: number;
    public lumpSum: number;
    public advisedLumpSum: number;
    public clientInstallment: number;
    public advisedInstallment: number;

    public contributionIncrease = 0.06;
    public futureValue: number;

    public lumpSumDetails: LumpSumDetails[];
    public debitOrderDetails: DebitOrderDetails;

    public portfolioTypeId: number;
    public advisedPortfolioTypeId: number;

    public noOtherTfsaConfirmed: boolean;
    public productDisclaimerAccepted: boolean;
    public affordabilityConfirmed: boolean;
    public taxFreeOffered: boolean;
    public taxFreeOpened: boolean;

    public accessType: string;
    public noticePeriod: string;

    public projectedInterestRate: number;
    public fundId: number;
    public cashInterestRate: number;

    public pstAdvised: boolean;
}
