var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';
import { InvestorHelperToggleAction, LogoutRequestAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var NavigationComponent = /** @class */ (function (_super) {
    __extends(NavigationComponent, _super);
    function NavigationComponent(store, router, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.store = store;
        _this.router = router;
        _this.showSmallHeader = false;
        _this.showTransparentHeader = false;
        _this.investorHelperToggled = false;
        _this.authenticated = store.select(fromShared.selectAuthenticated);
        _this.store
            .select(fromShared.selectUser)
            .pipe(_this.scavenger.collect())
            .subscribe(function (usr) { return (_this.isBanker = usr ? usr.isBanker : false); });
        _this.store
            .select(fromShared.selectMenus)
            .pipe(_this.scavenger.collect())
            .subscribe(function (menus) { return (_this.menus = menus); });
        _this.store
            .select(fromShared.selectInvestorHelper)
            .pipe(_this.scavenger.collect())
            .subscribe(function (investorHelperState) { return (_this.investorHelperToggled = investorHelperState && investorHelperState.isVisible); });
        var showSmallHeader$ = store.select(fromShared.selectShowSmallHeader);
        var showTransparentHeader$ = store.select(fromShared.selectShowTransparentHeader);
        combineLatest(showSmallHeader$, showTransparentHeader$, function (showSmallHeader, showTransparentHeader) {
            return { showSmallHeader: showSmallHeader, showTransparentHeader: showTransparentHeader };
        })
            .pipe(_this.scavenger.collect())
            .subscribe(function (_a) {
            var showSmallHeader = _a.showSmallHeader, showTransparentHeader = _a.showTransparentHeader;
            _this.showSmallHeader = showSmallHeader;
            _this.showTransparentHeader = !showSmallHeader && showTransparentHeader;
        });
        return _this;
    }
    NavigationComponent.prototype.ngOnDestroy = function () { };
    NavigationComponent.prototype.isOnboardingActive = function () {
        return (this.router.isActive('/secure/banker/pst', false) ||
            this.router.isActive('/secure/banker/robo', false) ||
            this.router.isActive('/secure/banker/customer-search', false) ||
            this.router.isActive('/secure/banker/application', false) ||
            this.router.isActive('/invest', false));
    };
    NavigationComponent.prototype.isDirectPstActive = function () {
        return (this.router.isActive('/secure/investor/pst', false) ||
            this.router.isActive('/secure/investor/robo', false) ||
            this.router.isActive('/secure/investor/application', false) ||
            this.router.isActive('/invest', false));
    };
    NavigationComponent.prototype.logout = function () {
        this.store.dispatch(new LogoutRequestAction());
    };
    NavigationComponent.prototype.investDirect = function () {
        this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
        return;
    };
    NavigationComponent.prototype.isMenuVisible = function (menuName) {
        var toggle = this.menus.filter(function (menu) { return menu.name === menuName; })[0];
        return toggle && toggle.visible;
    };
    NavigationComponent.prototype.toggleCustomerHelp = function () {
        this.store.dispatch(new InvestorHelperToggleAction());
    };
    return NavigationComponent;
}(BaseComponent));
export { NavigationComponent };
