import { AfterViewInit, Directive, ElementRef, Input, Renderer } from '@angular/core';

@Directive({
    selector: '[wimAutoSubmit]',
})
export class AutoSubmitDirective {
    private _autofocus;

    constructor(private element: ElementRef, private renderer: Renderer) {}

    @Input()
    public set readyToSubmit(submit: boolean) {
        if (submit) {
            setTimeout(() => this.element.nativeElement.submit(), 0);
            submit = false;
        }
    }
}
