var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// tslint:disable:max-classes-per-file
import { ROUTER_CANCEL, ROUTER_NAVIGATION } from '@ngrx/router-store';
export var HIDE_MENU = '[Layout] Hide Menu';
var HideMenuAction = /** @class */ (function () {
    function HideMenuAction(payload) {
        this.payload = payload;
        this.type = HIDE_MENU;
    }
    return HideMenuAction;
}());
export { HideMenuAction };
export var HIDE_MENUS = '[Layout] Hide Menus';
var HideMenusAction = /** @class */ (function () {
    function HideMenusAction(payload) {
        this.payload = payload;
        this.type = HIDE_MENUS;
    }
    return HideMenusAction;
}());
export { HideMenusAction };
export var SHOW_MENU = '[Layout] Show Menu';
var ShowMenuAction = /** @class */ (function () {
    function ShowMenuAction(payload) {
        this.payload = payload;
        this.type = SHOW_MENU;
    }
    return ShowMenuAction;
}());
export { ShowMenuAction };
export var SHOW_MENUS = '[Layout] Show Menus';
var ShowMenusAction = /** @class */ (function () {
    function ShowMenusAction(payload) {
        this.payload = payload;
        this.type = SHOW_MENUS;
    }
    return ShowMenusAction;
}());
export { ShowMenusAction };
export var TOGGLE_VISIBLE = '[Investor Helper] Toggle Panel';
var InvestorHelperToggleAction = /** @class */ (function () {
    function InvestorHelperToggleAction() {
        this.type = TOGGLE_VISIBLE;
    }
    return InvestorHelperToggleAction;
}());
export { InvestorHelperToggleAction };
export var TOGGLE_HIDE = '[Investor Helper] Hide Panel';
var InvestorHelperHideAction = /** @class */ (function () {
    function InvestorHelperHideAction() {
        this.type = TOGGLE_HIDE;
    }
    return InvestorHelperHideAction;
}());
export { InvestorHelperHideAction };
export var TOGGLE_HELPER_BUTTON = '[Investor Helper] Toggle Button';
var InvestorHelperToggleButtonAction = /** @class */ (function () {
    function InvestorHelperToggleButtonAction(payload) {
        this.payload = payload;
        this.type = TOGGLE_HELPER_BUTTON;
    }
    return InvestorHelperToggleButtonAction;
}());
export { InvestorHelperToggleButtonAction };
export var NAVIGATION = '[Navigation] Started';
var NavigationAction = /** @class */ (function () {
    function NavigationAction(payload) {
        this.payload = payload;
        this.type = NAVIGATION;
    }
    return NavigationAction;
}());
export { NavigationAction };
export var SHOW_SMALL_HEADER = '[Layout] Show Small Header';
var ShowSmallHeaderAction = /** @class */ (function () {
    function ShowSmallHeaderAction() {
        this.type = SHOW_SMALL_HEADER;
    }
    return ShowSmallHeaderAction;
}());
export { ShowSmallHeaderAction };
export var HIDE_SMALL_HEADER = '[Layout] Hide Small Header';
var HideSmallHeaderAction = /** @class */ (function () {
    function HideSmallHeaderAction() {
        this.type = HIDE_SMALL_HEADER;
    }
    return HideSmallHeaderAction;
}());
export { HideSmallHeaderAction };
export var SHOW_TRANSDPARENT_HEADER = '[Layout] Show Transparent Header';
var ShowTransparentHeaderAction = /** @class */ (function () {
    function ShowTransparentHeaderAction() {
        this.type = SHOW_TRANSDPARENT_HEADER;
    }
    return ShowTransparentHeaderAction;
}());
export { ShowTransparentHeaderAction };
export var HIDE_TRANSDPARENT_HEADER = '[Layout] Hide Transparent Header';
var HideTransparentHeaderAction = /** @class */ (function () {
    function HideTransparentHeaderAction() {
        this.type = HIDE_TRANSDPARENT_HEADER;
    }
    return HideTransparentHeaderAction;
}());
export { HideTransparentHeaderAction };
export var ENABLE_FIXED_FOOTER = '[Layout] Enable Fixed Footer';
var EnableFixedFooterAction = /** @class */ (function () {
    function EnableFixedFooterAction(payload) {
        this.payload = payload;
        this.type = ENABLE_FIXED_FOOTER;
    }
    return EnableFixedFooterAction;
}());
export { EnableFixedFooterAction };
var FULL_WIDTH_ROUTES = ['/invest', '/invest?showFunds=true', '/invest/contact-us', '/invest/about-us', '/invest?openLeadForm=true'];
export function showExpandedFooterReducer(state, action) {
    if (state === void 0) { state = false; }
    switch (action.type) {
        case ROUTER_CANCEL: {
            if (!action.payload || !action.payload.routerState) {
                return state;
            }
            return action.payload.routerState.url.startsWith('/banker-login');
        }
        case ROUTER_NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(function (route) { return route.startsWith(action.payload.routerState.url); }).length > 0;
        }
        case NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(function (route) { return route.startsWith(action.payload); }).length > 0;
        }
        default: {
            return state;
        }
    }
}
export function showFixedFooterReducer(state, action) {
    if (state === void 0) { state = false; }
    switch (action.type) {
        case ENABLE_FIXED_FOOTER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
export function showFullWidthReducer(state, action) {
    if (state === void 0) { state = false; }
    switch (action.type) {
        case ROUTER_CANCEL: {
            if (!action.payload || !action.payload.routerState) {
                return state;
            }
            return action.payload.routerState.url.startsWith('/banker-login');
        }
        case ROUTER_NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(function (route) { return route.startsWith(action.payload.routerState.url); }).length > 0;
        }
        case NAVIGATION: {
            return FULL_WIDTH_ROUTES.filter(function (route) { return route.startsWith(action.payload); }).length > 0;
        }
        default: {
            return state;
        }
    }
}
var MENU_HIDDEN = false;
var MENU_VISIBLE = true;
export function menusReducer(state, action) {
    if (state === void 0) { state = []; }
    switch (action.type) {
        case HIDE_MENU: {
            if (action.payload === '*') {
                return toggleAllMenus(state, MENU_HIDDEN);
            }
            return toggleMenu(state, action.payload, MENU_HIDDEN);
        }
        case HIDE_MENUS: {
            var newState = state.slice();
            for (var _i = 0, _a = action.payload; _i < _a.length; _i++) {
                var menuToToggle = _a[_i];
                newState = toggleMenu(newState, menuToToggle, MENU_HIDDEN);
            }
            return newState;
        }
        case SHOW_MENU: {
            if (action.payload === '*') {
                return toggleAllMenus(state, MENU_VISIBLE);
            }
            return toggleMenu(state, action.payload, MENU_VISIBLE);
        }
        case SHOW_MENUS: {
            var newState = state.slice();
            for (var _b = 0, _c = action.payload; _b < _c.length; _b++) {
                var menuToToggle = _c[_b];
                newState = toggleMenu(newState, menuToToggle, MENU_VISIBLE);
            }
            return newState;
        }
        default: {
            return state;
        }
    }
}
function toggleAllMenus(menus, visible) {
    return menus.map(function (m) {
        return __assign({}, m, { visible: false });
    });
}
function toggleMenu(existingState, menuToToggle, visible) {
    var menuNotYetInState = existingState.filter(function (m) { return m.name === menuToToggle; }).length === 0;
    if (menuNotYetInState) {
        return existingState.concat([{ name: menuToToggle, visible: visible }]);
    }
    return existingState.map(function (menu, index) {
        if (menu.name !== menuToToggle) {
            return menu;
        }
        return __assign({}, menu, { visible: visible });
    });
}
export function toggleInvestorHelperReducer(state, action) {
    if (state === void 0) { state = { isVisible: false, isActionButtonVisible: true }; }
    switch (action.type) {
        case TOGGLE_HIDE: {
            return Object.assign({}, state, { isVisible: false });
        }
        case TOGGLE_VISIBLE: {
            var isHelperVisible = state ? state.isVisible : false;
            return Object.assign({}, state, { isVisible: !isHelperVisible });
        }
        case TOGGLE_HELPER_BUTTON: {
            return Object.assign({}, state, { isActionButtonVisible: action.payload });
        }
        default: {
            return state;
        }
    }
}
export function showSmallHeaderReducer(state, action) {
    if (state === void 0) { state = false; }
    switch (action.type) {
        case SHOW_SMALL_HEADER: {
            return true;
        }
        case HIDE_SMALL_HEADER: {
            return false;
        }
        default: {
            return state;
        }
    }
}
export function showTransparentHeaderReducer(state, action) {
    if (state === void 0) { state = false; }
    switch (action.type) {
        case SHOW_TRANSDPARENT_HEADER: {
            return true;
        }
        case HIDE_TRANSDPARENT_HEADER: {
            return false;
        }
        default: {
            return state;
        }
    }
}
