import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import appConfig from 'appConfig';
import { PendingAgreement } from 'shared/models';

@Component({
    selector: 'wim-investor-acceptance-modal',
    templateUrl: 'investor-acceptance-modal.component.pug',
})
export class InvestorAcceptanceModalComponent {
    public agreement: PendingAgreement;
    public product: string;
    public agreementsLeft: number;
    public cancelled: boolean;
    public skipped: boolean;

    constructor(public activeModal: NgbActiveModal) {}

    public showAccountNumber() {
        return this.agreement && this.agreement.portfolioTypeId === appConfig.portfolioTypes.INV;
    }

    public buttonText() {
        return this.agreementsLeft > 0 ? 'Next' : 'Close';
    }
}
