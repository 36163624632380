import { growthFund } from './growth-fund';
import { incomeFund } from './income-fund';
import { moderateFund } from './moderate-fund';
import { stableFund } from './stable-fund';

export const funds = {
    FNBIF: incomeFund,
    FNBSF: stableFund,
    FNBMF: moderateFund,
    FNGFF: growthFund,
};
