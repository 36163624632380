/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "@ngrx/store";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "footer", [["class", "footer"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(3, { "fixed": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-md-1 technical"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["v", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "col-md-8 copyright"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "col-md-3 technical"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["Technical Ref: ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "footer"; var currVal_1 = _ck(_v, 3, 0, i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform(_co.fixedFooter))); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.version; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.machineId; _ck(_v, 9, 0, currVal_3); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wim-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i3.FooterComponent, [i4.Store], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("wim-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
