import { Component, Injector, OnInit, Type } from '@angular/core';
import { Store } from '@ngrx/store';
import { PstParserService, PstService } from 'pst/services';
import * as fromPst from 'pst/store/pst.store';
import { PstState } from 'pst/store/pst.store';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';

import { IContextLogic } from './context-logic/icontext-logic';
import { UserTypeContextService } from './context-logic/user-type.logic';

const contextMappings: Map<string, Type<IContextLogic>> = new Map();

@Component({
    selector: 'wim-pst-context',
    templateUrl: 'context.component.pug',
})
export class ContextComponent extends BaseComponent implements OnInit {
    constructor(
        loggerService: LoggerService,
        private pstParser: PstParserService,
        private injector: Injector,
        private pstStore: Store<PstState>,
        private pstService: PstService
    ) {
        super(loggerService);
        contextMappings.set('USER_TYPE', UserTypeContextService);
    }

    public ngOnInit() {
        this.pstStore
            .select(fromPst.selectPstTree)
            .pipe(this.scavenger.collect())
            .subscribe(tree => {
                if (!tree) {
                    return;
                }
                let contextType = this.pstParser.extractContextType(tree.node.nodeDescription);
                let resolverType = contextMappings.get(contextType);
                let resolver = this.injector.get<IContextLogic>(resolverType);
                resolver
                    .execute(tree)
                    .pipe(this.scavenger.collect())
                    .subscribe(result => {
                        this.pstService.traverseTreeForwards(tree.transactionref, tree.node, tree.childNodes[0], `${result}`);
                    });
            });
    }

    public ngOnDestroy() {}
}
