import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { ApplicationState } from 'application/store/application.store';
import * as fromApplication from 'application/store/application.store';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { PstResult } from 'pst/models';
import { FundSelectedAction, PstAdviceShownAction, PstCompletedAction, PstState } from 'pst/store';
import { combineLatest } from 'rxjs';
import { ContactBankerModalComponent } from 'sales/components/contact-banker';
import { BaseComponent } from 'shared/components/base';
import { FeatureFlags, Fund } from 'shared/models';
import { CustomerSearchResult } from 'application/models';
import { FeatureFlagService, LoggerService, LookupDataService } from 'shared/services';
import { SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

import { selectHorizonApplication } from '../../../application/store/application.store';

const DEFAULT_ESCALATION = '6';
@Component({
    selector: 'wim-sales',
    templateUrl: 'sales.component.pug',
    styleUrls: ['sales.component.scss'],
})
export class SalesComponent extends BaseComponent implements OnDestroy {
    public funds: Fund[];
    public selectedFund: Fund;
    public authenticated: boolean;
    public isDirect: boolean;
    public isEligible = false;
    public iKnowWhatIWantEnabled = false;

    public selectedCustomer: CustomerSearchResult;

    public showFundsButton = 'I want to Invest';
    public roboButton = 'Help me Invest';
    public investNowButton = 'Apply Now';

    public termsOfUseUrl = '';

    public showGetStartedButton = false;

    public carouselOptions = {
        loop: true,
        center: true,
        margin: 50,
        stagePadding: 153,
        mouseDrag: true,
        dots: true,
        dotsSpeed: 600,
        responsive: {
            0: {
                items: 1,
                margin: 15,
                center: false,
                stagePadding: 15,
                dots: false,
            },
            575: {
                items: 3,
                center: true,
                stagePadding: 350,
            },
            1440: {
                items: 4,
                margin: 67,
                stagePadding: 153,
                dots: true,
            },
        },
    };

    constructor(
        lookupService: LookupDataService,
        scrollService: PageScrollService,
        route: ActivatedRoute,
        @Inject(DOCUMENT) private document: any,
        private modalService: NgbModal,
        loggerService: LoggerService,
        private router: Router,
        private sharedStore: Store<SharedState>,
        private pstStore: Store<PstState>,
        private applicationStore: Store<ApplicationState>,
        private featureFlagService: FeatureFlagService
    ) {
        super(loggerService);
        lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(funds => {
                this.funds = funds;
                this.selectedFund = funds[0];
            });

        lookupService
            .getGeneralDocuments()
            .pipe(this.scavenger.collect())
            .subscribe(docs => {
                this.termsOfUseUrl = docs.find(d => d.name === 'Terms of Use').url;
            });            

        let authenticated$ = this.sharedStore.select(fromShared.selectAuthenticated);
        let isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        let application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        let isEligible$ = this.applicationStore.select(fromShared.selectInvestorEligible);
        let customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);

        combineLatest(authenticated$, isDirect$, application$, isEligible$, customer$, (authenticated, isDirect, appform, isEligible, customer) => {
            return { authenticated, isDirect, appform, isEligible, customer };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ authenticated, isDirect, isEligible, customer }) => {
                this.authenticated = authenticated;
                this.isDirect = isDirect;
                this.isEligible = isEligible;
                this.selectedCustomer = customer;

                if (authenticated) {
                    this.iKnowWhatIWantEnabled = isDirect && this.featureFlagService.isEnabled(FeatureFlags.IKnowWhatIWant);
                } else {
                    this.iKnowWhatIWantEnabled = true;
                }

                if (authenticated) {
                    if (isDirect) {
                        this.showFundsButton = 'I want to Invest';
                        this.roboButton = 'Help me Invest';
                        this.investNowButton = this.iKnowWhatIWantEnabled ? 'Apply Now' : 'Help me Invest';
                    } else {
                        // Banker
                        this.showFundsButton = 'Our Funds';
                        this.roboButton = 'Robo Advisor';
                        this.investNowButton = 'Robo Advisor';
                    }
                }


                if (authenticated) {
                    if (isDirect) {
                        if(isEligible) {
                            this.showGetStartedButton = true;
                        } else {
                            this.showGetStartedButton = false;
                        }
                    } else {
                        // Banker
                        this.showGetStartedButton = true;
                    }
                } else {
                    this.showGetStartedButton = true;
                }

            });

        route.queryParams.pipe(this.scavenger.collect()).subscribe(params => {
            if (params['showFunds'] === 'true') {
                const pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#tabs');
                scrollService.start(pageScrollInstance);
            }

            if (params['openLeadForm'] === 'true') {
                this.contactUs();
            }
        });
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new StopLoadingAction());
    }    

    public contactUs() {
        this.modalService.open(ContactBankerModalComponent, { windowClass: 'large-modal' });
    }

    public customerSelected() {
        return this.selectedCustomer;
    }

    public helpMe() {
        if (this.authenticated) {
            if (this.isDirect) {
                if(this.isEligible) {
                    this.router.navigateByUrl('/secure/investor/robo');
                } else {
                    this.contactUs();
                }                
            } else {
                if(this.customerSelected()) {
                    this.router.navigateByUrl('/secure/banker/robo');
                } else {
                    this.router.navigateByUrl('/secure/banker');
                }                                
            }
        } else {
            this.contactUs();
        }
    }

    public ngOnDestroy(): void {
        this.cleanUpSubscriptions();
    }

    public getIcon(fundIcon) {
        return `assets/icon/funds/${fundIcon}.svg`;
    }

    public select(fundId) {
        this.selectedFund = this.funds.find(fund => fund.fundId === fundId);
    }

    public investInFund(fundId) {
        if (this.authenticated && this.isEligible) {
            let fund = this.funds.filter(f => f.fundId === fundId)[0];
            this.pstStore.dispatch(new FundSelectedAction(fund));
            let pstResult = ({
                fundCode: fund.pstCode,
                pstAdvised: false,
                portfolioTypeId: appConfig.portfolioTypes.INV,
                reference: 'NOT_ADVISED',
                contributionIncrease: DEFAULT_ESCALATION,
            } as unknown) as PstResult;
            this.pstStore.dispatch(new PstAdviceShownAction(pstResult));
            this.pstStore.dispatch(new PstCompletedAction(pstResult));
            this.router.navigateByUrl('/secure/investor/application');
        } else {
            this.contactUs();
        }
    }
}
