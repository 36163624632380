import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'shared/shared.module';

import { AccountsService, DocumentsService, FnbSecuritiesService, InstructionsService } from 'investor/services';

import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { ApplicationModule } from 'application/application.module';
import {
    AccountDocumentsComponent,
    DisinvestInstructionComponent,
    GeneralDocumentsComponent,
    GeneralInstructionComponent,
    InstructionConfirmationModalComponent,
    InvestorAcceptanceModalComponent,
    InvestorAgreementsComponent,
    InvestorDirectSalesComponent,
    InvestorDocumentsComponent,
    InvestorHomeComponent,
    InvestorInstructionsComponent,
    LumpSumInstructionComponent,
    RecurringInstructionComponent,
    StatementInstructionComponent,
    SwitchInstructionComponent,
} from 'investor/components';
import { InvestorRoutingModule } from 'investor/investor-routing.module';
import { AccountEffects, InvestorEffects } from 'investor/store';
import { reducers } from 'investor/store/investor.reducers';

const MODULE_SERVICES = [AccountsService, DocumentsService, FnbSecuritiesService, InstructionsService];

const MODULE_COMPONENTS = [
    AccountDocumentsComponent,
    DisinvestInstructionComponent,
    GeneralDocumentsComponent,
    GeneralInstructionComponent,
    InvestorAgreementsComponent,
    InvestorAcceptanceModalComponent,
    InvestorDirectSalesComponent,
    InvestorDocumentsComponent,
    InvestorHomeComponent,
    InvestorInstructionsComponent,
    LumpSumInstructionComponent,
    RecurringInstructionComponent,
    StatementInstructionComponent,
    SwitchInstructionComponent,
    InstructionConfirmationModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ApplicationModule,
        InvestorRoutingModule,
        ReactiveFormsModule,
        NgxErrorsModule,
        RouterModule,
        StoreModule.forFeature('investor', reducers),
        EffectsModule.forFeature([AccountEffects, InvestorEffects]),
        StoreRouterConnectingModule,
        NgbModule,
    ],
    declarations: [...MODULE_COMPONENTS],
    providers: [...MODULE_SERVICES],
    entryComponents: [InstructionConfirmationModalComponent, InvestorAcceptanceModalComponent],
})
export class InvestorModule {}
