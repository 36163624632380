import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { AgreementContent, ConsentContent, CustomerBankAccount } from 'application/models';
import { Observable } from 'rxjs';
import { publishReplay, refCount, tap } from 'rxjs/operators';
import { Fund, PortfolioType, RoboGoal, PortfolioInstrumentFee } from 'shared/models';
import { CashProduct } from 'shared/models/cash-product';
import { SourceOfFundsType } from 'shared/models/source-of-funds-type';
import { SharedState } from 'shared/store/shared.store';
import { CustomerBankAccountsUpdatedAction } from 'shared/store/user.reducers';
import { GeneralDocument } from 'investor/models';

import { PstIcon, PstResultContent, PstTooltip } from 'pst/models';

@Injectable()
export class LookupDataService {
    private cache: Map<string, Observable<any>> = new Map();

    constructor(private http: HttpClient, private store: Store<SharedState>) {}

    public getSourceOfFundsTypes(): Observable<SourceOfFundsType[]> {
        const request = this.getFromCacheOrHttp<SourceOfFundsType[]>(
            'sourceOfFundsTypes',
            `${appConfig.apiUrl}/lookup-data/source-of-funds`
        );
        return this.primeCache(request);
    }

    public getHorizonFunds(): Observable<Fund[]> {
        const request = this.getFromCacheOrHttp<Fund[]>('funds', `${appConfig.apiUrl}/funds`);
        return this.primeCache(request);
    }

    public getHorizonFundsByPortfolioTypeId(portfolioTypeId: number) : Observable<Fund[]> {
        const request = this.getFromCacheOrHttp<Fund[]>(
            `funds_${portfolioTypeId}`,
            `${appConfig.apiUrl}/funds/by-portfolio-type/${portfolioTypeId}`
        );
        return this.primeCache(request);
    }
    
    public getPortfolioInstrumentFees(): Observable<PortfolioInstrumentFee[]> {
        const request = this.getFromCacheOrHttp<PortfolioInstrumentFee[]>('PortfolioInstrumentFees', `${appConfig.apiUrl}/funds/portfolio-instrument-fees`);
        return this.primeCache(request);
    }    

    public getAgreementContent(portfolioTypeId: number) {
        const request = this.getFromCacheOrHttp<AgreementContent[]>(
            `agreementContents_${portfolioTypeId}`,
            `${appConfig.apiUrl}/onboarding/agreement-content/${portfolioTypeId}`
        );
        return this.primeCache(request);
    }

    public getConsentContent() {
        const request = this.getFromCacheOrHttp<ConsentContent[]>('consentContents', `${appConfig.apiUrl}/onboarding/consent-content`);
        return this.primeCache(request);        
    }
    
    public getGeneralDocuments() {
        const request = this.getFromCacheOrHttp<GeneralDocument[]>('generalDocuments', `${appConfig.apiUrl}/general/general-documents`);
        return this.primeCache(request);        
    }    

    public getCustomerBankAccounts() {
        const request = this.getFromCacheOrHttp<CustomerBankAccount[]>(
            'investorBankAccounts',
            `${appConfig.apiUrl}/users/bank-accounts`
        ).pipe(tap(accounts => this.store.dispatch(new CustomerBankAccountsUpdatedAction(accounts))));
        return this.primeCache(request);
    }

    public getVATRate(): Observable<number> {
        const request = this.getFromCacheOrHttp<number>('vat', `${appConfig.apiUrl}/rates/vat`);
        return this.primeCache(request);
    }

    public getCPIRate(): Observable<number> {
        const request = this.getFromCacheOrHttp<number>('cpi', `${appConfig.apiUrl}/rates/cpi`);
        return this.primeCache(request);
    }    

    public getPortfolioTypes(): Observable<PortfolioType[]> {
        const request = this.getFromCacheOrHttp<PortfolioType[]>('portfolioTypes', `${appConfig.apiUrl}/lookup-data/portfolio-types`);
        return this.primeCache(request);
    }

    public getCashProductDetails(): Observable<CashProduct[]> {
        const request = this.getFromCacheOrHttp<CashProduct[]>('cashProducts', `${appConfig.apiUrl}/lookup-data/cash-product-details`);
        return this.primeCache(request);
    }

    public getCashProductDetailById(portfolioTypeId: number): Observable<CashProduct[]> {
        const request = this.getFromCacheOrHttp<CashProduct[]>(
            `cashProducts_${portfolioTypeId}`,
            `${appConfig.apiUrl}/lookup-data/cash-product-details/${portfolioTypeId}`
        );
        return this.primeCache(request);
    }

    public getPstIcons(): Observable<PstIcon[]> {
        const request = this.getFromCacheOrHttp<PstIcon[]>('pstIcons', `${appConfig.apiUrl}/lookup-data/pst-icons`);
        return this.primeCache(request);
    }

    public getPstTooltips(): Observable<PstTooltip[]> {
        const request = this.getFromCacheOrHttp<PstTooltip[]>('pstTooltips', `${appConfig.apiUrl}/lookup-data/pst-tooltips`);
        return this.primeCache(request);
    }

    public getPstResultContent(): Observable<PstResultContent[]> {
        const request = this.getFromCacheOrHttp<PstResultContent[]>(
            'pstResultContent',
            `${appConfig.apiUrl}/lookup-data/pst-result-content`
        );
        return this.primeCache(request);
    }

    public getRoboGoals(): Observable<RoboGoal[]> {
        const request = this.getFromCacheOrHttp<RoboGoal[]>('roboGoal', `${appConfig.apiUrl}/lookup-data/robo-goals`);
        return this.primeCache(request);
    }

    private getFromCacheOrHttp<T>(key: string, url: string): Observable<T> {
        let cached = this.cache.get(key);
        if (!cached) {
            cached = this.http.get<T>(url).pipe(
                publishReplay(1),
                refCount()
            );
            this.cache.set(key, cached);
        }
        return cached;
    }

    private primeCache<T>(observable: Observable<T>): Observable<T> {
        observable.subscribe();
        return observable;
    }
}
