import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import { CustomerSearchResult } from 'application/models';
import * as fromApplication from 'application/store/application.store';
import { ApplicationState } from 'application/store/application.store';
import { PstResult } from 'pst/models';
import { PstResultContent } from 'pst/models';
import { CashCalculator, PstResultType, PstService } from 'pst/services';
import { CashCalculationResult } from 'pst/services/cash-calculator/models';
import { FundSelectedAction, PstAdviceShownAction, PstCompletedAction, PstScreenValidAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { PstState } from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { Fund, PortfolioType } from 'shared/models';
import { CashProduct } from 'shared/models/cash-product';
import { LoggerService, LookupDataService, NumberFormatterService } from 'shared/services';
import { EnableFixedFooterAction, LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import { bigNumberMaxValidator, bigNumberMinIfNotZeroValidator, bigNumberMinValidator } from 'shared/validators';

import { PstContentModalComponent } from '../content-modal/pst-content-modal.component';

@Component({
    selector: 'wim-pst-cash-result',
    templateUrl: 'cash-result.component.pug',
    styleUrls: ['cash-result.component.scss'],
})
export class CashResultComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    public customerName: string;
    public customerAge: number;
    public calculation: CashCalculationResult;
    public fund: Fund;
    public chart: Chart;
    public calculationError = false;

    public calculationForm: FormGroup;

    public minDebitOrder = 1;
    public maxDebitOrder = 20000000;

    public estimatedFutureValue = 0;

    public minLumpSum = 1;
    public maxLumpSum = 50000000;

    private products: Map<number, PortfolioType> = new Map();
    public product: PortfolioType;

    public selectedCustomer: CustomerSearchResult;
    public cashProduct: CashProduct;

    private originalPstResult: PstResult = null;
    private recalculateTimer = null;

    public formReady = false;

    private resultType: PstResultType;
    private content: PstResultContent[] = [];

    constructor(
        loggerService: LoggerService,
        private pstStore: Store<PstState>,
        private applicationStore: Store<ApplicationState>,
        private sharedStore: Store<SharedState>,
        private pstService: PstService,
        private cashCalculator: CashCalculator,
        private router: Router,
        private lookupService: LookupDataService,
        private formBuilder: FormBuilder,
        private numberFormatter: NumberFormatterService,
        private modalService: NgbModal
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.sharedStore.dispatch(new EnableFixedFooterAction(true));

        const pstResult$ = this.pstStore.select(fromPst.selectInProgressPst);
        const pstTree$ = this.pstStore.select(fromPst.selectPstTree);
        const products$ = this.lookupService.getPortfolioTypes();
        const content$ = this.lookupService.getPstResultContent();
        const allCashProducts$ = this.lookupService.getCashProductDetails();
        const selectedCustomer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);

        const subscription = combineLatest(
            pstResult$,
            pstTree$,
            products$,
            allCashProducts$,
            selectedCustomer$,
            content$,
            (pstResult, pstTree, products, allCashProducts, selectedCustomer, content) => {
                return { pstResult, pstTree, products, allCashProducts, selectedCustomer, content };
            }
        ).subscribe(({ pstResult, pstTree, products, allCashProducts, selectedCustomer, content }) => {
            if (!pstResult) {
                this.sharedStore.dispatch(new StopLoadingAction());
                this.router.navigateByUrl('/secure/banker/customer-search');
                return;
            }
            // Empty pstResult, pst is not yet completed. Happens when restarting PST
            if (Object.keys(pstResult.capturedValues).length === 0) {
                return;
            }

            if (!this.originalPstResult) {
                CashCalculator.extractCapturedValues(pstResult);
                this.originalPstResult = Object.assign({}, pstResult);
                this.pstStore.dispatch(new PstAdviceShownAction(this.originalPstResult));
            }
            if (!this.resultType) {
                if (pstResult.goalAmount === null || pstResult.goalAmount === 0) {
                    this.logger.debug('User selected Just Invest during PST');
                    this.resultType = PstResultType.FutureValue;
                } else {
                    this.logger.debug('User selected a goal during PST');
                    this.resultType = PstResultType.Installment;
                }
            }
            this.content = content;
            pstResult.portfolioTypeId = pstResult.portfolioTypeId || parseInt(pstTree.node.nodeDescription, 10);
            pstResult.reference = pstResult.reference || pstTree.transactionref;

            products.forEach(product => {
                this.products.set(product.id, product);
            });
            this.pstStore.dispatch(new FundSelectedAction(this.fund));

            this.cashProduct = allCashProducts.find(cashProduct => cashProduct.portfolioTypeId === pstResult.portfolioTypeId);
            this.selectedCustomer = selectedCustomer;
            this.recalculate(pstResult);
            this.createForm();
            this.pstStore.dispatch(new PstScreenValidAction(this.calculationForm.valid));
        });

        this.registerSubscriptions(subscription);
    }

    public isJustInvest() {
        return this.calculation.pstResult && this.calculation.pstResult.goalAmount === 0;
    }

    private async recalculate(pstResult: PstResult) {
        if (!this.selectedCustomer) {
            return;
        }

        pstResult = pstResult || this.calculation.pstResult;

        this.sharedStore.dispatch(new LoadingAction());
        this.calculation = new CashCalculationResult();
        this.calculation.pstResult = pstResult;
        await this.cashCalculator
            .calculate(pstResult, this.cashProduct.adviceFee, this.selectedCustomer.age, this.selectedCustomer.ucn)
            .pipe(
                finalize(() => {
                    this.sharedStore.dispatch(new StopLoadingAction());
                    this.formReady = true;
                })
            )

            .subscribe(
                data => {
                    this.calculation = data;
                    this.calculation.pstResult = data.pstResult;
                    this.chart = data.graph;
                    this.cashProduct.interestRate = data.nominalRate;
                    this.calculationError = false;
                    this.pstStore.dispatch(new PstCompletedAction(pstResult));
                },
                error => (this.calculationError = true)
            );
    }

    private recalculateWithDebounce(pstResult: PstResult): Promise<any> {
        return new Promise<any>(resolve => {
            if (this.recalculateTimer) {
                clearTimeout(this.recalculateTimer || this.recalculateTimer._id);
            }
            this.recalculateTimer = setTimeout(() => {
                this.recalculate(pstResult);
                resolve();
            }, 1000);
        });
    }

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
        this.sharedStore.dispatch(new EnableFixedFooterAction(false));
    }

    public restart() {
        this.cleanUpSubscriptions();
        this.pstService.startNewTree();
    }

    public isContent(id: number) {
        return !!this.content.find(c => c.id === id);
    }

    public displayContentPopup(id: number) {
        if (!this.isContent(id)) {
            return;
        }
        let content = this.content.find(c => c.id === id);

        const modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.content;
    }

    private transformBigNumberRand(value, zeroIfEmpty = false) {
        return this.numberFormatter.transformNumberTextPrepend(value, null, 'R ', v =>
            this.numberFormatter.transformBigNumber(v, null, zeroIfEmpty, null)
        );
    }

    private transformTerm(value) {
        return this.numberFormatter.transformTerm(value);
    }

    private transformNoticePeriod(value) {
        return this.numberFormatter.transformNoticePeriod(value);
    }
    private parseBigNumber(bigNumber) {
        return this.numberFormatter.parseNumberTextPrepend(bigNumber, 'R ', this.numberFormatter.parseBigNumber);
    }

    private hasRandValueChanged(originalValue, newValue) {
        let rawNewValue = newValue;
        if (rawNewValue.indexOf('R') >= 0) {
            rawNewValue = this.parseBigNumber(rawNewValue);
        }
        return +rawNewValue !== +originalValue;
    }

    private createForm() {
        this.calculationForm = this.formBuilder.group({
            goalAmount: [
                this.transformBigNumberRand(this.calculation.pstResult.goalAmount),
                Validators.compose([Validators.required, bigNumberMinValidator(0, 'R')]),
            ],
            lumpSum: [
                this.transformBigNumberRand(this.calculation.pstResult.lumpSum, true),
                Validators.compose([bigNumberMinIfNotZeroValidator(this.minLumpSum, 'R'), bigNumberMaxValidator(this.maxLumpSum, 'R')]),
            ],
            clientInstallment: [
                this.transformBigNumberRand(this.calculation.pstResult.clientInstallment, true),
                Validators.compose([
                    bigNumberMinIfNotZeroValidator(this.minDebitOrder, 'R'),
                    bigNumberMaxValidator(this.maxDebitOrder, 'R'),
                ]),
            ],
            lumpSumDisabled: [this.transformBigNumberRand(this.calculation.pstResult.lumpSum)],
            futureValue: [this.transformBigNumberRand(this.calculation.estimatedFutureValue)],
            term: [this.transformTerm(this.calculation.pstResult.term)],
            goalName: [this.calculation.pstResult.goalName],
            // calculatedMonthlyContribution: [this.transformBigNumberRand(this.calculation.calculatedMonthlyContribution)],
            accessType: this.calculation.pstResult.accessType,
            noticePeriod: [this.transformNoticePeriod(this.calculation.pstResult.noticePeriod)],
        });

        let portfolioTypeCode = +this.calculation.pstResult.portfolioTypeId;

        this.product = this.products.get(portfolioTypeCode);

        this.calculationForm.valueChanges.subscribe(async changes => await this.checkForChanges(changes));
    }

    private async checkForChanges(changes) {
        if (this.hasRandValueChanged(this.calculation.pstResult.goalAmount, changes.goalAmount)) {
            this.calculation.pstResult.goalAmount = +this.parseBigNumber(changes.goalAmount);
            await this.recalculateInstallment();
        }
        this.calculation.pstResult.productDisclaimerAccepted = changes.productDisclaimerAccepted;
        this.pstStore.dispatch(new PstScreenValidAction(this.calculationForm.valid));
    }

    private async recalculateInstallment(usePreviousCalculation = false) {
        if (!usePreviousCalculation) {
            await this.recalculateWithDebounce(this.calculation.pstResult);
        }
    }
}
