var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit, Type } from '@angular/core';
import { Store } from '@ngrx/store';
import { PstParserService, PstService } from 'pst/services';
import * as fromPst from 'pst/store/pst.store';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';
import { UserTypeContextService } from './context-logic/user-type.logic';
var contextMappings = new Map();
var ContextComponent = /** @class */ (function (_super) {
    __extends(ContextComponent, _super);
    function ContextComponent(loggerService, pstParser, injector, pstStore, pstService) {
        var _this = _super.call(this, loggerService) || this;
        _this.pstParser = pstParser;
        _this.injector = injector;
        _this.pstStore = pstStore;
        _this.pstService = pstService;
        contextMappings.set('USER_TYPE', UserTypeContextService);
        return _this;
    }
    ContextComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pstStore
            .select(fromPst.selectPstTree)
            .pipe(this.scavenger.collect())
            .subscribe(function (tree) {
            if (!tree) {
                return;
            }
            var contextType = _this.pstParser.extractContextType(tree.node.nodeDescription);
            var resolverType = contextMappings.get(contextType);
            var resolver = _this.injector.get(resolverType);
            resolver
                .execute(tree)
                .pipe(_this.scavenger.collect())
                .subscribe(function (result) {
                _this.pstService.traverseTreeForwards(tree.transactionref, tree.node, tree.childNodes[0], "" + result);
            });
        });
    };
    ContextComponent.prototype.ngOnDestroy = function () { };
    return ContextComponent;
}(BaseComponent));
export { ContextComponent };
