import { ActionReducerMap } from '@ngrx/store';
import { applicationReducer } from 'application/store';
import { accessTokenReducer, initialTokenReducer } from 'shared/store/auth-token.reducers';
import { brandReducer } from 'shared/store/brand.reducers';
import { showLoaderReducer } from 'shared/store/global-loader.reducers';
import { showTransparentHeaderReducer, toggleInvestorHelperReducer } from 'shared/store/layout.reducers';
import {
    menusReducer,
    showExpandedFooterReducer,
    showFixedFooterReducer,
    showFullWidthReducer,
    showSmallHeaderReducer,
} from 'shared/store/layout.reducers';
import { SharedState } from 'shared/store/shared.store';
import {
    apiloginInProgressReducer,
    authenticatedReducer,
    bankerEnabledReducer,
    bankerLoginFailedReducer,
    logoutRedirectUrlReducer,
    logoutRequestedReducer,
    redirectRouteReducer,
    sessionExpiringReducer,
    sessionLifeSupportReducer,
    sessionRenewedReducer,
    userReducer,
} from 'shared/store/user.reducers';
import { loginInProgressReducer } from 'shared/store/user.reducers';

export const reducers: ActionReducerMap<SharedState> = {
    showGlobalLoader: showLoaderReducer,
    brand: brandReducer,
    authenticated: authenticatedReducer,
    user: userReducer,
    sessionRenewal: sessionRenewedReducer,
    logoutRequested: logoutRequestedReducer,

    loginInProgress: loginInProgressReducer,
    apiLoginInProgress: apiloginInProgressReducer,

    accessToken: accessTokenReducer,
    initialToken: initialTokenReducer,

    menus: menusReducer,
    bankerLoginEnabled: bankerEnabledReducer,
    investorHelper: toggleInvestorHelperReducer,

    showFullFooter: showExpandedFooterReducer,
    showFixedFooter: showFixedFooterReducer,
    showFullWidth: showFullWidthReducer,

    bankerLoginError: bankerLoginFailedReducer,
    logoutUrl: logoutRedirectUrlReducer,

    sessionLifeSupport: sessionLifeSupportReducer,
    sessionExpiring: sessionExpiringReducer,

    redirectRoute: redirectRouteReducer,
    showSmallHeader: showSmallHeaderReducer,
    showTransparentHeader: showTransparentHeaderReducer,
};
