var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Store } from '@ngrx/store';
import * as fromPst from 'pst/store/pst.store';
import { BaseComponent } from 'shared/components';
import { LoggerService } from 'shared/services';
var OnboardingInvestmentDetailsComponent = /** @class */ (function (_super) {
    __extends(OnboardingInvestmentDetailsComponent, _super);
    function OnboardingInvestmentDetailsComponent(pstStore, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.pstStore = pstStore;
        _this.insufficientFunds = false;
        _this.pstStore
            .select(fromPst.selectCompletedPst)
            .pipe(_this.scavenger.collect())
            .subscribe(function (flag) { return (_this.isAdvised = flag.pstAdvised); });
        return _this;
    }
    OnboardingInvestmentDetailsComponent.prototype.ngOnDestroy = function () { };
    return OnboardingInvestmentDetailsComponent;
}(BaseComponent));
export { OnboardingInvestmentDetailsComponent };
