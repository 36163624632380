import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { QuestionBase } from '../models/question-base';

@Injectable()
export class FormControlService {
    public toFormGroup(questions: Array<QuestionBase<any>>) {
        const group: any = {};

        questions.forEach(question => {
            group[question.id] = new FormControl(question.value || false);
        });
        return new FormGroup(group);
    }
}
