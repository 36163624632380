/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./context.component";
import * as i2 from "../../../shared/services/logger.service";
import * as i3 from "../../services/pst-parser.service";
import * as i4 from "@ngrx/store";
import * as i5 from "../../services/pst.service";
var styles_ContextComponent = [];
var RenderType_ContextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContextComponent, data: {} });
export { RenderType_ContextComponent as RenderType_ContextComponent };
export function View_ContextComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ContextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-pst-context", [], null, null, null, View_ContextComponent_0, RenderType_ContextComponent)), i0.ɵdid(1, 245760, null, 0, i1.ContextComponent, [i2.LoggerService, i3.PstParserService, i0.Injector, i4.Store, i5.PstService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContextComponentNgFactory = i0.ɵccf("wim-pst-context", i1.ContextComponent, View_ContextComponent_Host_0, {}, {}, []);
export { ContextComponentNgFactory as ContextComponentNgFactory };
