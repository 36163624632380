import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { Observable } from 'rxjs';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-tax-free-disclaimer-modal',
    templateUrl: 'tax-free-disclaimer-modal.component.pug',
})
export class TaxFreeDisclaimerModalComponent {

    public isDirect$: Observable<boolean>;

    constructor(public activeModal: NgbActiveModal, sharedStore: Store<SharedState>) {
        this.isDirect$ = sharedStore.select(fromShared.selectIsDirect);
    }
}
