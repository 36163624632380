import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Customer, CustomerSearchResult } from 'application/models';
import { ApplicationState } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import { combineLatest, Observable } from 'rxjs';
import { finalize, filter } from 'rxjs/operators';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { HideMenuAction } from 'shared/store/layout.reducers';

import { InvestmentAccount, TradingAccount } from '../../models';

import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';

@Component({
    selector: 'wim-investor-direct-sales',
    templateUrl: 'investor-direct-sales.component.pug',
    styleUrls: ['investor-direct-sales.component.scss'],
})
export class InvestorDirectSalesComponent extends BaseComponent implements OnInit, OnDestroy {
    public tradingAccounts$: Observable<TradingAccount[]>;
    public investmentAccounts$: Observable<InvestmentAccount[]>;

    public selectedCustomer: Customer;
    public isEligible = false;

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private applicationStore: Store<ApplicationState>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        loggerService: LoggerService
    ) {
        super(loggerService);
    }

    public ngOnInit() {
        this.sharedStore.dispatch(new LoadingAction());
        this.loadCustomer();
    }

    public loadCustomer() {
        const customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        //const queryParams$ = this.activatedRoute.queryParams;

        // this is not make sure the code doesn't execute twice when the hasPendingAgreements value changes from null
        const hasPendingAgreements$ = this.investorStore
            .select(fromInvestor.selectInvestmentAccountsPending)
            .pipe(filter(hasPending => hasPending != null));

        const hasProcessingApplications$ = this.investorStore
            .select(fromInvestor.selectHasProcessingApplications)
            .pipe(filter(hasPending => hasPending != null));

        const userSub$ = this.sharedStore
            .select(fromShared.selectUser)
            .pipe(filter(user => !!user));            

        combineLatest(customer$, hasPendingAgreements$, hasProcessingApplications$, userSub$, (customer, pendingAgreements, processingApplications, user) => {
            return { customer, pendingAgreements, processingApplications, user };
        }).subscribe(
            ({ customer, pendingAgreements, processingApplications, user }) => {

                this.checkEligibility(customer);
                if(this.isEligible) {
                    if(pendingAgreements || user.hasAgreedTerms || processingApplications) {
                        this.startAgreements(customer);
                    } else {
                        this.startDirect();
                    }                    
                } else {
                    this.startLead();
                }

                /*if (params['accountNumber'] === 'NEW') {
                    this.checkEligibilityAndRouteCustomer(customer);
                } else {
                    this.startMaintenance();
                }*/
            },
            () => this.startLead()
        );
    }

    public checkEligibility(customer: CustomerSearchResult) {
        if (customer && customer.validCustomer) {
            this.isEligible = true;
        } else {
            this.isEligible = false;
        }
    }


    public startLead() {
        this.sharedStore.dispatch(new HideMenuAction('*'));
        this.router.navigateByUrl('/invest?openLeadForm=true');        
        this.sharedStore.dispatch(new StopLoadingAction());
    }

    public startMaintenance() {
        this.router.navigateByUrl('/secure/investor');
        this.sharedStore.dispatch(new StopLoadingAction());
    }

    public startDirect() {
        this.router.navigateByUrl('/invest');
    }

    public startAgreements(customer: CustomerSearchResult) {
        this.router.navigateByUrl('/secure/investor/pending-agreements');
    }    

    public ngOnDestroy() {
        this.cleanUpSubscriptions();
    }
}
