export const growthFund = {
    code: 'FNGFF',
    expectedReturn: 0.1,
    term: 7,
    scenarios: {
        7: { down: 0.0102525513497841, expected: 0.0119414174178839, up: 0.0150722375220264 },
        8: { down: 0.0101478719411338, expected: 0.011600019162097, up: 0.0140157136757312 },
        9: { down: 0.00946388591225748, expected: 0.0104297780387234, up: 0.0127615299230509 },
        10: { down: 0.00949457499933508, expected: 0.00983483590950744, up: 0.0104929007163751 },
    },
};
