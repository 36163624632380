import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'wim-instruction-confirm-modal',
    templateUrl: 'confirmation-modal.component.pug',
    styleUrls: ['confirmation-modal.component.scss'],
})
export class InstructionConfirmationModalComponent {
    public message: string;
    public title: string;
    public success: boolean;

    public retry() {}

    constructor(public activeModal: NgbActiveModal) {}
}
