import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { Observable } from 'rxjs';
import { SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-disclaimer-modal',
    templateUrl: 'disclaimer-modal.component.pug',
})
export class DisclaimerModalComponent {
    public portfolioTypeId: number;

    public isDirect$: Observable<boolean>;

    public isInvestmentAccount(): boolean {
        return this.portfolioTypeId === appConfig.portfolioTypes.INV;
    }

    public isTaxFree(): boolean {
        return this.portfolioTypeId === appConfig.portfolioTypes.TFDS;
    }

    public isCash(): boolean {
        return !this.isInvestmentAccount() && !this.isTaxFree();
    }

    constructor(public activeModal: NgbActiveModal, sharedStore: Store<SharedState>) {
        this.isDirect$ = sharedStore.select(fromShared.selectIsDirect);
    }
}
