var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Action, Selector, State } from '@ngxs/store';
import { produce } from 'immer';
import { RoboState } from '../models';
import { AdviceFormStatusChangedAction, RoboActivateField as RoboActivateFieldAction, RoboAdditionalLumpsumsUpdated as RoboAdditionalLumpsumsUpdatedAction, RoboAdviceErrorAction, RoboAdviceUpdatedAction, RoboClearScreenAction, RoboContinuedAction, RoboDeactivateField as RoboDeactivateFieldAction, RoboSavedAction, RoboSaveErrorAction, RoboUpdateFieldAction, RoboUpdateMultipleFieldsAction, TaxFreeOpenedAction, TaxFreeOptOutAction, UpdateRoboValidationWarningsAction, } from './robo.actions';
var RoboStore = /** @class */ (function () {
    function RoboStore() {
    }
    RoboStore.completedField = function (state) {
        return function (type) {
            return state.fields[type];
        };
    };
    RoboStore.completedFields = function (state) {
        return state.fields;
    };
    RoboStore.additionalLumpsums = function (state) {
        return state.additionalLumpsums;
    };
    RoboStore.requiredFields = function (state) {
        return state.requiredFields;
    };
    RoboStore.currentAdvice = function (state) {
        return state.advice;
    };
    RoboStore.adviceError = function (state) {
        return state.adviceError;
    };
    RoboStore.saveError = function (state) {
        return state.saveError;
    };
    RoboStore.saveAdviceId = function (state) {
        return state.savedAdviceId;
    };
    RoboStore.validationErrors = function (state) {
        return state.validationErrors;
    };
    RoboStore.adviceEngineValidationWarnings = function (state) {
        return state.adviceEngineValidationWarnings;
    };
    RoboStore.roboValidationWarnings = function (state) {
        return state.roboValidationWarnings;
    };
    RoboStore.taxFreeOpened = function (state) {
        return state.taxFreeOpened;
    };
    RoboStore.taxFreeOptOut = function (state) {
        return state.taxFreeOptOut;
    };
    RoboStore.adviceFormValid = function (state) {
        return state.adviceFormValid;
    };
    RoboStore.downstreamAdviceError = function (state) {
        return state.downstreamAdviceError;
    };
    RoboStore.prototype.updateField = function (ctx, action) {
        var _this = this;
        var newState = produce(ctx.getState(), function (draftState) {
            _this.updateFieldInState(draftState, action.payload);
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.updateMultipleFields = function (ctx, action) {
        var _this = this;
        var newState = produce(ctx.getState(), function (draftState) {
            action.payload.forEach(function (fieldUpdate) { return _this.updateFieldInState(draftState, fieldUpdate); });
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.updateAdvice = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.requiredFields = action.payload.requiredFields;
            draftState.advice = action.payload.financialAdvice;
            draftState.validationErrors = action.payload.errors;
            draftState.adviceEngineValidationWarnings = action.payload.warnings;
            draftState.adviceError = null;
            draftState.downstreamAdviceError = action.payload.downstreamErrors;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.activateField = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.fields[action.payload].active = true;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.deactivateField = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.fields[action.payload].active = false;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.errorWhenGettingAdvice = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.adviceError = action.payload;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.clearInputs = function (ctx) {
        var newState = produce(ctx.getState(), function (draftState) {
            RoboState.clear(draftState);
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.additionalLumpsumsUpdates = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.additionalLumpsums = action.payload;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.errorWhenSaving = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.saveError = action.payload;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.successfulSave = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.savedAdviceId = action.payload;
            draftState.adviceError = null;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.successfulResume = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.savedAdviceId = action.payload;
            draftState.adviceError = null;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.openTaxFreeAccount = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.taxFreeOpened = action.payload;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.declineTaxFreeAccount = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.taxFreeOptOut = action.payload;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.formValidStatusChanged = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.adviceFormValid = action.payload === 'VALID';
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.updatedWarnings = function (ctx, action) {
        var newState = produce(ctx.getState(), function (draftState) {
            draftState.roboValidationWarnings = action.payload;
        });
        ctx.setState(newState);
    };
    RoboStore.prototype.updateFieldInState = function (draftState, fieldChange) {
        if (!draftState.fields) {
            draftState.fields = {};
        }
        if (!draftState.fields[fieldChange.fieldId]) {
            draftState.fields[fieldChange.fieldId] = { value: fieldChange.value, active: true };
        }
        else {
            draftState.fields[fieldChange.fieldId].value = fieldChange.value;
        }
    };
    __decorate([
        Action(RoboUpdateFieldAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboUpdateFieldAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "updateField", null);
    __decorate([
        Action(RoboUpdateMultipleFieldsAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboUpdateMultipleFieldsAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "updateMultipleFields", null);
    __decorate([
        Action(RoboAdviceUpdatedAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboAdviceUpdatedAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "updateAdvice", null);
    __decorate([
        Action(RoboActivateFieldAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboActivateFieldAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "activateField", null);
    __decorate([
        Action(RoboDeactivateFieldAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboActivateFieldAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "deactivateField", null);
    __decorate([
        Action(RoboAdviceErrorAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboAdviceErrorAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "errorWhenGettingAdvice", null);
    __decorate([
        Action(RoboClearScreenAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "clearInputs", null);
    __decorate([
        Action(RoboAdditionalLumpsumsUpdatedAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboAdditionalLumpsumsUpdatedAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "additionalLumpsumsUpdates", null);
    __decorate([
        Action(RoboSaveErrorAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboSaveErrorAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "errorWhenSaving", null);
    __decorate([
        Action(RoboSavedAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboSavedAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "successfulSave", null);
    __decorate([
        Action(RoboContinuedAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, RoboContinuedAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "successfulResume", null);
    __decorate([
        Action(TaxFreeOpenedAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, TaxFreeOpenedAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "openTaxFreeAccount", null);
    __decorate([
        Action(TaxFreeOptOutAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, TaxFreeOptOutAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "declineTaxFreeAccount", null);
    __decorate([
        Action(AdviceFormStatusChangedAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, AdviceFormStatusChangedAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "formValidStatusChanged", null);
    __decorate([
        Action(UpdateRoboValidationWarningsAction),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, UpdateRoboValidationWarningsAction]),
        __metadata("design:returntype", void 0)
    ], RoboStore.prototype, "updatedWarnings", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "completedField", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "completedFields", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "additionalLumpsums", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "requiredFields", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "currentAdvice", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "adviceError", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "saveError", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "saveAdviceId", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "validationErrors", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "adviceEngineValidationWarnings", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "roboValidationWarnings", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "taxFreeOpened", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "taxFreeOptOut", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "adviceFormValid", null);
    __decorate([
        Selector(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [RoboState]),
        __metadata("design:returntype", void 0)
    ], RoboStore, "downstreamAdviceError", null);
    RoboStore = __decorate([
        State({
            defaults: new RoboState(),
            name: 'robo',
        })
    ], RoboStore);
    return RoboStore;
}());
export { RoboStore };
