// tslint:disable:max-classes-per-file
import { OnDestroy } from '@angular/core';
import { Scavenger } from '@wishtack/rx-scavenger';
import { Subscription } from 'rxjs';

import { Logger, LoggerService } from '../../services';

export class BaseLoggingClass {
    protected logger: Logger;

    constructor(loggerService: LoggerService) {
        this.logger = loggerService.getLogger(this.constructor['name']);
    }
}

export abstract class BaseSubscriptionClass extends BaseLoggingClass implements OnDestroy {
    protected scavenger: Scavenger;
    private subscriptions: Subscription[] = [];
    constructor(loggerService: LoggerService) {
        super(loggerService);
        this.scavenger = new Scavenger(this);
    }

    public abstract ngOnDestroy();

    protected registerSubscriptions(...sub: Subscription[]) {
        this.subscriptions.push(...sub);
    }

    protected cleanUpSubscriptions() {
        this.logger.debug(`Cleaning up ${this.subscriptions.length} Observable subscriptions`);
        if (this.subscriptions && this.subscriptions.length > 0) {
            // tslint:disable-next-line:prefer-const
            for (let sub of this.subscriptions) {
                if (sub) {
                    sub.unsubscribe();
                }
            }
        }
    }
}

export abstract class BaseComponent extends BaseSubscriptionClass {
    constructor(loggerService: LoggerService) {
        super(loggerService);
    }
}

export abstract class BaseService extends BaseSubscriptionClass {
    constructor(loggerService: LoggerService) {
        super(loggerService);
    }
}
