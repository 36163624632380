var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { InstrumentAllocationBasis } from 'investor/models';
import { InstructionsService } from 'investor/services';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { maxIfValidator, minIfValidator, requiredIfValidator } from 'shared/validators';
var DisinvestInstructionComponent = /** @class */ (function (_super) {
    __extends(DisinvestInstructionComponent, _super);
    function DisinvestInstructionComponent(investorStore, sharedStore, instructionService, formBuilder, modalService, toastr, loggerService) {
        var _this = _super.call(this, toastr, loggerService, modalService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.instructionService = instructionService;
        _this.formBuilder = formBuilder;
        _this.modalTitle = 'Disinvest';
        _this.isKyc = false;
        _this.hasHoldings = false;
        _this.atLeastOneFundAllocated = false;
        return _this;
    }
    DisinvestInstructionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.createForm();
        var user$ = this.sharedStore.select(fromShared.selectUser);
        var investmentAccounts$ = this.investorStore.select(fromInvestor.selectInvestmentAccounts);
        var selectedAccount$ = this.investorStore.select(fromInvestor.selectSelectedInstructionAccount);
        combineLatest(user$, investmentAccounts$, selectedAccount$, function (user, investmentAccounts, selectedAccount) {
            return { user: user, investmentAccounts: investmentAccounts, selectedAccount: selectedAccount };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var user = _a.user, investmentAccounts = _a.investmentAccounts, selectedAccount = _a.selectedAccount;
            if (!investmentAccounts) {
                return;
            }
            _this.user = user;
            _this.isKyc = user.customerDetails.isKycVerified;
            _this.userBankAccounts = user.bankAccounts;
            if (user.bankAccounts.length >= 1) {
                _this.instructionForm.get('bankAccount').setValue(user.bankAccounts[0]);
            }
            if (_this.userBankAccounts) {
                _this.instructionForm.get('bankAccount').setValue(_this.userBankAccounts[0]);
            }
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.selectedAccountNumber = selectedAccount;
            _this.selectedAccount = investmentAccounts.find(function (acct) {
                return acct.accountNumber === _this.selectedAccountNumber;
            });
            _this.addFundHoldings();
        });
    };
    DisinvestInstructionComponent.prototype.ngOnDestroy = function () { };
    DisinvestInstructionComponent.prototype.canSubmit = function () {
        if (this.instructionForm.get('sellAll').value) {
            return this.instructionForm.get('bankAccount').valid;
        }
        return this.instructionForm.valid && this.atLeastOneFundAllocated;
    };
    DisinvestInstructionComponent.prototype.mayInstruct = function () {
        return this.hasHoldings && this.isKyc;
    };
    DisinvestInstructionComponent.prototype.submit = function () {
        var _this = this;
        if (this.canSubmit()) {
            var formData = this.instructionForm.value;
            this.sharedStore.dispatch(new LoadingAction());
            var payLoad = {
                accountNumber: this.selectedAccountNumber,
                bankAccountNumber: formData.bankAccount.accountNumber,
                bankAccountHolder: this.user.displayName,
                bankAccountType: formData.bankAccount.accountType,
                stopDebit: false,
                sellAll: formData.sellAll,
                disInvestBreakdown: this.getDisInvestAllocations(),
                ucn: this.user.customerDetails.ucn,
                caseTypeId: 0,
            };
            this.instructionService
                .submitDisInvestInstruction(payLoad)
                .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
                .subscribe(function (result) { return _this.handleResponse(result, _this.modalTitle); }, function () { return _this.handleSubmitError(_this.modalTitle); });
        }
        else {
            this.markAsTouched(this.instructionForm);
        }
    };
    DisinvestInstructionComponent.prototype.cancel = function () {
        this.instructionForm.reset();
        this.instructionForm.get('sellAll').setValue(false);
        this.instructionForm.get('disinvestBasis').setValue(InstrumentAllocationBasis.unitsQuantity);
        this.addFundHoldings();
    };
    DisinvestInstructionComponent.prototype.getDisInvestAllocations = function () {
        var _this = this;
        var _allocations = [];
        this.instructionForm.get('allocations').value.forEach(function (fund) {
            var allocation = {
                fundName: fund.fundName,
                fundCode: fund.fundCode.replace(' ZA', ''),
                allocatedUnits: null,
                allocatedPercent: null,
                allocatedAmount: null,
                allocationBasis: _this.instructionForm.get('disinvestBasis').value,
            };
            if (_this.byUnits()) {
                allocation.allocatedUnits = fund.units;
                allocation.allocatedPercent = fund.percentageOfUnits;
            }
            else {
                allocation.allocatedAmount = fund.value;
            }
            _allocations.push(allocation);
        });
        return _allocations;
    };
    DisinvestInstructionComponent.prototype.basis = function () {
        return this.instructionForm.get('disinvestBasis').value;
    };
    DisinvestInstructionComponent.prototype.byUnits = function () {
        return this.basis() === InstrumentAllocationBasis.unitsQuantity;
    };
    DisinvestInstructionComponent.prototype.byValue = function () {
        return this.basis() === InstrumentAllocationBasis.currencyValue;
    };
    DisinvestInstructionComponent.prototype.showTable = function () {
        return this.instructionForm.get('allocations').value.length > 0 && !this.instructionForm.get('sellAll').value;
    };
    DisinvestInstructionComponent.prototype.basisChange = function (basis) {
        var allocations = this.instructionForm.get('allocations');
        for (var _i = 0, _a = allocations.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            if (basis === InstrumentAllocationBasis.unitsQuantity) {
                control.get('value').setValue(0);
                control.get('value').markAsPristine();
                control.get('percOfValue').setValue(0);
                control.get('percOfValue').markAsPristine();
            }
            else {
                control.get('percentageOfUnits').setValue(0);
                control.get('percentageOfUnits').markAsPristine();
                control.get('units').setValue(0);
                control.get('units').markAsPristine();
            }
        }
    };
    DisinvestInstructionComponent.prototype.unitsUpdated = function (fundControl) {
        var unitPercentage = this.round((fundControl.value.units / fundControl.value.unitsHeld) * 100);
        fundControl.get('percentageOfUnits').setValue(unitPercentage, { emitEvent: false });
    };
    DisinvestInstructionComponent.prototype.unitPercentageUpdated = function (fundControl, value) {
        var units = this.round((fundControl.value.percentageOfUnits / 100) * fundControl.value.unitsHeld);
        fundControl.get('units').setValue(units, { emitEvent: false });
    };
    DisinvestInstructionComponent.prototype.valueUpdated = function (fundControl) {
        var valuePercentage = this.round((fundControl.value.value / fundControl.value.valueHeld) * 100);
        fundControl.get('percOfValue').setValue(valuePercentage, { emitEvent: false });
    };
    DisinvestInstructionComponent.prototype.round = function (value, places) {
        if (places === void 0) { places = 2; }
        return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
    };
    DisinvestInstructionComponent.prototype.createForm = function () {
        var _this = this;
        this.instructionForm = this.formBuilder.group({
            bankAccount: [null, Validators.required],
            disinvestBasis: [InstrumentAllocationBasis.unitsQuantity],
            allocations: this.formBuilder.array([]),
            sellAll: [false],
        });
        this.instructionForm.valueChanges.subscribe(function () {
            var allocations = _this.instructionForm.get('allocations').value;
            if (_this.byUnits()) {
                _this.atLeastOneFundAllocated = allocations.filter(function (alloc) { return alloc.units > 0 || alloc.percentageOfUnits > 0; }).length > 0;
            }
            else {
                _this.atLeastOneFundAllocated = allocations.filter(function (alloc) { return alloc.value > 0; }).length > 0;
            }
        });
    };
    DisinvestInstructionComponent.prototype.addFundHoldings = function () {
        var _this = this;
        if (this.selectedAccount) {
            var formArray = this.instructionForm.get('allocations');
            formArray.controls.length = 0;
            for (var _i = 0, _a = this.selectedAccount.accountPortfolios.filter(function (acc) {
                return _this.instructionService.canInstructOnAccountItem(acc);
            }); _i < _a.length; _i++) {
                var accountPorfolio = _a[_i];
                formArray.push(this.formBuilder.group({
                    units: [
                        0,
                        Validators.compose([
                            requiredIfValidator(function () { return _this.byUnits(); }),
                            minIfValidator(0, function () { return _this.byUnits(); }),
                            maxIfValidator(accountPorfolio.units, function () { return _this.byUnits(); }),
                        ]),
                    ],
                    value: [
                        0,
                        Validators.compose([
                            requiredIfValidator(function () { return _this.byValue(); }),
                            minIfValidator(0, function () { return _this.byValue(); }),
                            maxIfValidator(accountPorfolio.totalValue, function () { return _this.byValue(); }),
                        ]),
                    ],
                    percentageOfUnits: [
                        0,
                        Validators.compose([
                            requiredIfValidator(function () { return _this.byUnits(); }),
                            minIfValidator(0, function () { return _this.byUnits(); }),
                            maxIfValidator(100, function () { return _this.byUnits(); }),
                        ]),
                    ],
                    percOfValue: [
                        0,
                        Validators.compose([minIfValidator(0, function () { return _this.byValue(); }), maxIfValidator(98, function () { return _this.byValue(); })]),
                    ],
                    fundName: [accountPorfolio.fundName],
                    fundCode: [accountPorfolio.fundCode],
                    unitsHeld: [accountPorfolio.units],
                    unitPrice: [accountPorfolio.unitPrice],
                    lastPriceDate: [accountPorfolio.lastPriceDate],
                    valueHeld: [accountPorfolio.totalValue],
                }));
            }
            this.hasHoldings = formArray.length > 0;
        }
    };
    return DisinvestInstructionComponent;
}(BaseInstructionComponent));
export { DisinvestInstructionComponent };
