// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { InvestorDocument, GeneralDocument } from 'investor/models';

export const NEW_LIST_ACCOUNT = '[Documents] New List of account docs';
export const NEW_LIST_GENERAL = '[Documents] New List of general docs';
export const ERROR = '[Documents] Error';

export class NewAccountDocumentsListAction implements Action {
    public readonly type = NEW_LIST_ACCOUNT;
    constructor(public payload: InvestorDocument[]) {}
}

export class NewGeneralDocumentsListAction implements Action {
    public readonly type = NEW_LIST_GENERAL;
    constructor(public payload: GeneralDocument[]) {}
}

export class DocumentRetrievalError implements Action {
    public readonly type = ERROR;
    constructor(public payload: Error) {}
}

export type DocumentAction = NewAccountDocumentsListAction | DocumentRetrievalError | NewGeneralDocumentsListAction;

export function accountDocumentsReducer(state: InvestorDocument[] = null, action: DocumentAction) {
    switch (action.type) {
        case NEW_LIST_ACCOUNT: {
            return action.payload;
        }
        case ERROR: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function generalDocumentsReducer(state: GeneralDocument[] = null, action: DocumentAction) {
    switch (action.type) {
        case NEW_LIST_GENERAL: {
            return action.payload;
        }
        case ERROR: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function documentRetrievalErrorReducer(state: Error = null, action: DocumentAction) {
    switch (action.type) {
        case NEW_LIST_GENERAL:
        case NEW_LIST_ACCOUNT: {
            return null;
        }
        case ERROR: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
