var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstParserService } from 'pst/services';
import { PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest } from 'rxjs';
import { LoggerService, LookupDataService } from 'shared/services';
import { BasePstComponent } from '../base-pst.component';
var MONTHS_IN_YEAR = 12;
var TermComponent = /** @class */ (function (_super) {
    __extends(TermComponent, _super);
    function TermComponent(loggerService, modalService, pstStore, pstParser, formBuilder, lookupService) {
        var _this = _super.call(this, loggerService, modalService) || this;
        _this.pstStore = pstStore;
        _this.pstParser = pstParser;
        _this.formBuilder = formBuilder;
        _this.lookupService = lookupService;
        _this.minMonths = 0;
        _this.maxMonths = 11;
        _this.max = 10000;
        _this.min = 1;
        _this.step = 1;
        _this.sliderValue = 0;
        _this.switchPoint = 0;
        _this.overrideMax = true;
        _this.oldSliderValue = 0;
        return _this;
    }
    TermComponent.prototype.sliderMove = function (event) {
        var years = Math.floor(+event.value / MONTHS_IN_YEAR);
        var months = +event.value % MONTHS_IN_YEAR;
        this.inputForm.controls.GOAL_YEARS.setValue(years, { emitEvent: false });
        this.inputForm.controls.GOAL_MONTHS.setValue(months);
    };
    TermComponent.prototype.ngOnDestroy = function () { };
    TermComponent.prototype.ngOnInit = function () {
        var _this = this;
        var result$ = this.pstStore.select(fromPst.selectInProgressPst);
        var tree$ = this.pstStore.select(fromPst.selectPstTree);
        var tooltips$ = this.lookupService.getPstTooltips();
        combineLatest(result$, tree$, tooltips$, function (result, tree, tooltips) {
            return { result: result, tree: tree, tooltips: tooltips };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var result = _a.result, tree = _a.tree, tooltips = _a.tooltips;
            _this.tree = tree;
            if (!_this.tree) {
                return;
            }
            _super.prototype.initContent.call(_this, null, tooltips, tree.node.nodeId);
            var childNode = tree.childNodes[0];
            _this.pstStore.dispatch(new UserSelectedChildNodeAction(childNode));
            _this.heading = _this.pstParser.parseParameterizedString(tree.node.nodeHeading, result);
            _this.description = tree.node.nodeDescription;
            _this.sliderText = _this.pstParser.buildInputDescription(childNode.nodeDescription);
            var lineBreakIndex = _this.sliderText.findIndex(function (item) { return item.value === 'year(s) and'; });
            var line1 = _this.sliderText.slice(0, lineBreakIndex);
            var line2 = _this.sliderText.slice(lineBreakIndex + 1);
            _this.sliderText = line1.concat([
                { type: 'text', value: 'year(s)' },
                { type: 'break' },
                { type: 'text', value: 'and' }
            ], line2);
            var range = _this.pstParser.extractRange(childNode.nodeLongDescription);
            if (range) {
                _this.min = range[0], _this.max = range[1];
            }
            _this.switchPoint = _this.pstParser.extractTermSwitch(childNode.nodeLongDescription);
            _this.createForm();
            _this.pstStore.dispatch(new PstScreenValidAction(_this.inputForm.valid));
        });
    };
    TermComponent.prototype.monthsToYears = function (numerator, denominator) {
        return Math.floor(numerator / denominator);
    };
    TermComponent.prototype.createForm = function () {
        var _this = this;
        if (!this.inputForm) {
            this.maxYears = this.monthsToYears(this.max, MONTHS_IN_YEAR);
            this.minYears = this.monthsToYears(this.min, MONTHS_IN_YEAR);
            this.inputForm = this.formBuilder.group({
                GOAL_YEARS: [0, this.getValidators(this.minYears, this.maxYears)],
                GOAL_MONTHS: [0, this.getValidators(this.minMonths, this.maxMonths)],
            });
            this.inputForm.valueChanges.subscribe(function (changes) { return _this.handleFormChanges(changes); });
        }
    };
    TermComponent.prototype.handleFormChanges = function (changes) {
        var newMonths = +changes.GOAL_MONTHS || 0;
        var newYears = +changes.GOAL_YEARS;
        var totalMonths = newYears * MONTHS_IN_YEAR + newMonths;
        this.sliderValue = totalMonths;
        if (totalMonths < this.oldSliderValue) {
            // Slider is moving left (i.e. down)
            if (totalMonths < this.switchPoint) {
                this.step = 1;
            }
            else {
                this.step = MONTHS_IN_YEAR;
                // Bump up to next full year
                totalMonths = (newYears + 1) * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
        }
        else {
            // Slider is moving right (i.e. up)
            if (totalMonths <= this.switchPoint) {
                this.step = 1;
            }
            else {
                this.step = MONTHS_IN_YEAR;
            }
        }
        var currentMonths = +this.inputForm.get('GOAL_MONTHS').value;
        if (totalMonths > this.switchPoint) {
            if (currentMonths !== 0) {
                this.inputForm.get('GOAL_MONTHS').setValue(0, { emitEvent: false });
                var years = +this.inputForm.get('GOAL_YEARS').value + 1;
                this.inputForm.get('GOAL_YEARS').setValue(years, { emitEvent: false });
                totalMonths = years * MONTHS_IN_YEAR;
                this.sliderValue = totalMonths;
            }
            this.inputForm.get('GOAL_MONTHS').disable({ emitEvent: false });
        }
        else {
            this.inputForm.get('GOAL_MONTHS').enable({ emitEvent: false });
        }
        this.oldSliderValue = totalMonths;
        this.updateValidators(newYears, newMonths);
        this.pstStore.dispatch(new UserEnteredValueAction("" + totalMonths));
        this.pstStore.dispatch(new PstScreenValidAction(this.inputForm.valid));
    };
    TermComponent.prototype.updateValidators = function (years, months) {
        this.minMonths = years === 0 ? 1 : 0;
        this.minYears = months === 0 ? 1 : 0;
        this.inputForm.get('GOAL_MONTHS').setValidators(this.getValidators(this.minMonths, this.maxMonths));
        this.inputForm.get('GOAL_MONTHS').updateValueAndValidity({ emitEvent: false });
        this.inputForm.get('GOAL_YEARS').setValidators(this.getValidators(this.minYears, this.maxYears));
        this.inputForm.get('GOAL_YEARS').updateValueAndValidity({ emitEvent: false });
    };
    TermComponent.prototype.getValidators = function (min, max) {
        return Validators.compose([Validators.required, Validators.pattern('^[0-9]+$'), Validators.min(min), Validators.max(max)]);
    };
    return TermComponent;
}(BasePstComponent));
export { TermComponent };
