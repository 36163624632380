/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./session-expiring-modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../services/authentication.service";
import * as i4 from "@ngrx/store";
var styles_SessionExpiringModalComponent = [];
var RenderType_SessionExpiringModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SessionExpiringModalComponent, data: {} });
export { RenderType_SessionExpiringModalComponent as RenderType_SessionExpiringModalComponent };
export function View_SessionExpiringModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Are you still busy?"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["You will be logged out in ", " seconds"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.renewSession() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["I'm still busy"])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-rounded btn-themecolor waves-effect text-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Log me out"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeLeft; _ck(_v, 7, 0, currVal_0); }); }
export function View_SessionExpiringModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wim-session-expiring-modal", [], null, null, null, View_SessionExpiringModalComponent_0, RenderType_SessionExpiringModalComponent)), i0.ɵdid(1, 180224, null, 0, i1.SessionExpiringModalComponent, [i2.NgbActiveModal, i3.AuthenticationService, i4.Store], null, null)], null, null); }
var SessionExpiringModalComponentNgFactory = i0.ɵccf("wim-session-expiring-modal", i1.SessionExpiringModalComponent, View_SessionExpiringModalComponent_Host_0, {}, {}, []);
export { SessionExpiringModalComponentNgFactory as SessionExpiringModalComponentNgFactory };
