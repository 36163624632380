import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CustomerBankAccount } from 'application/models';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import {
    AccountPortfolios,
    InstructionAllocation,
    InstrumentAllocationBasis,
    InvestmentAccount,
    RecurringInstruction,
} from 'investor/models';
import { InstructionsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EacModalComponent, SharedModalComponent } from 'shared/components';
import { User } from 'shared/models';
import { SourceOfFundsType } from 'shared/models/source-of-funds-type';
import { DateFormatterService, LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { dateMinValidator } from 'shared/validators';

const OK_TO_CANCEL = 'Yes Cancel';

@Component({
    selector: 'wim-instruction-recurring',
    templateUrl: 'recurring-instruction.component.pug',
})
export class RecurringInstructionComponent extends BaseInstructionComponent implements OnInit, OnDestroy {
    private modalTitle = 'Recurring Investment';

    public minDebitOrder = 500;
    public maxDebitOrder = 100000;
    public maxEscalation = 15;
    public hasHoldings = false;
    public isKyc = false;
    public existingInstructions: RecurringInstruction[] = [];
    public viewExisting = false;

    public selectedFund: any;
    public errorMessage: string;

    public sourceOfFundsTypes: SourceOfFundsType[];
    public userBankAccounts: CustomerBankAccount[];
    public selectedAccountNumber: string;
    public debitDay = 0;

    public debitAgreement: string;
    public instructionForm: FormGroup;

    private totalAllocation = 0;
    private user: User;
    private selectedAccount: InvestmentAccount;
    public allFunds: AccountPortfolios[];

    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private instructionService: InstructionsService,
        private lookupService: LookupDataService,
        modalService: NgbModal,
        private formBuilder: FormBuilder,
        private dateFormatter: DateFormatterService,
        loggerService: LoggerService,
        toastr: ToastrService
    ) {
        super(toastr, loggerService, modalService);
    }

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());
        this.viewExisting = false;
        this.createForm(undefined);

        this.lookupService
            .getSourceOfFundsTypes()
            .pipe(this.scavenger.collect())
            .subscribe(types => {
                this.sourceOfFundsTypes = types ? types : [];
            });

        this.lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(funds => {
                if (funds && funds.length > 0) {
                    this.allFunds = funds.map(fund => {
                        return {
                            fundName: fund.name,
                            fundCode: fund.pstCode.split(' ')[0],
                            percentageOfTotal: 0,
                            totalValue: 0,
                            lastPriceDate: null,
                            units: 0,
                            unitPrice: 0,
                            factSheetUrl: fund.factSheetUrl,
                            ter: fund.ter,
                            transactionCosts: fund.transactionCosts,
                            adviceFee: fund.adviceFee,
                            adminFee: fund.adminFee,
                            assetClass: fund.assetClass,
                        };
                    });
                } else {
                    this.allFunds = [];
                }
            });

        combineLatest(
            this.investorStore.select(fromInvestor.selectInvestmentAccounts),
            this.investorStore.select(fromInvestor.selectSelectedInstructionAccount),
            this.sharedStore.select(fromShared.selectUser),
            (accounts, selectedAccount, selectedUser) => {
                return { accounts, selectedAccount, selectedUser };
            }
        )
            .pipe(this.scavenger.collect())
            .subscribe(({ accounts, selectedAccount, selectedUser }) => {
                if (!accounts) {
                    return;
                }
                if (!selectedUser) {
                    return;
                }
                this.user = selectedUser;
                this.isKyc = selectedUser.customerDetails.isKycVerified;
                this.userBankAccounts = selectedUser.bankAccounts;
                if (selectedUser.bankAccounts.length >= 1) {
                    this.instructionForm.get('bankAccount').setValue(selectedUser.bankAccounts[0]);
                }
                this.selectedAccountNumber = selectedAccount;

                this.selectedAccountNumber = selectedAccount;
                this.sharedStore.dispatch(new StopLoadingAction());
                this.selectedAccount = accounts.find(acct => {
                    return acct.accountNumber === this.selectedAccountNumber;
                });
                this.lookupService.getAgreementContent(this.selectedAccount.portfolioTypeId).subscribe(content => {
                    this.debitAgreement = content.filter(agg => agg.isDebitOrder)[0].summary;
                });
                this.addFundHoldings();
            });
    }

    public ngOnDestroy(): void {}

    public computeAllocationTotal() {
        this.totalAllocation = this.instructionForm.get('allocations').value.reduce((a, b) => a + b.percentageOfTotal, 0);
    }

    public loadInstruction(instruction: RecurringInstruction) {
        this.createForm(instruction);
        this.addFundHoldings();
        this.setExistingValues(instruction.bankAccountNumber, instruction.sourceOfFundName, instruction.existingInstructionId);
        this.viewExisting = false;
    }

    public mayInstruct() {
        return this.hasHoldings && this.isKyc;
    }

    public backToExisting() {
        this.viewExisting = true;
    }

    public createNew() {
        this.createForm(undefined);
        this.addFundHoldings();
        this.viewExisting = false;
    }

    public canSubmit() {
        if (this.instructionForm.get('stopDebit').value) {
            return true;
        }
        return this.totalAllocation === 100 && this.instructionForm.valid;
    }

    public submit() {
        if (this.canSubmit()) {
            const warningRef = this.modalService.open(SharedModalComponent);
            if (this.instructionForm.value.stopDebit) {
                warningRef.componentInstance.modalMessage = `Are you sure you want to stop the debit order for this account?`;
                warningRef.componentInstance.noValue = 'No';
                warningRef.componentInstance.yesValue = 'Yes, stop debit order';
                warningRef.componentInstance.modalTitle = 'Confirm Stop Debit Order';
            } else {
                warningRef.componentInstance.modalMessage = `Are you sure you want to create/modify your debit order for this account?`;
                warningRef.componentInstance.noValue = 'No';
                warningRef.componentInstance.yesValue = 'Yes, change it';
                warningRef.componentInstance.modalTitle = 'Confirm Debit Order Change';
            }
            warningRef.result
                .then(resultType => {
                    if (resultType === OK_TO_CANCEL) {
                        this.submitComfirmed();
                    }
                })
                .catch(() => {});
        } else {
            this.markAsTouched(this.instructionForm);
        }
    }

    public submitComfirmed() {
        const formData = this.instructionForm.value;
        this.sharedStore.dispatch(new LoadingAction());
        const payLoad: RecurringInstruction = formData.stopDebit
            ? {
                  accountNumber: this.selectedAccountNumber,
                  annualEscalationPercentage: null,
                  bankAccountNumber: null,
                  caseTypeId: formData.caseTypeId,
                  debitAmount: null,
                  debitDate: null,
                  debitDay: null,
                  isTermAccepted: formData.debitAuthorised,
                  newEscalationDate: null,
                  sourceOfFundName: null,
                  sourceOfFundOther: null,
                  stopDebit: formData.stopDebit,
                  ucn: null,
                  recurringAllocations: null,
                  existingInstructionId: formData.existingInstructionId,
              }
            : {
                  accountNumber: this.selectedAccountNumber,
                  annualEscalationPercentage: formData.annualEscalationPercentage,
                  bankAccountNumber: formData.bankAccount.accountNumber,
                  caseTypeId: formData.caseTypeId,
                  debitAmount: formData.debitOrderAmount,
                  debitDate: this.dateFormatter.forApi(formData.debitDate),
                  debitDay: this.debitDay,
                  isTermAccepted: formData.debitAuthorised,
                  newEscalationDate: this.dateFormatter.forApi(formData.escalationDate),
                  sourceOfFundName: formData.sourceOfFunds.description,
                  sourceOfFundOther: formData.otherSourceOfFund,
                  stopDebit: formData.stopDebit,
                  ucn: this.user.customerDetails.ucn,
                  recurringAllocations: this.getAllocations(),
                  existingInstructionId: formData.existingInstructionId,
              };

        this.instructionService
            .submitRecurringInstruction(payLoad)
            .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
            .subscribe(result => this.handleResponse(result, this.modalTitle), () => this.handleSubmitError(this.modalTitle));
    }

    public calculateEac() {
        const eacRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        const account = {
            funds: this.instructionForm.get('allocations').value.map(accountFund => {
                return {
                    allocation: accountFund.percentageOfTotal / 100,
                    fundCode: accountFund.fundCode.replace('ZA', '').trim(),
                };
            }),
        };
        eacRef.componentInstance.account = account;
    }

    public cancel() {
        this.instructionForm.get('stopDebit').setValue(false);
        this.instructionForm.reset();
        this.setDefaults();
        this.addFundHoldings();
    }

    public showTable() {
        return this.instructionForm.get('allocations').value.length > 0;
    }

    public isSourceOfFundsOther(id) {
        if (!this.sourceOfFundsTypes || !id) {
            return false;
        }
        const sourceType = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return sourceType && sourceType.isOther;
    }

    public updateDebitDay() {
        this.debitDay = this.dateFormatter.asMoment(this.instructionForm.get('debitDate').value).date();
        if (Number.isNaN(this.debitDay)) {
            this.debitDay = null;
        }
    }

    private getMinDebitDate() {
        return moment()
            .add(3, 'days')
            .startOf('day');
    }

    private getDefaultDebitEscalationDate() {
        return moment()
            .add(3, 'days')
            .add(1, 'year')
            .startOf('day');
    }

    private setDefaults() {
        this.instructionForm.get('debitDate').setValue(this.dateFormatter.forDatePicker(this.getMinDebitDate()));
        this.instructionForm.get('escalationDate').setValue(this.dateFormatter.forDatePicker(this.getDefaultDebitEscalationDate()));
        this.instructionForm.get('annualEscalationPercentage').setValue(6);
        this.instructionForm.get('stopDebit').setValue(false);
        this.instructionForm.get('debitAuthorised').setValue(false);
    }

    private createForm(instruction: RecurringInstruction) {
        const ok = !!instruction;
        const minDebitDate = ok ? moment(instruction.debitDate) : this.getMinDebitDate();
        const defaultDebitEscalationDate = ok ? moment(instruction.newEscalationDate) : this.getDefaultDebitEscalationDate();
        const debitAmount = ok ? instruction.debitAmount : null;
        const escalationPercent = ok ? instruction.annualEscalationPercentage : 6;
        const otherSource = ok ? instruction.sourceOfFundOther : null;
        const debitStop = ok ? instruction.stopDebit : false;
        const authorisedDebit = ok ? instruction.isTermAccepted : false;
        const defaultCaseTypeId = ok ? instruction.instructionId : null;

        this.instructionForm = this.formBuilder.group(
            {
                caseTypeId: [defaultCaseTypeId],
                bankAccount: [null, Validators.required],
                debitOrderAmount: [
                    debitAmount,
                    Validators.compose([Validators.required, Validators.min(this.minDebitOrder), Validators.max(this.maxDebitOrder)]),
                ],
                debitDate: [
                    this.dateFormatter.forDatePicker(minDebitDate),
                    Validators.compose([Validators.required, dateMinValidator(minDebitDate.toDate(), this.dateFormatter)]),
                ],
                annualEscalationPercentage: [
                    escalationPercent,
                    Validators.compose([Validators.required, Validators.min(0), Validators.max(this.maxEscalation)]),
                ],
                escalationDate: [this.dateFormatter.forDatePicker(defaultDebitEscalationDate), Validators.required],
                sourceOfFunds: [null, Validators.required],
                otherSourceOfFund: [otherSource],
                stopDebit: [debitStop, Validators.required],
                debitAuthorised: [authorisedDebit, Validators.requiredTrue],
                allocations: this.formBuilder.array([]),
                existingInstructionId: [null],
            },
            {
                validator: this.validateOtherFields.bind(this),
            }
        );

        this.updateDebitDay();
    }

    private addFundHoldings() {
        if (this.selectedAccount) {
            const items = this.instructionForm.get('allocations') as FormArray;
            items.controls.length = 0;
            for (let portfolioItem of this.allFunds) {
                items.push(
                    this.formBuilder.group({
                        fundCode: portfolioItem.fundCode,
                        fundName: portfolioItem.fundName,
                        percentageOfTotal: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
                    })
                );
            }
            this.hasHoldings = items.length > 0;
        }
    }

    private getAllocations() {
        const _allocations: InstructionAllocation[] = [];
        this.instructionForm.get('allocations').value.forEach(fund => {
            _allocations.push({
                fundCode: fund.fundCode,
                fundName: fund.fundName,
                allocatedAmount: (fund.percentageOfTotal / 100) * this.instructionForm.value.debitOrderAmount,
                allocatedPercent: parseFloat(fund.percentageOfTotal),
                allocatedUnits: null,
                allocationBasis: InstrumentAllocationBasis.currencyValue,
            });
        });
        return _allocations;
    }

    private otherDescriptionRequired(id: number) {
        const type = this.sourceOfFundsTypes.filter(sof => sof.id === id)[0];
        return type && type.isOther;
    }

    private validateSourceOfFundsOther(abstractForm: FormGroup): { [key: string]: any } {
        if (!this.sourceOfFundsTypes) {
            return null;
        }
        const sourceIdControl = abstractForm.get('sourceOfFunds');
        const descriptionControl = abstractForm.get('otherSourceOfFund');

        const required = this.otherDescriptionRequired(+(sourceIdControl.value ? sourceIdControl.value.id : 0));

        let valid = true;
        if (required) {
            const value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    }

    private validateEscalationDate(abstractForm: FormGroup): { [key: string]: any } {
        const firstDebitDateControl = abstractForm.get('debitDate');
        const escalationDateControl = abstractForm.get('escalationDate');

        let escalationDate = this.dateFormatter.asMoment(escalationDateControl.value);
        let firstDebitDate = this.dateFormatter.asMoment(firstDebitDateControl.value);

        let valid = true;
        if (escalationDateControl.value === '' || escalationDate === null) {
            escalationDateControl.setErrors({ required: true });
            valid = false;
        } else if (escalationDate.isBefore(firstDebitDate)) {
            escalationDateControl.setErrors({ beforeFirst: true });
            valid = false;
        } else {
            const oneYearFromNow = firstDebitDate.add(1, 'year').startOf('day');
            if (escalationDate.isAfter(oneYearFromNow)) {
                escalationDateControl.setErrors({ oneYear: oneYearFromNow.format('YYYY-MM-DD') });
                valid = false;
            }
        }
        if (valid) {
            escalationDateControl.setErrors(null);
            return null;
        }
        return { required: true };
    }

    private validateOtherFields(abstractForm: FormGroup): { [key: string]: any } {
        const sourceOfFundsErrors = this.validateSourceOfFundsOther(abstractForm);
        const escalationDateErrors = this.validateEscalationDate(abstractForm);

        if (sourceOfFundsErrors || escalationDateErrors) {
            return { required: true };
        }
        return null;
    }

    private setExistingValues(bankAccountNumber, sourceOfFundName, existingInstructionId) {
        const bankAccount = !!bankAccountNumber
            ? this.userBankAccounts.filter(k => k.accountNumber === bankAccountNumber)[0]
            : this.userBankAccounts[0];

        const selectedSource = this.sourceOfFundsTypes.filter(k => k.description === sourceOfFundName)[0];
        if (existingInstructionId) {
            this.instructionForm.get('existingInstructionId').setValue(existingInstructionId);
        }
        if (selectedSource) {
            this.instructionForm.get('sourceOfFunds').setValue(selectedSource);
        }
        this.instructionForm.get('bankAccount').setValue(bankAccount);
    }
}
