import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApplicationService } from 'application/services';
import * as fromApplication from 'application/store/application.store';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var HorizonOnboardingCompleteComponent = /** @class */ (function () {
    function HorizonOnboardingCompleteComponent(applicationStore, sharedStore, formBuilder, applicationService, toastr, lookupService) {
        var _this = this;
        this.applicationStore = applicationStore;
        this.sharedStore = sharedStore;
        this.formBuilder = formBuilder;
        this.applicationService = applicationService;
        this.toastr = toastr;
        this.referenceSubmitted = false;
        this.productName = '';
        this.isDirect = false;
        var portfolioTypes$ = lookupService.getPortfolioTypes();
        var application$ = this.applicationStore.select(fromApplication.selectHorizonApplication);
        var referenceNumber$ = this.applicationStore.select(fromApplication.selectApplicationReference);
        var submissionError$ = this.applicationStore.select(fromApplication.selectAppSubmissionError);
        var isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        this.subscriptions$ = combineLatest(referenceNumber$, submissionError$, application$, portfolioTypes$, isDirect$, function (referenceNum, submissionErr, application, portfolioTypes, isDirect) {
            return { referenceNum: referenceNum, submissionErr: submissionErr, application: application, portfolioTypes: portfolioTypes, isDirect: isDirect };
        }).subscribe(function (_a) {
            var referenceNum = _a.referenceNum, submissionErr = _a.submissionErr, application = _a.application, portfolioTypes = _a.portfolioTypes, isDirect = _a.isDirect;
            _this.referenceNumber = referenceNum;
            _this.submissionError = submissionErr;
            _this.productName = portfolioTypes.filter(function (portfolioType) { return portfolioType.id === application.portfolioTypeId; })[0].name;
            _this.isDirect = isDirect;
        });
        this.createForm();
    }
    HorizonOnboardingCompleteComponent.prototype.ngOnDestroy = function () {
        this.subscriptions$.unsubscribe();
    };
    HorizonOnboardingCompleteComponent.prototype.createForm = function () {
        this.phoneReferenceForm = this.formBuilder.group({
            phoneReference: [''],
        });
    };
    HorizonOnboardingCompleteComponent.prototype.canSubmit = function () {
        return this.phoneReferenceForm.valid;
    };
    HorizonOnboardingCompleteComponent.prototype.submit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        var formData = this.phoneReferenceForm.value;
        var payLoad = {
            applicationReference: this.referenceNumber,
            phoneCallReference: formData.phoneReference,
        };
        this.applicationService
            .SubmitPhoneReference(payLoad)
            .pipe(finalize(function () {
            _this.referenceSubmitted = true;
            _this.sharedStore.dispatch(new StopLoadingAction());
        }))
            .subscribe(function (result) { return _this.handleResponse(result); }, function (error) { return _this.handleError(); });
    };
    HorizonOnboardingCompleteComponent.prototype.handleResponse = function (result) {
        if (result.success) {
            this.toastr.success(result.message);
            this.cancel();
        }
        else {
            this.toastr.error(result.message);
        }
    };
    HorizonOnboardingCompleteComponent.prototype.handleError = function () {
        this.toastr.error('An error occured while submitting the phone reference. Please try again later.');
    };
    HorizonOnboardingCompleteComponent.prototype.cancel = function () {
        this.phoneReferenceForm.reset();
    };
    return HorizonOnboardingCompleteComponent;
}());
export { HorizonOnboardingCompleteComponent };
