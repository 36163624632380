import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import appConfig from 'appConfig';
import * as moment from 'moment';

import { Logger, LoggerService } from 'shared/services/logger.service';
import { ServerTimeService } from 'shared/services/server-time.service';
import { SharedState } from 'shared/store/shared.store';
import * as fromShared from 'shared/store/shared.store';
import { SessionExpiringAction } from 'shared/store/user.reducers';

const CHECK_INTERVAL_LIFESUPPORT = 10 * 1000;
const DEFAULT_SESSION_MINUTES = 20;
const WARNING_TIME_SECONDS = 60;

@Injectable()
export class SessionService {
    private logger: Logger;
    private authenticated: boolean;

    private checkTimer = null;
    private lifeSupportTimer = null;
    private expiryTime: moment.Moment;

    constructor(
        private store: Store<SharedState>,
        loggerService: LoggerService,
        private modalService: NgbModal,
        private http: HttpClient,
        private serverTimeService: ServerTimeService,
        private router: Router
    ) {
        this.logger = loggerService.getLogger('Session');
    }

    public start() {
        this.store.select(fromShared.selectAuthenticated).subscribe(authenticated => {
            this.authenticated = authenticated;
            if (!authenticated && this.checkTimer) {
                this.logger.debug('Stopping session timer');
                clearTimeout(this.checkTimer || this.checkTimer._id);
            }
        });
        this.store.select(fromShared.selectSessionRenewal).subscribe(sessionRenewed => {
            if (sessionRenewed && this.authenticated) {
                this.renewSession(sessionRenewed);
            }
        });
        this.store.select(fromShared.selectSessionLifeSupport).subscribe(enableLifeSupport => {
            if (enableLifeSupport) {
                this.startLifeSupport();
            } else {
                this.endLifeSupport();
            }
        });
    }

    public startSession() {
        if (!this.checkTimer) {
            this.checkSessionOnServer().then(sessionState => {
                this.startWarningTimer(moment(sessionState.expires));
            });
        }
        this.endLifeSupport();
    }

    private startWarningTimer(expiryTime) {
        if (this.checkTimer) {
            clearTimeout(this.checkTimer || this.checkTimer._id);
        }
        this.expiryTime = expiryTime;
        this.logger.debug(`Session expiry time set to ${this.expiryTime.format('YYYY-MM-DD HH:mm:ss')} based on server expiry time`);
        let warningTime = moment(this.expiryTime).add(-1 * WARNING_TIME_SECONDS, 'seconds');
        let secondsTillWarning = warningTime.diff(moment(this.serverTimeService.getServerTime()), 'seconds');

        this.checkTimer = setTimeout(() => {
            this.checkSession();
        }, secondsTillWarning * 1000);
    }

    public endSession() {
        if (this.checkTimer) {
            clearTimeout(this.checkTimer._id);
            this.checkTimer = null;
        }
    }

    public validateTimeout(minutes) {
        return minutes || DEFAULT_SESSION_MINUTES;
    }

    public checkSessionOnServer(): Promise<{ sessionValid: boolean; expires: moment.Moment }> {
        return this.http
            .get<Date>(`${appConfig.apiUrl}/users/session/check`)
            .toPromise()
            .then(expiry => {
                return { sessionValid: true, expires: moment(expiry) };
            })
            .catch(error => {
                if (!error || error.status === 401 || error.status === 403) {
                    return { sessionValid: false, expires: null };
                }
                return { sessionValid: true, expires: null };
            });
    }

    public startLifeSupport() {
        this.endLifeSupport();
        this.endSession();
        this.logger.debug('Session is still active but this tab is not. Going to life support mode');
        this.lifeSupportTimer = setInterval(() => {
            this.logger.debug('Heartbeat');
            this.checkSessionOnServer();
        }, CHECK_INTERVAL_LIFESUPPORT);

        setTimeout(() => this.checkSessionOnServer(), 2000);
        this.router.navigate(['/invest']);
    }

    public endLifeSupport() {
        if (this.lifeSupportTimer) {
            clearInterval(this.lifeSupportTimer._id || this.lifeSupportTimer);
            this.lifeSupportTimer = null;
        }
    }

    private checkSession() {
        this.logger.debug('Checking session');
        let self = this;
        this.checkSessionOnServer().then(sessionState => {
            let newExpiryTime = moment(sessionState.expires);
            if (newExpiryTime.isAfter(self.expiryTime)) {
                self.startWarningTimer(newExpiryTime);
            } else {
                self.logger.debug('Session is about to expire');
                this.store.dispatch(new SessionExpiringAction(true));
                self.endSession();
            }
        });
    }

    private renewSession(sessionRenewed) {
        if (this.checkTimer) {
            this.logger.info(
                `Session extended by [${sessionRenewed.url}] at ${new Date(sessionRenewed.timeExtended).toTimeString()} for [${
                    sessionRenewed.lengthMinutes
                } minutes]`
            );
        } else {
            this.startSession();
        }
    }
}
