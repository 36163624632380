var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterContentInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { Store } from '@ngrx/store';
import * as fromShared from 'shared/store/shared.store';
import * as fromApplication from 'application/store/application.store';
import { ApplicationDateService } from 'application/services';
import * as moment from 'moment';
import { PstContentModalComponent } from 'pst/components/content-modal/pst-content-modal.component';
import { FundingMethod } from 'robo/models/funding-method';
import { RoboFieldId } from 'robo/models/robo-field-id';
import { AdviceEngineService } from 'robo/services/advice-engine.service';
import { AdviceFormStatusChangedAction, ForceValidationMessagesAction, RoboAdditionalLumpsumsUpdated, RoboClearScreenAction, RoboRecommendationAcceptedAction, RoboStore, RoboUpdateFieldAction, TaxFreeOpenedAction, UpdateRoboValidationWarningsAction, RoboUpdateMultipleFieldsAction, } from 'robo/store';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { FeatureFlags } from 'shared/models';
import { DateFormatterService, FeatureFlagService, LoggerService, LookupDataService } from 'shared/services';
import { bigNumberMaxValidator, bigNumberMinValidator, dateMaxValidator, dateMinValidator, dateSubsetValidator } from 'shared/validators';
var DEFAULT_MIN_TERM = 1;
var DEFAULT_MAX_TERM = 120;
var DEFAULT_MAX = 10000000;
var DEFAULT_MIN = 1;
var DEBOUNCE_TIME = 500;
var OTHER_GOAL = 10;
var RoboInputCaptureComponent = /** @class */ (function (_super) {
    __extends(RoboInputCaptureComponent, _super);
    function RoboInputCaptureComponent(loggerService, formBuilder, store, sharedStore, applicationStore, lookupDataService, adviceService, modalService, dateFormatterService, actions$, applicationDateService, featureFlagService) {
        var _this = _super.call(this, loggerService) || this;
        _this.formBuilder = formBuilder;
        _this.store = store;
        _this.sharedStore = sharedStore;
        _this.applicationStore = applicationStore;
        _this.lookupDataService = lookupDataService;
        _this.adviceService = adviceService;
        _this.modalService = modalService;
        _this.dateFormatterService = dateFormatterService;
        _this.actions$ = actions$;
        _this.applicationDateService = applicationDateService;
        _this.featureFlagService = featureFlagService;
        _this.RoboFieldId = RoboFieldId;
        //public allowedTfsaDates = [1, 16, 21, 26];
        _this.allowedTfsaDates = Array.from({ length: 26 }, function (_, i) { return i + 1; });
        _this.goalName = null;
        _this.isAnnually = false;
        _this.frequencyTypes = [];
        _this.preserveLumpSumFieldRange = false;
        _this.preserveRecurringFieldRange = false;
        _this.toolTips = [];
        _this.OPTIONAL_FIELDS = [RoboFieldId.requestAdditionalLumpSums];
        _this.DEFAULT_NULL_BOOLEAN_FIELDS = [RoboFieldId.contributingToTaxFree];
        _this.showTarget = true;
        _this.showAdditionalLumpsums = false;
        _this.showAddAnotherButton = false;
        _this.completedFields = {};
        _this.visibleFields = {};
        _this.fieldsRanges = {};
        _this.targetAmount = 0;
        _this.considerInflation = false;
        _this.goalAdjustedForInflation = 0;
        _this.isTfsa = false;
        _this.taxFreeOptOut = false;
        _this.hasExistingTfsa = false;
        _this.hasInFlightTfsa = false;
        _this.selectedFundingType = FundingMethod.both;
        _this.additionalLumpsumsEnabled = null;
        // No way to query existing validators, so need to track separatelty
        _this.currentValidators = {};
        _this.markDisabledRecurringStartDate = function (date) {
            return ((date.day > 26) && (_this.isTfsa) && (!_this.isAnnually));
        };
        var minDate = _this.applicationDateService.firstPossibleDebitDate();
        var minDateTFUT = _this.applicationDateService.firstPossibleTFUTDebitDate();
        var maxDate = _this.applicationDateService.maxLumpsumDate();
        _this.multipleLumpSumsEnabaled = _this.featureFlagService.isEnabled(FeatureFlags.MultipleLumpSums);
        _this.minDateForPicker = _this.dateFormatterService.forDatePicker(minDate);
        _this.minDateForPickerRecurring = _this.dateFormatterService.forDatePicker(minDate);
        _this.maxDateForPicker = _this.dateFormatterService.forDatePicker(maxDate);
        _this.minDate = minDate.startOf('day').toDate();
        _this.minDateTFUT = minDateTFUT.startOf('day').toDate();
        _this.maxDate = maxDate.endOf('day').toDate();
        store
            .select(RoboStore.taxFreeOpened)
            .pipe(_this.scavenger.collect())
            .subscribe(function (taxFreeOpened) {
            _this.isTfsa = taxFreeOpened;
            _this.setMinDateRecurring();
            _this.validateDateFields();
        });
        sharedStore
            .select(fromShared.selectIsDirect)
            .pipe(_this.scavenger.collect())
            .subscribe(function (isDirect) {
            _this.isDirect = isDirect;
        });
        applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .subscribe(function (selected) {
            _this.hasExistingTfsa = selected ? selected.hasExistingTaxFreeAccount : null;
            _this.hasInFlightTfsa = selected ? selected.hasInFlightTaxFreeApplication : null;
            var validationErrors = [];
            if (_this.hasExistingTfsa) {
                validationErrors.push('Please note that you already have an existing Tax-Free Unit Trust account, an alternative investment will be recommended.');
            }
            if (_this.hasInFlightTfsa) {
                validationErrors.push('Please note that you already have an existing Tax-Free Unit Trust application in progress, an alternative investment will be recommended.');
            }
            _this.store.dispatch(new UpdateRoboValidationWarningsAction(validationErrors));
        });
        /*this.tfsaDateError = `For TFUT, only ${this.allowedTfsaDates
            .slice(0, this.allowedTfsaDates.length - 1)
            .join(',')} or ${this.allowedTfsaDates.slice(this.allowedTfsaDates.length - 1)} is allowed`;*/
        _this.tfsaDateError = "For TFUT, only " + _this.allowedTfsaDates[0] + "st to " + _this.allowedTfsaDates[_this.allowedTfsaDates.length - 1] + "th is allowed";
        return _this;
    }
    RoboInputCaptureComponent.prototype.ngAfterContentInit = function () {
        this.clearLocalData();
        this.getGoals();
        this.getTooltips();
        this.getCPIRate();
        this.registerForRequiredFieldChanges();
        this.registerForFieldValueChanges();
        this.registerForActionsDispatched();
        this.registerForSpecificFieldChanges();
        this.registerForAdviceUpdates();
        this.setDefaultRecurringFrequency();
        this.setDefaultTaxFreeOptOut();
        this.registerForTaxFreeOptOutChanges();
        this.checkForExistingTfsa();
    };
    RoboInputCaptureComponent.prototype.checkForExistingTfsa = function () {
        if (this.hasExistingTfsa) {
            this.adviceForm.get(RoboFieldId[RoboFieldId.contributingToTaxFree]).setValue(this.hasExistingTfsa);
        }
        if (this.hasInFlightTfsa) {
            this.adviceForm.get(RoboFieldId[RoboFieldId.contributingToTaxFree]).setValue(this.hasInFlightTfsa);
        }
        this.roboValidationWarnings$ = this.store.select(RoboStore.roboValidationWarnings);
    };
    RoboInputCaptureComponent.prototype.setDefaultTaxFreeOptOut = function () {
        // Default to false
        this.store.dispatch(new RoboUpdateFieldAction({
            fieldId: RoboFieldId.taxFreeOptOut,
            index: 0,
            value: this.taxFreeOptOut.toString(),
        }));
    };
    RoboInputCaptureComponent.prototype.registerForTaxFreeOptOutChanges = function () {
        var _this = this;
        this.store
            .select(RoboStore.taxFreeOptOut)
            .pipe(this.scavenger.collect())
            .subscribe(function (taxFreeOptOut) {
            if (_this.taxFreeOptOut === taxFreeOptOut) {
                return;
            }
            _this.taxFreeOptOut = taxFreeOptOut;
            _this.store.dispatch(new RoboUpdateFieldAction({
                fieldId: RoboFieldId.taxFreeOptOut,
                index: 0,
                value: _this.taxFreeOptOut.toString(),
            }))
                .pipe()
                .subscribe(function () { _this.adviceService.getAdvice(); });
        });
    };
    RoboInputCaptureComponent.prototype.getTooltips = function () {
        var _this = this;
        this.lookupDataService.getPstTooltips().subscribe(function (toolTips) {
            _this.toolTips = toolTips;
        });
    };
    RoboInputCaptureComponent.prototype.getCPIRate = function () {
        var _this = this;
        this.lookupDataService.getCPIRate().subscribe(function (rate) {
            _this.CPIRate = rate * 100;
        });
    };
    RoboInputCaptureComponent.prototype.getGoals = function () {
        var _this = this;
        this.lookupDataService
            .getRoboGoals()
            .pipe(this.scavenger.collect())
            .subscribe(function (goals) {
            _this.goals = goals;
            if (_this.hasExistingTfsa || _this.hasInFlightTfsa || _this.isDirect) {
                _this.goals = _this.goals.filter(function (goal) { return goal.goalId !== 11; });
            }
        });
    };
    RoboInputCaptureComponent.prototype.setDefaultRecurringFrequency = function () {
        // Default to Monthly for now, remove once LOBs can support others
        this.store.dispatch(new RoboUpdateFieldAction({
            fieldId: RoboFieldId.recurringContributionFrequency,
            index: 0,
            value: '3',
        }));
    };
    RoboInputCaptureComponent.prototype.getDefaultYealyIncreaseValue = function () {
        if (this.isJustInvestTaxFreeGoalCategory) {
            return '0';
        }
        if (this.isEmergenciesGoalCategory) {
            return this.CPIRate.toString();
        }
        var hasTaxFree = this.getBooleanFieldValue(RoboFieldId.contributingToTaxFree);
        var valueToSet;
        if (hasTaxFree == null) {
            valueToSet = this.CPIRate.toString();
        }
        else {
            if (hasTaxFree) {
                valueToSet = this.CPIRate.toString();
            }
            else {
                valueToSet = '0';
            }
        }
        return valueToSet;
    };
    RoboInputCaptureComponent.prototype.setDefaultYealyIncrease = function () {
        if (this.visibleFields[RoboFieldId.annualEscalationPercentage] || this.isJustInvestTaxFreeGoalCategory) {
            var valueToSet = this.getDefaultYealyIncreaseValue();
            this.store.dispatch(new RoboUpdateFieldAction({
                fieldId: RoboFieldId.annualEscalationPercentage,
                index: 0,
                value: valueToSet,
            }));
            this.adviceForm.get(RoboFieldId[RoboFieldId.annualEscalationPercentage]).setValue(valueToSet);
        }
    };
    RoboInputCaptureComponent.prototype.getMinDateRecurring = function () {
        var valueToSet = this.dateFormatterService.forDatePicker(moment(this.minDate));
        if ((this.isTfsa) && (!this.isAnnually)) {
            valueToSet = this.dateFormatterService.forDatePicker(moment(this.minDateTFUT));
        }
        return valueToSet;
    };
    RoboInputCaptureComponent.prototype.setMinDateRecurring = function () {
        var valueToSet = this.getMinDateRecurring();
        this.minDateForPickerRecurring = valueToSet;
        if (this.visibleFields[RoboFieldId.recurringContributionStartDate]) {
            var recurringDateValue = this.getDateFieldValue(RoboFieldId.recurringContributionStartDate);
            if (recurringDateValue && this.dateFormatterService.asMoment(valueToSet).isAfter(this.dateFormatterService.asMoment(recurringDateValue))) {
                // new min after current value
                this.adviceForm.get(RoboFieldId[RoboFieldId.recurringContributionStartDate]).setValue(valueToSet);
            }
        }
    };
    RoboInputCaptureComponent.prototype.setJustInvestTaxFreeFields = function () {
        var value = this.adviceForm.value[RoboFieldId[RoboFieldId.fundingMethod]];
        if (value === 5 && this.isJustInvestTaxFreeGoalCategory) {
            this.adviceForm.get(RoboFieldId[RoboFieldId.lumpSumAmount]).setValue(null);
            this.adviceForm.get(RoboFieldId[RoboFieldId.recurringContributionAmount]).setValue(null);
            var fields = [{
                    fieldId: RoboFieldId.lumpSumAmount,
                    index: 0,
                    value: null,
                },
                {
                    fieldId: RoboFieldId.recurringContributionAmount,
                    index: 0,
                    value: null,
                }];
            this.store.dispatch(new RoboUpdateMultipleFieldsAction(fields));
        }
        this.preserveLumpSumFieldRange = false;
        this.preserveRecurringFieldRange = false;
        if (value === 6 && this.isJustInvestTaxFreeGoalCategory) {
            this.frequencyTypes = [
                { value: '7', description: 'Annually' }
            ];
            this.isAnnually = true;
            this.store.dispatch(new RoboUpdateFieldAction({
                fieldId: RoboFieldId.recurringContributionFrequency,
                index: 0,
                value: '7',
            }));
        }
        else {
            this.frequencyTypes = [
                { value: '3', description: 'Monthly' }
            ];
            this.isAnnually = false;
            this.store.dispatch(new RoboUpdateFieldAction({
                fieldId: RoboFieldId.recurringContributionFrequency,
                index: 0,
                value: '3',
            }));
        }
    };
    RoboInputCaptureComponent.prototype.fieldChangeGoalCategory = function () {
        if (this.visibleFields[RoboFieldId.goal]) {
            var value = this.adviceForm.value[RoboFieldId[RoboFieldId.goal]];
            this.isEmergenciesGoalCategory = (value === 4);
            this.isJustInvestTaxFreeGoalCategory = (value === 11);
            this.setDefaultYealyIncrease();
            this.fieldChange(RoboFieldId.goal);
        }
    };
    RoboInputCaptureComponent.prototype.fieldChangeAnnualEscalationPercentage = function () {
        if (this.visibleFields[RoboFieldId.annualEscalationPercentage]) {
            var value = this.adviceForm.value[RoboFieldId[RoboFieldId.annualEscalationPercentage]];
            if ((0 < +value) && (+value < 1)) {
                this.store.dispatch(new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.annualEscalationPercentage,
                    index: 0,
                    value: '0',
                }));
            }
            else {
                this.fieldChange(RoboFieldId.annualEscalationPercentage);
            }
        }
    };
    RoboInputCaptureComponent.prototype.fieldChangeCheckTaxFreeLimit = function (fieldId) {
        // this check will only needs to be done for the Lump Sum & Recurring funding method type
        // here we will set a boolean values (preserveLumpSumFieldRange and preserveRecurringFieldRange) which will be used in the updateForm method 
        // this is becuase the fieldRange of the other field must only not change if the value entered does not exceed the current maximum
        if (this.adviceForm.value[RoboFieldId[RoboFieldId.fundingMethod]] === 5 && this.isJustInvestTaxFreeGoalCategory) {
            if (fieldId === RoboFieldId.lumpSumAmount) {
                this.preserveLumpSumFieldRange = true;
                var LSValue = +this.adviceForm.value[RoboFieldId[RoboFieldId.lumpSumAmount]];
                var LSValueMax = this.maxForField(RoboFieldId.lumpSumAmount);
                if (LSValue > LSValueMax) {
                    this.preserveRecurringFieldRange = true;
                }
                else {
                    this.preserveRecurringFieldRange = false;
                }
            }
            if (fieldId === RoboFieldId.recurringContributionAmount) {
                this.preserveRecurringFieldRange = true;
                var RECValue = +this.adviceForm.value[RoboFieldId[RoboFieldId.recurringContributionAmount]];
                var RECValueMax = this.maxForField(RoboFieldId.recurringContributionAmount);
                if (RECValue > RECValueMax) {
                    this.preserveLumpSumFieldRange = true;
                }
                else {
                    this.preserveLumpSumFieldRange = false;
                }
            }
            // we must update the field so that the advice engine is called with the correct value
            this.fieldChange(fieldId);
        }
        else {
            this.fieldChange(fieldId);
        }
    };
    RoboInputCaptureComponent.prototype.registerForAdviceUpdates = function () {
        var _this = this;
        this.store
            .select(RoboStore.currentAdvice)
            .pipe(this.scavenger.collect(), filter(function (advice) { return advice && advice.recommendations.length > 0; }), map(function (advice) { return advice.recommendations[0]; }))
            .subscribe(function (recommendation) {
            _this.goalAdjustedForInflation = recommendation.goalAmountAdjustedForInflation;
            if (recommendation.taxFreeOptionAvailable) {
                if (_this.taxFreeOptOut == false) {
                    _this.store.dispatch(new TaxFreeOpenedAction(recommendation.taxFreeOptionAvailable));
                }
            }
            else {
                _this.store.dispatch(new TaxFreeOpenedAction(false));
            }
            _this.validateDateFields();
        });
    };
    RoboInputCaptureComponent.prototype.validateDateFields = function () {
        var _this = this;
        if (this.adviceForm) {
            this.validateDateControl(this.adviceForm.get('lumpSumDate'));
            this.validateDateControl(this.adviceForm.get('recurringContributionStartDate'));
            this.adviceForm.get('additionalLumpsums').controls.forEach(function (control) {
                return _this.validateDateControl(control.get('date'));
            });
        }
    };
    RoboInputCaptureComponent.prototype.registerForSpecificFieldChanges = function () {
        var _this = this;
        this.selectFieldValue(RoboFieldId.goal).subscribe(function (goalId) {
            if (!_this.goals) {
                return;
            }
            var selectedGoal = _this.goals.find(function (goal) { return goal.goalId === +goalId; });
            _this.goalTypeName = selectedGoal ? selectedGoal.name : null;
            _this.isEmergenciesGoalCategory = goalId ? (+goalId === 4) : false;
            _this.isJustInvestTaxFreeGoalCategory = goalId ? (+goalId === 11) : false;
        });
        this.selectFieldValue(RoboFieldId.goalName)
            .pipe(this.scavenger.collect())
            .subscribe(function (goalName) {
            _this.goalName = goalName;
        });
        this.selectFieldValue(RoboFieldId.considerInflation)
            .pipe(this.scavenger.collect())
            .subscribe(function (considerInflation) {
            if (considerInflation != null) {
                _this.considerInflation = ("" + considerInflation).toLowerCase() === 'true';
            }
        });
        this.selectFieldValue(RoboFieldId.targetValue)
            .pipe(this.scavenger.collect())
            .subscribe(function (target) {
            _this.targetAmount = +target;
        });
    };
    RoboInputCaptureComponent.prototype.registerForActionsDispatched = function () {
        var _this = this;
        this.actions$
            .pipe(ofActionDispatched(ForceValidationMessagesAction), this.scavenger.collect())
            .subscribe(function () { return _this.markControlsTouched(_this.adviceForm); });
        this.actions$
            .pipe(ofActionDispatched(RoboClearScreenAction), this.scavenger.collect())
            .subscribe(function () {
            _this.clearLocalData();
        });
        this.actions$
            .pipe(ofActionDispatched(RoboRecommendationAcceptedAction), this.scavenger.collect())
            .subscribe(function (action) {
            switch (action.payload.field) {
                case RoboFieldId.lumpSumAmount:
                    _this.setRecommendedLumpSumAmount(action);
                    break;
                case RoboFieldId.recurringContributionAmount:
                    _this.setRecurringAmount(action);
                    break;
                case RoboFieldId.targetValue:
                    _this.adviceForm.get(RoboFieldId[RoboFieldId.targetValue]).setValue(action.payload.value);
                    break;
            }
            _this.adviceForm.updateValueAndValidity();
        });
    };
    RoboInputCaptureComponent.prototype.setRecurringAmount = function (action) {
        this.setFundingType(FundingMethod.recurring);
        this.adviceForm.get(RoboFieldId[RoboFieldId.fundingMethod]).setValue(this.selectedFundingType);
        this.fieldChange(RoboFieldId.fundingMethod);
        this.adviceForm.get(RoboFieldId[RoboFieldId.recurringContributionAmount]).setValue(action.payload.value);
        this.fieldChange(RoboFieldId.recurringContributionAmount);
    };
    RoboInputCaptureComponent.prototype.setRecommendedLumpSumAmount = function (action) {
        this.setFundingType(FundingMethod.lumpsum);
        this.adviceForm.get(RoboFieldId[RoboFieldId.fundingMethod]).setValue(this.selectedFundingType);
        this.fieldChange(RoboFieldId.fundingMethod);
        this.adviceForm.get(RoboFieldId[RoboFieldId.lumpSumAmount]).setValue(action.payload.value);
        this.fieldChange(RoboFieldId.lumpSumAmount);
    };
    RoboInputCaptureComponent.prototype.registerForFieldValueChanges = function () {
        var _this = this;
        this.store
            .select(RoboStore.completedFields)
            .pipe(this.scavenger.collect())
            .subscribe(function (completedFields) {
            _this.completedFields = completedFields;
            if (!_this.adviceForm) {
                _this.createForm();
            }
        });
    };
    RoboInputCaptureComponent.prototype.registerForRequiredFieldChanges = function () {
        var _this = this;
        this.store
            .select(RoboStore.requiredFields)
            .pipe(this.scavenger.collect(), filter(function (fields) { return !!fields; }))
            .subscribe(function (requiredFields) {
            _this.updateForm(requiredFields);
        });
    };
    RoboInputCaptureComponent.prototype.clearLocalData = function () {
        this.additionalLumpsumsEnabled = null;
        this.considerInflation = false;
        this.goalName = null;
        this.goalTypeName = null;
        this.showTarget = true;
        this.showAdditionalLumpsums = false;
        this.showAddAnotherButton = false;
        this.targetAmount = 0;
        this.goalAdjustedForInflation = 0;
        this.selectedFundingType = null;
    };
    RoboInputCaptureComponent.prototype.ngOnDestroy = function () { };
    RoboInputCaptureComponent.prototype.setFundingType = function (action) {
        var _this = this;
        this.selectFieldValue(RoboFieldId.fundingMethod).subscribe(function (fundingStrategy) {
            if (parseInt(fundingStrategy, 10) === FundingMethod.lumpsum && action === FundingMethod.lumpsum) {
                _this.selectedFundingType = parseInt(fundingStrategy, 10);
            }
            else if (parseInt(fundingStrategy, 10) === FundingMethod.recurring && action === FundingMethod.recurring) {
                _this.selectedFundingType = parseInt(fundingStrategy, 10);
            }
            else {
                _this.selectedFundingType = FundingMethod.both;
            }
        });
    };
    RoboInputCaptureComponent.prototype.maxForTermField = function () {
        var field = this.visibleFields[RoboFieldId.term];
        return field && field.fieldRange ? field.fieldRange.max : DEFAULT_MAX_TERM;
    };
    RoboInputCaptureComponent.prototype.showInflatedGoal = function () {
        return this.considerInflation && this.goalAdjustedForInflation > 0 && this.targetAmount !== this.goalAdjustedForInflation;
    };
    RoboInputCaptureComponent.prototype.minForTermField = function () {
        var field = this.visibleFields[RoboFieldId.term];
        return field && field.fieldRange ? field.fieldRange.min : DEFAULT_MIN_TERM;
    };
    RoboInputCaptureComponent.prototype.maxForField = function (fieldId) {
        var field = this.visibleFields[fieldId];
        return field && field.fieldRange ? field.fieldRange.max : DEFAULT_MAX;
    };
    RoboInputCaptureComponent.prototype.minForField = function (fieldId) {
        var field = this.visibleFields[fieldId];
        return field && field.fieldRange ? field.fieldRange.min : DEFAULT_MIN;
    };
    RoboInputCaptureComponent.prototype.fieldOptions = function (fieldId) {
        var field = this.visibleFields[fieldId];
        return field && field.options ? field.options : [];
    };
    RoboInputCaptureComponent.prototype.showField = function (fieldId) {
        return !!this.visibleFields[fieldId];
    };
    RoboInputCaptureComponent.prototype.showTooltip = function () {
        var content = this.toolTips[1];
        content.tooltip = content.tooltip.replace('{{CPI_VALUE}}', this.CPIRate + '%');
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.tooltip;
    };
    RoboInputCaptureComponent.prototype.showTaxFreeDisclaimer = function () {
        var content = this.toolTips[3];
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.tooltip;
    };
    RoboInputCaptureComponent.prototype.showInflationDescription = function () {
        var content = this.toolTips[6];
        this.logger.debug(content);
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.tooltip;
    };
    RoboInputCaptureComponent.prototype.showRecurringStartDateDisclaimer = function () {
        var content;
        if (this.isAnnually) {
            content = this.toolTips[4];
        }
        else {
            content = this.toolTips[5];
        }
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.componentInstance.content = content.tooltip;
    };
    RoboInputCaptureComponent.prototype.fieldChange = function (fieldId) {
        var fieldUpdate = {
            fieldId: fieldId,
            value: this.adviceForm.value[RoboFieldId[fieldId]],
        };
        this.store.dispatch(new RoboUpdateFieldAction(fieldUpdate));
    };
    RoboInputCaptureComponent.prototype.dateFieldChange = function (fieldId) {
        var date = this.adviceForm.value[RoboFieldId[fieldId]];
        var fieldUpdate = {
            fieldId: fieldId,
            value: this.dateFormatterService.toString(date),
        };
        var control = this.adviceForm.get(RoboFieldId[fieldId]);
        this.validateDateControl(control);
        this.store.dispatch(new RoboUpdateFieldAction(fieldUpdate));
    };
    RoboInputCaptureComponent.prototype.validateDateControl = function (control) {
        var minError = dateMinValidator(this.minDate, this.dateFormatterService)(control);
        var maxError = dateMaxValidator(this.maxDate, this.dateFormatterService)(control);
        var requiredError = Validators.required(control);
        var subsetError = null;
        if ((this.isTfsa) && (control === this.adviceForm.get('recurringContributionStartDate'))) {
            subsetError = dateSubsetValidator(this.allowedTfsaDates, this.dateFormatterService)(control);
        }
        var errors = Object.assign({}, minError, maxError, requiredError, subsetError);
        if (Object.keys(errors).length === 0) {
            errors = null;
        }
        control.setErrors(errors);
    };
    RoboInputCaptureComponent.prototype.selectFieldValue = function (id) {
        return this.store.select(RoboStore.completedField).pipe(map(function (fn) {
            var field = fn(id);
            return field ? field.value : null;
        }));
    };
    RoboInputCaptureComponent.prototype.updateForm = function (requiredFields) {
        var _this = this;
        if (!this.adviceForm) {
            return;
        }
        var changesMade = false;
        var _loop_1 = function (fieldId) {
            var field = requiredFields.find(function (f) { return f.fieldId === fieldId; });
            var fieldName = RoboFieldId[fieldId];
            var control = this_1.adviceForm.get(fieldName);
            if (field) {
                if (!control) {
                    return "continue";
                }
                // preserve fieldRange if neccessary for Tax Free limit
                if ((fieldId === RoboFieldId.lumpSumAmount) && (this_1.preserveLumpSumFieldRange)) {
                    field.fieldRange = this_1.visibleFields[fieldId].fieldRange;
                }
                // preserve fieldRange if neccessary for Tax Free limit
                if ((fieldId === RoboFieldId.recurringContributionAmount) && (this_1.preserveRecurringFieldRange)) {
                    field.fieldRange = this_1.visibleFields[fieldId].fieldRange;
                }
                this_1.visibleFields[fieldId] = field;
                var value = this_1.adviceForm.value[fieldName];
                if (value === null || value === undefined) {
                    var defaultValue = this_1.getValueOrDefault(fieldId, function (id) { return _this.getStringFieldValue(id); });
                    if (defaultValue) {
                        var controlValue = defaultValue;
                        if (fieldId === RoboFieldId.lumpSumDate) {
                            controlValue = this_1.dateFormatterService.forDatePickerFromString(defaultValue);
                        }
                        if (fieldId === RoboFieldId.recurringContributionStartDate) {
                            controlValue = this_1.getMinDateRecurring();
                            defaultValue = this_1.dateFormatterService.toString(controlValue);
                        }
                        if (fieldId === RoboFieldId.annualEscalationPercentage) {
                            controlValue = this_1.getDefaultYealyIncreaseValue();
                        }
                        changesMade = true;
                        control.setValue(controlValue, { emitEvent: false });
                        this_1.store.dispatch(new RoboUpdateFieldAction({
                            fieldId: field.fieldId,
                            value: defaultValue,
                        }));
                    }
                }
                // this is to cater for Save For Later - controls are not disabled initially
                if (!control.disabled && ((fieldId === RoboFieldId.contributingToTaxFree) && ((this_1.hasExistingTfsa) || (this_1.hasInFlightTfsa)))) {
                    control.disable({ onlySelf: true, emitEvent: false });
                }
                if (control.disabled && !((fieldId === RoboFieldId.contributingToTaxFree) && ((this_1.hasExistingTfsa) || (this_1.hasInFlightTfsa)))) {
                    control.enable({ onlySelf: true, emitEvent: false });
                }
                var oldValidators = Object.assign([], this_1.currentValidators[fieldId]);
                control.setValidators(this_1.getValidators(fieldId));
                if (this_1.haveValidatorsChanged(fieldId, oldValidators)) {
                    control.updateValueAndValidity();
                }
            }
            else {
                delete this_1.visibleFields[fieldId];
                if (control && control.enabled) {
                    control.disable({ onlySelf: true, emitEvent: false });
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = Object.values(RoboFieldId).filter(function (k) { return !isNaN(k); }); _i < _a.length; _i++) {
            var fieldId = _a[_i];
            _loop_1(fieldId);
        }
        var mustAskForAdditionalLumpsums = !!requiredFields.find(function (field) { return field.fieldId === RoboFieldId.requestAdditionalLumpSums; });
        var showingLumpsums = this.adviceForm.value.additionalLumpsumQuestion === 'true';
        if ((showingLumpsums && !mustAskForAdditionalLumpsums) || (!showingLumpsums && mustAskForAdditionalLumpsums)) {
            this.additionalLumpSumToggleChanged();
        }
        if (changesMade) {
            this.adviceForm.updateValueAndValidity();
        }
    };
    RoboInputCaptureComponent.prototype.getStringFieldValue = function (fieldId) {
        var field = this.completedFields[fieldId];
        if (!field) {
            return null;
        }
        return field.value;
    };
    RoboInputCaptureComponent.prototype.getNumberFieldValue = function (fieldId) {
        var value = this.getStringFieldValue(fieldId);
        if (value) {
            return +value;
        }
        return null;
    };
    RoboInputCaptureComponent.prototype.getBooleanFieldValue = function (fieldId) {
        var value = this.getStringFieldValue(fieldId);
        if (value) {
            return value.toLowerCase() === 'true';
        }
        return null;
    };
    RoboInputCaptureComponent.prototype.getDateFieldValue = function (fieldId) {
        var value = this.getStringFieldValue(fieldId);
        if (value) {
            return this.dateFormatterService.forDatePickerFromString(value);
        }
        return null;
    };
    RoboInputCaptureComponent.prototype.getLumpsums = function () {
        return this.adviceForm.get('additionalLumpsums');
    };
    RoboInputCaptureComponent.prototype.additionalLumpsumsChanged = function () {
        var _this = this;
        var lumpsumControls = this.getLumpsums();
        for (var _i = 0, _a = lumpsumControls.controls; _i < _a.length; _i++) {
            var control = _a[_i];
            this.validateDateControl(control.get('date'));
        }
        var lumpsums = lumpsumControls.value.map(function (lumpsum) {
            return __assign({}, lumpsum, { date: _this.dateFormatterService.toString(lumpsum.date) });
        });
        this.store.dispatch(new RoboAdditionalLumpsumsUpdated(lumpsums));
        if (lumpsumControls.length > 4) {
            this.showAddAnotherButton = false;
        }
        else {
            this.showAddAnotherButton = true;
        }
    };
    RoboInputCaptureComponent.prototype.removeLumpSum = function (index) {
        var array = this.getLumpsums();
        array.removeAt(index);
        this.additionalLumpsumsChanged();
    };
    RoboInputCaptureComponent.prototype.addLumpSum = function () {
        var array = this.getLumpsums();
        array.push(this.formBuilder.group({
            amount: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.min(this.minForField(RoboFieldId.lumpSumAmount)),
                    Validators.max(this.maxForField(RoboFieldId.lumpSumAmount)),
                ]),
            ],
            date: this.dateFormatterService.forDatePicker(moment().add(3, 'days')),
        }));
        this.additionalLumpsumsChanged();
    };
    RoboInputCaptureComponent.prototype.additionalLumpSumToggleChanged = function () {
        var value = this.adviceForm.value.additionalLumpsumQuestion;
        this.showAdditionalLumpsums = value === 'true';
        this.showAddAnotherButton = this.showAdditionalLumpsums;
        if (this.showAdditionalLumpsums && this.getLumpsums().length === 0) {
            this.addLumpSum();
        }
        else if (!this.showAdditionalLumpsums) {
            var lumpsumArray = this.adviceForm.get('additionalLumpsums');
            while (lumpsumArray.length !== 0) {
                lumpsumArray.removeAt(0);
            }
            this.store.dispatch(new RoboAdditionalLumpsumsUpdated([]));
        }
        if (!this.showField(RoboFieldId.requestAdditionalLumpSums)) {
            this.showAdditionalLumpsums = false;
            this.adviceForm.get('additionalLumpsumQuestion').setValue(false);
            this.store.dispatch(new RoboAdditionalLumpsumsUpdated([]));
        }
    };
    RoboInputCaptureComponent.prototype.createForm = function () {
        var _this = this;
        this.store
            .select(RoboStore.additionalLumpsums)
            .pipe(this.scavenger.collect(), take(1))
            .subscribe(function (additionalLumpsums) {
            _this.adviceForm = _this.formBuilder.group({
                goal: _this.buildNumberControl(RoboFieldId.goal),
                term: _this.buildNumberControl(RoboFieldId.term),
                goalName: _this.buildStringControl(RoboFieldId.goalName),
                targetValue: _this.buildNumberControl(RoboFieldId.targetValue),
                considerInflation: _this.buildBooleanControl(RoboFieldId.considerInflation),
                taxFreeContributionForCurrentTaxYear: _this.buildNumberControl(RoboFieldId.taxFreeContributionForCurrentTaxYear),
                contributingToTaxFree: _this.buildBooleanControl(RoboFieldId.contributingToTaxFree),
                fundingMethod: _this.buildNumberControl(RoboFieldId.fundingMethod),
                lumpSumAmount: _this.buildNumberControl(RoboFieldId.lumpSumAmount),
                lumpSumDate: _this.buildDateControl(RoboFieldId.lumpSumDate),
                withdrawalAccessType: _this.buildNumberControl(RoboFieldId.withdrawalAccessType),
                additionalLumpsumQuestion: false,
                additionalLumpsums: _this.formBuilder.array([]),
                recurringContributionAmount: _this.buildNumberControl(RoboFieldId.recurringContributionAmount),
                recurringContributionStartDate: _this.buildDateControl(RoboFieldId.recurringContributionStartDate),
                recurringContributionFrequency: ['3'],
                annualEscalationPercentage: _this.buildNumberControl(RoboFieldId.annualEscalationPercentage),
                requestAdditionalLumpSums: null,
            });
            _this.adviceForm.valueChanges
                .pipe(debounceTime(DEBOUNCE_TIME), _this.scavenger.collect())
                .subscribe(function (changes) { return _this.checkForChanges(changes); });
            _this.adviceForm.statusChanges
                .pipe(_this.scavenger.collect())
                .subscribe(function (status) { return _this.store.dispatch(new AdviceFormStatusChangedAction(status)); });
            if (additionalLumpsums.length > 0) {
                var initialLumpsum = additionalLumpsums[0];
                _this.adviceForm.get('lumpSumAmount').setValue(initialLumpsum.amount, { emitEvent: false });
                _this.store.dispatch(new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.lumpSumAmount,
                    index: 0,
                    value: "" + initialLumpsum.amount,
                }));
                _this.adviceForm
                    .get('lumpSumDate')
                    .setValue(_this.dateFormatterService.forDatePicker(moment(initialLumpsum.date)), { emitEvent: false });
                _this.store.dispatch(new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.lumpSumDate,
                    index: 0,
                    value: "" + initialLumpsum.date,
                }));
                additionalLumpsums = additionalLumpsums.slice(1);
                var array_1 = _this.getLumpsums();
                additionalLumpsums.forEach(function (lumpsum) {
                    return array_1.push(_this.formBuilder.group({
                        amount: lumpsum.amount,
                        date: _this.dateFormatterService.forDatePicker(moment(lumpsum.date)),
                    }));
                });
                _this.adviceForm.get('additionalLumpsumQuestion').setValue(additionalLumpsums.length > 0 ? 'true' : 'false');
                _this.additionalLumpsumsChanged();
                _this.additionalLumpSumToggleChanged();
                setTimeout(function () {
                    _this.adviceForm.updateValueAndValidity();
                    _this.store.dispatch(new AdviceFormStatusChangedAction(_this.adviceForm.status));
                }, 1000);
            }
        });
    };
    RoboInputCaptureComponent.prototype.buildNumberControl = function (fieldId) {
        var _this = this;
        return [this.getValueOrDefault(fieldId, function (id) { return _this.getNumberFieldValue(id); }), this.getValidators(fieldId)];
    };
    RoboInputCaptureComponent.prototype.buildBooleanControl = function (fieldId) {
        var _this = this;
        var valueOrDefault = this.getValueOrDefault(fieldId, function (id) { return _this.getBooleanFieldValue(id); });
        var valueShouldDefaultToNull = this.DEFAULT_NULL_BOOLEAN_FIELDS.find(function (id) { return id === fieldId; });
        if (valueOrDefault === null && !valueShouldDefaultToNull) {
            valueOrDefault = false;
            this.store.dispatch(new RoboUpdateFieldAction({ fieldId: fieldId, value: valueOrDefault }));
        }
        return [valueOrDefault, this.getValidators(fieldId)];
    };
    RoboInputCaptureComponent.prototype.buildStringControl = function (fieldId) {
        var _this = this;
        return [this.getValueOrDefault(fieldId, function (id) { return _this.getStringFieldValue(id); }), this.getValidators(fieldId)];
    };
    RoboInputCaptureComponent.prototype.buildDateControl = function (fieldId) {
        var _this = this;
        var valueOrDefault = this.getValueOrDefault(fieldId, function (id) { return _this.getDateFieldValue(id); });
        if (valueOrDefault === null) {
            valueOrDefault = this.dateFormatterService.forDatePicker(moment(this.minDate));
            this.store.dispatch(new RoboUpdateFieldAction({ fieldId: fieldId, value: this.dateFormatterService.toString(valueOrDefault) }));
        }
        return [valueOrDefault, this.getDateValidators(fieldId)];
    };
    RoboInputCaptureComponent.prototype.getValueOrDefault = function (fieldId, getter) {
        var existingValue = getter(fieldId);
        if (existingValue !== null && existingValue !== undefined) {
            return existingValue;
        }
        var fieldDetails = this.visibleFields[fieldId];
        if (fieldDetails && fieldDetails.defaultValue) {
            return fieldDetails.defaultValue;
        }
        return null;
    };
    RoboInputCaptureComponent.prototype.haveValidatorsChanged = function (fieldId, oldValidatorMetaData) {
        var currentValidators = this.currentValidators[fieldId];
        var _loop_2 = function (validator) {
            var matching = currentValidators.find(function (v) { return v.type === validator.type; });
            if (!matching) {
                return { value: true };
            }
        };
        for (var _i = 0, oldValidatorMetaData_1 = oldValidatorMetaData; _i < oldValidatorMetaData_1.length; _i++) {
            var validator = oldValidatorMetaData_1[_i];
            var state_1 = _loop_2(validator);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        var _loop_3 = function (validator) {
            var matching = oldValidatorMetaData.find(function (v) { return v.type === validator.type; });
            if (!matching) {
                return { value: true };
            }
            else {
                if (!this_2.areEqual(matching.values, validator.values)) {
                    return { value: true };
                }
            }
        };
        var this_2 = this;
        for (var _a = 0, currentValidators_1 = currentValidators; _a < currentValidators_1.length; _a++) {
            var validator = currentValidators_1[_a];
            var state_2 = _loop_3(validator);
            if (typeof state_2 === "object")
                return state_2.value;
        }
        return false;
    };
    RoboInputCaptureComponent.prototype.areEqual = function (obj1, obj2) {
        if (obj1 === obj2) {
            return true;
        }
        if (!obj1 || !obj2) {
            return false;
        }
        var aProps = Object.getOwnPropertyNames(obj1);
        var bProps = Object.getOwnPropertyNames(obj2);
        if (aProps.length !== bProps.length) {
            return false;
        }
        for (var _i = 0, aProps_1 = aProps; _i < aProps_1.length; _i++) {
            var propName = aProps_1[_i];
            if (obj1[propName] !== obj2[propName]) {
                return false;
            }
        }
        return true;
    };
    RoboInputCaptureComponent.prototype.validateForWhiteSpace = function (control) {
        if (control && control.value) {
            return control.value.trim() !== '' ? null : { whitespace: true };
        }
        else {
            return null;
        }
    };
    RoboInputCaptureComponent.prototype.getValidators = function (fieldId) {
        var validators = [];
        var validatorsMetaData = [];
        if (this.OPTIONAL_FIELDS.indexOf(fieldId) < 0) {
            validators.push(Validators.required);
            validatorsMetaData.push({ type: 'required' });
        }
        var fieldDetails = this.visibleFields[fieldId];
        if (fieldDetails && fieldDetails.fieldRange) {
            if (fieldDetails.fieldRange.min) {
                validators.push(bigNumberMinValidator(fieldDetails.fieldRange.min));
                validatorsMetaData.push({ type: 'min', values: { min: fieldDetails.fieldRange.min } });
            }
            if (fieldDetails.fieldRange.max) {
                validators.push(bigNumberMaxValidator(fieldDetails.fieldRange.max));
                validatorsMetaData.push({ type: 'max', values: { min: fieldDetails.fieldRange.max } });
            }
        }
        if (fieldId === RoboFieldId.goalName) {
            validators.push(this.validateForWhiteSpace);
            validatorsMetaData.push({ type: 'whitespace' });
        }
        this.currentValidators[fieldId] = validatorsMetaData;
        return Validators.compose(validators);
    };
    RoboInputCaptureComponent.prototype.getDateValidators = function (fieldId) {
        var validators = [];
        var validatorsMetaData = [];
        if (this.OPTIONAL_FIELDS.indexOf(fieldId) < 0) {
            validators.push(Validators.required);
        }
        validators.push(dateMinValidator(this.minDate, this.dateFormatterService));
        validatorsMetaData.push({ type: 'datemin', values: { min: this.minDate } });
        validators.push(dateMaxValidator(this.maxDate, this.dateFormatterService));
        validatorsMetaData.push({ type: 'datemax', values: { min: this.maxDate } });
        this.currentValidators[fieldId] = validatorsMetaData;
        return Validators.compose(validators);
    };
    RoboInputCaptureComponent.prototype.checkForChanges = function (changes) {
        this.adviceService.getAdvice();
    };
    RoboInputCaptureComponent.prototype.markControlsTouched = function (controlGroup) {
        if (controlGroup) {
            controlGroup.markAsTouched();
            if (controlGroup.controls) {
                for (var _i = 0, _a = Object.keys(controlGroup.controls); _i < _a.length; _i++) {
                    var controlName = _a[_i];
                    var control = controlGroup.get(controlName);
                    this.markControlsTouched(control);
                }
            }
        }
    };
    return RoboInputCaptureComponent;
}(BaseComponent));
export { RoboInputCaptureComponent };
