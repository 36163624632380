import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DebitOrderAgreement } from 'application/models/debit-order-agreement';

@Component({
    selector: 'wim-debit-order-agreement',
    templateUrl: './debit-order-agreement.component.pug',
    styleUrls: ['./debit-order-agreement.component.scss'],
})
export class DebitOrderAgreementComponent implements OnInit {
    @Input() public debitOrderDetails: DebitOrderAgreement;

    public isAnnualContribution = false;

    constructor() {}

    public ngOnInit() {

        if(this.debitOrderDetails.paymentFrequencyId === 7) {
            this.isAnnualContribution = true;
        }
    }
}
