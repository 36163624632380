import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { GeneralInstruction } from 'investor/models';
import { InstructionsService } from 'investor/services';
import { InvestorState } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'shared/models';
import { LoggerService } from 'shared/services';
import { LoadingAction, SharedState, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Component({
    selector: 'wim-instruction-general',
    templateUrl: 'general-instruction.component.pug',
})
export class GeneralInstructionComponent extends BaseInstructionComponent implements OnInit, OnDestroy {
    public selectedAccount: string;
    public instructionForm: FormGroup;

    private user: User;
    private modalTitle = 'How can we help you?';
    constructor(
        private investorStore: Store<InvestorState>,
        private sharedStore: Store<SharedState>,
        private instructionService: InstructionsService,
        private formBuilder: FormBuilder,
        loggerService: LoggerService,
        modalService: NgbModal,
        toastr: ToastrService
    ) {
        super(toastr, loggerService, modalService);
    }

    public ngOnInit(): void {
        this.sharedStore.dispatch(new LoadingAction());

        this.investorStore
            .select(fromInvestor.selectSelectedInstructionAccount)
            .pipe(this.scavenger.collect())
            .subscribe(account => (this.selectedAccount = account));
        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(usr => {
                this.sharedStore.dispatch(new StopLoadingAction());
                this.user = usr;
            });

        this.createForm();
    }

    public ngOnDestroy(): void {}

    public submit(): void {
        if (this.canSubmit()) {
            this.sharedStore.dispatch(new LoadingAction());
            const generalInstructionPayload: GeneralInstruction = {
                accountNumber: this.selectedAccount,
                ucn: this.user.customerDetails.ucn,
                instructionContent: this.instructionForm.value.instructionDetail,
            };
            this.instructionService
                .submitGeneralInstruction(generalInstructionPayload)
                .pipe(finalize(() => this.sharedStore.dispatch(new StopLoadingAction())))
                .subscribe(result => this.handleResponse(result, this.modalTitle), error => this.handleSubmitError(this.modalTitle));
        } else {
            this.markAsTouched(this.instructionForm);
        }
    }

    public cancel(): void {
        this.instructionForm.reset();
    }

    public canSubmit(): boolean {
        return this.instructionForm.valid;
    }

    private createForm() {
        this.instructionForm = this.formBuilder.group({
            instructionDetail: ['', Validators.required],
        });
    }
}
