var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsService, InstructionsService } from 'investor/services';
import { InstructionsAccountAction } from 'investor/store';
import * as fromInvestor from 'investor/store/investor.store';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'shared/components/base';
import { LoggerService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
var InvestorInstructionsComponent = /** @class */ (function (_super) {
    __extends(InvestorInstructionsComponent, _super);
    function InvestorInstructionsComponent(investorStore, sharedStore, route, formBuilder, instructionService, accountService, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.route = route;
        _this.formBuilder = formBuilder;
        _this.instructionService = instructionService;
        _this.accountService = accountService;
        _this.selectedAccount = null;
        _this.investmentAccounts = [];
        _this.hasHoldings = false;
        _this.isKyc = false;
        return _this;
    }
    InvestorInstructionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        var investmentAccounts$ = this.investorStore.select(fromInvestor.selectInvestmentAccounts);
        var params$ = this.route.params;
        var user$ = this.sharedStore.select(fromShared.selectUser);
        combineLatest(investmentAccounts$, params$, user$, function (accounts, routeParams, user) {
            return { accounts: accounts, routeParams: routeParams, user: user };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var accounts = _a.accounts, routeParams = _a.routeParams, user = _a.user;
            _this.createForm();
            _this.isKyc = user.customerDetails.isKycVerified;
            if (!accounts) {
                return;
            }
            _this.investmentAccounts = accounts;
            if (routeParams && routeParams['accountNumber']) {
                if (_this.investmentAccounts && _this.investmentAccounts.length > 0) {
                    _this.form
                        .get('selectedAccount')
                        .setValue(_this.investmentAccounts.filter(function (acct) { return acct.accountNumber === routeParams['accountNumber']; })[0]);
                    _this.investorStore.dispatch(new InstructionsAccountAction(_this.selectedAccount.accountNumber));
                    _this.checkForValidHoldings();
                }
            }
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
        this.accountService.fetchAccounts();
    };
    InvestorInstructionsComponent.prototype.ngOnDestroy = function () { };
    InvestorInstructionsComponent.prototype.selectedAccountChanged = function (account) {
        this.selectedAccount = account;
        var accountNumber = this.selectedAccount ? this.selectedAccount.accountNumber : null;
        this.investorStore.dispatch(new InstructionsAccountAction(accountNumber));
        this.checkForValidHoldings();
    };
    InvestorInstructionsComponent.prototype.createForm = function () {
        var _this = this;
        this.form = this.formBuilder.group({
            selectedAccount: [null, Validators.required],
        });
        this.form.valueChanges.subscribe(function (changes) {
            _this.selectedAccountChanged(changes.selectedAccount);
        });
    };
    InvestorInstructionsComponent.prototype.checkForValidHoldings = function () {
        var _this = this;
        this.hasHoldings =
            this.selectedAccount.accountPortfolios.filter(function (portfolioItem) { return _this.instructionService.canInstructOnAccountItem(portfolioItem); })
                .length > 0;
    };
    return InvestorInstructionsComponent;
}(BaseComponent));
export { InvestorInstructionsComponent };
