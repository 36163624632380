import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    HorizonOnboardingCompleteComponent,
    HorizonSeriesOnboardingWizardComponent,
    OnboardingInvestmentDetailsComponent,
    OnboardingTermsAndConditionsComponent,
} from 'application/components';
import { LoggedInAuthGuard } from 'shared/services';

export const applicationRoutes: Routes = [
    {
        path: '',
        canActivate: [LoggedInAuthGuard],
        component: HorizonSeriesOnboardingWizardComponent,
        children: [
            { path: '', redirectTo: 'investmentdetails', pathMatch: 'full' },
            { path: 'investmentdetails', component: OnboardingInvestmentDetailsComponent },
            { path: 'termsandconditions', component: OnboardingTermsAndConditionsComponent },
            { path: 'complete', component: HorizonOnboardingCompleteComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(applicationRoutes)],
    exports: [RouterModule],
})
export class ApplicationRoutingModule {}
