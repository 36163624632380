import { AdditionalLumpsum, AdviceResponse, FieldUpdate, RoboFieldId } from '../models';

// tslint:disable:max-classes-per-file

export class RoboUpdateFieldAction {
    public static type = '[Robo] Update field';
    constructor(public payload: FieldUpdate) {}
}

export class RoboUpdateMultipleFieldsAction {
    public static type = '[Robo] Update multiple fields';
    constructor(public payload: FieldUpdate[]) {}
}

export class RoboAdditionalLumpsumsUpdated {
    public static type = '[Robo] Additional Lumpsums Updated';
    constructor(public payload: AdditionalLumpsum[]) {}
}

export class RoboAdviceUpdatedAction {
    public static type = '[Robo] Advice Updated';
    constructor(public payload: AdviceResponse) {}
}

export class RoboAdviceErrorAction {
    public static type = '[Robo] Advice Error';
    constructor(public payload: Error) {}
}

export class RoboDeactivateField {
    public static type = '[Robo] Field Deactivated';
    constructor(public payload: RoboFieldId) {}
}

export class RoboActivateField {
    public static type = '[Robo] Field Activated';
    constructor(public payload: RoboFieldId) {}
}

export class RoboClearScreenAction {
    public static type = '[Robo] Clear Screen';
}

export class RoboSaveErrorAction {
    public static type = '[Robo] Save Error';
    constructor(public payload: Error) {}
}
export class RoboSavedAction {
    public static type = '[Robo] Saved for later';
    constructor(public payload: string) {}
}

export class RoboContinuedAction {
    public static type = '[Robo] Saved application continued';
    constructor(public payload: string) {}
}

export class RoboRecommendationAcceptedAction {
    public static type = '[Robo] Recommendation accepted';
    constructor(public payload: { field: RoboFieldId; value: number }) {}
}

export class TaxFreeOpenedAction {
    public static type = '[Robo] TFSA opened';
    constructor(public payload: boolean) {}
}

export class TaxFreeOptOutAction {
    public static type = '[Robo] TFSA Opt Out';
    constructor(public payload: boolean) {}
}

export class AdviceFormStatusChangedAction {
    public static type = '[Robo] Advice form status changes';
    constructor(public payload: string) {}
}

export class ForceValidationMessagesAction {
    public static type = '[Robo] Force Validation Messages';
}

export class UpdateRoboValidationWarningsAction {
    public static type = '[Robo] Warnings Updated';
    constructor(public payload: string[] ) {}
}
