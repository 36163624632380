// tslint:disable:max-classes-per-file

import { Action, ActionReducerMap } from '@ngrx/store';
import { CustomerSearchResult } from 'application/models';
import { BankerState } from 'banker/store';

export const NEW_CUSTOMER_SEARCH = '[Horizon Application] New Search';
export class NewCustomerSearchAction implements Action {
    public readonly type = NEW_CUSTOMER_SEARCH;
    constructor(public payload: CustomerSearchResult[]) {}
}

export type BankerAction = NewCustomerSearchAction;

export function customerSearchReducer(state: CustomerSearchResult[] = [], action: BankerAction) {
    switch (action.type) {
        case NEW_CUSTOMER_SEARCH: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export const reducers: ActionReducerMap<BankerState> = {
    customerSearchResults: customerSearchReducer,
};
