// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { AccountType, CustomerBankAccount } from 'application/models';
import { BankerLoginResult, SessionExtension, User } from 'shared/models';

export const BANKER_ENABLED = '[User] Banker Login Enabled';

export class BankerLoginEnabledAction implements Action {
    public readonly type = BANKER_ENABLED;
    constructor(public payload: boolean) {}
}

export const LOGOUT = '[User] Logout';
export class LogoutAction implements Action {
    public readonly type = LOGOUT;
}
export const LOGOUT_REQUEST = '[User] Logout Request';
export class LogoutRequestAction implements Action {
    public readonly type = LOGOUT_REQUEST;
    constructor(public payload: string = null) {}
}

export const LOGIN_STARTED = '[User] Login Started';
export class LoginStartedAction implements Action {
    public readonly type = LOGIN_STARTED;
}

export const LOGIN_SUCCESS = '[User] Login Success';
export class LoginSuccessAction implements Action {
    public readonly type = LOGIN_SUCCESS;
    constructor(public payload: User) {}
}

export const API_LOGIN = '[User] API Authentication Started';
export class ApiAuthenticationStarted implements Action {
    public readonly type = API_LOGIN;
}

export const LOGIN_FAILED = '[User] Login Failed';
export class LoginFailedAction implements Action {
    public readonly type = LOGIN_FAILED;
}

export const BANKER_LOGIN_FAILED = '[User] Banker Login Failed';
export class BankerLoginFailedAction implements Action {
    public readonly type = BANKER_LOGIN_FAILED;
    constructor(public payload: BankerLoginResult | Error) {}
}

export const AGREEMENT_COMPLETED = '[User] Agreement Completed';
export class AgreementCompletedAction implements Action {
    public readonly type = AGREEMENT_COMPLETED;
    constructor(public payload: User) {}
}

export const AGREEMENT_SUBMIT_FAILED = '[User] Agreement Submit Failed';
export class AgreementSubmitFailed implements Action {
    public readonly type = AGREEMENT_SUBMIT_FAILED;
    constructor(public payload: Error) {}
}

export const BANK_ACCOUNTS_UPDATED = '[User] Bank Accounts Updated';
export class CustomerBankAccountsUpdatedAction implements Action {
    public readonly type = BANK_ACCOUNTS_UPDATED;
    constructor(public payload: CustomerBankAccount[]) {}
}

export const SESSION_EXTENDED = '[User] Session Extended';
export class UserSessionExtendedAction implements Action {
    public readonly type = SESSION_EXTENDED;
    constructor(public payload: SessionExtension) {}
}

export const SESSION_LIFESUPPORT = '[User] Session Lifesupport';
export class SessionLifeSupportAction implements Action {
    public readonly type = SESSION_LIFESUPPORT;
    constructor(public payload: boolean) {}
}

export const SESSION_EXPIRING = '[User] Session Expiring';
export class SessionExpiringAction implements Action {
    public readonly type = SESSION_EXPIRING;
    constructor(public payload: boolean) {}
}

export const REDIRECT = '[User] Redirecting User';
export class RedirectRouteAction implements Action {
    public readonly type = REDIRECT;
    constructor(public payload: string) {}
}

export type UserAction =
    | LogoutAction
    | BankerLoginEnabledAction
    | LogoutRequestAction
    | LoginStartedAction
    | LoginSuccessAction
    | LoginFailedAction
    | ApiAuthenticationStarted
    | BankerLoginFailedAction
    | AgreementCompletedAction
    | AgreementSubmitFailed
    | CustomerBankAccountsUpdatedAction
    | UserSessionExtendedAction
    | SessionLifeSupportAction
    | SessionExpiringAction
    | RedirectRouteAction;

export function bankerEnabledReducer(state: boolean = false, action: UserAction) {
    switch (action.type) {
        case BANKER_ENABLED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

function filterAndMapBankAccounts(bankAccounts: CustomerBankAccount[]) {
    if (!bankAccounts) {
        return bankAccounts;
    }

    return bankAccounts
        .filter(account => account.validFundingAccount && account.accountType !== AccountType.Unknown)
        .map(account => {
            account.accountNumber = `${+account.accountNumber}`;
            return account;
        });
}

export function userReducer(state: User = null, action: UserAction) {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return Object.assign({}, state, action.payload, {
                bankAccounts: filterAndMapBankAccounts(action.payload.bankAccounts),
            });
        }
        case BANK_ACCOUNTS_UPDATED: {
            return Object.assign({}, state, {
                bankAccounts: filterAndMapBankAccounts(action.payload),
            });
        }
        case LOGOUT: {
            return null;
        }
        default: {
            return state;
        }
    }
}

export function sessionRenewedReducer(state: SessionExtension = null, action: UserAction) {
    switch (action.type) {
        case SESSION_EXTENDED: {
            return action.payload;
        }
        default: {
            return null;
        }
    }
}

export function authenticatedReducer(state: boolean = null, action: UserAction) {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return true;
        }
        case LOGOUT: {
            return false;
        }
        default: {
            return state;
        }
    }
}

export function loginInProgressReducer(state: boolean = false, action: UserAction) {
    switch (action.type) {
        case LOGIN_STARTED: {
            return true;
        }
        case LOGIN_SUCCESS:
        case LOGIN_FAILED:
        case BANKER_LOGIN_FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
}

export function apiloginInProgressReducer(state: boolean = false, action: UserAction) {
    switch (action.type) {
        case API_LOGIN: {
            return true;
        }
        case LOGIN_SUCCESS:
        case LOGIN_FAILED:
        case BANKER_LOGIN_FAILED: {
            return false;
        }
        default: {
            return state;
        }
    }
}

export function bankerLoginFailedReducer(state: BankerLoginResult | Error = null, action: UserAction) {
    switch (action.type) {
        case LOGIN_STARTED: {
            return null;
        }
        case LOGIN_SUCCESS: {
            return null;
        }
        case BANKER_LOGIN_FAILED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function logoutRequestedReducer(state: boolean, action: UserAction) {
    switch (action.type) {
        case LOGIN_STARTED: {
            return false;
        }
        case LOGIN_SUCCESS: {
            return false;
        }
        case BANKER_LOGIN_FAILED: {
            return false;
        }
        case LOGOUT_REQUEST: {
            return true;
        }
        default: {
            return state;
        }
    }
}

export function agreementRequestReducer(state: User | Error = null, action: UserAction) {
    switch (action.type) {
        case AGREEMENT_COMPLETED: {
            return action.payload;
        }
        case AGREEMENT_SUBMIT_FAILED: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function logoutRedirectUrlReducer(state: string = null, action: LogoutRequestAction) {
    switch (action.type) {
        case LOGOUT_REQUEST: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function sessionLifeSupportReducer(state: boolean = false, action: SessionLifeSupportAction) {
    switch (action.type) {
        case SESSION_LIFESUPPORT: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function sessionExpiringReducer(state: boolean = false, action: SessionExpiringAction) {
    switch (action.type) {
        case SESSION_EXPIRING: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}

export function redirectRouteReducer(state: string = null, action: RedirectRouteAction) {
    switch (action.type) {
        case REDIRECT: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
