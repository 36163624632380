var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PstContentModalComponent } from 'pst/components/content-modal/pst-content-modal.component';
import { TaxFreeDisclaimerModalComponent } from 'robo/components/goal-selection/tax-free-disclaimer-modal';
import { Store } from '@ngrx/store';
import * as fromApplication from 'application/store/application.store';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseComponent } from 'shared/components';
import { LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { RoboFieldId } from '../../models';
import { RoboClearScreenAction, RoboUpdateFieldAction, TaxFreeOptOutAction } from '../../store';
var RoboGoalSelectionComponent = /** @class */ (function (_super) {
    __extends(RoboGoalSelectionComponent, _super);
    function RoboGoalSelectionComponent(loggerService, lookupDataService, modalService, sharedStore, applicationStore, formBuilder, router, store, ngbModal) {
        var _this = _super.call(this, loggerService) || this;
        _this.lookupDataService = lookupDataService;
        _this.modalService = modalService;
        _this.sharedStore = sharedStore;
        _this.applicationStore = applicationStore;
        _this.formBuilder = formBuilder;
        _this.router = router;
        _this.store = store;
        _this.ngbModal = ngbModal;
        _this.error = null;
        _this.isDirect = false;
        _this.hasExistingTfsa = false;
        _this.hasInFlightTfsa = false;
        _this.content = [];
        return _this;
    }
    RoboGoalSelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.goals$ = this.lookupDataService.getRoboGoals();
        this.lookupDataService
            .getPstResultContent()
            .pipe(this.scavenger.collect())
            .subscribe(function (content) {
            _this.content = content;
        });
        var customer$ = this.applicationStore.select(fromApplication.selectSelectedCustomer);
        var isDirect$ = this.sharedStore.select(fromShared.selectIsDirect);
        this.store.dispatch(new RoboClearScreenAction());
        this.store.dispatch(new TaxFreeOptOutAction(false));
        combineLatest(customer$, isDirect$, function (customer, isDirect) {
            return { customer: customer, isDirect: isDirect };
        })
            .pipe(this.scavenger.collect(), catchError(function (error) {
            _this.error = error;
            return of(error);
        }))
            .subscribe(function (_a) {
            var customer = _a.customer, isDirect = _a.isDirect;
            _this.isDirect = isDirect;
            if (isDirect) {
                _this.goals$ = _this.goals$.pipe(map(function (goals) { return goals.filter(function (goal) { return goal.goalId !== 11; }); }));
            }
            if (customer === null) {
                if (isDirect === false) {
                    _this.logger.warn('Cannot give advice before selecting a customer, navigating to customer search');
                    _this.router.navigateByUrl('/secure/banker/customer-search');
                    _this.sharedStore.dispatch(new StopLoadingAction());
                    return;
                }
                else if (isDirect === true) {
                    _this.logger.warn('Cannot give advice before selecting a customer, navigating to SSO screen to lookup customer');
                    _this.router.navigateByUrl('/secure/investor/sso?brand=WIM_FNB&productCode=&subProductCode=&accountNumber=NEW');
                    return;
                }
            }
            _this.customerName = customer.title + " " + customer.firstname + " " + customer.lastname;
            _this.hasExistingTfsa = customer.hasExistingTaxFreeAccount;
            _this.hasInFlightTfsa = customer.hasInFlightTaxFreeApplication;
        });
        this.goals$
            .pipe(this.scavenger.collect(), catchError(function (error) {
            _this.error = error;
            return of(error);
        }))
            .subscribe(function () {
            _this.createForm();
            _this.sharedStore.dispatch(new StopLoadingAction());
        });
        // // TODO: When we actually know if this is a minor then set it here
        // this.store.dispatch(
        //     new RoboUpdateFieldAction({
        //         fieldId: RoboFieldId.isMinor,
        //         value: 'false',
        //     })
        // );
    };
    RoboGoalSelectionComponent.prototype.ngOnDestroy = function () { };
    RoboGoalSelectionComponent.prototype.retry = function () {
        this.ngOnInit();
    };
    RoboGoalSelectionComponent.prototype.routeTo = function (route) {
        var userType = this.isDirect ? 'investor' : 'banker';
        var routeUrl = route.replace('{user}', userType);
        this.router.navigateByUrl(routeUrl);
    };
    RoboGoalSelectionComponent.prototype.cancel = function () {
        this.routeTo('secure/{user}');
    };
    RoboGoalSelectionComponent.prototype.start = function () {
        this.routeTo('secure/{user}/robo/advice');
    };
    RoboGoalSelectionComponent.prototype.radioClicked = function (node) {
        this.goalForm.get('selectedGoal').setValue(node);
    };
    RoboGoalSelectionComponent.prototype.isIcon = function (goal) {
        return goal.iconName != null && goal.iconName !== '';
    };
    RoboGoalSelectionComponent.prototype.iconPath = function (goal) {
        return "assets/icon/pst/" + goal.iconName + ".svg";
    };
    RoboGoalSelectionComponent.prototype.isContent = function (id) {
        return !!this.content.find(function (c) { return c.id === id; });
    };
    RoboGoalSelectionComponent.prototype.displayContentPopup = function (id) {
        var _this = this;
        if (!this.isContent(id)) {
            return;
        }
        var content = this.content.find(function (c) { return c.id === id; });
        var modalRef = this.modalService.open(PstContentModalComponent);
        modalRef.result
            .then(function () {
            _this.goalForm.get('selectedGoal').setValue(null);
        })
            .catch(function () {
            _this.goalForm.get('selectedGoal').setValue(null);
        });
        modalRef.componentInstance.content = content.content;
    };
    RoboGoalSelectionComponent.prototype.showTaxFreeDisclaimer = function () {
        var _this = this;
        var modal = this.modalService.open(TaxFreeDisclaimerModalComponent, { windowClass: 'large-modal' });
        modal.result
            .then(function () {
            _this.start();
        })
            .catch(function () {
            _this.goalForm.get('selectedGoal').setValue(null);
        });
    };
    RoboGoalSelectionComponent.prototype.createForm = function () {
        var _this = this;
        this.goalForm = this.formBuilder.group({
            selectedGoal: [null, Validators.required],
        });
        this.goalForm.valueChanges.subscribe(function (changes) {
            if (changes.selectedGoal) {
                var selectedGoal = changes.selectedGoal;
                _this.store.dispatch(new RoboUpdateFieldAction({
                    fieldId: RoboFieldId.goal,
                    value: "" + selectedGoal.goalId,
                }));
            }
        });
        this.goalForm.valueChanges.pipe(this.scavenger.collect()).subscribe(function (values) {
            if (values.selectedGoal) {
                if (values.selectedGoal.goalId === 11) {
                    if (_this.hasExistingTfsa) {
                        _this.displayContentPopup(11);
                    }
                    else if (_this.hasInFlightTfsa) {
                        _this.displayContentPopup(12);
                    }
                    else {
                        _this.showTaxFreeDisclaimer();
                    }
                }
                else {
                    _this.start();
                }
            }
        });
    };
    return RoboGoalSelectionComponent;
}(BaseComponent));
export { RoboGoalSelectionComponent };
