import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PstReference } from 'pst/models';
import { PstParserService } from 'pst/services';
import { EnterPressedAction, PstScreenValidAction, UserEnteredValueAction, UserSelectedChildNodeAction } from 'pst/store';
import { PstState } from 'pst/store/pst.store';
import * as fromPst from 'pst/store/pst.store';
import { combineLatest, Observable } from 'rxjs';
import { User } from 'shared/models';
import { LoggerService, LookupDataService } from 'shared/services';

import { BasePstComponent } from '../base-pst.component';

@Component({
    selector: 'wim-pst-multiple-choice',
    templateUrl: 'multiple-choice.component.pug',
    styleUrls: ['multiple-choice.component.scss'],
})
export class MultipleChoiceComponent extends BasePstComponent implements OnInit {
    @Input()
    public customerName: string;
    public tree: PstReference;
    public user: Observable<User>;
    public form: FormGroup;

    constructor(
        loggerService: LoggerService,
        modalService: NgbModal,
        private pstStore: Store<PstState>,
        private formBuilder: FormBuilder,
        private pstParser: PstParserService,
        private lookupService: LookupDataService
    ) {
        super(loggerService, modalService);
    }

    public ngOnInit() {
        const tooltips$ = this.lookupService.getPstTooltips();
        const icons$ = this.lookupService.getPstIcons();
        const tree$ = this.pstStore.select(fromPst.selectPstTree);

        combineLatest(tree$, tooltips$, icons$, (tree, tooltips, icons) => {
            return { tree, tooltips, icons };
        })
            .pipe(this.scavenger.collect())
            .subscribe(({ tree, tooltips, icons }) => {
                this.tree = tree;
                if (!this.tree) {
                    return;
                }
                super.initContent(icons, tooltips, tree.node.nodeId);

                this.createForm();
                if (this.tree && this.tree.childNodes.length > 0) {
                    this.form.reset();
                    this.pstStore.dispatch(new UserSelectedChildNodeAction(null));
                }
                this.pstStore.dispatch(new UserEnteredValueAction(''));
                this.pstStore.dispatch(new PstScreenValidAction(false));
            });
    }

    public radioClicked(node) {
        this.form.get('selectedNode').setValue(node);
    }

    public ngOnDestroy() {}

    private createForm() {
        this.form = this.formBuilder.group({
            selectedNode: [null, Validators.required],
        });
        this.form.valueChanges.subscribe(changes => {
            let selectedNode = changes.selectedNode;
            this.pstStore.dispatch(new UserSelectedChildNodeAction(selectedNode));
            this.pstStore.dispatch(
                new UserEnteredValueAction(this.pstParser.extractChildNodeValue(selectedNode ? selectedNode.nodeLongDescription : ''))
            );
            this.pstStore.dispatch(new PstScreenValidAction(this.form.valid));
        });
    }
}
