var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CisSearchService } from 'banker/services';
import { NewCustomerSearchAction } from 'banker/store/banker.reducers';
import { CustomerFilterReason } from 'application/models';
import { CustomerSelectedAction } from 'application/store';
import * as fromApplication from 'application/store/application.store';
import * as fromBanker from 'banker/store/banker.store';
import { RoboFieldId } from 'robo/models';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from 'shared/components/base';
import { LoggerService, LookupDataService, SaIdNumberValidationService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import { RoboAdditionalLumpsumsUpdated, RoboClearScreenAction, RoboContinuedAction, RoboUpdateMultipleFieldsAction } from 'robo/store';
import { saIdNumberValidator } from 'shared/validators';
var CustomerSearchComponent = /** @class */ (function (_super) {
    __extends(CustomerSearchComponent, _super);
    function CustomerSearchComponent(formBuilder, cisService, bankerStore, applicationStore, sharedStore, pstStore, router, lookupService, saIdNumberService, ngxsStore, loggerService) {
        var _this = _super.call(this, loggerService) || this;
        _this.formBuilder = formBuilder;
        _this.cisService = cisService;
        _this.bankerStore = bankerStore;
        _this.applicationStore = applicationStore;
        _this.sharedStore = sharedStore;
        _this.pstStore = pstStore;
        _this.router = router;
        _this.lookupService = lookupService;
        _this.saIdNumberService = saIdNumberService;
        _this.ngxsStore = ngxsStore;
        _this.searchError = false;
        _this.searchPerformed = false;
        _this.emptyResultsMessage = '';
        return _this;
    }
    CustomerSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.searchResult = this.bankerStore.select(fromBanker.selectCustomerSearchResults);
        var customer$ = this.applicationStore
            .select(fromApplication.selectSelectedCustomer)
            .subscribe(function (selected) { return (_this.selectedCustomer = selected); });
        // Fetch now so we can be sure they'll be there when we need them
        var funds$ = this.lookupService.getSourceOfFundsTypes().subscribe();
        this.lookupService.getPortfolioTypes().subscribe(function (portfolios) { _this.portfolioTypes = portfolios; });
        this.registerSubscriptions(customer$, funds$);
        this.sharedStore.dispatch(new StopLoadingAction());
    };
    CustomerSearchComponent.prototype.ngOnDestroy = function () {
        this.cleanUpSubscriptions();
    };
    CustomerSearchComponent.prototype.search = function () {
        var _this = this;
        this.searchError = false;
        this.emptyResultsMessage = this.getEmptyMessage();
        this.sharedStore.dispatch(new LoadingAction());
        this.cisService
            .search(this.searchForm.get('customerNumber').value, this.searchForm.get('idNumber').value)
            .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
            .subscribe(function (results) {
            _this.bankerStore.dispatch(new NewCustomerSearchAction(results));
            _this.searchPerformed = true;
            if (results.length === 1) {
                _this.select(results[0]);
            }
        }, function (error) { return (_this.searchError = true); });
    };
    CustomerSearchComponent.prototype.canSearch = function () {
        if (!this.searchForm) {
            return false;
        }
        return ((this.searchForm.value.idNumber &&
            this.searchForm.value.idNumber.length > 0 &&
            !!this.searchForm.controls.idNumber &&
            this.searchForm.controls.idNumber.valid) ||
            (this.searchForm.controls.customerNumber &&
                !!this.searchForm.value.customerNumber &&
                this.searchForm.value.customerNumber.length > 0 &&
                this.searchForm.controls.customerNumber.valid));
    };
    CustomerSearchComponent.prototype.reset = function () {
        this.searchForm.reset();
        this.searchPerformed = false;
        this.bankerStore.dispatch(new NewCustomerSearchAction([]));
        this.bankerStore.dispatch(new CustomerSelectedAction(null));
    };
    CustomerSearchComponent.prototype.select = function (customer) {
        if (customer.validCustomer) {
            this.bankerStore.dispatch(new CustomerSelectedAction(customer));
        }
        else {
            this.bankerStore.dispatch(new CustomerSelectedAction(null));
        }
    };
    CustomerSearchComponent.prototype.isSelected = function (customer) {
        return this.selectedCustomer && customer.ucn === this.selectedCustomer.ucn;
    };
    CustomerSearchComponent.prototype.canStart = function () {
        return this.selectedCustomer;
    };
    CustomerSearchComponent.prototype.start = function () {
        if (!this.canStart()) {
            return;
        }
        //this.router.navigateByUrl('/secure/banker/robo');
        this.router.navigateByUrl('/invest');
    };
    CustomerSearchComponent.prototype.getYears = function (months) {
        return Math.floor(months / 12);
    };
    CustomerSearchComponent.prototype.getMonths = function (months) {
        return months % 12;
    };
    CustomerSearchComponent.prototype.GetPortfolioType = function (portfolioTypeId) {
        return this.portfolioTypes.filter(function (p) { return p.id === portfolioTypeId; })[0].name;
    };
    CustomerSearchComponent.prototype.getInEligibilityReason = function (reason) {
        switch (reason) {
            case CustomerFilterReason.email:
                return "This client doesn't have an email address, please update their email and resubmit the investment application";
            case CustomerFilterReason.kyc:
                // tslint:disable-next-line:max-line-length
                return 'This client is not KYC compliant please follow the normal KYC process and resubmit the investment application once KYC compliant';
            case CustomerFilterReason.minor:
                return 'This client is not at least 18 years old';
            case CustomerFilterReason.privateIndividual:
                return 'This client is not a private individual';
            case CustomerFilterReason.southAfricanId:
                return 'This client is not South African';
            case CustomerFilterReason.ucnCountry:
                return 'This client is not South African';
            case CustomerFilterReason.deceased:
                return 'This client is deceased';
            case CustomerFilterReason.accounts:
                return 'This client has no valid accounts that we can debit';
            case CustomerFilterReason.taxResidency:
                return 'This client is not a South African tax resident';
            case CustomerFilterReason.fnbAccount:
                return 'This client has no valid FNB accounts';
            case CustomerFilterReason.undesirable:
                return 'In accordance with domestic and international regulations, the Bank is required to take steps to prevent and mitigate its risk. Accordingly, the bank may not make any further products or services available to this client';
            case CustomerFilterReason.crsCompliant:
                return 'Client is not CRS Compliant. Please follow CRS process and provide tax residency updates to regulatory screens.';
            case CustomerFilterReason.foreignTaxIndicatorValue:
                return 'Client foreign tax residency is unknown. Please follow CRS process and provide tax residency updates to regulatory screens and confirm if the client has a foreign tax residency.';
            case CustomerFilterReason.foreignTaxResidency:
                return 'Client foreign tax residency detected. Please verify if foreign tax residency status is correct and active and follow CRS process to provide tax residency updates to Regulatory screens.';
            default:
                return '';
        }
    };
    CustomerSearchComponent.prototype.continueAdvice = function (result) {
        this.ngxsStore.dispatch(new RoboClearScreenAction());
        var fields = result.fields
            .filter(function (field) { return field.fieldId !== RoboFieldId.lumpSumAmount && field.fieldId !== RoboFieldId.lumpSumDate; })
            .filter(function (field) { return field.value && field.value.trim().length > 0; });
        if (this.selectedCustomer.hasExistingTaxFreeAccount || this.selectedCustomer.hasInFlightTaxFreeApplication) {
            fields = fields.filter(function (field) { return field.fieldId !== RoboFieldId.contributingToTaxFree; });
        }
        this.ngxsStore.dispatch(new RoboUpdateMultipleFieldsAction(fields));
        var lumpSumFields = result.fields.filter(function (field) { return field.fieldId === RoboFieldId.lumpSumAmount || field.fieldId === RoboFieldId.lumpSumDate; });
        var additionalLumpsums = [];
        for (var _i = 0, lumpSumFields_1 = lumpSumFields; _i < lumpSumFields_1.length; _i++) {
            var field = lumpSumFields_1[_i];
            if (!additionalLumpsums[field.index]) {
                additionalLumpsums[field.index] = {
                    amount: 0,
                    date: null,
                };
            }
            if (field.fieldId === RoboFieldId.lumpSumAmount) {
                additionalLumpsums[field.index].amount = +field.value;
            }
            else if (field.fieldId === RoboFieldId.lumpSumDate) {
                additionalLumpsums[field.index].date = field.value;
            }
        }
        this.ngxsStore.dispatch(new RoboAdditionalLumpsumsUpdated(additionalLumpsums));
        this.ngxsStore.dispatch(new RoboContinuedAction(result.pstReference));
        this.router.navigateByUrl("/secure/banker/robo/advice");
    };
    CustomerSearchComponent.prototype.createForm = function () {
        this.searchForm = this.formBuilder.group({
            customerNumber: ['', [Validators.pattern('^[0-9]+$')]],
            idNumber: ['', Validators.compose([Validators.pattern('^[0-9]+$'), saIdNumberValidator(this.saIdNumberService, true)])],
        });
    };
    CustomerSearchComponent.prototype.getEmptyMessage = function () {
        var idNumber = this.searchForm.get('idNumber');
        var ucn = this.searchForm.get('customerNumber');
        return idNumber.value && ucn.value
            ? 'No client found linked to this UCN and ID Number'
            : ucn.value
                ? 'No client found linked to this UCN'
                : 'No client found linked to this ID Number';
    };
    return CustomerSearchComponent;
}(BaseComponent));
export { CustomerSearchComponent };
