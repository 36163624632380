import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { BigNumberPipe } from 'shared/pipes';
import { NumberFormatterService } from 'shared/services/number-formatter.service';

@Directive({ selector: '[wimDecimalFormatter]' })
export class DecimalFormatterDirective implements OnInit {
    @Input()
    public zeroIfEmpty = false;

    private el: HTMLInputElement;

    constructor(private elementRef: ElementRef, private formatterService: NumberFormatterService) {
        this.el = this.elementRef.nativeElement;
    }

    public ngOnInit() {
        this.el.value = this.formatterService.transformDecimal(this.el.value, '1.0-1');
    }

    @HostListener('focus', ['$event.target.value'])
    public onFocus(value) {
        this.el.value = ((value !== '') && (+value < 1)) ? '0' : this.formatterService.parseDecimal(value, '1.0-1');
        this.el.type = 'number';
    }

    @HostListener('blur', ['$event.target.value'])
    public onBlur(value) {
        this.el.type = 'text';
        this.el.value = ((value !== '') && (+value < 1)) ? '0' : this.formatterService.transformDecimal(this.el.value, '1.0-1');
    }
}
