import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { JwtToken } from 'shared/models';
import { Logger, LoggerService, RequestInspectionService } from 'shared/services';
import { LogoutRequestAction, SharedState } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';

@Injectable()
export class LoggedOutInterceptor implements HttpInterceptor {
    private logger: Logger;

    constructor(
        private requestInspectionService: RequestInspectionService,
        loggerService: LoggerService,
        private store: Store<SharedState>
    ) {
        this.logger = loggerService.getLogger('LoggedOutInterceptor');
    }

    private checkResponseCode(request: HttpRequest<any>, responseError: HttpErrorResponse) {
        if (responseError instanceof HttpErrorResponse && this.requestInspectionService.isWebAPIRequest(request)) {
            const t = responseError as HttpErrorResponse;
            if (t.status === 401 || t.status === 403) {
                if (/\/users\/login/.test(t.url)) {
                    this.logger.warn(`API responded ${t.status} for login check, user is not logged in`);
                } else if (/\/users\/logout/.test(t.url)) {
                    this.logger.warn(`API responded ${t.status} for logout`);
                    this.logger.warn(`API responded ${t.status} for logout`);
                } else {
                    this.logger.warn(`API responded ${t.status}, logging user out`);
                    this.store.dispatch(new LogoutRequestAction());
                }
            }
        }
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error, caught) => {
                this.checkResponseCode(request, error as HttpErrorResponse);
                return throwError(error);
            })
        );
    }
}
