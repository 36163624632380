import { FinancialAdvice, RoboField, RoboInput } from '.';
import { AdditionalLumpsum } from './additional-lumpsum';

export class RoboState {
    constructor() {
        this.fields = {};
        this.requiredFields = [];
    }
    public fields: { [key: number]: RoboInput } = {};
    public additionalLumpsums: AdditionalLumpsum[] = [];
    public requiredFields: RoboField[];
    public advice: FinancialAdvice;
    public adviceError: Error;
    public savedAdviceId: string;
    public saveError: Error;

    public taxFreeOpened = false;
    public taxFreeOptOut = false;

    public validationErrors: string[] = [];
    public adviceEngineValidationWarnings: string[] = [];
    public roboValidationWarnings: string[] = [];

    public adviceFormValid = false;
    public downstreamAdviceError = false;

    public static clear(state: RoboState) {
        // When an object is stored in state its a plain old JS object (i.e. methods don't exist) so this needs to be static
        state.fields = {};
        state.requiredFields = [];
        state.additionalLumpsums = [];
        state.advice = null;
        state.adviceError = null;
        state.savedAdviceId = null;
        state.saveError = null;
        state.taxFreeOpened = false;
        state.validationErrors = [];
        state.adviceEngineValidationWarnings = [];
        state.roboValidationWarnings = [];
        state.adviceFormValid = false;
        state.downstreamAdviceError = false;
    }
}
