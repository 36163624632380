import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import appConfig from 'appConfig';
import { CreateCase, CreateCaseResult, Customer, ProductApplication, CustomerConsent, SubmissionResult } from 'application/models';
import { PhoneReference } from 'application/models/phone-reference';
import { PhoneReferenceResponse } from 'application/models/phone-reference-response';
import { CaseCreatedAction } from 'application/store/application.reducers';
import { ApplicationState } from 'application/store/application.store';
import { Observable } from 'rxjs';
import { PendingAgreement, PendingAgreements, PendingAgreementsResult } from 'shared/models';

@Injectable()
export class ApplicationService {
    constructor(private http: HttpClient, private applicationStore: Store<ApplicationState>) {}

    public createCase(pstReferenceNumber: string, customer: Customer, isAdvised: boolean) {
        const payload = {
            pstReferenceNumber,
            customer,
            isAdvised,
        } as CreateCase;
        this.http.post<CreateCaseResult>(`${appConfig.apiUrl}/onboarding/new`, payload).subscribe(result => {
            this.applicationStore.dispatch(new CaseCreatedAction(result));
        });
    }

    public createParty(application: ProductApplication): Observable<SubmissionResult> {
        return this.http.post<SubmissionResult>(`${appConfig.apiUrl}/onboarding/${application.applicationId}/submit`, application);
    }

    public getClientPendingAgreements() {
        return this.http.get<PendingAgreements>(`${appConfig.apiUrl}/onboarding/pending-client-agreement`);
    }

    public submitPendingAgreements(applicationId: number, consent: CustomerConsent): Observable<PendingAgreementsResult> {
        return this.http.post<PendingAgreementsResult>(`${appConfig.apiUrl}/onboarding/${applicationId}/submitAgreement`, consent);
    }

    public cancelApplication(application: ProductApplication): Observable<object> {
        return this.http.delete(`${appConfig.apiUrl}/onboarding/${application.applicationId}`);
    }

    public cancelCustomerApplication(applicationId: number): Observable<object> {
        return this.http.delete(`${appConfig.apiUrl}/onboarding/${applicationId}`);
    }
    public SubmitPhoneReference(payLoad: PhoneReference): Observable<PhoneReferenceResponse> {
        return this.http.post<PhoneReferenceResponse>(`${appConfig.apiUrl}/onboarding/submitPhoneReference`, payLoad);
    }
}
