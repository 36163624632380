var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseInstructionComponent } from 'investor/components/investor-instructions/base';
import { InstrumentAllocationBasis, } from 'investor/models';
import { InstructionsService } from 'investor/services';
import * as fromInvestor from 'investor/store/investor.store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { EacModalComponent } from 'shared/components';
import { DateFormatterService, LoggerService, LookupDataService } from 'shared/services';
import { LoadingAction, StopLoadingAction } from 'shared/store';
import * as fromShared from 'shared/store/shared.store';
import { dateMaxValidator, dateMinValidator } from 'shared/validators';
var LumpSumInstructionComponent = /** @class */ (function (_super) {
    __extends(LumpSumInstructionComponent, _super);
    function LumpSumInstructionComponent(investorStore, sharedStore, instructionService, lookupService, modalService, formBuilder, decimalPipe, toastr, loggerService, dateFormatter) {
        var _this = _super.call(this, toastr, loggerService, modalService) || this;
        _this.investorStore = investorStore;
        _this.sharedStore = sharedStore;
        _this.instructionService = instructionService;
        _this.lookupService = lookupService;
        _this.formBuilder = formBuilder;
        _this.decimalPipe = decimalPipe;
        _this.dateFormatter = dateFormatter;
        _this.modalTitle = 'Lumpsum Investment';
        _this.hasHoldings = false;
        _this.isKyc = false;
        _this.existingInstructions = [];
        _this.viewExisting = false;
        _this.totalAllocation = 0;
        _this.totalValueAllocation = 0;
        _this.minLumpSum = 2000;
        _this.maxLumpSum = 1000000;
        return _this;
    }
    LumpSumInstructionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sharedStore.dispatch(new LoadingAction());
        this.viewExisting = false;
        this.createForm(undefined);
        this.sharedStore
            .select(fromShared.selectUser)
            .pipe(this.scavenger.collect())
            .subscribe(function (user) {
            _this.user = user;
            _this.isKyc = user.customerDetails.isKycVerified;
            _this.userBankAccounts = user.bankAccounts;
            if (user.bankAccounts.length >= 1) {
                _this.instructionForm.get('bankAccount').setValue(user.bankAccounts[0]);
            }
        });
        this.lookupService
            .getSourceOfFundsTypes()
            .pipe(this.scavenger.collect())
            .subscribe(function (types) { return (_this.sourceOfFundsTypes = types); });
        this.lookupService
            .getHorizonFunds()
            .pipe(this.scavenger.collect())
            .subscribe(function (funds) {
            if (funds && funds.length > 0) {
                _this.allFunds = funds.map(function (fund) {
                    return {
                        fundName: fund.name,
                        fundCode: fund.pstCode.split(' ')[0],
                        percentageOfTotal: 0,
                        totalValue: 0,
                        lastPriceDate: null,
                        units: 0,
                        unitPrice: 0,
                        factSheetUrl: fund.factSheetUrl,
                        ter: fund.ter,
                        transactionCosts: fund.transactionCosts,
                        adviceFee: fund.adviceFee,
                        adminFee: fund.adminFee,
                        assetClass: fund.assetClass,
                    };
                });
            }
            else {
                _this.allFunds = [];
            }
        });
        combineLatest(this.investorStore.select(fromInvestor.selectInvestmentAccounts), this.investorStore.select(fromInvestor.selectSelectedInstructionAccount), function (accounts, selectedAccount) {
            return { accounts: accounts, selectedAccount: selectedAccount };
        })
            .pipe(this.scavenger.collect())
            .subscribe(function (_a) {
            var accounts = _a.accounts, selectedAccount = _a.selectedAccount;
            if (!accounts) {
                return;
            }
            _this.selectedAccountNumber = selectedAccount;
            _this.sharedStore.dispatch(new StopLoadingAction());
            _this.selectedAccount = accounts.find(function (acct) {
                return acct.accountNumber === _this.selectedAccountNumber;
            });
            _this.lookupService.getAgreementContent(_this.selectedAccount.portfolioTypeId).subscribe(function (content) {
                _this.debitAgreement = content.filter(function (agg) { return agg.isDebitOrder; })[0].summary;
            });
            _this.addFundHoldings();
        });
    };
    LumpSumInstructionComponent.prototype.ngOnDestroy = function () { };
    LumpSumInstructionComponent.prototype.adhocAmountUpdated = function () {
        this.computeAllocationTotal();
    };
    LumpSumInstructionComponent.prototype.computeAllocationTotal = function () {
        var allocations = this.instructionForm.get('allocations');
        var lumpsumAmount = +this.instructionForm.get('adHocAmount').value;
        if (lumpsumAmount === 0) {
            return;
        }
        for (var _i = 0, _a = allocations.controls; _i < _a.length; _i++) {
            var allocationControl = _a[_i];
            var percentValue = allocationControl.get('percentageOfTotal').value;
            percentValue = isNaN(percentValue) ? 0 : percentValue;
            var randValue = (lumpsumAmount * percentValue) / 100.0;
            allocationControl.get('valueAllocation').setValue(randValue, { emitEvent: false });
        }
        this.totalAllocation = this.instructionForm.get('allocations').value.reduce(function (a, b) { return a + b.percentageOfTotal; }, 0);
        this.totalValueAllocation = this.instructionForm.get('allocations').value.reduce(function (a, b) { return a + b.valueAllocation; }, 0);
    };
    LumpSumInstructionComponent.prototype.computeValueAllocationTotal = function () {
        var allocations = this.instructionForm.get('allocations');
        var lumpsumAmount = +this.instructionForm.get('adHocAmount').value;
        if (lumpsumAmount === 0) {
            return;
        }
        for (var _i = 0, _a = allocations.controls; _i < _a.length; _i++) {
            var allocationControl = _a[_i];
            var randValue = allocationControl.get('valueAllocation').value;
            randValue = isNaN(randValue) ? 0 : randValue;
            var percentAllocation = Math.round((randValue / lumpsumAmount) * 10000.0) / 100.0;
            allocationControl.get('percentageOfTotal').setValue(percentAllocation, { emitEvent: false });
        }
        this.totalAllocation = this.instructionForm.get('allocations').value.reduce(function (a, b) { return a + b.percentageOfTotal; }, 0);
        this.totalValueAllocation = this.instructionForm.get('allocations').value.reduce(function (a, b) { return a + b.valueAllocation; }, 0);
    };
    LumpSumInstructionComponent.prototype.canSubmit = function () {
        return this.totalValueAllocation === +this.instructionForm.get('adHocAmount').value && this.instructionForm.valid;
    };
    LumpSumInstructionComponent.prototype.mayInstruct = function () {
        return this.hasHoldings && this.isKyc;
    };
    LumpSumInstructionComponent.prototype.cancel = function () {
        this.instructionForm.reset();
        this.addFundHoldings();
        this.setDefaults();
    };
    LumpSumInstructionComponent.prototype.submit = function () {
        var _this = this;
        if (this.canSubmit()) {
            var formData = this.instructionForm.value;
            this.sharedStore.dispatch(new LoadingAction());
            var payLoad = {
                accountNumber: this.selectedAccountNumber,
                bankAccountNumber: formData.bankAccount.accountNumber,
                investmentAmount: formData.adHocAmount,
                isDebitAuthorised: formData.debitOrderAgreed,
                sourceOfFundName: formData.sourceOfFunds.description,
                sourceOfFundOther: formData.otherSourceOfFund,
                sourceOfFundsTypeId: formData.sourceOfFunds.id,
                ucn: this.user.customerDetails.ucn,
                caseTypeId: 0,
                investmentAllocations: this.getLumpSumAllocations(),
                debitDate: this.dateFormatter.forApi(formData.debitDate),
                existingInstructionId: formData.existingInstructionId,
                bankAccountHolder: this.user.displayName,
                bankAccountType: formData.bankAccount.accountType,
            };
            this.instructionService
                .submitLumpSumInstruction(payLoad)
                .pipe(finalize(function () { return _this.sharedStore.dispatch(new StopLoadingAction()); }))
                .subscribe(function (result) { return _this.handleResponse(result, _this.modalTitle); }, function () { return _this.handleSubmitError(_this.modalTitle); });
        }
        else {
            this.markAsTouched(this.instructionForm);
        }
    };
    LumpSumInstructionComponent.prototype.calculateEac = function () {
        var eacRef = this.modalService.open(EacModalComponent, { windowClass: 'large-modal' });
        var account = {
            funds: this.instructionForm.get('allocations').value.map(function (accountFund) {
                return {
                    allocation: accountFund.percentageOfTotal / 100,
                    fundCode: accountFund.fundCode.replace('ZA', '').trim(),
                };
            }),
        };
        eacRef.componentInstance.account = account;
    };
    LumpSumInstructionComponent.prototype.getLumpSumAllocations = function () {
        var _allocations = [];
        this.instructionForm.get('allocations').value.forEach(function (allocation) {
            _allocations.push({
                fundCode: allocation.fundCode,
                fundName: allocation.fundName,
                allocatedAmount: allocation.valueAllocation,
                allocatedPercent: allocation.percentageOfTotal,
                allocatedUnits: null,
                allocationBasis: InstrumentAllocationBasis.currencyValue,
            });
        });
        return _allocations;
    };
    LumpSumInstructionComponent.prototype.showTable = function () {
        return this.instructionForm.get('allocations').value.length > 0;
    };
    LumpSumInstructionComponent.prototype.isSourceOfFundsOther = function (id) {
        if (!this.sourceOfFundsTypes || !id) {
            return false;
        }
        var sourceType = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return sourceType && sourceType.isOther;
    };
    LumpSumInstructionComponent.prototype.loadInstruction = function (instruction) {
        this.createForm(instruction);
        this.addFundHoldings();
        this.setExistingValues(instruction.bankAccountNumber, instruction.sourceOfFundName, instruction.existingInstructionId);
        this.viewExisting = false;
    };
    LumpSumInstructionComponent.prototype.backToExisting = function () {
        this.viewExisting = true;
    };
    LumpSumInstructionComponent.prototype.createNew = function () {
        this.createForm(undefined);
        this.addFundHoldings();
        this.viewExisting = false;
    };
    LumpSumInstructionComponent.prototype.getMinDebitDate = function () {
        return moment()
            .add(3, 'days')
            .startOf('day');
    };
    LumpSumInstructionComponent.prototype.getMaxDebitDate = function () {
        return moment()
            .add(1, 'years')
            .startOf('day');
    };
    LumpSumInstructionComponent.prototype.setDefaults = function () {
        this.totalAllocation = 0;
        this.instructionForm.get('adHocAmount').setValue(0);
        this.instructionForm.get('debitDate').setValue(this.dateFormatter.forDatePicker(this.getMinDebitDate()));
        this.instructionForm.get('otherSourceOfFund').setValue('');
        this.instructionForm.get('debitOrderAgreed').setValue(false);
    };
    LumpSumInstructionComponent.prototype.createForm = function (instruction) {
        var ok = !!instruction;
        var minDebitDate = ok ? moment(instruction.debitDate) : this.getMinDebitDate();
        var maxDebitDate = ok
            ? moment(instruction.debitDate)
                .add(1, 'years')
                .startOf('day')
            : this.getMaxDebitDate();
        var investingAmount = ok ? instruction.investmentAmount : 0;
        var debitBankAccount = ok ? instruction.bankAccountNumber : null;
        var agreedDebit = ok ? instruction.isDebitAuthorised : false;
        this.instructionForm = this.formBuilder.group({
            adHocAmount: [
                investingAmount,
                Validators.compose([Validators.required, Validators.min(this.minLumpSum), Validators.max(this.maxLumpSum)]),
            ],
            bankAccount: [debitBankAccount, Validators.required],
            debitDate: [
                this.dateFormatter.forDatePicker(minDebitDate),
                Validators.compose([
                    Validators.required,
                    dateMinValidator(minDebitDate.toDate(), this.dateFormatter),
                    dateMaxValidator(maxDebitDate.toDate(), this.dateFormatter),
                ]),
            ],
            sourceOfFunds: [null, Validators.required],
            otherSourceOfFund: [''],
            allocations: this.formBuilder.array([]),
            debitOrderAgreed: [agreedDebit, Validators.requiredTrue],
            existingInstructionId: [null],
        }, { validator: this.validateSourceOfFunds.bind(this) });
    };
    LumpSumInstructionComponent.prototype.addFundHoldings = function () {
        var _this = this;
        if (this.selectedAccount) {
            var items = this.instructionForm.get('allocations');
            items.controls.length = 0;
            var totalFundHoldings = this.selectedAccount.accountPortfolios.reduce(function (total, holding) {
                if (_this.instructionService.canInstructOnAccountItem(holding)) {
                    return total + holding.totalValue;
                }
                return total;
            }, 0);
            var _loop_1 = function (fund) {
                var matchingHolding = this_1.selectedAccount.accountPortfolios.filter(function (holding) { return holding.fundCode.replace(' ZA', '') === fund.fundCode.replace(' ZA', ''); })[0];
                var fundOnlyPercentage = matchingHolding ? (matchingHolding.totalValue / totalFundHoldings) * 100 : 0;
                var percentageString = this_1.decimalPipe.transform(fundOnlyPercentage, '1.2-2');
                percentageString = percentageString.replace(/,/g, '').replace(/ /g, '');
                var percentageOfTotal = +percentageString;
                items.push(this_1.formBuilder.group({
                    fundCode: fund.fundCode,
                    fundName: fund.fundName,
                    valueAllocation: [0, Validators.compose([Validators.required, Validators.min(0)])],
                    percentageOfTotal: [
                        percentageOfTotal,
                        Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
                    ],
                }));
            };
            var this_1 = this;
            for (var _i = 0, _a = this.allFunds; _i < _a.length; _i++) {
                var fund = _a[_i];
                _loop_1(fund);
            }
            this.hasHoldings = items.length > 0;
            this.computeAllocationTotal();
        }
    };
    LumpSumInstructionComponent.prototype.otherDescriptionRequired = function (id) {
        var type = this.sourceOfFundsTypes.filter(function (sof) { return sof.id === id; })[0];
        return type && type.isOther;
    };
    LumpSumInstructionComponent.prototype.validateSourceOfFunds = function (abstractForm) {
        if (!this.sourceOfFundsTypes) {
            return null;
        }
        var sourceIdControl = abstractForm.get('sourceOfFunds');
        var descriptionControl = abstractForm.get('otherSourceOfFund');
        var required = this.otherDescriptionRequired(+(sourceIdControl.value ? sourceIdControl.value.id : 0));
        var valid = true;
        if (required) {
            var value = descriptionControl.value;
            valid = value && value !== '' && +value !== 0;
        }
        if (valid) {
            descriptionControl.setErrors(null);
            return null;
        }
        descriptionControl.setErrors({ required: true });
        return { required: true };
    };
    LumpSumInstructionComponent.prototype.setExistingValues = function (bankAccountNumber, sourceOfFundName, existingInstructionId) {
        var bankAccount = !!bankAccountNumber
            ? this.userBankAccounts.filter(function (k) { return k.accountNumber === bankAccountNumber; })[0]
            : this.userBankAccounts[0];
        var selectedSource = this.sourceOfFundsTypes.filter(function (k) { return k.description === sourceOfFundName; })[0];
        if (existingInstructionId) {
            this.instructionForm.get('existingInstructionId').setValue(existingInstructionId);
        }
        if (selectedSource) {
            this.instructionForm.get('sourceOfFunds').setValue(selectedSource);
        }
        this.instructionForm.get('bankAccount').setValue(bankAccount);
    };
    return LumpSumInstructionComponent;
}(BaseInstructionComponent));
export { LumpSumInstructionComponent };
